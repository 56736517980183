import { navigate } from 'gatsby-plugin-intl';
import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useRef, useState } from 'react';

type DraftState = 'initial-state' | 'draft-saving' | 'draft-saved';

export function useCreateOpening(companyData?: Company) {
  const [isSaving, setIsSaving] = useState(false);
  const [isDraftSaving, setIsDraftSaving] = useState(false);
  const [saveError, setSaveError] = useState<ApiErrorData>();
  const [draftState, setDraftState] = useState<DraftState>('initial-state');

  const savedStatus = useRef('');

  const http = useHttpClient('/api/v1/company');

  function handleError(request: OpeningRequest, error: HttpError) {
    if (error && request.status === 'draft') {
      setDraftState('initial-state');
      setIsDraftSaving(false);
      return;
    }

    setSaveError(error);
    setIsSaving(false);
    return;
  }

  async function handleAfterSubmit(
    request: OpeningRequest,
    jobOpening: Opening,
  ) {
    if (request.status === 'draft') {
      setIsDraftSaving(false);
      setDraftState('draft-saved');
      return;
    }

    if (!request.status) {
      setIsDraftSaving(false);
      setIsSaving(false);
      return;
    }

    try {
      await http.put<{ status: string }>(
        `${jobOpening.companyId}/openings/${jobOpening.id}/status`,
        { status: request.status },
      );
      navigate(
        `/profile/company/openings/${companyData?.handle}/opening/share/${jobOpening.id}`,
      );
      setIsDraftSaving(false);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setSaveError(e);
    } finally {
      setIsSaving(false);
    }
  }

  async function handleUpdateJobOpening(
    companyDataId: string,
    request: OpeningRequest,
  ) {
    if (request.status === 'draft') {
      request.publishedAt = null;
    }

    try {
      const { data } = await http.put<Opening, OpeningRequest>(
        `${companyDataId}/openings/${savedStatus.current}/update`,
        request,
      );
      handleAfterSubmit(request, data);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      handleError(request, e);
    }
  }

  async function handleAddJobOpening(
    companyDataId: string,
    request: OpeningRequest,
  ) {
    if (request.status === 'draft') {
      request.publishedAt = null;
    }

    if (
      request.status &&
      ['published-internal', 'published-public'].includes(request.status)
    ) {
      request.publishedAt = null;
    }

    try {
      const { data } = await http.post<Opening, OpeningRequest>(
        `${companyDataId}/openings/create`,
        request,
      );
      if (data.status === 'draft') {
        savedStatus.current = data.id;
      }
      handleAfterSubmit(request, data);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      handleError(request, e);
    }
  }

  const handleSubmit = async (request: OpeningRequest) => {
    if (!companyData?.id) return;

    request.status === 'draft' ? setIsDraftSaving(true) : setIsSaving(true);

    savedStatus.current
      ? handleUpdateJobOpening(companyData.id, request)
      : handleAddJobOpening(companyData.id, request);
  };

  return {
    isSaving,
    isDraftSaving,
    saveError,
    draftState,
    setDraftState,
    handleSubmit,
  };
}
