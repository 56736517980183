import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';

export function useAdminUser() {
  const { toastError, toastSuccess } = useToastify();
  const adminUserService = useHttpClient('/admin/users');

  const [error, setError] = useState<ApiErrorData>();
  const [user, setUser] = useState<GetUserDetailsResponseData>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  async function getUserDetails(userId: string) {
    setIsLoading(true);
    try {
      const { data } = await adminUserService.get<GetUserDetailsResponseData>(
        `/${userId}`,
      );
      setUser(data);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }
    setIsLoading(false);
  }

  async function setCompanyOnboardingDone(userId: string) {
    try {
      await adminUserService.put(`/${userId}/set-company-onboarding-done`);
      await getUserDetails(userId);
      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      toastError();
    }
  }

  async function setTalentOnboardingDone(userId: string) {
    try {
      await adminUserService.put(`/${userId}/set-talent-onboarding-done`);
      await getUserDetails(userId);
      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      toastError();
    }
  }

  return {
    user,
    error,
    isLoading,
    getUserDetails,
    setCompanyOnboardingDone,
    setTalentOnboardingDone,
  };
}
