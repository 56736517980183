import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';

export const buttonContainer = css`
  background: hsl(var(--color-warning-6));
  box-shadow: 6px 6px 40px rgba(0, 0, 0, 0.07);
  padding: ${spacing(15)} ${spacing(15)} 0;
  position: fixed;
  bottom: ${spacing(50)};
  right: ${spacing(50)};

  p,
  strong {
    color: hsl(var(--color-white-11));
    font-size: var(--font-size-small);
  }
`;

export const button = css`
  margin: ${spacing(15)} 0;
`;

export const fill = css`
  path {
    fill: hsl(var(--color-textLight-10));
  }
`;
