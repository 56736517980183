import { css } from '@emotion/react';

export const root = css`
  display: inline-block;
  color: hsl(var(--color-textLight-2));
  border-bottom: 1px solid currentColor;
  text-decoration: none;
  font-weight: var(--font-weight-bold);
  transition: color 0.3s ease, border-color 0.3s ease;

  &:hover,
  &:active {
    color: hsl(var(--color-textLight-4));
  }
`;
