import React, { useRef, useState } from 'react';

import * as styles from 'modules/app/styles/Tooltip.styles';
import { AnimatePresence, motion } from 'framer-motion';
import {
  FADE_IN_MOVE_Y_REGULAR,
  FADE_IN_MOVE_Y_REVERSE,
} from 'style/animations.config';
import { useOutOfBounds } from '..';

interface Props {
  position?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  placeholder: React.ReactNode;
  customStyles?: SerializedStyles;
  placeholderStyles?: SerializedStyles;
  theme?: 'light' | 'dark';
}

export const Tooltip: React.FC<Props> = ({
  position = 'bottomLeft',
  placeholder,
  children,
  customStyles,
  placeholderStyles,
  theme = 'dark',
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isTooltip, setIsTooltip] = useState(false);
  const isBottom = position === 'bottomLeft' || position === 'bottomRight';
  const animation = isBottom
    ? { ...FADE_IN_MOVE_Y_REGULAR }
    : { ...FADE_IN_MOVE_Y_REVERSE };
  const exitAnimation = isBottom
    ? { opacity: 0, y: 10 }
    : { opacity: 0, y: -10 };

  useOutOfBounds(ref, isTooltip);

  return (
    <div css={[styles.root, customStyles]}>
      <div
        css={[styles.placeholder, placeholderStyles]}
        onMouseEnter={() => setIsTooltip(true)}
        onMouseLeave={() => setIsTooltip(false)}
      >
        {placeholder}
      </div>
      <AnimatePresence>
        {isTooltip && (
          <motion.div
            ref={ref}
            {...animation}
            exit={exitAnimation}
            css={styles[position](theme)}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
