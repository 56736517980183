import { HttpError } from 'modules/app/http/client/httpError';
import { ActionUnion } from 'modules/redux-store';

import { talentOnboardingAction } from './actions';

interface InitialState extends OnboardingTalentBreadcrumbs {
  isLoading: boolean;
  isInRequest: boolean;
  listArray: ListingData[];
  error?: HttpError;
}

const INITIAL_STATE: InitialState = {
  basics: true,
  expertise: false,
  'experience/professional-experience': false,
  'experience/portfolio-list': false,
  'experience/project-list': false,
  'education/certificates': false,
  'education/education': false,
  'education/language': false,
  finishedScreen: false,
  isLoading: false,
  isInRequest: false,
  listArray: [],
  error: undefined,
};

export const talentOnboardingReducer = (
  state: InitialState = INITIAL_STATE,
  action: ActionUnion<typeof talentOnboardingAction>,
): InitialState => {
  switch (action.type) {
    case 'onboarding/TALENT_LOADING':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case 'onboarding/TALENT_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'onboarding/TALENT_IN_REQUEST':
      return {
        ...state,
        isInRequest: action.payload,
      };

    case 'onboarding/TALENT_SET_LIST_ARRAY':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        listArray: action.payload,
        isInRequest: false,
      };
    case 'onboarding/TALENT_ACCESS':
      return {
        ...state,
        ...action.payload,
      };
    case 'onboarding/TALENT_RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
