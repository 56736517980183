import { format } from 'date-fns';
import { parseDate } from 'modules/app';

function getUtcDate(date: Date) {
  const isoDate = date.toISOString();

  return `${isoDate.substring(0, 10)} ${isoDate.substring(11, 19)}`;
}

function isOpeningDateSame(date: Date | null, initialDate: Date | null) {
  if (!date || !initialDate) return false;

  const newDate = format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
  const oldDate = format(new Date(initialDate), 'yyyy-MM-dd HH:mm:ss');

  return newDate === oldDate;
}

export function handleOpeningSubmitDates(
  date: Date | null,
  isExpires?: boolean,
) {
  if (!date || isExpires) return null;

  return getUtcDate(date);
}

export function handlePublishedAt(
  date: Date | null,
  initialDate: Date | null,
  isScheduled: boolean,
) {
  if (!date || !isScheduled) {
    return null;
  }

  if (isOpeningDateSame(date, initialDate)) {
    return parseDate(initialDate, true);
  }

  return handleOpeningSubmitDates(date);
}

export function handleExpiresAt(date: Date | null, initialDate: Date | null) {
  if (isOpeningDateSame(date, initialDate)) {
    return parseDate(initialDate, true);
  }

  return handleOpeningSubmitDates(date, true);
}
