import React from 'react';
import Google from 'assets/icons/google.svg';
import LinkedIn from 'assets/icons/linkedin.svg';
import Github from 'assets/icons/github.svg';
import * as styles from 'modules/app/styles/SocialLogin.styles';
import { navigate, useLocation } from '@reach/router';
import { FormattedMessage } from 'react-intl';

interface Props {
  text?: string;
}

export const SocialLogin: React.FC<Props> = ({ text }) => {
  const location = useLocation();

  const onLogin = (provider: Provider) => {
    const hostname =
      location.hostname !== 'localhost' && !location.hostname.startsWith('www')
        ? `${location.hostname}`
        : location.hostname;

    const host = `${location.protocol}//${hostname}${
      location.hostname === 'localhost' ? ':' + location.port : ''
    }`;

    const redirectUri = encodeURI(`${host}/en/app/oauth/${provider}`);

    switch (provider) {
      case 'google':
        navigate(
          `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.GATSBY_GOOGLE_CLIENT_ID}&scope=email%20profile&redirect_uri=${redirectUri}&response_type=code&access_type=offline&include_granted_scopes=true`,
          {
            replace: true,
          },
        );

        break;
      case 'github':
        navigate(
          `https://github.com/login/oauth/authorize?client_id=${process.env.GATSBY_GITHUB_CLIENT_ID}&scope=read:user,user:email&response_type=code&redirect_uri=${redirectUri}&allow_signup=true`,
          {
            replace: true,
          },
        );

        break;
      case 'linkedin':
        navigate(
          `https://www.linkedin.com/oauth/v2/authorization?client_id=${process.env.GATSBY_LINKEDIN_CLIENT_ID}&response_type=code&scope=r_liteprofile%20r_emailaddress&redirect_uri=${redirectUri}`,
          {
            replace: true,
          },
        );
    }
  };

  return (
    <div css={styles.root}>
      <p>
        <FormattedMessage
          id={text ? text : 'shared.social.social_component_default'}
        />
      </p>

      <div css={styles.iconGroup}>
        <label css={styles.icon} onClick={() => onLogin('google')}>
          <Google />
        </label>
        <label css={styles.icon} onClick={() => onLogin('linkedin')}>
          <LinkedIn />
        </label>
        <label css={styles.icon} onClick={() => onLogin('github')}>
          <Github />
        </label>
      </div>
    </div>
  );
};
