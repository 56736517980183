import { margin } from '@prototyp/gatsby-plugin-gumball/utils/margin';
import {
  isArchiveDisabled,
  isDeleteDisabled,
  isEditDisabled,
} from '../helpers';
import React from 'react';
import Edit from 'assets/icons/EditBlack.svg';
import Delete from 'assets/icons/delete.svg';
import Archive from 'assets/icons/archive.svg';
import Duplicate from 'assets/icons/duplicate.svg';

interface OpeningActionsReturn {
  id: string;
  disabled?: boolean;
  element: React.ReactElement;
}

export function openingActions(data: CompanyOpening): OpeningActionsReturn[] {
  return [
    {
      id: 'edit',
      disabled: isEditDisabled(data),
      element: (
        <span>
          <Edit css={margin.right.med} /> Edit
        </span>
      ),
    },
    {
      id: 'duplicate',
      element: (
        <span>
          <Duplicate css={margin.right.med} /> Duplicate
        </span>
      ),
    },
    {
      id: 'archive',
      disabled: isArchiveDisabled(data),
      element: (
        <span>
          <Archive css={margin.right.med} /> Archive
        </span>
      ),
    },
    {
      id: 'delete',
      disabled: isDeleteDisabled(data),
      element: (
        <span>
          <Delete css={margin.right.med} /> Delete
        </span>
      ),
    },
  ];
}
