import { useHttpClient, useToastify } from 'modules/app';
import { useState } from 'react';
import { useSearchUsers } from '.';
import { HttpError } from 'modules/app/http/client/httpError';

export const useDeleteUser = (isDetails?: boolean) => {
  const adminUsersService = useHttpClient('/admin/users');
  const { toastError, toastSuccess } = useToastify();
  const [isLoading, setIsLoading] = useState(false);
  const { searchUsers } = useSearchUsers();

  async function requestDeactivateUser(
    userId: string,
    deactivateMessage: string,
    callback: VoidFunction,
  ) {
    setIsLoading(true);

    try {
      await adminUsersService.put(`/${userId}`, {
        deactivateMessage,
      });

      if (!isDetails) {
        searchUsers({
          page: 1,
          hideDeleted: true,
          sortBy: 'created_at',
          search: '',
          sort: 'DESC',
          input: '',
        });
      }

      setIsLoading(false);
      toastSuccess('shared.pills.successDefaultText');
      if (isDetails) location.reload();
      callback();
    } catch (e) {
      toastError();
    }
    setIsLoading(false);
  }

  async function requestDeleteUser(
    userId: string,
    otpToken: string,
    reason: string,
    callback: VoidFunction,
  ) {
    setIsLoading(true);

    try {
      await adminUsersService.delete(`/${userId}/forever-delete`, {
        params: {
          otp_token: otpToken,
        },
        data: {
          reason,
        },
      });

      toastSuccess('shared.pills.successDefaultText');
      if (isDetails) location.reload();
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.cause === 'invalid_otp_code_provided') {
        toastError('admin.users.deleteModal.otpTokenError');
      }
      if (e.cause === 'cannot_expire_only_admin') {
        toastError('admin.users.deleteModal.lastAdminError');
      }
    }
    setIsLoading(false);
  }

  return {
    isLoading,
    requestDeactivateUser,
    requestDeleteUser,
  };
};
