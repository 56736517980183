import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { debounce } from 'throttle-debounce';
import * as styles from 'modules/app/styles/Dropdown.styles';
import { useIntl } from 'gatsby-plugin-intl';

interface Props {
  handleOnChange: (v: string) => void;
}

export const DropdownSearch: React.FC<Props> = ({ handleOnChange }) => {
  const { register } = useForm();
  const { formatMessage } = useIntl();
  const ref = useRef<HTMLInputElement>(null);

  const onChange = debounce(300, (v: string) => {
    handleOnChange(v);
  });

  useEffect(() => {
    if (!ref.current) return;

    ref.current.focus();
  }, []);

  return (
    <div css={styles.search}>
      <input
        {...register('search')}
        onChange={(e) => onChange(e.target.value)}
        ref={ref}
        placeholder={formatMessage({ id: 'search.quickSearch' })}
        css={styles.searchInput}
      />
    </div>
  );
};
