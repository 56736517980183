import { FormattedMessage } from 'gatsby-plugin-intl';
import {
  CheckboxField,
  GooglePlacesAutocomplete,
  InputField,
} from 'modules/app';
import React, { useState } from 'react';
import * as styles from 'modules/app/styles/LocationForm.styles';

interface Props {
  currentLocation?: CompanyLocation;
  resetValue: number;
  selectedLocation: SelectedPlace | undefined;
  setSelectedLocation: React.Dispatch<
    React.SetStateAction<SelectedPlace | undefined>
  >;
}

export const CompanyLocationInputs: React.FC<Props> = ({
  currentLocation,
  resetValue,
  selectedLocation,
  setSelectedLocation,
}) => {
  const [isHeadquarters, setIsHeadquarters] = useState(false);

  return (
    <div css={styles.formBox}>
      <GooglePlacesAutocomplete
        label="onboarding.form.fields.companyLocation.locationLabel"
        name="location"
        id="location"
        selectedPlace={selectedLocation}
        onSelectedPlace={(place: SelectedPlace | undefined) =>
          setSelectedLocation(place)
        }
      />

      <div css={styles.twoFields}>
        <InputField
          type="text"
          name="additionalInfo"
          value={currentLocation ? currentLocation.additionalInfo : ''}
          constraints={{
            maxLength: {
              value: 255,
              message: 'shared.errors.max',
            },
          }}
          reset={resetValue}
          label="onboarding.form.fields.companyLocation.additionalInfoLabel"
        />

        <div css={styles.checkbox}>
          <CheckboxField
            onChange={() => setIsHeadquarters(!isHeadquarters)}
            name={
              currentLocation
                ? `isHeadquarters${currentLocation.id}`
                : 'isHeadquarters'
            }
            checked={
              currentLocation ? currentLocation.isHeadquarters : isHeadquarters
            }
            constraints={{}}
            reset={resetValue}
          >
            <FormattedMessage id="onboarding.form.fields.companyLocation.headquartersLabel" />
          </CheckboxField>
        </div>
      </div>
    </div>
  );
};
