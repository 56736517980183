import { useHttpClient, useToastify } from 'modules/app';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

export const useChangeOwnership = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { toastError, toastSuccess } = useToastify();
  const form = useForm<{ email: string }>({
    defaultValues: {
      email: '',
    },
  });

  const http = useHttpClient('/admin/companies');

  const changeOwnership = useCallback(
    async (companyId: string, email: string, callback: VoidFunction) => {
      setIsLoading(true);
      try {
        await http.post<{ email: string }>(`${companyId}/change-ownership`, {
          email,
        });
        callback();
        toastSuccess('Email has been sent');
      } catch (e) {
        toastError();
      } finally {
        setIsLoading(false);
      }
    },
    [http],
  );

  return { form, isLoading, changeOwnership };
};
