import { css } from '@emotion/react';
import { breakpoints } from 'style/variables';

export const mainContent = css`
  padding-left: calc(var(--unit) * 5);
  padding-right: calc(var(--unit) * 5);
  max-width: 1600px;
  margin: 0 auto;
  height: 100%;

  @media ${breakpoints.medium} {
    padding-left: calc(var(--unit) * 16);
    padding-right: calc(var(--unit) * 16);
  }

  @media ${breakpoints.large} {
    padding-left: calc(var(--unit) * 36);
    padding-right: calc(var(--unit) * 36);
  }
`;

export const mainContentPreview = css`
  padding-left: calc(var(--unit) * 4);
  padding-right: calc(var(--unit) * 4);

  @media ${breakpoints.medium} {
    padding-left: calc(var(--unit) * 10);
    padding-right: calc(var(--unit) * 10);
  }

  @media ${breakpoints.large} {
    padding-left: calc(var(--unit) * 10);
    padding-right: calc(var(--unit) * 10);
  }
`;
