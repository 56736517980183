import { BaseModal } from './BaseModal';
import React from 'react';
import { motion } from 'framer-motion';
import { FADE_IN } from 'style/animations.config';

import * as styles from '../styles/ParentModal.styles';
import { Heading } from './Heading';

export const ParentModal: React.FC = ({ children }) => {
  return (
    <BaseModal>
      <motion.div
        transition={{ duration: 0.4 }}
        {...FADE_IN}
        css={styles.wrapper}
      >
        <div css={styles.root}>
          <Heading as="h1" size="fluidLarge">
            Make Your Image Fit
          </Heading>
          <div css={styles.content}>{children}</div>
        </div>
      </motion.div>
    </BaseModal>
  );
};
