import React from 'react';
import * as styles from 'modules/admin/styles/UserItem.styles';
import { formatDate } from 'modules/admin/static';
import { Button } from 'modules/app';
import { useRetryRequestDataCopy } from '../hooks';

export const UserCopyRequestItem: React.FC<CopyRequestData> = ({
  id,
  userId,
  userFirstName,
  userLastName,
  userEmail,
  userRegisteredAt,
  userRegisteredIp,
  createdAt,
  responseStatus,
  response,
}) => {
  const { retryRequestData, isLoading } = useRetryRequestDataCopy();
  return (
    <tr css={styles.user}>
      <td>
        <span css={styles.userInfo}>
          <span>
            <a css={styles.userName}>
              <span>{`${userFirstName} ${userLastName}`}</span>
            </a>
            <span css={styles.userEmail}>{userEmail}</span>
          </span>
        </span>
      </td>

      <td>
        <span css={styles.userRegistered}>
          {formatDate(userRegisteredAt, 'PP, HH:mm')}
        </span>
        <span css={styles.userIp}>{userRegisteredIp}</span>
      </td>

      <td css={styles.userCreatedRequest}>
        <p css={styles.userRegistered}>{formatDate(createdAt, 'PP, HH:mm')}</p>
      </td>

      <td>
        <p css={styles.userIp}>{responseStatus || ''}</p>
        <p css={styles.userIp}>{response || 'User requested copy'}</p>
      </td>

      <td>
        <Button
          buttonType={'outline'}
          size={'tiny'}
          isLoading={isLoading}
          onClick={() => retryRequestData(userId, id)}
          disabled={responseStatus === 'Ok' || responseStatus === null}
        >
          Retry
        </Button>
      </td>
    </tr>
  );
};
