import React, { useContext } from 'react';
import { PreviewBox } from '../components';

const PreviewContext = React.createContext<PreviewBox>({
  isPreview: undefined,
  isGhost: false,
});

export const usePreviewContext = () => {
  const ctx = useContext(PreviewContext);
  return {
    ctx,
    Provider: PreviewContext.Provider,
  };
};
