import React from 'react';
import { Logo, Paragraph, Heading } from 'modules/app';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import Visa from 'assets/icons/visa.svg';
import Mastercard from 'assets/icons/mastercard.svg';

import * as footer from '../../../styles/Footer.styles';

export const Footer: React.FC = () => {
  return (
    <footer css={footer.footer}>
      <div className="footer__wrapper" role="presentation">
        {/* TODO - when they start writing newsletters bring this section back */}
        {/* <article
          className="footer__cta-section"
          css={[footer.newsletter, footer.grid]}
        >
          <section className="cta-section__title">
            <Paragraph size="fluidXxLarge">
              <FormattedMessage id="footer.ctaSection.ctaTitle" />
            </Paragraph>
            <Paragraph size="fluidMedium">
              <FormattedMessage id="footer.ctaSection.ctaSubtitle" />
            </Paragraph>
          </section>

          <section
            id="footer-form"
            className="cta-section__form"
            css={footer.formWrapper}
          >
            <div id="mc_embed_signup" role="presentation">
              <form
                action="https://barrage.us13.list-manage.com/subscribe/post?u=09a7aa3890d4c95fe81e44f67&amp;id=1da558a2f3"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
              >
                <div id="mc_embed_signup_scroll" role="presentation">
                  <div className="mc-field-group" role="presentation">
                    <label htmlFor="mce-EMAIL" css={footer.formLabel}>
                      <FormattedMessage id="footer.ctaSection.ctaLabel" />
                    </label>
                    <br />
                    <input
                      css={footer.formInput}
                      type="email"
                      name="EMAIL"
                      className="required email"
                      id="mce-EMAIL"
                      placeholder={intl.formatMessage({
                        id: 'footer.ctaSection.ctaPlaceholder',
                      })}
                    />
                  </div>

                  <input
                    ref={button}
                    css={footer.formSubmit}
                    type="submit"
                    disabled={true}
                    value={intl.formatMessage({
                      id: 'footer.ctaSection.ctaButton',
                    })}
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className="button"
                  />
                </div>

                <div id="mce-responses" className="clear">
                  <div
                    className="response"
                    id="mce-error-response"
                    style={{ display: 'none' }}
                  ></div>
                  <div
                    className="response"
                    id="mce-success-response"
                    style={{ display: 'none' }}
                  ></div>
                </div>
              </form>
            </div>

            <Checkbox
              name="terms"
              checked={false}
              onChecked={() => handleCheckChange()}
              customStyles={footer.termsAndConditions}
            >
              <FormattedMessage
                id="footer.ctaSection.ctaInfo"
                values={{
                  ctaTerms: (
                    <Link to="/terms-of-service">
                      <FormattedMessage id="footer.ctaSection.ctaTerms" />
                    </Link>
                  ),
                  ctaPrivacy: (
                    <Link to="/privacy-policy">
                      <FormattedMessage id="footer.ctaSection.ctaPrivacy" />
                    </Link>
                  ),
                }}
              />
            </Checkbox>
          </section>
        </article>

        <hr className="footer__divider" /> */}

        <article
          className="footer__info-section"
          css={[footer.grid, footer.footerInfoSection]}
        >
          <section className="info-section__roango">
            <Heading as="h2" className="info-section__title">
              <FormattedMessage id="footer.infoSection.roango.sectionTitle" />
            </Heading>
            <div className="info-section__content" role="presentation">
              <Link className="info-section__item" to="/explore">
                <FormattedMessage id="footer.infoSection.roango.linkExplore" />
              </Link>
              <Link className="info-section__item" to="/talents">
                <FormattedMessage id="footer.infoSection.roango.linkTalents" />
              </Link>
              <Link className="info-section__item" to="/companies">
                <FormattedMessage id="footer.infoSection.roango.linkCompany" />
              </Link>
              <Link className="info-section__item" to="/pricing">
                <FormattedMessage id="footer.infoSection.roango.linkPricing" />
              </Link>
              <a
                className="info-section__item"
                href="https://blog.roango.com/"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="footer.infoSection.roango.linkBlog" />
              </a>
              <Link className="info-section__item" to="/about">
                <FormattedMessage id="footer.infoSection.roango.linkAboutUs" />
              </Link>
            </div>
          </section>
          <section className="info-section__connect">
            <Heading as="h2" className="info-section__title">
              <FormattedMessage id="footer.infoSection.connect.sectionTitle" />
            </Heading>
            <div className="info-section__content" role="presentation">
              <a
                href="https://www.facebook.com/RoangoHQ"
                target="_blank"
                rel="noopener noreferrer"
                className="info-section__item"
              >
                <FormattedMessage id="footer.infoSection.connect.linkFacebook" />
              </a>

              <a
                href="https://www.instagram.com/roangohq/"
                target="_blank"
                rel="noopener noreferrer"
                className="info-section__item"
              >
                <FormattedMessage id="footer.infoSection.connect.linkInstagram" />
              </a>

              <a
                href="https://twitter.com/RoangoHQ"
                target="_blank"
                rel="noopener noreferrer"
                className="info-section__item"
              >
                <FormattedMessage id="footer.infoSection.connect.linkTwitter" />
              </a>

              <a
                href="https://www.linkedin.com/company/roangohq/about/"
                target="_blank"
                rel="noopener noreferrer"
                className="info-section__item"
              >
                <FormattedMessage id="footer.infoSection.connect.linkLinkedin" />
              </a>
            </div>
          </section>
          <section className="info-section__reach-out">
            <Heading as="h2" className="info-section__title">
              <FormattedMessage id="footer.infoSection.reachOut.sectionTitle" />
            </Heading>
            <div className="info-section__content" role="presentation">
              <Link className="info-section__item" to="/request-demo">
                <FormattedMessage id="footer.infoSection.reachOut.requestDemo" />
              </Link>
              <a className="info-section__item" href="mailto:hi@roango.hr">
                <FormattedMessage id="footer.infoSection.reachOut.linkEmail" />
              </a>
              <Link className="info-section__item" to="/support">
                <FormattedMessage id="footer.infoSection.roango.linkSupport" />
              </Link>
            </div>
          </section>
          <section className="info-section__legal">
            <Heading as="h2" className="info-section__title">
              <FormattedMessage id="footer.infoSection.legal.sectionTitle" />
            </Heading>
            <div className="info-section__content">
              <Link to="/terms-of-service" css={footer.link}>
                <FormattedMessage id="footer.infoSection.legal.linkTerms" />
              </Link>
              <Link to="/privacy-policy" css={footer.link}>
                <FormattedMessage id="footer.infoSection.legal.linkPrivacy" />
              </Link>

              <Link to="/cookie-policy" css={footer.link}>
                <FormattedMessage id="footer.infoSection.legal.linkCookie" />
              </Link>
              <Link to="/recurring-payment-policy" css={footer.link}>
                <FormattedMessage id="footer.infoSection.legal.linkRecurring" />
              </Link>
            </div>
          </section>
          <section className="info-section__address">
            <Heading as="h2" className="info-section__title">
              {''}
            </Heading>
            <p css={footer.companyInfo}>
              <FormattedMessage id="footer.companyInfoSection.businessInfo.name" />
            </p>
            <p css={footer.companyInfo}>
              <FormattedMessage id="footer.companyInfoSection.businessInfo.address.street" />
            </p>
            <p css={footer.companyInfo}>
              <FormattedMessage id="footer.companyInfoSection.businessInfo.address.city" />
              {', '}
              <FormattedMessage id="footer.companyInfoSection.businessInfo.address.country" />
            </p>
          </section>
        </article>

        <article className="footer__copyright-section" css={footer.grid}>
          <Logo css={footer.logo} />

          <Paragraph
            fontFamily="primary"
            size="tiny"
            className="copyright-section__description"
            css={footer.roangoText}
          >
            <FormattedMessage id="footer.copyrightSection.copyDisclaimer" />
          </Paragraph>

          <div css={footer.cardsContainer}>
            <Mastercard />
            <Visa />
          </div>
        </article>
      </div>
    </footer>
  );
};
