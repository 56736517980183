import React from 'react';
import { Sidebar, Header, Main } from 'modules/admin/components';
import * as styles from 'modules/admin/styles/Layout.styles';

export const AdminLayout: React.FC = ({ children }) => {
  return (
    <div css={styles.layout}>
      <Sidebar />
      <Main>
        <Header />
        {children}
      </Main>
    </div>
  );
};
