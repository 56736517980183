import { navigate } from 'gatsby-plugin-intl';
import { useCallback } from 'react';
import { useDeleteCompany } from './useDeleteCompany';
import { useGetAdminCompanies } from './useGetAdminCompanies';

export function useManageCompaniesContent(
  handlePageChange: (param: number) => void,
) {
  const { getAdminCompanies } = useGetAdminCompanies();
  const { deleteCompany, deactivateCompany } = useDeleteCompany();

  const getCompanies = useCallback(
    (search, currentPage = 1) => {
      getAdminCompanies({ page: currentPage, search: search });
    },
    [getAdminCompanies],
  );

  const onPageChange = (data: { selected: number }) => {
    const newValue = data.selected + 1;
    handlePageChange(newValue);
  };

  const handleDelete = (
    id: string,
    companies: AdminResponse<Companies>,
    search: string,
    page: number,
  ) => {
    let nextPage = page;
    if (companies.data.length === 1 && page !== 1) {
      nextPage = page - 1;
      navigate(`/admin/companies/?page=${nextPage}`);
    }
    deleteCompany(id, () => getCompanies(search, nextPage));
  };

  const handleDeactivate = (id: string, search: string, page: number) => {
    deactivateCompany(id, () => getCompanies(search, page));
  };

  return {
    onPageChange,
    handleDelete,
    handleDeactivate,
  };
}
