import React from 'react';
import * as styles from 'modules/admin/styles/AdminOverview.styles';
import { UserCopyRequestItem } from 'modules/admin/components';

interface Props {
  users: CopyRequestData[];
  isLoading: boolean;
  query: CopyUsersDataQuery;
}

export const UsersCopyRequestTable: React.FC<Props> = ({
  isLoading,
  users,
  query,
}) => {
  return (
    <table>
      <thead css={styles.tableHead}>
        <tr>
          <td width="300">Users</td>
          <td width="200">Registered</td>
          <td width="150">Created request</td>
          <td width="300">Copy of data sent</td>
          <td width="120">Retry</td>
        </tr>
      </thead>
      <tbody css={isLoading ? styles.loading : null}>
        {!isLoading && Boolean(users.length) ? (
          users.map((user, key: number) => (
            <UserCopyRequestItem key={key} {...user} />
          ))
        ) : (
          <tr>
            {!isLoading && (
              <td>
                {Boolean(query.search) ? (
                  <span>
                    No results found for <strong>"{query.search}"</strong>
                  </span>
                ) : (
                  <span>No results found</span>
                )}
              </td>
            )}
          </tr>
        )}
      </tbody>
    </table>
  );
};
