import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';

export const useCompanyLocations = () => {
  const { toastSuccess } = useToastify();

  const [pageCount, setPageCount] = useState(1);
  const [error, setError] = useState<HttpError>();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [locations, setLocations] = useState<CompanyLocation[]>();

  const http = useHttpClient('/api/v1/company');

  function onPageChange(data: { selected: number }) {
    setCurrentPage(data.selected + 1);
  }

  async function getLocations(companyId: string, page: number, load = true) {
    load && setIsLoading(true);

    try {
      const { data } = await http.get<CompanyLocationResponse>(
        `${companyId}/profile/locations`,
        { params: { page, perPage: 2 } },
      );
      if (data.lastPage < data.page) {
        setCurrentPage(data.page - 1);
        return;
      }
      setLocations(data.data);
      setPageCount(data.lastPage);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function addLocation(
    companyId: string,
    info: CompanyLocationRequest,
    callback: VoidFunction,
  ) {
    setIsLoading(true);
    try {
      await http.post<CompanyLocationRequest>(
        `${companyId}/profile/locations`,
        info,
      );
      callback();
      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function updateLocation(
    companyId: string,
    locationId: string,
    info: CompanyLocationRequest,
    callback: (data: CompanyLocation) => void,
  ) {
    setIsLoading(true);
    try {
      const { data } = await http.put<CompanyLocation, CompanyLocationRequest>(
        `${companyId}/profile/locations/${locationId}`,
        info,
      );
      callback(data);
      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function removeLocation(
    companyId: string,
    locationId: string,
    callback: VoidFunction,
  ) {
    try {
      await http.delete(`${companyId}/profile/locations/${locationId}`);
      callback();
      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }
  }

  return {
    error,
    isLoading,
    pageCount,
    locations,
    currentPage,
    addLocation,
    onPageChange,
    getLocations,
    updateLocation,
    removeLocation,
  };
};
