import { createAction } from 'modules/redux-store';

export const pipelinesAction = {
  companyPipelines: (payload: PipelineView[] | undefined) =>
    createAction('pipelines/COMPANY_PIPELINES', payload),

  pipeline: (payload: Pipeline | undefined) =>
    createAction('pipelines/GET_PIPELINE', payload),

  loading: () => createAction('pipelines/LOADING'),
  error: (payload: ApiErrorData) => createAction('pipelines/ERROR', payload),

  reset: () => createAction('pipelines/PIPELINES_RESET'),
};
