export const pwdMsgMap = {
  'Add another word or two. Uncommon words are better.':
    'register.errors.pwd_strength_errors.1',
  'Straight rows of keys are easy to guess':
    'register.errors.pwd_strength_errors.2',
  'Short keyboard patterns are easy to guess':
    'register.errors.pwd_strength_errors.3',
  'Use a longer keyboard pattern with more turns':
    'register.errors.pwd_strength_errors.4',
  'Repeats like "aaa" are easy to guess':
    'register.errors.pwd_strength_errors.5',
  'Repeats like "abcabcabc" are only slightly harder to guess than "abc"':
    'register.errors.pwd_strength_errors.6',
  'Sequences like abc or 6543 are easy to guess':
    'register.errors.pwd_strength_errors.7',
  'Avoid sequences': 'register.errors.pwd_strength_errors.8',
  'Recent years are easy to guess': 'register.errors.pwd_strength_errors.9',
  'Avoid recent years': 'register.errors.pwd_strength_errors.10',
  'Avoid years that are associated with you':
    'register.errors.pwd_strength_errors.11',
  'Dates are often easy to guess': 'register.errors.pwd_strength_errors.12',
  'Avoid dates and years that are associated with you':
    'register.errors.pwd_strength_errors.13',
  'This is a top-10 common password': 'register.errors.pwd_strength_errors.14',
  'This is a top-100 common password': 'register.errors.pwd_strength_errors.15',
  'This is a very common password': 'register.errors.pwd_strength_errors.16',
  'This is similar to a commonly used password':
    'register.errors.pwd_strength_errors.17',
  'A word by itself is easy to guess': 'register.errors.pwd_strength_errors.18',
  'Names and surnames by themselves are easy to guess':
    'register.errors.pwd_strength_errors.19',
  'Common names and surnames are easy to guess':
    'register.errors.pwd_strength_errors.20',
  "Capitalization doesn't help very much":
    'register.errors.pwd_strength_errors.21',
  'All-uppercase is almost as easy to guess as all-lowercase':
    'register.errors.pwd_strength_errors.22',
  "Reversed words aren't much harder to guess":
    'register.errors.pwd_strength_errors.23',
  "Predictable substitutions like '@' instead of 'a' don't help very much":
    'register.errors.pwd_strength_errors.24',
  'Use a few words, avoid common phrases':
    'register.errors.pwd_strength_errors.25',
  'No need for symbols, digits, or uppercase letters':
    'register.errors.pwd_strength_errors.26',
  'Avoid repeated words and characters':
    'register.errors.pwd_strength_errors.27',
};
