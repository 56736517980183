import { useIntl } from 'gatsby-plugin-intl';
import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useReset } from 'modules/onboarding';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { companyRoleValues } from '../consts';
import { settingsAction } from '../redux';

interface RoleChangeParams {
  id: string;
  user: CompanyUser;
  callback: (id: string) => void;
  currentRole: UserCompanyRoles;
  page: number;
}

export const useTeamMembers = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { resetValue, setReset } = useReset();
  const { toastError, toastSuccess } = useToastify();

  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState<number>();
  const [isAdditional, setIsAdditional] = useState(false);
  const [selectedTeamRole, setSelectedTeamRole] = useState<string | string[]>(
    '',
  );

  const isLimited = useMemo(
    () =>
      selectedTeamRole === 'limited_team_member' ||
      selectedTeamRole === 'reviewer',
    [selectedTeamRole],
  );
  const roleValues = useMemo(
    () =>
      companyRoleValues.map((value) => ({
        value: value.value,
        label: formatMessage({ id: value.label }),
      })),
    [companyRoleValues],
  );

  const http = useHttpClient('/api/v1/company/');

  const methods = useForm<InviteUserRequest>({
    defaultValues: {
      userEmail: '',
      companyRoleId: '',
      companyDepartmentId: null,
      companyOpeningId: null,
    },
  });
  const { handleSubmit, reset, setError, watch, clearErrors } = methods;

  function onRoleChange() {
    if (isLimited) {
      setIsAdditional(true);
      return;
    }

    setIsAdditional(false);
  }

  function handleEmpty(data: InviteUserRequest | UserRolesRequest) {
    if (!data.companyOpeningId && !data.companyDepartmentId) {
      setIsEmpty(true);
      setError('companyDepartmentId', {
        type: 'validate',
        message: 'settings.membersContent.chooseError',
      });
      setError('companyOpeningId', {
        type: 'validate',
        message: 'settings.membersContent.chooseError',
      });
      return true;
    }

    return false;
  }

  function handleErrors() {
    if (!isEmpty) return;

    if (watch().companyDepartmentId || watch().companyOpeningId) {
      clearErrors();
      setIsEmpty(false);
      return;
    }
  }

  async function inviteTeamMember(companyId: string, info: InviteUserRequest) {
    if (isLimited && handleEmpty(info)) {
      handleEmpty(info);
      return;
    }

    setIsLoading(true);
    try {
      await http.post<InviteUserRequest>(`${companyId}/users/invite`, info);
      const role = roleValues.find(
        (role) => role.value === info.companyRoleId,
      )?.label;
      reset();
      setReset();
      setIsAdditional(false);
      toastSuccess('settings.membersContent.inviteSuccess', {
        email: info.userEmail,
        role: role,
      });
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      toastError();
    } finally {
      setIsLoading(false);
    }
  }

  async function getTeamMembers(companyId: string, page = 1, load = true) {
    load && dispatch(settingsAction.loading());
    try {
      const { data } = await http.get<CompanyUsersResponse>(
        `${companyId}/users`,
        {
          params: {
            page,
            perPage: 15,
          },
        },
      );
      dispatch(settingsAction.companyUsers(data.data));
      setLastPage(data.lastPage);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(settingsAction.error(e));
    }
  }

  async function changeRole(
    companyId: string,
    userId: string,
    info: UserRolesRequest,
    page: number,
    callback?: VoidFunction,
  ) {
    setIsLoading(true);
    try {
      await http.post(`${companyId}/users/${userId}/roles`, info);
      getTeamMembers(companyId, page, false);
      callback?.();
    } catch (e) {
      toastError();
    } finally {
      setIsLoading(false);
    }
  }

  async function removeTeamMember(
    companyId: string,
    memberId: string,
    page: number,
    callback: VoidFunction,
  ) {
    try {
      await http.delete(`${companyId}/users/${memberId}/roles`);
      if (page === 1) {
        getTeamMembers(companyId, 1, false);
        return;
      }

      callback();
    } catch (e) {
      toastError();
    }
  }

  async function handleRoleChange(data: RoleChangeParams) {
    const { id, user, callback, currentRole, page } = data;
    const userId = user.userId;
    if (user.companyRoleId === id) return;

    const model: UserRolesRequest = {
      companyDepartmentId: null,
      companyOpeningId: null,
      companyRoleId: id,
    };

    if (id === 'limited_team_member' || id === 'reviewer') {
      callback(id);
      return;
    }

    changeRole(currentRole.companyId, userId, model, page);
  }

  return {
    methods,
    isEmpty,
    lastPage,
    isLoading,
    roleValues,
    resetValue,
    isAdditional,
    selectedTeamRole,
    watch,
    changeRole,
    handleEmpty,
    handleErrors,
    onRoleChange,
    handleSubmit,
    getTeamMembers,
    inviteTeamMember,
    removeTeamMember,
    handleRoleChange,
    setSelectedTeamRole,
  };
};
