import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { motion } from 'framer-motion';
import * as styles from 'modules/admin/styles/Manage.styles';
import * as buttonStyles from 'modules/app/styles/Button.styles';
import * as adminOverview from '../../styles/AdminOverview.styles';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import {
  AdminCheckBox,
  CompaniesContent,
  SectionHead,
} from 'modules/admin/components';
import ClearIcon from 'assets/icons/clear.svg';
import SearchIcon from 'assets/icons/search.svg';
import { Button, DropdownMenu, TextInput } from 'modules/app';
import { navigate } from 'gatsby-plugin-intl';
import queryString from 'query-string';
import { useGetAdminCompanies } from 'modules/admin/hooks/useGetAdminCompanies';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store/models/AppState';
import axios from 'axios';
import { useSearchParams } from 'modules/admin/hooks';
import {
  isCompanySearchBy,
  isCompanySort,
  isCompanySortBy,
  sortByOptions,
  sortOptions,
} from './helpers/typeGuards';

export const Overview: React.FC<RouteComponentProps> = () => {
  const url = queryString.parse(location.search);
  const page = url.page ? parseInt(url.page as string) : 1;
  const { getAdminCompanies } = useGetAdminCompanies();
  const queryStrings = window.location.search;
  const queryParams = new URLSearchParams(queryStrings);
  const {
    data: { companies },
    isLoading,
  } = useSelector((state: AppState) => state.adminUsers);

  const urlParams = {
    urlPage: queryParams.get('page') ?? '1',
    urlPerPage: queryParams.get('perPage') ?? '10',
    urlSort: queryParams.get('sort') ?? 'DESC',
    urlSortBy: queryParams.get('sortBy') ?? 'registered_at',
    urlSearch: queryParams.get('search') ?? '',
    urlSearchBy: queryParams.get('searchBy') ?? 'name',
    urlHideDeleted: queryParams.get('hideDeleted') ?? 'false',
  };

  const [term, setTerm] = useState(url.query ? url.query : '');
  const [query, setQuery] = useState<ExtraSearchQueryData>({
    page: +urlParams.urlPage,
    perPage: +urlParams.urlPerPage,
    sort: isCompanySort(urlParams.urlSort) ? urlParams.urlSort : 'DESC',
    sortBy: isCompanySortBy(urlParams.urlSortBy)
      ? urlParams.urlSortBy
      : 'registered_at',
    search: urlParams.urlSearch,
    searchBy: isCompanySearchBy(urlParams.urlSearchBy)
      ? urlParams.urlSearchBy
      : 'name',
    hideDeleted: JSON.parse(urlParams.urlHideDeleted),
  });

  const {
    handlePageChange,
    handleChangeSortBy,
    handleChangeSort,
    handleSearch,
    handleGenerateSearchFilterOptions,
  } = useSearchParams<ExtraSearchQueryData>({
    query,
    setQuery,
  });

  const onResetFilter = () => {
    setQuery({
      page: 1,
      perPage: 10,
      sortBy: 'registered_at',
      sort: 'DESC',
      searchBy: 'name',
      search: '',
    });
  };

  const onHideDeleted = () => {
    setQuery({
      ...query,
      page: 1,
      hideDeleted: !query.hideDeleted,
    });
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    getAdminCompanies({ ...query });
    navigate(
      `/admin/companies?page=${query.page}&sortBy=${query.sortBy}&sort=${
        query.sort
      }&search=${query.search ? query.search : ''}&hideDeleted=${
        query.hideDeleted
      }`,
    );

    return () => source.cancel();
  }, [query.page, query.sortBy, query.sort, query.search, query.hideDeleted]);

  return (
    <div css={styles.content}>
      <motion.section {...FADE_IN_MOVE_Y_REGULAR}>
        <SectionHead
          title={`Manage companies (${companies?.total || 0})`}
          customStyles={styles.companyListHeader}
        >
          <TextInput
            placeholder="Search Companies by Name or Handle"
            value={term}
            size={35}
            onChange={(e) => setTerm(e.currentTarget.value)}
            onKeyUp={handleSearch}
            icon={
              term ? (
                <ClearIcon
                  css={buttonStyles.root}
                  onClick={() => {
                    setTerm('');
                    navigate(`/admin/companies?page=1`);
                  }}
                />
              ) : (
                <SearchIcon />
              )
            }
          />

          <div css={adminOverview.searchFilter}>
            <AdminCheckBox
              onChange={onHideDeleted}
              name="hideDeleted"
              checked={Boolean(query.hideDeleted)}
              disabled={isLoading}
              isSmall={true}
            >
              Hide deleted
            </AdminCheckBox>
            <DropdownMenu
              onSelect={(id) => {
                handleChangeSortBy(id);
              }}
              placeholder={
                <div>
                  Sort by <strong>{query.sortBy}</strong>
                </div>
              }
              hideCaret={false}
              items={handleGenerateSearchFilterOptions(
                sortByOptions,
                query.sortBy,
              )}
              position="bottomRight"
              customButtonStyles={adminOverview.sortByDropdown}
            />

            <DropdownMenu
              onSelect={(id) => {
                handleChangeSort(id);
              }}
              placeholder={
                <div>
                  Sort <strong>{query.sort}</strong>
                </div>
              }
              hideCaret={false}
              items={handleGenerateSearchFilterOptions(sortOptions, query.sort)}
              position="bottomRight"
              customButtonStyles={adminOverview.sortByDropdown}
            />

            <Button
              buttonType={'outline'}
              size={'tiny'}
              onClick={onResetFilter}
              disabled={isLoading}
            >
              Reset filter
            </Button>
          </div>
        </SectionHead>
      </motion.section>
      {companies && (
        <motion.section {...FADE_IN_MOVE_Y_REGULAR}>
          <CompaniesContent
            currentPage={page}
            search={query.search}
            companies={companies}
            handlePageChange={handlePageChange}
          />
        </motion.section>
      )}
    </div>
  );
};
