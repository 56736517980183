export function getInitialValues(
  opening?: Opening,
  isDuplicate = false,
): OpeningFormValues {
  if (!opening) {
    return {
      title: '',
      departmentId: '',
      status: undefined,
      location: { cityCountry: '', location: { x: 0, y: 0 } },
      descriptionRolesResponsibilities: null,
      education: null,
      yearsOfProfessionalExperience: null,
      languages: '',
      workplaceSpecifies: null,
      typeOfWorkplace: [],
      salaryRange: null,
      numberOfWorkingHours: null,
      partTimePeriod: null,
      workingHoursGroup: [],
      contractType: '',
      tags: [],
      temporaryPeriodGroup: [],
      temporaryProbationPeriodDuration: null,
      indefinitePeriodGroup: [],
      indefiniteProbationPeriodDuration: null,
      careerLevel: null,
      benefits: null,
      metaTitle: null,
      metaDescription: null,
      metaImage: null,
      expiresAt: null,
      publishedAt: null,
      pipeline: null,
      internally: false,
    };
  }

  return {
    title: opening.title,
    status: opening.status,
    departmentId: opening.departmentId,
    descriptionRolesResponsibilities: opening.descriptionRolesResponsibilities,
    education: opening.education,
    tags: (function () {
      if (opening.tags) {
        return opening.tags.split(',').map((tag) => tag.substring(1));
      }

      return [];
    })(),
    yearsOfProfessionalExperience:
      opening.yearsOfProfessionalExperience || null,
    languages: Boolean(opening.languages.length)
      ? JSON.stringify(opening.languages)
      : '',
    workplaceSpecifies: opening.workplaceSpecifies,
    typeOfWorkplace: opening.typeOfWorkplace,
    salaryRange: opening.salaryRange,
    workingHoursGroup: opening.workingHours ? [opening.workingHours] : [],
    contractType: opening.contractType,
    careerLevel: opening.careerLevel,
    benefits: opening.benefits,
    metaTitle: opening.metaTitle,
    metaDescription: opening.metaDescription,
    metaImage: isDuplicate ? null : opening.metaImage,
    temporaryPeriodGroup:
      opening.contractType === 'temporary' && opening.temporaryProbationPeriod
        ? ['with-probation']
        : ['without-probation'],
    temporaryProbationPeriodDuration: opening.temporaryProbationPeriodDuration
      ? opening.temporaryProbationPeriodDuration
      : null,
    indefinitePeriodGroup:
      opening.indefiniteProbationPeriod && opening.indefiniteProbationPeriod
        ? ['with-probation']
        : ['without-probation'],
    indefiniteProbationPeriodDuration: opening.indefiniteProbationPeriodDuration
      ? opening.indefiniteProbationPeriodDuration
      : null,
    partTimePeriod: opening.partTimePeriod,
    numberOfWorkingHours: opening.numberOfWorkingHours
      ? opening.numberOfWorkingHours + ''
      : null,
    pipeline: opening.pipelineId,
    expiresAt: opening.expiresAt ? new Date(opening.expiresAt) : null,
    location: {
      cityCountry: opening.cityCountry,
      location: { x: opening.location.x, y: opening.location.y },
    },
    publishedAt: opening.publishedAt ? new Date(opening.publishedAt) : null,
    internally: false,
  };
}
