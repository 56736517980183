import { companyOnboardingAction, useCompanyId } from 'modules/onboarding';
import {
  PortfolioIdEnum,
  PortfolioUrl,
  useHttpClient,
  useToastify,
} from 'modules/app';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { HttpError } from 'modules/app/http/client/httpError';
import { UseFormSetError } from 'react-hook-form';

export const useCompanySocials = () => {
  const dispatch = useDispatch();
  const companyId = useCompanyId();
  const { toastError, toastSuccess } = useToastify();

  const [error, setError] = useState<HttpError>();

  const http = useHttpClient('/api/v1/company');

  async function getSocials(page: number, load = true) {
    load && dispatch(companyOnboardingAction.loading());

    try {
      const {
        data: { data },
      } = await http.get<CompanyPortfolioResponse>(
        `${companyId}/profile/portfolios`,
        {
          params: {
            page,
            perPage: 20,
          },
        },
      );
      const socialsList: ListingData[] = data.map((item) => ({
        title: PortfolioIdEnum[item.portfolioId],
        content:
          item.portfolioId === 'other'
            ? `${item.url}`
            : `${PortfolioUrl[item.portfolioId]}${item.handle}`,
        id: item.id,
      }));
      dispatch(companyOnboardingAction.setListArray(socialsList));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(companyOnboardingAction.error(e));
    }
  }

  async function deleteSocials(id: string) {
    try {
      await http.delete(`/${companyId}/profile/portfolios/${id}`);
      getSocials(1, false);
    } catch (e) {
      toastError();
    }
  }

  async function addSocial(
    info: CompanyPortfolioRequest,
    callback: VoidFunction,
    formError: UseFormSetError<CompanyPortfolioRequest>,
  ) {
    try {
      await http.post<CompanyPortfolio, CompanyPortfolioRequest>(
        `/${companyId}/profile/portfolios`,
        info,
      );
      callback();
      getSocials(1, false);
      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.cause === 'max_number_reached:portfolios') {
        formError('portfolioId', {
          type: 'manual',
          message: 'http_errors.max_portfolios',
        });
        return;
      }
      if (e.cause === 'item_already_exists') {
        formError('url', {
          type: 'manual',
          message: 'http_errors.item_already_exists',
        });
        return;
      }
      setError(e);
    }
  }

  return {
    error,
    addSocial,
    getSocials,
    deleteSocials,
  };
};
