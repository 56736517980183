import { createAction } from 'modules/redux-store';

export const talentDashboardActions = {
  setActiveInvitations: (payload?: Invitation[]) =>
    createAction('talentDashboard/ACTIVE_INVITATIONS', payload),
  setClosedInvitations: (payload?: DashboardApplication[]) =>
    createAction('talentDashboard/CLOSED_INVITATIONS', payload),
  setRejectedInvitations: (payload?: DashboardApplication[]) =>
    createAction('talentDashboard/REJECTED_INVITATIONS', payload),

  setActiveApplications: (payload?: DashboardApplication[]) =>
    createAction('talentDashboard/ACTIVE_APPLICATIONS', payload),
  setRequestedApplications: (payload?: RequestedApplication[]) =>
    createAction('talentDashboard/REQUESTED_APPLICATIONS', payload),
  setDisqualifiedApplications: (payload?: DashboardApplication[]) =>
    createAction('talentDashboard/DISQUALIFIED_APPLICATIONS', payload),

  loading: (payload: boolean) =>
    createAction('talentDashboard/LOADING', payload),
  error: (payload: ApiErrorData) =>
    createAction('talentDashboard/ERROR', payload),

  reset: () => createAction('talentDashboard/RESET'),
};
