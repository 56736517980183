import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import { Avatar, Heading, MainContent } from 'modules/app';
import { AppState } from 'modules/redux-store';
import { format } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';

import * as styles from '../styles/GracePeriod.styles';

interface Props {
  name: string;
  handle: string;
  image: Picture | null;
}

export const GracePeriod: React.FC<Props> = ({ name, handle, image }) => {
  const { latestPlan } = useSelector((state: AppState) => state.companyData);

  if (!latestPlan) return null;

  return (
    <section css={styles.root}>
      <MainContent>
        <div css={styles.content}>
          <p css={styles.label}>
            <FormattedMessage id="companyDashboard.grace.label" />
          </p>
          <Heading as="h3" size="fluidXxLarge">
            <FormattedMessage id="companyDashboard.grace.title" />
          </Heading>

          <div css={styles.company}>
            <Avatar name={name} imagePath={image?.large} />
            <p>{name}</p>
          </div>

          <p css={styles.info}>
            <FormattedMessage id="companyDashboard.grace.info" />
          </p>
          <div>
            <p css={styles.text}>
              <FormattedMessage
                id="companyDashboard.grace.content0"
                values={{
                  date: format(new Date(latestPlan.expiresAt), 'dd/MM/yyyy'),
                }}
              />
            </p>
            <p css={styles.text}>
              <FormattedMessage id="companyDashboard.grace.content1" />
            </p>
          </div>

          <Link
            to={`/profile/settings/company/${handle}/billing/billing-data?planId=${latestPlan.futurePlanId}`}
            css={styles.link}
          >
            <FormattedMessage id="companyDashboard.grace.btn" />
          </Link>
        </div>
      </MainContent>
    </section>
  );
};
