import React from 'react';
import { ucFirst } from '../static';

interface Props {
  firstName: string | undefined;
  lastName: string | undefined;
}

export const Initials: React.FC<Props> = ({ firstName, lastName }) => {
  return (
    <>
      {ucFirst(firstName ?? '')}
      {ucFirst(lastName ?? '')}
    </>
  );
};
