import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';

export const sidebar = css`
  background: hsl(var(--color-white-11));
  border-right: 1px solid hsl(var(--color-borderUltraLight-9));
  min-height: 100vh;
  height: 100%;
  overflow: auto;
  width: 240px;
  padding: var(--spacing-xxxlrg) var(--spacing-lrg);
  position: fixed;
  z-index: 3;
`;

export const subSidebar = css`
  background: none;
  position: sticky;
  top: 0;
  height: 100%;
  width: 210px;
  z-index: 2;

  a {
    &.active {
      background: none;
      text-decoration: underline;
    }
  }
`;

export const stickyNavigation = css`
  position: sticky;
  top: 0;
`;

export const sidebar__logo = css`
  margin: 0 0 ${spacing(64)} var(--spacing-lrg);
`;

export const sidebar__navigation = css`
  margin: 0 0 var(--spacing-xxxlrg);

  .label {
    color: hsl(var(--color-textLight-7));
    font-size: var(--font-size-micro);
    padding: var(--spacing-med) var(--spacing-lrg);
    text-transform: uppercase;
  }

  a {
    display: block;
    margin: 0 0 var(--spacing-lrg);
    color: var(--colors-text);
    border-radius: var(--border-radius-regular);
    font-size: var(--font-size-tiny);
    line-height: var(--line-height-tiny);
    overflow: hidden;
    padding: var(--spacing-med) var(--spacing-lrg);

    &.active {
      font-weight: var(--font-weight-bold);
      background: hsl(var(--color-robin-1));
    }
  }
`;
