import React, { useEffect, useRef, useCallback } from 'react';

import * as styles from 'modules/app/styles/ActionModal.styles';
import { BaseModal } from 'modules/app';
import { motion } from 'framer-motion';
import { FADE_IN, FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  customStyles?: SerializedStyles;
}

export const ActionModal: React.FC<Props> = ({
  children,
  isOpen,
  setIsOpen,
  customStyles,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const closeOnEsc = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsOpen(false);

        return;
      }
    },
    [setIsOpen],
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', closeOnEsc);

      return;
    }

    document.removeEventListener('keydown', closeOnEsc);
  }, [isOpen, closeOnEsc]);

  return (
    <BaseModal>
      <motion.div
        {...FADE_IN}
        transition={{ duration: 0.2 }}
        css={styles.root}
        exit={{
          opacity: 0,
          transition: { duration: 0.2, delay: 0.2 },
        }}
      >
        <motion.div
          ref={ref}
          {...FADE_IN_MOVE_Y_REGULAR}
          transition={{ duration: 0.3, delay: 0.2 }}
          css={[styles.content, customStyles]}
          exit={{
            opacity: 0,
            y: 10,
            transition: { duration: 0.2, delay: 0 },
          }}
        >
          {children}
        </motion.div>
      </motion.div>
    </BaseModal>
  );
};
