import React from 'react';
import WarnWhite from 'assets/icons/warn_white.svg';
import WarnBlack from 'assets/icons/warn_black.svg';
import Info from 'assets/icons/info.svg';
import * as alertStyles from 'modules/app/styles/Alert.styles';

const images = {
  warning: <WarnBlack />,
  error: <WarnWhite />,
  success: <Info />,
};

export const Alert: React.FC<AlertProps> = ({
  type,
  message,
  label,
  show,
  styles,
  customStyles,
  ...rest
}) => {
  if (!show) return null;

  return (
    <div css={[alertStyles[type], styles, customStyles]} {...rest}>
      <div>{images[type]}</div>

      {label && <label>{label}</label>}

      {message && <p>{message}</p>}
    </div>
  );
};
