import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { SelectComponent, proficiencyValues } from 'modules/app';

import * as styles from 'modules/app/styles/GenericForm.styles';
import { useFormContext } from 'react-hook-form';

export const languageValues = {
  a1: {
    value: 'a1',
    label: <FormattedMessage id="onboarding.form.fields.languages.a1" />,
  },
  a2: {
    value: 'a2',
    label: <FormattedMessage id="onboarding.form.fields.languages.a2" />,
  },
  b1: {
    value: 'b1',
    label: <FormattedMessage id="onboarding.form.fields.languages.b1" />,
  },
  b2: {
    value: 'b2',
    label: <FormattedMessage id="onboarding.form.fields.languages.b2" />,
  },
  c1: {
    value: 'c1',
    label: <FormattedMessage id="onboarding.form.fields.languages.c1" />,
  },
  c2: {
    value: 'c2',
    label: <FormattedMessage id="onboarding.form.fields.languages.c2" />,
  },
};

interface Props {
  selected: string | string[];
  currentLanguage?: Language;
}

export const TalentSecondLanguage: React.FC<Props> = ({
  selected,
  currentLanguage,
}) => {
  const { setValue } = useFormContext();

  const values = proficiencyValues.filter((val) => val.value !== 'native');

  useEffect(() => {
    if (currentLanguage) {
      setValue(
        'understandingListening',
        currentLanguage?.understandingListening,
      );
      setValue('understandingReading', currentLanguage?.understandingReading);
      setValue('spokenProduction', currentLanguage?.spokenProduction);
      setValue('spokenInteraction', currentLanguage?.spokenInteraction);
      setValue('writing', currentLanguage?.writing);

      return;
    }
    setValue('understandingListening', selected);
    setValue('understandingReading', selected);
    setValue('spokenProduction', selected);
    setValue('spokenInteraction', selected);
    setValue('writing', selected);
  }, [setValue, selected, currentLanguage]);

  return (
    <>
      <div css={styles.subgridForm}>
        <SelectComponent
          selectedValue={
            typeof selected === 'string' &&
            languageValues[
              currentLanguage?.understandingListening
                ? currentLanguage.understandingListening
                : selected
            ]
          }
          name="understandingListening"
          label={
            <FormattedMessage id="onboarding.form.fields.languages.understanding_when_listening" />
          }
          constraints={{}}
          values={values}
        />

        <SelectComponent
          selectedValue={
            typeof selected === 'string' &&
            languageValues[
              currentLanguage?.understandingReading
                ? currentLanguage.understandingReading
                : selected
            ]
          }
          name="understandingReading"
          label={
            <FormattedMessage id="onboarding.form.fields.languages.understanding_when_reading" />
          }
          constraints={{}}
          values={values}
        />

        <SelectComponent
          selectedValue={
            typeof selected === 'string' &&
            languageValues[
              currentLanguage?.spokenProduction
                ? currentLanguage.spokenProduction
                : selected
            ]
          }
          name="spokenProduction"
          label={
            <FormattedMessage id="onboarding.form.fields.languages.spoken_production" />
          }
          constraints={{}}
          values={values}
        />

        <SelectComponent
          selectedValue={
            typeof selected === 'string' &&
            languageValues[
              currentLanguage?.spokenInteraction
                ? currentLanguage.spokenInteraction
                : selected
            ]
          }
          name="spokenInteraction"
          label={
            <FormattedMessage id="onboarding.form.fields.languages.spoken_interaction" />
          }
          constraints={{}}
          values={values}
        />

        <SelectComponent
          selectedValue={
            typeof selected === 'string' &&
            languageValues[
              currentLanguage?.writing ? currentLanguage.writing : selected
            ]
          }
          name="writing"
          label={
            <FormattedMessage id="onboarding.form.fields.languages.writing" />
          }
          constraints={{}}
          values={values}
        />
      </div>
    </>
  );
};
