import { createAction } from 'modules/redux-store';

export const billingActions = {
  setActivePlan: (plan?: ActivePlanCached) =>
    createAction('billing/SET_ACTIVE_PLAN', plan),
  setPlans: (plans?: Plan[]) => createAction('billing/SET_PLANS', plans),
  setBillingInfo: (billingInfo?: BillingDetailsResponse) =>
    createAction('billing/SET_BILLING_INFO', billingInfo),
  setPaymentCheck: (paymentCheck?: PaymentCheckResponse) =>
    createAction('billing/SET_PAYMENT_CHECK', paymentCheck),
  setActivities: (payload: { data?: Activity[]; lastPage?: number }) =>
    createAction('billing/SET_ACTIVITIES', payload),
  setOpenings: (openings?: { value: string; label: string }[]) =>
    createAction('billing/SET_OPENINGS', openings),
  setCard: (card?: PaymentCard) => createAction('billing/SET_CARD', card),
  setInvoices: (payload: { data?: Invoice[]; lastPage?: number }) =>
    createAction('billing/SET_INVOICES', payload),
  loading: () => createAction('billing/LOADING'),
  error: (error: ApiErrorData) => createAction('billing/ERROR', error),
  billingInfoError: (error: ApiErrorData) =>
    createAction('billing/BILLING_INFO_ERROR', error),
  reset: () => createAction('billing/BILLING_RESET'),
};
