import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { useEffect, useState } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import SendIcon from 'assets/icons/sendMessage.svg';
import * as styles from 'modules/talentMessages/styles/MessageInput.styles';
import { FormError } from 'modules/app/intl';
import { useHandleAttachments } from '../hooks/useHandleAttachments';
import { MobileAttachmentInput } from './MobileAttachmentInput';
import { FileRejection } from 'react-dropzone';
import { Attachment } from './Attachment';

interface Props {
  name: string;
  label: string;
  constraints?: RegisterOptions;
  isLoading?: boolean;
  maxChar?: string;
}

export const MessageInput: React.FC<Props> = ({
  name,
  label,
  constraints,
  isLoading,
  maxChar,
  ...rest
}) => {
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>();
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const error = errors[name];

  const {
    onDrop,
    onDelete,
    handleError,
    error: attachError,
    isSizeError,
    errors: attachErrors,
    uploadedFiles,
    control: attachControl,
  } = useHandleAttachments('attachments');

  useEffect(() => {
    handleError(rejectedFiles);
  }, [rejectedFiles]);

  return (
    <>
      {Boolean(uploadedFiles.length) && (
        <div css={[styles.mobile, styles.attachments]}>
          {uploadedFiles.map((file, i) => (
            <Attachment
              key={i}
              name={file.name}
              onDelete={() => onDelete(i)}
              theme="dark"
            />
          ))}
        </div>
      )}

      <div css={styles.root}>
        <div css={styles.label}>
          <label>
            <FormattedMessage id={label} />
          </label>

          {maxChar && (
            <span>
              <FormattedMessage
                id="talentMessages.maxChar"
                values={{ max: maxChar }}
              />
            </span>
          )}
        </div>
        <div css={error ? styles.inputError : styles.inputWrapper}>
          <Controller
            name={name}
            control={control}
            rules={constraints}
            render={({ field }) => (
              <input
                autoComplete="off"
                css={styles.input}
                {...field}
                {...rest}
                type="text"
              />
            )}
          />

          <div css={styles.mobile}>
            <MobileAttachmentInput
              name="attachments"
              acceptedFiles={[
                '.docx',
                '.pdf',
                'image/jpeg',
                'image/jpg',
                'image/png',
                'image/webp',
              ]}
              maxSize={10485760}
              control={attachControl}
              onDrop={onDrop}
              setRejectedFiles={setRejectedFiles}
              isDisabled={isSizeError}
            />
          </div>
          <button type="submit" css={styles.button} disabled={isLoading}>
            <SendIcon />
          </button>
        </div>

        <div css={isSizeError ? styles.bottomLabelError : styles.bottomLabel}>
          <FormattedMessage id="talentMessages.attachmentFormats" />
        </div>

        {error && (
          <div css={styles.error}>
            <FormError name={name} errors={errors} values={{ max: 5000 }} />
          </div>
        )}

        {attachError && (
          <div css={[styles.error, styles.mobile]}>
            <FormError name={'attachments'} errors={attachErrors} />
          </div>
        )}
      </div>
    </>
  );
};
