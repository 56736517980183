import React from 'react';
import * as styles from 'modules/app/styles/TextInput.styles';
import { useFormContext, Controller } from 'react-hook-form';
import { Label } from 'modules/app';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { FormError } from 'modules/app/intl';

interface Props {
  name: string;
  defaultValue?: string | number;
  showErrorMessage?: boolean;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  customStyles?: SerializedStyles;
}

function customNumberValidation(val: string) {
  if (!val) {
    return true;
  }

  const v = parseInt(val);

  if (isNaN(v)) {
    return 'shared.errors.number';
  }

  return true;
}

export const InputNumber: React.FC<Props> = ({
  name,
  label,
  placeholder,
  defaultValue,
  isRequired,
  customStyles,
  showErrorMessage = false,
}) => {
  const intl = useIntl();
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <>
      {label && (
        <Label
          label={<FormattedMessage id={label} />}
          labelStyle
          isOptional={!isRequired}
          error={errors[name]}
        />
      )}
      <div
        css={[
          styles.root,
          customStyles ? customStyles : undefined,
          errors[name] ? styles.rootError : undefined,
        ]}
      >
        <Controller
          name={name}
          control={control}
          rules={{
            required: isRequired,
            validate: customNumberValidation,
          }}
          defaultValue={defaultValue || ''}
          render={({ field: { onChange } }) => (
            <input
              type="text"
              onChange={onChange}
              defaultValue={defaultValue || ''}
              placeholder={intl.formatMessage({ id: placeholder })}
              css={[styles.input, styles.inputFullWidth]}
            />
          )}
        />

        {showErrorMessage && <FormError errors={errors} name={name} />}
      </div>
    </>
  );
};
