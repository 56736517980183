import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints, font } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  padding: calc(var(--unit) * 15) 0 calc(var(--unit) * 20);

  @media ${breakpoints.large} {
    padding: calc(var(--unit) * 40) 0;
  }

  h3 {
    margin-bottom: calc(var(--unit) * 15);
  }
`;

export const analyticsWrapper = css`
  display: grid;
  grid-gap: ${spacing(32)};

  @media ${breakpoints.medium} {
    justify-content: left;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: ${spacing(20)};
  }
`;

export const visitsTitle = css`
  ${font.size.tiny};
  text-transform: uppercase;
  margin-bottom: calc(var(--unit) * 3);
`;

export const visitsNumber = css`
  ${font.size.large};
`;

export const skeleton = css`
  min-height: ${spacing(80)};
`;
