import { useCsrfCookie } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { billingActions } from 'modules/billing';
import { AppState } from 'modules/redux-store';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { companyDataAction } from '..';
import { CompanyProfileService } from '../http';

export const useCompanyData = (handle: string | undefined) => {
  const { getCsrf } = useCsrfCookie();
  const dispatch = useDispatch();
  const csrf = getCsrf();

  const companyRoles = useSelector(
    (state: AppState) => state.session.userCompanyRoles,
  );
  const {
    companyData: company,
    isLoading,
    error,
  } = useSelector((state: AppState) => state.companyData);

  const companyHttp = useMemo(() => new CompanyProfileService(), [csrf]);

  async function getActivePlanCached(companyId: string) {
    try {
      const { data } = await companyHttp.get<ActivePlanCached>(
        `/${companyId}/settings/company-subscription-plans/active-cached`,
      );
      dispatch(billingActions.setActivePlan(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.cause === 'active_plan_required') {
        dispatch(billingActions.setActivePlan(undefined));
        return;
      }
      dispatch(companyDataAction.error(e));
    }
  }

  async function getLatestPlan(companyId: string) {
    try {
      const { data } = await companyHttp.get<LatestPlan>(
        `/${companyId}/settings/company-subscription-plans/latest`,
      );
      dispatch(companyDataAction.setLatestPlan(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.status === 404) {
        dispatch(companyDataAction.setLatestPlan(undefined));
        return;
      }
      dispatch(companyDataAction.error(e));
    }
  }

  async function getCompanyData() {
    const companyByHandle = companyRoles?.find(
      (role) => role.handle === handle,
    );
    const companyById = companyRoles?.find((role) => role.companyId === handle);
    const companyId = companyByHandle?.companyId ?? companyById?.companyId;

    if ((company && company.id === companyId) || !companyId) return;

    try {
      const { data } = await companyHttp.get(`/${companyId}`);
      await getLatestPlan(data.id);
      await getActivePlanCached(data.id),
        dispatch(companyDataAction.companyData(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(companyDataAction.error(e));
    }
  }

  useEffect(() => {
    getCompanyData();
  }, [handle]);

  return {
    companyData: company,
    isLoading,
    error,
  };
};
