import { css } from '@emotion/react';

import backgroundImg from 'assets/images/background-sample.png';

export const root = css`
  background: url(${backgroundImg});
  background-repeat: repeat;
`;

export const content = css`
  max-width: 2400px;
  margin: 0 auto;
  background: hsl(var(--color-grayscale-1));
`;
