import React from 'react';
import { RouteComponentProps } from '@reach/router';

import * as styles from 'modules/app/styles/SuccessScreen.styles';

import { LoadingImg, Logo } from 'modules/app';

interface Props extends RouteComponentProps {
  isLoading?: boolean;
  error?: React.ReactNode;
  header: React.ReactNode;
  paragraph?: React.ReactNode;
  button: React.ReactNode;
}

export const SuccessScreen: React.FC<Props> = ({
  isLoading,
  header,
  paragraph,
  button,
  error,
}) => {
  return (
    <>
      <div css={styles.root}>
        <div css={styles.inner}>
          <Logo css={styles.logo} />

          <div css={styles.content}>
            {isLoading && (
              <div css={styles.loading}>
                <LoadingImg />
              </div>
            )}

            {!isLoading && !Boolean(error) && (
              <>
                <div>{header}</div>
                <div>{paragraph}</div>
                <div>{button}</div>
              </>
            )}

            {error && <div>{error}</div>}
          </div>
        </div>
      </div>
    </>
  );
};
