import { ActionUnion } from 'modules/redux-store';

import { loginAction } from './actions';

interface LoginReducerState {
  email: string;
  password: string;
  remember: boolean;
  is2faAuth: boolean;
  token: string;
  redirect?: string;
}

const INITIAL_STATE: LoginReducerState = {
  email: '',
  password: '',
  remember: false,
  is2faAuth: false,
  token: '',
};

export const reducer = (
  state: LoginReducerState = INITIAL_STATE,
  action: ActionUnion<typeof loginAction>,
): LoginReducerState => {
  switch (action.type) {
    case 'login/ADD_2FA_DATA':
      return {
        ...state,
        email: action.payload.email,
        password: action.payload.password,
        remember: action.payload.remember,
        token: action.payload.token,
        is2faAuth: action.payload.is2faAuth,
      };
    case 'login/LOGIN_REDIRECT':
      return {
        ...state,
        redirect: action.payload,
      };
    case 'login/RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
