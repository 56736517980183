import { navigate as gatsbyNavigate } from 'gatsby';
import { navigate as intlNavigate } from 'gatsby-plugin-intl';
import { NavigateOptions } from '@reach/router';

export function doNavigate(
  to: string,
  options?: NavigateOptions<Record<string, string>>,
) {
  if (/public-opening/.test(location.pathname)) {
    return gatsbyNavigate(
      `/${window.localStorage.getItem('gatsby-intl-language')}${to}`,
    );
  }

  if (/public-opening/.test(to)) {
    gatsbyNavigate(to);

    return;
  }

  return intlNavigate(to, options);
}
