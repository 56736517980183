import { css } from '@emotion/react';

export const labelWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  & > label {
    margin-right: calc(var(--unit) * 4);
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
  }
`;

export const isOptional = css`
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
  color: hsl(var(--color-grayscale-4));
`;

export const label = css`
  padding: 0;
  margin: 0;
  font-size: var(--font-size-small);
  color: hsl(var(--color-grayscale-6));
`;

export const labelError = css`
  ${label};
  color: hsl(var(--color-error-7));
`;
