import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { talentOnboardingAction, useReset } from 'modules/onboarding';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

export function useTalentExperience() {
  const dispatch = useDispatch();
  const { setReset } = useReset();

  const [httpError, setHttpError] = useState<HttpError>();

  const talentService = useHttpClient<Basics>('/api/v1/talent/profile');

  async function getExperienceLocally(
    pageNumber = 1,
    perPage?: number,
    sortBy?: string,
  ) {
    try {
      const {
        data: { data, page, lastPage },
      } = await talentService.get<ExperienceResponse>('/experiences', {
        params: {
          page: pageNumber,
          perPage: perPage || 15,
          sortBy: sortBy || 'employment_end_date',
        },
      });

      setHttpError(undefined);
      return { data, page, lastPage };
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  async function getExperienceDispatch(
    pageNumber: number,
    sortBy?: string,
    perPage?: number,
    load = true,
  ) {
    load && dispatch(talentOnboardingAction.loading());

    try {
      const {
        data: { data },
      } = await talentService.get<ExperienceResponse>('/experiences', {
        params: {
          page: pageNumber,
          perPage: perPage || 15,
          sortBy: sortBy || 'employment_end_date',
        },
      });

      setHttpError(undefined);
      if (!data) return;

      const experienceList: ListingData[] = data?.map((item) => ({
        title: item.jobTitle,
        content: item.companyName,
        id: item.id,
      }));

      dispatch(talentOnboardingAction.setListArray(experienceList));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(talentOnboardingAction.error(e));
    }
  }

  async function addExperience(
    experienceData: ExperienceRequest,
    isOnboarding?: boolean,
  ) {
    try {
      const { data } = await talentService.post<Experience, ExperienceRequest>(
        '/experiences',
        experienceData,
      );

      setHttpError(undefined);
      if (isOnboarding) {
        setReset();
        getExperienceDispatch(1);
      }

      return { data };
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  async function updateExperience(
    experienceData: ExperienceRequest,
    id: string,
  ) {
    try {
      const { data } = await talentService.put<Experience, ExperienceRequest>(
        `/experiences/${id}`,
        experienceData,
      );
      setHttpError(undefined);
      return { data };
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  async function deleteExperience(id: string) {
    try {
      await talentService.delete(`/experiences/${id}`);
      setHttpError(undefined);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  return {
    httpError,
    getExperienceLocally,
    getExperienceDispatch,
    addExperience,
    updateExperience,
    deleteExperience,
  };
}
