import { css } from '@emotion/react';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  width: 100%;
  max-width: calc(var(--unit) * 160);
`;

export const twoFields = css`
  @media ${breakpoints.medium} {
    display: flex;
    justify-content: space-between;
  }
`;

export const formBox = css``;

export const checkbox = css`
  display: flex;
  padding-top: calc(var(--unit) * 6);
  padding-bottom: calc(var(--unit) * 6);

  @media ${breakpoints.medium} {
    width: 40%;
    padding-bottom: 0;
  }

  > label {
    margin-left: calc(var(--unit) * 3);
  }
`;

export const deleteButtonWrapper = css`
  display: flex;
  justify-content: right;

  p {
    background-color: transparent;
    border: none;
    padding: calc(var(--unit) * 2) calc(var(--unit) * 2) calc(var(--unit) * 2)
      calc(var(--unit) * 2);
    color: hsl(var(--color-error-7));
    cursor: pointer;
  }
`;

export const httpError = css`
  margin: 0 0 calc(var(--unit) * 10) 0;
`;

export const cancelBtn = css`
  color: hsl(var(--color-error-7));
`;
