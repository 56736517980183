import React from 'react';

import * as styles from 'modules/openings/styles/DeleteModal.styles';
import { Button, Heading } from 'modules/app';
import { FormattedMessage } from 'gatsby-plugin-intl';
interface Props {
  setIsModal: (isModal: boolean) => void;
  onDelete: (companyId: string, openingId: string) => void;
  data: CompanyOpening;
}

export const DeleteModal: React.FC<Props> = ({
  setIsModal,
  onDelete,
  data,
}) => {
  const onSubmit = () => {
    onDelete(data.companyId, data.id);

    setIsModal(false);
  };

  return (
    <>
      <Heading as="h3" size="fluidXLarge">
        <FormattedMessage id="openings.overview.deleteModalTitle" />
      </Heading>
      <p css={styles.content}>
        <FormattedMessage
          id="openings.overview.deleteModalContent"
          values={{
            opening: <span>"{data.title}"</span>,
          }}
        />
      </p>
      <p css={styles.info}>
        <FormattedMessage id="openings.overview.deleteModalInfo" />
      </p>

      <div css={styles.buttonGroup}>
        <Button
          size="medium"
          buttonType="secondary"
          type="button"
          onClick={onSubmit}
        >
          <FormattedMessage id="openings.overview.deleteModalBtn" />
        </Button>
        <Button
          size="medium"
          buttonType="ghost"
          type="button"
          onClick={() => setIsModal(false)}
        >
          <FormattedMessage id="shared.cancel" />
        </Button>
      </div>
    </>
  );
};
