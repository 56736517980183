import { motion } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import { Heading, ImageOverlay, Paragraph } from 'modules/app';
import React from 'react';
import { FADE_IN_MOVE_Y_CONTROLLED, TRANSITION } from 'style/animations.config';

import * as styles from '../styles/Header.styles';

export const Header: React.FC = () => {
  return (
    <header css={styles.root}>
      <div css={styles.wrapper}>
        <div css={styles.content} role="presentation">
          <Heading
            variants={FADE_IN_MOVE_Y_CONTROLLED}
            transition={TRANSITION(0.5)}
            initial="hidden"
            animate="visible"
            useMotion
            as="h1"
            size="fluidHuge"
            css={styles.title}
          >
            <FormattedMessage id="talentsPage.header.title" />
          </Heading>

          <div>
            <Paragraph
              variants={FADE_IN_MOVE_Y_CONTROLLED}
              transition={TRANSITION(0.7)}
              initial="hidden"
              animate="visible"
              useMotion
              size="fluidLarge"
              fontFamily="primary"
              css={styles.description}
            >
              <FormattedMessage id="talentsPage.header.description" />
            </Paragraph>

            <motion.div
              variants={FADE_IN_MOVE_Y_CONTROLLED}
              transition={TRANSITION(1)}
              initial="hidden"
              animate="visible"
              css={styles.button}
            >
              <Link to="/register" css={styles.signUpBtn}>
                <span>
                  <FormattedMessage id="buttons.signUp" />
                </span>
              </Link>

              <p css={styles.card}>
                <FormattedMessage id="homepage.header.free_for_talents" />
              </p>
            </motion.div>
          </div>
        </div>
        <div css={styles.illustration}>
          <ImageOverlay background="hsl(var(--color-white-11))" type="right" />
          <StaticImage
            formats={['auto', 'webp', 'avif']}
            loading="lazy"
            css={styles.illustrationImage}
            placeholder="blurred"
            src="../../../assets/images/talent-illustration.webp"
            alt=""
            quality={100}
          />
        </div>
      </div>
    </header>
  );
};
