import { HttpError } from 'modules/app/http/client/httpError';
import { useMemo, useState } from 'react';
import { useCsrfCookie } from 'modules/app';
import { useResetReducer } from 'modules/app/auth/useResetReducers';
import { useLocation } from '@reach/router';
import { useHttpClient } from 'modules/app/http/hooks/useHttpClient';

export const useFreezeAccount = () => {
  const { search } = useLocation();

  const [error, setError] = useState<HttpError>();
  const [isFrozen, setIsFrozen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const email = useMemo(() => {
    const params = new URLSearchParams(search);

    return params.get('email');
  }, [search]);

  const { remove } = useCsrfCookie();
  const resetReducer = useResetReducer();

  const authService = useHttpClient<SessionResponse>('/auth');

  async function resetPassword() {
    if (!email) return;

    setIsResending(true);
    try {
      await authService.post('/reset', { email });
      setIsDisabled(true);
      setIsPasswordReset(true);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }
    setIsResending(false);
  }

  async function freezeAccount(token: string) {
    setIsLoading(true);

    try {
      await authService.get(`/freeze/${token}`);
      if (!email) {
        setIsFrozen(true);
        return;
      }
      await resetPassword();
      remove();
      resetReducer();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }

    setIsLoading(false);
  }

  return {
    error,
    isFrozen,
    isLoading,
    isDisabled,
    isResending,
    isPasswordReset,
    freezeAccount,
    resetPassword,
    setIsDisabled,
  };
};
