import * as styles from 'modules/admin/styles/FlatLink.styles';
import React from 'react';

interface FlatLinkProps {
  text: string;
  icon?: React.ReactNode;
  active?: boolean;
}

export const FlatLink: React.FC<FlatLinkProps> = ({ icon, text, active }) => {
  return (
    <a css={[styles.flatLink, active ? styles.flatLinkActive : null]}>
      {icon && <span css={styles.flatLinkIcon}>{icon}</span>}
      {text && <span css={styles.flatLinkText}>{text}</span>}
    </a>
  );
};
