import { useAuthDispatch, useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useMandatoryRefresh } from 'modules/auth';
import { AppState } from 'modules/redux-store';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { companyDataAction } from '../redux';

export const useImageUpload = (company?: Company) => {
  const dispatch = useDispatch();
  const { toastError } = useToastify();
  const { dispatchUpdatedUser } = useAuthDispatch();
  const { mandatoryRefresh } = useMandatoryRefresh();
  const session = useSelector((state: AppState) => state.session);

  const [error, setError] = useState<HttpError>();
  const [isLoading, setIsLoading] = useState(false);

  const http = useHttpClient('/api/v1/talent/profile');
  const companyHttp = useHttpClient('/api/v1/company');

  function handleError(e: unknown) {
    if (!(e instanceof HttpError)) return;

    if (e.cause === 'max_number_reached:company_pictures') {
      toastError('profileManagement.errors.maxNumberError');
      return;
    }
    if (e.message === 'Payload too large') {
      toastError('profileManagement.errors.fileTooBig');
      return;
    }
    if (e.message === 'Unsupported media') {
      toastError('profileManagement.errors.invalidFileFormat');
      return;
    }
    toastError();
  }

  function getFormData(blob: Blob) {
    const formData = new FormData();
    formData.append('profileImage', blob);
    return formData;
  }

  async function uploadCompanyIcon(blob: Blob) {
    if (!company) return;

    try {
      const { data } = await companyHttp.post<UploadedImages, FormData>(
        `/${company.id}/profile/icon-picture`,
        getFormData(blob),
      );
      mandatoryRefresh();
      dispatch(
        companyDataAction.companyData({ ...company, iconPicture: data }),
      );
    } catch (e) {
      handleError(e);
    }
  }

  async function deleteCompanyIcon() {
    if (!company) return;

    try {
      await companyHttp.delete(`/${company.id}/profile/icon-picture`);
      dispatch(
        companyDataAction.companyData({ ...company, iconPicture: null }),
      );
      mandatoryRefresh();
    } catch (e) {
      toastError('profileManagement.errors.deleteError');
    }
  }

  async function uploadCompanyProfile(blob: Blob) {
    if (!company) return;

    try {
      const { data } = await companyHttp.post<UploadedImages, FormData>(
        `/${company.id}/profile/profile-picture`,
        getFormData(blob),
      );
      dispatch(
        companyDataAction.companyData({ ...company, profilePicture: data }),
      );
    } catch (e) {
      handleError(e);
    }
  }

  async function deleteCompanyProfile() {
    if (!company) return;

    try {
      await companyHttp.delete(`/${company.id}/profile/profile-picture`);
      dispatch(
        companyDataAction.companyData({ ...company, profilePicture: null }),
      );
    } catch (e) {
      toastError('profileManagement.errors.deleteError');
    }
  }

  async function uploadCompanyCover(blob: Blob) {
    if (!company) return;

    try {
      const { data } = await companyHttp.post<UploadedImages, FormData>(
        `/${company.id}/profile/cover-picture`,
        getFormData(blob),
      );
      dispatch(
        companyDataAction.companyData({ ...company, coverPicture: data }),
      );
    } catch (e) {
      handleError(e);
    }
  }

  async function deleteCompanyCover() {
    if (!company) return;

    try {
      await companyHttp.delete(`/${company.id}/profile/cover-picture`);
      dispatch(
        companyDataAction.companyData({ ...company, coverPicture: null }),
      );
    } catch (e) {
      toastError('profileManagement.errors.deleteError');
    }
  }

  async function getCompanyPhotos(companyId: string, load = true) {
    load && setIsLoading(true);
    try {
      const { data } = await companyHttp.get<CompanyPictures[]>(
        `${companyId}/profile/company-pictures`,
      );
      dispatch(
        companyDataAction.updateCompanyData({
          isLoading: false,
          companyData: {
            companyPhotos: data,
          },
        }),
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function deleteCompanyAsset(
    companyId: string,
    imageId: string,
    isUpload?: boolean,
  ) {
    try {
      await companyHttp.delete(
        `/${companyId}/profile/company-pictures/${imageId}`,
      );
      if (isUpload) return;
      getCompanyPhotos(companyId, false);
    } catch (e) {
      toastError('profileManagement.errors.deleteError');
    }
  }

  async function uploadCompanyAssets(blob: Blob, imageId?: string) {
    if (!company) return;

    try {
      if (imageId) {
        await deleteCompanyAsset(company.id, imageId, true);
      }
      await companyHttp.post<UploadedImages, FormData>(
        `/${company.id}/profile/company-pictures`,
        getFormData(blob),
      );
      getCompanyPhotos(company.id, false);
    } catch (e) {
      handleError(e);
    }
  }

  async function changeProfileImage(blob: Blob) {
    try {
      const { data } = await http.post<UploadedImages, FormData>(
        '/profile-picture',
        getFormData(blob),
      );

      dispatchUpdatedUser(
        { ...session.user, profilePicture: data },
        session.userCompanyRoles,
        session.userStaffRole,
      );
    } catch (e) {
      handleError(e);
    }
  }

  async function deleteProfileImage() {
    try {
      await http.delete('/profile-picture');
      dispatchUpdatedUser(
        { ...session.user, profilePicture: null },
        session.userCompanyRoles,
        session.userStaffRole,
      );
    } catch (e) {
      toastError('profileManagement.errors.deleteError');
    }
  }

  async function changeCoverImage(blob: Blob) {
    try {
      const { data } = await http.post<UploadedImages, FormData>(
        '/cover-picture',
        getFormData(blob),
      );

      dispatchUpdatedUser(
        { ...session.user, coverPicture: data },
        session.userCompanyRoles,
        session.userStaffRole,
      );
    } catch (e) {
      handleError(e);
    }
  }

  async function deleteCoverImage() {
    try {
      await http.delete('/cover-picture');
      dispatchUpdatedUser(
        { ...session.user, coverPicture: null },
        session.userCompanyRoles,
        session.userStaffRole,
      );
    } catch (e) {
      toastError('profileManagement.errors.deleteError');
    }
  }

  return {
    error,
    isLoading,
    getCompanyPhotos,
    uploadCompanyIcon,
    uploadCompanyCover,
    uploadCompanyAssets,
    uploadCompanyProfile,
    deleteCompanyProfile,
    deleteCompanyAsset,
    deleteCompanyCover,
    deleteCompanyIcon,
    changeCoverImage,
    changeProfileImage,
    deleteCoverImage,
    deleteProfileImage,
  };
};
