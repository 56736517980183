import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { CheckboxField, Heading, TextEditor } from 'modules/app';
import React from 'react';
import * as styles from 'modules/onboarding/styles/Basics.styles';

interface Props {
  companyData: Company | undefined;
}

export const CultureOverviewInputs: React.FC<Props> = ({ companyData }) => {
  return (
    <>
      <div css={margin.bottom.xlrg}>
        <Heading as="h1" size="fluidLarge">
          <FormattedMessage id="cultureOverview.offeringHeader" />
        </Heading>
      </div>
      <div css={styles.textEditorWrapper}>
        <TextEditor
          label="cultureOverview.label"
          name="offering"
          defaultValue={companyData?.offering || undefined}
          constraints={{
            required: false,
            maxLength: 5000,
          }}
          maxChar={5000}
          placeholder="cultureOverview.offeringPlaceholder"
        />
      </div>

      <div css={[margin.bottom.xlrg, margin.top.huge]}>
        <Heading as="h1" size="fluidLarge">
          <FormattedMessage id="cultureOverview.expectationsHeader" />
        </Heading>
      </div>
      <div css={styles.textEditorWrapper}>
        <TextEditor
          label="cultureOverview.label"
          name="expectation"
          defaultValue={companyData?.expectation || undefined}
          constraints={{
            required: false,
            maxLength: 5000,
          }}
          maxChar={5000}
          placeholder="cultureOverview.expectationsPlaceholder"
        />
      </div>

      <div css={[margin.bottom.xlrg, margin.top.huge]}>
        <Heading as="h1" size="fluidLarge">
          <FormattedMessage id="cultureOverview.benefitsAndPerksHeader" />
        </Heading>
      </div>
      <div css={styles.textEditorWrapper}>
        <TextEditor
          label="cultureOverview.label"
          name="benefitsAndPerks"
          defaultValue={companyData?.benefitsAndPerks || undefined}
          constraints={{
            required: false,
            maxLength: 5000,
          }}
          maxChar={5000}
          placeholder="cultureOverview.benefitsAndPerksPlaceholder"
        />
      </div>

      <div css={[margin.bottom.xlrg, margin.top.huge]}>
        <Heading as="h1" size="fluidLarge">
          <FormattedMessage id="cultureOverview.workLifeBalanceHeader" />
        </Heading>
      </div>
      <div css={margin.bottom.med}>
        <CheckboxField
          name="flexibleWork"
          checked={companyData?.flexibleWork || false}
          constraints={{}}
        >
          <FormattedMessage id="cultureOverview.flexibleWork" />
        </CheckboxField>
      </div>
      <div css={margin.bottom.med}>
        <CheckboxField
          name="remoteWork"
          checked={companyData?.remoteWork || false}
          constraints={{}}
        >
          <FormattedMessage id="cultureOverview.remoteWork" />
        </CheckboxField>
      </div>
      <div css={margin.bottom.huge}>
        <CheckboxField
          name={'petFriendlyOffice'}
          checked={companyData?.petFriendlyOffice || false}
          constraints={{}}
        >
          <FormattedMessage id="cultureOverview.petFriendlyOffice" />
        </CheckboxField>
      </div>

      <div css={styles.textEditorWrapper}>
        <TextEditor
          label="cultureOverview.label"
          name="workLifeBalance"
          defaultValue={companyData?.workLifeBalance || undefined}
          constraints={{
            required: false,
            maxLength: 5000,
          }}
          maxChar={5000}
          placeholder="cultureOverview.workLifeBalancePlaceholder"
        />
      </div>
    </>
  );
};
