import React from 'react';
import { useLocation, useMatch } from '@reach/router';
import { Link } from 'gatsby-plugin-intl';
import ArrowIcon from 'assets/icons/DirectionRightNotClipped.svg';
import * as styles from 'modules/admin/styles/Breadcrumbs.styles';

interface Breadcrumb {
  label: string;
  link: string;
}

export const Breadcrumbs: React.FC = () => {
  const location = useLocation();

  const matchDashboard = useMatch('/:lang/admin');
  const matchUserDetails = useMatch('/:lang/admin/users/:id');
  const matchCompanyDetails = useMatch('/:lang/admin/companies/:id');
  const matchBillingActivity = useMatch('/:lang/admin/billing-activity/*');

  const pathArr = location.pathname.split('/');

  const [, , role, ...rest] = pathArr;
  const crumbs: Breadcrumb[] = [];

  rest.forEach((item) => {
    crumbs.push({
      label: item,
      link: item,
    });
  });

  if (matchDashboard) {
    crumbs.push({
      label: 'Dashboard',
      link: '',
    });
  }

  if (matchUserDetails) {
    crumbs[1].label = 'User details';
  }

  if (matchCompanyDetails) {
    crumbs[1].label = 'Company details';
  }

  if (matchBillingActivity) {
    crumbs[0].label = 'Billing activity';
  }

  const generateCrumb = (item: Breadcrumb, index: number) => {
    const basePath = `/${role}`;
    let crumbPath = '';

    for (let i = 0; i < crumbs.length; i++) {
      if (crumbs[i].link === '') {
        crumbs.splice(i, i);
      }

      if (i <= index) {
        if (crumbs[i].label === 'dashboard') {
          crumbPath += '/';
          continue;
        }

        crumbPath += '/' + crumbs[i].link;
      }
    }

    if (item.label === 'dashboard') {
      item.link = '';
    }

    if (index + 1 === crumbs.length) {
      return item.label;
    }

    if (crumbPath === '/users') {
      crumbPath += '/';
    }

    if (crumbPath === '/activity') {
      crumbPath += '/users';
    }

    if (crumbPath === '/plans') {
      crumbPath += '/regular';
    }

    return (
      <>
        <Link to={basePath + crumbPath}>{item.label}</Link>
        {index + 1 !== crumbs.length && <ArrowIcon css={styles.arrow} />}
      </>
    );
  };

  return (
    <div css={styles.breadcrumbs}>
      {crumbs.map((item, index) => {
        return <span key={index}>{generateCrumb(item, index)}</span>;
      })}
    </div>
  );
};
