import { css } from '@emotion/react';
import { font } from '@prototyp/gatsby-plugin-gumball/utils/font';

export const root = css`
  position: relative;
`;

export const placeholder = css`
  &:hover {
    cursor: pointer;
  }
`;

const tooltip = (theme: 'light' | 'dark') => css`
  position: absolute;
  width: 240px;
  max-width: 240px;
  padding: calc(var(--unit) * 4);
  background: ${theme === 'dark'
    ? 'hsl(var(--color-borderBlack-2))'
    : 'hsl(var(--color-white-11))'};
  border-radius: 3px;
  color: ${theme === 'dark'
    ? 'hsl(var(--color-grayscale-1))'
    : 'hsl(var(--color-text-1))'};
  ${font.size.tiny};
  z-index: 10;
  filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.18));
`;

export const topLeft = (theme: 'light' | 'dark') => css`
  ${tooltip(theme)};
  bottom: calc(100% + calc(var(--unit) * 5));
  right: 0;
`;

export const bottomLeft = (theme: 'light' | 'dark') => css`
  ${tooltip(theme)};
  top: calc(100% + calc(var(--unit) * 5));
  right: 0;
`;

export const topRight = (theme: 'light' | 'dark') => css`
  ${tooltip(theme)};
  bottom: calc(100% + calc(var(--unit) * 5));
  left: 0;
`;

export const bottomRight = (theme: 'light' | 'dark') => css`
  ${tooltip(theme)};
  top: calc(100% + calc(var(--unit) * 5));
  left: 0;
`;
