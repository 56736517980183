import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import Cookies from 'universal-cookie';
import { HttpError } from './httpError';

export abstract class HttpClient<Model> {
  private client: AxiosInstance;
  private headers = {
    accept: 'application/json',
    'x-csrf-token': new Cookies().get('csrfToken'),
  };

  constructor(
    private endpoint: string,
    interceptCallback?: (e: ApiErrorData) => void,
  ) {
    this.client = axios.create({
      baseURL: this.normalizeUrl(
        `${process.env.GATSBY_API_BASE_URL}/${this.endpoint}`,
      ),
      headers: this.headers,
      withCredentials: true,
    });

    this.client.interceptors.response.use(
      (response) => response,
      (error: AxiosError<ApiErrorData>) => {
        const { response } = error;
        if (!response) return error;
        interceptCallback?.(response.data);
        throw new HttpError(response.status, response.data);
      },
    );
  }

  normalizeUrl(url: string) {
    const splittedUrl = url.split('https://');
    const normalizedUrl = splittedUrl[1].replace(/([\/])\1+/g, '/');

    return `https://${normalizedUrl}`;
  }

  get<ReturnType = Model>(url: string, config?: AxiosRequestConfig) {
    return this.client.get<ReturnType>(url, config);
  }

  post<ReturnType = Model, DataType = ReturnType>(
    url: string,
    data?: DataType,
    config?: AxiosRequestConfig,
  ) {
    return this.client.post<ReturnType>(url, data, config);
  }

  put<ReturnType = Model, DataType = ReturnType>(
    url: string,
    data?: DataType,
    config?: AxiosRequestConfig,
  ) {
    return this.client.put<ReturnType>(url, data, config);
  }

  patch<ReturnType = Model, DataType = ReturnType>(
    url: string,
    data?: Partial<DataType>,
    config?: AxiosRequestConfig,
  ) {
    return this.client.patch<ReturnType>(url, data, config);
  }

  delete<ReturnType = Model>(url: string, config?: AxiosRequestConfig) {
    return this.client.delete<ReturnType>(url, config);
  }
}
