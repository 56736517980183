import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import * as styles from 'modules/admin/styles/Header.styles';
import { BarDropdown } from 'modules/app/components/profileBar/BarDropdown';
import { Breadcrumbs } from 'modules/admin/components';

export const Header: React.FC = () => {
  const { user } = useSelector((state: AppState) => state.session);

  return (
    <div css={styles.header}>
      <Breadcrumbs />

      <div css={styles.header__user}>
        <div className="header__user--name">
          {user?.firstName} {user?.lastName}
        </div>

        <BarDropdown
          firstName={user?.firstName}
          lastName={user?.lastName}
          profilePicture={user?.profilePicture?.medium}
          theme="dark"
        />
      </div>
    </div>
  );
};
