import CheckedIcon from 'assets/icons/check.svg';
import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import * as styles from 'modules/app/styles/CheckboxField.styles';

interface Props extends InputHTMLAttributes<Omit<HTMLInputElement, 'type'>> {
  name: string;
  checked: boolean;
  constraints: RegisterOptions;
  reset?: number;
  customStyles?: SerializedStyles | SerializedStyles[];
  onChecked?: (isChecked: boolean) => void;
  isSmall?: boolean;
}

export const CheckboxField: React.FC<Props> = ({
  children,
  name,
  checked,
  constraints,
  reset,
  customStyles,
  onChecked,
  isSmall,
  ...rest
}) => {
  const { control, setValue } = useFormContext();
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  useEffect(() => {
    setValue(name, isChecked, {
      shouldDirty: true,
    });
  }, [isChecked, setValue, name]);

  useEffect(() => {
    setIsChecked(checked);
    setValue(name, checked);
  }, [checked, name, setValue]);

  useEffect(() => {
    if (Number.isInteger(reset) && reset !== 0) {
      setIsChecked(false);
      setValue(name, false);
    }
  }, [reset, setIsChecked, setValue, name]);

  return (
    <div css={[styles.root, customStyles]}>
      <Controller
        name={name}
        control={control}
        rules={constraints}
        defaultValue={isChecked}
        render={({ field: { onChange } }) => (
          <input
            {...rest}
            css={styles.input}
            checked={isChecked}
            id={`checkbox-${name}`}
            type="checkbox"
            name={name}
            onChange={() => {
              onChange(!isChecked);
              onChecked?.(!isChecked);
              setIsChecked(!isChecked);
            }}
          />
        )}
      />

      <label css={styles.label} htmlFor={`checkbox-${name}`}>
        {isSmall ? (
          <span
            css={isChecked ? styles.controlCheckedSmall : styles.controlSmall}
          >
            {isChecked && <CheckedIcon />}
          </span>
        ) : (
          <span css={isChecked ? styles.controlChecked : styles.control}>
            {isChecked && <CheckedIcon />}
          </span>
        )}

        {children && (
          <span css={isSmall ? styles.contentSmall : styles.content}>
            {children}
          </span>
        )}
      </label>
    </div>
  );
};
