export function isUploadedImage(
  profileImage: unknown,
): profileImage is UploadedImages {
  return !!(
    profileImage &&
    typeof profileImage === 'object' &&
    'large' in profileImage &&
    profileImage.hasOwnProperty('large')
  );
}
