import React from 'react';
import {
  HeaderLayout,
  Heading,
  LabelTag,
  Paragraph,
  Skeleton,
} from 'modules/app';

import * as styles from 'modules/dashboard/styles/StatsOverview.styles';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';

interface Props {
  isLoading: boolean;
}

export const StatsOverview: React.FC<Props> = ({ isLoading }) => {
  const { stats } = useSelector((state: AppState) => state.companyDashboard);

  return (
    <section css={styles.root}>
      <HeaderLayout>
        {isLoading ? (
          <Skeleton
            count={4}
            containerStyles={styles.statsItemWrapper}
            customStyles={styles.skeleton}
          />
        ) : (
          <motion.div
            {...FADE_IN_MOVE_Y_REGULAR}
            transition={{ duration: 0.3 }}
          >
            <Heading as="h2" size="tiny">
              <FormattedMessage id="companyDashboard.statsOverview.header" />
            </Heading>

            <div css={styles.statsItemWrapper}>
              <div>
                <LabelTag size="medium">
                  {stats?.companyOpeningsCount.toString()}
                </LabelTag>
                <Paragraph size="base">
                  <FormattedMessage id="companyDashboard.statsOverview.desc1" />
                </Paragraph>
              </div>

              <div>
                <LabelTag size="medium">
                  {stats?.candidatesCount.toString()}
                </LabelTag>
                <Paragraph size="base">
                  <FormattedMessage id="companyDashboard.statsOverview.desc2" />
                </Paragraph>
              </div>

              <div>
                <LabelTag size="medium">
                  {stats?.applicationsCount.toString()}
                </LabelTag>
                <Paragraph size="base">
                  <FormattedMessage id="companyDashboard.statsOverview.desc3" />
                </Paragraph>
              </div>

              <div>
                <LabelTag size="medium">
                  {stats?.activeApplicationsCount.toString()}
                </LabelTag>
                <Paragraph size="base">
                  <FormattedMessage id="companyDashboard.statsOverview.desc4" />
                </Paragraph>
              </div>
            </div>
          </motion.div>
        )}
      </HeaderLayout>
    </section>
  );
};
