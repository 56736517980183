import React from 'react';
import { useMatch } from '@reach/router';
import Seo from 'modules/app/components/Seo';
import {
  ManageUsersOverview,
  CopyUsersDataOverview,
} from 'modules/admin/components';
import { RouteComponentProps } from '@reach/router';
import * as sectionStyles from '../styles/Section.styles';
import * as gridStyles from 'modules/admin/styles/Grid';
import { AdminSidebar } from './AdminSidebar';
import { usersManageRoutes } from '../consts';

export const UsersOverview: React.FC<RouteComponentProps> = () => {
  const path = useMatch('/:lang/admin/users/data-copy-requests')
    ? '/data-copy-requests'
    : '';

  return (
    <>
      <Seo title="Manage users" />
      <div css={sectionStyles.section}>
        <div css={gridStyles.subSidebarGrid}>
          <AdminSidebar routes={usersManageRoutes} />
          {path === '' ? <ManageUsersOverview /> : <CopyUsersDataOverview />}
        </div>
      </div>
    </>
  );
};
