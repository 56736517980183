import { MainContent, Skeleton } from 'modules/app';
import React from 'react';

import * as styles from 'modules/openings/styles/OpeningOverviewSkeleton.styles';
import { motion } from 'framer-motion';

interface Props {
  theme?: 'light' | 'dark';
  customStyles?: SerializedStyles;
}

export const OpeningOverviewSkeleton: React.FC<Props> = ({
  theme = 'light',
  customStyles,
}) => {
  if (theme === 'dark') {
    return (
      <motion.div
        exit={{ opacity: 0, transition: { duration: 0.3 } }}
        css={[styles.root, customStyles]}
      >
        <Skeleton count={2} containerStyles={styles.name} />
        <Skeleton count={1} customStyles={styles.stages} />
        <Skeleton count={1} customStyles={styles.actions} />
      </motion.div>
    );
  }

  return (
    <MainContent>
      <div css={[styles.root, customStyles]}>
        <Skeleton count={2} containerStyles={styles.name} />
        <Skeleton count={1} customStyles={styles.stages} />
        <Skeleton count={1} customStyles={styles.actions} />
      </div>
    </MainContent>
  );
};
