import { createAction } from 'modules/redux-store';

export const openingsActions = {
  addCurrentOpening: (payload: Opening) =>
    createAction('openings/CURRENT_OPENING', payload),
  addPublicOpening: (payload?: PublicOpening | Opening) =>
    createAction('openings/PUBLIC_OPENING', payload),
  setCompanyOpenings: (payload: {
    companyOpenings: CompanyOpening[];
    pageCount: number;
    perPage: number;
    currentPage: number;
    searchTerm?: string;
    departments?: string[];
    statuses?: string[];
    locations?: string[];
  }) => createAction('openings/SET_COMPANY_OPENINGS', payload),
  setCompanyOpeningsFilters: (payload: { filters?: CompanyOpeningFilters }) =>
    createAction('openings/SET_COMPANY_OPENINGS_FILTERS', payload),
  reset: () => createAction('openings/RESET'),
  resetFiltering: () => createAction('openings/RESET_FILTERING'),
  loading: () => createAction('openings/LOADING'),
  error: (error?: ApiErrorData) => createAction('openings/ERROR', error),
  filtersLoading: () => createAction('openings/FILTERS_LOADING'),
};
