import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';
import { UseFormSetError } from 'react-hook-form';

export const useCompanyPortfolio = () => {
  const { toastSuccess } = useToastify();

  const [pageCount, setPageCount] = useState(1);
  const [error, setError] = useState<HttpError>();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [socialMedia, setSocialMedia] = useState<CompanyPortfolio[]>();

  const http = useHttpClient('/api/v1/company');

  function onPageChange(data: { selected: number }) {
    setCurrentPage(data.selected + 1);
  }

  async function getPortfolios(companyId: string, page: number, load = true) {
    load && setIsLoading(true);
    try {
      const { data } = await http.get<CompanyPortfolioResponse>(
        `/${companyId}/profile/portfolios`,
        {
          params: {
            page,
            perPage: 15,
          },
        },
      );
      if (data.lastPage < data.page) {
        setCurrentPage(data.page - 1);
        return;
      }
      setSocialMedia(data.data);
      setPageCount(data.lastPage);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function addPortfolio(
    companyId: string,
    info: CompanyPortfolioRequest,
    callback: VoidFunction,
    formError: UseFormSetError<CompanyPortfolioRequest>,
  ) {
    setIsLoading(true);
    try {
      await http.post<CompanyPortfolio, CompanyPortfolioRequest>(
        `${companyId}/profile/portfolios`,
        info,
      );
      callback();
      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.cause === 'max_number_reached:portfolios') {
        formError('portfolioId', {
          type: 'manual',
          message: 'http_errors.max_portfolios',
        });
        return;
      }
      if (e.cause === 'item_already_exists') {
        formError('url', {
          type: 'manual',
          message: 'http_errors.item_already_exists',
        });
        return;
      }
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function removePortfolio(
    companyId: string,
    portfolioId: string,
    callback: VoidFunction,
  ) {
    setIsLoading(true);
    try {
      await http.delete(`${companyId}/profile/portfolios/${portfolioId}`);
      toastSuccess('shared.pills.successDefaultText');
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  return {
    error,
    isLoading,
    pageCount,
    currentPage,
    socialMedia,
    addPortfolio,
    onPageChange,
    getPortfolios,
    removePortfolio,
  };
};
