import { useHttpClient, useToastify } from 'modules/app';
import { useGetTeamMembers } from './useGetTeamMembers';

interface AssignMemberRole {
  companyId: string;
  companyRoleId: string;
  companyDepartmentId?: string;
  companyOpeningId?: string;
}

export const useAssingMemberRole = () => {
  const adminUsersServices = useHttpClient('/admin/users');
  const { getTeamMembers } = useGetTeamMembers();
  const { toastError, toastSuccess } = useToastify();

  async function assignMemberRole(
    userId: string,
    data: AssignMemberRole,
    handleSetUsers: (data?: Users[]) => void,
  ) {
    try {
      const {
        data: { companyId },
      } = await adminUsersServices.post<AssignMemberRole>(
        `/${userId}/roles`,
        data,
      );

      toastSuccess('Member role assigned successfully');
      getTeamMembers({ companyId }, handleSetUsers);
    } catch (e) {
      toastError();
    }
  }

  async function removeMemberRole(
    userId: string,
    companyId: string,
    handleRemoveFromList: (id: string) => void,
  ) {
    try {
      await adminUsersServices.put(`/${userId}/roles`, {
        companyId,
      });
      handleRemoveFromList(userId);
      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      toastError();
    }
  }

  return {
    assignMemberRole,
    removeMemberRole,
  };
};
