import {
  searchUsersAction,
  searchUsersDataCopyRequestAction,
} from 'modules/admin/redux';
import { useDispatch } from 'react-redux';
import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';

export const useSearchUsers = () => {
  const adminSearchUserService = useHttpClient('/admin/users');

  const dispatch = useDispatch();

  async function searchUsers(query: ManageUsersDataQuery) {
    dispatch(searchUsersAction.loading());
    try {
      const { data } = await adminSearchUserService.get<
        SearchResponse<SearchUsersResponseData>
      >('', {
        params: {
          ...query,
        },
      });
      dispatch(searchUsersAction.search(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(searchUsersAction.error(e));
    }
  }

  async function searchUsersDataCopyRequest(query: CopyUsersDataQuery) {
    dispatch(searchUsersDataCopyRequestAction.loading());

    try {
      const { data } = await adminSearchUserService.get<
        SearchResponse<CopyRequestData>
      >('/data-copy-requests', {
        params: {
          ...query,
        },
      });
      dispatch(searchUsersDataCopyRequestAction.search(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(searchUsersDataCopyRequestAction.error(e));
    }
  }

  return {
    searchUsers,
    searchUsersDataCopyRequest,
  };
};
