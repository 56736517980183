import { navigate } from 'gatsby-plugin-intl';
import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';

export const useEditOpening = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [isDraftSaving, setIsDraftSaving] = useState(false);
  const [updateError, setUpdateError] = useState<ApiErrorData>();
  const [draftState, setDraftState] = useState<
    'initial-state' | 'draft-saving' | 'draft-saved'
  >('initial-state');

  const http = useHttpClient('/api/v1/company');

  async function onDraft(
    request: OpeningRequest,
    companyData: Company,
    openingId: string,
  ) {
    request.publishedAt = null;
    setDraftState('draft-saving');
    setIsDraftSaving(true);

    try {
      await http.put<OpeningRequest>(
        `${companyData.id}/openings/${openingId}/update`,
        request,
      );
      setDraftState('draft-saved');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setUpdateError(e);
      setDraftState('initial-state');
    } finally {
      setIsDraftSaving(false);
    }
  }

  async function onUpdate(
    request: OpeningRequest,
    companyData: Company,
    openingId: string,
    currentOpening: Opening,
  ) {
    if (request.status === 'draft') {
      onDraft(request, companyData, openingId);
      return;
    }

    setIsSaving(true);

    try {
      await http.put<OpeningRequest>(
        `${companyData.id}/openings/${openingId}/update`,
        request,
      );
      if (currentOpening.status === request.status) {
        navigate(
          `/profile/company/openings/${companyData.handle}/opening/share/${openingId}`,
        );
        return;
      }
      if (!request.status) return;

      await http.put<{ status: string }>(
        `/${companyData.id}/openings/${openingId}/status`,
        { status: request.status },
      );
      navigate(
        `/profile/company/openings/${companyData.handle}/opening/share/${openingId}`,
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setUpdateError(e);
    } finally {
      setIsSaving(false);
    }
  }

  return {
    onUpdate,
    draftState,
    updateError,
    isSaving,
    isDraftSaving,
    setDraftState,
  };
};
