import { ScheduleData, StatusData } from '../components';

export function getDeactivateContent(
  openingId?: string,
  companyId?: string,
  statusData?: StatusData,
  scheduleData?: ScheduleData,
) {
  if (statusData)
    return {
      info: 'openings.deactivateLimit.infoStatus',
      value: 'openings.deactivateLimit.contentStatus',
    };

  if (scheduleData)
    return {
      info: 'openings.deactivateLimit.infoSchedule',
      value: 'openings.deactivateLimit.contentSchedule',
    };

  if (openingId && companyId)
    return {
      info: 'openings.deactivateLimit.infoDuplicate',
      value: 'openings.deactivateLimit.contentDuplicate',
    };

  return {
    info: 'openings.deactivateLimit.info',
    value: 'openings.deactivateLimit.contentCreate',
  };
}
