import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  width: 100%;
  max-width: calc(var(--unit) * 160);
`;

export const httpError = css`
  margin: 0 0 calc(var(--unit) * 10) 0;
`;

export const subgridForm = css`
  display: grid;
  column-gap: calc(var(--unit) * 8);

  @media ${breakpoints.medium} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const httpErrorFix = css`
  margin: calc(var(--unit) * 5) 0 calc(var(--unit) * 5) 0;
`;

export const cancelBtn = css`
  color: hsl(var(--color-error-7));
`;

export const subSubgridForm = css`
  display: grid;

  @media ${breakpoints.medium} {
    grid-template-columns: 2fr 1fr;

    & > div:nth-of-type(odd) {
      margin-right: 33px;
    }
  }
`;

export const establishedAtError = css`
  margin-bottom: ${spacing(40)};
`;

export const subheaderGroup = css`
  margin: calc(var(--unit) * 12) 0 calc(var(--unit) * 10) 0;
`;

export const submitBtn = css`
  margin-top: calc(var(--unit) * 6);
`;

export const resetButton = css`
  margin-bottom: calc(var(--unit) * 6);
`;

export const tosKeyTakeaway = css`
  width: 60%;
`;
