import { ActionModal, Button, Checkbox, TextArea } from 'modules/app';
import React, { useState } from 'react';
import * as styles from 'modules/settings/styles/Companies.styles';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useDeactivateCompanyPlan } from 'modules/admin/hooks';

interface Props {
  companyId: string;
  plan?: CompanyResponsePlan;
  isOpen: boolean;
  handleModalClose: VoidFunction;
  handleSetCompany: (data: CompanyResponse) => void;
}

interface FormData {
  reasonForCancelation: string;
}

export const DeactivateCompanyPlanModal: React.FC<Props> = ({
  companyId,
  plan,
  isOpen,
  handleModalClose,
  handleSetCompany,
}) => {
  const { deactivateCompanyPlan } = useDeactivateCompanyPlan();
  const [isConsentToggled, setIsConsentToggled] = useState(false);
  const form = useForm<FormData>({
    defaultValues: {
      reasonForCancelation: '',
    },
  });

  const isSubmitDisabled =
    form.watch('reasonForCancelation').length < 50 || !isConsentToggled;

  function handleToggleConsent() {
    setIsConsentToggled(!isConsentToggled);
  }

  async function handleSubmit(data: FormData) {
    if (!plan) return;

    const company = await deactivateCompanyPlan({
      companyId,
      planId: plan.planId,
      reason: data.reasonForCancelation,
    });
    company && handleSetCompany(company);
    handleModalClose();
  }

  return (
    <ActionModal isOpen={isOpen} setIsOpen={handleModalClose}>
      <h3 css={styles.modalTitle}>
        <FormattedMessage id={`admin.company.deactivatePlanModal.title`} />
      </h3>
      <div css={styles.modal}>
        <p css={styles.modalContentHighlight}>
          <FormattedMessage
            id={`admin.company.deactivatePlanModal.textHighlighted`}
          />
        </p>
        <div>
          <p css={styles.modalContent}>
            <FormattedMessage id={`admin.company.deactivatePlanModal.text`} />
          </p>
          <ul>
            <li css={styles.modalContent}>
              <FormattedMessage
                id={`admin.company.deactivatePlanModal.text.listItem.1`}
              />
            </li>

            <li css={styles.modalContent}>
              <FormattedMessage
                id={`admin.company.deactivatePlanModal.text.listItem.2`}
              />
            </li>
          </ul>
        </div>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <TextArea
              name="reasonForCancelation"
              placeholder={
                'admin.company.deactivatePlanModal.textAreaPlaceholder'
              }
              value=""
              topLabel={`admin.company.deactivatePlanModal.textAreaTopLabel`}
              altLabel="min char 50 / max char 1000"
              constraints={{
                minLength: 50,
                maxLength: 1000,
              }}
              customStyles={margin.bottom.xxxlrg}
            />
            <Checkbox
              checked={isConsentToggled}
              name={'consent'}
              onClick={handleToggleConsent}
              isSmall
            >
              <FormattedMessage id="admin.company.deactivatePlanModal.consent" />
            </Checkbox>
            <div>
              <Button
                buttonType="primary"
                size="small"
                type="submit"
                css={styles.modalDeactivateBtn}
                disabled={isSubmitDisabled}
              >
                <FormattedMessage
                  id={`admin.company.deactivatePlanModal.btn`}
                />
              </Button>

              <Button
                buttonType="ghost"
                size="small"
                type="button"
                onClick={handleModalClose}
              >
                <FormattedMessage
                  id={`admin.users.deactivateModal.buttonCancel`}
                />
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </ActionModal>
  );
};
