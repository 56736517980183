import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  HttpError,
  TalentCertificateInputs,
  useFormGuardEffect,
  useToastify,
} from 'modules/app';
import * as styles from 'modules/app/styles/GenericForm.styles';
import { useReset } from 'modules/onboarding';
import {
  useTalentCertificates,
  normalizeCertificateRequest,
} from 'modules/talents';

interface Props {
  isOnboarding?: boolean;
  setCurrentCertificates?: React.Dispatch<React.SetStateAction<Certificate[]>>;
  isProfile?: boolean;
  resetFormCounter?: () => void;
  currentPage?: number;
  setPageCount?: React.Dispatch<React.SetStateAction<number>>;
}

export const TalentCertificatesForm: React.FC<Props> = ({
  isOnboarding = false,
  setCurrentCertificates,
  isProfile,
  resetFormCounter,
  currentPage,
  setPageCount,
}) => {
  const methods = useForm<CertificateData>({
    defaultValues: {
      name: '',
      issuingOrganization: '',
      issueDate: null,
      expirationDate: null,
      credentialId: null,
      credentialUrl: null,
    },
  });
  useFormGuardEffect(methods, isOnboarding);
  const { handleSubmit, reset } = methods;

  const { toastSuccess } = useToastify();
  const { resetValue, setReset } = useReset();
  const { httpError, getCertificateLocally, addCertificate } =
    useTalentCertificates();

  const [isInRequest, setIsInRequest] = useState(false);

  const gettingCertificates = async () => {
    const response = await getCertificateLocally(currentPage);
    if (!response) return;
    const { data, lastPage } = response;

    setPageCount?.(lastPage);
    setCurrentCertificates?.(data);
  };

  const onSubmit = async (data: CertificateData) => {
    const model: CertificateRequest = normalizeCertificateRequest(data);

    reset();
    setReset();
    setIsInRequest(true);

    await addCertificate(model, isOnboarding);

    setIsInRequest(false);

    if (isProfile) {
      toastSuccess('shared.pills.successDefaultText');
      gettingCertificates();
      resetFormCounter?.();
    }
  };

  return (
    <div css={styles.root}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <HttpError styles={styles.httpErrorFix} error={httpError} />

          <TalentCertificateInputs resetValue={resetValue} />

          <Button type="submit" disabled={isInRequest} css={styles.submitBtn}>
            <FormattedMessage id="onboarding.form.fields.certificates.save_certificate" />
          </Button>
          {isProfile && resetFormCounter && (
            <Button
              css={styles.cancelBtn}
              buttonType="ghost"
              onClick={() => {
                resetFormCounter();
              }}
              disabled={isInRequest}
            >
              <FormattedMessage id="profileManagement.heading.buttons.cancel" />
            </Button>
          )}
        </form>
      </FormProvider>
    </div>
  );
};
