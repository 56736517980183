import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { InputHTMLAttributes, useState } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import * as styles from 'modules/app/styles/InputButton.styles';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { Label } from 'modules/app';

interface Props extends InputHTMLAttributes<Omit<HTMLInputElement, 'type'>> {
  data: {
    value: string;
    label: string;
  }[];
  name: string;
  label: string;
  selected: string | undefined;
  constraints?: RegisterOptions;
  onValueChange?: (selected: string | undefined) => void;
}

export const RadioButtonGroup: React.FC<Props> = ({
  name,
  label,
  selected,
  data,
  constraints,
  onValueChange,
}) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [selectedValue, setSelectedValue] = useState(selected);

  const onClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const currentValue = event.currentTarget.value;

    setValue(name, selectedValue === currentValue ? null : currentValue);
    setSelectedValue(selectedValue === currentValue ? undefined : currentValue);

    onValueChange?.(selectedValue === currentValue ? undefined : currentValue);
  };

  return (
    <>
      <Label
        label={<FormattedMessage id={label} />}
        customStyles={margin.bottom.med}
        labelStyle
        error={errors[name]}
      />

      <Controller
        name={name}
        defaultValue={selectedValue}
        control={control}
        rules={constraints}
        render={() => (
          <div css={styles.root}>
            {data.map((item, index) => {
              return (
                <div css={styles.item} key={index}>
                  <input
                    name={name}
                    type="radio"
                    css={styles.input}
                    defaultChecked={selectedValue === item.value}
                    value={item.value}
                    id={item.value}
                    onClick={onClick}
                  />
                  <label
                    htmlFor={item.value}
                    css={
                      selectedValue === item.value
                        ? styles.inputButtonChecked
                        : styles.inputButton
                    }
                  >
                    {item.label}
                  </label>
                </div>
              );
            })}
          </div>
        )}
      />
    </>
  );
};
