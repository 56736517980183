import React, { useRef, useEffect } from 'react';
import { useFormContext, RegisterOptions, Controller } from 'react-hook-form';
import { IndicatorProps, ValueType, Props as SelectProps } from 'react-select';
import * as styles from 'modules/app/styles/Select.styles';
import { FormError } from 'modules/app/intl';
import { Label } from './Label';
import Clear from 'assets/icons/clear.svg';
import CaretDown from 'assets/icons/down.svg';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { useIntl } from 'gatsby-plugin-intl';

const Select = React.lazy(() => import('react-select'));

interface Props {
  name: string;
  label: React.ReactNode;
  values: Value[];
  constraints: RegisterOptions;
  isMulti?: boolean;
  selectedValue?: Value | Value[];
  setSelectedValue?: React.Dispatch<React.SetStateAction<string | string[]>>;
  reset?: number;
  customStyles?: SerializedStyles | SerializedStyles[];
  onValueChange?: (value: string | string[]) => void;
  isOptional?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  bottomText?: React.ReactNode;
  theme?: 'dark' | 'light';
  components?: SelectProps['components'];
}

export interface Value {
  value: string;
  label: React.ReactNode | string;
}

function determineValue(value: ValueType<Value, boolean>): string | string[] {
  if (Array.isArray(value)) {
    return value.map((val: Value) => val.value);
  } else if (value) {
    return value.value;
  }

  return '';
}

export const SelectComponent: React.FC<Props> = ({
  name,
  constraints,
  label,
  values,
  selectedValue,
  isMulti,
  reset,
  customStyles,
  setSelectedValue,
  onValueChange,
  isOptional,
  isDisabled,
  placeholder,
  bottomText,
  theme = 'light',
  components = {},
}) => {
  const formRef = useRef(null);
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { formatMessage } = useIntl();

  const ClearIndicator = (props: IndicatorProps<Value, boolean>) => {
    const {
      innerProps: { ref, ...restInnerProps },
    } = props;

    return (
      <Clear
        {...restInnerProps}
        ref={ref}
        height={16}
        width={16}
        css={margin.right.med}
      />
    );
  };

  const DropdownIndicator = (props: IndicatorProps<Value, boolean>) => {
    const {
      innerProps: { ref, ...restInnerProps },
    } = props;

    return <CaretDown {...restInnerProps} ref={ref} height={7} width={12} />;
  };

  const setDefaultValue = () => {
    if (selectedValue && !Array.isArray(selectedValue)) {
      setSelectedValue?.(selectedValue.value);
      setValue(name, selectedValue.value);
    }
  };

  const getValue = (values: Value[], ctrlValue: string | string[]) => {
    if (!Array.isArray(ctrlValue)) {
      return values.find(({ value }) => value === ctrlValue);
    }

    return ctrlValue.map((value) => values.find((v) => v.value === value));
  };

  useEffect(() => {
    if (Number.isInteger(reset) && reset !== 0) {
      formRef?.current?.select.clearValue();
    }
  }, [reset]);

  useEffect(setDefaultValue, []);

  return (
    <div
      css={[
        styles.root(theme),
        customStyles ? customStyles : undefined,
        errors[name] ? styles.error : undefined,
      ]}
    >
      <Label label={label} isOptional={isOptional} />
      <React.Suspense fallback={null}>
        <Controller
          name={name}
          control={control}
          defaultValue={selectedValue}
          rules={constraints}
          render={({ field: { onChange, value: ctrlValue } }) => (
            <Select
              ref={formRef}
              onChange={(value: ValueType<Value, boolean>) => {
                const determinedValue = determineValue(value);

                onChange(determinedValue);

                setSelectedValue?.(determinedValue);
                onValueChange?.(determinedValue);
              }}
              value={getValue(values, ctrlValue)}
              defaultValue={selectedValue}
              components={{
                ClearIndicator,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                ...components,
              }}
              isMulti={isMulti}
              name={name}
              options={values}
              className="roango-multi-select"
              classNamePrefix="roango-select"
              isDisabled={isDisabled || false}
              placeholder={
                placeholder || formatMessage({ id: 'shared.select' })
              }
            />
          )}
        />
      </React.Suspense>
      {bottomText && <span css={[styles.bottomText]}>{bottomText}</span>}

      <FormError errors={errors} name={name} />
    </div>
  );
};
