import { Value } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { usePipeline } from 'modules/pipelines/hooks';
import { useDepartments } from 'modules/settings/hooks';
import { useTalentLanguage } from 'modules/talents';
import { useEffect, useState } from 'react';

export const useStartSectionData = (companyId: string | undefined) => {
  const { getLanguageConfig } = useTalentLanguage();

  const [languages, setLanguages] = useState<Value[]>([]);
  const [isLanguageLoading, setIsLanguageLoading] = useState(true);
  const { getDepartments } = useDepartments();
  const { getPipelines } = usePipeline();

  const [isDepartmentsLoading, setIsDepartmentsLoading] = useState(true);
  const [departments, setDepartments] = useState<Value[]>();

  const [isPipelinesLoading, setIsPipelinesLoading] = useState(true);
  const [pipelines, setPipelines] = useState<Value[]>();

  const [startSectionHttpError, setStartSectionHttpError] =
    useState<ApiErrorData>();

  const effectFn = () => {
    if (!companyId) return;

    getDepartments(companyId, false).then((res) => {
      setIsDepartmentsLoading(false);
      if (res instanceof HttpError || res === undefined) {
        setStartSectionHttpError(res);
        return;
      }

      const preparedData = res.map((department) => ({
        value: department.id,
        label: department.name,
      }));
      setDepartments(preparedData || []);
    });

    getLanguageConfig().then((response) => {
      setIsLanguageLoading(false);
      if (!response) return;

      if (response.error) {
        setStartSectionHttpError(response.error);
        return;
      }

      const representation = response.languages.map((language) => ({
        value: language.name,
        label: language.name,
      }));

      setLanguages(representation);
    });

    getPipelines(companyId).then((res) => {
      setIsPipelinesLoading(false);
      if (res instanceof HttpError || res === undefined) {
        setStartSectionHttpError(res);
        return;
      }

      const representation = res.data.map((pipeline) => ({
        value: pipeline.id,
        label: pipeline.name,
      }));
      setPipelines(representation);
    });
  };

  useEffect(effectFn, [companyId]);

  return {
    isDepartmentsLoading,
    isLanguageLoading,
    languages,
    departments,
    pipelines,
    isPipelinesLoading,
    startSectionHttpError,
  };
};
