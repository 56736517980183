import { ActionUnion } from 'modules/redux-store';

import { adminAction } from './actions';

interface AdminInitState {
  isLoading: boolean;
  error?: ApiErrorData;
  activeSessions?: Sessions[];
  data: {
    talents?: AdminResponse<Users>;
    users?: AdminResponse<Users>;
    sessions?: AdminResponse<Sessions>;
    admin?: AdminResponse<Users>;
    support?: AdminResponse<Users>;
    superadmin?: AdminResponse<Users>;
    companies?: AdminResponse<Companies>;
  };
}

const initalData = {
  data: [],
  lastPage: 1,
  total: 1,
  sortable: [''],
  page: 1,
  perPage: 1,
  searchable: [''],
  hideDeleted: true,
};

const INITIAL_STATE: AdminInitState = {
  isLoading: false,
  error: undefined,
  activeSessions: [],
  data: {
    talents: initalData,
    users: initalData,
    sessions: initalData,
    admin: initalData,
    support: initalData,
    companies: initalData,
  },
};

export const adminReducer = (
  state: AdminInitState = INITIAL_STATE,
  action: ActionUnion<typeof adminAction>,
): AdminInitState => {
  switch (action.type) {
    case 'admin/DATA':
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case 'admin/ACTIVE_SESSIONS': {
      return {
        ...state,
        activeSessions: action.payload,
      };
    }
    case 'admin/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case 'admin/LOADING':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case 'admin/RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
