import React from 'react';

import { motion } from 'framer-motion';
import { FADE_IN } from 'style/animations.config';
import { Section } from 'modules/profilePreview/components/Section';

const BasicsRoot = React.lazy(
  () => import('modules/profilePreview/components/basic/Root'),
);
const Experience = React.lazy(
  () => import('modules/profilePreview/components/experience/Root'),
);
const Portfolio = React.lazy(
  () => import('modules/profilePreview/components/portfolio/Root'),
);
const Projects = React.lazy(
  () => import('modules/profilePreview/components/projects/Root'),
);
const Certificates = React.lazy(
  () => import('modules/profilePreview/components/certificates/Root'),
);
const Education = React.lazy(
  () => import('modules/profilePreview/components/education/Root'),
);
const Language = React.lazy(
  () => import('modules/profilePreview/components/languages/Root'),
);

interface Props {
  previewData: TalentDataPreview | Application | GhostApplication;
  isOpeningCvPreview?: boolean;
}

function extractBasics(
  data: TalentDataPreview | Application | GhostApplication,
): PreviewBasics {
  const basics: PreviewBasics = {
    about: null,
    cityCountry: null,
    disability: null,
    dob: '',
    firstName: '',
    gender: null,
    lastName: '',
    location: null,
    phone: null,
    title: null,
    workplace: [],
    profilePicture: null,
    coverPicture: null,
    expertises: [],
    portfolios: null,
  };

  Object.keys(data)
    .filter(
      (key) =>
        key !== 'culture' &&
        key !== 'certificates' &&
        key !== 'educations' &&
        key !== 'experiences' &&
        key !== 'languages' &&
        key !== 'projects',
    )
    .forEach((key) => (basics[key] = data[key]));

  return basics;
}

export interface PreviewBox {
  isPreview: boolean | undefined;
  isGhost?: boolean;
}

export const TalentPreviewBox: React.FC<Props> = ({
  previewData,
  isOpeningCvPreview,
}) => {
  return (
    <React.Suspense fallback={null}>
      <motion.div transition={{ duration: 0.4 }} {...FADE_IN}>
        <BasicsRoot
          data={extractBasics(previewData)}
          isOpeningCvPreview={isOpeningCvPreview}
        />

        {Boolean(previewData.experiences.length) && (
          <Section theme="black">
            <Experience
              listing={previewData.experiences as Experience[]}
              isOpeningCvPreview={isOpeningCvPreview}
            />
          </Section>
        )}

        {Boolean(previewData.portfolios.length) && (
          <Section theme="white">
            <Portfolio
              listing={previewData.portfolios}
              isOpeningCvPreview={isOpeningCvPreview}
            />
          </Section>
        )}

        {Boolean(previewData.projects.length) && (
          <Section theme="gray">
            <Projects
              listing={previewData.projects as Project[]}
              isOpeningCvPreview={isOpeningCvPreview}
            />
          </Section>
        )}

        {Boolean(previewData.certificates.length) && (
          <Section theme="white">
            <Certificates
              listing={previewData.certificates as Certificate[]}
              isOpeningCvPreview={isOpeningCvPreview}
            />
          </Section>
        )}

        {Boolean(previewData.educations.length) && (
          <Section theme="gray">
            <Education
              listing={previewData.educations as Education[]}
              isOpeningCvPreview={isOpeningCvPreview}
            />
          </Section>
        )}

        {Boolean(previewData.languages.length) && (
          <Section theme="white">
            <Language
              listing={previewData.languages as Language[]}
              isOpeningCvPreview={isOpeningCvPreview}
            />
          </Section>
        )}
      </motion.div>
    </React.Suspense>
  );
};
