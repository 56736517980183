import { ActionUnion } from 'modules/redux-store';
import { searchUsersDataCopyRequestAction } from './actions';

const INITIAL_STATE: SearchUsersInitState<CopyRequestData> = {
  isLoading: false,
  error: undefined,
  data: undefined,
  page: 1,
  perPage: 10,
  total: undefined,
  lastPage: undefined,
  sortable: undefined,
  searchable: undefined,
};

export const searchUsersDataCopyRequestReducer = (
  state: SearchUsersInitState<CopyRequestData> = INITIAL_STATE,
  action: ActionUnion<typeof searchUsersDataCopyRequestAction>,
): SearchUsersInitState<CopyRequestData> => {
  switch (action.type) {
    case 'admin/SEARCH_DATA_COPY_REQUEST_USERS':
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: undefined,
      };
    case 'admin/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case 'admin/LOADING':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case 'admin/RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
