import React from 'react';
import { GenericError } from 'modules/app/intl';
import { HttpError } from 'modules/app';

interface Props {
  isFileTooBig?: boolean;
  isFileInvalidFormat?: boolean;
  httpError?: ApiErrorData;
  deleteError?: ApiErrorData;
  isMaxNumberReached?: boolean;
  isMetaImage?: boolean;
}

export const UploadError: React.FC<Props> = ({
  isFileTooBig,
  isFileInvalidFormat,
  httpError,
  deleteError,
  isMaxNumberReached,
  isMetaImage,
}) => {
  return (
    <>
      {isFileTooBig && (
        <GenericError
          message={
            isMetaImage
              ? 'openings.metaImage.fileSizeError'
              : 'profileManagement.errors.fileTooBig'
          }
        />
      )}

      {isFileInvalidFormat && (
        <GenericError message="profileManagement.errors.invalidFileFormat" />
      )}

      {deleteError && (
        <GenericError message="profileManagement.errors.deleteError" />
      )}

      {isMaxNumberReached && (
        <GenericError message="profileManagement.errors.maxNumberError" />
      )}

      <HttpError error={httpError} />
    </>
  );
};
