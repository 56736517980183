import { ActionUnion } from 'modules/redux-store';
import { plansActions } from './actions';

interface PlansInitState {
  isLoading: boolean;
  error?: ApiErrorData;
  plans?: Plan[];
}

const INITIAL_STATE: PlansInitState = {
  isLoading: false,
};

export const reducer = (
  state: PlansInitState = INITIAL_STATE,
  action: ActionUnion<typeof plansActions>,
): PlansInitState => {
  switch (action.type) {
    case 'plans/LOADING':
      return {
        ...state,
        isLoading: true,
      };

    case 'plans/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case 'plans/SET_PLANS':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        plans: action.payload,
      };

    case 'plans/PIPELINES_RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
