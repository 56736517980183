import React from 'react';
import * as styles from 'modules/admin/styles/AdminOverview.styles';
import { InvoicesTableItems } from './InvoicesTableItems';

interface Props {
  isLoading: boolean;
  invoices: InvoiceResponse[] | undefined;
  handleOpenCancelModal: (invoice: InvoiceResponse) => void;
}

export const InvoicesTable: React.FC<Props> = ({
  isLoading,
  invoices,
  handleOpenCancelModal,
}) => {
  return (
    <table>
      <thead css={styles.tableHead}>
        <tr>
          <td width="200">Invoice number</td>
          <td width="350">Company name</td>
          <td width="300">Date</td>
          <td width="300">Plan</td>
          <td width="150">Amount</td>
          <td width="150">Currency</td>
          <td width="120">Preview</td>
          <td></td>
        </tr>
      </thead>
      <tbody css={isLoading ? styles.loading : null}>
        {invoices &&
          invoices.map((invoice) => (
            <InvoicesTableItems
              key={invoice.id}
              handleOpenCancelModal={handleOpenCancelModal}
              invoice={invoice}
            />
          ))}
      </tbody>
    </table>
  );
};
