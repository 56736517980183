import React from 'react';
import { motion } from 'framer-motion';
import LoadingImage from 'assets/icons/smallLoading.svg';

import * as styles from 'modules/app/styles/Loading.styles.css';

interface Props {
  customStyles?: SerializedStyles | SerializedStyles[];
}

export const SmallLoadingImg: React.FC<Props> = ({ customStyles }) => {
  return (
    <motion.div
      css={[styles.imageRoot, customStyles]}
      animate={{
        rotateZ: [0, 360],
      }}
      transition={{ duration: 0.8, repeat: Infinity, ease: 'linear' }}
    >
      <LoadingImage />
    </motion.div>
  );
};
