import { ErrorMessage } from '@hookform/error-message';
import { PrimitiveType } from 'intl-messageformat';
import React from 'react';
import { FieldErrors } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as styles from 'modules/app/styles/TextInput.styles';

interface Props {
  errors: FieldErrors<Record<string, string>>;
  name: string;
  values?: Record<string, PrimitiveType>;
}

export const FormError: React.FC<Props> = ({ errors, name, values }) => {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => {
        return (
          <>
            {message && (
              <span css={styles.fieldError}>
                <FormattedMessage id={message} values={values} />
              </span>
            )}
          </>
        );
      }}
    />
  );
};
