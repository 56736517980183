import { useEffect, useState } from 'react';

export const useFormCounter = (nodeAdded = 0) => {
  const [formsNum, setFormsNum] = useState<number[]>([]);

  useEffect(() => {
    if (nodeAdded !== 0) {
      setFormsNum((formsNum) => {
        return [...formsNum, 0];
      });
    }
  }, [nodeAdded]);

  const resetFormCounter = () => {
    setFormsNum([]);
  };

  return { formsNum, resetFormCounter };
};
