import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { companyDataAction } from 'modules/profile/redux';
import { AppState } from 'modules/redux-store';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { billingActions } from '../redux';

export const useActivePlan = () => {
  const dispatch = useDispatch();
  const { toastSuccess, toastError } = useToastify();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiErrorData>();
  const [isInRequest, setIsInRequest] = useState(false);
  const [isPlanLoading, setIsPlanLoading] = useState(false);
  const [planError, setPlanError] = useState<ApiErrorData>();

  const { activePlan } = useSelector((state: AppState) => state.billing);

  const http = useHttpClient('/api/v1/company');

  async function getActivePlan(companyId: string) {
    setIsPlanLoading(true);

    try {
      const { data } = await http.get<ActivePlanCached>(
        `${companyId}/settings/company-subscription-plans/active-cached`,
      );
      dispatch(billingActions.setActivePlan(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.cause === 'active_plan_required') {
        dispatch(billingActions.setActivePlan());
        return;
      }
      setPlanError(error);
    } finally {
      setIsPlanLoading(false);
    }
  }

  async function cancelPlan(companyId: string, callback: VoidFunction) {
    setIsLoading(true);

    try {
      await http.post(
        `${companyId}/settings/company-subscription-plans/cancel`,
      );
      getActivePlan(companyId);
      callback();
      toastSuccess(
        'settings.billingContent.subscriptionContent.cancelSuccess',
        {
          name: activePlan?.planName,
        },
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(error);
    }

    setIsLoading(false);
  }

  async function reactivatePlan(companyId: string, callback: VoidFunction) {
    setIsLoading(true);

    try {
      await http.post(
        `${companyId}/settings/company-subscription-plans/reactivate`,
      );
      getActivePlan(companyId);
      callback();
      toastSuccess(
        'settings.billingContent.subscriptionContent.reactivateSuccess',
        { name: activePlan?.planName },
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(error);
    }

    setIsLoading(false);
  }

  async function getLatestPlan(companyId: string) {
    try {
      const { data } = await http.get<LatestPlan>(
        `/${companyId}/settings/company-subscription-plans/latest`,
      );
      dispatch(companyDataAction.setLatestPlan(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(companyDataAction.setLatestPlan());
    }
  }

  async function startFreeTrial(companyId: string) {
    setIsInRequest(true);

    try {
      await http.post(`/${companyId}/settings/activate-free-trial`);
      getActivePlan(companyId);
      getLatestPlan(companyId);
      toastSuccess(
        'settings.billingContent.subscriptionContent.freeTrialActivated',
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      toastError();
    }

    setIsInRequest(false);
  }

  return {
    error,
    planError,
    isLoading,
    isInRequest,
    isPlanLoading,
    cancelPlan,
    getActivePlan,
    getLatestPlan,
    startFreeTrial,
    reactivatePlan,
  };
};
