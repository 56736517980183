import { RegisterOptions } from 'react-hook-form';
import { isMaxEditorChars, isMinEditorChars } from 'modules/app/static';
import { isValidationRuleNumber } from './isValidationRuleNumber';

export function addLengthConstraints(constraints: RegisterOptions) {
  let newValidate = {};

  if (typeof constraints.validate === 'object') {
    newValidate = { ...constraints.validate };
  } else if (typeof constraints.validate === 'function') {
    newValidate = {
      customValidateFn: constraints.validate,
    };
  }

  const constructData = [
    { prop: 'minLength', fnName: 'minLengthValidation', errorProp: 'min' },
    { prop: 'maxLength', fnName: 'maxLengthValidation', errorProp: 'max' },
  ];

  for (const data of constructData) {
    let length = 0;
    let message = `shared.errors.${data.errorProp}`;

    // for some reason, typescript does not accept isValidationRuleNumber() as
    // a valid typecheck so this must be done
    if (
      Number.isInteger(constraints[data.prop]) &&
      isValidationRuleNumber(constraints[data.prop])
    ) {
      length = constraints[data.prop];
    } else if (
      typeof constraints[data.prop] === 'object' &&
      typeof constraints[data.prop].value === 'number'
    ) {
      length = constraints[data.prop].value;
      message = constraints[data.prop].message;
    }

    if (constraints[data.prop]) {
      newValidate[data.fnName] = (text: string) => {
        if (!text) {
          text = '[{"type":"paragraph","children":[{"text":""}]}]';
        }
        if (typeof text !== 'string') {
          text = JSON.stringify(text);
        }

        if (data.prop === 'maxLength') {
          if (isMaxEditorChars(text, length)) {
            return message;
          }
        }

        if (data.prop === 'minLength') {
          if (isMinEditorChars(text, length)) {
            return message;
          }
        }

        return true;
      };
    }

    delete constraints[data.prop];
  }

  constraints.validate = newValidate;
}
