import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils/breakpoints';
import { font } from '@prototyp/gatsby-plugin-gumball/utils/font';
import * as btn from 'modules/app/styles/Button.styles';

export const root = css`
  padding: ${spacing(60)} 0;
  background: hsl(var(--color-white-11));
  border-bottom: 1px solid hsl(var(--color-borderUltraLight-9));

  > div {
    display: flex;
    align-items: center;

    @media ${breakpoints.smallMax} {
      justify-content: space-between;
    }
  }

  label {
    ${font.size.tiny};
    margin-right: ${spacing(12)};
    letter-spacing: 1.25px;
  }
`;

export const link = css`
  ${btn.root};
  ${btn.outline};
  ${btn.tiny};
`;
