import { useCallback } from 'react';
import { useAdminSessions, useGetAdminUsers, useGetAdminCompanies } from '.';

export const useGetData = (
  type?: string,
  currentPage?: number,
  itemsPerPage?: number,
  searchTerm?: string,
) => {
  const { getAdminSessions } = useAdminSessions();
  const { getAdminUsers } = useGetAdminUsers();
  const { getAdminCompanies } = useGetAdminCompanies();

  const getDataFn = ({
    dataType = type,
    page = currentPage,
    perPage = itemsPerPage,
    search = searchTerm,
  }) => {
    if (!dataType) {
      return;
    }

    switch (dataType) {
      case 'sessions':
        getAdminSessions({ page: page });
        break;
      case 'talents':
        getAdminUsers({
          page: page,
          perPage: perPage || 10,
          hideStaff: true,
          search: search,
        });
        break;
      case 'users':
        getAdminUsers({ page: page, perPage: perPage || 10, hideStaff: false });
        break;
      case 'admin':
        getAdminUsers({
          page: page,
          perPage: perPage || 10,
          staffRoleId: 'Admin',
        });
        break;
      case 'superadmin':
        getAdminUsers({
          page: page,
          perPage: perPage || 10,
          staffRoleId: 'SuperAdmin',
        });
        break;
      case 'support':
        getAdminUsers({
          page: page,
          perPage: perPage || 10,
          staffRoleId: 'Support',
        });
        break;
      case 'companies':
        getAdminCompanies({
          page: page || 1,
          search: search || '',
          sortBy: 'registered_at',
          sort: 'DESC',
        });
        break;
      case 'all':
        getAdminUsers({ hideStaff: true });
        getAdminUsers({ hideStaff: false });
        getAdminUsers({ staffRoleId: 'Admin' });
        getAdminUsers({ staffRoleId: 'Support' });
        getAdminSessions({ page: 1 });
        getAdminCompanies({
          page: 1,
          search: '',
          sortBy: 'registered_at',
          sort: 'DESC',
        });
        break;
      default:
        return;
    }
  };
  return {
    getData: useCallback(getDataFn, []),
  };
};
