import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useMoreFormsGuard } from './useMoreFormsGuard';

export function useFormGuardEffect<Model>(
  methods: UseFormReturn<Model, unknown>,
  isOnboarding?: boolean,
) {
  const { handleAddForm, handleAddFormDirtyFields } =
    useMoreFormsGuard<Model>();
  const { formState } = methods;

  useEffect(() => {
    if (isOnboarding) return;

    handleAddForm(methods);
  }, [methods, isOnboarding]);

  useEffect(() => {
    if (isOnboarding) return;

    handleAddFormDirtyFields(
      formState.dirtyFields,
      formState.isSubmitSuccessful,
    );
  }, [formState.isDirty, formState.isSubmitSuccessful, isOnboarding]);
}
