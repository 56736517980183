export function normalizeNewCompanyFormData(data: CompanyBasicsRequest) {
  return {
    handle: data.handle,
    name: data.name,
    website: `https://${data.website}`,
    country: data.country,
    establishedAt: `${data.establishedAt}-01-01`,
    representativeFirstName: data.representativeFirstName,
    representativeLastName: data.representativeLastName,
    representativeEmail: data.representativeEmail,
    representativePhone: data.representativePhone
      ? data.representativePhone.toLowerCase().replace(/\s+/g, '')
      : null,
  };
}
