import { companyOnboardingAction } from './../../redux/actions';
import { useHttpClient } from 'modules/app';
import { AppState } from 'modules/redux-store';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HttpError } from 'modules/app/http/client/httpError';
import { useCompanyId } from '../useCompanyId';

export const useGetCompanyData = () => {
  const dispatch = useDispatch();
  const { userCompanyRoles } = useSelector((state: AppState) => state.session);
  const { companyData, countries } = useSelector(
    (state: AppState) => state.companyOnboarding,
  );

  const companyId = useCompanyId();
  const http = useHttpClient('/api/v1/company');
  const assetsHttp = useHttpClient('/api/v1/assets');

  async function getOnboardingCompany() {
    if (!Boolean(userCompanyRoles?.length) || !companyId) return;

    try {
      const { data } = await http.get<Company>(`/${companyId}`);
      dispatch(companyOnboardingAction.setCompanyData(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(companyOnboardingAction.error(e));
    }
  }

  async function getCountries(load = true) {
    load && dispatch(companyOnboardingAction.loading());
    try {
      const { data } = await assetsHttp.get<CompanyConfig>('/companies');
      const countriesValues = data.countries.map((country) => ({
        value: country.code,
        label: country.name,
      }));
      dispatch(companyOnboardingAction.setCountries(countriesValues));
      return;
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(companyOnboardingAction.error(e));
    }
  }

  async function getCompanyData() {
    if (countries && companyData) return;

    if (!countries && !companyData) {
      getOnboardingCompany();
      getCountries(false);
      return;
    }

    if (countries) {
      getOnboardingCompany();
      return;
    }

    getCountries();
  }

  useEffect(() => {
    getCompanyData();
  }, [companyId]);
};
