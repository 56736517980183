import { useState } from 'react';

export const useReset = () => {
  const [counter, setCounter] = useState<number>(0);

  return {
    resetValue: counter,
    setReset: () => {
      setCounter((counter) => ++counter);
    },
  };
};
