import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints, font } from '@prototyp/gatsby-plugin-gumball/utils';

export const container = (theme: 'light' | 'dark') => css`
  height: calc(var(--unit) * 10);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme === 'dark'
    ? 'hsl(var(--color-borderBlack-2))'
    : 'hsl(var(--color-white-11))'};
  padding: 0 calc(var(--unit) * 4) 0 calc(var(--unit) * 5);
  border-radius: 3px;
  margin-top: ${spacing(16)};
  min-width: 180px;

  @media ${breakpoints.medium} {
    min-width: 304px;
    margin-top: 0;
  }
  & > svg {
    fill: ${theme === 'dark'
      ? 'hsl(var(--color-textLight-7))'
      : 'hsl(var(--color-text-1))'};
  }
`;

export const input = (theme: 'light' | 'dark') => css`
  background: none;
  ${font.size.base};
  ${font.weight.bold};
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 10px 0;
  color: ${theme === 'dark'
    ? 'hsl(var(--color-grayscale-1))'
    : 'hsl(var(--color-text-1))'};

  &::placeholder {
    ${font.size.small};
  }
`;
