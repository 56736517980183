import { css } from '@emotion/react';
import { breakpoints } from 'style/variables';

export const gridWrapper = css`
  max-width: 1312px;
  margin: 0 auto;
  padding: 0 var(--spacing-xlrg);
  box-sizing: content-box;
`;

export const gridHeader = css`
  display: grid;
  row-gap: calc(var(--unit) * 20);

  @media ${breakpoints.medium} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, auto);
    column-gap: var(--spacing-xxxlrg);
    row-gap: var(--spacing-xlrg);
  }
`;

export const gridBanner = css`
  ${gridWrapper};

  display: grid;
  row-gap: var(--spacing-xlrg);

  @media ${breakpoints.medium} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--spacing-xxxlrg);
  }
`;

export const gridCTA = css`
  ${gridWrapper}

  display: grid;
  row-gap: var(--spacing-xlrg);

  @media ${breakpoints.medium} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, auto);
    column-gap: var(--spacing-xxxlrg);
    row-gap: var(--spacing-xhuge);
  }
`;

export const gridFeatures = css`
  display: grid;
  row-gap: var(--spacing-xhuge);

  @media ${breakpoints.medium} {
    grid-template-columns: repeat(2, 1fr);

    .features-list__wrapper {
      grid-column: 1 / -1;
    }

    .feature-list-item {
      grid-column-start: span 1;
      grid-column-end: span 1;
    }
  }

  @media ${breakpoints.large} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--spacing-xxxlrg);
    row-gap: var(--spacing-huge);

    .features-list__heading {
      grid-column: 1 / span 6;
    }

    .feature-list-item {
      grid-column-start: span 3;
      grid-column-end: span 3;
    }
  }
`;
