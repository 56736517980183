import { useIntl } from 'gatsby-plugin-intl';

type StatusActions = Exclude<
  PublishStatus,
  | 'draft'
  | 'scheduled-for-publishing-(internal)'
  | 'scheduled-for-publishing-(public)'
>;

export const useOpeningValues = () => {
  const intl = useIntl();

  const statusValues: { value: PublishStatus; label: string }[] = [
    {
      value: 'draft',
      label: intl.formatMessage({
        id: 'openings.status.draft',
      }),
    },
    {
      value: 'scheduled-for-publishing-(internal)',
      label: intl.formatMessage({
        id: 'openings.status.scheduledForPublishingInternal',
      }),
    },
    {
      value: 'scheduled-for-publishing-(public)',
      label: intl.formatMessage({
        id: 'openings.status.scheduledForPublishingPublic',
      }),
    },
    {
      value: 'published-internal',
      label: intl.formatMessage({
        id: 'openings.status.publishedInternal',
      }),
    },
    {
      value: 'published-public',
      label: intl.formatMessage({
        id: 'openings.status.publishedPublic',
      }),
    },
    {
      value: 'closed',
      label: intl.formatMessage({
        id: 'openings.status.closed',
      }),
    },
    {
      value: 'archived',
      label: intl.formatMessage({
        id: 'openings.status.archived',
      }),
    },
    {
      value: 'deactivated',
      label: intl.formatMessage({
        id: 'openings.status.deactivated',
      }),
    },
  ];

  const statusActions: {
    value: StatusActions;
    label: string;
  }[] = [
    {
      value: 'published-internal',
      label: intl.formatMessage({
        id: 'openings.status.actions.publishInternal',
      }),
    },
    {
      value: 'published-public',
      label: intl.formatMessage({
        id: 'openings.status.actions.publishPublic',
      }),
    },
    {
      value: 'closed',
      label: intl.formatMessage({
        id: 'openings.status.actions.close',
      }),
    },
    {
      value: 'archived',
      label: intl.formatMessage({
        id: 'openings.status.actions.archive',
      }),
    },
  ];

  const statusColor = {
    ['draft']: {
      color: 'hsl(var(--color-grayscale-1))',
      backgroundColor: 'hsl(var(--color-text-1))',
      activeBackgroundColor: 'hsl(var(--color-text-2))',
    },
    ['scheduled-for-publishing-(internal)']: {
      backgroundColor: ' hsl(var(--color-note-8))',
      activeBackgroundColor: 'hsl(var(--color-note-7));',
    },
    ['scheduled-for-publishing-(public)']: {
      backgroundColor: ' hsl(var(--color-note-8))',
      activeBackgroundColor: 'hsl(var(--color-note-7));',
    },
    ['published-internal']: {
      backgroundColor: ' hsl(var(--color-success-9));',
      activeBackgroundColor: 'hsl(var(--color-success-8));',
    },
    ['published-public']: {
      backgroundColor: ' hsl(var(--color-success-9));',
      activeBackgroundColor: 'hsl(var(--color-success-8));',
    },
    ['closed']: {
      backgroundColor: ' hsl(var(--color-grayscale-2))',
      activeBackgroundColor: 'hsl(var(--color-grayscale-3));',
    },
    ['archived']: {
      backgroundColor: ' hsl(var(--color-grayscale-2))',
      activeBackgroundColor: 'hsl(var(--color-grayscale-3));',
    },
    ['deactivated']: {
      backgroundColor: ' hsl(var(--color-grayscale-2))',
      activeBackgroundColor: 'hsl(var(--color-grayscale-3));',
    },
  };

  const statusSelectValues = (data: CompanyOpening) => {
    const isScheduled =
      data.status === 'scheduled-for-publishing-(internal)' ||
      data.status === 'scheduled-for-publishing-(public)';

    const actionItems = statusActions.map((item) => {
      const isDisabled =
        data.status === item.value ||
        (data.status === 'draft' && item.value === 'archived') ||
        (isScheduled && item.value === 'archived');

      return {
        id: item.value,
        element: item.label,
        disabled: isDisabled,
      };
    });

    return actionItems;
  };

  return {
    statusValues,
    statusActions,
    statusColor,
    statusSelectValues,
  };
};
