import React, { useEffect } from 'react';

import * as styles from 'modules/talentOpenings/styles/Matches.styles';
import { LayoutGroup, motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import {
  HeaderLayout,
  Heading,
  HttpError,
  Loading,
  OpeningBox,
  Slider,
} from 'modules/app';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { useTalentOpenings } from '../hooks';

export const Matches: React.FC = () => {
  const { getTalentMatches } = useTalentOpenings();
  const { isMatchingLoading, matchingError, matches } = useSelector(
    (state: AppState) => state.talentOpenings,
  );

  useEffect(() => {
    getTalentMatches();
  }, []);

  return (
    <LayoutGroup>
      <motion.div layout css={styles.root}>
        <Loading
          isLoading={isMatchingLoading}
          component={
            !matchingError && matches ? (
              <motion.div
                {...FADE_IN_MOVE_Y_REGULAR}
                transition={{ duration: 0.4, delay: 0.4 }}
              >
                <HeaderLayout>
                  <div css={styles.title}>
                    <Heading as="h1" size="fluidXxLarge">
                      <FormattedMessage id="talentMatching.title" />
                    </Heading>
                    <div css={styles.info}>
                      <div css={styles.infoNumber}>
                        <p>{matches.length.toString()}</p>
                        <p>
                          <FormattedMessage id="talentMatching.matches" />
                        </p>
                      </div>
                      <p css={styles.infoDescription}>
                        <FormattedMessage id="talentMatching.content" />
                      </p>
                    </div>
                  </div>
                </HeaderLayout>

                {Boolean(matches.length) && (
                  <Slider>
                    {matches.map((opening, i) => (
                      <OpeningBox
                        key={i}
                        opening={opening}
                        theme="dark"
                        customStyles={styles.opening}
                      />
                    ))}
                  </Slider>
                )}
              </motion.div>
            ) : (
              <HeaderLayout>
                <HttpError error={matchingError} />
              </HeaderLayout>
            )
          }
        />
      </motion.div>
    </LayoutGroup>
  );
};
