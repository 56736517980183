import { HttpError, MainContent } from 'modules/app';
import { NotFound } from 'modules/notFound';
import React from 'react';

interface Props {
  error: ApiErrorData;
}

export const SSRError: React.FC<Props> = ({ error }) => {
  if (error.status === 410) {
    return (
      <MainContent>
        <NotFound type="contentOnlyCenter" />
      </MainContent>
    );
  }

  if (error.status === 404) {
    return (
      <MainContent>
        <NotFound type="contentOnlyCenter" />
      </MainContent>
    );
  }

  return (
    <MainContent>
      <HttpError error={error} />
    </MainContent>
  );
};
