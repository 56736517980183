export const useSocialScriptLoader = () => {
  return (type: 'twitter' | 'facebook' | 'linkedin', callback: () => void) => {
    if (type === 'linkedin') {
      callback();

      return;
    }
    const existingScript = document.getElementById(type);

    if (!existingScript) {
      const sources = {
        twitter: 'https://platform.twitter.com/widgets.js',
        facebook:
          'https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v11.0',
      };

      const script = document.createElement('script');
      script.src = sources[type];
      script.type = 'text/javascript';
      script.id = type;
      script.async = true;

      if (type === 'facebook') {
        script.crossOrigin = 'anonymous';
        script.nonce = '16SopaeY';
      }

      if (type === 'twitter') {
        script.charset = 'utf-8';
      }

      document.body.appendChild(script);

      script.onload = () => {
        callback();
      };
    } else {
      callback();
    }
  };
};
