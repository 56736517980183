import React from 'react';
import * as styles from 'modules/app/styles/Sidebar.styles';

export const Sidebar: React.FC = ({ children, ...rest }) => {
  return (
    <section css={styles.root} {...rest}>
      {children}
    </section>
  );
};
