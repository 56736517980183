import { FormattedMessage } from 'gatsby-plugin-intl';
import { InputField, SelectComponent, TextArea, Value } from 'modules/app';
import React from 'react';
import * as styles from 'modules/app/styles/GenericForm.styles';

const range: Value[] = (function () {
  const date = new Date();
  const start = date.getFullYear();
  const nums: Value[] = [];

  for (let i = start; i >= 1970; i--) {
    nums.push({
      value: '' + i,
      label: '' + i,
    });
  }

  return nums;
})();

export const transformYear = (
  value: string | undefined | null,
  selectValues: Value[],
): Value | undefined => {
  const valueLabel = selectValues.filter((val) => {
    return val.value === value;
  });
  if (value && selectValues && valueLabel.length !== 0) {
    return {
      value: value,
      label: valueLabel[0].label,
    };
  }
  return undefined;
};

interface Props {
  currentEducation?: Education;
  resetValue: number;
  startYear: string;
}

export const TalentEducationInputs: React.FC<Props> = ({
  currentEducation,
  resetValue,
  startYear,
}) => {
  return (
    <>
      <InputField
        type="text"
        name="educationalInstitution"
        value={currentEducation?.educationalInstitution || ''}
        constraints={{
          required: true,
          maxLength: {
            value: 150,
            message: 'shared.errors.max',
          },
        }}
        reset={resetValue}
        label="onboarding.form.fields.education.educational_institution_label"
      />

      <div css={styles.subgridForm}>
        <SelectComponent
          name="startYear"
          reset={resetValue}
          label={
            <FormattedMessage id="onboarding.form.fields.education.first_year_label" />
          }
          values={range}
          constraints={{
            required: true,
          }}
          selectedValue={transformYear(
            currentEducation &&
              currentEducation.startYear &&
              new Date(currentEducation?.startYear).getFullYear().toString(),
            range,
          )}
        />

        <SelectComponent
          isOptional={true}
          name="endYear"
          reset={resetValue}
          label={
            <FormattedMessage id="onboarding.form.fields.education.final_year_label" />
          }
          values={range}
          constraints={{
            validate: (year: string) => {
              if (!year && !startYear) {
                return true;
              }

              if (year) {
                return parseInt(year) < parseInt(startYear)
                  ? 'onboarding.form.errors.startYear'
                  : true;
              }

              return true;
            },
          }}
          selectedValue={transformYear(
            currentEducation &&
              currentEducation.endYear &&
              new Date(currentEducation?.endYear).getFullYear().toString(),
            range,
          )}
        />
      </div>

      <InputField
        isOptional={true}
        type="text"
        name="fieldOfStudy"
        value={currentEducation?.fieldOfStudy || ''}
        reset={resetValue}
        constraints={{
          maxLength: {
            value: 150,
            message: 'shared.errors.max',
          },
        }}
        label="onboarding.form.fields.education.field_of_study_label"
      />

      <InputField
        isOptional={true}
        type="text"
        name="degree"
        value={currentEducation?.degree || ''}
        reset={resetValue}
        constraints={{
          maxLength: {
            value: 150,
            message: 'shared.errors.max',
          },
        }}
        label="onboarding.form.fields.education.degree_label"
      />

      <TextArea
        isOptional={true}
        reset={resetValue}
        name="associations"
        constraints={{
          maxLength: {
            value: 255,
            message: 'shared.errors.max',
          },
        }}
        placeholder="onboarding.form.fields.education.associations_placeholder"
        topLabel="onboarding.form.fields.education.associations_label"
        value={currentEducation?.associations || ''}
      />

      <TextArea
        isOptional={true}
        reset={resetValue}
        name="activities"
        constraints={{
          maxLength: {
            value: 255,
            message: 'shared.errors.max',
          },
        }}
        placeholder="onboarding.form.fields.education.activities_placeholder"
        topLabel="onboarding.form.fields.education.activities_label"
        value={currentEducation?.activities || ''}
      />
    </>
  );
};
