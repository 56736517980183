import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface Props {
  classList?: string[];
}

export const BaseCookieConsent: React.FC<Props> = ({ children }) => {
  const [el, setEl] = useState<HTMLDivElement>();

  useEffect(() => {
    if (document.getElementById('cookieConsentModal')) {
      return;
    }

    if (!el) {
      const main = document.getElementsByTagName('main');
      setEl(main[0]);
    }
  }, [el]);

  if (!el || document.getElementById('cookieConsentModal')) {
    return null;
  }

  return ReactDOM.createPortal(children, el);
};
