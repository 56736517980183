import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { billingActions } from '../redux';
import { useActivePlan } from './useActivePlan';

interface RequestData {
  subscriptionPlanId: string;
}

export const useDowngradePlan = () => {
  const dispatch = useDispatch();
  const { toastSuccess } = useToastify();
  const { getActivePlan } = useActivePlan();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiErrorData>();

  const http = useHttpClient('/api/v1/company/');

  async function downgradePlan(
    companyId: string,
    planId: string,
    callback: VoidFunction,
  ) {
    setIsLoading(true);
    setError(undefined);

    try {
      await http.post<RequestData>(
        `${companyId}/settings/company-subscription-plans/downgrade`,
        { subscriptionPlanId: planId },
      );
      callback();
      getActivePlan(companyId);
      toastSuccess('settings.billingContent.changePlan.downgradeSuccess');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }

    setIsLoading(false);
  }

  async function getOpenings(companyId: string) {
    try {
      const { data } = await http.get<OpeningsList[]>(
        `${companyId}/openings/short-list`,
      );
      dispatch(
        billingActions.setOpenings(
          data.map((opening) => ({ value: opening.id, label: opening.title })),
        ),
      );
    } catch (e) {}
  }

  async function archiveOpening(companyId: string, openingId: string) {
    try {
      await http.put(`${companyId}/openings/${openingId}/archive`);
      return;
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      return e;
    }
  }

  async function archiveOpenings(companyId: string, openings: string[]) {
    setIsLoading(true);

    const response = await Promise.all(
      openings.map((openingId) => archiveOpening(companyId, openingId)),
    );
    const error = response.find((res) => res !== undefined);

    await getOpenings(companyId);

    if (error) {
      setError(error);
      setIsLoading(false);
      return;
    }

    getActivePlan(companyId);
    toastSuccess('settings.billingContent.changePlan.archiveSuccess');
    setIsLoading(false);
  }

  return {
    error,
    isLoading,
    getOpenings,
    downgradePlan,
    archiveOpenings,
  };
};
