import { createAction } from 'modules/redux-store';

export const settingsAction = {
  companyUsers: (payload?: CompanyUser[]) =>
    createAction('settings/COMPANY_USERS', payload),

  companyId: (payload: string) => createAction('settings/COMPANY_ID', payload),

  addDepartments: (payload: Department[]) =>
    createAction('settings/ADD_DEPARTMENTS', payload),

  addArchivedDepartments: (payload: Department[]) =>
    createAction('settings/ADD_ARCHIVED_DEPARTMENTS', payload),

  addSingleDepartment: (payload: Department) =>
    createAction('settings/ADD_SINGLE_DEPARTMENT', payload),

  updateSingleDepartment: (payload: Department) =>
    createAction('settings/UPDATE_SINGLE_DEPARTMENT', payload),

  deactivateCompany: () => createAction('settings/DEACTIVATE_COMPANY'),

  currentCompanyRole: (payload: UserCompanyRoles) =>
    createAction('settings/CURRENT_COMPANY_ROLE', payload),

  loading: () => createAction('settings/LOADING'),
  error: (payload: ApiErrorData) => createAction('settings/ERROR', payload),

  setReasons: (payload: {
    reasons?: Reason[];
    page: number;
    lastPage: number;
  }) => createAction('settings/SET_REASONS', payload),

  reset: () => createAction('settings/SETTINGS_RESET'),
};
