import { css } from '@emotion/react';

export const root = css`
  position: fixed;
  right: 40px;
  bottom: 45px;
  z-index: 10;
  min-width: 60px;
  min-height: 30px;
`;

export const button = css`
  all: initial;
  cursor: pointer;
  box-shadow: 0 5px 4px 0 rgb(0 0 0 / 26%);
  border-radius: 34px 8px 34px 34px;

  svg {
    width: 60px;
    height: 60px;
  }
`;
