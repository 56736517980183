import React from 'react';

import * as styles from 'modules/talentMessages/styles/MessageList.styles';
import { Avatar, LoadingImg } from 'modules/app';
import { format } from 'date-fns';
import { useGetDay } from '../hooks';
import { Attachment } from './Attachment';

interface Props {
  type: 'talent' | 'company';
  isFirstDay: boolean;
  message: ConversationMessage;
  user: ConversationUser;
}

export const MessageListItem: React.FC<Props> = ({
  type,
  isFirstDay,
  message,
  user,
}) => {
  const sentAt = message.isLoading
    ? null
    : format(new Date(message.createdAt + 'Z'), 'h:mm a');
  const day = useGetDay(message.createdAt);
  const talentAvatarStyles = user.profile_picture
    ? styles.talentAvatar
    : styles.talentAvatarNoImage;
  const companyAvatarStyles = user.profile_picture
    ? styles.companyAvatar
    : styles.companyAvatarNoImage;

  return (
    <div css={styles.itemRoot}>
      {isFirstDay && <p css={styles.day}>{day}</p>}

      <div css={type === 'talent' ? styles.item : styles.companyItem}>
        <Avatar
          name={user.firstName}
          imagePath={user.profile_picture?.large}
          lastName={user.lastName}
          isShadow={false}
          customStyles={
            type === 'talent' ? talentAvatarStyles : companyAvatarStyles
          }
        />
        <div css={type === 'talent' ? styles.message : styles.companyMessage}>
          <p>{message.body}</p>
          {Boolean(message.attachments.length) && (
            <div css={styles.attachments}>
              {message.attachments?.map((attachment, i) => (
                <Attachment
                  key={i}
                  name={attachment.fileName}
                  path={attachment.data}
                  isSending={message.isLoading}
                />
              ))}
            </div>
          )}

          {!message.isLoading && <span css={styles.sentAt}>{sentAt}</span>}

          {message.isLoading && (
            <div css={styles.loader}>
              <LoadingImg />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
