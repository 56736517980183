import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import * as styles from 'modules/app/styles/AccountQuery.styles';
import { HighlightedLink } from './HighlightedLink';
import { useLocation } from '@reach/router';

type QueryType = 'register' | 'login';

interface Props {
  type: QueryType;
  companyInviteToken?: string;
  companyClaimToken?: string;
}

export const AccountQuery: React.FC<Props> = ({
  type,
  companyInviteToken,
  companyClaimToken,
}) => {
  const { search } = useLocation();
  const path = useMemo(() => {
    const base = type === 'login' ? 'register' : 'login';

    if (companyClaimToken)
      return `/${base}/claim/${companyClaimToken}${search}`;

    if (companyInviteToken) return `/${base}/invite/${companyInviteToken}`;

    return `/${base}`;
  }, [type]);

  return (
    <div css={styles.root}>
      <p css={styles.paragraph}>
        <FormattedMessage id={`${type}.page.account.text`} />
      </p>

      {companyClaimToken && (
        <HighlightedLink to={path} label={`${type}.page.account.link_text`} />
      )}

      {companyInviteToken && (
        <HighlightedLink to={path} label={`${type}.page.account.link_text`} />
      )}

      {!companyClaimToken && !companyInviteToken && (
        <HighlightedLink to={path} label={`${type}.page.account.link_text`} />
      )}
    </div>
  );
};
