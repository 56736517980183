import Cookies from 'universal-cookie';
import { useCallback } from 'react';

export const useCsrfCookie = () => {
  const setCsrf = useCallback((csrf: string, expiresAt: Date) => {
    const cookie = new Cookies();

    cookie.set('csrfToken', csrf, {
      path: '/',
      expires: expiresAt,
      secure: true,
    });
  }, []);

  const remove = useCallback(() => {
    const cookie = new Cookies();

    cookie.remove('csrfToken', {
      path: '/',
    });
  }, []);

  const getCsrf = useCallback(() => {
    return new Cookies().get('csrfToken');
  }, []);

  const isLoggedIn = useCallback(() => {
    return Boolean(new Cookies().get('csrfToken'));
  }, []);

  return {
    setCsrf,
    remove,
    getCsrf,
    isLoggedIn,
  };
};
