import { createAction } from 'modules/redux-store';

export const faqAction = {
  saveData: (data: { name?: string; email: string }) =>
    createAction('faq/SAVE_DATA', data),

  nextStep: (step: number) => createAction('faq/NEXT_STEP', step),

  reset: () => createAction('faq/RESET'),
};
