import { Button } from 'modules/app';
import React from 'react';
import * as styles from '../styles/Table.styles';

interface Props {
  headers?: ({ id: string; title: string; width: string } | null)[];
  onSort?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const AdminTable: React.FC<Props> = ({ headers, children, onSort }) => {
  return (
    <table css={styles.table}>
      {headers && (
        <colgroup>
          {headers.map((item) => {
            return item && <col key={item.id} width={item.width} />;
          })}
        </colgroup>
      )}
      <thead css={styles.head}>
        <tr>
          {headers?.map((item, index) => {
            return (
              item && (
                <th key={index}>
                  <Button onClick={onSort} buttonType="ghost" css={styles.head}>
                    {item.title}
                  </Button>
                </th>
              )
            );
          })}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};
