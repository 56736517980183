import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';

export const section = css`
  max-width: ${spacing(1400)};
  min-height: ${spacing(200)};
  margin: ${spacing(97)} 0 0;
  grid-column: 2;
`;

export const users = css`
  grid-column: 2;
`;

export const sectionNarrow = css`
  max-width: ${spacing(704)};
  margin: ${spacing(168)} ${spacing(32)} ${spacing(98)} ${spacing(256)};
`;

export const sectionHead = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${spacing(32)} ${spacing(32)} ${spacing(84)};
`;

export const sectionHeadTitle = css`
  h1 {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-large);
    margin: 0;
    padding: ${spacing(20)} 0 ${spacing(20)};
    position: relative;

    &:after {
      background: hsl(var(--color-borderBlack-2));
      content: '';
      width: ${spacing(40)};
      height: ${spacing(2)};
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
`;

export const sectionBody = css`
  padding: 0 ${spacing(32)} ${spacing(84)};
`;
