import { useIntl } from 'gatsby-plugin-intl';
import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { companyDataAction } from '../redux';

export interface LegalData {
  representativeFirstName: string;
  representativeLastName: string;
  representativeEmail: string;
  representativePhone: string | null;
}

export const useCompanyEdit = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { toastError, toastSuccess } = useToastify();

  const [error, setError] = useState<HttpError>();
  const [isLoading, setIsLoading] = useState(false);
  const [expertise, setExpertise] = useState<CompanyExpertiseResponse>();

  const http = useHttpClient('/api/v1/company');

  function onSuccess(data: Company, callback?: (data: Company) => void) {
    toastSuccess('shared.pills.successDefaultText');
    dispatch(companyDataAction.companyData(data));
    callback?.(data);
  }

  async function updateCareerPath(
    companyId: string,
    info: CareerPathRequest,
    callback: (data: Company) => void,
  ) {
    setIsLoading(true);
    try {
      const { data } = await http.put<Company, CareerPathRequest>(
        `${companyId}/profile/career-path`,
        info,
      );
      onSuccess(data, callback);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function updateCultureOverview(
    companyId: string,
    info: CultureOverviewRequest,
    onError: UseFormSetError<CultureOverviewRequest>,
    callback: (data: Company) => void,
  ) {
    setError(undefined);
    setIsLoading(true);
    try {
      const { data } = await http.put<Company, CultureOverviewRequest>(
        `${companyId}/profile/careers`,
        info,
      );
      onSuccess(data, callback);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e?.data?.errors) {
        e.data.errors.benefitsAndPerks &&
          onError('benefitsAndPerks', {
            type: 'validate',
          });
        e.data.errors.expectation &&
          onError('expectation', {
            type: 'validate',
          });
        e.data.errors.offering &&
          onError('offering', {
            type: 'validate',
          });
        e.data.errors.workLifeBalance &&
          onError('workLifeBalance', {
            type: 'validate',
          });
        return;
      }
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function getExpertise(companyId: string, load = true) {
    load && setIsLoading(true);
    try {
      const { data } = await http.get<CompanyExpertiseResponse>(
        `${companyId}/profile/expertise`,
      );
      setExpertise(data);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function addExpertise(
    companyId: string,
    info: string[],
    callback: (companyId: string) => void,
  ) {
    try {
      await http.post(`${companyId}/profile/expertise`, { names: info });
      callback(companyId);
    } catch (e) {}
  }

  async function removeExpertise(companyId: string, expertiseId: string) {
    try {
      await http.delete(`${companyId}/profile/expertise/${expertiseId}`);
      getExpertise(companyId, false);
    } catch (e) {
      toastError();
    }
  }

  async function updateLegal(
    companyId: string,
    info: CompanyBasicsRequest,
    onError: UseFormSetError<LegalData>,
    callback: (data: Company) => void,
  ) {
    setIsLoading(true);
    try {
      const { data } = await http.put<Company, CompanyBasicsRequest>(
        `/${companyId}/profile`,
        info,
      );
      onSuccess(data, callback);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.data?.errors.representativePhone || e.data?.errors.phone) {
        onError('representativePhone', {
          type: 'invalidPhone',
          message: formatMessage({
            id: 'onboarding.form.errors.phone',
          }),
        });
        return;
      }
      if (e.data?.errors.representativeEmail) {
        onError('representativeEmail', {
          type: 'validate',
          message: formatMessage({
            id: 'onboarding.form.errors.representative_email',
          }),
        });
        return;
      }
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function updateMissionVision(
    companyId: string,
    info: CompanyMissionRequest,
    callback: (data: Company) => void,
  ) {
    setIsLoading(true);
    try {
      const { data } = await http.put<Company, CompanyMissionRequest>(
        `/${companyId}/profile/additional-info`,
        info,
      );
      onSuccess(data, callback);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function updateDescription(
    companyId: string,
    info: CompanyDescriptionRequest,
    onError: UseFormSetError<CompanyDescriptionRequest>,
    callback: (data: Company) => void,
  ) {
    try {
      const { data } = await http.put<Company, CompanyDescriptionRequest>(
        `${companyId}/profile/details`,
        info,
      );
      onSuccess(data, callback);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.data?.errors.longDescription) {
        onError('longDescription', {
          type: 'validate',
        });
        return;
      }
      setError(e);
    }
  }

  async function updateTeamBonding(
    companyId: string,
    info: TeamBondingRequest,
  ) {
    setIsLoading(true);
    try {
      const { data } = await http.put<Company, TeamBondingRequest>(
        `${companyId}/profile/team-bonding`,
        info,
      );
      onSuccess(data);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function updateTraining(
    companyId: string,
    info: TrainingAndDevelopmentRequest,
    onError: UseFormSetError<TrainingAndDevelopmentRequest>,
    callback: (data: Company) => void,
  ) {
    setIsLoading(true);
    try {
      const { data } = await http.put<Company, TrainingAndDevelopmentRequest>(
        `${companyId}/profile/employee-programs`,
        info,
      );
      onSuccess(data, callback);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.data?.errors.continuousLearning) {
        onError('continuousLearning', {
          type: 'validate',
        });
        return;
      }
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  return {
    error,
    isLoading,
    expertise,
    updateLegal,
    addExpertise,
    getExpertise,
    setExpertise,
    updateTraining,
    removeExpertise,
    updateCareerPath,
    updateDescription,
    updateTeamBonding,
    updateMissionVision,
    updateCultureOverview,
  };
};
