import React from 'react';
import { InputField, emailRegex } from 'modules/app';

import * as styles from 'modules/app/styles/GenericForm.styles';

interface Props {
  company?: Company;
}

export const CompanyLegalInputs: React.FC<Props> = ({ company }) => {
  return (
    <div css={styles.subgridForm}>
      <InputField
        label="onboarding.form.fields.information.first_name"
        name="representativeFirstName"
        value={company?.representativeFirstName || ''}
        constraints={{
          required: true,
          maxLength: 50,
          minLength: 2,
        }}
      />
      <InputField
        label="onboarding.form.fields.information.last_name"
        name="representativeLastName"
        value={company?.representativeLastName || ''}
        constraints={{
          required: true,
          maxLength: 50,
          minLength: 2,
        }}
      />
      <InputField
        label="onboarding.form.fields.information.email"
        name="representativeEmail"
        value={company?.representativeEmail || ''}
        constraints={{
          required: true,
          maxLength: 255,
          pattern: {
            value: emailRegex,
            message: 'register.errors.email_invalid',
          },
        }}
      />

      <InputField
        label="onboarding.form.fields.information.contact_number"
        name="representativePhone"
        value={company?.representativePhone || ''}
        constraints={{
          maxLength: 50,
          minLength: 6,
          validate: (phone: string) => {
            if (!phone) {
              return true;
            }

            return /^^[+][-\s\./0-9]*$$/.test(phone)
              ? true
              : 'onboarding.form.errors.phone';
          },
        }}
        isOptional={true}
      />
    </div>
  );
};
