import { Heading, Paragraph, useOnScrollAnimation } from 'modules/app';
import React from 'react';
import { gridBanner as grid } from '../styles/Grid.styles';
import { FormattedMessage } from 'gatsby-plugin-intl';

import * as styles from '../styles/Banner.styles';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_CONTROLLED, TRANSITION } from 'style/animations.config';

export const Banner: React.FC = () => {
  const banner = useOnScrollAnimation();

  return (
    <article css={styles.banner}>
      <motion.div
        ref={banner.ref}
        initial="hidden"
        animate={banner.animation}
        css={grid}
        role="presentation"
      >
        <Heading
          as="h2"
          size="xxLarge"
          css={styles.title}
          useMotion
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={TRANSITION(0.4)}
        >
          <FormattedMessage id="talentsPage.banner.title" />
        </Heading>

        <Paragraph
          css={styles.description}
          size="fluidLarge"
          fontFamily="primary"
          useMotion
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={TRANSITION(0.8)}
        >
          <FormattedMessage id="talentsPage.banner.description" />
        </Paragraph>
      </motion.div>
    </article>
  );
};
