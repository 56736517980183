import { ActionUnion } from 'modules/redux-store';
import { openingsActions } from './actions';

interface OpeningInitialState {
  currentOpening?: Opening;
  publicOpening?: PublicOpening | Opening;
  companyOpenings?: CompanyOpening[];
  filters?: CompanyOpeningFilters;
  isLoading: boolean;
  error?: ApiErrorData;
  pageCount: number;
  perPage: number;
  currentPage: number;
  searchTerm?: string;
  departments?: string[];
  statuses?: string[];
  locations?: string[];
  isFiltersLoading: boolean;
}

const INITIAL_STATE: OpeningInitialState = {
  isLoading: false,
  error: undefined,
  currentOpening: undefined,
  companyOpenings: undefined,
  filters: undefined,
  pageCount: 1,
  perPage: 4,
  currentPage: 1,
  searchTerm: undefined,
  departments: undefined,
  statuses: undefined,
  locations: undefined,
  isFiltersLoading: false,
};

export const openingsReducer = (
  state: OpeningInitialState = INITIAL_STATE,
  action: ActionUnion<typeof openingsActions>,
): OpeningInitialState => {
  switch (action.type) {
    case 'openings/LOADING':
      return {
        ...state,
        error: undefined,
        isLoading: true,
      };
    case 'openings/CURRENT_OPENING':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentOpening: action.payload,
      };
    case 'openings/PUBLIC_OPENING':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        publicOpening: action.payload,
      };
    case 'openings/SET_COMPANY_OPENINGS':
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: undefined,
      };
    case 'openings/SET_COMPANY_OPENINGS_FILTERS':
      return {
        ...state,
        ...action.payload,
        error: undefined,
        isFiltersLoading: false,
      };
    case 'openings/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        isFiltersLoading: false,
      };
    case 'openings/FILTERS_LOADING':
      return {
        ...state,
        isFiltersLoading: true,
        error: undefined,
      };
    case 'openings/RESET_FILTERING':
      return {
        ...state,
        statuses: [''],
        departments: [''],
        locations: [''],
      };
    case 'openings/RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
