import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, HttpError, useFormGuardEffect } from 'modules/app';
import { FormattedMessage } from 'react-intl';
import { useOnboardingLocations, useReset } from 'modules/onboarding';
import * as styles from 'modules/app/styles/LocationForm.styles';
import { CompanyLocationInputs } from './inputs';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { useCompanyLocations } from 'modules/profile/hooks';

interface Props {
  isOnboarding?: boolean;
  onFormReset?: VoidFunction;
  onAddLocation?: VoidFunction;
}

export interface LocationFormData {
  location: SelectedPlace | undefined;
  isHeadquarters: boolean;
  additionalInfo: string;
}

export const CompanyLocationForm: React.FC<Props> = ({
  isOnboarding = false,
  onFormReset,
  onAddLocation,
}) => {
  const { isLoading, error, addLocation } = useCompanyLocations();
  const { error: httpError, addLocation: addOnboardingLocation } =
    useOnboardingLocations();

  const methods = useForm<LocationFormData>({
    defaultValues: {
      location: undefined,
      isHeadquarters: false,
      additionalInfo: '',
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;
  useFormGuardEffect(methods, isOnboarding);

  const formRef = useRef<HTMLFormElement>(null);
  const { resetValue, setReset } = useReset();
  const { companyData } = useSelector((state: AppState) => state.companyData);

  const [selectedLocation, setSelectedLocation] = useState<SelectedPlace>();

  useEffect(() => {
    if (Boolean(Object.keys(errors).length)) {
      formRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errors]);

  function onboardingCallback() {
    reset();
    setReset();
    setSelectedLocation(undefined);
  }

  async function onSubmit(data: LocationFormData) {
    if (!selectedLocation) return;

    const model = {
      address: selectedLocation.cityCountry,
      location: selectedLocation.location,
      additionalInfo: data.additionalInfo,
      isHeadquarters: data.isHeadquarters,
    };

    if (isOnboarding) {
      addOnboardingLocation(model, onboardingCallback);
      return;
    }

    if (!companyData?.id || !onAddLocation) return;

    addLocation(companyData.id, model, onAddLocation);
  }

  return (
    <div css={styles.root}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          {(error || httpError) && (
            <HttpError styles={styles.httpError} error={error || httpError} />
          )}

          <CompanyLocationInputs
            resetValue={resetValue}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
          />

          <Button disabled={isLoading} type="submit" css={margin.top.xxlrg}>
            <FormattedMessage id="onboarding.form.fields.companyLocation.saveLocationButton" />
          </Button>

          {!isOnboarding && onFormReset && (
            <Button
              css={styles.cancelBtn}
              buttonType="ghost"
              onClick={onFormReset}
              disabled={isLoading}
            >
              <FormattedMessage id="profileManagement.heading.buttons.cancel" />
            </Button>
          )}
        </form>
      </FormProvider>
    </div>
  );
};
