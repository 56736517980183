import React from 'react';
import { SerializedStyles } from '@emotion/react';
import * as styles from 'modules/admin/styles/Section.styles';

interface Props {
  customStyles?: SerializedStyles | SerializedStyles[];
}

export const Section: React.FC<Props> = ({ children, customStyles }) => {
  return <div css={[styles.section, customStyles]}>{children}</div>;
};
