import { useCsrfCookie } from 'modules/app/auth';
import { useResetReducer } from 'modules/app/auth/useResetReducers';
import { useMemo } from 'react';

import { HttpClient } from '../client/http';
import { HttpError } from '../client/httpError';

export function useHttpClient<T>(url: string) {
  const resetReducer = useResetReducer();
  const { remove, getCsrf } = useCsrfCookie();

  const csrf = getCsrf();

  function logout(e: HttpError) {
    if (
      e.cause === 'authentication_error_session_expired' ||
      e.cause === 'authentication_failed_no_valid_session_id' ||
      e.cause === 'authentication_failed_no_session_found'
    ) {
      resetReducer();
      remove();

      return;
    }
  }

  class ModuleService extends HttpClient<T> {
    constructor() {
      super(url, logout);
    }
  }

  return useMemo(() => new ModuleService(), [csrf]);
}
