import { createAction } from 'modules/redux-store/index';

export const authenticationAction = {
  setAuthSession: (payload: LoggedInSession) =>
    createAction('authentication/SET_SESSION', payload),

  update2fa: (payload: { is2fa: boolean }) =>
    createAction('authentication/UPDATE_2FA', payload),

  logout: () => createAction('authentication/LOGOUT'),

  loading: () => createAction('authentication/LOADING'),
};
