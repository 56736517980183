import React from 'react';

import * as styles from 'modules/app/styles/ListUL.styles';

export const ListUL: React.FC = ({ children, ...rest }) => {
  return (
    <ul css={styles.root} {...rest}>
      {children}
    </ul>
  );
};
