import { useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useHttpClient } from 'modules/app/http/hooks/useHttpClient';
import { useDispatch } from 'react-redux';
import { applicantsActions } from '../redux';

export function useApplicants() {
  const dispatch = useDispatch();
  const applicantService =
    useHttpClient<SearchResponse<Applicant>>('/admin/applicants');
  const openingService = useHttpClient('/api/v1/company');
  const { toastSuccess, toastError } = useToastify();

  const companyId =
    process.env.NODE_ENV === 'production'
      ? 'dffd17ec-470d-47f5-81a4-6bae03859279'
      : '1816b404-c239-4cff-b9be-776029196963';

  async function getApplicants(query: ApplicantsQuery) {
    dispatch(applicantsActions.loading());
    try {
      const response = await applicantService.get('', {
        params: {
          ...query,
          companyId,
        },
      });

      dispatch(applicantsActions.setApplicants(response.data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(applicantsActions.error(e));
    }
  }

  async function getOpenings() {
    if (!companyId.length) return;

    try {
      const { data } = await openingService.get<OpeningsList[]>(
        `/${companyId}/openings/short-list`,
      );
      dispatch(applicantsActions.setOpenings(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(applicantsActions.error(e));
    }
  }

  async function exportApplicants(openingIds: string[]) {
    if (!openingIds.length) return;

    try {
      await applicantService.post('/export', {
        companyId,
        openingIds,
      });
      toastSuccess('admin.applicants.applicantsExport.success');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(applicantsActions.error(e));
      toastError();
    }
  }

  return {
    getApplicants,
    getOpenings,
    exportApplicants,
  };
}
