import { useLocation } from '@reach/router';
import { Link } from 'gatsby-plugin-intl';
import React from 'react';

interface Props {
  id?: string;
  path: string;
  text: React.ReactNode;
  onClick?: VoidFunction;
  checkPartial?: boolean;
}

export const NavLink: React.FC<Props> = ({
  id,
  path,
  text,
  children,
  onClick,
  checkPartial,
}) => {
  const { pathname } = useLocation();
  const isActive = (isCurrent: boolean, isPartiallyCurrent: boolean) =>
    isCurrent ||
    (checkPartial && isPartiallyCurrent) ||
    (id && pathname.includes(id));

  return (
    <Link
      getProps={({ isCurrent, isPartiallyCurrent }) => {
        return {
          className: isActive(isCurrent, isPartiallyCurrent)
            ? 'link--active'
            : 'link',
        };
      }}
      to={path}
      onClick={onClick}
    >
      {text}
      {children}
    </Link>
  );
};
