import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, authenticationAction } from 'modules/redux-store';
import { useCsrfCookie } from 'modules/app';
import { addYears } from 'date-fns';
import { HttpError } from 'modules/app/http/client/httpError';
import { useResetReducer } from 'modules/app/auth/useResetReducers';
import { useHttpClient } from 'modules/app/http/hooks/useHttpClient';

export const useRefreshInterval = () => {
  const dispatch = useDispatch();
  const { setCsrf, isLoggedIn, remove } = useCsrfCookie();
  const resetReducer = useResetReducer();

  const authService = useHttpClient<SessionResponse>('/auth');

  const sessionExpiresAt = useSelector((state: AppState) => {
    if (typeof state.session.tokenExpiresAt === 'string') {
      return new Date(state.session.tokenExpiresAt);
    }

    return state.session.tokenExpiresAt;
  });

  async function expires() {
    try {
      const {
        data: { user, userSession, userCompanyRoles, userStaffRole },
      } = await authService.post('/refresh');

      const expiresAt = userSession.expiresAt
        ? new Date(userSession.expiresAt + 'Z')
        : new Date(addYears(new Date(), 20));
      remove();
      setCsrf(userSession.csrf, new Date(expiresAt));

      user.authType = userSession.authType;

      dispatch(
        authenticationAction.setAuthSession({
          user,
          userCompanyRoles,
          tokenExpiresAt: new Date(expiresAt),
          userStaffRole,
        }),
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.status === 404 || e.cause === 'authentication_error_invalid_csrf') {
        return;
      }
      remove();
      resetReducer();
    }
  }

  const expiresCallback = useCallback(expires, [sessionExpiresAt]);

  useEffect(() => {
    if (!(sessionExpiresAt instanceof Date) || !isLoggedIn()) return;

    const interval = setInterval(expiresCallback, 600000);

    return () => {
      clearInterval(interval);
    };
  }, [expiresCallback, sessionExpiresAt, isLoggedIn]);
};
