import { GetActivity } from '../hooks';

export function normalizeActivityParams(params: GetActivity) {
  if (params.verb === 'none' || !Boolean(params.verb?.length)) {
    delete params.verb;
    return params;
  }

  return params;
}
