export function normalizeCertificateRequest(
  data: CertificateData,
): CertificateRequest {
  return {
    name: data.name,
    issuingOrganization: data.issuingOrganization,
    issueDate: data.issueDate
      ? `${data.issueDate.getFullYear()}-${
          data.issueDate.getMonth() + 1
        }-${data.issueDate.getDate()}`
      : '',
    expirationDate: data.expirationDate
      ? `${data.expirationDate.getFullYear()}-${
          data.expirationDate.getMonth() + 1
        }-${data.expirationDate.getDate()}`
      : null,
    credentialId: data.credentialId ? data.credentialId : null,
    credentialUrl: data.credentialUrl ? data.credentialUrl : null,
  };
}
