import React from 'react';
import { SectionHead } from '../SectionHead';
import { DropdownMenu, SmallLoadingImg, TextInput } from 'modules/app';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { flex, margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { FlatLink } from '../FlatLink';
import SearchIcon from 'assets/icons/search.svg';
import CalendarIcon from 'assets/icons/calendar.svg';
import * as styles from 'modules/admin/styles/AdminOverview.styles';
import DatePickerButton from 'modules/app/components/form/DatePickerButton';
import { useActivityLog } from 'modules/admin/hooks';

interface Props {
  matchCompanies: boolean;
  isLoading: boolean;
  page: number;
  handlePageReset: VoidFunction;
}

export const ActivityLogHeader: React.FC<Props> = ({
  matchCompanies,
  isLoading,
  page,
  handlePageReset,
}) => {
  const {
    title,
    actionType,
    actionTypes,
    startDate,
    endDate,
    onSearch,
    onSelectAction,
    handleStartDateChange,
    handleEndDateChange,
  } = useActivityLog({ page, handlePageReset });

  return (
    <SectionHead title={title}>
      {!matchCompanies && (
        <TextInput
          autoFocus
          icon={isLoading ? <SmallLoadingImg /> : <SearchIcon />}
          css={styles.search}
          placeholder={'Search by username, press enter to run.'}
          onKeyUp={(
            e: React.KeyboardEvent<HTMLInputElement> &
              React.ChangeEvent<HTMLInputElement>,
          ) => onSearch(e)}
        />
      )}
      <div css={[flex.display.flex, flex.align.center, margin.bottom.lrg]}>
        <DropdownMenu
          onSelect={onSelectAction}
          placeholder={
            <div>
              Action: <strong>{actionType || 'none'}</strong>
            </div>
          }
          hideCaret={false}
          items={actionTypes.map((item) => ({
            id: item.value,
            element: (
              <FlatLink
                text={item.label}
                active={Boolean(actionType === item.value)}
              />
            ),
          }))}
          position="bottomRight"
          customButtonStyles={styles.sortByDropdown}
        />
      </div>
      <div css={styles.datePickers}>
        <DatePickerButton
          label={
            <FormattedMessage id="admin.users.copy-profile-data.startDate" />
          }
          isDisabled={false}
          dateString={startDate}
          maxDate={new Date(endDate)}
          buttonIcon={<CalendarIcon />}
          theme={'light'}
          onSelect={handleStartDateChange}
          showTime={false}
        />
        <DatePickerButton
          label={
            <FormattedMessage id="admin.users.copy-profile-data.endDate" />
          }
          isDisabled={false}
          dateString={endDate}
          buttonIcon={<CalendarIcon />}
          theme={'light'}
          onSelect={handleEndDateChange}
          showTime={false}
          minDate={new Date(startDate)}
        />
      </div>
    </SectionHead>
  );
};
