import React from 'react';
import * as detailStyles from 'modules/admin/styles/Details.styles';
import { StatusPill } from 'modules/admin/components';
import { checkSessionStatus, formatDate } from 'modules/admin/static';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { Link } from 'gatsby-plugin-intl';

export const UserBasics: React.FC<GetUserDetailsResponseData> = ({
  email,
  createdAt,
  emailVerifiedAt,
  companyRoles,
  newsletter,
  userSessionLoggedInIp,
  userSessionExpiresAt,
  userSessionAuthType,
}) => {
  return (
    <motion.div transition={{ duration: 0.3 }} {...FADE_IN_MOVE_Y_REGULAR}>
      <table css={detailStyles.table}>
        <tbody>
          <tr>
            <td>
              <span css={detailStyles.tableLabel}>Email</span>
            </td>
            <td>
              <span css={detailStyles.flex}>
                <strong css={detailStyles.flex}>{email}</strong>
                <StatusPill
                  pillStatus={!!emailVerifiedAt}
                  statusTrueMessage="Verified"
                  statusFalseMessage="Unverified"
                />
              </span>
            </td>
          </tr>

          <tr>
            <td>
              <span css={detailStyles.tableLabel}>Registered</span>
            </td>
            <td>
              <strong>{formatDate(createdAt, 'PP, HH:mm')}</strong>
              <span css={detailStyles.textFaded}>
                IP: {userSessionLoggedInIp}
              </span>
            </td>
          </tr>

          <tr>
            <td>
              <span css={detailStyles.tableLabel}>Auth type</span>
            </td>
            <td>
              <strong>{userSessionAuthType}</strong>
            </td>
          </tr>

          <tr>
            <td>
              <span css={detailStyles.tableLabel}>Related to</span>
            </td>
            <td>
              {Boolean(!companyRoles.length) && '/'}
              {companyRoles.map((company, i) => (
                <span key={i}>
                  <Link
                    to={`/admin/companies/${company.companyId}`}
                    css={detailStyles.company}
                    key={i}
                  >
                    {company.companyName} (
                    {company.roleId.charAt(0).toUpperCase() +
                      company.roleId.slice(1)}
                    )
                  </Link>

                  {companyRoles.length === i + 1 ? ' ' : ', '}
                </span>
              ))}
            </td>
          </tr>

          <tr>
            <td>
              <span css={detailStyles.tableLabel}>Newsletter</span>
            </td>
            <td>
              <StatusPill
                pillStatus={newsletter}
                statusTrueMessage="Signed up"
                statusFalseMessage="Unsigned"
              />
            </td>
          </tr>

          <tr>
            <td>
              <span css={detailStyles.tableLabel}>Session</span>
            </td>
            <td>
              <StatusPill
                pillStatus={Boolean(checkSessionStatus(userSessionExpiresAt))}
                statusTrueMessage="Active"
                statusFalseMessage="Inactive"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </motion.div>
  );
};
