import React, { useEffect, useState } from 'react';
import Seo from 'modules/app/components/Seo';
import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import {
  useImpersonate,
  useResetAdminPassword,
  useAdminUser,
} from 'modules/admin/hooks';
import { useCheckStaffRole } from 'modules/admin/static';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import {
  Avatar,
  Button,
  Loading,
  Pill,
  SmallLoadingImg,
  Tag,
} from 'modules/app';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import * as userItemStyles from 'modules/admin/styles/UserItem.styles';
import * as detailStyles from 'modules/admin/styles/Details.styles';
import * as sectionStyles from 'modules/admin/styles/Section.styles';
import PersonIcon from 'assets/icons/person.svg';
import ResetPasswordIcon from 'assets/icons/undo.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import DeactivateIcon from 'assets/icons/closeOutline.svg';
import FreezeUserIcon from 'assets/icons/snowflake.svg';
import CheckIcon from 'assets/icons/circleCheck.svg';
import {
  Section,
  SectionBody,
  SectionHead,
  UserBasics,
  UserSession,
  UsersDeleteModal,
  UsersFreezeModal,
} from 'modules/admin/components';
import { flex, margin } from '@prototyp/gatsby-plugin-gumball/utils';

export const UsersDetail: React.FC<RouteComponentProps> = () => {
  const urlParams = new URLSearchParams(location.search);
  const loc = urlParams.get('tab') || 'basics';

  const { pathname } = useLocation();
  const id = String(pathname.split('/').pop());

  const { user: self } = useSelector((state: AppState) => state.session);

  const { isLoading: impersonateLoading } = useSelector(
    (state: AppState) => state.adminImpersonate,
  );

  const [isDeactivateModalOpen, setDeactivateModalOpen] =
    useState<boolean>(false);
  const [isFreezeModalOpen, setFreezeModalOpen] = useState<boolean>(false);
  const [tab, setTab] = useState<string>(loc);
  const { requestImpersonate } = useImpersonate();

  const {
    setCompanyOnboardingDone,
    setTalentOnboardingDone,
    getUserDetails,
    user,
    error,
    isLoading: detailsLoading,
  } = useAdminUser();
  const { requestResetPassword, isLoading: requestResetPasswordLoading } =
    useResetAdminPassword();

  useEffect(() => {
    getUserDetails(id);
  }, [id]);

  const staffRole = useCheckStaffRole()?.toUpperCase();
  const isSetTalentOnboardingButtonDisabled =
    user &&
    (!user.emailVerifiedAt ||
      user.id === self?.id ||
      staffRole === 'SUPPORT' ||
      Boolean(user.talentOnboardingDone?.length) ||
      Boolean(user.companyOnboardingDone?.length));

  const isSetCompanyOnboardingButtonDisabled =
    user &&
    (!user.emailVerifiedAt ||
      user.id === self?.id ||
      staffRole === 'SUPPORT' ||
      !user.companyRoles.length ||
      Boolean(user.companyOnboardingDone?.length));

  const isStaff = () => {
    return !!user?.roleRoleId;
  };

  const isDeleted = () => {
    return Boolean(user?.deletedAt);
  };

  const onImpersonate = (userId: string) => {
    requestImpersonate(userId);
  };

  const onResetPassword = (userId: string) => {
    requestResetPassword(userId);
  };

  const onFreeze = () => {
    setFreezeModalOpen(true);
  };

  const closeFreeze = () => {
    setFreezeModalOpen(false);
  };

  const onDelete = () => {
    setDeactivateModalOpen(true);
  };

  const onShowBasics = () => {
    setTab('basics');
    navigate(`?tab=basics`);
  };

  const onShowSessions = () => {
    setTab('sessions');
    navigate(`?tab=sessions`);
  };

  if (error) {
    return (
      <Section customStyles={sectionStyles.sectionNarrow}>
        <Pill type="error" title={error.status.toString()} text={error.type} />
      </Section>
    );
  }

  if (!user) {
    return null;
  }

  return (
    <>
      <Seo title="Users detail" />
      <Section customStyles={sectionStyles.sectionNarrow}>
        <Loading
          isLoading={detailsLoading}
          component={
            <>
              <motion.div
                transition={{ duration: 0.3 }}
                {...FADE_IN_MOVE_Y_REGULAR}
              >
                <SectionHead
                  customStyles={detailStyles.detailsHeader}
                  title={
                    <div css={flex.display.flex}>
                      <span>{user.fullName}</span>

                      <span css={detailStyles.detailsTags}>
                        {user.roleRoleId && (
                          <span css={userItemStyles.tag}>
                            <Tag text={user.roleRoleId} />
                          </span>
                        )}

                        {user.deletedAt && (
                          <span
                            css={[
                              userItemStyles.tag,
                              userItemStyles.deletedAtTag,
                            ]}
                          >
                            <Tag text="Deleted" />
                          </span>
                        )}
                      </span>
                    </div>
                  }
                >
                  <Avatar
                    imagePath={user.profilePicture?.small}
                    name={user.firstName}
                    lastName={user.lastName}
                  />
                </SectionHead>

                <SectionBody>
                  <div css={detailStyles.actions}>
                    <Button
                      onClick={() => onImpersonate(user.id)}
                      disabled={isStaff() || isDeleted()}
                      buttonType="ghost"
                      icon={
                        impersonateLoading ? (
                          <SmallLoadingImg />
                        ) : (
                          <PersonIcon css={detailStyles.fillBlack} />
                        )
                      }
                      size="small"
                      css={detailStyles.actionsButton}
                    >
                      Impersonate
                    </Button>

                    <Button
                      onClick={() => onResetPassword(user.id)}
                      buttonType="ghost"
                      disabled={
                        user.fullName.includes('DELETED') ||
                        user.id === self?.id
                      }
                      icon={
                        requestResetPasswordLoading ? (
                          <SmallLoadingImg />
                        ) : (
                          <ResetPasswordIcon css={detailStyles.fillBlack} />
                        )
                      }
                      size="small"
                      css={detailStyles.actionsButton}
                    >
                      Reset password {requestResetPasswordLoading}
                    </Button>

                    <Button
                      onClick={onFreeze}
                      buttonType="ghost"
                      disabled={!!user.deletedAt || user.id === self?.id}
                      icon={<FreezeUserIcon css={detailStyles.fillBlack} />}
                      size="small"
                      css={detailStyles.actionsButton}
                    >
                      Freeze user
                    </Button>

                    <Button
                      onClick={onDelete}
                      buttonType="ghost"
                      disabled={
                        user.fullName.includes('DELETED') ||
                        user.id === self?.id ||
                        staffRole === 'SUPPORT'
                      }
                      icon={
                        !!user.deletedAt ? (
                          <DeleteIcon css={detailStyles.fillBlack} />
                        ) : (
                          <DeactivateIcon
                            css={detailStyles.deactivateFillBlack}
                          />
                        )
                      }
                      size="small"
                      css={detailStyles.actionsButton}
                    >
                      {!!user.deletedAt ? 'Delete user' : 'Deactivate user'}
                    </Button>
                    <Button
                      onClick={() => setTalentOnboardingDone(user.id)}
                      buttonType="ghost"
                      disabled={isSetTalentOnboardingButtonDisabled}
                      icon={
                        <CheckIcon css={detailStyles.deactivateFillBlack} />
                      }
                      size="small"
                      css={detailStyles.actionsButton}
                    >
                      Set talent onboarding done
                    </Button>
                    <Button
                      onClick={() => setCompanyOnboardingDone(user.id)}
                      buttonType="ghost"
                      disabled={isSetCompanyOnboardingButtonDisabled}
                      icon={
                        <CheckIcon css={detailStyles.deactivateFillBlack} />
                      }
                      size="small"
                      css={detailStyles.actionsButton}
                    >
                      Set company onboarding done
                    </Button>
                  </div>

                  <div css={margin.bottom.xlrg}>
                    <Button
                      css={margin.right.med}
                      size="tiny"
                      buttonType={tab === 'basics' ? 'primary' : 'neutral'}
                      onClick={onShowBasics}
                    >
                      Basics
                    </Button>
                    <Button
                      size="tiny"
                      buttonType={tab === 'sessions' ? 'primary' : 'neutral'}
                      onClick={onShowSessions}
                    >
                      Sessions
                    </Button>
                  </div>

                  {tab === 'basics' && <UserBasics {...user} />}
                  {tab === 'sessions' && <UserSession userId={id} />}
                </SectionBody>
              </motion.div>
            </>
          }
        />
      </Section>

      {isDeactivateModalOpen && (
        <UsersDeleteModal
          isOpen={isDeactivateModalOpen}
          setIsOpen={setDeactivateModalOpen}
          userId={user.id}
          isDeactivateAction={!Boolean(user.deletedAt)}
          isDetails
        />
      )}
      {isFreezeModalOpen && (
        <UsersFreezeModal
          isOpen={isFreezeModalOpen}
          handleModalClose={closeFreeze}
          userId={user.id}
        />
      )}
    </>
  );
};
