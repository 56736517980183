import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { wrappers } from 'style/variables';

export const root = css`
  min-height: 100vh;

  padding: ${spacing(40)} ${spacing(32)};

  @media ${breakpoints.medium} {
    padding: ${spacing(80)} ${spacing(32)};
  }

  @media ${breakpoints.xxLarge} {
    padding: ${spacing(80)} 0;
  }
`;

export const wrapper = css`
  max-width: ${wrappers.page};
  margin: 0 auto;
`;

export const rootDark = css`
  ${root};
  background-color: hsl(var(--color-grayscale-9));
  color: hsl(var(--color-grayscale-1));
`;
