import React from 'react';
import { Button, emailRegex, Heading, InputField } from 'modules/app';
import { FormProvider } from 'react-hook-form';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { useChangeOwnership } from 'modules/admin/hooks';

interface Props {
  companyId: string;
  onClose: VoidFunction;
}

export const ChangeOwnershipModal: React.FC<Props> = ({
  companyId,
  onClose,
}) => {
  const { form, isLoading, changeOwnership } = useChangeOwnership();
  const { handleSubmit } = form;

  const onSubmit = (data: { email: string }) => {
    changeOwnership(companyId, data.email, onClose);
  };

  return (
    <div>
      <Heading as="h3" size="fluidLarge" css={margin.bottom.med}>
        Account Ownership Transfer
      </Heading>

      <p css={margin.bottom.lrg}>
        Enter the new owner's e-mail address. We'll send them an activation link
        to claim their account. It expires after 24 hours.
      </p>

      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputField
            type="email"
            name="email"
            constraints={{
              required: true,
              pattern: {
                value: emailRegex,
                message: 'register.errors.email_invalid',
              },
            }}
            value=""
            placeholder="New Account Owner Email Address"
          />
          <div css={margin.top.xlrg}>
            <Button
              type="submit"
              buttonType="primary"
              size="medium"
              css={margin.right.med}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Change ownership
            </Button>
            <Button
              type="button"
              buttonType="hazard"
              onClick={onClose}
              size="medium"
            >
              Cancel
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
