export function paymentRequest(
  planId: string,
  useExisting: boolean,
  data: PaymentForm,
): PurchasePlanRequest {
  if (useExisting) {
    return {
      subscriptionPlanId: planId,
      useExistingPaymentMethod: useExisting,
      cardSavingPolicyAccepted: data.cardSavingPolicyAccepted,
      termsOfServiceAccepted: data.termsOfServiceAccepted,
    };
  }

  const { expiryDate, ...info } = data;

  // Getting expiry month and expiry date array
  const cardDate = expiryDate.replace(/\s+/g, '').split('/');

  return {
    ...info,
    useExistingPaymentMethod: false,
    cardExpiryMonth: cardDate[0],
    cardExpiryYear: `20${cardDate[1]}`,
    subscriptionPlanId: planId,
  };
}

export function cardRequest(data: PaymentForm): CardRequest {
  const { expiryDate, ...info } = data;

  // Getting expiry month and expiry date array
  const cardDate = expiryDate.replace(/\s+/g, '').split('/');

  return {
    ...info,
    cardExpiryMonth: cardDate[0],
    cardExpiryYear: `20${cardDate[1]}`,
  };
}
