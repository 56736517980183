import { css } from '@emotion/react';
import { borderRadius } from 'style/variables';

export const root = css`
  width: 100%;
  margin-bottom: calc(var(--unit) * 8);

  textarea {
    width: 100%;
    height: 140px;
    resize: none;
    border: none;
    background-color: transparent;
    border-radius: ${borderRadius.regular};
    padding: calc(var(--unit) * 5);
    font-size: var(--font-size-base);
    outline: none;
    border: 1px solid hsl(var(--color-borderUltraLight-9));
    line-height: 1.5em;
    margin-top: var(--unit);

    &::placeholder {
      color: hsl(var(--color-textLight-7));
    }

    &:hover {
      border-color: hsl(var(--color-borderLight-8));

      &::placeholder {
        color: hsl(var(--color-grayscale-6));
      }
    }

    &:focus {
      border-color: hsl(var(--color-borderLight-8));
    }
  }

  label {
    padding: 0;
    margin: 0;
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    color: hsl(var(--color-grayscale-6));
  }
`;

export const error = css`
  textarea {
    border: 1px solid hsl(var(--color-error-7));
  }

  label {
    color: hsl(var(--color-error-7));
  }
`;

export const labelWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const maxChar = css`
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
  color: hsl(var(--color-textLight-7));
`;
