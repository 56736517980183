import { ActionUnion } from 'modules/redux-store';

import { talentOpeningsAction } from './actions';

interface SearchInitState {
  openings?: OpeningsSearch[];
  recentOpenings?: OpeningsSearch[];
  isLoading: boolean;
  error?: ApiErrorData;
  params: OpeningsSearchParams;
  page: number;
  lastPage: number;
  isMatchingLoading: boolean;
  matchingError?: ApiErrorData;
  matches?: TalentMatch[];
}

const INITIAL_STATE: SearchInitState = {
  isLoading: false,
  isMatchingLoading: false,
  error: undefined,
  openings: undefined,
  recentOpenings: undefined,
  params: {
    search: '',
    workingHours: undefined,
    typeOfWorkplace: undefined,
    contractType: undefined,
    careerLevel: undefined,
    xLocation: undefined,
    yLocation: undefined,
    location: undefined,
    page: 1,
  },
  page: 1,
  lastPage: 1,
};

export const talentOpeningsReducer = (
  state: SearchInitState = INITIAL_STATE,
  action: ActionUnion<typeof talentOpeningsAction>,
): SearchInitState => {
  switch (action.type) {
    case 'talentOpenings/OPENINGS':
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: undefined,
      };
    case 'talentOpenings/RECENT_OPENINGS':
      return {
        ...state,
        recentOpenings: action.payload,
        isLoading: false,
        error: undefined,
      };
    case 'talentOpenings/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case 'talentOpenings/LOADING':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case 'talentOpenings/PARAMS':
      return {
        ...state,
        ...action.payload,
      };

    case 'talentOpenings/SET_MATCHING':
      return {
        ...state,
        isMatchingLoading: false,
        matchingError: undefined,
        matches: action.payload,
      };
    case 'talentOpenings/MATCHING_ERROR':
      return {
        ...state,
        isMatchingLoading: false,
        matchingError: action.payload,
      };
    case 'talentOpenings/MATCHING_LOADING':
      return {
        ...state,
        isMatchingLoading: true,
        matchingError: undefined,
      };

    case 'talentOpenings/RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
