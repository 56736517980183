import {
  flex,
  font,
  margin,
  padding,
  text,
} from '@prototyp/gatsby-plugin-gumball/utils';
import * as styles from '../../styles/Manage.styles';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { UserCard } from 'modules/admin/components';
import { Button } from 'modules/app';
import React from 'react';

interface Props {
  talents: Users[] | undefined;
  search: string;
  role: string;
  companyId?: string;
  editRole: (id: string) => void;
}

export const TalentList: React.FC<Props> = ({
  talents,
  search,
  role,
  companyId,
  editRole,
}) => {
  const isMember = (
    usersRoles: {
      roleId: string;
      companyName: string;
      companyId: string;
      companyHandle: string;
    }[],
  ) => {
    return usersRoles.find((item) => item.companyId === companyId);
  };

  if (!Boolean(talents?.length)) {
    return (
      <>
        No results for <span css={font.weight.bold}>'{search}'</span>, try with
        full name or username
      </>
    );
  }
  return (
    <div css={styles.talents}>
      {!Boolean(search) && (
        <div
          css={[
            text.transform.uppercase,
            text.color.textLight,
            font.size.tiny,
            padding.bottom.med,
          ]}
        >
          suggestions
        </div>
      )}
      {talents?.map((user) => {
        return (
          <div
            key={user.id}
            css={[flex.display.flex, flex.justify.between, margin.bottom.med]}
          >
            <UserCard
              profilePicture={user.profilePicture?.small}
              name={user.firstName}
              lastName={user.lastName}
              info={user.email}
            />

            <Button
              buttonType={
                Boolean(isMember(user.companyRoles)) ? 'ghost' : 'primary'
              }
              size="small"
              type="button"
              disabled={Boolean(isMember(user.companyRoles))}
              css={margin.right.med}
              onClick={() => {
                if (!Boolean(isMember(user.companyRoles))) {
                  editRole(user.id);
                }
              }}
            >
              {' '}
              {Boolean(isMember(user.companyRoles)) ? (
                <span css={text.transform.capitalize}>
                  {
                    <FormattedMessage
                      id={`settings.membersContent.${
                        isMember(user.companyRoles)?.roleId
                      }`}
                    />
                  }
                </span>
              ) : (
                <span css={text.transform.capitalize}>
                  set as{' '}
                  {<FormattedMessage id={`settings.membersContent.${role}`} />}
                </span>
              )}
            </Button>
          </div>
        );
      })}
    </div>
  );
};
