import { FormattedMessage } from 'gatsby-plugin-intl';
import {
  PortfolioInput,
  portfolioValues,
  SelectComponent,
  TextArea,
  useTransformSelect,
} from 'modules/app';
import React, { useState } from 'react';
import * as styles from 'modules/app/styles/GenericForm.styles';
import * as inputStyles from 'modules/app/styles/PortfolioForm.styles';

interface Props {
  currentPortfolio?: Portfolio;
  resetValue: number;
}

export const TalentPortfolioInputs: React.FC<Props> = ({
  currentPortfolio,
  resetValue,
}) => {
  const [selectedPortfolio, setSelectedPortfolio] = useState<string | string[]>(
    '',
  );

  const getDefaultValue = (): string => {
    if (!currentPortfolio) return '';

    if (currentPortfolio.url && selectedPortfolio === 'other') {
      return currentPortfolio.url;
    }

    if (currentPortfolio.handle) {
      return currentPortfolio.handle;
    }

    return '';
  };

  return (
    <>
      <div css={styles.subgridForm}>
        <SelectComponent
          name="portfolioId"
          label={
            <FormattedMessage id="onboarding.form.fields.portfolio.add_portfolio" />
          }
          constraints={{ required: true }}
          setSelectedValue={setSelectedPortfolio}
          reset={resetValue}
          values={portfolioValues}
          selectedValue={useTransformSelect(
            currentPortfolio?.portfolioId,
            portfolioValues,
          )}
          isDisabled={currentPortfolio ? true : false}
        />
        <div>
          <PortfolioInput
            customStyles={inputStyles.portfolioInput}
            name="url"
            value={getDefaultValue()}
            selectedPortfolio={selectedPortfolio}
            reset={resetValue}
            constraints={{
              required: true,
              maxLength: {
                value: 2048,
                message: 'shared.errors.max',
              },
              minLength: {
                value: 3,
                message: 'shared.errors.min',
              },
              validate: (url: string) => {
                if (selectedPortfolio !== 'other') return true;
                return /^(http|https):\/\/[^ "]+$/.test(url)
                  ? true
                  : 'onboarding.form.errors.url';
              },
            }}
          />
        </div>
      </div>
      <TextArea
        isOptional={true}
        value={currentPortfolio?.description || ''}
        name="description"
        topLabel="onboarding.form.fields.portfolio.portfolio_description_label"
        placeholder="onboarding.form.fields.portfolio.portfolio_description_placeholder"
        reset={resetValue}
        constraints={{
          maxLength: {
            value: 1000,
            message: 'shared.errors.max',
          },
        }}
      />
    </>
  );
};
