import { css } from '@emotion/react';

export const root = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const openings = css`
  flex-grow: 1;
`;

export const loadingStyle = css`
  height: 100%;
`;
