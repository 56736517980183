import React, { useEffect } from 'react';
import { useGetData } from '../hooks';
import { OpeningsList } from './OpeningsList';
import { ProfileAnalytics } from './ProfileAnalytics';
import { StatsOverview } from './StatsOverview';

import * as styles from 'modules/dashboard/styles/Root.styles';
import { useSelector } from 'react-redux';
import { HttpError, MainContent, useGracePeriod } from 'modules/app';
import { AppState } from 'modules/redux-store';
import { NoOpenings } from 'modules/openings/components/NoOpenings';
import { NoPlan } from './NoPlan';
import { GracePeriod } from './GracePeriod';

interface Props {
  companyData: Company;
}

export const DashboardContent: React.FC<Props> = ({ companyData }) => {
  const { getData, isLoading, error } = useGetData();
  const { companyOpenings } = useSelector((state: AppState) => state.openings);
  const { activePlan } = useSelector((state: AppState) => state.billing);

  const { isGracePeriod } = useGracePeriod();

  useEffect(() => {
    if (isGracePeriod || !activePlan) return;

    getData(companyData.id);
  }, []);

  if (error) {
    return (
      <MainContent>
        <HttpError error={error} />
      </MainContent>
    );
  }

  if (isGracePeriod) {
    return (
      <GracePeriod
        name={companyData.name}
        handle={companyData.handle}
        image={companyData.iconPicture}
      />
    );
  }

  if (activePlan || isGracePeriod) {
    return (
      <section css={styles.root}>
        {Boolean(companyOpenings?.length) ? (
          <>
            <StatsOverview isLoading={isLoading} />

            <OpeningsList
              isLoading={isLoading}
              companyHandle={companyData.handle}
            />

            <ProfileAnalytics isLoading={isLoading} />
          </>
        ) : (
          !isLoading && <NoOpenings companyHandle={companyData.handle} />
        )}
      </section>
    );
  }

  return (
    <NoPlan companyId={companyData.id} companyHandle={companyData.handle} />
  );
};
