import { ActionUnion } from 'modules/redux-store';

import { companyDataAction } from './actions';
import { isCompany } from './isCompany';

interface CompanyInitState {
  companyData?: Company;
  isLoading: boolean;
  error?: ApiErrorData;
  publicCompany?: PublicCompany;
  publicOpenings: {
    openings?: JobOpeningListItem[];
    page: number;
    lastPage: number;
  };
  latestPlan?: LatestPlan;
}

const INITIAL_STATE: CompanyInitState = {
  companyData: undefined,
  isLoading: false,
  error: undefined,
  publicOpenings: {
    page: 1,
    lastPage: 1,
  },
};

export const companyDataReducer = (
  state: CompanyInitState = INITIAL_STATE,
  action: ActionUnion<typeof companyDataAction>,
): CompanyInitState => {
  switch (action.type) {
    case 'company/COMPANY_DATA':
      return {
        ...state,
        companyData: action.payload,
        isLoading: false,
        error: undefined,
      };
    case 'company/UPDATE_COMPANY_DATA':
      const companyData = {
        ...state.companyData,
        ...action.payload.companyData,
      };

      if (isCompany(companyData)) {
        return {
          ...state,
          companyData: { ...companyData },
          isLoading: action.payload.isLoading,
          error: action.payload.error,
        };
      }

      return {
        ...state,
      };

    case 'company/LOADING':
      return {
        ...state,
        error: undefined,
        isLoading: true,
      };
    case 'company/ERROR':
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case 'company/PUBLIC_COMPANY':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        publicCompany: action.payload,
      };
    case 'company/PUBLIC_OPENINGS':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        publicOpenings: action.payload,
      };
    case 'company/LATEST_PLAN': {
      return {
        ...state,
        latestPlan: action.payload,
      };
    }
    case 'company/COMPANY_DATA_RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
