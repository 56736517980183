export const userActionTypes = [
  { value: 'none', label: 'None (Show all)' },
  { value: 'create', label: 'Create' },
  { value: 'enable', label: 'Enable' },
  { value: 'update', label: 'Update' },
  { value: 'assign', label: 'Assign' },
  { value: 'expire', label: 'Expire' },
  { value: 'leave', label: 'Leave' },
  { value: 'delete', label: 'Delete' },
  { value: 'soft_delete', label: 'Soft Delete' },
  { value: 'disable', label: 'Disable' },
  { value: 'apply', label: 'Apply' },
  { value: 'impersonate', label: 'Impersonate' },
  { value: 'end_impersonate', label: 'End Impersonate' },
  { value: 'soft_restore', label: 'Soft Restore' },
  { value: 'send_password_reset', label: 'Send Password Reset' },
  { value: 'freeze_account', label: 'Freeze Account' },
  { value: 'set_active', label: 'Set Active' },
  { value: 'set_inactive', label: 'Set Inactive' },
  { value: 'cancel', label: 'Cancel' },
  { value: 'refund', label: 'Refund' },
  { value: 'partial_refund', label: 'Partial Refund' },
  { value: 'refiscalize', label: 'Refiscalize' },
  { value: 'regenerate', label: 'Regenerate' },
];

export const companyActionTypes = [
  { value: 'none', label: 'None (Show all)' },
  { value: 'create', label: 'Create' },
  { value: 'assign', label: 'Assign' },
  { value: 'update', label: 'Update' },
  { value: 'delete', label: 'Delete' },
  { value: 'expire', label: 'Expire' },
  { value: 'leave', label: 'Leave' },
  { value: 'schedule', label: 'Schedule' },
  { value: 'archive', label: 'Archive' },
  { value: 'restore', label: 'Restore' },
  { value: 'deactivate', label: 'Deactivate' },
  { value: 'reactivate', label: 'Reactivate' },
  { value: 'purchase', label: 'Purchase' },
  { value: 'downgrade', label: 'Downgrade' },
  { value: 'card_registration', label: 'Card Registration' },
  { value: 'upload_profile_picture', label: 'Upload Profile Picture' },
  { value: 'delete_profile_picture', label: 'Delete Profile Picture' },
  { value: 'upload_cover_picture', label: 'Upload Cover Picture' },
  { value: 'delete_cover_picture', label: 'Delete Cover Picture' },
];
