import { useMatch } from '@reach/router';

export const useIsMatch = () => {
  const isExpertise = useMatch('/:lang/profile/talent/manage/expertise');
  const isCulture = useMatch('/:lang/profile/talent/manage/culture');
  const isBasics = useMatch('/:lang/profile/talent/manage/basic-information');

  return {
    isExpertise,
    isCulture,
    isBasics,
  };
};
