import { useHttpClient } from 'modules/app';
import { useState } from 'react';

export const useGetTalents = (callback?: React.Dispatch<Users[]>) => {
  const adminUsersService = useHttpClient('/admin/users');
  const [isLoading, setIsLoading] = useState(false);

  // change number of results per page --> only here
  async function getTalents({
    page = 1,
    perPage = undefined,
    sort = 'DESC',
    sortBy = 'created_at',
    search = '',
    searchBy = '',
    hideDeleted = true,
    hideStaff = false,
    staffRoleId = undefined,
    companyId = undefined,
  }: UsersAttributes) {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await adminUsersService.get<AdminResponse<Users>>('', {
        params: {
          page,
          perPage,
          sort,
          sortBy,
          search,
          searchBy,
          hideDeleted,
          hideStaff,
          staffRoleId,
          companyId,
        },
      });
      callback?.(data);
    } catch (e) {}
    setIsLoading(false);
  }

  return {
    isLoading,
    getTalents,
  };
};
