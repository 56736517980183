import { useAuthDispatch, useCsrfCookie } from 'modules/app';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginAction } from 'modules/login';
import { navigate } from 'gatsby-plugin-intl';
import { HttpError } from 'modules/app/http/client/httpError';
import { registrationActions } from 'modules/register';
import { FormFourData } from 'modules/register/forms/FormFour';
import { useResetReducer } from 'modules/app/auth/useResetReducers';
import { useHttpClient } from 'modules/app/http/hooks/useHttpClient';
import { useLocation, navigate as routerNavigate } from '@reach/router';

interface GhostData {
  password: string;
  confirmPassword: string;
  isTosAccepted: boolean;
}

export function useAuth() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const resetReducer = useResetReducer();
  const { getCsrf, remove } = useCsrfCookie();
  const { dispatchLogin } = useAuthDispatch();
  const csrf = getCsrf();
  const authService = useHttpClient<SessionResponse>('/auth');

  const [error, setError] = useState<HttpError>();

  async function logoutUser() {
    try {
      await authService.post('/logout', undefined, {
        headers: {
          'x-csrf-token': csrf,
        },
      });
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }
    resetReducer();
    remove();
    pathname.includes('public-opening') && routerNavigate(pathname);
  }

  async function loginUser(
    user: LoginUserRequest,
    companyInviteToken?: string,
    companyClaimToken?: string,
  ) {
    try {
      const response = await authService.post('/login', user);

      const { data } = response;
      if (user.token && !!user.token.length) data.user.is2fa = true;

      dispatchLogin(
        data.user,
        data.userSession,
        data.userStaffRole,
        data.userCompanyRoles,
        companyInviteToken,
        companyClaimToken,
      );

      setError(undefined);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
      if (e.cause === 'invalid_otp_token') {
        dispatch(loginAction.reset());
        dispatch(
          loginAction.addStepOne({
            email: user.email,
            password: user.password,
            remember: user.remember,
            is2faAuth: true,
            token: '',
          }),
        );

        if (companyInviteToken) {
          navigate(`/login/step-two?inviteToken=${companyInviteToken}`);
          return;
        }

        if (companyClaimToken) {
          navigate(`/login/step-two?claimToken=${companyClaimToken}`);
          return;
        }

        navigate('/login/step-two');
      }
    }
  }

  async function checkRegisteredEmail(userData: {
    email: string;
    password: string;
  }) {
    try {
      await authService.post('/check-email-and-password', userData);
      return false;
    } catch (e) {
      return true;
    }
  }

  async function registerUser(
    user: RegisterUserRequest,
    onChangeStep: (step: Step) => void,
    otpData?: FormFourData,
  ) {
    try {
      await authService.post('/register', user);
      onChangeStep('final-step');

      dispatch(registrationActions.completeRegistration());
      navigate('/register/final-step');

      if (otpData) {
        dispatch(
          registrationActions.addStepFour({
            otpSecret: otpData.otpSecret,
            otpToken: otpData.code,
          }),
        );
      }
      setError(undefined);
    } catch (e) {
      if (!(e instanceof HttpError)) return;

      if (e.cause === 'registration_invalid_email') {
        navigate(`/register`, {
          state: {
            error: e.cause,
          },
        });
      }
      setError(e);
    }
  }

  async function resendVerificationEmail(email: string) {
    try {
      const {
        data: { success },
      } = await authService.post<{ success: boolean }, string>('/reset', email);

      return { success };
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
      return;
    }
  }

  function getInviteToken(search: string) {
    const query = new URLSearchParams(search);
    const inviteToken = query.get('inviteToken');
    const claimToken = query.get('claimToken');

    return {
      inviteToken: inviteToken || undefined,
      claimToken: claimToken || undefined,
    };
  }

  async function ghostRegister(info: GhostData, token: string) {
    try {
      const { data } = await authService.post<
        SessionResponse,
        RegisterGhostUser
      >(`/ghost-verify-register/${token}`, {
        ...info,
        isProcessingPrivacyPolicyAccepted: info.isTosAccepted,
      });

      dispatchLogin(
        data.user,
        data.userSession,
        data.userStaffRole,
        data.userCompanyRoles,
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }
  }

  return {
    error,
    logoutUser,
    loginUser,
    registerUser,
    ghostRegister,
    checkRegisteredEmail,
    getInviteToken,
    resendVerificationEmail,
  };
}
