import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins/spacing';

export const root = css`
  border-radius: 50%;
  flex-shrink: 0;
  width: ${spacing(10)};
  height: ${spacing(10)};
  margin-top: ${spacing(34)};
`;

export const active = css`
  ${root};
  background: hsl(var(--color-textSuccess-7));
`;

export const inactive = css`
  ${root};
  background: hsl(var(--color-textLight-7));
`;
