import React from 'react';
import { SectionHead } from 'modules/admin/components';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { Button, DropdownMenu, SmallLoadingImg, TextInput } from 'modules/app';
import Search from 'assets/icons/search.svg';
import { motion } from 'framer-motion';
import * as styles from '../styles/BillingActivityHeader.styles';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { useIntl } from 'gatsby-plugin-intl';

interface Props {
  isLoading?: boolean;
  query: BillingActivityQuery;
  setQuery: React.Dispatch<React.SetStateAction<BillingActivityQuery>>;
  handleSearch: (
    e: React.KeyboardEvent<HTMLInputElement> &
      React.ChangeEvent<HTMLInputElement>,
  ) => void;
  handleChangeSort: (option: string) => void;
  handleChangeSortBy: (option: string) => void;
  handleGenerateSearchFilterOptions: (
    options: string[],
    activeFilter: string,
  ) => {
    id: string;
    element: EmotionJSX.Element;
  }[];
  handleChangeSearchBy: (option: string) => void;
}

export const BillingActivityHeader: React.FC<Props> = ({
  isLoading,
  query,
  setQuery,
  handleSearch,
  handleChangeSort,
  handleChangeSortBy,
  handleGenerateSearchFilterOptions,
  handleChangeSearchBy,
}) => {
  const searchByOptions = ['company_name', 'currency'];
  const paymentType = ['Payment', 'Refund', 'Cancellation', 'Partial Refund'];
  const sortByOptions = ['amount', 'created_at'];
  const sortOptions = ['DESC', 'ASC'];
  const { formatMessage } = useIntl();

  const onResetFilter = () => {
    setQuery({
      page: 1,
      sort: 'DESC',
      sortBy: 'created_at',
      search: '',
      searchBy: 'company_name',
      startDate: 'company_name',
      paymentType: undefined,
      input: '',
    });
  };

  const handleChangePaymentType = (selectedPayment: string) => {
    setQuery({ ...query, paymentType: selectedPayment });
  };

  return (
    <motion.div transition={{ duration: 0.3 }} {...FADE_IN_MOVE_Y_REGULAR}>
      <SectionHead title="Billing Activity" customStyles={styles.sectionHead}>
        <TextInput
          autoFocus
          value={query.input}
          icon={isLoading ? <SmallLoadingImg /> : <Search />}
          css={styles.search}
          placeholder={formatMessage({
            id: 'admin.billingActivity.billingActivityHeader.input.placeholder',
          })}
          onChange={(e) => setQuery({ ...query, input: e.target.value })}
          onKeyUp={(
            e: React.KeyboardEvent<HTMLInputElement> &
              React.ChangeEvent<HTMLInputElement>,
          ) => handleSearch(e)}
        />

        <div css={styles.searchFilter}>
          <DropdownMenu
            onSelect={(id) => {
              handleChangeSearchBy(id);
            }}
            placeholder={
              <div>
                Search by <strong>{query.searchBy}</strong>
              </div>
            }
            hideCaret={false}
            items={handleGenerateSearchFilterOptions(
              searchByOptions,
              query.searchBy || 'company_name',
            )}
            position="bottomRight"
            customButtonStyles={styles.sortByDropdown}
          />

          <DropdownMenu
            onSelect={(id) => {
              handleChangeSortBy(id);
            }}
            placeholder={
              <div>
                Sort by <strong>{query.sortBy}</strong>
              </div>
            }
            hideCaret={false}
            items={handleGenerateSearchFilterOptions(
              sortByOptions,
              query.sortBy,
            )}
            position="bottomRight"
            customButtonStyles={styles.sortByDropdown}
          />

          <DropdownMenu
            onSelect={(id) => {
              handleChangePaymentType(id);
            }}
            placeholder={
              <div>
                Filter by <strong>{query.paymentType}</strong>
              </div>
            }
            hideCaret={false}
            items={handleGenerateSearchFilterOptions(
              paymentType,
              query.paymentType || '',
            )}
            position="bottomRight"
            customButtonStyles={styles.sortByDropdown}
          />

          <DropdownMenu
            onSelect={(id) => {
              handleChangeSort(id);
            }}
            placeholder={
              <div>
                Sort by <strong>{query.sort}</strong>
              </div>
            }
            hideCaret={false}
            items={handleGenerateSearchFilterOptions(sortOptions, query.sort)}
            position="bottomRight"
            customButtonStyles={styles.sortByDropdown}
          />
          <Button
            buttonType={'outline'}
            size={'tiny'}
            onClick={onResetFilter}
            disabled={isLoading}
          >
            Reset filter
          </Button>
        </div>
      </SectionHead>
    </motion.div>
  );
};
