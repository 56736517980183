import { ActionUnion } from 'modules/redux-store';

import { companyOnboardingAction } from './actions';

interface InitialState extends OnboardingCompanyBreadcrumbs {
  isLoading: boolean;
  companyData?: Company;
  error?: ApiErrorData;
  countries?: { value: string; label: string }[];
  listArray: ListingData[];
  isInRequest: boolean;
}

const INITIAL_STATE: InitialState = {
  'information/basics': true,
  'information/location': false,
  'information/description': false,
  'social-accounts': false,
  expertise: false,
  finishedScreen: false,
  isLoading: false,
  listArray: [],
  isInRequest: false,
};

export const companyOnboardingReducer = (
  state: InitialState = INITIAL_STATE,
  action: ActionUnion<typeof companyOnboardingAction>,
): InitialState => {
  switch (action.type) {
    case 'onboarding/COMPANY_LOADING':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case 'onboarding/COMPANY_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'onboarding/COMPANY_IN_REQUEST':
      return {
        ...state,
        isInRequest: action.payload,
      };

    case 'onboarding/COMPANY_SET_LIST_ARRAY':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        listArray: action.payload,
        isInRequest: false,
      };
    case 'onboarding/COMPANY_SET_COMPANY_DATA':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        companyData: action.payload,
      };
    case 'onboarding/COMPANY_SET_COUNTRIES':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        countries: action.payload,
      };
    case 'onboarding/COMPANY_ACCESS':
      return {
        ...state,
        ...action.payload,
      };
    case 'onboarding/COMPANY_ONBOARDING_RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
