import { useOutOfBounds } from 'modules/app/hooks';
import { useHttpClient } from 'modules/app';
import { useAssets } from 'modules/shared';
import { removeWhiteSpace } from 'modules/talents/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { debounce } from 'throttle-debounce';
import { useOutsideClick } from './useOutsideClick';

export const useAddTagButton = (
  onAddTag: (tag: string) => void,
  companyId?: string,
) => {
  const [isModal, setIsModal] = useState(false);
  const [companyTags, setCompanyTags] = useState<string[]>();
  const [allCompanyTags, setAllCompanyTags] = useState<string[]>();

  const modalRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const ref = useOutsideClick<HTMLDivElement>(() => setIsModal(false));

  const methods = useForm<{ value: string }>({ defaultValues: { value: '' } });
  const { setValue, setError, clearErrors, reset } = methods;

  const { suggestedTags, setSuggestedTags, getAllTags } = useAssets();

  const http = useHttpClient('/api/v1/company');

  async function getTags(companyId: string) {
    try {
      const { data } = await http.get<string[]>(
        `/${companyId}/applications/tags/show-all`,
      );
      setAllCompanyTags(data);
    } catch (e) {}
  }

  function onSubmit(data: { value: string }) {
    onAddTag(removeWhiteSpace(data.value));
    setSuggestedTags(undefined);
    setIsModal(false);
    reset();
  }

  const onInputChange = debounce(300, async (text: string) => {
    if (!text) {
      setSuggestedTags(undefined);
      setCompanyTags(undefined);
      return;
    }

    if (!companyId) {
      getAllTags(text);
      return;
    }

    if (!allCompanyTags) return;

    setCompanyTags(allCompanyTags.filter((tag) => tag.includes(text)));
  });

  function onEnter(e: KeyboardEvent) {
    if (!inputRef.current || e.key !== 'Enter') return;

    const value = inputRef.current.value;

    if (!value.length || value.length > 30) {
      setError('value', { type: 'validate' });
      return;
    }

    clearErrors('value');

    onAddTag(value);
    onSubmit({ value });
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;

    if (!value.trim()) {
      e.target.value = '';
      setValue('value', '');
      onInputChange(value);
      return;
    }

    setValue('value', value);
    value && onInputChange(value);
  }

  function onCompanyTagsClick(tag: string) {
    onAddTag(tag);
    setIsModal(false);
    reset();
  }

  function onTagsClick(tag: Expertise) {
    onAddTag(tag.name);
    setIsModal(false);
    reset();
    setSuggestedTags(undefined);
  }

  const getCompanyTags = useCallback(() => {
    if (!companyId || !isModal) return;

    getTags(companyId);
  }, [companyId, isModal]);

  useOutOfBounds(modalRef, isModal);

  useEffect(() => {
    if (isModal && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.addEventListener('keydown', onEnter);
    }

    return () => inputRef.current?.removeEventListener('keydown', onEnter);
  }, [inputRef, isModal]);

  useEffect(getCompanyTags, [isModal]);

  return {
    onChange,
    onSubmit,
    setIsModal,
    onTagsClick,
    onCompanyTagsClick,
    ref,
    methods,
    isModal,
    inputRef,
    modalRef,
    companyTags,
    suggestedTags,
  };
};
