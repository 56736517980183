import React, { useState } from 'react';
import { Logo, NavLink, QuickSearch, useCsrfCookie } from 'modules/app';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';

import * as styles from '../../styles/Navbar.styles';
import { root, primary, tiny, ghost } from 'modules/app/styles/Button.styles';
import { MobileNavbar } from 'modules/app';
import { NavbarIcon } from '../mobileNavbar/NavbarIcon';

interface Props {
  negative?: boolean;
  isGray?: boolean;
  isLight?: boolean;
}

export const Navbar: React.FC<Props> = ({ negative, isGray, isLight }) => {
  const [toggleNav, setToggleNav] = useState(false);
  const { isLoggedIn } = useCsrfCookie();
  const intl = useIntl();
  const isDark = negative || isGray;
  const navBarStyle = () => {
    if (negative) return styles.navbarNegative;

    if (isGray) return styles.navbarGray;

    if (isLight) return styles.navbarLight;

    return styles.navbar;
  };

  const dashboardPath = () => {
    if (isLoggedIn()) {
      return '/profile/talent/overview/invitations';
    }

    return '/register';
  };

  return (
    <nav className="nav" css={navBarStyle()}>
      <div css={styles.wrapper} role="presentation">
        <Logo css={styles.logo} colorType={isDark ? 'white' : 'black'} />

        <section css={styles.navbarPrimary}>
          <div
            css={isDark ? styles.navbarLinkNegative : styles.navbarLink}
            role="presentation"
          >
            <NavLink
              path="/companies"
              text={intl.formatMessage({ id: 'navbar.navLinks.companies' })}
            />
            <NavLink
              path="/explore"
              text={intl.formatMessage({ id: 'navbar.navLinks.explore' })}
              checkPartial
            />
            <NavLink
              path="/pricing"
              text={intl.formatMessage({ id: 'navbar.navLinks.pricing' })}
            />
            <a
              href="https://blog.roango.com/"
              rel="noopener noreferrer"
              className="link"
            >
              {intl.formatMessage({ id: 'navbar.navLinks.blog' })}
            </a>
            <NavLink
              path="/support"
              text={intl.formatMessage({ id: 'navbar.navLinks.support' })}
            />
          </div>

          <div
            className="nav__actions"
            css={styles.navbarUtils}
            role="presentation"
          >
            {!isLoggedIn() && (
              <Link
                to="/login/"
                css={[
                  root,
                  ghost,
                  tiny,
                  isDark ? styles.buttonTextNegative : styles.buttonAlt,
                ]}
              >
                <FormattedMessage id="navbar.ctaLogin" />
              </Link>
            )}

            <Link
              to={dashboardPath()}
              css={[
                root,
                primary,
                tiny,
                isDark ? styles.buttonNegative : styles.button,
              ]}
            >
              <FormattedMessage
                id={isLoggedIn() ? 'navbar.ctaDashboard' : 'navbar.ctaRegister'}
              />
            </Link>
            {isLoggedIn() && (
              <div css={styles.searchWrapper}>
                <QuickSearch
                  formStyles={styles.search}
                  theme={isDark ? 'dark' : 'light'}
                />
              </div>
            )}
          </div>
        </section>

        <NavbarIcon
          onClick={() => setToggleNav(!toggleNav)}
          isToggled={toggleNav}
          negative={isDark}
        />
      </div>

      <MobileNavbar toggleNav={toggleNav} negative={isDark} />
    </nav>
  );
};
