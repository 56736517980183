import React, { useEffect, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Button,
  HttpError,
  ValidationError,
  TalentProjectInputs,
  useToastify,
  useFormGuardEffect,
} from 'modules/app';
import { FormattedMessage } from 'react-intl';
import * as styles from 'modules/app/styles/ProjectsForm.styles';

import { useReset } from 'modules/onboarding';
import { useTalentProjects } from 'modules/talents';

interface Props {
  isOnboarding?: boolean;
  setCurrentProjects?: React.Dispatch<
    React.SetStateAction<Project[] | undefined>
  >;
  isProfile?: boolean;
  resetFormCounter?: () => void;
  experienceData: Experience[];
  currentPage?: number;
  setPageCount?: React.Dispatch<React.SetStateAction<number>>;
}

export const TalentProjectsForm: React.FC<Props> = ({
  isOnboarding = false,
  setCurrentProjects,
  isProfile,
  resetFormCounter,
  experienceData,
  currentPage,
  setPageCount,
}) => {
  const methods = useForm<ProjectsFormData>({
    defaultValues: {
      name: '',
      owner: '',
      startDate: null,
      endDate: null,
      relatedWith: null,
      userExperienceId: null,
      url: null,
      description: null,
      featured: false,
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = methods;
  useFormGuardEffect(methods, isOnboarding);

  const positionalRef = useRef<HTMLFormElement>(null);

  const { toastSuccess } = useToastify();
  const { resetValue, setReset } = useReset();
  const {
    httpError,
    validationError,
    getProfileProjects,
    getProfileProjectsDispatch,
    addProject,
  } = useTalentProjects();

  const [isInRequest, setIsInRequest] = useState<boolean>();

  const getFormData = (data: ProjectsFormData): ProjectRequest => {
    return {
      ...data,
      url: data.url || null,
      startDate: data.startDate
        ? `${data.startDate.getFullYear()}-${
            data.startDate.getMonth() + 1
          }-${data.startDate.getDate()}`
        : '',
      endDate: data.endDate
        ? `${data.endDate.getFullYear()}-${
            data.endDate.getMonth() + 1
          }-${data.endDate.getDate()}`
        : null,
      relatedWith: data.relatedWith || null,
      userExperienceId: data.userExperienceId || null,
      featured: data.featured,
      description: data.description ? data.description : '',
    };
  };

  const gettingProjects = async () => {
    const response = await getProfileProjects({
      page: currentPage || 1,
      perPage: 5,
    });
    if (!response) return;

    setPageCount?.(response.pageCount);
    setCurrentProjects?.(response.projects);
  };

  const onValid = async (data: ProjectsFormData) => {
    const model = getFormData(data);

    setIsInRequest(true);
    const response = await addProject(model);
    setIsInRequest(false);

    if (!response || (response && response.error)) return;

    if (isOnboarding) {
      reset();
      setReset();
      setValue('relatedWith', null);
      setValue('userExperienceId', null);
      positionalRef?.current?.scrollIntoView({ behavior: 'smooth' });
      getProfileProjectsDispatch({ page: 1, load: false });

      return;
    }

    if (isProfile) {
      toastSuccess('shared.pills.successDefaultText');
      gettingProjects();
      resetFormCounter?.();
    }
  };

  useEffect(() => {
    if (Boolean(Object.keys(errors).length)) {
      positionalRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errors]);

  return (
    <div css={styles.root}>
      <FormProvider {...methods}>
        <form ref={positionalRef} onSubmit={handleSubmit(onValid)}>
          <HttpError styles={styles.httpErrorFix} error={httpError} />
          <ValidationError
            styles={styles.httpErrorFix}
            error={validationError}
          />

          <TalentProjectInputs
            resetValue={resetValue}
            experienceData={experienceData}
          />

          <Button disabled={isInRequest} type="submit">
            <FormattedMessage id="onboarding.form.fields.project_list.save_projects_button_label" />
          </Button>
          {isProfile && resetFormCounter && (
            <Button
              css={styles.cancelBtn}
              buttonType="ghost"
              onClick={() => {
                resetFormCounter();
              }}
              disabled={isInRequest}
            >
              <FormattedMessage id="profileManagement.heading.buttons.cancel" />
            </Button>
          )}
        </form>
      </FormProvider>
    </div>
  );
};
