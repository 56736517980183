import { css } from '@emotion/react';

export const root = (background: string) => css`
  position: absolute;
  height: calc(100% + 2px);
  width: calc(100% + 2px);
  background: ${background};
  z-index: 5;
`;

export const topOffset = css`
  left: -1px;
  bottom: -1px;
`;

export const rightOffset = css`
  left: -1px;
  top: -1px;
`;

export const bottomOffset = css`
  ${rightOffset};
`;

export const leftOffset = css`
  right: -1px;
  top: -1px;
`;
