import React from 'react';
import * as styles from 'modules/admin/styles/Section.styles';
import { SerializedStyles } from '@emotion/react';

interface SectionHeadProps {
  title: string | React.ReactNode;
  customStyles?: SerializedStyles | SerializedStyles[];
}

export const SectionHead: React.FC<SectionHeadProps> = ({
  children,
  title,
  customStyles,
}) => {
  return (
    <div css={[styles.sectionHead, customStyles]}>
      <div css={styles.sectionHeadTitle}>
        <h1>{title}</h1>
      </div>

      <div>{children}</div>
    </div>
  );
};
