type StatusType = {
  value: PublishStatus;
  label: string;
};

export function getFilteredStatuses(
  statuses: StatusType[],
  filters?: CompanyOpeningFilters,
) {
  const existing = filters?.statuses.filter((i) => i.count > 0);

  return statuses.filter((item) =>
    existing?.some((i) => item.value === i.status),
  );
}

export function getFilteredLocations(filters?: CompanyOpeningFilters) {
  return filters?.locations.map((item) => ({
    value: item.location,
    label: item.location,
  }));
}

export function getFilteredDepartments(filters?: CompanyOpeningFilters) {
  return filters?.departments.map((item) => ({
    value: item.id,
    label: item.name,
  }));
}
