import { flex, font, margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { isPast } from 'date-fns';
import { FormattedMessage, Link, navigate } from 'gatsby-plugin-intl';
import { navigate as gatsbyNavigate } from 'gatsby';
import {
  ActionModal,
  Button,
  OpeningSocialShare,
  RadioButtons,
  useGracePeriod,
  useToastify,
} from 'modules/app';
import Preview from 'assets/icons/showPassword.svg';
import Edit from 'assets/icons/EditBlack.svg';

import * as dateStyles from 'modules/app/styles/DatePicker.styles';
import * as buttonStyles from 'modules/app/styles/Button.styles';
import * as styles from '../styles/Openings.styles';
import React, { useState } from 'react';
import { OpeningActions } from './OpeningActions';
import { StartDateLabel } from './StartDateLabel';
import { useCheckIfImpersonate } from 'modules/admin/static';
import { useOpeningActions } from '../hooks/useOpeningActions';
import { usePlanRestrictions } from '../hooks';
import { AnimatePresence } from 'framer-motion';
import { OpeningRestrictionModal } from './OpeningRestrictionModal';
import { DeactivateLimitModal } from './DeactivateLimitModal';
import { handleOpeningSubmitDates } from '../helpers';

const DatePickerButton = React.lazy(
  () => import('modules/app/components/form/DatePickerButton'),
);

export interface ScheduleData {
  companyId: string;
  openingId: string;
  data: {
    status: string;
    publishedAt: string;
  };
}

interface Props {
  data: CompanyOpening;
  isApplication?: boolean;
  theme?: 'light' | 'dark';
  onArchive: (companyId: string, openingId: string) => void;
  onDelete: (companyId: string, openingId: string) => void;
  onUpdateSceduleDate: (
    companyId: string,
    openingId: string,
    data: {
      status: string;
      publishedAt: string;
    },
  ) => void;
  onUpdateCloseDate: (
    companyId: string,
    openingId: string,
    data: Pick<Opening, 'expiresAt'>,
  ) => void;
}

export const CompanyOpeningActions: React.FC<Props> = ({
  data,
  isApplication = false,
  theme = 'light',
  onArchive,
  onDelete,
  onUpdateSceduleDate,
  onUpdateCloseDate,
}) => {
  const [isLimitModal, setIsLimitModal] = useState(false);
  const [isDeactivateModal, setIsDeactivateModal] = useState(false);
  const [scheduleData, setScheduleData] = useState<ScheduleData>();

  const { isPlanLimit, isDeactivateLimit } = usePlanRestrictions();
  const { isGracePeriod } = useGracePeriod();
  const isImpersonate = useCheckIfImpersonate();
  const { toastError } = useToastify();
  const isScheduleDisabled =
    data.status === 'published-public' ||
    data.status === 'published-internal' ||
    isGracePeriod;
  const {
    selectedValue,
    setSelectedValue,
    today,
    minClosingDate,
    isSocialShare,
    scheduleStatusItems,
    formatDate,
    formatDateForPublishedDropdown,
  } = useOpeningActions(data);

  const onScheduleDateSelect = (date: Date) => {
    if (isPlanLimit) {
      setIsLimitModal(true);
      return;
    }

    const formatedDate = handleOpeningSubmitDates(date) || '';

    if (isPast(date)) {
      toastError('openings.scheduleOpening.pastScheduleDate');
      return;
    }

    if (isDeactivateLimit) {
      setScheduleData({
        companyId: data.companyId,
        openingId: data.id,
        data: {
          status: selectedValue || 'scheduled-for-publishing-(internal)',
          publishedAt: formatedDate,
        },
      });
      setIsDeactivateModal(true);
      return;
    }

    onUpdateSceduleDate(data.companyId, data.id, {
      status: selectedValue || 'scheduled-for-publishing-(internal)',
      publishedAt: formatedDate,
    });
  };

  const onCloseDateSelect = (date: Date) => {
    const formatedDate = handleOpeningSubmitDates(date) || '';

    onUpdateCloseDate(data.companyId, data.id, {
      expiresAt: formatedDate,
    });
  };

  const onPreview = () => {
    if (
      ['published-public', 'closed', 'published-internal'].includes(data.status)
    ) {
      gatsbyNavigate(`/public-opening/${data.companyHandle}/${data.slug}`);
      return;
    }

    navigate(`/opening/${data.companyId}/${data.id}`);
  };

  return (
    <>
      <AnimatePresence>
        {isLimitModal && isPlanLimit && (
          <ActionModal isOpen={isLimitModal} setIsOpen={setIsLimitModal}>
            <OpeningRestrictionModal
              setIsOpen={setIsLimitModal}
              handle={data.companyHandle}
            />
          </ActionModal>
        )}
        {isDeactivateModal && isDeactivateLimit && (
          <ActionModal
            isOpen={isDeactivateModal}
            setIsOpen={setIsDeactivateModal}
          >
            <DeactivateLimitModal
              setIsOpen={setIsDeactivateModal}
              onScheduleOpening={onUpdateSceduleDate}
              scheduleData={scheduleData}
            />
          </ActionModal>
        )}
      </AnimatePresence>

      <div css={styles.wrapper}>
        <div css={styles.inner}>
          {!isApplication &&
            (data.status === 'published-public' ||
              data.status === 'published-internal') && (
              <Link
                to={`/profile/company/openings/${data.companyHandle}/opening/rematch/${data.id}`}
                css={
                  isImpersonate || isGracePeriod
                    ? styles.matchingLinkDisabled
                    : styles.matchingLink
                }
              >
                <FormattedMessage id="openings.findCandidates" />
              </Link>
            )}
          <div css={dateStyles.companyOpeningsDateWrapper}>
            <p css={dateStyles.companyOpeningsDateTitle}>
              <FormattedMessage id="openings.overview.lastChange" />
            </p>
            <p css={font.size.small}>{formatDate(data.updatedAt)}</p>
          </div>
          <div css={dateStyles.companyOpeningsDateWrapper}>
            <p css={dateStyles.companyOpeningsDateTitle}>
              <FormattedMessage id="openings.overview.lastApplication" />
            </p>
            <p css={font.size.small}>{formatDate(data.lastApplication)}</p>
          </div>
          <React.Suspense fallback={null}>
            <div css={margin.right.xhuge}>
              <DatePickerButton
                isDisabled={isScheduleDisabled}
                label={
                  <StartDateLabel
                    openingStatus={data.status}
                    openingPublishDate={data.publishedAt}
                  />
                }
                dateString={formatDateForPublishedDropdown(data.publishedAt)}
                onSelect={onScheduleDateSelect}
                minDate={today}
                buttonIcon={isScheduleDisabled ? <></> : <Edit />}
                theme={theme}
                isOpening
              >
                <>
                  <RadioButtons
                    label="openings.overview.selectStatus"
                    name="status"
                    data={scheduleStatusItems}
                    selected={selectedValue}
                    onValueChange={setSelectedValue}
                  />
                </>
              </DatePickerButton>
            </div>
          </React.Suspense>
          <div css={margin.right.xhuge}>
            <DatePickerButton
              label={
                <FormattedMessage id="openings.overview.applicationsClosing" />
              }
              isDisabled={isImpersonate || isGracePeriod}
              dateString={formatDateForPublishedDropdown(data.expiresAt)}
              onSelect={onCloseDateSelect}
              minDate={minClosingDate}
              buttonIcon={<Edit />}
              theme={theme}
              isOpening
            />
          </div>
        </div>
        <div css={[flex.display.flex, flex.align.center]}>
          {data.status !== 'archived' && (
            <Button
              disabled={isGracePeriod}
              buttonType="ghost"
              size="small"
              onClick={onPreview}
              css={
                theme === 'light'
                  ? buttonStyles.svgButton
                  : buttonStyles.svgButtonDark
              }
            >
              <Preview />
            </Button>
          )}

          {isSocialShare && data.status !== 'closed' && (
            <OpeningSocialShare
              companyHandle={data.companyHandle}
              openingSlug={data.slug}
              theme={theme}
              position="topRight"
              isDisabled={isGracePeriod}
            />
          )}

          <OpeningActions
            theme={theme}
            data={data}
            onArchive={onArchive}
            onDelete={onDelete}
          />
        </div>
      </div>
    </>
  );
};
