import { graphql, useStaticQuery } from 'gatsby';

interface SiteMetadata {
  title: string;
  description: string;
  author: string;
  siteUrl: string;
  image: string;
}

export default function useSiteMetadata(): SiteMetadata {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
          image
        }
      }
    }
  `);

  return data.site.siteMetadata;
}
