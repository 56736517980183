import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HttpError, useFormGuard } from 'modules/app';
import { debounce } from 'throttle-debounce';
import { FormattedMessage } from 'react-intl';
import { FormError } from 'modules/app/intl';
import * as styles from 'modules/app/styles/ExpertiseForm.styles';
import { useTalentExpertise } from 'modules/talents';
import { useAssets } from 'modules/shared';

type Props = {
  setChosenExpertise: React.Dispatch<
    React.SetStateAction<Expertise[] | undefined>
  >;
  totalExpertise: number;
  setTotalExpertise: React.Dispatch<React.SetStateAction<number>>;
};

export const TalentExpertiseForm: React.FC<Props> = ({
  setChosenExpertise,
  totalExpertise,
  setTotalExpertise,
}) => {
  const methods = useForm<{ expertise: string }>({
    defaultValues: {
      expertise: '',
    },
  });
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setError,
    clearErrors,
  } = methods;
  useFormGuard(methods);

  const { suggestedTags, setSuggestedTags, getAllTags } = useAssets();

  const { httpError, addExpertise, getExpertiseWithoutSet } =
    useTalentExpertise();

  const handleAfterAdd = () => {
    reset({ expertise: '' });
    setSuggestedTags(undefined);
    getExpertiseWithoutSet().then((res) => {
      if (!res) return;

      setTotalExpertise(res.total);
      setChosenExpertise(res.data);
    });
  };

  const addUserExpertise = async (data: { expertise: string }) => {
    if (/[^A-Za-z0-9#+]/g.test(data.expertise)) return;
    if (totalExpertise < 10) {
      await addExpertise(data.expertise, handleAfterAdd);
      return;
    }

    reset({ expertise: '' });
    setSuggestedTags(undefined);
    setError('expertise', {
      type: 'manual',
      message: 'http_errors.max_expertise',
    });
  };

  const onInputChange = debounce(300, async (text: string) => {
    if (/[^A-Za-z0-9#+]/g.test(text)) {
      setError('expertise', {
        type: 'manual',
        message: 'shared.errors.string',
      });
      return;
    }

    getAllTags(text);
    clearErrors('expertise');
  });

  return (
    <div css={styles.expertise}>
      <FormProvider {...methods}>
        <form
          css={
            errors.expertise ? styles.expertiseFormError : styles.expertiseForm
          }
          onSubmit={handleSubmit(addUserExpertise)}
        >
          <div css={styles.expertiseFormHash}>#</div>
          <label
            htmlFor="expertise"
            css={errors.expertise ? styles.labelError : styles.label}
          >
            <FormattedMessage id="onboarding.form.fields.expertise.choose_a_tag" />
          </label>
          <input
            css={styles.expertiseFormInput}
            type="text"
            placeholder="tag"
            {...register('expertise', {
              required: true,
              minLength: 1,
              maxLength: {
                value: 30,
                message: 'shared.errors.max',
              },
              onChange: (e) => onInputChange(e.target.value),
            })}
            autoComplete="off"
          />
          <input css={styles.expertiseFormSubmit} type="submit" value="Add" />
        </form>

        <FormError
          errors={errors}
          name="expertise"
          values={{
            max: 30,
          }}
        />
      </FormProvider>

      {suggestedTags && (
        <div css={styles.suggested}>
          {suggestedTags?.map((tag) => (
            <div css={styles.suggestedItem} key={tag.id}>
              <p
                onClick={() => {
                  addUserExpertise({ expertise: tag.name });
                }}
                css={styles.suggestedText}
              >
                {tag.name}
              </p>
              <p css={styles.suggestedResults}>
                {tag.totalCount.toString()} results
              </p>
            </div>
          ))}
        </div>
      )}

      {httpError && <HttpError error={httpError} />}
    </div>
  );
};
