import { css } from '@emotion/react';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  background: hsla(var(--color-text-1), 65%);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: calc(var(--unit) * 3);

  @media ${breakpoints.medium} {
    padding: calc(var(--unit) * 5);
  }
`;

export const content = css`
  background: hsl(var(--color-white-11));
  border-radius: calc(var(--unit) * 2);
  padding: calc(var(--unit) * 10) calc(var(--unit) * 7);
  max-width: calc(var(--unit) * 160);
  width: calc(var(--unit) * 160);

  @media ${breakpoints.medium} {
    padding: calc(var(--unit) * 15) calc(var(--unit) * 10);
  }
`;
