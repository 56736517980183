import { FieldErrors } from 'react-hook-form';

interface UseFieldArrayErrorTransformer {
  isFieldArrayError: boolean;
  fieldArrayError?: FieldErrors;
}

export const useFieldArrayErrorTransformer = (
  errors: { [key: string]: FieldErrors },
  name: string,
  fieldArrayKey: number | undefined,
): UseFieldArrayErrorTransformer => {
  if (!Number.isInteger(fieldArrayKey)) {
    return { isFieldArrayError: false };
  }

  const [formName, index, fieldName] = name.split('.');

  if (parseInt(index) !== fieldArrayKey) {
    return { isFieldArrayError: false };
  }

  if (!errors[formName]) {
    return { isFieldArrayError: false };
  }

  const formErrors = errors[formName];

  if (!formErrors[fieldArrayKey]) {
    return { isFieldArrayError: false };
  }

  const fieldError = formErrors[fieldArrayKey];

  if (!fieldError[fieldName]) {
    return { isFieldArrayError: false };
  }

  return {
    isFieldArrayError: true,
    fieldArrayError: fieldError[fieldName],
  };
};
