import React from 'react';
import { RouteComponentProps } from '@reach/router';
import queryString from 'query-string';
import * as styles from 'modules/admin/styles/Sidebar.styles';
import { Link } from 'gatsby-plugin-intl';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

export const Sidebar: React.FC<RouteComponentProps> = () => {
  const url = queryString.parse(location.search);

  const { userStaffRole } = useSelector((state: AppState) => state.session);

  return (
    <div css={[styles.sidebar, styles.subSidebar]}>
      <div css={styles.stickyNavigation}>
        <div css={styles.sidebar__navigation}>
          <Link
            to={`/admin/staff/?user=admin`}
            className={url.user === 'admin' || !url.user ? 'active' : ''}
          >
            Admin Users
          </Link>
          <Link
            to="/admin/staff/?user=support"
            className={url.user === 'support' ? 'active' : ''}
          >
            Support Users
          </Link>

          {userStaffRole?.name === 'SuperAdmin' && (
            <Link
              to={`/admin/staff/?user=superadmin`}
              className={url.user === 'superadmin' ? 'active' : ''}
            >
              SuperAdmin Users
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
