export const checkSessionStatus = (session: Date | string) => {
  const now = new Date();
  const userSession = new Date(session);

  userSession.setMinutes(
    userSession.getMinutes() - userSession.getTimezoneOffset(),
  );

  return now.getTime() < userSession.getTime();
};
