export const arrayDiff = (
  array1: string[],
  array2: Array<string> | unknown,
) => {
  const diff: string[] = [];

  if (!Array.isArray(array2)) return diff;

  for (const k of array1) {
    if (!array2.includes(k)) {
      diff.push(k);
    }
  }

  for (const k of array2) {
    if (!array1.includes(k)) {
      diff.push(k);
    }
  }

  return diff;
};
