import { useOutOfBounds } from 'modules/app/hooks';
import { useCallback, useEffect, useRef, useState } from 'react';

export type ItemType = {
  id: string;
  element: JSX.Element | string;
  disabled?: boolean;
};

export type NewItemType = {
  id: string;
  element: string;
  disabled?: boolean;
  items?: { id: string; element: string }[];
};

export const useDropdownMenu = (items?: ItemType[]) => {
  const [expanded, setExpanded] = useState(false);
  const [isOtherExpanded, setIsOtherExpanded] = useState(false);
  const [currentItem, setCurrentItem] = useState<NewItemType>();
  const [values, setValues] = useState(items);
  const ref = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const closeOnEsc = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setExpanded(false);
      setIsOtherExpanded(false);
      setCurrentItem(undefined);
    }
  }, []);

  const closeOnOutsideClick = useCallback((e: MouseEvent) => {
    if (
      !ref.current ||
      !wrapperRef.current ||
      wrapperRef.current.contains(e.target as Node)
    ) {
      return;
    }

    setExpanded(false);
    setIsOtherExpanded(false);
    setCurrentItem(undefined);
  }, []);

  const onSearch = (v: string) => {
    const newItems = items
      ?.filter((item) =>
        item.element.toString().toLowerCase().includes(v.toLowerCase()),
      )
      .map((item) => ({
        ...item,
        element: item.element,
      }));

    setValues(newItems);
  };

  useEffect(() => {
    if (expanded) {
      document.addEventListener('keydown', closeOnEsc);
      document.addEventListener('mousedown', closeOnOutsideClick);

      return;
    }

    document.removeEventListener('keydown', closeOnEsc);
    document.removeEventListener('mousedown', closeOnOutsideClick);
  }, [expanded, closeOnEsc, closeOnOutsideClick]);

  useOutOfBounds(ref, expanded);

  useEffect(() => {
    if (expanded) return;

    setValues(items);
  }, [expanded, items]);

  return {
    ref,
    values,
    expanded,
    wrapperRef,
    currentItem,
    isOtherExpanded,
    onSearch,
    setExpanded,
    setCurrentItem,
    setIsOtherExpanded,
  };
};
