import React, { useState } from 'react';
import { Dropdown, Avatar } from 'modules/app';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { useSelector } from 'react-redux';
import { AppState, isUserStaffRole } from 'modules/redux-store';
import { useCheckIfImpersonate } from 'modules/admin/static';
import { useLocation } from '@reach/router';

import * as styles from './styles/Dropdown.styles';
import Plus from 'assets/icons/plus.svg';
import Support from 'assets/icons/support.svg';
import Logout from 'assets/icons/logout.svg';
import Settings from 'assets/icons/settings.svg';
import SmallCheck from 'assets/icons/checkGreen.svg';
import Eye from 'assets/icons/showPassword.svg';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { Link } from 'modules/app/intl/Link';
import { CompanyLink } from './CompanyLink';
import { useAuth } from 'modules/auth';

interface Props {
  firstName: string | undefined;
  lastName: string | undefined;
  profilePicture: string | undefined;
  theme?: 'light' | 'dark';
}

export const BarDropdown: React.FC<Props> = ({
  firstName,
  lastName,
  profilePicture,
  theme = 'dark',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state: AppState) => state.session.user);
  const companyRoles = useSelector(
    (state: AppState) => state.session.userCompanyRoles,
  );
  const adminRole = useSelector(
    (state: AppState) => state.session.userStaffRole,
  );
  const isImpersonate = useCheckIfImpersonate();
  const { pathname } = useLocation();
  const { logoutUser } = useAuth();

  function onLogout() {
    if (isImpersonate) return;

    logoutUser();
  }

  return (
    <div css={margin.left.med}>
      <Dropdown
        arrow="top"
        button={
          <Avatar
            customStyles={styles.profilePicture}
            imagePath={profilePicture || null}
            name={firstName}
            lastName={lastName}
            isShadow={false}
          />
        }
        position="bottomRight"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        theme={theme}
        customStyles={styles.root}
      >
        <Link
          className={
            pathname.includes('profile/talent') ? 'link--active' : 'link'
          }
          to="/profile/talent/overview/invitations"
        >
          <div css={styles.avatarWrapper}>
            <Avatar
              customStyles={styles.avatar}
              name={user?.firstName}
              lastName={user?.lastName}
              imagePath={user?.profilePicture?.medium || null}
              isShadow={false}
            />
            <p css={styles.name}>{user?.firstName}</p>
            <SmallCheck className="check" />
          </div>
          <Eye className="eye" />
        </Link>

        {companyRoles &&
          companyRoles.map((company) => {
            if (company.companyDeletedAt) {
              return;
            }

            return <CompanyLink key={company.companyId} company={company} />;
          })}

        {!isImpersonate && (
          <Link
            getProps={({ isCurrent }) => {
              return {
                className: isCurrent ? 'link--active' : 'link',
              };
            }}
            to="/profile/settings/company/create-company"
          >
            <div css={styles.svgWrapper}>
              <Plus css={styles.plus} />
            </div>
            <p css={styles.name}>
              <FormattedMessage id="profileManagement.profileBar.createOrganization" />
            </p>
            <SmallCheck className="check" />
          </Link>
        )}

        {isUserStaffRole(adminRole) && (
          <Link
            getProps={({ isPartiallyCurrent }) => {
              return {
                className: isPartiallyCurrent ? 'link--active' : 'link',
              };
            }}
            to="/admin"
          >
            <div css={styles.svgWrapper}>
              <Support css={styles.plus} />
            </div>
            <p css={styles.name}>Backoffice</p>
            <SmallCheck className="check" />
          </Link>
        )}

        <hr />

        <Link
          className={
            pathname.includes('/profile/settings') &&
            !pathname.includes('create-company')
              ? 'link--active'
              : 'link'
          }
          to="/profile/settings/user/account"
        >
          <div css={styles.svgWrapper}>
            <Settings />
          </div>
          <p css={styles.name}>
            <FormattedMessage id="profileManagement.profileBar.settings" />
          </p>
          <SmallCheck className="check" />
        </Link>

        <div
          className="link"
          css={isImpersonate && styles.logoutDisabled}
          onClick={onLogout}
        >
          <div css={styles.svgWrapper}>
            <Logout />
          </div>
          <p css={styles.name}>
            <FormattedMessage id="profileManagement.profileBar.signOut" />
          </p>
          <SmallCheck className="check" />
        </div>
      </Dropdown>
    </div>
  );
};
