import { css } from '@emotion/react';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  flex-wrap: wrap;
  overflow: hidden;

  & > input {
    flex-basis: 100%;

    @media ${breakpoints.medium} {
      flex-basis: 70%;
    }
  }

  & > label {
    display: block;
    color: hsl(var(--color-borderDark-5));
    margin-top: 25px;
  }
`;
