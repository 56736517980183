import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { Controller, useFormContext, RegisterOptions } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FormError } from 'modules/app/intl';
import { TextInput } from './TextInput';
import { useIntl } from 'gatsby-plugin-intl';

interface Props extends InputHTMLAttributes<Omit<HTMLInputElement, 'type'>> {
  name: string;
  label?: string;
  value: string;
  constraints: RegisterOptions;
  onValueChange?: (text: string) => void;
  customStyles?: SerializedStyles;
  reset?: number;
  isOptional?: boolean;
  showError?: boolean;
  maxChar?: string;
}

export const InputField: React.FC<Props> = ({
  name,
  value,
  constraints,
  label,
  onValueChange,
  customStyles,
  reset,
  isOptional = false,
  showError = true,
  maxChar,
  ...rest
}) => {
  const intl = useIntl();
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const [val, setVal] = useState(value);

  const err = errors[name];

  useEffect(() => {
    setValue(name, value);
    setVal(value);
  }, [value, setValue, name]);

  useEffect(() => {
    if (Number.isInteger(reset) && reset !== 0) {
      setVal('');
    }
  }, [reset]);

  return (
    <>
      <div css={customStyles}>
        <Controller
          name={name}
          control={control}
          rules={constraints}
          render={({ field: { onChange } }) => {
            return (
              <TextInput
                {...rest}
                showError={Boolean(err)}
                isOptional={isOptional}
                maxChar={maxChar}
                formErrors={
                  showError && (
                    <FormError
                      errors={errors}
                      name={name}
                      values={{
                        min: constraints.minLength?.value,
                        max: constraints.maxLength?.value,
                      }}
                    />
                  )
                }
                id={label}
                label={label && <FormattedMessage id={label} />}
                placeholder={
                  rest.placeholder &&
                  intl.formatMessage({ id: rest.placeholder })
                }
                value={val}
                type="text"
                onChange={(e) => {
                  const value = e.target.value;

                  if (!value.trim()) {
                    onChange('');
                    setVal('');
                    onValueChange?.('');

                    return;
                  }

                  onChange(e.target.value);
                  setVal(e.target.value);

                  onValueChange?.(e.target.value);
                }}
              />
            );
          }}
        />
      </div>
    </>
  );
};
