import { useHttpClient, Value } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useEffect, useState } from 'react';

export const useTeamMembersValues = (companyId: string) => {
  const [isDepartmentsLoading, setIsDepartmentsLoading] = useState(true);
  const [isOpeningsLoading, setIsOpeningsLoading] = useState(true);

  const [departments, setDepartments] = useState<Value[]>();
  const [openings, setOpenings] = useState<Value[]>();
  const [valuesError, setValuesError] = useState<ApiErrorData>();

  const http = useHttpClient('/api/v1/company');

  async function getDepartments() {
    setIsDepartmentsLoading(true);
    try {
      const { data } = await http.get<Department[]>(
        `${companyId}/settings/departments`,
      );
      setDepartments(
        data.map((department) => ({
          value: department.id,
          label: department.name,
        })),
      );
      return;
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      return e;
    } finally {
      setIsDepartmentsLoading(false);
    }
  }

  async function getOpenings() {
    setIsOpeningsLoading(true);
    try {
      const { data } = await http.get<OpeningsList[]>(
        `${companyId}/openings/short-list`,
      );
      setOpenings(
        data.map((opening) => ({
          value: opening.id,
          label: opening.title,
        })),
      );
      return;
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      return e;
    } finally {
      setIsOpeningsLoading(false);
    }
  }

  async function getValues() {
    const res = await Promise.all([getDepartments(), getOpenings()]);
    const error = res.find((e) => e !== undefined);

    if (error) {
      setValuesError(error);
      return;
    }
  }

  useEffect(() => {
    getValues();
  }, []);

  return {
    valuesError,
    departments,
    isDepartmentsLoading,
    isOpeningsLoading,
    openings,
  };
};
