import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { SectionLink } from './SectionLink';
import { useLocation } from '@reach/router';
import { BarDropdown } from './BarDropdown';
import { QuickSearch } from './QuickSearch';

import * as styles from './styles/ProfileBar.styles';
import { useNormalizedPermissions } from '../../auth';
import { companyDataAction } from 'modules/profile';

interface Props {
  companyData: Company;
}

export const CompanyProfileBar: React.FC<Props> = ({ companyData }) => {
  const dispatch = useDispatch();
  const company = useSelector(
    (state: AppState) => state.companyData.companyData,
  );
  const { pathname } = useLocation();
  const hasPermission = useNormalizedPermissions();

  useEffect(() => {
    return () => {
      dispatch(companyDataAction.reset());
    };
  }, []);

  return (
    <>
      <div css={styles.barSection}>
        <SectionLink
          path={`/profile/company/overview/${companyData.handle}`}
          text={<FormattedMessage id="profileManagement.profileBar.overview" />}
          pathname={pathname}
          active={'/profile/company/overview'}
        />

        <SectionLink
          path={`/profile/company/openings/${companyData.handle}`}
          pathname={pathname}
          text={
            <FormattedMessage id="profileManagement.profileBar.manageOpenings" />
          }
          active="/profile/company/openings/"
        />

        {hasPermission('addEditInformation', companyData.handle) && (
          <SectionLink
            path={`/profile/company/manage/${companyData.handle}/basic-information`}
            text={
              <FormattedMessage id="profileManagement.profileBar.manageCompany" />
            }
            pathname={pathname}
            active="/profile/company/manage/"
          />
        )}

        <div css={styles.navSection}>
          <QuickSearch />

          <BarDropdown
            firstName={company?.name}
            lastName={undefined}
            profilePicture={company?.iconPicture?.medium}
          />
        </div>
      </div>
    </>
  );
};
