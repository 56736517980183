import { createAction } from 'modules/redux-store';

export const talentOpeningsAction = {
  searchOpenings: (payload: {
    openings?: OpeningsSearch[];
    page?: number;
    lastPage?: number;
  }) => createAction('talentOpenings/OPENINGS', payload),
  recentOpenings: (payload: OpeningsSearch[]) =>
    createAction('talentOpenings/RECENT_OPENINGS', payload),
  loading: () => createAction('talentOpenings/LOADING'),
  error: (payload: ApiErrorData) =>
    createAction('talentOpenings/ERROR', payload),

  searchParams: (payload: { params: OpeningsSearchParams }) =>
    createAction('talentOpenings/PARAMS', payload),

  matchingLoading: () => createAction('talentOpenings/MATCHING_LOADING'),
  matchingError: (payload: ApiErrorData) =>
    createAction('talentOpenings/MATCHING_ERROR', payload),
  setMatching: (payload?: TalentMatch[]) =>
    createAction('talentOpenings/SET_MATCHING', payload),

  reset: () => createAction('talentOpenings/RESET'),
};
