import React, { useState } from 'react';
import { Avatar, LoadingImg } from 'modules/app';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import DeleteIcon from 'assets/icons/delete.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { useConversations } from '../hooks';
import { Link } from 'gatsby-plugin-intl';

import * as styles from 'modules/talentMessages/styles/ConversationList.styles';

interface Props {
  conversation: Conversation;
}

export const ConversationListItem: React.FC<Props> = ({ conversation }) => {
  const { unreadConversations } = useSelector(
    (state: AppState) => state.talentMessages,
  );
  const { conversationId, isDeleting, deleteConversation } = useConversations();

  const [isActions, setIsActions] = useState(false);

  const lastMessage = format(
    new Date(conversation.updatedAt + 'Z'),
    "do MMM 'at' h:mm a",
  );

  const isActive = conversationId === conversation.id;
  const isUnread = Boolean(
    unreadConversations.find((unread) => unread === conversation.id),
  );
  const avatarStyles = conversation.companyIconPicture
    ? styles.itemAvatar
    : styles.itemNoAvatar;
  const showActions = (isActions || isActive) && !isUnread;

  function onDelete(e: React.MouseEvent<SVGElement, MouseEvent>) {
    e.stopPropagation();
    e.preventDefault();

    deleteConversation(conversation.id);
  }

  return (
    <Link
      css={isActive ? styles.itemActive : styles.item}
      to={`/profile/talent/messages?messageId=${conversation.id}`}
      onMouseEnter={() => setIsActions(true)}
      onMouseLeave={() => setIsActions(false)}
    >
      <div css={styles.flex}>
        <Avatar
          isShadow={false}
          name={conversation.companyName}
          imagePath={conversation.companyIconPicture?.large}
          customStyles={avatarStyles}
        />
        <div>
          <p css={styles.itemCompany}>
            {conversation.companyName}
            <span>{lastMessage}</span>
          </p>
          <p css={styles.itemOpening}>{conversation.subject}</p>
        </div>
      </div>

      {isUnread && <div css={styles.dot} />}

      <AnimatePresence>
        {(showActions || isDeleting) && (
          <motion.div
            {...FADE_IN_MOVE_Y_REGULAR}
            transition={{ duration: 0.3, delay: 0.1 }}
            css={styles.actions}
          >
            {isDeleting ? (
              <LoadingImg />
            ) : (
              <DeleteIcon css={styles.icon} onClick={onDelete} />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </Link>
  );
};
