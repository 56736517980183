import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';
import { colors } from 'style/variables';

export const pagination = css`
  display: flex;
  justify-content: center;
  width: 100%;

  > div {
    width: 100%;
    display: flex;
  }

  ul {
    width: 100%;
  }

  .previous {
    margin-right: auto;
  }

  .next {
    margin-left: auto;
  }
`;

export const loading = css`
  opacity: 0.4;
  transition: 0.2s;
  pointer-events: none;
`;

export const exportContainer = css`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: ${spacing(24)};

  & > span {
    margin-top: ${spacing(12)};
    ${font.size.tiny};
    color: ${colors.border};
  }
`;
