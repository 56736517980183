import React from 'react';
import { FlatLink } from 'modules/admin/components';

export const generateDropdownOptions = (
  options: string[],
  target?: string | null,
) => {
  const optionsArr: { id: string; element: React.ReactElement }[] = [];

  options.forEach((item: string) => {
    optionsArr.push({
      id: item,
      element: <FlatLink text={item} active={Boolean(target === item)} />,
    });
  });

  return optionsArr;
};
