export const portfolioValues: { value: string; label: string }[] = [
  {
    value: 'behance',
    label: 'Behance',
  },
  {
    value: 'instagram',
    label: 'Instagram',
  },
  {
    value: 'dribbble',
    label: 'Dribbble',
  },
  {
    value: 'medium',
    label: 'Medium',
  },
  {
    value: 'facebook',
    label: 'Facebook',
  },
  {
    value: 'youtube',
    label: 'Youtube',
  },
  {
    value: 'twitter',
    label: 'Twitter',
  },
  {
    value: 'linkedin',
    label: 'LinkedIn',
  },
  {
    value: 'coroflot',
    label: 'Coroflot',
  },
  {
    value: 'github',
    label: 'GitHub',
  },
  {
    value: 'gitlab',
    label: 'GitLab',
  },
  {
    value: 'bitbucket',
    label: 'Bitbucket',
  },
  {
    value: 'other',
    label: 'Other',
  },
];
