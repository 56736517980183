import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';
import { UseFormSetError } from 'react-hook-form';

export interface CultureCharacteristicsData {
  dominant_chars_env: string | null;
  company_changes: string | null;
  behaviors_norms: string | null;
  leadership_decision_making: string | null;
  strategic_emphasis: string | null;
  criteria_success: string | null;
}

export const useCultureCharacteristics = () => {
  const { toastSuccess } = useToastify();

  const [cultureCharacteristics, setCultureCharacteristics] =
    useState<CultureCharacteristic[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<HttpError>();

  const http = useHttpClient('/api/v1/company');

  const getFormRequest = (
    data: CultureCharacteristicsData,
  ): CultureCharacteristicsRequest => ({
    answers: [
      {
        code: 'dominant_chars_env',
        answer: data.dominant_chars_env ? data.dominant_chars_env : null,
      },
      {
        code: 'company_changes',
        answer: data.company_changes ? data.company_changes : null,
      },
      {
        code: 'behaviors_norms',
        answer: data.behaviors_norms ? data.behaviors_norms : null,
      },
      {
        code: 'leadership_decision_making',
        answer: data.leadership_decision_making
          ? data.leadership_decision_making
          : null,
      },
      {
        code: 'strategic_emphasis',
        answer: data.strategic_emphasis ? data.strategic_emphasis : null,
      },
      {
        code: 'criteria_success',
        answer: data.criteria_success ? data.criteria_success : null,
      },
    ],
  });

  async function getCultureCharacteristics(companyId: string) {
    setIsLoading(true);
    try {
      const { data } = await http.get<CultureCharacteristicsResponse>(
        `${companyId}/profile/culture-questions`,
      );
      setCultureCharacteristics(data.data);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function addCultureCharacteristics(
    companyId: string,
    info: CultureCharacteristicsData,
    onError: UseFormSetError<CultureCharacteristicsData>,
    callback: (data: CultureCharacteristic[]) => void,
  ) {
    setError(undefined);
    try {
      const { data } = await http.post<
        CultureCharacteristic[],
        CultureCharacteristicsRequest
      >(`${companyId}/profile/culture-questions`, getFormRequest(info));
      toastSuccess('shared.pills.successDefaultText');
      callback(data);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.data?.errors) {
        e.data.errors.answers0Answer &&
          onError('dominant_chars_env', {
            type: 'validate',
          });
        e.data.errors.answers1Answer &&
          onError('company_changes', {
            type: 'validate',
          });
        e.data.errors.answers2Answer &&
          onError('behaviors_norms', {
            type: 'validate',
          });
        e.data.errors.answers3Answer &&
          onError('leadership_decision_making', {
            type: 'validate',
          });
        e.data.errors.answers4Answer &&
          onError('strategic_emphasis', {
            type: 'validate',
          });
        e.data.errors.answers5Answer &&
          onError('criteria_success', {
            type: 'validate',
          });
        return;
      }
      setError(e);
    }
  }

  return {
    error,
    isLoading,
    cultureCharacteristics,
    setCultureCharacteristics,
    getCultureCharacteristics,
    addCultureCharacteristics,
  };
};
