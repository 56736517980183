import React from 'react';

import * as styles from 'modules/app/styles/ButtonGroup.styles';
import { SerializedStyles } from '@emotion/react';

interface Props {
  css?: SerializedStyles;
}

export const ButtonGroup: React.FC<Props> = ({ children, css, ...rest }) => {
  return (
    <div css={[styles.root, css]} {...rest}>
      {children}
    </div>
  );
};
