import { useSocialScriptLoader } from './useSocialScriptLoader';

const links = {
  twitter: 'https://twitter.com/intent/tweet?text=',
  facebook: 'https://facebook.com/sharer/sharer.php?u=',
  linkedin: 'https://www.linkedin.com/shareArticle?mini=true&url=',
};

export const useSocialPopup = () => {
  const onLoad = useSocialScriptLoader();

  return (type: 'twitter' | 'facebook' | 'linkedin', text?: string) => {
    onLoad(type, () => {
      const dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : window.screenX;
      const dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : window.screenY;

      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width;
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height;

      const systemZoom = width / window.screen.availWidth;
      const left = (width - 600) / 2 / systemZoom + dualScreenLeft;
      const top = (height - 600) / 2 / systemZoom + dualScreenTop;
      const newWindow = window.open(
        `${links[type]}${text ? text : ''}`,
        '',
        `
      scrollbars=yes,
      resizable=yes,
      menubar=no,
      toolbar=no
      width=${600 / systemZoom}, 
      height=${600 / systemZoom},
      top=${top}, 
      left=${left}
      `,
      );

      newWindow?.focus();
    });
  };
};
