import { flex } from '@prototyp/gatsby-plugin-gumball/utils';
import { navigate } from 'gatsby-plugin-intl';
import { CompanyList } from 'modules/admin/components';
import { useManageCompaniesContent } from 'modules/admin/hooks';
import { useDeleteCompany } from 'modules/admin/hooks/useDeleteCompany';
import { Loading } from 'modules/app';
import { AppState } from 'modules/redux-store';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

const Pagination = React.lazy(
  () => import('modules/app/components/pagination/Pagination'),
);

interface Props {
  currentPage: number;
  search: string;
  companies: AdminResponse<Companies>;
  handlePageChange: (param: number) => void;
}

export const Content: React.FC<Props> = ({
  currentPage: page,
  search,
  companies,
  handlePageChange,
}) => {
  const { isLoading } = useSelector((state: AppState) => state.adminUsers);
  const { handleDelete, handleDeactivate, onPageChange } =
    useManageCompaniesContent(handlePageChange);

  const handleDeleteRequest = (id: string) => {
    handleDelete(id, companies, search, page);
  };

  const handleDeactivateRequest = (id: string) => {
    handleDeactivate(id, search, page);
  };

  return (
    <Loading
      isLoading={isLoading}
      component={
        <React.Suspense fallback={null}>
          <CompanyList
            search={search}
            companies={companies}
            handleDelete={handleDeleteRequest}
            handleDeactivate={handleDeactivateRequest}
          />
          <div css={[flex.display.flex, flex.justify.center]}>
            <Pagination
              initialPage={page - 1}
              onPageChange={onPageChange}
              pageCount={companies?.lastPage || 1}
              forcePage={page - 1}
            />
          </div>
        </React.Suspense>
      }
    />
  );
};
