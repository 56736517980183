import React, { useState, useEffect } from 'react';
import { Controller, useFormContext, RegisterOptions } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { PortfolioUrl } from 'modules/app';

import * as styles from '../styles/PortfolioForm.styles';

interface Props {
  constraints: RegisterOptions;
  name: string;
  value: string;
  onValueChange?: (text: string) => void;
  reset?: number;
  selectedPortfolio: string | string[];
  readOnly?: boolean;
  customStyles?: SerializedStyles;
}

export const PortfolioInput: React.FC<Props> = ({
  constraints,
  name,
  value,
  onValueChange,
  reset,
  selectedPortfolio,
  readOnly,
  customStyles,
}) => {
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const [urlHandle, setUrlHandle] = useState<null | string>(null);
  const [val, setVal] = useState(value);

  useEffect(() => {
    setValue(name, value);
    setVal(value);
  }, [value, setValue, name]);

  useEffect(() => {
    if (Number.isInteger(reset) && reset !== 0) {
      setVal('');
    }
  }, [reset]);

  useEffect(() => {
    if (!Array.isArray(selectedPortfolio)) {
      if (selectedPortfolio === 'other') {
        setUrlHandle(null);
      } else {
        setUrlHandle(PortfolioUrl[selectedPortfolio]);
      }
    }
  }, [selectedPortfolio]);

  return (
    <div css={errors[name] ? styles.rootError : styles.root}>
      <label
        css={errors.url ? styles.urlLabelError : styles.urlLabel}
        htmlFor="url"
      >
        <FormattedMessage id="onboarding.form.fields.portfolio.url" />
      </label>
      <Controller
        name={name}
        control={control}
        rules={constraints}
        render={({ field: { onChange } }) => {
          return (
            <div css={styles.urlInputWrapper}>
              {urlHandle && <p css={styles.urlHandle}>{urlHandle}</p>}
              <input
                readOnly={readOnly}
                value={val}
                type="text"
                css={[styles.urlInput, customStyles]}
                onChange={(e) => {
                  const value = e.target.value;

                  if (!value.trim()) {
                    onChange('');
                    setVal('');
                    onValueChange?.('');

                    return;
                  }

                  onChange(e.target.value);
                  setVal(e.target.value);

                  onValueChange?.(e.target.value);
                }}
              />
              <div css={styles.helpContainer}>
                <>
                  {errors && errors[name] && (
                    <span css={styles.fieldError}>
                      {errors[name].message && (
                        <FormattedMessage
                          id={errors[name].message}
                          values={{
                            max: constraints?.maxLength?.value,
                            min: constraints?.minLength?.value,
                          }}
                        />
                      )}
                    </span>
                  )}
                </>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};
