import { css } from '@emotion/react';

export const loading = css`
  width: 16px;
  height: 16px;
  margin-left: calc(var(--unit) * 2);

  path {
    stroke: hsl(var(--color-text-1));
  }
`;

export const loadingPrimary = css`
  ${loading};

  path {
    stroke: hsl(var(--color-white-11));
  }
`;

export const loadingLeft = css`
  width: 16px;
  height: 16px;
  margin-right: calc(var(--unit) * 2);

  path {
    stroke: hsl(var(--color-text-1));
  }
`;

export const loadingPrimaryLeft = css`
  ${loadingLeft};

  path {
    stroke: hsl(var(--color-white-11));
  }
`;
