import React from 'react';
import * as styles from 'modules/admin/styles/Section.styles';
import { SerializedStyles } from '@emotion/react';

interface SectionBodyProps {
  customStyles?: SerializedStyles | SerializedStyles[];
}

export const SectionBody: React.FC<SectionBodyProps> = ({
  children,
  customStyles,
}) => {
  return <div css={[styles.sectionBody, customStyles]}>{children}</div>;
};
