import { useCsrfCookie } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { TalentOpeningsService } from '../http';
import { talentOpeningsAction } from '../redux';

export function useTalentOpenings() {
  const { getCsrf } = useCsrfCookie();
  const csrf = getCsrf();

  const talentOpeningService = useMemo(
    () => new TalentOpeningsService(),
    [csrf],
  );

  const dispatch = useDispatch();

  async function getTalentMatches() {
    dispatch(talentOpeningsAction.matchingLoading());
    try {
      const { data } = await talentOpeningService.get('/match');

      dispatch(talentOpeningsAction.setMatching(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(talentOpeningsAction.matchingError(e));
    }
  }

  return {
    getTalentMatches,
  };
}
