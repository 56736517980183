import { InputField } from 'modules/app';
import React, { useState } from 'react';
import * as styles from 'modules/app/styles/GenericForm.styles';

const DatePickerDays = React.lazy(() => import('../../form/DatePickerDays'));

interface Props {
  resetValue: number;
  currentCertificate?: Certificate;
}

export const TalentCertificateInputs: React.FC<Props> = ({
  resetValue,
  currentCertificate,
}) => {
  const [expirationDate, setExpirationDate] = useState<string | undefined>();
  const [issueDate, setIssueDate] = useState<Date | undefined>(
    currentCertificate ? new Date(currentCertificate.issueDate) : undefined,
  );

  return (
    <>
      <InputField
        name="name"
        label="onboarding.form.fields.certificates.name"
        constraints={{
          required: true,
          maxLength: {
            value: 150,
            message: 'shared.errors.max',
          },
        }}
        value={currentCertificate?.name || ''}
        reset={resetValue}
      />
      <InputField
        name="issuingOrganization"
        label="onboarding.form.fields.certificates.issuing_organization"
        constraints={{
          required: true,
          maxLength: {
            value: 150,
            message: 'shared.errors.max',
          },
        }}
        value={currentCertificate?.issuingOrganization || ''}
        reset={resetValue}
      />
      <React.Suspense fallback={null}>
        <div css={styles.subgridForm}>
          <DatePickerDays
            name="issueDate"
            label="onboarding.form.fields.certificates.issue_date"
            constraints={{ required: true }}
            maxDate={true}
            reset={resetValue}
            setStartDate={setIssueDate}
            value={currentCertificate?.issueDate || ''}
          />
          <DatePickerDays
            isOptional={true}
            name="expirationDate"
            label="onboarding.form.fields.certificates.expiration_date"
            constraints={{}}
            currentButton={true}
            setEndDateValue={setExpirationDate}
            endDateValue={expirationDate}
            reset={resetValue}
            minDate={issueDate}
            defaultValue="shared.date_picker.no_expiry"
            value={currentCertificate?.expirationDate || ''}
            isNullable={true}
          />
          <InputField
            isOptional={true}
            name="credentialId"
            label="onboarding.form.fields.certificates.credential_id"
            constraints={{
              maxLength: {
                value: 255,
                message: 'shared.errors.max',
              },
            }}
            value={currentCertificate?.credentialId || ''}
            reset={resetValue}
          />
        </div>
      </React.Suspense>

      <InputField
        isOptional={true}
        name="credentialUrl"
        label="onboarding.form.fields.certificates.credential_url"
        constraints={{
          maxLength: { value: 255, message: 'shared.errors.max' },
          pattern: {
            value: /^(http|https):\/\/[^ "]+$/,
            message: 'onboarding.form.errors.url',
          },
        }}
        value={currentCertificate?.credentialUrl || ''}
        reset={resetValue}
      />
    </>
  );
};
