import { HttpError, MainContent, OpeningBox, Skeleton } from 'modules/app';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SearchFilters } from './components';

import * as styles from 'modules/talentOpenings/styles/Search.styles';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { useSearchOpenings } from '.';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { useDispatch, useSelector } from 'react-redux';
import { talentOpeningsAction } from './redux';
import { RouteComponentProps } from '@reach/router';
import { SearchBar } from 'modules/openings/components';
import { AppState } from 'modules/redux-store';

const Pagination = React.lazy(
  () => import('modules/app/components/pagination/Pagination'),
);

interface FormData extends Omit<OpeningsSearchParams, 'typeOfWorkplace'> {
  typeOfWorkplace?: string[];
}

export const Search: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const { openings, error, isLoading, params, lastPage } = useSelector(
    (state: AppState) => state.talentOpenings,
  );
  const { onSearch, onPageChange, searchOpenings } = useSearchOpenings();

  const methods = useForm<FormData>({
    defaultValues: {
      search: '',
      workingHours: '',
      typeOfWorkplace: undefined,
      contractType: '',
      careerLevel: '',
      location: undefined,
    },
  });

  useEffect(() => {
    return () => {
      dispatch(talentOpeningsAction.reset());
    };
  }, []);

  useEffect(() => {
    searchOpenings(params);
  }, [params]);

  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <motion.section
      {...FADE_IN_MOVE_Y_REGULAR}
      transition={{ duration: 0.4 }}
      css={styles.search}
    >
      <MainContent>
        <div css={styles.searchHeading}>
          <h1>
            <FormattedMessage id="search.searchTitle" />
          </h1>
          <SearchBar
            onChange={onSearch}
            placeholder="search.searchPlaceholder"
            value={params.search || ''}
            theme="light"
            inputStyles={styles.searchInput}
            containerStyles={styles.searchInputWrapper}
          />
        </div>
        <div css={styles.resultsWrapper}>
          <FormProvider {...methods}>
            <SearchFilters />
          </FormProvider>

          <div>
            {isLoading && (
              <Skeleton
                key="openingsLoading"
                count={6}
                containerStyles={styles.result}
                customStyles={styles.opening}
              />
            )}

            {!isLoading && (
              <motion.div
                {...FADE_IN_MOVE_Y_REGULAR}
                transition={{ duration: 0.3 }}
              >
                {!error && openings ? (
                  <>
                    {Boolean(openings.length) && (
                      <div css={styles.result}>
                        {openings.map((opening) => (
                          <OpeningBox
                            key={opening.slug}
                            opening={opening}
                            customStyles={styles.opening}
                          />
                        ))}
                      </div>
                    )}

                    {!Boolean(openings.length) && (
                      <div css={styles.noResults}>
                        <div>
                          <h3>
                            <FormattedMessage id="search.noOpeningsHeader" />
                          </h3>
                          <p>
                            <FormattedMessage id="search.noOpeningsContent" />
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <HttpError error={error} />
                )}
              </motion.div>
            )}

            {!error && lastPage > 1 && (
              <React.Suspense fallback={null}>
                <div>
                  <Pagination
                    forcePage={params.page - 1}
                    pageCount={lastPage}
                    onPageChange={onPageChange}
                  />
                </div>
              </React.Suspense>
            )}
          </div>
        </div>
      </MainContent>
    </motion.section>
  );
};
