import { useEffect, useState } from 'react';
import { EditData } from '../EditOpening';
import { transformSelectedPlace } from 'modules/profile/utils';
import { useStartSectionData } from './useStartSectionData';
import { useOpeningFlow } from './useOpeningFlow';

export const useIsReady = () => {
  const { currentOpening, isLoading, companyData, error } = useOpeningFlow();
  const {
    languages,
    departments,
    pipelines,
    startSectionHttpError: startError,
  } = useStartSectionData(companyData?.id);
  const [isReady, setIsReady] = useState(false);
  const [editData, setEditData] = useState<EditData>();

  const checkIfReady = () => {
    if (!currentOpening) {
      return;
    }

    if (departments && Boolean(languages.length) && pipelines && !isLoading) {
      const selectedDepartment = departments.find(
        (dep) => dep.value === currentOpening.departmentId,
      );
      const selectedPipeline = pipelines.find(
        (pipeline) => pipeline.value === currentOpening.pipelineId,
      );

      if (!selectedDepartment || !selectedPipeline) {
        return;
      }

      setEditData({
        selectedDepartment: selectedDepartment,
        selectedLocation: transformSelectedPlace(
          currentOpening.cityCountry,
          currentOpening.location,
        ),
        selectedPipeline: selectedPipeline,
      });

      setIsReady(true);
    }
  };

  useEffect(checkIfReady, [departments, languages, currentOpening, pipelines]);

  return {
    isReady,
    editData,
    languages,
    departments,
    pipelines,
    companyData,
    error,
    currentOpening,
    startError,
  };
};
