import React from 'react';

import * as styles from 'modules/dashboard/styles/Plan.styles';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';

interface Props {
  plan: Plan;
  companyHandle: string;
}

export const Plan: React.FC<Props> = ({ plan, companyHandle }) => {
  return (
    <div css={styles.root}>
      <Link
        to={`/profile/settings/company/${companyHandle}/billing/billing-data?planId=${plan.id}`}
        css={styles.title}
      >
        {plan.name}
      </Link>

      <div css={styles.priceAndButton}>
        <div>
          <p css={styles.price}>
            {plan.currency} {plan.price}
          </p>
          <p css={styles.openings}>
            <FormattedMessage
              id="companyDashboard.noPlan.openingsNumber"
              values={{ number: plan.allowedActiveOpeningCount }}
            />
          </p>
        </div>
        <Link
          to={`/profile/settings/company/${companyHandle}/billing/billing-data?planId=${plan.id}`}
          css={styles.button}
        >
          <FormattedMessage id="companyDashboard.noPlan.button" />
        </Link>
      </div>
    </div>
  );
};
