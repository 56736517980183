import { motion } from 'framer-motion';
import React from 'react';
import { useOnScrollAnimation } from '..';

import * as styles from 'modules/app/styles/ImageOverlay.styles';

interface Props {
  threshold?: number;
  background: string;
  type?: 'right' | 'left' | 'top' | 'bottom';
}

export const ImageOverlay: React.FC<Props> = ({
  threshold = 0,
  background,
  type = 'right',
}) => {
  const image = useOnScrollAnimation({ threshold: threshold });

  const style = () => {
    switch (type) {
      case 'top':
        return styles.topOffset;
      case 'bottom':
        return styles.bottomOffset;
      case 'right':
        return styles.rightOffset;
      case 'left':
        return styles.leftOffset;
    }
  };

  const variant =
    type === 'right' || type === 'left'
      ? { hidden: { width: 'calc(100% + 2px)' }, visible: { width: 0 } }
      : { hidden: { height: 'calc(100% + 2px)' }, visible: { height: 0 } };

  return (
    <motion.div
      ref={image.ref}
      variants={variant}
      initial="hidden"
      animate={image.animation}
      transition={{
        duration: 1.5,
        ease: [0.77, 0.0, 0.175, 1.0],
      }}
      css={[styles.root(background), style()]}
    />
  );
};
