import { css } from '@emotion/react';
import { font } from '@prototyp/gatsby-plugin-gumball/utils/font';

const root = css`
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-regular);
  padding: calc(var(--unit) * 4);

  & + & {
    margin-top: calc(var(--unit) * 3);
  }

  p {
    ${font.size.small};
    margin-left: calc(var(--unit) * 4);
  }
`;

export const success = css`
  ${root}
  background-color: hsl(var(--color-success-9));

  p {
    color: hsl(var(--color-textLight-2));
  }
`;

export const warning = css`
  ${root}
  background-color: hsl(var(--color-note-8));

  p {
    color: hsl(var(--color-textLight-2));
  }
`;

export const error = css`
  ${root}
  background-color: hsl(var(--color-warning-6));

  p {
    color: white;
  }
`;
