import { createAction } from 'modules/redux-store';

export const applicantsActions = {
  setApplicants: (payload?: SearchResponse<Applicant>) =>
    createAction('applicants/SET_APPLICANTS', payload),
  setOpenings: (payload: OpeningsList[]) =>
    createAction('applicants/SET_OPENINGS', payload),
  loading: () => createAction('applicants/LOADING'),
  error: (payload: ApiErrorData) => createAction('applicants/ERROR', payload),
  reset: () => createAction('applicants/RESET'),
};
