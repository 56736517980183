import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from 'style/variables';

export const root = css`
  padding-top: ${spacing(40)};
  border-top: 1px solid hsl(var(--color-borderBlack-2));
  padding-bottom: ${spacing(30)};

  @media ${breakpoints.medium} {
    padding-bottom: 0;
  }
`;

export const attachments = css`
  @media ${breakpoints.smallMax} {
    display: none;
  }
`;
