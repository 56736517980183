import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { talentOnboardingAction } from 'modules/onboarding';
import { AppState } from 'modules/redux-store';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function useTalentLanguage() {
  const dispatch = useDispatch();

  const [httpError, setHttpError] = useState<HttpError>();
  const languages = useSelector(
    (state: AppState) => state.talentOnboarding.listArray,
  );

  const talentService = useHttpClient<Basics>('/api/v1/talent/profile');

  async function getLanguageLocally(page = 1, perPage = 15) {
    try {
      const {
        data: { data },
      } = await talentService.get<LanguageResponse>(
        `/languages?perPage=${perPage}&page=${page}`,
      );
      setHttpError(undefined);
      return { data };
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  async function getLanguageDispatch(page = 1, perPage = 15, load = true) {
    load && dispatch(talentOnboardingAction.loading());

    try {
      const {
        data: { data },
      } = await talentService.get<LanguageResponse>(
        `/languages?perPage=${perPage}&page=${page}`,
      );
      setHttpError(undefined);
      const languageList: ListingData[] = data?.map((item) => ({
        title: item.language,
        content:
          item.proficiency === 'native'
            ? `${item.proficiency
                .charAt(0)
                .toUpperCase()}${item.proficiency.slice(1)}`
            : `General proficiency ${item.proficiency.toUpperCase()}`,
        id: item.id,
      }));

      dispatch(talentOnboardingAction.setListArray(languageList));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(talentOnboardingAction.error(e));
      setHttpError(e);
    }
  }

  async function getLanguageConfig() {
    try {
      const {
        data: { languages },
      } = await talentService.get<LangConfigResponse>('/languages/config');
      setHttpError(undefined);
      return { languages };
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
      return { error: e };
    }
  }

  async function addLanguage(
    languageData: LanguageRequest,
    isOnboarding?: boolean,
    isProfile?: boolean,
    handleOnboardingCallback?: VoidFunction,
    handleProfileCallback?: VoidFunction,
  ) {
    try {
      await talentService.post<LanguageResponse, LanguageRequest>(
        '/languages',
        languageData,
      );
      setHttpError(undefined);
      if (isOnboarding) {
        handleOnboardingCallback?.();
        return;
      }

      if (isProfile) {
        handleProfileCallback?.();
        return;
      }
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
      return { error: e };
    }
  }

  async function updateLanguage(
    languageData: LanguageRequest,
    id?: string,
    handleUpdateCallback?: (data: LanguageRequest) => void,
  ) {
    if (!id) return;

    try {
      await talentService.put(`/languages/${id}`, languageData);
      setHttpError(undefined);
      handleUpdateCallback?.(languageData);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
      return { error: e };
    }
  }

  function removeLanguage(id: string) {
    const idx = languages.findIndex((exp) => exp.id === id);
    languages.splice(idx, 1);
    dispatch(talentOnboardingAction.setListArray(languages));
  }

  async function deleteLanguage(id: string, removeFromList?: boolean) {
    try {
      await talentService.delete(`/languages/${id}`);
      setHttpError(undefined);
      removeFromList && removeLanguage(id);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  return {
    httpError,
    addLanguage,
    getLanguageLocally,
    getLanguageConfig,
    getLanguageDispatch,
    updateLanguage,
    deleteLanguage,
  };
}
