import { font, text } from '@prototyp/gatsby-plugin-gumball/utils';
import React from 'react';

interface Props {
  dataType: 'users' | 'companies' | 'search';
  search?: string;
}

export const NoData: React.FC<Props> = ({ dataType, search }) => {
  const getNoData = () => {
    switch (dataType) {
      case 'users':
        return <span>There is currently no data for these users</span>;
      case 'companies':
        return <span>There is currently no data for companies</span>;
      case 'search':
        return (
          <span>
            There is currently no data for <strong>{search}</strong>
          </span>
        );
    }
  };
  return <div css={[text.align.center, font.size.medium]}>{getNoData()}</div>;
};
