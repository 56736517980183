import { useCallback, useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { globalHistory, useLocation, navigate } from '@reach/router';

export function useFormGuard<Model>(
  form: UseFormReturn<Model>,
  nextStepPath?: string,
) {
  const {
    formState: { dirtyFields, isSubmitSuccessful },
    watch,
    reset,
    getValues,
  } = form;
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const isDirty = useMemo(() => {
    const filteredDirtyFields = Object.keys(dirtyFields).filter((key) => {
      // Checkfields need to be skipped.
      if (
        key.includes('isRequired') ||
        key.includes('featured') ||
        key.includes('isHeadquarters')
      )
        return;

      // There's an issue where only click on slate editor would trigger dirty fields.
      // So null values need to be skipped.
      if (watch()[key] === null && typeof watch()[key] === 'object') return;
      return dirtyFields[key];
    });

    return Boolean(Object.keys(filteredDirtyFields).length);
  }, [watch()]);

  const handleGuardedRouteCheck = useCallback(() => {
    const formGuardMessage = window.confirm(
      formatMessage({ id: 'confirm-navigation' }),
    );

    if (!formGuardMessage) navigate(pathname);
  }, []);

  useEffect(() => {
    if (!isSubmitSuccessful) return;
    reset(getValues(), {
      keepDirty: false,
      keepIsSubmitted: false,
      keepDefaultValues: false,
      keepValues: false,
    });
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (pathname.includes('onboarding') || isSubmitSuccessful || !isDirty)
      return;

    return globalHistory.listen(({ location }) => {
      if (nextStepPath && location.pathname.includes(nextStepPath)) return;
      if (location.pathname !== pathname) handleGuardedRouteCheck();
    });
  }, [isDirty, isSubmitSuccessful, handleGuardedRouteCheck, pathname]);

  return isDirty;
}
