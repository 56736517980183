import React from 'react';
import { FADE_IN } from 'style/animations.config';
import { motion } from 'framer-motion';
import UploadPhoto from 'assets/icons/photo.svg';

import * as styles from 'modules/app/styles/ProfileUpload.styles';

interface Props {
  uploadedImage: string | undefined;
  customStyles?: SerializedStyles;
  existingImage: string | null;
}

export const ImageRepresentation: React.FC<Props> = ({
  uploadedImage,
  customStyles,
  existingImage,
}) => {
  return (
    <>
      {!uploadedImage && !existingImage && <UploadPhoto />}

      {uploadedImage && !existingImage && (
        <motion.div
          css={[styles.imageRepresentation, customStyles]}
          transition={{ duration: 0.4 }}
          {...FADE_IN}
        >
          {uploadedImage && <img src={uploadedImage} />}
        </motion.div>
      )}

      {!uploadedImage && existingImage && (
        <motion.div
          css={[customStyles]}
          transition={{ duration: 0.4 }}
          {...FADE_IN}
        >
          <img src={existingImage} />
        </motion.div>
      )}
    </>
  );
};
