import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { flex } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  ${flex.display.flex};
  ${flex.justify.between};
  ${flex.align.center};
`;

export const iconGroup = css`
  ${flex.display.flex};
  ${flex.justify.between};
`;

export const icon = css`
  ${flex.display.flex};
  ${flex.justify.center};
  ${flex.align.center};

  cursor: pointer;

  background-color: hsl(var(--color-text-1));
  width: ${spacing(48)};
  height: ${spacing(48)};
  border-radius: var(--border-radius-full);

  &:not(:last-child) {
    margin-right: ${spacing(16)};
  }
`;
