import { Button, DropdownMenu } from 'modules/app';
import React from 'react';
import * as styles from '../styles/Table.styles';
import CloseOutlineBlack from 'assets/icons/closeOutlineBlack.svg';
import { StatusIndicator, UserCard } from '.';
import {
  flex,
  font,
  padding,
  text,
} from '@prototyp/gatsby-plugin-gumball/utils';
import { formatDate } from 'modules/admin/static';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

interface Props extends Users {
  onAssign: (id: string, role: string) => void;
  onRemoveRole: (id: string) => void;
}

export const UserData: React.FC<Props> = ({
  onAssign,
  onRemoveRole,
  id,
  firstName,
  lastName,
  email,
  profilePicture,
  createdAt,
  emailVerifiedAt,
  roleRoleId,
  roleId,
}) => {
  const roles = [
    { element: 'Admin', id: 'admin' },
    { element: 'Support', id: 'support' },
  ];
  const { userStaffRole } = useSelector((state: AppState) => state.session);

  const adminRoles =
    userStaffRole?.name === 'SuperAdmin'
      ? [...roles, { element: 'SuperAdmin', id: 'superadmin' }]
      : roles;

  return (
    <tr css={styles.row}>
      <td css={styles.data}>
        <UserCard
          profilePicture={profilePicture?.small}
          name={firstName}
          lastName={lastName}
          info={email}
          noLink={true}
        />
      </td>
      <td>
        <div css={[flex.display.flex, flex.direction.column, flex.align.start]}>
          <div css={font.weight.bold}>
            {formatDate(createdAt, 'LLL do')}, {formatDate(createdAt, 'HH:mm')}
          </div>
        </div>
      </td>
      <td>
        <StatusIndicator
          dataToCheck={emailVerifiedAt}
          statusTrueMessage="Verified"
          statusFalseMessage="Unverified"
        />
      </td>
      <td css={[font.weight.bold, font.size.base]}>
        {userStaffRole?.userStaffRoleId !== roleId &&
          userStaffRole?.name !== 'Support' && (
            <DropdownMenu
              onSelect={(roleId) => onAssign(id, roleId)}
              position="bottomLeft"
              customButtonStyles={[font.size.base, padding.none]}
              disabled={
                userStaffRole?.userStaffRoleId === roleId ||
                Boolean(
                  userStaffRole?.name === 'Support' && roleRoleId === 'Admin',
                )
              }
              placeholder={<span>{roleRoleId}</span>}
              items={adminRoles}
            />
          )}
        {(userStaffRole?.userStaffRoleId === roleId ||
          userStaffRole?.name === 'Support') && (
          <span css={font.weight.bold}>{roleRoleId}</span>
        )}
      </td>
      <td css={styles.action}>
        {userStaffRole?.userStaffRoleId !== roleId &&
          userStaffRole?.name !== 'Support' && (
            <Button
              type="submit"
              buttonType="outline"
              size="small"
              icon={<CloseOutlineBlack />}
              onClick={() => onRemoveRole(roleId)}
            >
              <span css={text.transform.capitalize}>remove staff role</span>
            </Button>
          )}
      </td>
    </tr>
  );
};
