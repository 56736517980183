import { css } from '@emotion/react';

export const root = css`
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-flow: column;
  overflow-y: hidden;
  width: 100%;
`;

export const editor = css`
  border-radius: 3px;
  border: 1px solid hsla(var(--color-grayscale-2));
  border-radius: 3px;
  display: flex;
  flex-flow: column;
  font-family: var(--font-family-primary);
  flex: auto;
  max-height: calc(100% - 34px);

  &:hover,
  &:focus {
    border-color: hsl(var(--color-borderLight-8));

    & > .slate__toolbar {
      border-color: hsl(var(--color-borderLight-8));
    }
  }

  .slate__editor {
    flex: auto;
    border: none;
    border-radius: 3px;
    padding: calc(var(--unit) * 4);
    outline: none;
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    min-height: calc(var(--unit) * 42);

    span[contenteditable='false'] {
      white-space: normal !important;
      padding-right: calc(var(--unit) * 7);
    }

    p {
      margin: 0;
    }

    ol {
      list-style: decimal;
      padding-left: calc(var(--unit) * 4);
      margin: 0;
    }

    ul {
      list-style: disc;
      padding-left: calc(var(--unit) * 4);
      margin: 0;
    }

    &::-webkit-scrollbar {
      width: var(--unit);
    }

    &::-webkit-scrollbar-thumb {
      background: hsl(var(--color-borderDark-5));
      border-radius: var(--unit);
    }
  }

  .slate__toolbar {
    display: flex;
    list-style: none;
    margin: 0;
    padding: calc(var(--unit) * 2) calc(var(--unit) * 3);
    border-bottom: 1px solid hsla(var(--color-grayscale-2));
  }

  .slate__toolbar__icon {
    display: block;
    padding: var(--unit);
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    margin-right: calc(var(--unit) * 3);
    opacity: 0.3;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  .slate__toolbar__icon--active {
    opacity: 1;
  }
`;

export const editorError = css`
  ${editor};
  border: 1px solid hsl(var(--color-error-7));

  .slate__toolbar {
    border-color: hsl(var(--color-error-7));
  }

  &:hover,
  &:focus {
    border-color: hsl(var(--color-error-7));

    & > .slate__toolbar {
      border-color: hsl(var(--color-error-7));
    }
  }
`;

export const maxChar = css`
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
  color: hsl(var(--color-grayscale-4));
`;

export const labelWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: var(--unit);
`;

export const label = css`
  padding: 0;
  margin: 0;
  font-size: var(--font-size-small);
  color: hsl(var(--color-grayscale-6));
`;

export const labelError = css`
  ${label};
  color: hsl(var(--color-error-7));
`;

export const optionalSection = css`
  & > span:first-of-type {
    margin-right: calc(var(--unit) * 3);
  }
`;
