import { css } from '@emotion/react';
import { borderRadius } from 'style/variables';
import { breakpoints, font } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  ${font.size.base};
  padding: calc(var(--unit) * 5);
  border-radius: ${borderRadius.regular};
  position: relative;

  @media ${breakpoints.medium} {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding-right: calc(var(--unit) * 4);
  }
`;

export const title = css`
  display: flex;
  align-items: center;
  margin-bottom: calc(var(--unit) * 4);
  padding-right: calc(var(--unit) * 5);

  & > span {
    margin-left: calc(var(--unit) * 4);
    font-weight: var(--font-weight-bold);
  }

  @media ${breakpoints.medium} {
    margin: 0 calc(var(--unit) * 4) 0 0;
    padding: 0;
  }
`;

export const closeBtn = css`
  position: absolute;
  top: calc(var(--unit) * 5);
  right: calc(var(--unit) * 4);

  @media ${breakpoints.medium} {
    position: static;
  }
`;

export const content = css`
  @media ${breakpoints.medium} {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
`;

export const success = css`
  background-color: hsl(var(--color-success-9));
`;

export const warning = css`
  background-color: hsl(var(--color-note-8));
`;

export const error = css`
  background-color: hsl(var(--color-warning-6));
  color: white;
`;
