import { useHttpClient, useToastify } from 'modules/app';
import { useState } from 'react';

interface TemplateData {
  name: string;
  body: string;
}

export const useTemplates = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState<EmailTemplateResponse>();

  const { toastError } = useToastify();

  const http = useHttpClient('/api/v1/company');

  async function getTemplates(companyId: string, page: number) {
    setIsLoading(true);
    try {
      const { data } = await http.get<EmailTemplateResponse>(
        `${companyId}/settings/email-templates`,
        {
          params: {
            page,
            perPage: 15,
            searchBy: 'name',
          },
        },
      );
      setTemplates(data);
    } catch (e) {
      toastError();
    } finally {
      setIsLoading(false);
    }
  }

  async function createTemplate(
    companyId: string,
    info: TemplateData,
    callback: VoidFunction,
  ) {
    setIsLoading(true);
    try {
      await http.post<TemplateData>(
        `${companyId}/settings/email-templates/create`,
        info,
      );
      callback();
    } catch (e) {
      toastError();
    }
  }

  async function editTemplate(
    companyId: string,
    templateId: string,
    info: TemplateData,
    callback: VoidFunction,
  ) {
    setIsLoading(true);
    try {
      await http.put<TemplateData>(
        `${companyId}/settings/email-templates/${templateId}`,
        info,
      );
      callback();
    } catch (e) {
      toastError();
    }
  }

  async function deleteTemplate(
    companyId: string,
    templateId: string,
    callback: VoidFunction,
  ) {
    try {
      await http.delete(`${companyId}/settings/email-templates/${templateId}`);
      callback();
    } catch (e) {
      toastError();
    }
  }

  return {
    isLoading,
    templates,
    getTemplates,
    editTemplate,
    createTemplate,
    deleteTemplate,
  };
};
