import { Heading, Logo } from 'modules/app';
import React from 'react';

import * as styles from 'modules/app/styles/TitlesWithLogo.styles';

interface Props {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  isRight?: boolean;
}

export const TitlesWithLogo: React.FC<Props> = ({
  title,
  subtitle,
  isRight = false,
}) => {
  return (
    <article css={isRight ? styles.rightRoot : styles.root}>
      <div css={styles.wrapper}>
        <header>
          <Logo css={styles.logo} />
          <Heading css={styles.title} as="h2" size="fluidHuge">
            {title}
          </Heading>
        </header>

        <Heading as="h3" css={styles.footer} size="fluidMedium">
          {subtitle}
        </Heading>
      </div>
    </article>
  );
};
