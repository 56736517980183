import React from 'react';
import { SectionBody } from '../SectionBody';
import * as styles from 'modules/admin/styles/AdminOverview.styles';
import { ActivityItem } from './ActivityItem';

const Pagination = React.lazy(
  () => import('modules/app/components/pagination/Pagination'),
);

interface Props {
  isLoading: boolean;
  activities?: ActivityData[];
  error?: ApiErrorData;
  lastPage?: number;
  page: number;
  onPageChange: (page: number) => void;
}

export const ActivityLogBody: React.FC<Props> = ({
  isLoading,
  activities,
  error,
  lastPage,
  page,
  onPageChange,
}) => {
  if (!activities) {
    return (
      <SectionBody>
        <p>No activities</p>
      </SectionBody>
    );
  }

  return (
    <SectionBody>
      <table>
        <thead css={styles.tableHead}>
          <tr>
            <td width="500">User</td>
            <td width="200">User type</td>
            <td width="350">Time</td>
            <td width="250">Action</td>
            <td width="300" />
          </tr>
        </thead>
        <tbody css={isLoading ? styles.loading : null}>
          {activities && Boolean(activities.length) ? (
            activities.map((activity, key: number) => (
              <ActivityItem key={key} {...activity} />
            ))
          ) : (
            <tr>{!isLoading && <td>No activity found.</td>}</tr>
          )}
        </tbody>
      </table>

      {!error && lastPage !== 1 && (
        <React.Suspense fallback={null}>
          <div css={[styles.pagination, isLoading ? styles.loading : null]}>
            <Pagination
              initialPage={page - 1}
              forcePage={page - 1}
              pageCount={lastPage || 1}
              onPageChange={(pag) => onPageChange(pag.selected + 1)}
            />
          </div>
        </React.Suspense>
      )}
    </SectionBody>
  );
};
