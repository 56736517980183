import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { wrappers } from 'style/variables';

export const root = css`
  --content-with-deco-background: hsl(var(--color-textLight-10));
  --content-with-deco-color: hsl(var(--color-textLight-2));

  padding: ${spacing(32)};

  @media ${breakpoints.large} {
    padding: ${spacing(60)} ${spacing(144)};
  }
`;

export const rootNegative = css`
  ${root}

  --content-with-deco-background: hsl(var(--color-textLight-2));
  --content-with-deco-color: hsl(var(--color-textLight-10));

  background-color: var(--content-with-deco-background);
  color: var(--content-with-deco-color);
`;

export const wrapper = css`
  position: relative;
  padding-top: ${spacing(20)};
  border-top: 2px solid var(--content-with-deco-color);

  @media ${breakpoints.large} {
    max-width: ${wrappers.sml};
  }
`;

export const footer = css`
  margin-top: ${spacing(56)};
  padding-top: ${spacing(32)};
  border-top: 1px solid hsl(var(--color-borderLight-8));

  @media ${breakpoints.large} {
    max-width: ${wrappers.sml};
  }
`;

export const fullHeight = css`
  @media ${breakpoints.medium} {
    min-height: 100vh;
  }
`;
