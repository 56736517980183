import React, { useCallback } from 'react';
import ReactPaginate from 'react-paginate';
import { useIntl } from 'gatsby-plugin-intl';

import * as styles from 'modules/app/styles/Pagination.styles';
import { motion } from 'framer-motion';

type PaginationProp = {
  selected: number;
};

interface Props {
  pageCount: number;
  onPageChange: (data: PaginationProp) => void;
  initialPage?: number;
  forcePage?: number;
  alwaysDisplay?: boolean;
  customStyles?: SerializedStyles | SerializedStyles[];
}
const Pagination: React.FC<Props> = ({
  pageCount,
  onPageChange,
  initialPage = 0,
  forcePage,
  alwaysDisplay,
  customStyles,
}) => {
  const intl = useIntl();

  const onChange = useCallback(
    (data: PaginationProp) => {
      onPageChange(data);
    },
    [onPageChange],
  );
  if (pageCount <= 1 && !alwaysDisplay) {
    return null;
  }
  return (
    <motion.div layout css={[styles.root, customStyles]}>
      <ReactPaginate
        initialPage={initialPage}
        forcePage={forcePage}
        pageCount={pageCount}
        pageRangeDisplayed={2}
        marginPagesDisplayed={3}
        onPageChange={onChange}
        previousLabel={intl.formatMessage({ id: 'pagination.prev' })}
        nextLabel={intl.formatMessage({ id: 'pagination.next' })}
        containerClassName="pagination"
        previousLinkClassName="pagination__previous"
        nextLinkClassName="pagination__next"
        activeLinkClassName="pagination__btn--active"
        pageLinkClassName="pagination__btn"
        disableInitialCallback
      />
    </motion.div>
  );
};

export default Pagination;
