import { useCsrfCookie } from 'modules/app';
import { authenticationAction } from 'modules/redux-store';
import { useDispatch } from 'react-redux';
import { useAuth } from 'modules/auth';
import { HttpError } from 'modules/app/http/client/httpError';
import { useHttpClient } from 'modules/app/http/hooks/useHttpClient';

export function useMandatoryRefresh() {
  const dispatch = useDispatch();
  const { logoutUser } = useAuth();
  const { setCsrf } = useCsrfCookie();

  const authService = useHttpClient<SessionResponse>('/auth');

  async function mandatoryRefresh() {
    try {
      const {
        data: { user, userSession, userStaffRole, userCompanyRoles },
      } = await authService.post('/refresh');

      const expiresAt = new Date(userSession.expiresAt + 'Z');
      setCsrf(userSession.csrf, expiresAt);

      user.authType = userSession.authType;

      dispatch(
        authenticationAction.setAuthSession({
          user,
          userCompanyRoles,
          tokenExpiresAt: expiresAt,
          userStaffRole,
        }),
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.status === 401) {
        logoutUser();
      }
    }
  }

  return { mandatoryRefresh };
}
