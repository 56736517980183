import React, { useRef } from 'react';
import { SectionBody } from 'modules/admin/components';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { BillingActivityTable } from './BillingActivityTable';
import Pagination from 'modules/app/components/pagination/Pagination';
import * as styles from '../styles/BillingActivityBody.styles';
import * as invoicesBodyStyles from 'modules/admin/invoices/styles/InvoicesBody.styles';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { Button } from 'modules/app';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { useBillingActivity } from '../hooks';

interface Props {
  query: BillingActivityQuery;
  handlePageChange: (page: number) => void;
}

export const BillingActivityBody: React.FC<Props> = ({
  query,
  handlePageChange,
}) => {
  const downloadRef = useRef<HTMLAnchorElement>(null);
  const { isSubmitting, exportBillingActivities } = useBillingActivity();
  const { isLoading, billingActivity, error } = useSelector(
    (selector: AppState) => selector.adminBillingActivity,
  );

  return (
    <SectionBody>
      <motion.div transition={{ duration: 0.3 }} {...FADE_IN_MOVE_Y_REGULAR}>
        <div css={invoicesBodyStyles.exportContainer}>
          <Button
            buttonType={'outline'}
            size={'tiny'}
            onClick={() => exportBillingActivities(downloadRef.current, query)}
            disabled={isSubmitting}
          >
            <FormattedMessage id="admin.shared.exportList" />
          </Button>
          <a ref={downloadRef} css={styles.hiddenInput} hidden />
        </div>
        <BillingActivityTable
          isLoading={isLoading}
          billingActivity={billingActivity?.billingActivities}
        />
        {!error && billingActivity?.lastPage !== 1 && (
          <React.Suspense fallback={null}>
            <div css={[styles.pagination, isLoading ? styles.loading : null]}>
              <Pagination
                forcePage={query.page - 1}
                initialPage={query.page - 1}
                pageCount={billingActivity?.lastPage || 1}
                onPageChange={(pag) => handlePageChange(pag.selected + 1)}
              />
            </div>
          </React.Suspense>
        )}
      </motion.div>
    </SectionBody>
  );
};
