import { createAction } from 'modules/redux-store';

export const applicationFlowActions = {
  addApplicationFlowData: (payload: {
    coverLetter?: string;
    privacyMode?: boolean;
  }) => createAction('applicationFlow/CURRENT_APPLICATION', payload),
  updateGhostApplication: (payload: { ghostApplication: GhostApplication }) =>
    createAction('applicationFlow/UPDATE_GHOST_APPLICATION', payload),
  editExperience: (payload: { experience?: GhostExperience }) =>
    createAction('applicationFlow/UPDATE_GHOST_EXPERIENCE', payload),
  markBasics: (payload: { areBasicsDone: boolean }) =>
    createAction('applicationFlow/MARK_BASICS', payload),
  markExpertise: (payload: { areExpertiseDone: boolean }) =>
    createAction('applicationFlow/MARK_EXPERTISE', payload),
  markEducation: (payload: { isEducationDone: boolean }) =>
    createAction('applicationFlow/MARK_EDUCATION', payload),
  markLanguages: (payload: { areLanguagesDone: boolean }) =>
    createAction('applicationFlow/MARK_LANGUAGES', payload),
  reset: () => createAction('applicationFlow/RESET'),
};
