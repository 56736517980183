import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';

export const normalizedProficiency: LanguageChoice<React.ReactNode>[] = [
  {
    value: 'native',
    label: (
      <FormattedMessage id="onboarding.form.fields.languages.proficiency_native" />
    ),
  },
  {
    value: 'a1',
    label: (
      <FormattedMessage id="onboarding.form.fields.languages.normalizedA1" />
    ),
  },
  {
    value: 'a2',
    label: (
      <FormattedMessage id="onboarding.form.fields.languages.normalizedA2" />
    ),
  },
  {
    value: 'b1',
    label: (
      <FormattedMessage id="onboarding.form.fields.languages.normalizedB1" />
    ),
  },
  {
    value: 'b2',
    label: (
      <FormattedMessage id="onboarding.form.fields.languages.normalizedB2" />
    ),
  },
  {
    value: 'c1',
    label: (
      <FormattedMessage id="onboarding.form.fields.languages.normalizedC1" />
    ),
  },
  {
    value: 'c2',
    label: (
      <FormattedMessage id="onboarding.form.fields.languages.normalizedC2" />
    ),
  },
];
