import { FormattedMessage } from 'gatsby-plugin-intl';
import {
  SelectComponent,
  TextArea,
  TextEditor,
  useTransformSelect,
  useCompanyDescriptionValues,
} from 'modules/app';
import React from 'react';
interface Props {
  company: Company | undefined;
}

export const CompanyDescriptionInputs: React.FC<Props> = ({ company }) => {
  const { sizeValues, industryValues, organizationTypeValues } =
    useCompanyDescriptionValues();

  return (
    <>
      <div>
        <SelectComponent
          name="organizationType"
          label={
            <FormattedMessage id="onboarding.form.fields.information.organization_type" />
          }
          constraints={{
            required: true,
          }}
          selectedValue={useTransformSelect(
            company?.organizationType,
            organizationTypeValues,
          )}
          values={organizationTypeValues}
        />
        <SelectComponent
          name="size"
          label={
            <FormattedMessage id="onboarding.form.fields.information.organization_size" />
          }
          constraints={{
            required: true,
          }}
          selectedValue={useTransformSelect(company?.size, sizeValues)}
          values={sizeValues}
        />
      </div>

      <SelectComponent
        name="industry"
        label={
          <FormattedMessage id="onboarding.form.fields.information.organization_industry" />
        }
        constraints={{
          required: true,
        }}
        selectedValue={useTransformSelect(company?.industry, industryValues)}
        values={industryValues}
      />

      <TextArea
        name="shortDescription"
        topLabel="onboarding.form.fields.information.organization_desc_short"
        placeholder="onboarding.form.fields.information.organization_desc_short_placeholder"
        value={company?.shortDescription || ''}
        constraints={{
          required: true,
          maxLength: { value: 150, message: 'shared.errors.max' },
        }}
        maxChar="150"
      />

      <div>
        <TextEditor
          label="onboarding.form.fields.information.organization_desc_long"
          name="longDescription"
          defaultValue={company?.longDescription || undefined}
          constraints={{
            required: true,
            maxLength: 500,
          }}
          maxChar={500}
          placeholder="onboarding.form.fields.information.organization_desc_long_placeholder"
        />
      </div>
    </>
  );
};
