import { ActionUnion } from 'modules/redux-store';
import { applicationFlowActions } from './actions';

interface ApplicationFlowInitialState {
  coverLetter: string;
  privacyMode: boolean;
  ghostApplication: GhostApplication;
  experienceToEdit?: GhostExperience;
  isGhostApplicationFinished: boolean;
  areBasicsDone: boolean;
  areExpertiseDone: boolean;
  areLanguagesDone: boolean;
  isEducationDone: boolean;
}

const INITIAL_STATE: ApplicationFlowInitialState = {
  coverLetter: '',
  privacyMode: false,
  ghostApplication: {
    isTosAccepted: false,
    coverLetter: '',
    firstName: '',
    lastName: '',
    dob: '',
    cityCountry: '',
    location: {
      x: 0,
      y: 0,
      srid: 0,
    },
    email: '',
    title: '',
    phone: null,
    workplace: [],
    gender: null,
    disability: null,
    about: null,
    profilePicture: null,
    coverPicture: null,
    certificates: [],
    expertises: [],
    educations: [],
    experiences: [],
    languages: [],
    projects: [],
    portfolios: [],
    isPrivacyPolicyAccepted: false,
  },
  experienceToEdit: undefined,
  isGhostApplicationFinished: false,
  areBasicsDone: false,
  areExpertiseDone: false,
  areLanguagesDone: false,
  isEducationDone: false,
};

export const applicationFlowReducer = (
  state: ApplicationFlowInitialState = INITIAL_STATE,
  action: ActionUnion<typeof applicationFlowActions>,
): ApplicationFlowInitialState => {
  switch (action.type) {
    case 'applicationFlow/CURRENT_APPLICATION':
      return {
        ...state,
        ...action.payload,
      };
    case 'applicationFlow/UPDATE_GHOST_EXPERIENCE':
      return {
        ...state,
        experienceToEdit: action.payload.experience,
      };
    case 'applicationFlow/UPDATE_GHOST_APPLICATION':
      state.ghostApplication = action.payload.ghostApplication;
      state.ghostApplication = { ...state.ghostApplication };

      return {
        ...state,
      };
    case 'applicationFlow/MARK_BASICS':
      return {
        ...state,
        areBasicsDone: action.payload.areBasicsDone,
      };

    case 'applicationFlow/MARK_EXPERTISE':
      return {
        ...state,
        areExpertiseDone: action.payload.areExpertiseDone,
      };
    case 'applicationFlow/MARK_LANGUAGES':
      return {
        ...state,
        areLanguagesDone: action.payload.areLanguagesDone,
      };
    case 'applicationFlow/MARK_EDUCATION':
      return {
        ...state,
        isEducationDone: action.payload.isEducationDone,
      };
    case 'applicationFlow/RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
