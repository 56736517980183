import { useHttpClient, useToastify } from 'modules/app';
import { useState } from 'react';
import { useConversations } from './useConversations';

export const useStartConversation = () => {
  const conversationHttp = useHttpClient<Conversation>(
    '/api/v1/talent/openings/applications',
  );
  const [isLoading, setIsLoading] = useState(false);
  const { toastError } = useToastify();

  const { getNotifications } = useConversations();

  async function sendMessage(
    applicationId: string,
    data: MessageRequest,
    callback: VoidFunction,
  ) {
    const formData = new FormData();
    formData.append('body', data.body);

    if (data.subject) formData.append('subject', data.subject);

    if (data.attachments && Boolean(data.attachments?.length)) {
      data.attachments.forEach((attachment) =>
        formData.append(`attachments`, attachment, attachment.name),
      );
    }

    setIsLoading(true);

    try {
      await conversationHttp.post(
        `/${applicationId}/contact-company`,
        formData,
      );
      getNotifications();
      callback();
    } catch (e) {
      toastError();
    }

    setIsLoading(false);
  }

  return {
    isLoading,
    sendMessage,
  };
};
