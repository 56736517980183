import { ActionUnion } from 'modules/redux-store';

import { talentDashboardActions } from './actions';

interface TalentDashboardInitState {
  activeInvitations?: Invitation[];
  activeApplications?: DashboardApplication[];
  requestedApplications?: RequestedApplication[];
  disqualifiedApplications?: DashboardApplication[];
  closedInvitations?: DashboardApplication[];
  rejectedInvitations?: DashboardApplication[];
  isLoading: boolean;
  error?: ApiErrorData;
}

const INITIAL_STATE: TalentDashboardInitState = {
  isLoading: false,
};

export const talentDashboardReducer = (
  state: TalentDashboardInitState = INITIAL_STATE,
  action: ActionUnion<typeof talentDashboardActions>,
): TalentDashboardInitState => {
  switch (action.type) {
    case 'talentDashboard/ACTIVE_INVITATIONS':
      return {
        ...state,
        error: undefined,
        activeInvitations: action.payload,
      };
    case 'talentDashboard/CLOSED_INVITATIONS':
      return {
        ...state,
        error: undefined,
        closedInvitations: action.payload,
      };
    case 'talentDashboard/REJECTED_INVITATIONS':
      return {
        ...state,
        error: undefined,
        rejectedInvitations: action.payload,
      };

    case 'talentDashboard/ACTIVE_APPLICATIONS':
      return {
        ...state,
        error: undefined,
        activeApplications: action.payload,
      };
    case 'talentDashboard/REQUESTED_APPLICATIONS':
      return {
        ...state,
        error: undefined,
        requestedApplications: action.payload,
      };
    case 'talentDashboard/DISQUALIFIED_APPLICATIONS':
      return {
        ...state,
        error: undefined,
        disqualifiedApplications: action.payload,
      };

    case 'talentDashboard/LOADING':
      return {
        ...state,
        isLoading: action.payload,
        error: undefined,
      };
    case 'talentDashboard/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'talentDashboard/RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
