import { ActionUnion } from 'modules/redux-store';

import { authenticationAction } from './actions';

interface LoggedInSessionReducerState {
  user: User | undefined;
  isLoading: boolean;
  userStaffRole: UserStaffRole | undefined;
  userCompanyRoles: UserCompanyRoles[] | undefined;
  tokenExpiresAt?: Date | string;
}

const INITIAL_STATE: LoggedInSessionReducerState = {
  user: undefined,
  isLoading: false,
  userStaffRole: undefined,
  userCompanyRoles: undefined,
  tokenExpiresAt: undefined,
};

export const sessionReducer = (
  state: LoggedInSessionReducerState = INITIAL_STATE,
  action: ActionUnion<typeof authenticationAction>,
): LoggedInSessionReducerState => {
  switch (action.type) {
    case 'authentication/SET_SESSION':
      return {
        ...state,
        user: { ...action.payload.user },
        userStaffRole: action.payload.userStaffRole,
        userCompanyRoles: action.payload.userCompanyRoles,
        tokenExpiresAt: action.payload.tokenExpiresAt,
        isLoading: false,
      };

    case 'authentication/UPDATE_2FA':
      if (state.user) {
        state.user.is2fa = action.payload.is2fa;
      }

      return {
        ...state,
        isLoading: false,
      };
    case 'authentication/LOADING':
      return {
        ...state,
        isLoading: true,
      };
    case 'authentication/LOGOUT':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
