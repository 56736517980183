export const navLinks = [
  'companies',
  'explore',
  'pricing',
  'blog',
  'about',
  'support',
] as const;

export const socialLinks = [
  { name: 'FB', url: 'https://www.facebook.com/RoangoHQ' },
  { name: 'IG', url: 'https://www.instagram.com/roangohq/' },
  { name: 'TW', url: 'https://twitter.com/RoangoHQ' },
  { name: 'LI', url: 'https://www.linkedin.com/company/roangohq/about/' },
] as const;
