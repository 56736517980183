import { AnimatePresence, motion } from 'framer-motion';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { NavLink, Paragraph } from 'modules/app';
import React, { useRef } from 'react';

import * as styles from '../../styles/Navbar.styles';
import {
  navbarVariant,
  listAnimation,
  linkAnimation,
  socialLinks,
  navLinks,
} from './utils';

interface Props {
  negative?: boolean;
  toggleNav: boolean;
}

export const MobileNavbar: React.FC<Props> = ({ negative, toggleNav }) => {
  const mobileNav = useRef<HTMLElement>(null);
  const { formatMessage } = useIntl();

  const mobileNavHeight = () => {
    if (typeof window === 'undefined' || !mobileNav.current) {
      return;
    }

    return `${window.innerHeight - mobileNav.current.offsetTop}px`;
  };

  const animateNavbar = navbarVariant(mobileNavHeight());

  if (typeof window !== 'undefined') {
    document.body.style.overflow = toggleNav ? 'hidden' : 'auto';

    window.addEventListener('resize', () => mobileNavHeight());
    mobileNavHeight();
  }

  return (
    <motion.section
      ref={mobileNav}
      className="nav__wrapper--mobile"
      animate={toggleNav ? 'open' : 'closed'}
      variants={animateNavbar}
    >
      <AnimatePresence exitBeforeEnter>
        {toggleNav && (
          <div>
            <motion.div
              variants={listAnimation}
              initial="closed"
              animate="open"
              exit="exit"
              transition={{ duration: 3 }}
              css={negative ? styles.navbarLinkNegative : styles.navbarLink}
              className="nav__links"
              role="presentation"
            >
              {navLinks.map((link) => (
                <motion.div key={link} variants={linkAnimation}>
                  {link === 'blog' ? (
                    <a
                      href="https://blog.roango.com/"
                      rel="noopener noreferrer"
                      className="link"
                    >
                      {formatMessage({ id: 'navbar.navLinks.blog' })}
                    </a>
                  ) : (
                    <NavLink
                      path={`/${link}`}
                      text={formatMessage({ id: `navbar.navLinks.${link}` })}
                      checkPartial
                    />
                  )}
                </motion.div>
              ))}
            </motion.div>

            <motion.hr
              initial={{ width: 0 }}
              animate={{
                width: 'calc(100% + 20px)',
                transition: { duration: 0.7, delay: 0.3 },
              }}
              exit={{ width: 0, transition: { duration: 0.7 } }}
              css={styles.divider}
            />

            <div
              className="nav__actions"
              css={styles.navbarUtils}
              role="presentation"
            >
              <section className="nav__social" css={styles.navbarUtils}>
                <Paragraph size="tiny" fontFamily="primary">
                  <FormattedMessage id="navbar.connect" />
                </Paragraph>

                <section className="nav__social-links" css={styles.navbarUtils}>
                  {socialLinks.map(({ name, url }) => (
                    <a
                      key={name}
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {name}
                    </a>
                  ))}
                </section>
              </section>
            </div>
          </div>
        )}
      </AnimatePresence>
    </motion.section>
  );
};
