import { css } from '@emotion/react';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  width: calc(var(--unit) * 8);
  height: calc(var(--unit) * 8);
  min-width: calc(var(--unit) * 8);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
`;

export const rootShadow = css`
  ${root};
  box-shadow: 6px 6px 25px rgba(0, 0, 0, 0.1);
  background-color: hsl(var(--color-borderUltraLight-9));

  span {
    font-size: var(--font-size-tiny);
    color: hsl(var(--color-text-1));
  }
`;

export const rootNoShadow = css`
  ${root};
  background: none;
  color: hsl(var(--color-text-1));
  box-shadow: none;
`;

export const avatar = css`
  span {
    font-size: var(--font-size-tiny);
    color: hsl(var(--color-text-1));
    font-weight: var(--font-weight-bold);
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

export const avatarWithImage = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(var(--unit) * 12);
  height: calc(var(--unit) * 12);

  span {
    color: white;
    ${font.size.base}
  }
`;

export const avatarWithoutImage = css`
  ${avatarWithImage};
  background: hsl(var(--color-error-7));
`;
