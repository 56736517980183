const year = new Date().getFullYear().toString().slice(-2);

export const expiryDateRegex = new RegExp(
  `(0[1-9]|1[0-2]) \/ ([${year.substring(0, 1)}-9][${year.slice(-1)}-9])`,
);

export const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;

export const masterRegex =
  /^5[1-5][0-9]{14}|^(222[1-9]|22[3-9]\\d|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{12}$/;

export const cardBrand = [
  {
    label: 'Visa',
    value: 'VISA',
  },
  {
    label: 'MasterCard',
    value: 'MASTER',
  },
];

export enum CardEnum {
  'VISA' = 'Visa',
  'MASTER' = 'MasterCard',
}

export const initialValues: PaymentForm = {
  cardBrand: '',
  cardCvv: '',
  expiryDate: '',
  cardHolder: '',
  cardNumber: '',
  cardSavingPolicyAccepted: false,
  termsOfService: false,
};

export const submitBtnText = (
  isLoading: boolean,
  isRedirecting: boolean,
  isNewCard?: boolean,
) => {
  if (isLoading) return 'settings.billingContent.paymentMethod.processingBtn';

  if (isRedirecting) return 'settings.billingContent.paymentMethod.redirecting';

  if (isNewCard) return 'settings.billingContent.paymentMethod.newCardBtn';

  return 'settings.billingContent.paymentMethod.subscribe';
};
