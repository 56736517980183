import { navigate } from 'gatsby-plugin-intl';
import { useCsrfCookie, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PipelinesServices } from '../http';
import { pipelinesAction } from '../redux';

export const usePipeline = () => {
  const [error, setError] = useState<HttpError>();
  const [isLoading, setIsLoading] = useState(false);

  const { toastError } = useToastify();
  const { getCsrf } = useCsrfCookie();
  const dispatch = useDispatch();
  const csrf = getCsrf();

  const pipelineHttp = useMemo(() => new PipelinesServices(), [csrf]);

  async function getPipelines(companyId: string, load = true) {
    load && dispatch(pipelinesAction.loading());

    try {
      const { data } = await pipelineHttp.get<PipelinesResponse>(
        `/${companyId}/pipelines`,
      );
      dispatch(pipelinesAction.companyPipelines(data.data));
      return data;
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(pipelinesAction.error(e));
      return e;
    }
  }

  async function createPipeline(
    companyId: string,
    handle: string,
    info: CreatePipelineData,
  ) {
    setIsLoading(true);
    setError(undefined);
    try {
      const { data } = await pipelineHttp.post<Pipeline, CreatePipelineData>(
        `/${companyId}/pipelines`,
        info,
      );
      navigate(`/profile/settings/company/${handle}/pipelines/edit/${data.id}`);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }
    setIsLoading(false);
  }

  async function deletePipeline(
    companyId: string,
    pipelineId: string,
    callback: VoidFunction,
  ) {
    setIsLoading(true);
    try {
      await pipelineHttp.delete(`/${companyId}/pipelines/${pipelineId}`);
      getPipelines(companyId, false);
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }
    setIsLoading(false);
  }

  async function editPipeline(
    companyId: string,
    pipelineId: string,
    info: CreatePipelineData,
  ) {
    setIsLoading(true);
    try {
      await pipelineHttp.put(`/${companyId}/pipelines/${pipelineId}`, info);
      getPipelines(companyId, false);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      toastError();
    }
  }

  async function getPipeline(
    companyId: string,
    pipelineId: string,
    load = true,
  ) {
    load && dispatch(pipelinesAction.loading());
    try {
      const { data } = await pipelineHttp.get<Pipeline>(
        `/${companyId}/pipelines/${pipelineId}`,
      );
      dispatch(pipelinesAction.pipeline(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(pipelinesAction.error(e));
    }
  }

  return {
    error,
    isLoading,
    getPipeline,
    getPipelines,
    editPipeline,
    createPipeline,
    deletePipeline,
  };
};
