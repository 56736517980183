import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { flex } from '@prototyp/gatsby-plugin-gumball/utils';

export const label = css`
  ${flex.display.flex};
  ${flex.align.center};

  cursor: pointer;
  user-select: none;
`;

export const radio = css`
  position: relative;
  ${flex.display.flex};
  ${flex.justify.center};
  ${flex.align.center};

  width: ${spacing(24)};
  height: ${spacing(24)};
  border: 2px solid hsl(var(--color-border-8));
  border-radius: 20px;
  margin-right: 12px;
`;

export const radioChecked = css`
  ${radio};

  &::before {
    content: '';
    position: absolute;
    width: ${spacing(14)};
    height: ${spacing(14)};
    border-radius: 20px;
    background-color: hsl(var(--color-grayscale-10));
    border-color: hsl(var(--color-grayscale-10));
  }
`;

export const input = css`
  display: none;
`;

export const root = css`
  margin: calc(var(--unit) * 3) 0;
  position: relative;
`;
