import { adminAction } from 'modules/admin/redux';
import { useDispatch } from 'react-redux';
import { HttpError } from 'modules/app/http/client/httpError';
import { useHttpClient } from 'modules/app';

export const useGetAdminCompanies = () => {
  const adminCompaniesService =
    useHttpClient<AdminResponse<Companies>>('/admin/companies');
  const dispatch = useDispatch();

  // change number of results per page --> only here
  async function getAdminCompanies({
    page = 1,
    perPage = 10,
    sortBy = 'registered_at',
    sort = 'DESC',
    searchBy = 'name',
    search = '',
    hideDeleted = false,
  }: ExtraSearchQueryData) {
    dispatch(adminAction.loading());

    try {
      const { data } = await adminCompaniesService.get('', {
        params: {
          page,
          perPage,
          sortBy,
          sort,
          searchBy,
          search,
          hideDeleted,
        },
      });

      dispatch(adminAction.companies(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(adminAction.error(e));
    }
  }

  async function getAllAdminCompanies({
    page = 1,
    sortBy = 'handle',
    sort = 'DESC',
    hideDeleted = false,
  }: ExtraSearchQueryData) {
    dispatch(adminAction.loading());

    try {
      const { data } = await adminCompaniesService.get('', {
        params: {
          page,
          sortBy,
          sort,
          hideDeleted,
        },
      });

      dispatch(adminAction.companies(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(adminAction.error(e));
    }
  }

  return {
    getAdminCompanies,
    getAllAdminCompanies,
  };
};
