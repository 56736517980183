import { AddTagButton, Label, Tag } from 'modules/app';
import React, { useState } from 'react';
import * as valueStyles from 'modules/profile/styles/Mission.styles';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'gatsby-plugin-intl';

interface Props {
  label: string;
  altLabel?: string;
  name: string;
  max?: number;
  values?: string[];
  constrains?: RegisterOptions;
  isApplication?: boolean;
}

export const InputTag: React.FC<Props> = ({
  label,
  altLabel,
  name,
  max,
  values,
  constrains,
  isApplication = true,
}) => {
  const {
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const [valuesArray, setValuesArray] = useState<string[]>(values || []);

  const onDelete = (value: string) => {
    const array = valuesArray.filter((item) => {
      return item !== value;
    });

    setValuesArray(array);
    setValue(name, array);
  };

  const onAddTag = (tag: string) => {
    if (valuesArray.includes(tag)) {
      return;
    }

    const newArray = [...valuesArray, tag];

    if (newArray.length) {
      clearErrors(name);
    }

    setValuesArray(newArray);
    setValue(name, newArray);
  };

  return (
    <>
      <Label
        label={<FormattedMessage id={label} />}
        altText={altLabel && <FormattedMessage id={altLabel} />}
        labelStyle
        error={errors[name]}
      />

      <Controller
        name={name}
        rules={constrains}
        render={() => (
          <div css={valueStyles.values}>
            {valuesArray &&
              valuesArray.map((value, i) => {
                return (
                  <Tag key={i} text={value} onDelete={() => onDelete(value)} />
                );
              })}
            {max ? (
              valuesArray.length < max && (
                <AddTagButton
                  isApplication={isApplication}
                  customStyles={margin.left.sml}
                  onAddTag={onAddTag}
                />
              )
            ) : (
              <AddTagButton
                isApplication={isApplication}
                customStyles={margin.left.sml}
                onAddTag={onAddTag}
              />
            )}
          </div>
        )}
      />
    </>
  );
};
