import React, { useState } from 'react';
import { useMatch } from '@reach/router';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { LoadingImg, Pill } from 'modules/app';
import { AdminSidebar, SectionBody } from 'modules/admin/components';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux-store';
import { activityLogRoutes } from '../../consts';
import { ActivityLogHeader } from './ActivityLogHeader';
import { ActivityLogBody } from './ActivityLogBody';
import Seo from 'modules/app/components/Seo';
import * as styles from 'modules/admin/styles/AdminOverview.styles';
import * as gridStyles from 'modules/admin/styles/Grid';
import * as sectionStyles from 'modules/admin/styles/Section.styles';

export const ActivityLog: React.FC = () => {
  const {
    data: activities,
    error,
    isLoading,
    lastPage,
  } = useSelector((state: AppState) => state.adminActivity);

  const matchCompanies = useMatch('/:lang/admin/activity/companies');

  const [page, setPage] = useState(1);

  function handlePageChange(pageNumber: number) {
    setPage(pageNumber);
  }

  function handlePageReset() {
    setPage(1);
  }

  return (
    <>
      <Seo title="Activity Log" />
      <div css={sectionStyles.section}>
        <div css={gridStyles.subSidebarGrid}>
          <AdminSidebar routes={activityLogRoutes} />

          <div css={sectionStyles.users}>
            <motion.div
              transition={{ duration: 0.3 }}
              {...FADE_IN_MOVE_Y_REGULAR}
            >
              <ActivityLogHeader
                matchCompanies={!!matchCompanies}
                isLoading={isLoading}
                page={page}
                handlePageReset={handlePageReset}
              />

              {error && (
                <div css={sectionStyles.section}>
                  <SectionBody>
                    <Pill type="error" title={error.message} text={' '} />
                  </SectionBody>
                </div>
              )}
              {isLoading ? (
                <div css={[sectionStyles.section, styles.loadingSpace]}>
                  <LoadingImg />
                </div>
              ) : (
                <ActivityLogBody
                  isLoading={isLoading}
                  activities={activities}
                  error={error}
                  lastPage={lastPage}
                  page={page}
                  onPageChange={handlePageChange}
                />
              )}
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};
