import { css } from '@emotion/react';
import { center } from 'style/global.styles';

export const uploadOverlay = css`
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;
  border: 1px dashed hsl(var(--color-borderLight-8));
  margin: calc(var(--unit) * 5) 0;
  height: calc(var(--unit) * 38);
  max-width: 100%;

  svg {
    ${center};
  }
`;

export const deleteButton = css`
  color: hsl(var(--color-error-6));
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin: calc(var(--unit) * 3) 0;
`;

export const uploadedImg = css`
  position: relative;
  width: 100%;
`;

export const topLabel = css`
  font-family: Aeonik;
  font-weight: normal;
  font-size: 14px;
  line-height: calc(var(--unit) * 5);
  color: hsl(var(--color-borderDark-5));
`;

export const bottomLabel = css`
  font-family: Aeonik;
  font-weight: normal;
  font-size: calc(var(--unit) * 3);
  line-height: calc(var(--unit) * 4);
  color: hsl(var(--color-textLight-7));
`;

export const error = css`
  ${topLabel}
  color: hsl(var(--color-warning-6));
`;
