import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { flex } from '@prototyp/gatsby-plugin-gumball/utils';

export const label = css`
  ${flex.display.flex};
  ${flex.align.center};

  cursor: pointer;
  user-select: none;
`;

export const control = css`
  ${flex.display.flex};
  ${flex.justify.center};
  ${flex.align.center};

  flex: 0 0 ${spacing(48)};
  width: ${spacing(48)};
  height: ${spacing(28)};
  border-radius: 24px;
  background: #d2d2e1;
  position: relative;
  transition: background 0.3s;
`;

export const controlChecked = css`
  ${control};
  background-color: hsl(var(--color-success-9));
`;

export const input = css`
  display: none;
`;

export const content = css`
  display: inline-block;
  margin-left: ${spacing(16)};
`;

export const toggle = css`
  width: ${spacing(20)};
  height: ${spacing(20)};
  position: absolute;
  right: calc(100% - 24px);
  top: 4px;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 2px 4px rgba(74, 74, 74, 0.25);
  transition: right 0.3s;
`;

export const toggleChecked = css`
  ${toggle};
  right: 4px;
`;
