import { useState } from 'react';

export function useInvoiceActions() {
  const [chosenInvoice, setChosenInvoice] = useState<InvoiceResponse>();

  function handleOpenCancelModal(invoice: InvoiceResponse) {
    setChosenInvoice(invoice);
  }

  function handleCloseCancelModal() {
    setChosenInvoice(undefined);
  }

  return {
    chosenInvoice,
    handleOpenCancelModal,
    handleCloseCancelModal,
  };
}
