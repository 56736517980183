import React, { useCallback, useEffect, useRef } from 'react';
import { animate } from 'framer-motion';

interface Props {
  isDisabled: boolean;
  setIsDisabled: (isDisabled: boolean) => void;
  duration: number;
}

export const Counter: React.FC<Props> = ({
  isDisabled,
  setIsDisabled,
  duration,
}) => {
  const nodeRef = useRef<HTMLSpanElement>(null);

  const countdown = useCallback(() => {
    if (!isDisabled) return;

    const node = nodeRef.current;

    if (!node) return;

    const controls = animate(duration, 0, {
      ease: 'linear',
      duration: duration,
      onUpdate(value) {
        node.textContent = `${value.toFixed(0)}s`;
        if (value === 0) {
          setIsDisabled(false);
        }
      },
    });

    return () => controls.stop();
  }, [isDisabled]);

  useEffect(countdown, [isDisabled]);

  return <span ref={nodeRef} />;
};
