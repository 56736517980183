import { css } from '@emotion/react';
import { borderRadius } from 'style/variables';

export const root = css`
  display: flex;
  justify-content: left;
  border-radius: ${borderRadius.regular};

  button {
    color: white;
    border: 1px solid white;
  }
`;

export const white = css`
  button {
    color: hsl(var(--color-text-1));
    border: 1px solid hsl(var(--color-borderLight-8));
  }
`;

export const black = css`
  button {
    color: white;
  }
`;
