import React from 'react';
import { Paragraph } from 'modules/app/index';
import * as styles from 'modules/app/styles/PercentageCalculator.styles';
import { FormattedMessage } from 'react-intl';

interface Props {
  data: number[];
}

export const PercentageCalculator: React.FC<Props> = ({ data }) => {
  return (
    <div css={styles.root}>
      <div>
        <Paragraph size="fluidBase">
          <FormattedMessage id="profileManagement.culture.labelsAndText.clanTypeLabel" />
        </Paragraph>
        <Paragraph size="fluidLarge">
          {Math.round(data[0] * 100 * 100) / 100}%
        </Paragraph>
      </div>
      <div>
        <Paragraph size="fluidBase">
          <FormattedMessage id="profileManagement.culture.labelsAndText.adhocracyTypeLabel" />
        </Paragraph>
        <Paragraph size="fluidLarge">
          {Math.round(data[1] * 100 * 100) / 100}%
        </Paragraph>{' '}
      </div>
      <div>
        <Paragraph size="fluidBase">
          <FormattedMessage id="profileManagement.culture.labelsAndText.hierarchyTypeLabel" />
        </Paragraph>
        <Paragraph size="fluidLarge">
          {Math.round(data[2] * 100 * 100) / 100}%
        </Paragraph>{' '}
      </div>
      <div>
        <Paragraph size="fluidBase">
          <FormattedMessage id="profileManagement.culture.labelsAndText.marketTypeLabel" />
        </Paragraph>
        <Paragraph size="fluidLarge">
          {Math.round(data[3] * 100 * 100) / 100}%
        </Paragraph>{' '}
      </div>
    </div>
  );
};
