import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints, flex } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  padding: ${spacing(40)} ${spacing(32)};

  @media ${breakpoints.medium} {
    padding: ${spacing(61)} ${spacing(32)};
    margin-left: ${spacing(50)};
  }

  @media ${breakpoints.large} {
    ${flex.display.flex};
    ${flex.direction.column};
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 ${spacing(112)} 0 ${spacing(60)};
    margin-left: 0;
  }
`;

export const rightRoot = css`
  ${root};
  @media ${breakpoints.large} {
    padding: 0 ${spacing(60)} 0 ${spacing(112)};
    align-items: flex-start;
  }
`;

export const wrapper = css`
  @media ${breakpoints.large} {
    ${flex.display.flex};
    ${flex.direction.column};
    justify-content: space-between;
    width: 100%;
    max-width: ${spacing(528)};
    padding-top: ${spacing(80)};
    padding-bottom: ${spacing(180)};
    min-height: 100vh;
  }
`;

export const logo = css`
  margin-bottom: ${spacing(136)};
`;

export const title = css`
  ${flex.grow.g1};
  margin-bottom: ${spacing(120)};
  font-weight: var(--font-weight-bold);
  font-family: var(--font-family-secondary);
  font-weight: normal;
`;

export const footer = css`
  font-family: var(--font-family-primary);
  font-weight: normal;
`;
