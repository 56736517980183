import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  HttpError,
  useToastify,
  useFormGuardEffect,
} from 'modules/app';
import * as styles from 'modules/app/styles/GenericForm.styles';
import { useReset } from 'modules/onboarding';
import { TalentPortfolioInputs } from './inputs/TalentPortfolioInputs';
import { useTalentPortfolio } from 'modules/talents';

interface Props {
  isOnboarding?: boolean;
  isProfile?: boolean;
  resetFormCounter?: () => void;
  currentPortfolio?: Portfolio;
  setCurrentPortfolios?: React.Dispatch<
    React.SetStateAction<Portfolio[] | undefined>
  >;
  currentPage?: number;
  setPageCount?: React.Dispatch<React.SetStateAction<number>>;
}

export enum PortfolioIdEnum {
  'github' = 'GitHub',
  'behance' = 'Behance',
  'dribbble' = 'Dribbble',
  'coroflot' = 'Coroflot',
  'gitlab' = 'GitLab',
  'bitbucket' = 'Bitbucket',
  'instagram' = 'Instagram',
  'medium' = 'Medium',
  'facebook' = 'Facebook',
  'twitter' = 'Twitter',
  'linkedin' = 'LinkedIn',
  'youtube' = 'Youtube',
  'other' = 'Other',
}

export enum PortfolioUrl {
  'behance' = 'behance.net/',
  'instagram' = 'instagram.com/',
  'dribbble' = 'dribbble.com/',
  'medium' = 'medium.com/',
  'facebook' = 'facebook.com/',
  'youtube' = 'youtube.com/',
  'twitter' = 'twitter.com/',
  'linkedin' = 'linkedin.com/',
  'coroflot' = 'coroflot.com/',
  'gitlab' = 'gitlab.com/',
  'bitbucket' = 'bitbucket.org/',
  'github' = 'github.com/',
}

export const TalentPortfolioForm: React.FC<Props> = ({
  isOnboarding = false,
  isProfile,
  resetFormCounter,
  setCurrentPortfolios,
  currentPage,
  setPageCount,
}) => {
  const methods = useForm<PortfolioData>({
    defaultValues: {
      portfolioId: '',
      handle: null,
      url: null,
      description: null,
    },
  });
  useFormGuardEffect(methods, isOnboarding);
  const { handleSubmit, reset, setError, clearErrors } = methods;

  const { toastSuccess } = useToastify();
  const { resetValue, setReset } = useReset();
  const { httpError, getPortfolios, getPortfoliosDispatch, addPortfolio } =
    useTalentPortfolio();

  const [isInRequest, setIsInRequest] = useState(false);

  const gettingPortfolios = async () => {
    const response = await getPortfolios({
      page: currentPage || 1,
      perPage: 15,
    });

    if (!response) return;

    setPageCount?.(response.lastPage || 1);
    setCurrentPortfolios?.(response.portfolios);
  };

  const onSubmit = async (data: PortfolioData) => {
    const model: PortfolioData = {
      portfolioId: data.portfolioId,
      handle: data.portfolioId === 'other' ? null : data.url,
      url: data.portfolioId === 'other' ? data.url : null,
      description: data.description ? data.description : null,
    };

    setIsInRequest(true);
    const response = await addPortfolio({ portfolio: model, setError });
    setIsInRequest(false);

    if (!response || (response && response.error)) return;

    if (isOnboarding) {
      reset();
      setReset();
      getPortfoliosDispatch({ page: currentPage, perPage: 15, load: false });
      clearErrors();

      return;
    }

    if (isProfile) {
      toastSuccess('shared.pills.successDefaultText');
      gettingPortfolios();
      resetFormCounter?.();
    }
  };

  return (
    <div css={styles.root}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <HttpError styles={styles.httpErrorFix} error={httpError} />

          <TalentPortfolioInputs resetValue={resetValue} />

          <Button type="submit" disabled={isInRequest} css={styles.submitBtn}>
            <FormattedMessage id="onboarding.form.fields.portfolio.save_portfolio_submit" />
          </Button>
          {isProfile && resetFormCounter && (
            <Button
              css={styles.cancelBtn}
              buttonType="ghost"
              onClick={() => {
                resetFormCounter();
              }}
              disabled={isInRequest}
            >
              <FormattedMessage id="profileManagement.heading.buttons.cancel" />
            </Button>
          )}
        </form>
      </FormProvider>
    </div>
  );
};
