import { ActionUnion } from 'modules/redux-store';

import { undoTimerActions } from './undoTimerActions';

export type UndoTimer = {
  time: number;
  companyId: string;
  applicationId: string;
  companyOpeningId: string;
  type: 'stage' | 'clone';
};

interface UndoTimerReducerState {
  item?: UndoTimer;
}

const INITIAL_STATE: UndoTimerReducerState = {
  item: undefined,
};

export const undoTimerReducer = (
  state: UndoTimerReducerState = INITIAL_STATE,
  action: ActionUnion<typeof undoTimerActions>,
): UndoTimerReducerState => {
  switch (action.type) {
    case 'undoTimer/SET_TIMER':
      return {
        ...state,
        item: action.payload,
      };
    case 'undoTimer/RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
