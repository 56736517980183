import React from 'react';

import * as styles from 'modules/app/styles/PublicLayout.styles';

export const PublicLayout: React.FC = ({ children }) => {
  return (
    <section css={styles.root}>
      <div css={styles.content}>{children}</div>
    </section>
  );
};
