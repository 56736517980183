export const FADE_IN = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};

export const FADE_IN_REVERSE = {
  initial: FADE_IN.animate,
  animate: FADE_IN.initial,
};

export const FADE_IN_MOVE_Y_REGULAR = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
};

export const FADE_IN_MOVE_Y_REGULAR_REVERSE = {
  initial: FADE_IN_MOVE_Y_REGULAR.animate,
  animate: FADE_IN_MOVE_Y_REGULAR.initial,
};

export const FADE_IN_MOVE_Y_REVERSE = {
  initial: { opacity: 0, y: -10 },
  animate: { opacity: 1, y: 0 },
};

export const EXPAND_HORIZONTAL = {
  initial: {
    width: '0%',
    overflow: 'auto',
  },
  animate: {
    width: '100%',
  },
};

export const EXPAND_HORIZONTAL_REVERSE = {
  initial: {
    width: '1000%',
    overflow: 'auto',
  },
  animate: {
    width: '0%',
  },
};

export const ROTATE_FADE_IN = {
  initial: { opacity: 0, x: -100, rotateZ: '-0.5turn' },
  animate: { opacity: 1, x: 0, rotateZ: 0 },
};

export const MOVE_FROM_BOTTOM = {
  initial: { translateY: 'calc(100% + 50px)' },
  animate: { translateY: 0 },
};

export const MOVE_TO_BOTTOM = {
  initial: MOVE_FROM_BOTTOM.animate,
  animate: MOVE_FROM_BOTTOM.initial,
};

export const FADE_IN_MOVE_Y_CONTROLLED = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0 },
};

export const FADE_IN_MOVE_X_LEFT_CONTROLLED = {
  hidden: { opacity: 0, x: -20 },
  visible: { opacity: 1, x: 0 },
};

export const FADE_IN_MOVE_X_RIGHT_CONTROLLED = {
  hidden: { opacity: 0, x: 20 },
  visible: { opacity: 1, x: 0 },
};

export const STAGGER_CONTAINER = {
  visible: {
    transition: {
      staggerChildren: 0.3,
    },
  },
};

export const STAGGER_CONTAINER_AND_OPACITY = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

export const TILT_X = {
  animate: {
    rotate: [0, -20, 10, -5, 0],
    translateX: ['0px', '-10px', '5px', '-3px', '0px'],
  },
};

export const TRANSITION = (delay?: number, duration?: number) => ({
  duration: duration ? duration : 0.5,
  delay: delay ? delay : 0,
  type: 'keyframes',
  ease: 'easeInOut',
});

export const BOUNCE = {
  initial: { y: '100%' },
  animate: { y: ['100%', '-10%', '0%'] },
};
