export function parseLanguages(languages: string): OpeningLanguage[] {
  let parsedLanguages: OpeningLanguage[] = languages
    ? JSON.parse(languages)
    : [];

  if (languages.length > 0) {
    parsedLanguages = parsedLanguages
      .filter((lang) => Boolean(lang.language))
      .map((lang) => {
        return {
          language: lang.language,
          proficiency: lang.proficiency ? lang.proficiency : null,
          required: lang.required,
        };
      });
  }

  return parsedLanguages;
}
