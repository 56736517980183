import React, { useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Button,
  HttpError,
  useToastify,
  useFormGuardEffect,
} from 'modules/app';
import { FormattedMessage } from 'react-intl';
import * as styles from 'modules/app/styles/GenericForm.styles';
import { useReset } from 'modules/onboarding';
import { TalentEducationInputs } from './inputs';
import { useTalentEducation, normalizeEducationRequest } from 'modules/talents';

interface Props {
  isOnboarding?: boolean;
  setCurrentEducations?: React.Dispatch<
    React.SetStateAction<Education[] | undefined>
  >;
  isProfile?: boolean;
  resetFormCounter?: () => void;
  currentPage?: number;
  setPageCount?: React.Dispatch<React.SetStateAction<number>>;
}

export const TalentEducationForm: React.FC<Props> = ({
  isOnboarding = false,
  setCurrentEducations,
  isProfile,
  resetFormCounter,
  currentPage,
  setPageCount,
}) => {
  const methods = useForm<EducationRequest>({
    defaultValues: {
      educationalInstitution: '',
      startYear: '',
      degree: null,
      fieldOfStudy: null,
      activities: null,
      associations: null,
      endYear: null,
    },
  });
  useFormGuardEffect(methods, isOnboarding);
  const { handleSubmit, reset, watch, clearErrors } = methods;
  const startYear = watch('startYear') || '0';

  const { resetValue } = useReset();
  const { toastSuccess } = useToastify();
  const positionalRef = useRef<HTMLFormElement>(null);
  const { httpError, getTalentEducationsLocally, addTalentEducation } =
    useTalentEducation();

  const [isInRequest, setIsInRequest] = useState<boolean>();

  const gettingEducation = async () => {
    setIsInRequest(true);
    const response = await getTalentEducationsLocally(currentPage, '', 5);
    setIsInRequest(false);

    if (!response) return;
    const { data, lastPage } = response;

    setPageCount?.(lastPage);
    setCurrentEducations?.(data);
  };

  const onValid = async (educationRequest: EducationRequest) => {
    const model = normalizeEducationRequest(educationRequest);

    setIsInRequest(true);
    const response = await addTalentEducation(model, isOnboarding);
    setIsInRequest(false);

    if (isOnboarding) {
      reset();
      clearErrors();

      return;
    }

    if (response && isProfile) {
      toastSuccess('shared.pills.successDefaultText');
      resetFormCounter?.();
      gettingEducation();
    }
  };

  return (
    <div css={styles.root}>
      <FormProvider {...methods}>
        <form ref={positionalRef} onSubmit={handleSubmit(onValid)}>
          <HttpError styles={styles.httpErrorFix} error={httpError} />

          <TalentEducationInputs
            resetValue={resetValue}
            startYear={startYear}
          />

          <Button disabled={isInRequest} type="submit" css={styles.submitBtn}>
            <FormattedMessage id="onboarding.form.fields.education.save_education_button" />
          </Button>
          {isProfile && resetFormCounter && (
            <Button
              css={styles.cancelBtn}
              buttonType="ghost"
              onClick={() => {
                resetFormCounter();
              }}
              disabled={isInRequest}
            >
              <FormattedMessage id="profileManagement.heading.buttons.cancel" />
            </Button>
          )}
        </form>
      </FormProvider>
    </div>
  );
};
