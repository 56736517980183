import { FormattedMessage } from 'gatsby-plugin-intl';
import { Heading, SelectComponent } from 'modules/app';
import { usePlans } from 'modules/billing/hooks';
import React, { useEffect } from 'react';

import * as styles from 'modules/dashboard/styles/NoPlansHeading.styles';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { motion } from 'framer-motion';
import { FormProvider, useForm } from 'react-hook-form';

interface Props {
  companyId: string;
}

export const NoPlansHeading: React.FC<Props> = ({ companyId }) => {
  const { getPlans, currencySelect, currentCurrency, onCurrencyChange } =
    usePlans();
  const methods = useForm({
    defaultValues: {
      region: '',
    },
  });

  useEffect(() => {
    if (currentCurrency !== 'EUR') {
      getPlans(companyId, 'USD');

      return;
    }

    getPlans(companyId, currentCurrency);
  }, [currentCurrency]);

  return (
    <motion.section
      {...FADE_IN_MOVE_Y_REGULAR}
      transition={{ duration: 0.3 }}
      css={styles.root}
    >
      <Heading as="h1" size="fluidXxLarge" css={styles.title}>
        <FormattedMessage id="companyDashboard.noPlan.title" />
      </Heading>

      <p css={styles.content}>
        <FormattedMessage id="companyDashboard.noPlan.content" />
      </p>

      <div css={styles.select}>
        <FormProvider {...methods}>
          <SelectComponent
            name="region"
            label={
              <FormattedMessage id="settings.billingContent.subscriptionContent.region" />
            }
            constraints={{}}
            values={currencySelect}
            selectedValue={currencySelect.find(
              (currency) => currency.value === currentCurrency,
            )}
            onValueChange={onCurrencyChange}
            theme="dark"
          />
        </FormProvider>
      </div>
    </motion.section>
  );
};
