import { FormattedMessage } from 'gatsby-plugin-intl';
import { Label } from 'modules/app';
import React, { useEffect, useState } from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';
import Dropzone, { FileRejection } from 'react-dropzone';
import { Attachment } from './Attachment';
import { FormError } from 'modules/app/intl';
import { useHandleAttachments } from '../hooks/useHandleAttachments';

import * as styles from 'modules/talentMessages/styles/AttachmentsInput.styles';
import DownloadIcon from 'assets/icons/download.svg';

interface Props {
  name: string;
  constraints?: RegisterOptions;
  acceptedFiles: string[];
  maxSize: number;
  theme?: 'light' | 'dark';
}

export const AttachmentsInput: React.FC<Props> = ({
  name,
  constraints,
  acceptedFiles,
  maxSize,
  theme = 'dark',
}) => {
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>();
  const {
    onDrop,
    onDelete,
    handleError,
    error,
    filesSize,
    isSizeError,
    errors,
    uploadedFiles,
    control,
  } = useHandleAttachments(name);

  useEffect(() => {
    handleError(rejectedFiles);
  }, [rejectedFiles]);

  return (
    <div css={styles.root}>
      <Label
        isOptional
        label={<FormattedMessage id="talentMessages.attachmentLabel" />}
        customStyles={theme === 'dark' ? styles.label : styles.labelLight}
      />
      <Controller
        name={name}
        control={control}
        rules={constraints}
        render={({ field }) => (
          <Dropzone
            disabled={isSizeError}
            onDrop={onDrop}
            accept={acceptedFiles}
            maxSize={maxSize}
            noKeyboard
            {...field}
          >
            {({ getRootProps, getInputProps, fileRejections }) => {
              if (Boolean(fileRejections.length)) {
                setRejectedFiles(fileRejections);
              } else {
                setRejectedFiles(undefined);
              }

              return (
                <div
                  css={theme === 'dark' ? styles.input : styles.inputLight}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} type="file" name={name} />
                  <DownloadIcon />
                </div>
              );
            }}
          </Dropzone>
        )}
      />
      <div css={isSizeError ? styles.sizeError : styles.bottomLabel}>
        <FormattedMessage id="talentMessages.attachmentFormats" />
        <span>{filesSize.toFixed(2)}/10MB</span>
      </div>

      {Boolean(uploadedFiles.length) && (
        <div css={styles.attachments}>
          {uploadedFiles.map((file, i) => (
            <Attachment
              key={i}
              name={file.name}
              onDelete={() => onDelete(i)}
              theme={theme}
            />
          ))}
        </div>
      )}

      {error && (
        <div css={styles.error}>
          <FormError name={name} errors={errors} />
        </div>
      )}
    </div>
  );
};
