import { useHttpClient } from 'modules/app';
import { useState } from 'react';

export const useGetCompanyDetails = () => {
  const adminCompanyService =
    useHttpClient<CompanyResponse>('/admin/companies');
  const [isLoading, setIsLoading] = useState(false);

  async function getCompanyDetails(companyId: string) {
    setIsLoading(true);
    try {
      const { data } = await adminCompanyService.get(companyId);
      return data;
    } catch (e) {}

    setIsLoading(false);
  }

  return {
    isLoading,
    getCompanyDetails,
  };
};
