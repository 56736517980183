export function getSendingMessage(
  data: MessageRequest,
  messages: Conversation,
  companyUser?: string,
): ConversationMessage | undefined {
  const message = messages.conversationMessages;
  const sendingId = `sending-${message.length}`;
  const userId = messages.conversationUsers.find(
    (user) => user.userId === messages.userId,
  )?.id;
  const companyUserId = messages.conversationUsers.find(
    (user) => user.userId === companyUser,
  )?.id;
  const id = companyUser ? companyUserId : userId;

  if (!id) {
    return;
  }

  return {
    id: sendingId,
    body: data.body,
    attachments:
      data.attachments?.map((att) => ({
        data: att.name,
        fileName: att.name,
      })) || [],
    conversationId: messages.id,
    conversationUserId: id,
    createdAt: new Date().toString(),
    updatedAt: new Date().toString(),
    isLoading: true,
  };
}
