import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import SearchIcon from 'assets/icons/search.svg';
import CloseIcon from 'assets/icons/closeOutline.svg';
import { useIntl } from 'gatsby-plugin-intl';
import { useSelector } from 'react-redux';

import * as styles from './styles/QuickSearch.styles';
import { AppState } from 'modules/redux-store';
import { useQuickSearch } from './hooks';
import { motion } from 'framer-motion';
import { variants } from './consts';

interface Props {
  formStyles?: SerializedStyles;
  theme?: 'dark' | 'light';
}

export const QuickSearch: React.FC<Props> = ({
  formStyles,
  theme = 'dark',
}) => {
  const intl = useIntl();
  const { searchValue } = useSelector((state: AppState) => state.quickSearch);
  const methods = useForm({
    defaultValues: {
      search: searchValue || '',
    },
  });
  const {
    onSubmit,
    resetValue,
    onSearchToggle,
    onSearchClose,
    isSearchActive,
    isCloseIcon,
  } = useQuickSearch(methods);
  const { handleSubmit, register } = methods;

  return (
    <section css={styles.section}>
      <FormProvider {...methods}>
        <motion.div
          animate={isSearchActive ? 'open' : 'closed'}
          initial="closed"
          variants={variants}
          css={isSearchActive ? styles.fixedWrapper : styles.wrapper}
        >
          <form
            onClick={(e) => {
              e.preventDefault;
              e.stopPropagation();
            }}
            css={[styles.root, formStyles]}
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              autoComplete="off"
              css={theme === 'dark' ? styles.input : styles.inputLight}
              {...register('search', { maxLength: 40 })}
              placeholder={intl.formatMessage({ id: 'search.quickSearch' })}
            />
          </form>
          {isCloseIcon || isSearchActive ? (
            <div
              onClick={resetValue}
              css={theme === 'dark' ? styles.closeIcon : styles.closeIconLight}
            >
              <CloseIcon onClick={onSearchClose} />
            </div>
          ) : (
            <SearchIcon
              css={theme === 'dark' ? styles.icon : styles.iconLight}
              onClick={onSearchToggle}
            />
          )}
        </motion.div>
      </FormProvider>
    </section>
  );
};
