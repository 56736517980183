import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface Props {
  classList?: string[];
}

export const BaseModal: React.FC<Props> = ({ children, classList }) => {
  const [el, setEl] = useState<HTMLDivElement>();

  useEffect(() => {
    if (!el) {
      const el = document.createElement('div');
      el.classList.add('overlay-modal');

      if (Array.isArray(classList)) {
        for (const c of classList) {
          el.classList.add(c);
        }
      }

      document.body.prepend(el);
      document.body.style.overflow = 'hidden';

      setEl(el);
    }
  }, [el, setEl, classList]);

  useEffect(() => {
    return () => {
      const el = document.getElementsByClassName('overlay-modal')[0];

      if (el) {
        document.body.removeChild(el);
        document.body.style.overflow = 'auto';
      }
    };
  }, []);

  if (!el) {
    return null;
  }

  return ReactDOM.createPortal(children, el);
};
