import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints, grid } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  min-height: 100vh;
  overflow: hidden;
  background-color: hsl(var(--color-text-1));
  color: white;
`;

export const inner = css`
  margin: 0 auto;
  padding: ${spacing(32)};
`;

export const logo = css`
  margin: calc(var(--unit) * 10) 0 0 calc(var(--unit) * 10);
`;

export const content = css`
  margin-top: calc(var(--unit) * 30);

  ${grid.display};
  ${grid.template.cols12};

  & > div {
    ${grid.colSpan.c12};
    margin-bottom: calc(var(--unit) * 10);
  }

  @media ${breakpoints.large} {
    & > div {
      ${grid.colSpan.c6};
      ${grid.colStart.c4};
    }
  }
`;

export const loading = css`
  ${grid.colSpan.c2};
  ${grid.colStart.c1};
`;
