export function socialUrl(
  id: string,
  handle: string | null,
  url: string | null,
): string | undefined {
  if (url) {
    return url;
  }

  if (handle) {
    if (id === 'github') {
      return `https://github.com/${handle}`;
    } else if (id === 'behance') {
      return `https://behance.net/${handle}`;
    } else if (id === 'dribbble') {
      return `https://dribbble.com/${handle}`;
    } else if (id === 'coroflot') {
      return `https://coroflot.com/${handle}`;
    } else if (id === 'gitlab') {
      return `https://gitlab.com/${handle}`;
    } else if (id === 'bitbucket') {
      return `https://bitbucket.com/${handle}`;
    } else if (id === 'instagram') {
      return `https://instagram.com/${handle}`;
    } else if (id === 'medium') {
      return `https://medium.com/${handle}`;
    } else if (id === 'facebook') {
      return `https://facebook.com/${handle}`;
    } else if (id === 'twitter') {
      return `https://twitter.com/${handle}`;
    } else if (id === 'linkedin') {
      return `https://linkedin.com/${handle}`;
    } else if (id === 'youtube') {
      return `https://youtube.com/c/${handle}`;
    }
  }

  return;
}
