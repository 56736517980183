import { impersonateAction } from 'modules/admin/redux';
import { useDispatch } from 'react-redux';
import { useAuthDispatch, useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';

export const useImpersonate = () => {
  const adminImpersonateService =
    useHttpClient<SessionResponse>('/admin/users');

  const dispatch = useDispatch();
  const { dispatchLogin } = useAuthDispatch();

  async function requestImpersonate(userId: string) {
    dispatch(impersonateAction.loading());

    try {
      const { data } = await adminImpersonateService.get(
        `/${userId}/impersonate`,
      );
      dispatchLogin(
        data.user,
        data.userSession,
        data.userStaffRole,
        data.userCompanyRoles,
      );

      dispatch(impersonateAction.start({ user: data.userSession }));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(impersonateAction.error(e));
    }
  }

  return {
    requestImpersonate,
  };
};
