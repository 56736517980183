import React from 'react';
import { NewItemType } from './DropdownMenu';

import * as styles from 'modules/app/styles/Dropdown.styles';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import ArrowIcon from 'assets/icons/smallArrow.svg';
import { FormattedMessage } from 'gatsby-plugin-intl';

interface Props {
  items: Array<NewItemType>;
  onSelect: (firstItemId: string, secondItemId?: string) => void;
  position?: 'left' | 'right';
  currentItem: NewItemType | undefined;
  setCurrentItem: React.Dispatch<React.SetStateAction<NewItemType | undefined>>;
  setExpanded: (expanded: boolean) => void;
  isOtherExpanded: boolean;
  setIsOtherExpanded: (isOtherExpanded: boolean) => void;
  theme: 'light' | 'dark';
}

export const MultiSelectMenu: React.FC<Props> = ({
  items,
  onSelect,
  position = 'right',
  currentItem,
  setCurrentItem,
  setExpanded,
  isOtherExpanded,
  setIsOtherExpanded,
  theme,
}) => {
  return (
    <>
      {items.map((item) => (
        <button
          type="button"
          key={item.id}
          css={
            currentItem?.id === item.id
              ? styles.newItemButtonActive
              : styles.newItemButton
          }
          onClick={() => {
            if (!item.items) {
              setExpanded(false);
              setIsOtherExpanded(false);
              onSelect(item.id);

              return;
            }

            setCurrentItem(item);
            setIsOtherExpanded(true);
          }}
          disabled={item.disabled}
        >
          <span>{item.element}</span>
          {item.items && <ArrowIcon />}
        </button>
      ))}

      {isOtherExpanded && currentItem && (
        <motion.div
          transition={{ duration: 0.2 }}
          exit={{ opacity: 0, y: 10, transition: { duration: 0.2 } }}
          css={[styles.newDropdown(position), styles.theme(theme)]}
          {...FADE_IN_MOVE_Y_REGULAR}
        >
          <div
            css={styles.newItemTitle}
            onClick={() => {
              setIsOtherExpanded(false);
              setCurrentItem(undefined);
            }}
          >
            <ArrowIcon css={position === 'left' && styles.arrowRight} />
            <p>{currentItem.element.toString()}</p>
          </div>

          {Boolean(currentItem.items?.length) ? (
            <>
              {currentItem.items?.map(({ id, element }) => (
                <button
                  type="button"
                  key={String(id)}
                  css={styles.dropdownListButton}
                  onClick={() => {
                    onSelect(currentItem.id, id);
                    setExpanded(false);
                    setIsOtherExpanded(false);
                    setCurrentItem(undefined);
                  }}
                >
                  {element}
                </button>
              ))}
            </>
          ) : (
            <button
              type="button"
              css={styles.dropdownListButton}
              disabled={true}
            >
              <FormattedMessage id="shared.noOptions" />
            </button>
          )}
        </motion.div>
      )}
    </>
  );
};
