import { RouteComponentProps } from '@reach/router';
import { useIntl } from 'gatsby-plugin-intl';
import Seo from 'modules/app/components/Seo';
import React from 'react';
import { Matches } from './components';
import { Search } from './Search';

export const TalentOpenings: React.FC<RouteComponentProps> = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Seo title={formatMessage({ id: 'seoTitle.talentOpenings' })} />

      <>
        <Matches />
        <Search />
      </>
    </>
  );
};
