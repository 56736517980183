import React from 'react';

import * as styles from '../styles/LoadMore.styles';
import { Button } from 'modules/app';
import { FormattedMessage } from 'react-intl';
import { SerializedStyles } from '@emotion/react';

interface Props {
  onClick: () => void;
  customStyles?: SerializedStyles | SerializedStyles[];
  theme?: 'black' | 'white';
}

export const LoadMore: React.FC<Props> = ({
  onClick,
  theme = 'white',
  customStyles,
}) => {
  return (
    <div css={[styles.root, styles[theme], customStyles]}>
      <Button onClick={onClick} buttonType="ghost">
        <FormattedMessage id="shared.page.load_more" />
      </Button>
    </div>
  );
};
