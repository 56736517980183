import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useGetCompanyDetails } from './useGetCompanyDetails';

interface DeactivateCompanyPlan {
  companyId: string;
  planId: string;
  reason: string;
}

export function useDeactivateCompanyPlan() {
  const adminCompaniesService = useHttpClient('/admin/companies');
  const { getCompanyDetails } = useGetCompanyDetails();
  const { toastError, toastSuccess } = useToastify();

  async function deactivateCompanyPlan({
    companyId,
    planId,
    reason,
  }: DeactivateCompanyPlan) {
    try {
      await adminCompaniesService.delete(
        `/${companyId}/deactivate-company-plan/${planId}`,
        {
          data: {
            reason,
          },
        },
      );
      const data = await getCompanyDetails(companyId);
      toastSuccess('shared.pills.successDefaultText');
      return data;
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      toastError();
    }
  }

  return { deactivateCompanyPlan };
}
