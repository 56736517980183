export const navbarVariant = (height?: string) => ({
  open: {
    height: height,
    transition: {
      duration: 1,
      ease: [0.19, 1, 0.22, 1],
    },
  },
  closed: {
    height: '0px',
    transition: {
      duration: 1,
      ease: [1, 0, 0, 1],
    },
  },
});

export const listAnimation = {
  open: {
    transition: {
      staggerChildren: 0.05,
      delayChildren: 0.3,
    },
  },
  exit: {
    transition: { staggerChildren: 0.1 },
  },
};

export const linkAnimation = {
  open: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.4, ease: 'easeOut' },
  },
  closed: { y: -20, opacity: 0 },
  exit: { y: -10, opacity: 0, transition: { duration: 0.2 } },
};
