import { useHttpClient, useToastify } from 'modules/app';

export const useManageStaffRole = () => {
  const adminStaffRoleService = useHttpClient('/admin/user-staff-roles');
  const { toastError, toastSuccess } = useToastify();

  async function requestAssignStaffRole(
    roleId: string,
    data: { roleId: string },
    callback: VoidFunction,
  ) {
    try {
      await adminStaffRoleService.post(`${roleId}/assign`, data);
      callback();
      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      toastError();
    }
  }

  async function requestExpireStaffRole(
    roleId: string,
    callback: VoidFunction,
  ) {
    try {
      await adminStaffRoleService.post(`${roleId}/expire`);
      callback();
      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      toastError();
    }
  }

  return {
    requestAssignStaffRole,
    requestExpireStaffRole,
  };
};
