import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';

export const statusPill = css`
  color: hsl(var(--color-text-1));
  cursor: default;
  font-size: var(--font-size-tiny);
  text-align: center;
  white-space: nowrap;
  border-radius: var(--border-radius-half);
  padding: ${spacing(5)} ${spacing(20)};
  width: fit-content;
  line-height: var(--line-height-tiny);
`;

export const statusVerified = css`
  background: hsl(var(--color-success-9));
  border: 1px solid hsl(var(--color-success-9));
`;

export const statusUnverified = css`
  border: 1px solid hsl(var(--color-borderUltraLight-9));
`;
