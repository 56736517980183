import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import Select, { IndicatorProps } from 'react-select';
import * as styles from 'modules/app/styles/Select.styles';
import { Label, Value } from 'modules/app';
import { FormError } from 'modules/app/intl';
import Clear from 'assets/icons/clear.svg';
import CaretDown from 'assets/icons/down.svg';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils';

interface Props {
  name: string;
  defaultValue?: SelectableNameStructure | SelectableNameStructure[];
  options: SelectableNameStructure[];
  label: React.ReactNode;
  customStyles?: SerializedStyles;
  isMulti?: boolean;
  isOptional?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  bottomText?: React.ReactNode;
  constraints?: RegisterOptions;
  theme?: 'light' | 'dark';
}

/**
 * Initial working select field component with default values
 * Manual value handling that returns string instead of object breaks defaultValue
 */
export const SelectField: React.FC<Props> = ({
  name,
  defaultValue,
  options,
  label,
  customStyles,
  isMulti,
  isOptional,
  isDisabled,
  placeholder,
  bottomText,
  constraints,
  theme = 'light',
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const ClearIndicator = (props: IndicatorProps<Value, boolean>) => {
    const {
      innerProps: { ref, ...restInnerProps },
    } = props;

    return (
      <Clear
        {...restInnerProps}
        ref={ref}
        height={16}
        width={16}
        css={margin.right.med}
      />
    );
  };

  const DropdownIndicator = (props: IndicatorProps<Value, boolean>) => {
    const {
      innerProps: { ref, ...restInnerProps },
    } = props;

    return <CaretDown {...restInnerProps} ref={ref} height={7} width={12} />;
  };

  return (
    <div
      css={[
        styles.root(theme),
        customStyles ? customStyles : undefined,
        errors[name] ? styles.error : undefined,
      ]}
    >
      <Label label={label} isOptional={isOptional} />

      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        rules={constraints}
        render={({ field }) => (
          <Select
            {...field}
            placeholder={placeholder}
            isMulti={isMulti}
            isDisabled={isDisabled}
            className="roango-multi-select"
            classNamePrefix="roango-select"
            options={options}
            components={{
              ClearIndicator,
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
          />
        )}
      />

      {bottomText && <span css={[styles.bottomText]}>{bottomText}</span>}

      <FormError errors={errors} name={name} />
    </div>
  );
};
