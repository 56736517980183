import React, { ChangeEvent, useState } from 'react';
import * as styles from '../styles/SearchBar.styles';
import SearchIcon from 'assets/icons/search.svg';
import ClearIcon from 'assets/icons/clear.svg';
import * as buttonStyles from 'modules/app/styles/Button.styles';
import { useIntl } from 'gatsby-plugin-intl';

interface Props {
  onChange: (value: string) => void;
  value?: string;
  placeholder: string;
  theme?: 'light' | 'dark';
  inputStyles?: SerializedStyles;
  containerStyles?: SerializedStyles;
}

export const SearchBar: React.FC<Props> = ({
  onChange,
  value,
  placeholder,
  theme = 'light',
  inputStyles,
  containerStyles,
}) => {
  const [val, setVal] = useState(value || '');
  const intl = useIntl();

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onChange(value);
    setVal(value);
  };

  const clearValue = () => {
    onChange('');
    setVal('');
  };

  return (
    <div css={[styles.container(theme), containerStyles]}>
      <input
        css={[styles.input(theme), inputStyles]}
        type="text"
        name="search"
        value={val}
        onChange={onChangeHandler}
        placeholder={intl.formatMessage({ id: placeholder })}
      />
      {val ? (
        <ClearIcon css={buttonStyles.root} onClick={clearValue} />
      ) : (
        <SearchIcon />
      )}
    </div>
  );
};
