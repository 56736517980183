import { motion } from 'framer-motion';
import React from 'react';

import * as styles from './styles/Skeleton.styles';

function getList(count: number) {
  const list = [];

  for (let i = 0; i < count; i++) {
    list.push(i);
  }

  return list;
}

interface Props {
  count: number;
  customStyles?: SerializedStyles;
  containerStyles?: SerializedStyles;
}

export const Skeleton: React.FC<Props> = ({
  count,
  customStyles,
  containerStyles,
}) => {
  const list = getList(count);

  return (
    <motion.div
      css={containerStyles}
      exit={{ transition: { duration: 0.3 }, opacity: 0 }}
    >
      {list.map((item) => (
        <div css={[customStyles, styles.root]} key={item} />
      ))}
    </motion.div>
  );
};
