import React from 'react';

export const useTransformSelect = (
  value: string | undefined | null,
  selectValues: {
    value: string;
    label: React.ReactNode;
  }[],
):
  | {
      value: string;
      label: React.ReactNode;
    }
  | undefined => {
  const valueLabel = selectValues.filter((val) => {
    return val.value === value;
  });
  if (value && selectValues) {
    return {
      value: value,
      label: valueLabel[0].label,
    };
  }
  return undefined;
};
