import React from 'react';
import { CompanyOpeningItem } from 'modules/openings/components';
import { ListHeader } from './ListHeader';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import * as styles from 'modules/dashboard/styles/Root.styles';
import { OpeningOverviewSkeleton } from 'modules/openings/components/OpeningOverviewSkeleton';

interface Props {
  companyHandle: string;
  isLoading: boolean;
}

export const OpeningsList: React.FC<Props> = ({ companyHandle, isLoading }) => {
  const { companyOpenings } = useSelector((state: AppState) => state.openings);

  return (
    <section css={styles.openings}>
      {isLoading ? (
        <OpeningOverviewSkeleton />
      ) : (
        companyOpenings &&
        Boolean(companyOpenings.length) && (
          <motion.div
            {...FADE_IN_MOVE_Y_REGULAR}
            transition={{ duration: 0.3 }}
            layout="position"
          >
            <ListHeader companyHandle={companyHandle} />
            <div>
              {companyOpenings.map((data) => (
                <CompanyOpeningItem key={data.id} data={data} />
              ))}
            </div>
          </motion.div>
        )
      )}
    </section>
  );
};
