import React from 'react';

import * as styles from './styles/SectionLink.styles';
import { Link } from 'modules/app/intl/Link';

interface Props {
  text: React.ReactNode;
  path: string;
  pathname: string;
  active: string;
}

export const SectionLink: React.FC<Props> = ({
  text,
  path,
  pathname,
  active,
}) => {
  return (
    <div css={Boolean(pathname.includes(active)) ? styles.active : styles.root}>
      <Link to={path}>{text}</Link>
    </div>
  );
};
