import { createAction } from 'modules/redux-store';

export const evaluationsActions = {
  setEvaluations: (payload: {
    evaluations: Evaluation[] | undefined;
    page: number;
    lastPage: number;
  }) => createAction('evaluations/SET_EVALUATIONS', payload),
  loading: () => createAction('evaluations/LOADING'),
  error: (payload: ApiErrorData) => createAction('evaluations/ERROR', payload),
  reset: () => createAction('evaluations/RESET'),
};
