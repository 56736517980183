import { css } from '@emotion/react';
import { GumGridConfig } from '@prototyp/gatsby-plugin-gumball/models';

export const pageTwoCols: GumGridConfig[] = [
  {
    mediaMinWidth: 'large',
    template: { column: '48% auto', row: 'auto' },
    gap: { row: 32 },
  },
];

export const pageTwoColsReverse: GumGridConfig[] = [
  {
    mediaMinWidth: 'large',
    template: { column: 'auto 48%', row: 'auto' },
    gap: { row: 32 },
  },
];

export const singleColumn: GumGridConfig[] = [
  {
    mediaMinWidth: 'medium',
    template: { column: '100% auto', row: 'auto' },
  },
];

export const doubleSpaceGrid: GumGridConfig[] = [
  {
    mediaMinWidth: 'medium',
    template: { column: '33% auto', row: 'auto' },
  },
];

export const base = css`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 32px;
  max-width: 1312px;
  margin: 0 auto;
`;

export const config = { pageTwoCols, singleColumn, pageTwoColsReverse, base };
