import { createAction } from 'modules/redux-store';

export const companyMessagesActions = {
  setConversations: (conversations?: Conversation[]) =>
    createAction('messages/SET_CONVERSATIONS', conversations),
  setMessages: (messages?: Conversation) =>
    createAction('messages/SET_MESSAGES', messages),
  loading: () => createAction('messages/LOADING'),
  error: (error: ApiErrorData) => createAction('messages/ERROR', error),
  reset: () => createAction('messages/MESSAGES_RESET'),
};
