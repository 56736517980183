import { impersonateAction } from 'modules/admin/redux';
import { useDispatch } from 'react-redux';
import { useAuthDispatch, useHttpClient } from 'modules/app';
import { navigate } from 'gatsby-plugin-intl';
import { HttpError } from 'modules/app/http/client/httpError';

export const useImpersonateEnd = () => {
  const adminImpersonateService = useHttpClient<SessionResponse>(
    '/admin/users/end-impersonate',
  );

  const dispatch = useDispatch();
  const { dispatchLogin } = useAuthDispatch();

  async function requestImpersonateEnd() {
    dispatch(impersonateAction.loading());

    try {
      const { data } = await adminImpersonateService.get('');
      dispatchLogin(
        data.user,
        data.userSession,
        data.userStaffRole,
        data.userCompanyRoles,
      );

      dispatch(impersonateAction.end({}));

      navigate(`/admin/users/`);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(impersonateAction.error(e));
    }
  }

  return {
    requestImpersonateEnd,
  };
};
