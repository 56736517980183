import { createAction } from 'modules/redux-store';

export const talentMessagesActions = {
  setConversations: (payload?: {
    conversations: Conversation[];
    lastPage: number;
    page: number;
  }) => createAction('talentMessages/SET_CONVERSATIONS', payload),
  error: (payload: ApiErrorData) =>
    createAction('talentMessages/ERROR', payload),
  loading: () => createAction('talentMessages/LOADING'),

  setMessages: (payload?: Conversation) =>
    createAction('talentMessages/SET_MESSAGES', payload),
  messagesLoading: () => createAction('talentMessages/MESSAGES_LOADING'),
  messagesError: (payload: ApiErrorData) =>
    createAction('talentMessages/MESSAGES_ERROR', payload),

  setUnreadConversations: (payload: string[]) =>
    createAction('talentMessages/SET_UNREAD_CONVERSATIONS', payload),
  setConversationsList: (payload: { id: string; applicationId: string }[]) =>
    createAction('talentMessages/SET_CONVERSATIONS_LIST', payload),

  resetConversations: () => createAction('talentMessages/RESET_CONVERSATIONS'),
  reset: () => createAction('talentMessages/RESET'),
};
