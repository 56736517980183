import React from 'react';
import { Alert, StatusCodes } from 'modules/app';
import { SerializedStyles } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
import { HttpError as IHttpError } from '../client/httpError';

interface Props {
  error?: ApiErrorData | IHttpError;
  styles?: SerializedStyles;
}

const invalidDataGroup: ResponseErrorCause[] = [
  'email_not_verified',
  'token_action_executed',
  'token_action_expired',
  'no_email_field',
  'no_email_attribute',
  'no_password_field',
  'no_password_attribute',
  'registration_invalid_email',
  'item_already_exists',
];

const unauthorizedGroup: OauthErrors[] = [
  'oauth_github_invalid_authorization_code',
  'oauth_google_invalid_authorization_code',
  'oauth_linkedin_invalid_authorization_code',
];

function isOauthError(
  cause: ResponseErrorCause | undefined,
): cause is OauthErrors {
  const typedCause = cause as OauthErrors;
  return typedCause && unauthorizedGroup.includes(typedCause);
}

export const HttpError: React.FC<Props> = ({ error, styles }) => {
  if (!error) return null;

  let message = '',
    values: Record<string, string> | undefined = undefined;

  switch (error.type) {
    case 'network_error':
      message = 'http_errors.network_error';
      break;
    case 'timeout_error':
      message = 'http_errors.timeout_error';
      break;
  }

  if (error.status === StatusCodes.TOO_MANY_REQUESTS) {
    const [seconds] = error.message?.match(/(\d+)s/) || [];

    message = 'http_errors.too_many_requests';
    if (seconds) values = { seconds };
  }

  if (message) {
    return (
      <Alert
        show
        message={<FormattedMessage id={message} values={values} />}
        type="error"
        styles={styles}
      />
    );
  }

  if (error.code === 'not_found') {
    message = 'http_errors.invalid_reset_token';
  }

  if (isOauthError(error.cause)) {
    message = 'http_errors.data_invalid';
  }

  if (error.cause && invalidDataGroup.includes(error.cause)) {
    message = 'data_invalid';
  }

  switch (error.cause) {
    case 'email_not_verified':
      message = 'http_errors.email_not_verified';
      break;
    case 'registration_invalid_otp_token':
      message = 'http_errors.invalid_qr_token';
      break;
    case 'invalid_credentials':
      message = 'http_errors.invalid_credentials';
      break;
    case 'invalid_otp_token':
      message = 'http_errors.invalid_login_token';
      break;
    case 'item_already_exists':
      message = 'http_errors.item_already_exists';
      break;
    case 'token_action_executed':
      message = 'settings.userJoin.alreadyJoined';
      break;
    case 'token_action_expired':
      message = 'settings.userJoin.expired';
      break;
    case 'cv_already_requested':
      message = 'http_errors.cv_requested';
      break;
    default:
      message = 'http_errors.generic_error';
  }

  return (
    <Alert
      show
      type="error"
      message={<FormattedMessage id={message} />}
      styles={styles}
    />
  );
};
