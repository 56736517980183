import { AppState } from 'modules/redux-store';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useGracePeriod = () => {
  const { latestPlan } = useSelector((state: AppState) => state.companyData);

  const isGracePeriod = useMemo(() => {
    if (!latestPlan || latestPlan.status === 'active') return false;

    const date = new Date();
    const expires = new Date(latestPlan.expiresAt);
    const graceExpires = new Date(latestPlan.expiresAt);
    graceExpires.setDate(expires.getDate() + 15);

    if (date > expires && date < graceExpires) {
      return true;
    }

    return false;
  }, [latestPlan]);

  return { isGracePeriod };
};
