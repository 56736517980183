import React from 'react';
import { checkSessionStatus, formatDate } from 'modules/admin/static';
import { Button } from 'modules/app';
import {
  flex,
  font,
  margin,
  text,
} from '@prototyp/gatsby-plugin-gumball/utils';
import * as util from 'modules/admin/styles/Utility.styles';
import { useAdminSessions } from 'modules/admin/hooks';
import { StatusDot } from './StatusDot';

export const UserSessionItem: React.FC<Sessions> = ({
  id,
  createdAt,
  expiresAt,
  loggedInIp,
  userAgent,
  authType,
}) => {
  const { killSession, isLoading, isSessionKilled } = useAdminSessions();

  const onKillSession = (sessionId: string) => {
    killSession(sessionId);
  };

  const checkStatus = (expires: string) => {
    if (isSessionKilled) {
      return false;
    }

    return Boolean(checkSessionStatus(expires));
  };

  return (
    <tr css={isLoading && util.statusLoading}>
      <td>
        <div css={flex.display.flex}>
          <StatusDot status={checkStatus(expiresAt)} />
          <div>
            <div css={[util.textFaded, margin.left.xxxlrg]}>
              {formatDate(createdAt, 'P, HH:mm')}
              <span css={[margin.left.tny, margin.right.tny]}> - </span>
              {formatDate(expiresAt, 'P, HH:mm')}
            </div>
            <div
              css={[font.size.medium, margin.left.xxxlrg, margin.bottom.lrg]}
            >
              {loggedInIp}
            </div>
            <div css={[font.size.small, margin.left.xxxlrg, margin.bottom.sml]}>
              <strong>Auth type: {authType}</strong>
            </div>
            <div css={[font.size.small, margin.left.xxxlrg, util.textFaded]}>
              {userAgent}
            </div>
          </div>
        </div>
      </td>
      <td valign="middle" width="150" css={text.align.right}>
        {checkStatus(expiresAt) && (
          <Button
            onClick={() => onKillSession(id)}
            buttonType="outline"
            disabled={isLoading}
            size="tiny"
          >
            Kill session
          </Button>
        )}
      </td>
    </tr>
  );
};
