import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import * as styles from 'modules/app/styles/ProfileUpload.styles';
import { LabelTag, ParentModal } from 'modules/app';
import { ImageRepresentation } from './ImageRepresentation';
import { useMemoryUpload } from './useMemoryUpload';
import { UploadError } from './UploadError';

const CropWindow = React.lazy(
  () => import('modules/app/components/crop/CropWindow'),
);

interface Props {
  label?: string;
  bottomLabel?: string;
  inputName: string;
  onImageUploaded: (img: string) => void;
  minAspectRatio?: number;
  maxAspectRatio?: number;
  company?: Company;
  customStyles?: SerializedStyles;
  existingImage: string | null;
  optional?: boolean;
}

export const Root: React.FC<Props> = ({
  label,
  inputName,
  minAspectRatio = 16,
  maxAspectRatio = 9,
  bottomLabel,
  onImageUploaded,
  existingImage,
  customStyles,
  optional = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploadedImage, setUploadedImage] = useState<FileReader>();
  const [imagePreview, setImagePreview] = useState<string>('');
  const [isCroppingStarted, setIsCroppingStarted] = useState(false);
  const [alreadyUploadedImage, setAlreadyUploadedImage] =
    useState(existingImage);

  const { isFileInvalidFormat, isFileTooBig, onChange } = useMemoryUpload(
    10485760,
    (reader: FileReader) => {
      setUploadedImage(reader);
      setIsCroppingStarted(true);
    },
  );

  const onTriggerUpload = () => {
    if (inputRef.current) {
      inputRef.current?.click();
    }
  };

  const onCropFinished = async (blob: Blob) => {
    setIsCroppingStarted(false);

    const bufReader = new FileReader();
    bufReader.readAsArrayBuffer(blob);

    bufReader.onloadend = () => {
      const arr = new Uint8Array(bufReader.result as ArrayBuffer);
      const str = arr.reduce((data, byte) => {
        return data + String.fromCharCode(byte);
      }, '');

      const base64 = btoa(str);
      const img = `data:${blob.type};base64,${base64}`;
      onImageUploaded(img);

      setAlreadyUploadedImage(null);
    };

    setImagePreview(URL.createObjectURL(blob));
  };

  return (
    <div css={[styles.root, customStyles]}>
      <div css={styles.labelWithButton}>
        {label && (
          <LabelTag fontFamily="primary" size="small" css={styles.label}>
            <FormattedMessage id={label} />
          </LabelTag>
        )}

        {optional && (
          <LabelTag fontFamily="primary" size="small" css={styles.label}>
            <FormattedMessage id="shared.page.optional" />
          </LabelTag>
        )}
      </div>

      <div
        onClick={onTriggerUpload}
        css={[
          styles.uploadOverlayBorder,
          uploadedImage || alreadyUploadedImage ? styles.noHeight : undefined,
        ]}
      >
        <ImageRepresentation
          existingImage={alreadyUploadedImage}
          uploadedImage={
            imagePreview && !isCroppingStarted ? imagePreview : undefined
          }
        />

        <input
          ref={inputRef}
          css={styles.fileInput}
          type="file"
          onChange={onChange}
          name={inputName}
        />
      </div>

      <UploadError
        isFileInvalidFormat={isFileInvalidFormat}
        isFileTooBig={isFileTooBig}
      />

      {bottomLabel && (
        <p css={styles.bottomLabel}>
          <FormattedMessage id={bottomLabel} />
        </p>
      )}

      {isCroppingStarted && uploadedImage && (
        <ParentModal>
          <React.Suspense fallback={null}>
            <CropWindow
              onFinished={onCropFinished}
              onCancel={() => setUploadedImage(undefined)}
              modalData={{ url: uploadedImage.result?.toString() }}
              minAspectRatio={minAspectRatio}
              maxAspectRatio={maxAspectRatio}
            />
          </React.Suspense>
        </ParentModal>
      )}
    </div>
  );
};
