import React from 'react';
import { Alert } from 'modules/app';
import { SerializedStyles } from '@emotion/react';
import { FormattedMessage } from 'react-intl';

import * as validationErrorStyles from 'modules/app/styles/ValidationError.styles';

interface Props {
  error?: ApiErrorData;
  styles?: SerializedStyles;
}

export const ValidationError: React.FC<Props> = ({ error, styles }) => {
  if (!error) return null;

  let message = '';

  switch (error.cause) {
    case 'max_number_reached:featured_projects':
      message = 'http_errors.max_featured_projects';
      break;
  }

  return (
    <Alert
      show
      type="error"
      message={<FormattedMessage id={message} />}
      styles={[validationErrorStyles.root, styles]}
    />
  );
};
