import { ActionUnion } from 'modules/redux-store';
import {
  Action,
  applyMiddleware,
  CombinedState,
  combineReducers,
  createStore,
  ReducersMapObject,
  Store,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer } from 'redux-persist';
import persistStore from 'redux-persist/es/persistStore';
import { Persistor } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage';
import { sessionReducer as loggedInReducer } from '../state/session/reducer';

import { AppState } from '../models';
import { registerReducer } from 'modules/register';
import { reducer as loginReducer } from 'modules/login';
import { reducer as supportReducer } from 'modules/support/redux';
import {
  talentOnboardingReducer,
  companyOnboardingReducer,
} from 'modules/onboarding';
import { companyDataReducer, userReducer } from 'modules/profile';
import { settingsReducer } from 'modules/settings';
import { quickSearchReducer } from 'modules/quickSearch';
import { openingsReducer } from 'modules/openings';
import { talentOpeningsReducer } from 'modules/talentOpenings';
import { pipelinesReducer } from 'modules/pipelines';
import { applicationsReducer } from 'modules/applications';
import { applicationFlowReducer } from 'modules/application';
import { talentDashboardReducer } from 'modules/talentDashboard';
import { companyDashboardReducer } from 'modules/dashboard';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import {
  adminReducer,
  searchUsersReducer,
  activityReducer,
  impersonateReducer,
  searchUsersDataCopyRequestReducer,
} from 'modules/admin';
import { talentMessagesReducer } from 'modules/talentMessages';
import { undoTimerReducer } from '../../applications/redux/undoTimerReducer';
import { reducer as notesReducer } from 'modules/notes';
import { reducer as plansReducer } from 'modules/admin/plans/redux/reducer';
import { billingReducer } from 'modules/billing';
import { companyMessagesReducer } from 'modules/companyMessages';
import { invoicesReducer } from 'modules/admin/invoices';
import { applicantsReducer } from 'modules/admin/applicants';
import { adminBillingActivityReducer } from 'modules/admin/billing-activity';
import { reducer as evaluationsReducer } from 'modules/evaluations';
import { reducer as companyActivityReducer } from 'modules/activity';

export const createReduxStore = (): { store: Store; persistor: Persistor } => {
  const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2,
    whitelist: [
      'registration',
      'login',
      'session',
      'talentOnboarding',
      'companyOnboarding',
      'companyData',
      'settings',
      'quickSearch',
      'user',
      'pipelines',
      'applications',
      'applicationFlow',
      'adminUsers',
      'talentDashboard',
      'adminSessionsList',
      'adminSearchUsers',
      'talentMessages',
      'adminImpersonate',
      'undoTimer',
      'billing',
      'companyMessages',
      'evaluations',
      'activity',
    ],
  };
  /**
   * Create the composing function for our middlewares
   * Include dev tools support
   */
  const composeEnhancers = composeWithDevTools({});

  /**
   * Merge all reducers into a single object
   */
  const rootReducer: ReducersMapObject<AppState> = {
    session: loggedInReducer,
    registration: registerReducer,
    login: loginReducer,
    talentOnboarding: talentOnboardingReducer,
    companyOnboarding: companyOnboardingReducer,
    companyData: companyDataReducer,
    settings: settingsReducer,
    quickSearch: quickSearchReducer,
    user: userReducer,
    openings: openingsReducer,
    talentOpenings: talentOpeningsReducer,
    pipelines: pipelinesReducer,
    applications: applicationsReducer,
    applicationFlow: applicationFlowReducer,
    adminUsers: adminReducer,
    talentDashboard: talentDashboardReducer,
    faq: supportReducer,
    companyDashboard: companyDashboardReducer,
    adminSearchUsers: searchUsersReducer,
    adminDataCopyRequestUsers: searchUsersDataCopyRequestReducer,
    adminActivity: activityReducer,
    talentMessages: talentMessagesReducer,
    adminImpersonate: impersonateReducer,
    undoTimer: undoTimerReducer,
    notes: notesReducer,
    plans: plansReducer,
    billing: billingReducer,
    companyMessages: companyMessagesReducer,
    invoices: invoicesReducer,
    applicants: applicantsReducer,
    evaluations: evaluationsReducer,
    activity: companyActivityReducer,
    adminBillingActivity: adminBillingActivityReducer,
  };

  const reducers = combineReducers(rootReducer);

  // Action<AuthActionType> is only here temporarily, later on, more state will be created
  // and a union type will be used here specify all action types
  const persistedReducer = persistReducer<
    CombinedState<AppState>,
    Action<ActionUnion<AuthActionType>>
  >(persistConfig, reducers);

  const store: Store = createStore(
    persistedReducer,
    {},
    process.env.NODE_ENV === 'production'
      ? applyMiddleware()
      : composeEnhancers(applyMiddleware()),
  );

  const persistor: Persistor = persistStore(store);

  return {
    store: store,
    persistor: persistor,
  };
};
