export function billingDefaultValues(
  billingInfo?: BillingDetailsResponse,
): BillingDetailsRequest {
  return {
    companyName: billingInfo?.companyName || '',
    companyIdentificationNumber: billingInfo?.companyIdentificationNumber || '',
    billingEmail: billingInfo?.billingEmail || '',
    city: billingInfo?.city || '',
    countryOfIncorporation: billingInfo?.countryOfIncorporation || '',
    addressLine: billingInfo?.addressLine || '',
    zip: billingInfo?.zip || '',
    stateRegion: billingInfo?.stateRegion || '',
    vatId: billingInfo?.vatId || '',
  };
}
