export function isEditDisabled(data: CompanyOpening) {
  return data.status === 'archived';
}

export function isArchiveDisabled(data: CompanyOpening) {
  return [
    'archived',
    'draft',
    'scheduled-for-publishing-(internal)',
    'scheduled-for-publishing-(public)',
  ].includes(data.status);
}

export function isDeleteDisabled(data: CompanyOpening) {
  return data.status !== 'draft' && data.status !== 'archived';
}
