import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { GooglePlacesAutocomplete, Tag } from 'modules/app';
import { useReset } from 'modules/onboarding';
import React from 'react';

interface Props {
  setLocations: React.Dispatch<React.SetStateAction<string[]>>;
  locations: string[];
}

export const GooglePlacesArray: React.FC<Props> = ({
  locations,
  setLocations,
}) => {
  const { resetValue, setReset } = useReset();

  const onDelete = (id: string) => {
    const newLocations = locations.filter((location) => location !== id);
    setLocations(newLocations);
  };

  const onSelect = (selected?: SelectedPlace) => {
    if (!selected) {
      return;
    }

    setReset();
    setLocations((locations) => [...locations, selected.cityCountry]);
  };

  return (
    <div css={margin.bottom.xxxlrg}>
      <GooglePlacesAutocomplete
        name="location"
        id="location"
        label="search.locationLabel"
        selectedPlace={undefined}
        onSelectedPlace={onSelect}
        placeholder="search.locationsPlaceholder"
        isRequired={false}
        reset={resetValue}
        customStyles={margin.bottom.lrg}
      />

      {Boolean(locations.length) &&
        locations.map((location, i) => {
          return (
            <Tag text={location} key={i} onDelete={() => onDelete(location)} />
          );
        })}
    </div>
  );
};
