import React from 'react';
import { LabelTag, MainContent } from 'modules/app';
import { Link } from 'gatsby-plugin-intl';

import * as styles from 'modules/dashboard/styles/ListHeader.styles';
import { FormattedMessage } from 'react-intl';

interface Props {
  companyHandle: string;
}

export const ListHeader: React.FC<Props> = ({ companyHandle }) => {
  return (
    <div css={styles.root}>
      <MainContent>
        <LabelTag size="small" fontFamily="primary">
          <FormattedMessage id="companyDashboard.latestOpeningsLabel" />
        </LabelTag>
        <Link
          to={`/profile/company/openings/${companyHandle}`}
          css={styles.link}
        >
          <FormattedMessage id="companyDashboard.viewAllOpenings" />
        </Link>
      </MainContent>
    </div>
  );
};
