import React, { InputHTMLAttributes } from 'react';

import * as styles from 'modules/app/styles/TextInput.styles';
import { SerializedStyles } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'gatsby-plugin-intl';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  label?: React.ReactNode;
  icon?: React.ReactNode;
  showError?: boolean;
  formErrors?: React.ReactNode;
  isOptional?: boolean;
  helpText?: boolean;
  css?: SerializedStyles;
  isPassword?: boolean;
  maxChar?: string;
}

export const TextInput: React.FC<Props> = ({
  showError,
  isOptional,
  formErrors,
  label,
  id,
  icon,
  helpText,
  css,
  isPassword,
  maxChar,
  ...props
}) => {
  const { formatMessage } = useIntl();

  return (
    <div css={[showError ? styles.rootError : styles.root, css]}>
      <div css={showError ? styles.labelWrapperError : styles.labelWrapper}>
        <label htmlFor={id}>{label}</label>
        {isOptional && (
          <span css={styles.optional}>
            <FormattedMessage id="shared.page.optional" />
            {maxChar &&
              `, ${maxChar} ${formatMessage({
                id: 'onboarding.form.fields.information.characters_max',
              })}`}
          </span>
        )}
      </div>

      <input
        id={id}
        css={Boolean(icon) ? styles.inputWithIcon : styles.input}
        {...props}
      />
      {icon && (
        <span css={isPassword ? styles.iconPassword : styles.icon}>{icon}</span>
      )}
      <div css={styles.helpContainer}>
        {showError && formErrors ? (
          <span css={styles.fieldError}>{formErrors}</span>
        ) : (
          helpText
        )}
      </div>
    </div>
  );
};
