function recursiveTextCheck(node: CustomSlateElement): string {
  if (Array.isArray(node)) {
    let nodeText = '';
    for (const child of node) {
      if (child.children) {
        nodeText += recursiveTextCheck(child.children);
      }

      if (child.text) {
        nodeText += child.text;
      }
    }

    return nodeText;
  }

  // will only reach here if node is empty or not an array
  return '';
}

export const getEditorText = (editorText: string): string => {
  return recursiveTextCheck(JSON.parse(editorText));
};
