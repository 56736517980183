function getUnreadConversation(conversation: Conversation, userId?: string) {
  const conversationUser = conversation.conversationUsers.find(
    (conv) => conv.userId === userId,
  );

  if (!conversationUser) return;

  if (!conversationUser.lastView) {
    return conversation;
  }

  if (
    new Date(conversation.updatedAt).valueOf() >
    new Date(conversationUser.lastView).valueOf()
  ) {
    return conversation;
  }

  return;
}

export function getUnreadList(conversations: Conversation[], user: User) {
  const unreadConversations = conversations.filter((conversation) =>
    getUnreadConversation(conversation, user.id),
  );

  return unreadConversations.map((conversation) => conversation.id);
}
