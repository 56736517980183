import React from 'react';
import * as styles from 'modules/app/styles/MainContent.styles';
import { usePreviewContext } from 'modules/app';

interface Props {
  customStyles?: SerializedStyles | SerializedStyles[];
}

export const MainContent: React.FC<Props> = ({ children, customStyles }) => {
  const { ctx } = usePreviewContext();

  return (
    <div
      css={[
        ctx.isPreview ? styles.mainContentPreview : styles.mainContent,
        customStyles,
      ]}
    >
      {children}
    </div>
  );
};
