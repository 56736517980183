import { useHttpClient } from 'modules/app';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { openingsActions } from 'modules/openings';
import { companyDashboardAction } from '..';
import { HttpError } from 'modules/app/http/client/httpError';

interface PaginatedResponse<T> {
  page: number;
  per_page: number;
  total: number;
  last_page: number;
  data: T[];
}

export const useGetData = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiErrorData>();
  const activeStatuses: PublishStatus[] = [
    'published-public',
    'published-internal',
    'scheduled-for-publishing-(public)',
    'scheduled-for-publishing-(internal)',
    'closed',
  ];
  const deactiveStatuses: PublishStatus[] = [
    'archived',
    'draft',
    'deactivated',
  ];

  const http = useHttpClient('/api/v1/company');

  const openingsDispatch = (res: {
    data: CompanyOpening[];
    page: number;
    per_page: number;
    last_page: number;
  }) => {
    dispatch(
      openingsActions.setCompanyOpenings({
        companyOpenings: res.data,
        pageCount: res.last_page,
        perPage: res.per_page,
        currentPage: res.page,
      }),
    );
  };

  async function getStats(companyId: string) {
    try {
      const { data } = await http.get<CompanyStats>(
        `/${companyId}/openings/dashboard-stats`,
      );
      dispatch(companyDashboardAction.setStats(data));
      return;
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      return e;
    }
  }

  async function getVisits(companyId: string) {
    try {
      const { data } = await http.get<CompanyVisits>(
        `/${companyId}/openings/dashboard-visits`,
      );
      dispatch(companyDashboardAction.setVisits(data));
      return;
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      return e;
    }
  }

  async function getOpeningsFn(companyId: string, statuses?: string) {
    try {
      const { data } = await http.get<PaginatedResponse<CompanyOpening>>(
        `/${companyId}/openings`,
        {
          params: {
            page: 1,
            per_page: 3,
            statuses: statuses,
          },
        },
      );
      return data;
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      return e;
    }
  }

  async function getOpenings(companyId: string) {
    const res = await getOpeningsFn(companyId, activeStatuses.join(','));
    if (res instanceof HttpError || res === undefined) {
      return res;
    }

    if (res.data.length) {
      openingsDispatch({ ...res });
      return;
    }

    const newRes = await getOpeningsFn(companyId, deactiveStatuses.join(','));
    if (newRes instanceof HttpError || newRes === undefined) {
      return newRes;
    }
    openingsDispatch({ ...newRes });
    return;
  }

  const getData = async (companyId: string) => {
    setIsLoading(true);

    const response = await Promise.all([
      getOpenings(companyId),
      getVisits(companyId),
      getStats(companyId),
    ]);

    const error = response.find((error) => error !== undefined);

    if (error) {
      setIsLoading(false);
      setError(error);

      return;
    }

    setIsLoading(false);
  };

  return {
    getData,
    isLoading,
    error,
  };
};
