import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  HttpError,
  SelectComponent,
  Button,
  PortfolioInput,
  useFormGuardEffect,
  portfolioValues,
} from 'modules/app';
import { FormattedMessage } from 'react-intl';
import { useCompanySocials, useReset } from 'modules/onboarding';

import * as styles from 'modules/app/styles/GenericForm.styles';
import * as inputStyles from 'modules/app/styles/PortfolioForm.styles';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { useCompanyPortfolio } from 'modules/profile';

interface Props {
  isOnboarding?: boolean;
  resetFormCounter?: () => void;
  onAddPortfolio?: VoidFunction;
}

export const CompanySocialMedia: React.FC<Props> = ({
  isOnboarding = false,
  resetFormCounter,
  onAddPortfolio,
}) => {
  const methods = useForm<CompanyPortfolioRequest>({
    defaultValues: {
      portfolioId: '',
      handle: null,
      url: null,
    },
  });
  useFormGuardEffect(methods, isOnboarding);
  const { handleSubmit, reset, setError } = methods;

  const { resetValue, setReset } = useReset();
  const { error: httpError, addSocial } = useCompanySocials();
  const { error, isLoading, addPortfolio } = useCompanyPortfolio();

  const { companyData } = useSelector((state: AppState) => state.companyData);

  const [selectedPortfolio, setSelectedPortfolio] = useState<string | string[]>(
    '',
  );

  function onboardingCallback() {
    reset();
    setReset();
  }

  async function onSubmit(data: CompanyPortfolioRequest) {
    const model: CompanyPortfolioRequest = {
      portfolioId: data.portfolioId,
      handle: data.portfolioId === 'other' ? null : data.url,
      url: data.portfolioId === 'other' ? data.url : null,
    };

    if (isOnboarding) {
      addSocial(model, onboardingCallback, setError);
      return;
    }

    if (!companyData?.id || !onAddPortfolio) return;

    addPortfolio(companyData.id, model, onAddPortfolio, setError);
  }

  return (
    <div css={styles.root}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {(error || httpError) && (
            <HttpError styles={styles.httpError} error={httpError || error} />
          )}

          <div css={inputStyles.portfolioGrid}>
            <SelectComponent
              name="portfolioId"
              label={
                <FormattedMessage id="onboarding.form.fields.socials.social_media" />
              }
              setSelectedValue={setSelectedPortfolio}
              reset={resetValue}
              constraints={{
                required: true,
              }}
              values={portfolioValues}
            />
            <div>
              <PortfolioInput
                customStyles={inputStyles.portfolioInput}
                constraints={{
                  required: true,
                  minLength: {
                    value: 3,
                    message: 'shared.errors.min',
                  },
                  validate: (url: string) => {
                    if (selectedPortfolio !== 'other') return true;
                    return /^(http|https):\/\/[^ "]+$/.test(url)
                      ? true
                      : 'onboarding.form.errors.url';
                  },
                }}
                name="url"
                value=""
                selectedPortfolio={selectedPortfolio}
                reset={resetValue}
              />
            </div>
          </div>

          <Button
            type="submit"
            disabled={isLoading}
            css={styles.submitBtn}
            isLoading={isLoading}
          >
            <FormattedMessage id="onboarding.form.fields.socials.save_social" />
          </Button>

          {!isOnboarding && resetFormCounter && (
            <Button
              css={styles.cancelBtn}
              buttonType="ghost"
              onClick={resetFormCounter}
              disabled={isLoading}
            >
              <FormattedMessage id="profileManagement.heading.buttons.cancel" />
            </Button>
          )}
        </form>
      </FormProvider>
    </div>
  );
};
