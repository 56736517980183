import { FileRejection } from 'react-dropzone';

export function attachmentErrors(rejected?: FileRejection[]): string[] {
  if (rejected && Boolean(rejected.length)) {
    const errorCodes = rejected.map(({ errors }) => errors[0].code);
    const uniqueErrors = new Set(errorCodes);
    const errors = Array.from(uniqueErrors);

    return errors;
  }

  return [];
}
