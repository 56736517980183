import { FormattedMessage } from 'gatsby-plugin-intl';
import { Heading } from 'modules/app';
import React from 'react';
import { FeaturesTheme } from './FeatureListItem';

import * as featuresList from 'modules/app/styles/FeaturesList.styles';
import { ColorInput } from 'modules/app/components/shared/ImageGridLayout';
import { motion } from 'framer-motion';
import { useOnScrollAnimation } from 'modules/app/hooks/useOnScrollAnimation';
import {
  FADE_IN_MOVE_Y_CONTROLLED,
  STAGGER_CONTAINER,
  TRANSITION,
} from 'style/animations.config';

interface Props {
  heading: string;
  bgColor?: ColorInput;
  theme?: FeaturesTheme;
}

export const FeaturesList: React.FC<Props> = ({
  children,
  heading,
  bgColor,
  theme,
}) => {
  const list = useOnScrollAnimation({ threshold: 0.3 });
  const title = useOnScrollAnimation({ threshold: 0.2 });

  return (
    <article css={featuresList.features(bgColor)}>
      <div
        ref={title.ref}
        css={[featuresList.wrapper, featuresList.grid]}
        role="presentation"
      >
        <Heading
          as="h2"
          useMotion
          size="fluidXxxLarge"
          css={featuresList.heading}
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={TRANSITION()}
          initial="hidden"
          animate={title.animation}
        >
          <FormattedMessage id={heading} />
        </Heading>

        <motion.ul
          ref={list.ref}
          variants={STAGGER_CONTAINER}
          initial="hidden"
          animate={list.animation}
          css={featuresList.featuresList(theme)}
        >
          {children}
        </motion.ul>
      </div>
    </article>
  );
};
