import {
  toastError as error,
  toastSuccess as success,
  toastInfo as info,
} from 'style/toastify.config';
import { toast } from 'react-toastify';
import { useIntl } from 'gatsby-plugin-intl';
import { ReactElement } from 'react';

export type ValueType = Record<string, string | ReactElement | undefined>;

export const useToastify = () => {
  const { formatMessage } = useIntl();

  const toastError = (textId?: string, values?: ValueType) => {
    return toast.warning(
      formatMessage(
        { id: textId ? textId : 'http_errors.generic_error' },
        { ...values },
      ),
      { ...error },
    );
  };

  const toastSuccess = (textId: string, values?: ValueType) => {
    return toast.info(formatMessage({ id: textId }, { ...values }), {
      ...success,
    });
  };

  const toastInfo = (textId: string, values?: ValueType) => {
    return toast.warning(formatMessage({ id: textId }, { ...values }), {
      ...info,
    });
  };

  return { toastError, toastSuccess, toastInfo };
};
