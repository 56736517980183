import React from 'react';
import copy from 'copy-to-clipboard';
import * as styles from 'modules/app/styles/ClipboardLabel.styles';
import CopyToClipboard from 'assets/icons/copyToClipboard.svg';

interface Props {
  data: string;
}

export const ClipboardLabel: React.FC<Props> = ({ data }) => {
  return (
    <div onClick={() => copy(data)} css={styles.root}>
      <p>{data}</p>
      <CopyToClipboard />
    </div>
  );
};
