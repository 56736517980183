import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { HttpError, IconButton } from 'modules/app';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';

import * as styles from 'modules/talentMessages/styles/MobileMessages.styles';
import { info as infoStyles } from 'modules/talentMessages/styles/Messages.styles';
import { MessageList } from './MessageList';
import { SendReply } from './SendReply';
import { useConversations } from '../hooks';

import CloseIcon from 'assets/icons/close.svg';
import { MessagesLoading } from './MessagesLoading';
import { isOpeningActive } from '../utils';
import { FormattedMessage, navigate } from 'gatsby-plugin-intl';

export const MobileMessages: React.FC = () => {
  const { messages, isLoading, error } = useSelector(
    (state: AppState) => state.talentMessages.messages,
  );
  const { conversationId, getMessages } = useConversations();

  const isActive = isOpeningActive(messages);

  const closeMessages = () => {
    navigate('/profile/talent/messages');
  };

  useEffect(() => {
    if (!conversationId) return;

    getMessages(conversationId);
  }, [conversationId]);

  return (
    <motion.section
      css={styles.root}
      animate={{ x: 0 }}
      initial={{ x: '100vw' }}
      exit={{ x: '100vw' }}
      transition={{ duration: 0.4, ease: 'easeInOut' }}
    >
      {isLoading ? (
        <MessagesLoading />
      ) : !error && messages ? (
        <motion.div
          {...FADE_IN_MOVE_Y_REGULAR}
          transition={{ duration: 0.2 }}
          css={styles.contentWrapper}
        >
          <div css={styles.header}>
            <p css={styles.title}>{messages.subject}</p>
            <IconButton
              type="button"
              buttonType="outline"
              onClick={closeMessages}
              icon={<CloseIcon />}
              size="tiny"
              css={styles.closeBtn}
            />
          </div>
          {!isActive && (
            <p css={infoStyles}>
              <FormattedMessage id="talentMessages.openingInactive" />
            </p>
          )}
          <MessageList messages={messages} />
          {isActive && <SendReply messages={messages} />}
        </motion.div>
      ) : (
        <HttpError error={error} />
      )}
    </motion.section>
  );
};
