import { ActionUnion } from 'modules/redux-store';

import { applicationsAction } from './actions';

interface ApplicationsInitState {
  currentStage: string;
  applications: {
    applications?: Application[];
    page: number;
    lastPage: number;
  };
  isApplicationsLoading: boolean;
  applicationsError?: ApiErrorData;
  application?: Application;
  isApplicationLoading: boolean;
  applicationError?: ApiErrorData;
  tags?: { value: string; label: string }[];
  history?: History;
  opening?: CompanyOpening;
  isOpeningLoading: boolean;
  openingError?: ApiErrorData;
  openingsValues?: OpeningsList[];
  reasonsValues?: ReasonsList[];
}

const INITIAL_STATE: ApplicationsInitState = {
  currentStage: 'all',
  isApplicationsLoading: false,
  isApplicationLoading: false,
  isOpeningLoading: false,
  applications: {
    page: 1,
    lastPage: 1,
  },
  history: undefined,
};

export const applicationsReducer = (
  state: ApplicationsInitState = INITIAL_STATE,
  action: ActionUnion<typeof applicationsAction>,
): ApplicationsInitState => {
  switch (action.type) {
    case 'applications/CURRENT_STAGE':
      return {
        ...state,
        currentStage: action.payload,
      };

    case 'applications/OPENING':
      return {
        ...state,
        opening: action.payload,
        isOpeningLoading: false,
        openingError: undefined,
      };
    case 'applications/OPENING_LOADING':
      return {
        ...state,
        isOpeningLoading: true,
        openingError: undefined,
      };
    case 'applications/OPENING_ERROR':
      return {
        ...state,
        isOpeningLoading: false,
        openingError: action.payload,
      };

    case 'applications/APPLICATIONS_LOADING':
      return {
        ...state,
        isApplicationsLoading: true,
        applicationsError: undefined,
      };
    case 'applications/APPLICATIONS_ERROR':
      return {
        ...state,
        isApplicationsLoading: false,
        applicationsError: action.payload,
      };
    case 'applications/SET_APPLICATIONS':
      return {
        ...state,
        isApplicationsLoading: false,
        applicationsError: undefined,
        applications: action.payload,
      };
    case 'applications/SET_APPLICATIONS_TAGS':
      return {
        ...state,
        tags: action.payload,
      };

    case 'applications/APPLICATION_LOADING':
      return {
        ...state,
        isApplicationLoading: true,
        applicationError: undefined,
      };
    case 'applications/APPLICATION_ERROR':
      return {
        ...state,
        isApplicationLoading: false,
        applicationError: action.payload,
      };
    case 'applications/SET_APPLICATION':
      return {
        ...state,
        isApplicationLoading: false,
        applicationError: undefined,
        application: action.payload,
      };
    case 'applications/SET_HISTORY':
      return {
        ...state,
        history: action.payload,
      };

    case 'applications/SET_REASONS_VALUES':
      return {
        ...state,
        reasonsValues: action.payload,
      };
    case 'applications/SET_OPENINGS_VALUES':
      return {
        ...state,
        openingsValues: action.payload,
      };

    case 'applications/APPLICATIONS_RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
