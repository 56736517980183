import { css } from '@emotion/react';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const filters = css`
  width: 100%;
  @media ${breakpoints.large} {
    max-width: 304px;
  }
`;

export const workingHours = css`
  margin-bottom: calc(var(--unit) * 5);

  span {
    display: none;
  }
`;

export const contractType = css`
  margin: calc(var(--unit) * 5) 0 calc(var(--unit) * 8);
`;
