import { RefObject, useCallback, useEffect } from 'react';

export const useOutOfBounds = (
  ref: RefObject<HTMLElement | undefined>,
  isOpen: boolean,
) => {
  const horizontalFn = useCallback(() => {
    const element = ref.current;

    if (isOpen && element) {
      const left = element.getBoundingClientRect().left;
      const right = element.getBoundingClientRect().right;
      const documentWidth = document.documentElement.clientWidth;

      if (left < 0) {
        element.style.right = `${left - 12}px`;

        return;
      }

      if (right > documentWidth) {
        element.style.left = `${-(right - documentWidth + 12)}px`;

        return;
      }
    }
  }, [isOpen, ref]);

  const verticalFn = useCallback(() => {
    const element = ref.current;

    if (isOpen && element) {
      if (element.getBoundingClientRect().top < 0) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });

        return;
      }

      if (
        element.getBoundingClientRect().bottom >
        document.documentElement.clientHeight
      ) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
    }
  }, [ref, isOpen]);

  useEffect(horizontalFn, [ref, isOpen]);
  useEffect(verticalFn, [ref, isOpen]);
};
