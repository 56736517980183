import React from 'react';
import * as styles from '../styles/InvoicesTable.styles';
import { format } from 'date-fns';
import { getInvoiceLink } from 'modules/billing/helpers';
import MoreOptionsIcon from 'assets/icons/moreOptions.svg';
import DeactivateIcon from 'assets/icons/closeOutline.svg';
import { FlatLink } from 'modules/admin/components';
import { DropdownMenu } from 'modules/app';

interface Props {
  invoice: InvoiceResponse;
  handleOpenCancelModal: (invoice: InvoiceResponse) => void;
}

export const InvoicesTableItems: React.FC<Props> = ({
  invoice,
  handleOpenCancelModal,
}) => {
  const invoicesActions = [
    {
      id: 'cancel',
      disabled:
        Boolean(invoice.paymentType) &&
        invoice.paymentType.toLowerCase() !== 'cancellation',

      element: <FlatLink text="Cancel" icon={<DeactivateIcon />} />,
    },
  ];

  function handleDropdownSelect(id: string) {
    if (id !== 'cancel') return;
    handleOpenCancelModal(invoice);
  }

  return (
    <tr css={styles.root}>
      <td>{invoice.invoiceNumber}</td>
      <td>{invoice.company.name}</td>
      <td>{format(new Date(invoice.createdAt), 'PP, HH:mm')}</td>
      <td>{invoice.planName}</td>
      <td>{invoice.finalPrice}</td>
      <td>{invoice.currency}</td>
      <td>
        <a
          href={getInvoiceLink(invoice)}
          target="_blank"
          rel="noreferrer"
          css={styles.preview}
        >
          Preview
        </a>
      </td>
      <td>
        <span css={styles.invoicesActions}>
          <DropdownMenu
            onSelect={handleDropdownSelect}
            customButtonStyles={styles.invoicesActionsDropdown}
            hideCaret={true}
            placeholder={<MoreOptionsIcon />}
            items={invoicesActions}
            position="bottomRight"
          />
        </span>
      </td>
    </tr>
  );
};
