import { createAction } from 'modules/redux-store';

export const companyDataAction = {
  companyData: (payload?: Company) =>
    createAction('company/COMPANY_DATA', payload),
  updateCompanyData: (payload: {
    companyData?: Partial<Company>;
    isLoading: boolean;
    error?: ApiErrorData;
  }) => createAction('company/UPDATE_COMPANY_DATA', payload),
  reset: () => createAction('company/COMPANY_DATA_RESET'),

  loading: () => createAction('company/LOADING'),
  error: (payload: ApiErrorData) => createAction('company/ERROR', payload),

  publicCompany: (payload: PublicCompany | undefined) =>
    createAction('company/PUBLIC_COMPANY', payload),

  publicOpenings: (payload: {
    openings: JobOpeningListItem[] | undefined;
    page: number;
    lastPage: number;
  }) => createAction('company/PUBLIC_OPENINGS', payload),

  setLatestPlan: (payload?: LatestPlan) =>
    createAction('company/LATEST_PLAN', payload),
};

export const userAction = {
  userBasics: (payload?: BasicsRequest) =>
    createAction('user/USER_BASICS', payload),

  loading: () => createAction('user/LOADING'),
  error: (payload: ApiErrorData) => createAction('user/ERROR', payload),

  reset: () => createAction('user/USER_RESET'),
};
