import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Heading, TextEditor } from 'modules/app';
import React from 'react';
import * as styles from 'modules/profile/styles/CultureCharacteristics.styles';

const getDefaultValues = (
  code: string,
  cultureCharacteristics: CultureCharacteristic[],
): SlateValue | undefined => {
  const answer = cultureCharacteristics.find((characteristic) => {
    return characteristic.code === code && characteristic.answer;
  });

  return answer ? answer.answer : undefined;
};

interface Props {
  cultureCharacteristics: CultureCharacteristic[];
}

export const CultureCharacteristicsInputs: React.FC<Props> = ({
  cultureCharacteristics,
}) => {
  return (
    <>
      <div css={margin.bottom.xlrg}>
        <Heading as="h1" size="fluidLarge">
          <FormattedMessage id="cultureCharacteristics.dominantHeading" />
        </Heading>
      </div>
      <div css={[styles.textEditorWrapper, margin.bottom.huge]}>
        <div css={styles.textEditor}>
          <TextEditor
            label="cultureOverview.label"
            name="dominant_chars_env"
            defaultValue={getDefaultValues(
              'dominant_chars_env',
              cultureCharacteristics,
            )}
            constraints={{
              required: false,
              maxLength: 1000,
            }}
            maxChar={1000}
            placeholder="cultureCharacteristics.dominantPlaceholder"
          />
        </div>
        <div css={styles.content}>
          <p>
            <FormattedMessage id="cultureCharacteristics.dominantSubheading" />
          </p>
          <p>
            <FormattedMessage id="cultureCharacteristics.dominantContent" />
          </p>
        </div>
      </div>

      <div css={margin.bottom.xlrg}>
        <Heading as="h1" size="fluidLarge">
          <FormattedMessage id="cultureCharacteristics.changesHeading" />
        </Heading>
      </div>
      <div css={[styles.textEditorWrapper, margin.bottom.huge]}>
        <div css={styles.textEditor}>
          <TextEditor
            label="cultureOverview.label"
            name="company_changes"
            defaultValue={getDefaultValues(
              'company_changes',
              cultureCharacteristics,
            )}
            constraints={{
              required: false,
              maxLength: 1000,
            }}
            maxChar={1000}
            placeholder="cultureCharacteristics.changesPlaceholder"
          />
        </div>
        <div css={styles.content}>
          <p>
            <FormattedMessage id="cultureCharacteristics.dominantSubheading" />
          </p>
          <p>
            <FormattedMessage id="cultureCharacteristics.changesContent" />
          </p>
        </div>
      </div>

      <div css={margin.bottom.xlrg}>
        <Heading as="h1" size="fluidLarge">
          <FormattedMessage id="cultureCharacteristics.behaviorsHeading" />
        </Heading>
      </div>
      <div css={[styles.textEditorWrapper, margin.bottom.huge]}>
        <div css={styles.textEditor}>
          <TextEditor
            label="cultureOverview.label"
            name="behaviors_norms"
            defaultValue={getDefaultValues(
              'behaviors_norms',
              cultureCharacteristics,
            )}
            constraints={{
              required: false,
              maxLength: 1000,
            }}
            maxChar={1000}
            placeholder="cultureCharacteristics.behaviorsPlaceholder"
          />
        </div>
        <div css={styles.content}>
          <p>
            <FormattedMessage id="cultureCharacteristics.dominantSubheading" />
          </p>
          <p>
            <FormattedMessage id="cultureCharacteristics.behaviorsContent" />
          </p>
        </div>
      </div>

      <div css={margin.bottom.xlrg}>
        <Heading as="h1" size="fluidLarge">
          <FormattedMessage id="cultureCharacteristics.leadershipHeading" />
        </Heading>
      </div>
      <div css={[styles.textEditorWrapper, margin.bottom.huge]}>
        <div css={styles.textEditor}>
          <TextEditor
            label="cultureOverview.label"
            name="leadership_decision_making"
            defaultValue={getDefaultValues(
              'leadership_decision_making',
              cultureCharacteristics,
            )}
            constraints={{
              required: false,
              maxLength: 1000,
            }}
            maxChar={1000}
            placeholder="cultureCharacteristics.leadershipPlaceholder"
          />
        </div>
        <div css={styles.content}>
          <p>
            <FormattedMessage id="cultureCharacteristics.dominantSubheading" />
          </p>
          <p>
            <FormattedMessage id="cultureCharacteristics.leadershipContent" />
          </p>
        </div>
      </div>

      <div css={margin.bottom.xlrg}>
        <Heading as="h1" size="fluidLarge">
          <FormattedMessage id="cultureCharacteristics.strategicHeading" />
        </Heading>
      </div>
      <div css={[styles.textEditorWrapper, margin.bottom.huge]}>
        <div css={styles.textEditor}>
          <TextEditor
            label="cultureOverview.label"
            name="strategic_emphasis"
            defaultValue={getDefaultValues(
              'strategic_emphasis',
              cultureCharacteristics,
            )}
            constraints={{
              required: false,
              maxLength: 1000,
            }}
            maxChar={1000}
            placeholder="cultureCharacteristics.strategicPlaceholder"
          />
        </div>
        <div css={styles.content}>
          <p>
            <FormattedMessage id="cultureCharacteristics.dominantSubheading" />
          </p>
          <p>
            <FormattedMessage id="cultureCharacteristics.strategicContent" />
          </p>
        </div>
      </div>

      <div css={margin.bottom.xlrg}>
        <Heading as="h1" size="fluidLarge">
          <FormattedMessage id="cultureCharacteristics.criteriaHeading" />
        </Heading>
      </div>
      <div css={[styles.textEditorWrapper, margin.bottom.huge]}>
        <div css={styles.textEditor}>
          <TextEditor
            label="cultureOverview.label"
            name="criteria_success"
            defaultValue={getDefaultValues(
              'criteria_success',
              cultureCharacteristics,
            )}
            constraints={{
              required: false,
              maxLength: 1000,
            }}
            maxChar={1000}
            placeholder="cultureCharacteristics.criteriaPlaceholder"
          />
        </div>
        <div css={styles.content}>
          <p>
            <FormattedMessage id="cultureCharacteristics.dominantSubheading" />
          </p>
          <p>
            <FormattedMessage id="cultureCharacteristics.criteriaContent" />
          </p>
        </div>
      </div>
    </>
  );
};
