import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { font } from '@prototyp/gatsby-plugin-gumball/utils/font';
import * as btn from 'modules/app/styles/Button.styles';

export const content = css`
  margin: ${spacing(32)} 0 ${spacing(40)};
  color: hsl(var(--color-grayscale-6));
`;

export const button = css`
  ${btn.root};
  ${btn.secondary};
  ${btn.medium};
  margin-right: ${spacing(20)};
`;

export const buttonDeactivate = css`
  ${btn.root};
  ${btn.hazard}
  ${btn.small};
  margin-right: ${spacing(20)};
`;

export const contentDeactivate = css`
  color: hsl(var(--color-grayscale-6));
  margin-bottom: ${spacing(40)};
`;

export const info = css`
  margin: ${spacing(32)} 0 ${spacing(16)};
  ${font.size.small};
  padding: ${spacing(8)} ${spacing(16)};
  background: hsl(var(--color-warning-9));
  border-radius: ${spacing(3)};
`;
