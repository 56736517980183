import { css } from '@emotion/react';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const textEditorWrapper = css`
  display: grid;
  grid-gap: calc(var(--unit) * 8);

  @media ${breakpoints.xxLarge} {
    grid-template-columns: 640px auto;
  }
`;

export const textEditor = css`
  width: 100%;
  max-width: calc(var(--unit) * 160);
  min-height: calc(var(--unit) * 65);
`;

export const content = css`
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
  color: hsl(var(--color-grayscale-6));

  p {
    margin: 0;
    margin-bottom: calc(var(--unit) * 2);
  }
`;
