import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import { Heading, Paragraph } from 'modules/app';
import { ColorInput } from 'modules/app/components/shared/ImageGridLayout';
import React from 'react';
import { useOnScrollAnimation } from 'modules/app/hooks/useOnScrollAnimation';

import * as styles from '../../styles/DoubleMediaLayout.styles';
import { GridConfig } from '../../styles/DoubleMediaLayout.styles';
import { FADE_IN_MOVE_Y_CONTROLLED, TRANSITION } from 'style/animations.config';
import { motion } from 'framer-motion';

interface Props {
  heading: string;
  description: string;
  layoutType: GridConfig;
  bgColor?: ColorInput;
  headingColor?: ColorInput;
  descColor?: ColorInput;
  isCta?: boolean;
}

export const DoubleMediaLayout: React.FC<Props> = ({
  children,
  heading,
  description,
  layoutType,
  bgColor,
  headingColor,
  descColor,
  isCta,
}) => {
  const title = useOnScrollAnimation();
  const content = useOnScrollAnimation();
  const button = useOnScrollAnimation();

  return (
    <article css={styles.doubleMediaLayout(bgColor)}>
      <div
        css={[styles.wrapper, styles.gridConfig[layoutType]]}
        role="presentation"
      >
        <section css={isCta ? styles.infoSectionCta : styles.infoSection}>
          <Heading
            variants={FADE_IN_MOVE_Y_CONTROLLED}
            transition={TRANSITION(0.4)}
            initial="hidden"
            animate={title.animation}
            useMotion
            as="h2"
            size="fluidXxxLarge"
            css={styles.heading(headingColor)}
          >
            <span ref={title.ref}>
              <FormattedMessage id={heading} />
            </span>
          </Heading>

          <Paragraph
            variants={FADE_IN_MOVE_Y_CONTROLLED}
            transition={TRANSITION(0.6)}
            initial="hidden"
            animate={content.animation}
            size="medium"
            fontFamily="primary"
            useMotion
            css={styles.description(descColor)}
          >
            <span ref={content.ref}>
              <FormattedMessage id={description} />
            </span>
          </Paragraph>

          {isCta && (
            <motion.div
              ref={button.ref}
              variants={FADE_IN_MOVE_Y_CONTROLLED}
              transition={TRANSITION(0.8)}
              initial="hidden"
              animate={button.animation}
              css={styles.cta}
            >
              <Link to="/register" css={styles.ctaBtn}>
                <span>
                  <FormattedMessage id="buttons.signUp" />
                </span>
              </Link>

              <p css={styles.ctaText}>
                <FormattedMessage id="homepage.header.free_for_talents" />
              </p>
            </motion.div>
          )}
        </section>

        <figure css={styles.media}>{children}</figure>
      </div>
    </article>
  );
};
