import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux-store';
import { UserSessionItem } from 'modules/admin/components';
import { useAdminSessions } from 'modules/admin/hooks';
import { Button, Loading, Pill, SmallLoadingImg } from 'modules/app';
import { DropdownMenu } from 'modules/app/components';
import { generateDropdownOptions } from 'modules/admin/static';
import { motion } from 'framer-motion';
import { navigate } from '@reach/router';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { flex, margin } from '@prototyp/gatsby-plugin-gumball/utils';
import * as styles from '../styles/AdminOverview.styles';
import * as detailStyles from 'modules/admin/styles/Details.styles';

const Pagination = React.lazy(
  () => import('modules/app/components/pagination/Pagination'),
);

interface Props {
  userId: string;
}

export const UserSession: React.FC<Props> = ({ userId }) => {
  const urlParams = new URLSearchParams(location.search);
  const page = urlParams.get('page') || '1';
  const sort = urlParams.get('sort') || 'DESC';
  const sortBy = urlParams.get('sortBy') || 'created_at';
  const authType = urlParams.get('authType');

  const {
    isKillSessionsButtonShown,
    isLoading: killAllSessionsLoading,
    getAdminSessions,
    getAllSessionPerUser,
    killAllSessions,
  } = useAdminSessions();

  const [query, setQuery] = useState<UserQuery>({
    page: +page,
    sort,
    sortBy,
    authType,
  });

  const sortOption = ['DESC', 'ASC'];
  const sortByOption = ['created_at', 'expires_at'];
  const authTypeOption = [
    'all',
    'credentials',
    'google',
    'linkedin',
    'github',
    'impersonate',
  ];

  const {
    data: { sessions },
    isLoading,
    error,
  } = useSelector((state: AppState) => state.adminUsers);

  const getSessions = () => {
    getAdminSessions({
      userId: userId,
      page: query.page,
      perPage: 10,
      sort: query.sort,
      sortBy: query.sortBy,
      authType: query.authType,
    });
  };

  const onPageChange = (pag: number) => {
    setQuery({
      ...query,
      page: pag,
    });
    navigate(
      `${userId}?tab=sessions&page=${query.page}&sort=${query.sort}&sortBy=${query.sortBy}`,
    );
  };

  const onChangeSort = (option: string) => {
    setQuery({
      ...query,
      sort: option as Sort,
    });
  };

  const onChangeSortBy = (option: string) => {
    setQuery({
      ...query,
      sortBy: option as SessionsSortBy,
    });
  };

  const onChangeAuthType = (option: string) => {
    setQuery({
      ...query,
      authType: option !== 'all' ? option : null,
    });
  };

  const onKillSessions = (userId: string) => {
    killAllSessions(userId);
  };

  useEffect(() => {
    getSessions();
    getAllSessionPerUser({ userId });
  }, [
    query.page,
    query.sort,
    query.sortBy,
    query.authType,
    killAllSessionsLoading,
  ]);

  if (error) {
    return (
      <Pill type="error" title={error.status.toString()} text={error.type} />
    );
  }

  if (!sessions) {
    return (
      <motion.div transition={{ duration: 0.3 }} {...FADE_IN_MOVE_Y_REGULAR}>
        <div>No sessions found..</div>
      </motion.div>
    );
  }

  return (
    <Loading
      isLoading={isLoading}
      component={
        <motion.div transition={{ duration: 0.3 }} {...FADE_IN_MOVE_Y_REGULAR}>
          <div
            css={[flex.display.flex, flex.justify.between, flex.align.center]}
          >
            <div css={[flex.display.flex]}>
              <DropdownMenu
                customButtonStyles={[styles.sortByDropdown, margin.right.lrg]}
                onSelect={onChangeSortBy}
                items={generateDropdownOptions(sortByOption, query.sortBy)}
                placeholder={
                  <div>
                    Sort by <strong>{query.sortBy}</strong>
                  </div>
                }
              />

              <DropdownMenu
                customButtonStyles={[styles.sortByDropdown, margin.right.lrg]}
                onSelect={onChangeSort}
                items={generateDropdownOptions(sortOption, query.sort)}
                placeholder={
                  <div>
                    Sort by <strong>{query.sort}</strong>
                  </div>
                }
              />

              <DropdownMenu
                customButtonStyles={[styles.sortByDropdown, margin.right.huge]}
                onSelect={onChangeAuthType}
                items={generateDropdownOptions(authTypeOption, query.authType)}
                placeholder={
                  <div>
                    Auth type: <strong>{query.authType || 'all'}</strong>
                  </div>
                }
              />
            </div>

            {isKillSessionsButtonShown && (
              <Button
                onClick={() => onKillSessions(userId)}
                buttonType="outline"
                icon={killAllSessionsLoading ? <SmallLoadingImg /> : null}
                size="small"
              >
                Kill all sessions
              </Button>
            )}
          </div>

          {sessions.data.length === 0 && (
            <div css={margin.top.lrg}>No sessions found.</div>
          )}

          <table css={detailStyles.table}>
            <tbody>
              {sessions.data?.map((session, key) => (
                <UserSessionItem key={key} {...session} />
              ))}
            </tbody>
          </table>
          {!error && sessions.lastPage !== 1 && (
            <div css={[styles.pagination, isLoading ? styles.loading : null]}>
              <React.Suspense fallback={null}>
                <Pagination
                  forcePage={query.page - 1}
                  initialPage={query.page - 1}
                  pageCount={sessions.lastPage || 1}
                  onPageChange={(pag) => onPageChange(pag.selected + 1)}
                />
              </React.Suspense>
            </div>
          )}
        </motion.div>
      }
    />
  );
};
