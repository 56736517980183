import { FormattedMessage } from 'gatsby-plugin-intl';
import { Heading, Paragraph, useOnScrollAnimation } from 'modules/app';
import React from 'react';
import { ImageGrid } from './ImageGrid';
import {
  GumColors,
  GumColorVariation,
} from '@prototyp/gatsby-plugin-gumball/models';

import * as imageGridLayout from '../../styles/ImageGridLayout.styles';
import { FADE_IN_MOVE_Y_CONTROLLED, TRANSITION } from 'style/animations.config';

export type ColorInput = [GumColors, GumColorVariation];

interface Props {
  heading: string;
  description: string;
  bgColor?: ColorInput;
  fontColor?: ColorInput;
  type?: 'talent' | 'company';
}

export const ImageGridLayout: React.FC<Props> = ({
  heading,
  description,
  bgColor,
  fontColor,
  type = 'talent',
}) => {
  const title = useOnScrollAnimation();
  const content = useOnScrollAnimation();

  return (
    <article css={imageGridLayout.imageGridLayout(bgColor)}>
      <div
        css={[imageGridLayout.wrapper, imageGridLayout.grid]}
        role="presentation"
      >
        <section css={imageGridLayout.infoSection(fontColor)}>
          <Heading
            as="h2"
            size="fluidXxxLarge"
            css={imageGridLayout.heading}
            variants={FADE_IN_MOVE_Y_CONTROLLED}
            transition={TRANSITION(0.4)}
            initial="hidden"
            animate={title.animation}
            useMotion
          >
            <span ref={title.ref}>
              <FormattedMessage id={heading} />
            </span>
          </Heading>

          <Paragraph
            variants={FADE_IN_MOVE_Y_CONTROLLED}
            transition={TRANSITION(0.9)}
            initial="hidden"
            animate={content.animation}
            useMotion
            size="medium"
            fontFamily="primary"
            css={imageGridLayout.description}
          >
            <span ref={content.ref}>
              <FormattedMessage id={description} />
            </span>
          </Paragraph>
        </section>

        <ImageGrid type={type} />
      </div>
    </article>
  );
};
