import CheckedIcon from 'assets/icons/check.svg';
import React, { InputHTMLAttributes, useState } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import * as styles from 'modules/app/styles/CheckboxField.styles';
import { Label } from 'modules/app';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { FormattedMessage } from 'gatsby-plugin-intl';

interface Props extends InputHTMLAttributes<Omit<HTMLInputElement, 'type'>> {
  groupLabel: string;
  groupName: string;
  selectedValues: string[];
  data: { value: string; label: string }[];
  constraints?: RegisterOptions;
  reset?: number;
  resetValue?: string;
}

export const CheckboxGroup: React.FC<Props> = ({
  groupLabel,
  groupName,
  selectedValues,
  constraints,
  data,
  resetValue,
  ...rest
}) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [selected, setSelected] = useState<string[]>(selectedValues);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filterValues = selected.filter((v) => v !== event.target.value);
    const toggleValue = selected.includes(event.target.value)
      ? filterValues
      : [...selected, event.target.value];

    const newArray =
      constraints?.maxLength && selected.length >= constraints?.maxLength
        ? filterValues
        : toggleValue;

    setSelected(newArray);
    setValue(groupName, newArray, { shouldDirty: true });

    if (resetValue) {
      if (selected.includes(event.target.value)) {
        return;
      } else if (
        event.target.value === resetValue ||
        selected.includes(resetValue)
      ) {
        setSelected([resetValue]);
        setValue(groupName, [resetValue]);

        return;
      }
    }
  };

  return (
    <>
      <Label
        label={<FormattedMessage id={groupLabel} />}
        altText={
          constraints?.maxLength &&
          `Select up to ${constraints.maxLength} (minimum 1)`
        }
        customStyles={margin.bottom.med}
        labelStyle
        isOptional={!Boolean(constraints?.required)}
        error={errors[groupName]}
      />
      {data.map((item, index) => {
        const isChecked = selected.includes(item.value);

        return (
          <div key={index} css={[styles.root, margin.bottom.med]}>
            <Controller
              name={groupName}
              control={control}
              rules={{
                ...constraints,
                validate: () => {
                  if (!constraints?.required) {
                    return;
                  }

                  return Boolean(selected.length);
                },
              }}
              defaultValue={isChecked}
              render={() => {
                return (
                  <input
                    {...rest}
                    css={styles.input}
                    checked={isChecked}
                    id={`checkbox-${item.value}`}
                    type="checkbox"
                    name={item.value}
                    value={item.value}
                    onChange={onChange}
                  />
                );
              }}
            />

            <label css={styles.label} htmlFor={`checkbox-${item.value}`}>
              <span css={isChecked ? styles.controlChecked : styles.control}>
                {isChecked && <CheckedIcon />}
              </span>

              <span css={styles.content}>{item.label}</span>
            </label>
          </div>
        );
      })}
    </>
  );
};
