import { css } from '@emotion/react';

export const root = css`
  & > div {
    display: flex;
    justify-content: space-between;

    border-bottom: 1px solid hsl(var(--color-borderDark-5));
    color: hsl(var(--color-borderDark-5));
    padding: calc(var(--unit) * 5) 0 calc(var(--unit) * 5) 0;

    p {
      font-family: var(--font-family-primary);
    }
  }
`;
