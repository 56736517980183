import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import * as styles from '../styles/Root.styles';

interface Props {
  label: string;
  name: string;
  optional: boolean;
  maxChar?: number;
}

export const TextEditorLabel: React.FC<Props> = ({
  label,
  name,
  optional,
  maxChar,
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <div css={styles.labelWrapper}>
      <label
        css={errors[name] ? styles.labelError : styles.label}
        htmlFor={name}
      >
        <FormattedMessage id={label} />
      </label>

      <div css={styles.optionalSection}>
        {optional && (
          <span css={styles.maxChar}>
            <FormattedMessage id="shared.page.optional" />
          </span>
        )}

        {maxChar && (
          <span css={styles.maxChar}>
            {maxChar}{' '}
            <FormattedMessage id="onboarding.form.fields.information.characters_max" />
          </span>
        )}
      </div>
    </div>
  );
};
