import { useDispatch, useSelector } from 'react-redux';
import {
  AppState,
  authenticationAction,
  isUser,
  isUserCompanyRoles,
} from 'modules/redux-store';
import { useCsrfCookie } from 'modules/app';
import { navigate } from 'gatsby-plugin-intl';
import { addYears } from 'date-fns';

export interface UseAuthDispatch {
  dispatchLogin: (
    user: User,
    session: Session,
    userStaffRole?: UserStaffRole,
    userCompanyRoles?: UserCompanyRoles[],
    companyInviteToken?: string,
    companyClaimToken?: string,
  ) => void;
  dispatchUpdatedUser: (
    user: unknown,
    companyRoles: unknown,
    userStaffRole?: UserStaffRole,
  ) => void;
}

export const useAuthDispatch = (): UseAuthDispatch => {
  const dispatch = useDispatch();
  const { setCsrf } = useCsrfCookie();
  const is2fa = useSelector((state: AppState) => state.session.user?.is2fa);

  return {
    dispatchLogin: (
      user: User,
      session: Session,
      userStaffRole: UserStaffRole | undefined,
      userCompanyRoles: UserCompanyRoles[] | undefined,
      companyInviteToken?: string,
      companyClaimToken?: string,
    ) => {
      const expiresAt = session.expiresAt
        ? new Date(session.expiresAt + 'Z')
        : new Date(addYears(new Date(), 20));
      setCsrf(session.csrf, new Date(expiresAt));

      if (is2fa) {
        user.is2fa = true;
      }

      user.authType = session.authType;

      dispatch(
        authenticationAction.setAuthSession({
          user: user,
          userCompanyRoles: userCompanyRoles ? userCompanyRoles : [],
          tokenExpiresAt: new Date(expiresAt),
          userStaffRole: userStaffRole,
        }),
      );

      if (companyInviteToken) {
        navigate(`/app/company/users/join/${companyInviteToken}`);
      }

      if (companyClaimToken) {
        navigate(`/app/transfer/${companyClaimToken}`);
      }
    },
    dispatchUpdatedUser(
      user: unknown,
      companyRoles: unknown,
      userStaffRole?: UserStaffRole,
    ) {
      if (isUser(user) && isUserCompanyRoles(companyRoles)) {
        dispatch(
          authenticationAction.setAuthSession({
            user: { ...user },
            userCompanyRoles: [...companyRoles],
            userStaffRole,
          }),
        );
      }
    },
  };
};
