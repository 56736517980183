import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball';

export const staff = css`
  max-width: 1312px;
  height: calc(100vh - 100px);
  justify-content: start;
`;

export const grid = css`
  position: relative;
  display: grid;
  grid-template-columns: 256px auto;
`;

export const borderBottom = css`
  border-bottom: 1px solid hsl(var(--color-borderUltraLight-9));
  padding: ${spacing(24)} 0;
`;

export const content = css`
  position: relative;
  top: 100px;
  padding: ${spacing(30)} ${spacing(60)};
`;

export const details = css`
  max-width: 800px;
  margin: 0 auto;
`;

export const talents = css`
  max-height: 500px;
  overflow-y: auto;
`;

export const role = css`
  align-self: center;
`;

export const companyListHeader = css`
  padding-left: 0;
  & > div:last-child {
    flex: 0.6;
  }
`;
