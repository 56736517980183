import React from 'react';

import * as styles from 'modules/app/styles/ImageGrid.styles';
import { ImageOverlay } from 'modules/app';
import { StaticImage } from 'gatsby-plugin-image';

export interface ImagePosition {
  columnStart: number | string;
  columnEnd: number | string;
  rowStart: number | string;
  rowEnd: number | string;
}

interface ImageGridProps {
  type: 'talent' | 'company';
}

export const ImageGrid: React.FC<ImageGridProps> = ({ type = 'talent' }) => {
  return (
    <div css={styles.wrapper}>
      {type === 'talent' && (
        <>
          <div css={styles.talentImageOne}>
            <StaticImage
              formats={['auto', 'webp', 'avif']}
              loading="lazy"
              placeholder="blurred"
              src="../../../../assets/images/talents/talent1.jpg"
              alt=""
              quality={100}
            />
            <ImageOverlay type="top" background="hsl(var(--color-white-11))" />
          </div>
          <div css={styles.talentImageTwo}>
            <StaticImage
              formats={['auto', 'webp', 'avif']}
              loading="lazy"
              placeholder="blurred"
              src="../../../../assets/images/talents/talent2.jpg"
              alt=""
              quality={100}
            />
            <ImageOverlay
              type="right"
              background="hsl(var(--color-white-11))"
            />
          </div>
        </>
      )}

      {type === 'company' && (
        <>
          <div css={styles.companyImageOne}>
            <StaticImage
              formats={['auto', 'webp', 'avif']}
              loading="lazy"
              placeholder="blurred"
              src="../../../../assets/images/companies/company1.jpg"
              alt=""
              quality={50}
            />
            <ImageOverlay type="left" background="hsl(var(--color-white-11))" />
          </div>
          <div css={styles.companyImageTwo}>
            <StaticImage
              formats={['auto', 'webp', 'avif']}
              loading="lazy"
              placeholder="blurred"
              src="../../../../assets/images/companies/company2.jpg"
              alt=""
              quality={50}
            />
            <ImageOverlay type="top" background="hsl(var(--color-white-11))" />
          </div>
        </>
      )}
    </div>
  );
};
