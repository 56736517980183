import { useAuthDispatch, useToastify } from 'modules/app';
import { useState } from 'react';
import { normalizeBasicsRequest, normalizeInitialValues } from '../utils';
import { HttpError } from 'modules/app/http/client/httpError';
import { useDispatch, useSelector } from 'react-redux';
import { UseFormReturn } from 'react-hook-form';
import { AppState } from 'modules/redux-store';
import { navigate, useIntl } from 'gatsby-plugin-intl';
import { talentOnboardingAction } from 'modules/onboarding';
import { userAction } from 'modules/profile';
import { useHttpClient } from 'modules/app/http/hooks/useHttpClient';

interface AddBasicsProps {
  data: BasicsFormData;
  handleSetInitialData?: (data: BasicsFormData) => void;
  selectedPlace?: SelectedPlace;
  isOnboarding?: boolean;
}

export function useTalentBasics(form?: UseFormReturn<BasicsFormData, unknown>) {
  const talentProfileService = useHttpClient<Basics>('/api/v1/talent/profile');

  const { setError } = form || {};
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { toastSuccess } = useToastify();
  const { dispatchUpdatedUser } = useAuthDispatch();
  const session = useSelector((state: AppState) => state.session);

  const [httpError, setHttpError] = useState<HttpError>();
  const [basicsData, setBasicsData] = useState<Basics>();

  async function addBasics({
    data,
    selectedPlace,
    handleSetInitialData,
    isOnboarding,
  }: AddBasicsProps) {
    try {
      const model: BasicsRequest = normalizeBasicsRequest(data);

      if (selectedPlace) {
        model.cityCountry = selectedPlace.cityCountry;
        model.location = selectedPlace.location;
      }

      await talentProfileService.put('/basics', model);

      handleSetInitialData?.(normalizeInitialValues(model));

      const updatedUser = {
        ...session.user,
        firstName: data.firstName,
        lastName: data.lastName,
      };

      dispatchUpdatedUser(
        updatedUser,
        session.userCompanyRoles,
        session.userStaffRole,
      );
      dispatch(userAction.userBasics(model));

      if (!isOnboarding) {
        toastSuccess('shared.pills.successDefaultText');
        return;
      }

      dispatch(
        talentOnboardingAction.updateTalentOnboardingAccess({
          expertise: true,
        }),
      );
      navigate('/app/onboarding/talent/expertise');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.errors?.phone) {
        setError?.('phone', {
          type: 'invalidPhone',
          message: formatMessage({
            id: 'onboarding.form.errors.phone',
          }),
        });

        return;
      }

      setHttpError(e);
    }
  }

  async function getBasics() {
    try {
      const { data } = await talentProfileService.get('/basics');

      setBasicsData(data);
      dispatch(userAction.userBasics(basicsData));
    } catch (e) {
      if (!(e instanceof HttpError)) return;

      dispatch(userAction.error(e));
    }
  }

  return {
    session,
    httpError,
    basicsData,
    addBasics,
    getBasics,
  };
}
