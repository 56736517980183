import React from 'react';

import * as styles from 'modules/profilePreview/styles/Section.styles';
import { MainContent } from 'modules/app';

interface Props {
  theme: 'white' | 'black' | 'gray';
  customStyles?: SerializedStyles | SerializedStyles[];
}

export const Section: React.FC<Props> = ({ theme, customStyles, children }) => {
  return (
    <section css={[styles.root, styles.theme(theme), customStyles]}>
      <MainContent>{children}</MainContent>
    </section>
  );
};
