import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { companyDataAction } from 'modules/profile';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  initialGraphData: CultureGraphData;
}

type CultureType = 'talent' | 'company';

export function useCulture({ initialGraphData }: Props) {
  const dispatch = useDispatch();

  const [httpError, setHttpError] = useState<HttpError>();
  const [isDisabled, setIsDisabled] = useState(false);
  const [areAnswersLoading, setAreAnswersLoading] = useState(true);
  const [isCultureSubmitted, setIsCultureSubmitted] = useState(false);
  const [areAnswersSubmitted, setAreAnswersSubmitted] = useState(false);
  const [chartData, setChartData] = useState([
    initialGraphData.cultureClan || 0,
    initialGraphData.cultureAdhocracy || 0,
    initialGraphData.cultureHierarchy || 0,
    initialGraphData.cultureMarket || 0,
  ]);

  const talentService = useHttpClient<Culture>('/api/v1/talent/profile');
  const companyService = useHttpClient<Culture>('/api/v1/company');

  function handleDataCheck(graphData: CultureGraphData) {
    let hasData = false;
    for (const key of Object.keys(graphData)) {
      if (graphData && graphData[key] > 0) {
        hasData = true;
        break;
      }
    }
    return hasData;
  }

  function normalizeAnswers(answers: number[][]) {
    const submittableAnswers = [];

    for (const answ of answers) {
      submittableAnswers.push({
        cultureClan: answ[0] / 100,
        cultureAdhocracy: answ[1] / 100,
        cultureHierarchy: answ[2] / 100,
        cultureMarket: answ[3] / 100,
      });
    }
    return submittableAnswers;
  }

  function handleCompanyCultureAnswers() {
    const hasData = handleDataCheck(initialGraphData);
    setAreAnswersLoading(false);

    if (hasData) {
      setAreAnswersSubmitted(true);
      setIsCultureSubmitted(true);
    }

    if (!hasData) {
      setAreAnswersSubmitted(false);
    }

    setIsDisabled(false);
  }

  async function getCultureAnswers(type: CultureType) {
    setIsDisabled(true);

    if (type === 'company') {
      handleCompanyCultureAnswers();
      return;
    }

    try {
      const { data } = await talentService.get<Culture>('/culture');

      if (!data) return;
      data.cultureAdhocracy = data.cultureAdhocracy ?? 0;
      data.cultureClan = data.cultureClan ?? 0;
      data.cultureHierarchy = data.cultureHierarchy ?? 0;
      data.cultureMarket = data.cultureMarket ?? 0;

      const hasData = handleDataCheck(data);

      if (data && hasData) {
        setChartData([
          data.cultureClan,
          data.cultureAdhocracy,
          data.cultureHierarchy,
          data.cultureMarket,
        ]);

        setIsCultureSubmitted(true);
        setAreAnswersSubmitted(true);
      }
      setHttpError(undefined);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
    setAreAnswersLoading(false);
    setIsDisabled(false);
  }

  async function updateQuestions(
    type: CultureType,
    answers: number[][],
    companyId?: string,
  ) {
    setIsDisabled(true);
    let response;
    const submittableAnswers = normalizeAnswers(answers);

    try {
      type === 'talent'
        ? (response = await talentService.put('/culture', {
            answers: submittableAnswers,
          }))
        : (response = await companyService.put(
            `/${companyId}/profile/culture`,
            {
              answers: submittableAnswers,
            },
          ));
      setIsCultureSubmitted(true);
      setAreAnswersSubmitted(true);

      const responseChartData = [
        response.data.cultureClan ?? 0,
        response.data.cultureAdhocracy ?? 0,
        response.data.cultureHierarchy ?? 0,
        response.data.cultureMarket ?? 0,
      ];

      setChartData(responseChartData);
      setHttpError(undefined);

      if (type === 'company') {
        dispatch(
          companyDataAction.updateCompanyData({
            error: undefined,
            companyData: {
              cultureClan: responseChartData[0],
              cultureAdhocracy: responseChartData[1],
              cultureHierarchy: responseChartData[2],
              cultureMarket: responseChartData[3],
            },
            isLoading: false,
          }),
        );
      }
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
    setIsDisabled(false);
  }

  return {
    httpError,
    isDisabled,
    areAnswersLoading,
    areAnswersSubmitted,
    isCultureSubmitted,
    chartData,
    getCultureAnswers,
    updateQuestions,
    setIsCultureSubmitted,
    setAreAnswersSubmitted,
    setChartData,
  };
}
