import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';

export function useTalentPreview() {
  const [httpError, setHttpError] = useState<HttpError>();
  const [previewData, setPreviewData] = useState<TalentDataPreview>();

  const talentService = useHttpClient<Basics>('/api/v1/talent/profile');

  async function getApplicationPreview() {
    try {
      const { data } = await talentService.get<TalentDataPreview>(
        '/application-preview',
      );
      setPreviewData(data);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  return {
    httpError,
    previewData,
    getApplicationPreview,
  };
}
