import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { font } from '@prototyp/gatsby-plugin-gumball/utils/font';
import { breakpoints } from 'style/variables';

export const root = css`
  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  background: hsl(var(--color-text-1));
  overflow: hidden;
  padding: ${spacing(20)};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  @media ${breakpoints.medium} {
    display: none;
  }
`;

export const loading = css`
  height: 100%;
`;

export const contentWrapper = css`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
`;

export const title = css`
  color: hsl(var(--color-grayscale-1));
  text-align: center;
  ${font.weight.bold};
`;

export const header = css`
  display: flex;
  align-items: center;
  border-bottom: 1px solid hsl(var(--color-borderBlack-2));
  justify-content: space-between;
  padding-bottom: ${spacing(20)};
`;

export const closeBtn = css`
  border-color: hsl(var(--color-borderDark-5));

  svg {
    width: ${spacing(8)};
    height: ${spacing(8)};

    path {
      fill: hsl(var(--color-grayscale-1));
    }
  }
`;
