import React from 'react';

import * as styles from 'modules/app/styles/ContentWithDeco.styles';
import { SerializedStyles } from '@emotion/react';

interface Props {
  footer?: React.ReactNode;
  css?: SerializedStyles | SerializedStyles[];
  negative?: boolean;
}

export const ContentWithDeco: React.FC<Props> = ({
  children,
  footer,
  negative,
  ...rest
}) => {
  return (
    <section css={negative ? styles.rootNegative : styles.root} {...rest}>
      <article css={styles.wrapper}>{children}</article>

      {footer && <footer css={styles.footer}>{footer}</footer>}
    </section>
  );
};
