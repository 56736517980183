import React, { useEffect, useState } from 'react';
import * as styles from 'modules/app/styles/ToggleSwitchInput.styles';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  checked: boolean;
  customStyles?: SerializedStyles | SerializedStyles[];
  onChecked?: (isChecked: boolean) => void;
}

export const Switch: React.FC<Props> = ({
  children,
  checked,
  customStyles,
  onChecked,
  ...rest
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div
      css={[customStyles]}
      {...rest}
      onClick={() => {
        const checked = !isChecked;
        setIsChecked(checked);
        onChecked?.(checked);
      }}
    >
      <label css={styles.label}>
        <span css={isChecked ? styles.controlChecked : styles.control}>
          <div css={isChecked ? styles.toggleChecked : styles.toggle} />
        </span>

        {children && <span css={styles.content}>{children}</span>}
      </label>
    </div>
  );
};
