import { grid } from '@prototyp/gatsby-plugin-gumball/utils';
import React, { useMemo } from 'react';
import { config } from 'style/grids.config';
import { SerializedStyles } from '@emotion/react';

interface Props {
  type: keyof typeof config;
  customCss?: SerializedStyles;
}

export const Grid: React.FC<Props> = ({
  children,
  type,
  customCss,
  ...rest
}) => {
  const gridConfig = useMemo(() => grid.config[type], [type]);

  return (
    <div css={[gridConfig, customCss]} {...rest}>
      {children}
    </div>
  );
};
