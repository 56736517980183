import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import { Logo } from 'modules/app/components/';
import * as styles from 'modules/admin/styles/Sidebar.styles';
import { useMatch } from '@reach/router';

export const Sidebar: React.FC = () => {
  const match = useMatch('/:lang/admin/dashboard/*');
  const matchAdmin = useMatch('/:lang/admin');
  const matchActivity = useMatch('/:lang/admin/activity/*');
  const matchUsers = useMatch('/:lang/admin/users');
  const matchUserDetails = useMatch('/:lang/admin/users/*');
  const matchCompanies = useMatch('/:lang/admin/companies/*');
  const matchStaff = useMatch('/:lang/admin/staff/*');
  const matchPlans = useMatch('/:lang/admin/plans/*');
  const matchInvoices = useMatch('/:lang/admin/invoices/*');
  const matchApplicants = useMatch('/:lang/admin/applicants/*');
  const matchTalents = useMatch('/:lang/admin/talents/*');
  const matchBillingActivity = useMatch('/:lang/admin/billing-activity/*');

  return (
    <div css={styles.sidebar}>
      <div css={styles.sidebar__logo}>
        <Logo colorType="black" linkTo="/admin" />
      </div>

      <div css={styles.sidebar__navigation}>
        <Link to="/admin" className={match || matchAdmin ? 'active' : ''}>
          Dashboard
        </Link>
        <Link
          to="/admin/activity/talents"
          className={matchActivity ? 'active' : ''}
        >
          Activity Log
        </Link>
        <div className="label">Manage</div>
        <Link
          to="/admin/users/"
          className={matchUsers || matchUserDetails ? 'active' : ''}
        >
          Users
        </Link>
        <Link to="/admin/companies/" className={matchCompanies ? 'active' : ''}>
          Companies
        </Link>
        <Link to="/admin/staff/" className={matchStaff ? 'active' : ''}>
          Staff
        </Link>

        <div className="label">Billing</div>
        <Link to="/admin/plans/regular" className={matchPlans ? 'active' : ''}>
          Plans
        </Link>
        <Link to="/admin/invoices/" className={matchInvoices ? 'active' : ''}>
          Invoices
        </Link>
        <Link
          to="/admin/billing-activity/"
          className={matchBillingActivity ? 'active' : ''}
        >
          Billing Activity
        </Link>
        <div className="label">Developers Challenge</div>
        <Link
          to="/admin/applicants/"
          className={matchApplicants ? 'active' : ''}
        >
          Applicants
        </Link>
        <div className="label">Talents Insight</div>
        <Link to="/admin/talents/" className={matchTalents ? 'active' : ''}>
          Talent Counter
        </Link>
      </div>
    </div>
  );
};
