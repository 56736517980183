import React, { useEffect, useRef, useState } from 'react';

import * as styles from 'modules/app/styles/Dropdown.styles';
import { AnimatePresence, motion } from 'framer-motion';
import {
  FADE_IN_MOVE_Y_REGULAR,
  FADE_IN_MOVE_Y_REVERSE,
} from 'style/animations.config';
import { useOutsideClick } from 'modules/app/components/hooks/useOutsideClick';

interface Props {
  button: React.ReactNode;
  customStyles?: SerializedStyles;
  position: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  theme?: 'light' | 'dark';
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabled?: boolean;
  arrow?: 'top' | 'bottom';
}

export const Dropdown: React.FC<Props> = ({
  children,
  button,
  customStyles,
  position,
  theme = 'light',
  isOpen,
  setIsOpen,
  isDisabled,
  arrow,
}) => {
  const clickRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [arrowLeft, setArrowLeft] = useState(0);
  const [arrowRight, setArrowRight] = useState(0);

  const ref = useOutsideClick<HTMLDivElement>(() => setIsOpen(false));

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const element = dropdownRef.current;

      if (element.getBoundingClientRect().top < 0) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });

        return;
      }

      if (
        element.getBoundingClientRect().bottom >
        document.documentElement.clientHeight
      ) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
    }
  }, [dropdownRef, isOpen]);

  useEffect(() => {
    if (isOpen && clickRef.current) {
      if (position === 'bottomLeft' || position === 'topLeft') {
        setArrowLeft((clickRef.current.clientWidth - 8) / 2);

        return;
      }

      if (position === 'bottomRight' || position === 'topRight') {
        setArrowRight((clickRef.current.clientWidth - 8) / 2);

        return;
      }
    }
  }, [isOpen, position]);

  return (
    <div ref={ref} css={styles.root} onBlur={() => setIsOpen(false)}>
      <div ref={clickRef} onClick={() => !isDisabled && setIsOpen(!isOpen)}>
        {button}
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            transition={{ duration: 0.2 }}
            {...(position === 'bottomLeft' || position === 'bottomRight'
              ? {
                  ...FADE_IN_MOVE_Y_REGULAR,
                }
              : {
                  ...FADE_IN_MOVE_Y_REVERSE,
                })}
            exit={{ opacity: 0, y: 10, transition: { duration: 0.2 } }}
            css={[styles[position], styles.theme(theme), customStyles]}
            ref={dropdownRef}
          >
            {arrow && (
              <div
                css={[
                  styles.arrow(theme),
                  styles[arrow],
                  arrowRight && { right: arrowRight },
                  arrowLeft && { left: arrowLeft },
                ]}
              />
            )}
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
