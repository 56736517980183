import React from 'react';
import { MainContent } from './MainContent';
import * as styles from 'modules/app/styles/HeaderLayout.styles';

interface Props {
  customStyles?: SerializedStyles | SerializedStyles[];
}

export const HeaderLayout: React.FC<Props> = ({ children, customStyles }) => {
  return (
    <div css={[styles.root, customStyles]}>
      <MainContent>{children}</MainContent>
    </div>
  );
};
