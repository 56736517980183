import Seo from 'modules/app/components/Seo';
import React, { useEffect } from 'react';
import useSiteMetadata from '../graphql/useSiteMetadata';
import { useRefreshInterval } from 'modules/auth';
import { ImpersonateEndButton } from 'modules/admin/components';
import { useCookiePolicy } from './hooks';
import { useCookieAccept } from '../hooks';

const Layout: React.FC = ({ children }) => {
  const { getCookie } = useCookiePolicy();
  const { enableGTM } = useCookieAccept();
  const siteMetadata = useSiteMetadata();
  useRefreshInterval();

  useEffect(() => {
    if (getCookie() !== 'accept') return;

    enableGTM();
  }, []);

  return (
    <>
      <Seo title={siteMetadata.title} />
      <main>{children}</main>
      <ImpersonateEndButton />
    </>
  );
};

export default Layout;
