import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import { FormattedMessage } from 'react-intl';

import * as styles from 'modules/app/styles/HighlightedLink.styles';
interface Props {
  to: string;
  label: string;
}

export const HighlightedLink: React.FC<Props> = ({ to, label }) => {
  return (
    <Link css={styles.root} to={to}>
      <FormattedMessage id={label} />
    </Link>
  );
};
