import { ActionUnion } from 'modules/redux-store';
import { faqAction } from './actions';

interface FirstStepData {
  email: string;
  name?: string;
  step: number;
}

const INITIAL_STATE: FirstStepData = {
  email: '',
  name: '',
  step: 0,
};

export const reducer = (
  state: FirstStepData = INITIAL_STATE,
  action: ActionUnion<typeof faqAction>,
): FirstStepData => {
  switch (action.type) {
    case 'faq/SAVE_DATA':
      return {
        ...state,
        email: action.payload.email,
        name: action.payload.name,
      };
    case 'faq/NEXT_STEP':
      return {
        ...state,
        step: action.payload,
      };
    case 'faq/RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
