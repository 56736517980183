import React from 'react';
import Hacker from 'assets/icons/hacker.svg';
import JointAccount from 'assets/icons/joint-account.svg';
import WindowParagraph from 'assets/icons/window-paragraph.svg';

import * as styles from 'modules/shared/styles/WhyRoango.styles';
import { StaticImage } from 'gatsby-plugin-image';
import { ImageOverlay, Paragraph, useOnScrollAnimation } from 'modules/app';
import { motion } from 'framer-motion';
import { Link, FormattedMessage } from 'gatsby-plugin-intl';
import {
  FADE_IN_MOVE_Y_CONTROLLED,
  STAGGER_CONTAINER,
} from 'style/animations.config';

interface Props {
  title: string;
  titleSpecial: string;
  listDescriptions: string[];
  description: string;
}

export const WhyRoangoSection: React.FC<Props> = ({
  title,
  titleSpecial,
  listDescriptions,
  description,
}) => {
  const titleContainer = useOnScrollAnimation({ threshold: 0.1 });
  const textContainer = useOnScrollAnimation({ threshold: 0.5 });

  return (
    <section css={styles.root}>
      <motion.div
        initial="hidden"
        animate={titleContainer.animation}
        transition={{ delayChildren: 0.4, staggerChildren: 0.5 }}
        variants={STAGGER_CONTAINER}
        ref={titleContainer.ref}
        css={styles.content}
      >
        <motion.h3
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          css={styles.subTitle}
        >
          Why Roango?
        </motion.h3>
        <motion.h2
          variants={FADE_IN_MOVE_Y_CONTROLLED}
          transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          css={styles.title}
        >
          {title}
        </motion.h2>
        <ul css={styles.listItems}>
          <motion.li
            variants={FADE_IN_MOVE_Y_CONTROLLED}
            transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          >
            <div css={styles.icon}>
              <Hacker />
            </div>
            <p>{listDescriptions[0]}</p>
          </motion.li>
          <motion.li
            variants={FADE_IN_MOVE_Y_CONTROLLED}
            transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          >
            <div css={styles.icon}>
              <WindowParagraph />
            </div>
            <p>{listDescriptions[1]}</p>
          </motion.li>
          <motion.li
            variants={FADE_IN_MOVE_Y_CONTROLLED}
            transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
          >
            <div css={styles.icon}>
              <JointAccount />
            </div>
            <p>{listDescriptions[2]}</p>
          </motion.li>
        </ul>
        <div css={styles.imageWrapper}>
          <StaticImage
            formats={['auto', 'webp', 'avif']}
            loading="lazy"
            placeholder="blurred"
            css={styles.image}
            src="../../../assets/images/roango-start-inviting-talent.jpg"
            alt=""
            quality={100}
          />
          <ImageOverlay
            threshold={0.3}
            background="hsl(var(--color-grayscale-9))"
          />
        </div>
        <motion.div
          initial="hidden"
          animate={textContainer.animation}
          transition={{ delayChildren: 0.4, staggerChildren: 0.5 }}
          variants={STAGGER_CONTAINER}
          ref={textContainer.ref}
          css={styles.textWrapper}
        >
          <motion.h2
            variants={FADE_IN_MOVE_Y_CONTROLLED}
            transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
            css={styles.titleSpecial}
          >
            {titleSpecial}
          </motion.h2>
          <motion.p
            variants={FADE_IN_MOVE_Y_CONTROLLED}
            transition={{ duration: 0.5, type: 'keyframes', ease: 'easeInOut' }}
            css={styles.description}
          >
            {description}
          </motion.p>
          <motion.div
            variants={FADE_IN_MOVE_Y_CONTROLLED}
            transition={{
              duration: 0.5,
              type: 'keyframes',
              ease: 'easeInOut',
            }}
            css={styles.buttonWrapper}
          >
            <Link to="/register" css={styles.link}>
              <span>
                <FormattedMessage id="buttons.joinRoango" />
              </span>
            </Link>
            <Paragraph
              size="fluidMedium"
              fontFamily="primary"
              css={styles.details}
            >
              <span>
                <FormattedMessage id="homepage.header.free" />{' '}
              </span>
              <FormattedMessage id="homepage.header.for_talents" />
            </Paragraph>
          </motion.div>
        </motion.div>
      </motion.div>
    </section>
  );
};
