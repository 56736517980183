import React, { useEffect, useState } from 'react';
import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import Search from 'assets/icons/search.svg';
import CalendarIcon from 'assets/icons/calendar.svg';
import {
  LoadingImg,
  Pill,
  SmallLoadingImg,
  TextInput,
  DropdownMenu,
  Button,
} from 'modules/app';
import {
  SectionBody,
  SectionHead,
  UsersCopyRequestTable,
} from 'modules/admin/components';
import { useSelector } from 'react-redux';
import { useSearchUsers, useSearchParams } from 'modules/admin/hooks';
import { AppState } from 'modules/redux-store';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import * as styles from 'modules/admin/styles/AdminOverview.styles';
import * as sectionStyles from '../styles/Section.styles';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { startOfToday, startOfYear, format } from 'date-fns';

const Pagination = React.lazy(
  () => import('modules/app/components/pagination/Pagination'),
);

const DatePickerButton = React.lazy(
  () => import('modules/app/components/form/DatePickerButton'),
);

export const CopyUsersDataOverview: React.FC<RouteComponentProps> = () => {
  const { search: queryString } = useLocation();
  const queryParams = new URLSearchParams(queryString);

  const sortByOptions = ['created_at', 'generated_at'];
  const searchByOptions = ['user_name', 'user_email'];
  const sortOptions = ['DESC', 'ASC'];

  const firstDayOfTheYear = format(startOfYear(new Date()), 'yyyy-MM-dd');
  const today = format(startOfToday(), 'yyyy-MM-dd');

  const urlParams = {
    urlSearch: queryParams.get('search') ?? '',
    urlSearchBy: queryParams.get('searchBy') ?? 'user_name',
    urlPage: queryParams.get('page') ?? '1',
    urlSort: queryParams.get('sort') ?? 'DESC',
    urlSortBy: queryParams.get('sortBy') ?? 'created_at',
    urlStartDate: queryParams.get('startDate') ?? firstDayOfTheYear,
    urlEndDate: queryParams.get('endDate') ?? today,
  };

  const {
    data: users,
    isLoading,
    error,
    total,
    lastPage,
  } = useSelector((state: AppState) => state.adminDataCopyRequestUsers);

  const numberOfUsers = isLoading ? 0 : total;
  const title = `Copy of Profile Data (${numberOfUsers})`;

  const { searchUsersDataCopyRequest } = useSearchUsers();
  const [query, setQuery] = useState<CopyUsersDataQuery>({
    page: +urlParams.urlPage,
    sort: urlParams.urlSort,
    sortBy: urlParams.urlSortBy,
    search: urlParams.urlSearch,
    searchBy: urlParams.urlSearchBy,
    startDate: urlParams.urlStartDate,
    endDate: urlParams.urlEndDate,
    input: '',
  });

  const {
    handleSearch,
    handlePageChange,
    handleChangeSortBy,
    handleChangeSearchBy,
    handleChangeSort,
    handleStartDateChange,
    handleEndDateChange,
    handleGenerateSearchFilterOptions,
  } = useSearchParams<CopyUsersDataQuery>({ query, setQuery });

  useEffect(() => {
    searchUsersDataCopyRequest(query);
    navigate(
      `?page=${query.page}&sort=${query.sort}&sortBy=${query.sortBy}&search=${query.search}&searchBy=${query.searchBy}&startDate=${query.startDate}&endDate=${query.endDate}`,
    );
  }, [
    query.page,
    query.sort,
    query.sortBy,
    query.startDate,
    query.endDate,
    query.searchBy,
    query.search,
  ]);

  useEffect(() => {
    if (queryString) return;
    navigate(
      `?page=${query.page}&sort=${query.sort}&sortBy=${query.sortBy}&search=${query.search}&searchBy=${query.searchBy}&startDate=${query.startDate}&endDate=${query.endDate}`,
    );
  }, [queryString]);

  const onResetFilter = () => {
    setQuery({
      page: 1,
      sort: 'DESC',
      sortBy: 'created_at',
      search: '',
      searchBy: 'user_name',
      startDate: firstDayOfTheYear,
      endDate: today,
      input: '',
    });
  };

  if (isLoading && !users) {
    return (
      <div css={[sectionStyles.section, styles.loadingSpace]}>
        <LoadingImg />
      </div>
    );
  }

  if (error) {
    return (
      <div css={sectionStyles.section}>
        <Pill type="error" title={error.message} text={' '} />
      </div>
    );
  }

  if (!users) {
    return null;
  }

  return (
    <div css={sectionStyles.users}>
      <motion.div transition={{ duration: 0.3 }} {...FADE_IN_MOVE_Y_REGULAR}>
        <SectionHead title={title}>
          <DropdownMenu
            onSelect={(id) => {
              handleChangeSearchBy(id);
            }}
            placeholder={
              <div>
                Search by <strong>{query.searchBy}</strong>
              </div>
            }
            hideCaret={false}
            items={handleGenerateSearchFilterOptions(
              searchByOptions,
              query.searchBy,
            )}
            position="bottomLeft"
            customButtonStyles={styles.sortByDropdown}
          />
          <TextInput
            autoFocus
            value={query.input}
            icon={isLoading ? <SmallLoadingImg /> : <Search />}
            css={styles.search}
            placeholder={`Search users by ${
              query.searchBy.split('_')[1]
            }. Press enter to run.`}
            onChange={(e) => setQuery({ ...query, input: e.target.value })}
            onKeyUp={(
              e: React.KeyboardEvent<HTMLInputElement> &
                React.ChangeEvent<HTMLInputElement>,
            ) => handleSearch(e)}
          />

          <React.Suspense fallback={null}>
            <div css={styles.datePickers}>
              <DatePickerButton
                label={
                  <FormattedMessage id="admin.users.copy-profile-data.startDate" />
                }
                isDisabled={false}
                dateString={query.startDate}
                maxDate={startOfToday()}
                buttonIcon={<CalendarIcon />}
                theme={'light'}
                onSelect={handleStartDateChange}
                showTime={false}
              />
              <DatePickerButton
                label={
                  <FormattedMessage id="admin.users.copy-profile-data.endDate" />
                }
                isDisabled={false}
                dateString={query.endDate}
                buttonIcon={<CalendarIcon />}
                theme={'light'}
                onSelect={handleEndDateChange}
                showTime={false}
              />
            </div>
          </React.Suspense>

          <div css={styles.searchFilter}>
            <DropdownMenu
              onSelect={(id) => {
                handleChangeSortBy(id);
              }}
              placeholder={
                <div>
                  Sort by <strong>{query.sortBy}</strong>
                </div>
              }
              hideCaret={false}
              items={handleGenerateSearchFilterOptions(
                sortByOptions,
                query.sortBy,
              )}
              position="bottomRight"
              customButtonStyles={styles.sortByDropdown}
            />

            <DropdownMenu
              onSelect={(id) => {
                handleChangeSort(id);
              }}
              placeholder={
                <div>
                  Sort <strong>{query.sort}</strong>
                </div>
              }
              hideCaret={false}
              items={handleGenerateSearchFilterOptions(sortOptions, query.sort)}
              position="bottomRight"
              customButtonStyles={styles.sortByDropdown}
            />

            <Button
              buttonType={'outline'}
              size={'tiny'}
              onClick={onResetFilter}
              disabled={isLoading}
            >
              Reset filter
            </Button>
          </div>
        </SectionHead>
      </motion.div>

      <SectionBody>
        {Boolean(query.search?.length) &&
          Boolean(users.length) &&
          !isLoading && (
            <div css={styles.results}>
              <strong>{total}</strong> result(s) found for{' '}
              <strong>{query.search}</strong>
            </div>
          )}

        <motion.div transition={{ duration: 0.3 }} {...FADE_IN_MOVE_Y_REGULAR}>
          <UsersCopyRequestTable
            isLoading={isLoading}
            query={query}
            users={users}
          />

          {!error && lastPage !== 1 && (
            <React.Suspense fallback={null}>
              <div css={[styles.pagination, isLoading ? styles.loading : null]}>
                <Pagination
                  forcePage={query.page - 1}
                  initialPage={query.page - 1}
                  pageCount={lastPage || 1}
                  onPageChange={(pag) => handlePageChange(pag.selected + 1)}
                />
              </div>
            </React.Suspense>
          )}
        </motion.div>
      </SectionBody>
    </div>
  );
};
