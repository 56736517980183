import React, { InputHTMLAttributes, useState } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FormError } from 'modules/app/intl';
import DarkEmail from 'assets/icons/transparentMail.svg';
import WhiteEmail from 'assets/icons/whiteEmail.svg';
import { TextInput } from 'modules/app';
import { useIntl } from 'gatsby-plugin-intl';

interface Props extends InputHTMLAttributes<Omit<HTMLInputElement, 'type'>> {
  constraints: RegisterOptions;
  value: string;
  theme?: 'dark' | 'light';
  label?: string;
  placeholder?: string;
}

export const EmailField: React.FC<Partial<Props>> = ({
  constraints,
  value,
  theme = 'light',
  label = 'register.email_label',
  placeholder = '',
  ...rest
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const [email, setEmail] = useState(value);
  const intl = useIntl();

  const err = errors['email'];

  return (
    <>
      <Controller
        name="email"
        control={control}
        rules={constraints}
        render={({ field: { onChange } }) => (
          <TextInput
            {...rest}
            id="email_field"
            showError={Boolean(err)}
            formErrors={<FormError errors={errors} name="email" />}
            label={<FormattedMessage id={label} />}
            placeholder={
              placeholder ? intl.formatMessage({ id: placeholder }) : ''
            }
            value={email}
            type="email"
            icon={theme === 'dark' ? <WhiteEmail /> : <DarkEmail />}
            onChange={(e) => {
              onChange(e.target.value);
              setEmail(e.target.value);
            }}
          />
        )}
      />
    </>
  );
};
