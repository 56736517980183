import { AppState } from 'modules/redux-store';

const getTalentMessages = (state: AppState) => state.talentMessages;

const getActiveConversation = (applicationId: string) => (state: AppState) => {
  const { conversationsList } = getTalentMessages(state);

  return conversationsList.find((c) => c.applicationId === applicationId);
};

export const talentMessagesSelector = {
  getActiveConversation,
};
