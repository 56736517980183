import React from 'react';

import * as styles from 'modules/app/styles/Heading.styles';
import { SerializedStyles } from '@emotion/react';
import { motion, MotionProps } from 'framer-motion';

interface Props extends MotionProps {
  as: keyof JSX.IntrinsicElements;
  size: keyof typeof styles;
  css?: SerializedStyles | SerializedStyles[];
  useMotion?: boolean;
}

export const Heading: React.FC<Props> = ({
  as,
  size,
  children,
  css,
  useMotion,
  ...rest
}) => {
  const Component = useMotion ? motion[as] : as;

  return (
    <Component css={[styles[size], css]} {...rest}>
      {children}
    </Component>
  );
};
