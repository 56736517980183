import { AxiosError } from 'axios';

export function isResponseError(
  response: unknown,
): response is AxiosError<ApiErrorData> {
  return Boolean(
    response &&
      typeof response === 'object' &&
      response.hasOwnProperty('isAxiosError'),
  );
}
