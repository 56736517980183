import React, { useEffect, useRef, useState } from 'react';
import { Loading, useScript } from 'modules/app';
import { FormFieldError } from 'modules/app/intl';
import { SerializedStyles } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
import { FormError } from '../intl';
import { Controller, useFormContext } from 'react-hook-form';

import * as styles from 'modules/app/styles/TextInput.styles';
import * as googleStyles from 'modules/app/styles/GoogleAutocomplete.styles';
import { useFieldArrayErrorTransformer } from 'modules/onboarding/hooks/useFieldArrayErrorTransformer';
import { useIntl } from 'gatsby-plugin-intl';

interface Props {
  label: string;
  id: string;
  name: string;
  selectedPlace: SelectedPlace | undefined;
  onSelectedPlace: (place: SelectedPlace | undefined) => void;
  customStyles?: SerializedStyles;
  fieldArrayKey?: number;
  isRequired?: boolean;
  reset?: number;
  placeholder?: string;
}

function processSelectedPlace(place: PlaceDetailsResponse): SelectedPlace {
  return {
    cityCountry: place.formatted_address,
    location: {
      x: place.geometry.location.lat(),
      y: place.geometry.location.lng(),
    },
  };
}

export const GooglePlacesAutocomplete: React.FC<Props> = ({
  label,
  id,
  name,
  selectedPlace,
  onSelectedPlace,
  customStyles,
  fieldArrayKey,
  reset,
  isRequired = true,
  placeholder,
}) => {
  const intl = useIntl();
  const {
    control,
    formState: { errors },
    clearErrors,
    setValue,
  } = useFormContext();

  const { isFieldArrayError, fieldArrayError } = useFieldArrayErrorTransformer(
    errors,
    name,
    fieldArrayKey,
  );

  const [internalSelectedPlace, setInternalSelectedPlace] = useState<
    SelectedPlace | undefined
  >(selectedPlace);
  const [inputValue, setInputValue] = useState<string>('');
  const autocompleteRef = useRef<HTMLInputElement>(null);
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete>();
  const [isListenerAdded, setIsListenerAdded] = useState(false);
  const status = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${
      process.env.GATSBY_GOOGLE_API_KEY
    }&libraries=places&language=${intl.locale || intl.defaultLocale}`,
  );

  const showError = errors[name] || isFieldArrayError;

  useEffect(() => {
    if (status === 'ready' && !autocomplete) {
      const at = new google.maps.places.Autocomplete(autocompleteRef.current, {
        fields: ['geometry', 'address_components', 'formatted_address'],
      });

      setAutocomplete(at);

      if (selectedPlace) {
        setInputValue(selectedPlace.cityCountry);
      }
    }
  }, [status, selectedPlace, name, autocomplete]);

  useEffect(() => {
    if (autocomplete && !isListenerAdded) {
      setIsListenerAdded(true);

      autocomplete.addListener('place_changed', () => {
        const place: PlaceDetailsResponse = autocomplete.getPlace();
        if (!place.geometry) {
          return;
        }

        const selectedValue = processSelectedPlace(place);
        clearErrors([name]);
        setInputValue(selectedValue.cityCountry);
        setValue(name, selectedValue.cityCountry);
        onSelectedPlace(selectedValue);
        setInternalSelectedPlace(selectedValue);
      });
    }
  }, [
    autocomplete,
    name,
    clearErrors,
    onSelectedPlace,
    isListenerAdded,
    setValue,
  ]);

  const selectedPlaceEffect = () => {
    if (!selectedPlace) {
      setInputValue('');
      setInternalSelectedPlace(undefined);

      return;
    }

    onSelectedPlace(selectedPlace);
  };

  useEffect(selectedPlaceEffect, [selectedPlace]);

  useEffect(() => {
    if (Number.isInteger(reset) && reset !== 0) {
      setInputValue('');
      setInternalSelectedPlace(undefined);
    }
  }, [reset]);

  return (
    <div css={[showError ? styles.rootError : styles.root, customStyles]}>
      <Loading
        customStyles={googleStyles.loadingIndicator}
        isLoading={!Boolean(autocomplete)}
        component={null}
        altText="register.alt_tags.googleIsLoading"
      />
      <div css={showError ? styles.labelWrapperError : styles.labelWrapper}>
        <label htmlFor={id}>{<FormattedMessage id={label} />}</label>
      </div>
      <Controller
        name={name}
        control={control}
        defaultValue={inputValue}
        rules={{
          required: isRequired,
          validate: () => {
            if (isRequired) {
              return !internalSelectedPlace
                ? 'onboarding.form.errors.location'
                : true;
            }

            return;
          },
        }}
        render={() => (
          <input
            id={id}
            name={name}
            css={styles.input}
            type="text"
            value={inputValue}
            ref={autocompleteRef}
            placeholder={
              placeholder ? intl.formatMessage({ id: placeholder }) : undefined
            }
            onChange={(e) => {
              setValue(name, e.target.value, { shouldValidate: true });
              setInputValue(e.target.value);
              onSelectedPlace(undefined);
              setInternalSelectedPlace(undefined);
            }}
          />
        )}
      />

      <div css={styles.helpContainer}>
        {!Number.isInteger(fieldArrayKey) && (
          <FormError errors={errors} name={name} />
        )}

        {Number.isInteger(fieldArrayKey) && (
          <FormFieldError error={fieldArrayError} />
        )}
      </div>
    </div>
  );
};
