import { FormattedMessage } from 'react-intl';
import React from 'react';

import * as labelStyles from 'modules/app/styles/FormLabels.styles';

interface Props {
  label: React.ReactNode;
  customStyles?: SerializedStyles | SerializedStyles[];
  isOptional?: boolean;
  altText?: React.ReactNode;
  maxChar?: string;
  labelStyle?: boolean;
  error?: boolean;
}

export const Label: React.FC<Props> = ({
  customStyles,
  label,
  isOptional,
  maxChar,
  labelStyle,
  altText,
  error,
}) => {
  return (
    <div css={[labelStyles.labelWrapper, customStyles]}>
      <label
        css={[labelStyle && labelStyles.label, error && labelStyles.labelError]}
      >
        {label}
      </label>

      <span css={labelStyles.isOptional}>
        {isOptional && (
          <>
            <FormattedMessage id="shared.page.optional" />
            {maxChar && ', '}
          </>
        )}

        {maxChar && (
          <>
            {maxChar}{' '}
            <FormattedMessage id="onboarding.form.fields.information.characters_max" />
          </>
        )}

        {altText && <span css={labelStyles.isOptional}>{altText}</span>}
      </span>
    </div>
  );
};
