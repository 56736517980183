import React from 'react';
import * as styles from 'modules/admin/styles/StatusPill.styles';
interface StatusPillProps {
  pillStatus: boolean;
  statusTrueMessage: string;
  statusFalseMessage: string;
}

export const StatusPill: React.FC<StatusPillProps> = ({
  pillStatus,
  statusTrueMessage,
  statusFalseMessage,
}) => {
  return (
    <>
      <div css={styles.statusPill}>
        {pillStatus ? (
          <div className={'statusPill--verified'}>{statusTrueMessage}</div>
        ) : (
          <div className={'statusPill--unverified'}>{statusFalseMessage}</div>
        )}
      </div>
    </>
  );
};
