import { useState, useEffect } from 'react';
import { navigate } from '@reach/router';

export function useQueryHandler(
  handleRequest: (query: ExtraSearchQueryData) => void,
  urlParams: UrlParams,
  queryString: string,
) {
  const [query, setQuery] = useState<ExtraSearchQueryData>({
    page: Number(urlParams.urlPage),
    sort: urlParams.urlSort,
    sortBy: urlParams.urlSortBy,
    search: urlParams.urlSearch,
    searchBy: urlParams.urlSearchBy,
    startDate: urlParams.urlStartDate,
    endDate: urlParams.urlEndDate,
    hideDeleted: JSON.parse(urlParams.urlHideDeleted),
    input: '',
  });

  useEffect(() => {
    if (queryString) return;
    navigate(
      `?page=${query.page}&sort=${query.sort}&sortBy=${query.sortBy}&search=${query.search}&searchBy=${query.searchBy}&startDate=${query.startDate}&endDate=${query.endDate}`,
    );
  }, [queryString]);

  useEffect(() => {
    handleRequest(query);
    navigate(
      `?page=${query.page}&sort=${query.sort}&sortBy=${query.sortBy}&search=${query.search}&searchBy=${query.searchBy}&startDate=${query.startDate}&endDate=${query.endDate}`,
    );
  }, [
    query.page,
    query.sort,
    query.sortBy,
    query.search,
    query.searchBy,
    query.startDate,
    query.endDate,
  ]);

  return {
    query,
    queryString,
    setQuery,
  };
}
