import { css } from '@emotion/react';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  background-color: hsl(var(--color-grayscale-9));
  color: hsl(var(--color-textLight-10));
`;

export const fullHeight = css`
  @media ${breakpoints.large} {
    min-height: 100vh;
  }
`;
