import { AppState } from 'modules/redux-store';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Freshchat } from 'reactjs-freshchat';
import { useCookiePolicy } from './hooks';
import { Loading } from './shared/Loading';

import 'reactjs-freshchat/dist/index.css';
import FreshChatIcon from 'assets/icons/freshchat.svg';

import * as styles from '../styles/FreshChat.styles';

declare global {
  interface Window {
    fcWidget: {
      open: VoidFunction;
      close: VoidFunction;
      isOpen: () => boolean;
    };
  }
}

export const FreshChat: React.FC = () => {
  const { user } = useSelector((state: AppState) => state.session);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const { getCookie } = useCookiePolicy();
  const [isCookieAccepted, setIsCookieAccepted] = React.useState(
    getCookie() === 'accept',
  );
  const cookieCheckInterval = useRef<NodeJS.Timeout | undefined>();

  const shouldInitComponent = 'fcWidget' in window || isOpened;

  const checkCookieInterval = useCallback(() => {
    if (getCookie() === 'accept') {
      setIsCookieAccepted(true);
      cookieCheckInterval.current && clearInterval(cookieCheckInterval.current);
    }
  }, []);

  const handleWidgetClick = () => {
    const hasWidget = 'fcWidget' in window;

    if (!hasWidget) {
      setIsLoading(true);
      setIsOpened(true);
      setTimeout(() => setIsLoading(false), 3000);
      return;
    }

    window.fcWidget.isOpen() ? window.fcWidget.close() : window.fcWidget.open();
  };

  useEffect(() => {
    if (!isCookieAccepted && !cookieCheckInterval.current) {
      cookieCheckInterval.current = setInterval(checkCookieInterval, 1000);
    }

    return () => {
      cookieCheckInterval.current && clearInterval(cookieCheckInterval.current);
    };
  }, [checkCookieInterval]);

  if (!isCookieAccepted) {
    return null;
  }

  return (
    <>
      {shouldInitComponent && (
        <Freshchat
          token={`${process.env.GATSBY_FRESHCHAT_TOKEN}`}
          externalId={user?.id}
          firstName={user?.firstName}
          lastName={user?.lastName}
          open={true}
          email={user?.email}
          config={{
            headerProperty: {
              hideChatButton: true,
              backgroundColor: '#b5f2ea',
              foregroundColor: '#131618',
            },
          }}
          tags={['roango_public']}
        />
      )}
      <div css={styles.root}>
        {isLoading ? (
          <Loading isLoading={true} component={null} />
        ) : (
          <button css={styles.button} onClick={handleWidgetClick}>
            <FreshChatIcon />
          </button>
        )}
      </div>
    </>
  );
};
