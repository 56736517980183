import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  isHandleExisting?: boolean;
  setIsHandleExisting?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useValidateCompanyBasicInputs({
  isHandleExisting,
  setIsHandleExisting,
}: Props) {
  const { setError, clearErrors, watch, getValues } = useFormContext();
  const [handleWithError, setHandleWithError] = useState('');

  const validateHandle = (handle: string) => {
    if (!handle) return true;
    if (handle.includes(' ')) return 'onboarding.form.errors.no_whitespace';
    if (handle.length < 3)
      return 'onboarding.form.errors.company_username_short';
    if (handle.length > 20)
      return 'onboarding.form.errors.company_username_long';

    return true;
  };

  const validateWebsiteUrl = (url: string) => {
    if (!url) return true;
    if (url.includes(' ')) return 'onboarding.form.errors.no_whitespace';

    return true;
  };

  const validateYear = (year: string) => {
    if (!year) return true;

    const yearRegex = /^\d{4}$/g;
    if (!yearRegex.test(year)) {
      return 'onboarding.form.errors.invalidYear';
    }

    return parseInt(year) <= new Date().getFullYear()
      ? true
      : 'onboarding.form.errors.future_date';
  };

  useEffect(() => {
    if (!isHandleExisting) {
      clearErrors('handle');
      return;
    }

    setHandleWithError(getValues('handle'));
    setError('handle', {
      message: 'onboarding.form.errors.company_handle',
    });
  }, [isHandleExisting]);

  useEffect(() => {
    if (!setIsHandleExisting) return;
    if (handleWithError !== watch('handle')) setIsHandleExisting(false);
  }, [watch('handle')]);

  return {
    validateHandle,
    validateWebsiteUrl,
    validateYear,
  };
}
