export const companyRoleValues = [
  {
    value: 'administrator',
    label: 'settings.membersContent.administrator',
  },
  {
    value: 'team_member',
    label: 'settings.membersContent.team_member',
  },
  {
    value: 'limited_team_member',
    label: 'settings.membersContent.limited_team_member',
  },
  {
    value: 'reviewer',
    label: 'settings.membersContent.reviewer',
  },
];
