import React, { useCallback, useEffect, useState } from 'react';

import * as styles from 'modules/admin/styles/Manage.styles';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import {
  AdminTable,
  SectionHead,
  UserData,
  AddMembers,
  NoData,
  SectionBody,
} from 'modules/admin/components';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { ActionModal, Button } from 'modules/app';
import PlusIcon from 'assets/icons/plus.svg';
import { flex, font, svg, text } from '@prototyp/gatsby-plugin-gumball/utils';
import { useManageStaffRole } from '../../hooks';
import { useGetData } from 'modules/admin/hooks/useGetData';
import { navigate } from 'gatsby-plugin-intl';
import { isAdminUser } from 'modules/admin/helpers';

const Pagination = React.lazy(
  () => import('modules/app/components/pagination/Pagination'),
);

interface Props {
  urlDataType: string;
  currentPage: number;
}

export const Content: React.FC<Props> = ({
  urlDataType,
  currentPage: page,
}) => {
  const { data } = useSelector((state: AppState) => state.adminUsers);
  const { userStaffRole } = useSelector((state: AppState) => state.session);
  const header = [
    { id: 'user', title: 'user', width: '300px' },
    { id: 'reg', title: 'registered', width: '150px' },
    { id: 'email', title: 'e-mail ver.', width: '150px' },
    { id: 'role', title: 'role', width: 'auto' },
  ];
  const users = data[urlDataType];
  const [modal, setModal] = useState(false);

  const { requestExpireStaffRole, requestAssignStaffRole } =
    useManageStaffRole();
  const { getData } = useGetData();

  const getStaff = useCallback(
    (currentPage = 1, urlDataType) => {
      getData({ dataType: urlDataType, page: currentPage });
    },
    [getData, urlDataType],
  );

  const onAssign = (id: string, roleId: string) => {
    const role =
      roleId === 'superadmin'
        ? 'SuperAdmin'
        : roleId.slice(0, 1).toUpperCase() + roleId.slice(1).toLowerCase();
    let nextPage = page;
    if (users.data.length === 1 && page !== 1) {
      nextPage = page - 1;
      navigate(`/admin/staff/?user=${urlDataType}&page=${nextPage}`);
    }
    requestAssignStaffRole(id, { roleId: role }, () =>
      getStaff(nextPage, urlDataType),
    );
  };

  const onRemoveRole = (id: string) => {
    let nextPage = page;
    if (users.data.length === 1 && page !== 1) {
      nextPage = page - 1;
      navigate(`/admin/staff/?user=${urlDataType}&page=${nextPage}`);
    }
    requestExpireStaffRole(id, () => getStaff(nextPage, urlDataType));
  };

  const onPageChange = (data: { selected: number }) => {
    navigate(`/admin/staff/?user=${urlDataType}&page=${data.selected + 1}`);
  };

  useEffect(() => {
    getStaff(page, urlDataType);
  }, [getStaff, page]);

  return (
    <div css={styles.staff}>
      <motion.section
        transition={{ duration: 0.3 }}
        {...FADE_IN_MOVE_Y_REGULAR}
      >
        <SectionHead
          title={`Manage ${urlDataType} users`}
          customStyles={flex.align.center}
        >
          {isAdminUser(userStaffRole?.name) && (
            <Button
              type="submit"
              buttonType="primary"
              css={svg.stroke.currentColor}
              icon={<PlusIcon css={flex.display.flex} />}
              onClick={() => setModal(!modal)}
            >
              <span css={[font.size.small, text.transform.capitalize]}>
                Add {urlDataType} member
              </span>
            </Button>
          )}
        </SectionHead>
      </motion.section>

      {modal && (
        <ActionModal isOpen={modal} setIsOpen={setModal}>
          <AddMembers
            role={urlDataType}
            handleClick={() => setModal(!modal)}
            onAssign={onAssign}
          />
        </ActionModal>
      )}

      <motion.div transition={{ duration: 0.3 }} {...FADE_IN_MOVE_Y_REGULAR}>
        <SectionBody>
          {!users || !Boolean(users.total) ? (
            <NoData dataType="users" />
          ) : (
            <React.Suspense fallback={null}>
              <AdminTable headers={header}>
                {users.data.map((user: Users) => (
                  <UserData
                    key={user.id}
                    {...user}
                    onAssign={onAssign}
                    onRemoveRole={onRemoveRole}
                  />
                ))}
              </AdminTable>
              <div css={[flex.display.flex, flex.justify.center]}>
                <Pagination
                  initialPage={page - 1}
                  onPageChange={onPageChange}
                  pageCount={users.lastPage}
                  forcePage={page - 1}
                />
              </div>
            </React.Suspense>
          )}
        </SectionBody>
      </motion.div>
    </div>
  );
};
