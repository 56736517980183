import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { font } from '@prototyp/gatsby-plugin-gumball/utils/font';

export const root = css`
  color: hsl(var(--color-grayscale-1));
  max-height: ${spacing(925)};
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const loading = css`
  margin-top: ${spacing(80)};
`;

export const info = css`
  padding: ${spacing(8)} ${spacing(16)};
  border-radius: ${spacing(3)};
  background: hsl(var(--color-note-8));
  ${font.size.small};
  margin-bottom: ${spacing(16)};
  color: hsl(var(--color-text-1));
`;
