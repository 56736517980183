/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from 'react';
import 'normalize.css';

import { Global } from '@emotion/react';
import { ToastContainer } from 'react-toastify';

import { StoreProvider } from 'modules/redux-store';
import Layout from 'modules/app/components/Layout';
import { Provider as IntlProvider } from 'modules/app/intl';
import { global } from 'style/global.styles';
import 'react-toastify/dist/ReactToastify.css';
import { FreshChat } from 'src/modules/app/components/FreshChat.tsx';

// You can delete this file if you're not using it

export const wrapRootElement = ({ element }) => {
  return (
    <StoreProvider>
      <IntlProvider>
        <Layout>
          <ToastContainer closeButton={false} position="bottom-left" />
          <Global styles={global} />
          {element}
          <FreshChat />
        </Layout>
      </IntlProvider>
    </StoreProvider>
  );
};
