import { css } from '@emotion/react';

export const header = css`
  border-bottom: 1px solid hsl(var(--color-borderUltraLight-9));
  display: flex;
  align-items: center;
  padding: var(--spacing-xxxlrg);
  position: fixed;
  width: calc(100% - 240px);
  flex-direction: row;
  justify-content: space-between;
  background: hsl(var(--color-grayscale-1));
  z-index: 3;
`;

export const header__user = css`
  display: flex;
  align-items: center;

  .header__user--avatar {
    background: hsl(var(--color-grayscale-2));
    border-radius: var(--border-radius-full);
    display: flex;
    font-size: var(--font-size-tiny);
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin: 0 0 0 var(--spacing-lrg);
    overflow: hidden;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
`;
