import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints, grid } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  margin-bottom: ${spacing(60)};

  @media ${breakpoints.medium} {
    margin-bottom: ${spacing(80)};
    display: grid;
    ${grid.template.cols2};
    grid-gap: ${spacing(32)};
  }

  @media ${breakpoints.large} {
    ${grid.template.cols12};
  }
`;

export const title = css`
  @media ${breakpoints.large} {
    ${grid.colSpan.c4};
  }
`;

export const content = css`
  color: hsl(var(--color-grayscale-4));

  @media ${breakpoints.large} {
    ${grid.colSpan.c3};
  }
`;

export const select = css`
  .roango-select__single-value {
    color: hsl(var(--color-grayscale-4));
  }

  @media ${breakpoints.large} {
    ${grid.colSpan.c3};
    ${grid.colStart.c9};
  }
`;
