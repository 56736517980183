import { css } from '@emotion/react';

export const root = css`
  overflow: hidden;
  padding: calc(var(--unit) * 30) 0 calc(var(--unit) * 30) 0;
`;

export const theme = (type: 'white' | 'black' | 'gray') => {
  if (type === 'gray') {
    return css`
      background-color: hsl(var(--color-textLight-10));

      button {
        color: hsl(var(--color-text-1));
      }
    `;
  }

  if (type === 'black') {
    return css`
      background-color: hsl(var(--color-text-1));
      color: hsl(var(--color-grayscale-1));

      h1 {
        color: white;
      }
    `;
  }

  return css`
    background-color: white;

    h1,
    button {
      color: hsl(var(--color-text-1));
    }
  `;
};
