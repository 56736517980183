import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as styles from 'modules/app/styles/InfoText.styles';
import InfoIcon from 'assets/icons/info.svg';

interface Props {
  customStyles?: SerializedStyles | SerializedStyles[];
  textId: string;
}

export const InfoText: React.FC<Props> = ({ textId, customStyles }) => {
  return (
    <div css={[styles.root, customStyles]}>
      <InfoIcon css={styles.infoIcon} />
      <p>
        <FormattedMessage id={textId} />
      </p>
    </div>
  );
};
