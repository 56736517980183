import { formatDate } from '../static';

export function getMonthlyGraphData(data?: UsersActivityResponse[]) {
  if (!data) return;

  data.sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf());

  const peak = data.reduce((prev, current) =>
    prev.count > current.count ? prev : current,
  );

  const graph = {
    labels: data.map((item) => formatDate(item.date, 'dd.MM')),
    datasets: [
      {
        label: 'Active users',
        data: data.map((item) => item.count),
        fill: true,
        borderColor: '#8aeadd',
        borderWidth: 3,
      },
    ],
  };

  return {
    graph,
    peakNumber: peak.count,
    peakDate: formatDate(peak.date, 'dd.MM'),
    noUsersCount: data.filter((item) => item.count === 0).length,
    values: data.map((item) => item.count),
  };
}
