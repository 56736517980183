import { navigate } from 'gatsby-plugin-intl';
import { useToastify } from 'modules/app';
import { useCsrfCookie } from 'modules/app/auth';
import { HttpError } from 'modules/app/http/client/httpError';
import { billingActions } from 'modules/billing';
import { companyDataAction } from 'modules/profile';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ProfileBarServices } from '../http';

export const useGetCompanyInfo = () => {
  const dispatch = useDispatch();
  const { toastError } = useToastify();
  const { getCsrf } = useCsrfCookie();
  const csrf = getCsrf();

  const [isLoading, setIsLoading] = useState(false);

  const companyHttp = useMemo(() => new ProfileBarServices(), [csrf]);

  async function getActivePlanCached(companyId: string) {
    try {
      const { data } = await companyHttp.get<ActivePlanCached>(
        `/${companyId}/settings/company-subscription-plans/active-cached`,
      );
      dispatch(billingActions.setActivePlan(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.cause === 'active_plan_required') {
        dispatch(billingActions.setActivePlan(undefined));
      }
    }
  }

  async function getCompany(companyId: string) {
    try {
      const { data } = await companyHttp.get(`/${companyId}`);
      dispatch(companyDataAction.companyData(data));
    } catch (e) {
      return;
    }
  }

  async function getLatestPlan(companyId: string) {
    try {
      const { data } = await companyHttp.get<LatestPlan>(
        `/${companyId}/settings/company-subscription-plans/latest`,
      );
      dispatch(companyDataAction.setLatestPlan(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.status === 404) {
        dispatch(companyDataAction.setLatestPlan(undefined));
      }
    }
  }

  async function getCompanyData(companyId: string, handle: string) {
    setIsLoading(true);

    try {
      await Promise.all([
        getCompany(companyId),
        getLatestPlan(companyId),
        getActivePlanCached(companyId),
      ]);
      navigate(`/profile/company/overview/${handle}`);
    } catch (e) {
      toastError();
    }

    setIsLoading(false);
  }

  return {
    isLoading,
    getCompany: getCompanyData,
  };
};
