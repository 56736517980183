import { useLocation } from '@reach/router';
import { navigate } from 'gatsby-plugin-intl';
import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useAuth, useMandatoryRefresh } from 'modules/auth';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { settingsAction } from '../redux';

interface DeactivateData {
  deactivateMessage: string;
  isDeactivateAccepted: boolean;
}

interface ChangePasswordData {
  password: string;
  newPassword: string;
}

export const useUserSettings = () => {
  const dispatch = useDispatch();
  const { logoutUser } = useAuth();
  const { search } = useLocation();
  const { toastError, toastSuccess } = useToastify();
  const { mandatoryRefresh } = useMandatoryRefresh();

  const [lastPage, setLastPage] = useState(1);
  const [error, setError] = useState<HttpError>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLastAdmin, setIsLastAdmin] = useState(false);
  const [sessions, setSessions] = useState<UserSession[]>();
  const [isUsersTfaEnabled, setIsUsersTfaEnabled] = useState(false);

  const handle = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('handle');
  }, [search]);

  const http = useHttpClient('/api/v1/settings');
  const companyHttp = useHttpClient('/api/v1/company');

  async function closeAccount(
    isDeactivateAccepted: boolean,
    deactivateMessage: string,
  ) {
    try {
      await http.put<DeactivateData>(`/deactivate`, {
        isDeactivateAccepted,
        deactivateMessage,
      });
      logoutUser();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.cause?.startsWith('cannot_expire_only_admin_in')) {
        toastError('http_errors.adminOnlyUser');
        return;
      }
      toastError();
    }
  }

  async function changePassword(password: string, newPassword: string) {
    try {
      await http.put<ChangePasswordData>(`/user-password`, {
        password,
        newPassword,
      });

      toastSuccess(
        'settings.securitySection.changePassword.changePasswordSuccessText',
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.cause === 'invalid_credentials') {
        toastError(
          'settings.securitySection.changePassword.changePasswordError',
        );
        return;
      }
      toastError();
    }
  }

  async function killSessions() {
    try {
      await http.delete(`user-sessions/kill-all`);
      toastSuccess('settings.securitySection.sessionsSuccess');
      logoutUser();
    } catch (e) {
      toastError();
    }
  }

  async function getSessions(page: number) {
    setIsLoading(true);
    try {
      const { data } = await http.get<PaginatedResponse<UserSession>>(
        `user-sessions?status=all`,
        {
          params: {
            page,
            perPage: 5,
            sort: 'desc',
          },
        },
      );
      setSessions(data.data);
      setLastPage(data.lastPage);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function deactivateCompany(
    companyId: string,
    info: DeactivateCompanyRequest,
    callback: VoidFunction,
  ) {
    setIsLoading(true);
    try {
      await companyHttp.put(`${companyId}/settings/deactivate-company`, {
        ...info,
      });
      callback();
      mandatoryRefresh();
      dispatch(settingsAction.deactivateCompany());
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function leaveCompany(companyId: string) {
    setIsLoading(true);
    try {
      await companyHttp.delete(`${companyId}/leave`);
      mandatoryRefresh();
      setIsLastAdmin(false);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.cause === 'cannot_expire_only_admin') {
        setIsLastAdmin(true);
        return;
      }
      toastError();
    } finally {
      setIsLoading(false);
    }
  }

  async function joinCompany(
    inviteToken: string,
    errorCallback: (e: HttpError) => void,
  ) {
    setIsLoading(true);
    try {
      await companyHttp.get<InvitedUser>(`users/join/${inviteToken}`);
      await mandatoryRefresh();
      navigate('/profile/settings/user/account');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      errorCallback(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function claimCompany(
    token: string,
    errorCallback: (e: HttpError) => void,
  ) {
    setIsLoading(true);
    try {
      await companyHttp.post(`settings/transfer-ownership/${token}`);
      await mandatoryRefresh();
      toastSuccess('Company has been claimed successfully!');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      errorCallback(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function requestDataCopy() {
    setIsLoading(true);
    try {
      await http.post(`/user-data-copy`);
      toastSuccess('settings.privacyContent.success');
    } catch (e) {
      toastError();
    } finally {
      setIsLoading(false);
    }
  }

  async function requestTfaStatus() {
    try {
      const { data } = await http.get<boolean>(`/two-factor/check`);
      setIsUsersTfaEnabled(data);
    } catch (e) {}
  }

  return {
    error,
    handle,
    sessions,
    lastPage,
    isLoading,
    isLastAdmin,
    isUsersTfaEnabled,
    joinCompany,
    getSessions,
    killSessions,
    claimCompany,
    closeAccount,
    leaveCompany,
    changePassword,
    setIsLastAdmin,
    requestDataCopy,
    requestTfaStatus,
    deactivateCompany,
  };
};
