import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';

export const statusPill = css`
  color: hsl(var(--color-text-1));
  cursor: default;
  display: flex;
  font-size: var(--font-size-tiny);
  align-items: center;
  text-align: center;
  white-space: nowrap;

  .statusPill--verified,
  .statusPill--unverified {
    border-radius: var(--border-radius-half);
    padding: ${spacing(5)} ${spacing(20)};
    line-height: var(--line-height-tiny);
  }

  .statusPill--verified {
    background: hsl(var(--color-success-9));
    border: 1px solid hsl(var(--color-success-9));
  }

  .statusPill--unverified {
    border: 1px solid hsl(var(--color-borderUltraLight-9));
  }
`;
