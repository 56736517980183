import { format } from 'date-fns';

export const parseDate = (date: Date | null, addTime = false) => {
  if (!date) {
    return;
  }

  if (addTime) {
    return format(date, 'yyyy-MM-dd HH:mm:ss');
  }

  return format(date, 'yyyy-MM-dd');
};
