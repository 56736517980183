import React from 'react';

import * as styles from 'modules/app/styles/Heading.styles';
import { SerializedStyles } from '@emotion/react';

interface Props extends React.HTMLAttributes<HTMLLabelElement> {
  size: Exclude<keyof typeof styles, 'fontFamily'>;
  fontFamily?: 'primary' | 'secondary';
  customStyles?: SerializedStyles | SerializedStyles[];
}

export const LabelTag: React.FC<Props> = ({
  size,
  fontFamily,
  children,
  customStyles,
  ...rest
}) => {
  return (
    <label
      css={[styles[size], styles.fontFamily(fontFamily), customStyles]}
      {...rest}
    >
      {children}
    </label>
  );
};
