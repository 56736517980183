import { useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import { IntersectionOptions, useInView } from 'react-intersection-observer';

const DEFAULT_OPTIONS = {
  triggerOnce: true,
  threshold: 0.5,
};

export const useOnScrollAnimation = (options?: IntersectionOptions) => {
  const state = useInView({ ...DEFAULT_OPTIONS, ...options });
  const animation = useAnimation();

  useEffect(() => {
    if (!state.inView) {
      return;
    }

    animation.start('visible');
  }, [animation, state.inView]);

  return { ...state, animation };
};
