interface BasicData {
  handle: string;
  name: string;
  website: string;
  country: string;
  establishedAt: string;
}

type DefaultValue = {
  [key: string]: unknown;
};

export function useUpdateCompanyData() {
  const normalizeFormData = (
    data: CompanyBasicsRequest,
    companyData: Company,
  ): CompanyBasicsRequest => {
    return {
      handle: data.handle,
      name: data.name,
      website: `https://${data.website}`,
      country: data.country,
      establishedAt: `${data.establishedAt}-01-01`,
      representativeFirstName: companyData.representativeFirstName,
      representativeLastName: companyData.representativeLastName,
      representativeEmail: companyData.representativeEmail,
      representativePhone: companyData.representativePhone,
    };
  };

  const getInitialValues = (
    data?: Company | CompanyBasicsRequest,
  ): BasicData => {
    return {
      handle: data?.handle || '',
      name: data?.name || '',
      website: data?.website.substring(8) || '',
      country: data?.country || '',
      establishedAt: data?.establishedAt.substring(0, 4) || '',
    };
  };

  const equalityFunction = (
    initial: CompanyBasicData,
    changedValues: DefaultValue,
  ) =>
    initial.handle !== changedValues.handle ||
    initial.name !== changedValues.name ||
    initial.establishedAt !== changedValues.establishedAt ||
    initial.website !== changedValues.website ||
    initial.country !== changedValues.country;

  return {
    normalizeFormData,
    equalityFunction,
    getInitialValues,
  };
}
