import { css } from '@emotion/react';
import { breakpoints, font } from '@prototyp/gatsby-plugin-gumball/utils';

const hover = css`
  &:hover {
    cursor: pointer;
  }
`;

export const root = css`
  background: transparent;
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  margin-top: calc(var(--unit) * 10);
  text-align: center;

  @media ${breakpoints.medium} {
    text-align: left;
  }

  .pagination {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .pagination__btn {
    width: calc(var(--unit) * 10);
    height: calc(var(--unit) * 10);
    min-width: calc(var(--unit) * 10);
    border: 1px solid hsl(var(--color-borderUltraLight-9));
    margin: 0 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    ${hover};
  }

  .pagination__btn--active {
    border-color: hsl(var(--color-borderBlack-2));
  }

  .pagination__next {
    margin-left: calc(var(--unit) * 4);
    padding-left: 2px;
    ${hover};
  }

  .pagination__previous {
    margin-right: calc(var(--unit) * 4);
    padding-right: 2px;
    ${hover}
  }

  .disabled .pagination__previous {
    color: hsl(var(--color-grayscale-6));
    cursor: default;
  }

  .disabled .pagination__next {
    color: hsl(var(--color-grayscale-6));
    cursor: default;
  }

  .break {
    color: hsl(var(--color-grayscale-4));
    padding: 0 var(--unit);
    margin: 0 calc(var(--unit) * 5);
    ${hover};
  }
`;

export const perPage = css`
  border-bottom: 1px solid hsl(var(--color-borderLight-8));
  padding: calc(var(--unit) * 2) 0;
  ${font.size.small};
  border-radius: 0;
  font-weight: var(--font-weight-regular);

  &:hover,
  &:active,
  &:focus {
    border-color: hsl(var(--color-borderDark-5));
  }
`;
