import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';

import * as styles from 'modules/app/styles/Dropdown.styles';

type ItemType = {
  id: string;
  element: JSX.Element | string;
  disabled?: boolean;
};

interface Props {
  onSelect: (id: string) => void;
  setExpanded: (expanded: boolean) => void;
  items: Array<ItemType>;
}

export const SingleMenu: React.FC<Props> = ({
  onSelect,
  setExpanded,
  items,
}) => {
  if (!Boolean(items.length)) {
    return (
      <button type="button" css={styles.dropdownListButton} disabled={true}>
        <FormattedMessage id="shared.noOptions" />
      </button>
    );
  }

  return (
    <>
      {items.map(({ id, element, disabled }) => (
        <button
          type="button"
          key={String(id)}
          css={styles.dropdownListButton}
          onClick={() => {
            onSelect(id);
            setExpanded(false);
          }}
          disabled={disabled}
        >
          {element}
        </button>
      ))}
    </>
  );
};
