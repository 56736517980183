import React, { useState, useEffect, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  SelectComponent,
  Button,
  HttpError,
  Loading,
  useTransformSelect,
  useToastify,
  useFormGuardEffect,
  proficiencyValues,
} from 'modules/app';
import { motion, AnimatePresence } from 'framer-motion';
import { FADE_IN } from 'style/animations.config';
import { TalentSecondLanguage } from './TalentSecondLanguage';
import { useReset } from 'modules/onboarding';
import { ChangeableEffect } from 'modules/profile/components/ChangeableEffect';
import * as styles from 'modules/app/styles/GenericForm.styles';
import { Value } from '../form/SelectComponent';
import { useTalentLanguage } from 'modules/talents';

interface Props {
  languages?: Language[];
  isOnboarding?: boolean;
  currentLanguage?: Language;
  setCurrentLanguages?: React.Dispatch<
    React.SetStateAction<Language[] | undefined>
  >;
  isProfile?: boolean;
  resetFormCounter?: () => void;
}

function transformLanguage(
  value: string | undefined | null,
  selectValues: Value[],
): Value | undefined {
  const valueLabel = selectValues.filter((val) => {
    return val.value === value;
  });
  if (value && selectValues && valueLabel.length !== 0) {
    return {
      value: value,
      label: valueLabel[0].label,
    };
  }
  return undefined;
}

const getInitialValues = (
  data: Language | LanguageRequest | undefined,
): LanguageRequest => {
  return {
    language: data?.language || '',
    proficiency: data?.proficiency || '',
    understandingListening: data?.understandingListening || null,
    understandingReading: data?.understandingReading || null,
    spokenProduction: data?.spokenProduction || null,
    spokenInteraction: data?.spokenInteraction || null,
    writing: data?.writing || null,
  };
};

export const TalentLanguagesForm: React.FC<Props> = ({
  isOnboarding = false,
  currentLanguage,
  setCurrentLanguages,
  isProfile,
  resetFormCounter,
  languages,
}) => {
  const [isSaving, setIsSaving] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isInRequest, setIsInRequest] = useState(false);
  const [isSecondLanguage, setIsSecondLanguage] = useState(false);
  const [languageValues, setLanguageValues] = useState<Value[]>([]);
  const [selectedProficiency, setSelectedProficiency] = useState<
    string | string[]
  >('');
  const [initialData, setInitialData] = useState<LanguageRequest>(() =>
    getInitialValues(currentLanguage),
  );

  const methods = useForm<LanguageRequest>({
    defaultValues: initialData,
  });
  const {
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    setError,
    control,
  } = methods;
  useFormGuardEffect(methods, isOnboarding);

  const formRef = useRef<HTMLFormElement>(null);

  const {
    httpError,
    getLanguageLocally,
    getLanguageDispatch,
    getLanguageConfig,
    addLanguage,
    deleteLanguage,
    updateLanguage,
  } = useTalentLanguage();
  const { toastSuccess } = useToastify();
  const { resetValue, setReset } = useReset();
  const language = transformLanguage(currentLanguage?.language, languageValues);
  const proficiency = useTransformSelect(
    currentLanguage?.proficiency,
    proficiencyValues,
  );

  const resetForm = () => {
    reset();
    setReset();
    setIsSecondLanguage(false);
  };

  const getFormData = (data: LanguageRequest): LanguageRequest => {
    return {
      language: data.language,
      proficiency: data.proficiency,
      understandingListening: data.understandingListening,
      understandingReading: data.understandingReading,
      spokenProduction: data.spokenProduction,
      spokenInteraction: data.spokenInteraction,
      writing: data.writing,
    };
  };

  const handleError = (error: ApiErrorData) => {
    if (error.cause === 'max_number_reached:native_languages') {
      setError('proficiency', {
        type: 'manual',
        message: 'http_errors.max_native_languages',
      });
    } else if (error.cause === 'item_already_exists') {
      setError('language', {
        type: 'manual',
        message: 'http_errors.item_already_exists',
      });
    } else if (error.cause === 'max_number_reached:languages') {
      setError('language', {
        type: 'manual',
        message: 'http_errors.max_languages',
      });
    }

    formRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const gettingLanguages = async () => {
    const response = await getLanguageLocally();
    setIsInRequest(false);

    if (!response) return;

    setCurrentLanguages?.(response.data);
  };

  const proficiencyValidate = (val: string) => {
    if (val !== 'native' || !languages) return true;
    const native = languages?.filter((l) => l.proficiency === 'native');

    if (val === 'native' && native && native.length < 3) return true;
    return 'http_errors.max_native_languages';
  };

  const handleOnboardingCallback = () => {
    resetForm();
    getLanguageDispatch(1, 20, false);
  };

  const handleProfileCallback = () => {
    toastSuccess('shared.pills.successDefaultText');
    gettingLanguages();
    resetFormCounter?.();
  };

  const handleUpdateCallback = (data: LanguageRequest) => {
    toastSuccess('shared.pills.successDefaultText');
    setInitialData(data);
    gettingLanguages();
  };

  const onSubmit = async (data: LanguageRequest) => {
    const model = getFormData(data);

    const response = await addLanguage(
      model,
      isOnboarding,
      isProfile,
      handleOnboardingCallback,
      handleProfileCallback,
    );
    if (!response) return;

    if (response.error) handleError(response.error);
  };

  const onUpdate = async (data: LanguageRequest) => {
    const model = getFormData(data);

    setIsSaving(true);
    setIsInRequest(true);
    const response = await updateLanguage(
      model,
      currentLanguage?.id,
      handleUpdateCallback,
    );
    setIsSaving(false);
    setIsInRequest(false);

    if (response?.error) handleError(response.error);
  };

  const onRemove = async (languageId: string) => {
    setIsInRequest(true);
    await deleteLanguage(languageId);
    setIsInRequest(false);

    gettingLanguages();
  };

  useEffect(() => {
    if (!Boolean(Object.keys(errors).length)) return;

    formRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [errors]);

  useEffect(() => {
    setIsSecondLanguage(false);

    const timeout = setTimeout(() => {
      if (!selectedProficiency) return;
      selectedProficiency === 'native'
        ? setIsSecondLanguage(false)
        : setIsSecondLanguage(true);
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [selectedProficiency, setValue]);

  useEffect(() => {
    if (Boolean(languageValues.length)) return;

    getLanguageConfig().then((response) => {
      if (!response?.languages) return;
      setIsLoading(false);

      setLanguageValues(
        response.languages.map(
          (lang): Value => ({ value: lang.name, label: lang.name }),
        ),
      );
    });
  }, [languageValues]);

  useEffect(() => {
    if (!currentLanguage) return;

    setSelectedProficiency(currentLanguage.proficiency);
  }, [currentLanguage]);

  return (
    <div css={styles.root}>
      <Loading
        isLoading={isLoading}
        component={
          <>
            {Boolean(languageValues.length) && !isLoading && (
              <>
                <FormProvider {...methods}>
                  <form
                    onSubmit={handleSubmit(
                      currentLanguage ? onUpdate : onSubmit,
                    )}
                    ref={formRef}
                  >
                    {currentLanguage && (
                      <ChangeableEffect<LanguageRequest>
                        control={control}
                        equalityFn={(initial, changedValues) => {
                          if (changedValues.proficiency === 'native') {
                            return (
                              initial.language !== changedValues.language ||
                              initial.proficiency !== changedValues.proficiency
                            );
                          }

                          return (
                            initial.language !== changedValues.language ||
                            initial.proficiency !== changedValues.proficiency ||
                            initial.understandingListening !==
                              changedValues.understandingListening ||
                            initial.understandingReading !==
                              changedValues.understandingReading ||
                            initial.spokenProduction !==
                              changedValues.spokenProduction ||
                            initial.spokenInteraction !==
                              changedValues.spokenInteraction ||
                            initial.writing !== changedValues.writing
                          );
                        }}
                        initial={initialData}
                        onChange={setIsSaving}
                      />
                    )}

                    {httpError && !methods.formState.errors && (
                      <HttpError styles={styles.httpError} error={httpError} />
                    )}

                    <div css={styles.subgridForm}>
                      <SelectComponent
                        name="language"
                        label={
                          <FormattedMessage id="onboarding.form.fields.languages.language" />
                        }
                        constraints={{ required: true }}
                        reset={resetValue}
                        values={languageValues}
                        selectedValue={language}
                        isDisabled={currentLanguage ? true : false}
                      />

                      <SelectComponent
                        setSelectedValue={(value) => {
                          setSelectedProficiency(value);
                        }}
                        name="proficiency"
                        label={
                          <FormattedMessage id="onboarding.form.fields.languages.proficiency" />
                        }
                        constraints={{
                          required: true,
                          validate: proficiencyValidate,
                        }}
                        reset={resetValue}
                        selectedValue={proficiency}
                        values={proficiencyValues}
                      />
                    </div>
                    <AnimatePresence exitBeforeEnter>
                      {isSecondLanguage && (
                        <motion.div
                          transition={{ duration: 0.1 }}
                          {...FADE_IN}
                          exit={{ transition: { duration: 0.1 } }}
                        >
                          {currentLanguage ? (
                            <TalentSecondLanguage
                              selected={selectedProficiency}
                              currentLanguage={currentLanguage}
                            />
                          ) : (
                            <TalentSecondLanguage
                              selected={selectedProficiency}
                            />
                          )}
                        </motion.div>
                      )}
                    </AnimatePresence>

                    {currentLanguage ? (
                      <>
                        <Button
                          buttonType="secondary"
                          type="submit"
                          disabled={isSaving}
                        >
                          <FormattedMessage id="profileManagement.heading.buttons.saveChanges" />
                        </Button>
                        <Button
                          buttonType="ghost"
                          onClick={() => {
                            onRemove(currentLanguage.id);
                          }}
                          disabled={isInRequest}
                        >
                          <FormattedMessage id="profileManagement.heading.buttons.delete" />
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          type="submit"
                          disabled={isInRequest}
                          css={styles.submitBtn}
                        >
                          <FormattedMessage id="onboarding.form.fields.languages.save_language" />
                        </Button>
                        {isProfile && resetFormCounter && (
                          <Button
                            css={styles.cancelBtn}
                            buttonType="ghost"
                            onClick={() => {
                              resetFormCounter();
                            }}
                            disabled={isInRequest}
                          >
                            <FormattedMessage id="profileManagement.heading.buttons.cancel" />
                          </Button>
                        )}
                      </>
                    )}
                  </form>
                </FormProvider>
              </>
            )}
          </>
        }
      />
    </div>
  );
};
