import { HttpClient } from 'modules/app/http/client/http';

export class TalentProfileService extends HttpClient<Basics> {
  constructor() {
    super('/api/v1/talent/profile');
  }
}

export class CompanyProfileService extends HttpClient<Company> {
  constructor() {
    super('/api/v1/company');
  }
}
