import React from 'react';
import * as styles from 'modules/openings/styles/Openings.styles';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';
import SeparatorArrow from 'assets/icons/separatorArrow.svg';
import SeparatorLine from 'assets/icons/separatorLine.svg';
import { useDispatch, useSelector } from 'react-redux';
import { applicationsAction } from 'modules/applications';
import { AppState } from 'modules/redux-store';
import { FormattedMessage, navigate } from 'gatsby-plugin-intl';
import { useGracePeriod } from 'modules/app';

function sortPipelines(pipelines: OpeningPipeline[]): OpeningPipeline[] {
  const pre = pipelines.filter((pipeline) => pipeline.flag === 'pre');
  const post = pipelines.filter((pipeline) => pipeline.flag === 'post');
  const custom = pipelines.filter((pipeline) => pipeline.flag === 'custom');

  return [...pre, ...custom, ...post];
}

interface Props {
  data: CompanyOpening;
  isApplication?: boolean;
  currentStage?: string;
  customStyles?: SerializedStyles | SerializedStyles[];
}

export const CompanyPipelineItems: React.FC<Props> = ({
  data,
  isApplication,
  currentStage,
  customStyles,
}) => {
  const dispatch = useDispatch();
  const { isGracePeriod } = useGracePeriod();
  const { companyData } = useSelector((state: AppState) => state.companyData);

  const onClick = (pipeline: OpeningPipeline) => {
    dispatch(applicationsAction.currentStage(pipeline.name));

    if (isApplication) return;

    navigate(
      `/profile/company/openings/${companyData?.handle}/applications/${data.id}`,
    );
  };

  return (
    <div css={[styles.applicationWrapper, customStyles]}>
      <div
        css={
          isGracePeriod
            ? styles.pipelineWrapperDisabled
            : styles.pipelineWrapper
        }
      >
        {sortPipelines(data.pipeline).map((pipeline, index) => (
          <div
            key={pipeline.id}
            css={
              isApplication && currentStage === pipeline.name
                ? styles.companyOpeningsPipelineActive
                : styles.companyOpeningsPipeline
            }
            onClick={() => onClick(pipeline)}
          >
            <div>
              <p
                css={[
                  font.size.tiny,
                  pipeline.name === 'Hired'
                    ? styles.companyOpeningsPipelineTextAlt
                    : styles.companyOpeningsPipelineText,
                ]}
              >
                {pipeline.name}
              </p>
              <p
                css={[
                  font.size.medium,
                  pipeline.name === 'Hired'
                    ? styles.companyOpeningsPipelineNumberAlt
                    : styles.companyOpeningsPipelineNumber,
                ]}
              >
                {pipeline.count}
              </p>
            </div>
            {index > 0 && index < data.pipeline.length - 2 ? (
              <SeparatorArrow />
            ) : (
              <SeparatorLine />
            )}
          </div>
        ))}
      </div>
      {isApplication && (
        <p
          css={currentStage === 'all' ? styles.viewAllActive : styles.viewAll}
          onClick={() => dispatch(applicationsAction.currentStage('all'))}
        >
          <FormattedMessage id="shared.viewAll" />
        </p>
      )}
    </div>
  );
};
