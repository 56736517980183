import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  background-color: white;
  margin: ${spacing(40)} 0;
  padding: calc(var(--unit) * 8);

  @media ${breakpoints.smallMax} {
    margin-left: ${spacing(-20)};
    margin-right: ${spacing(-20)};
  }

  @media ${breakpoints.medium} {
    padding: calc(var(--unit) * 15);
  }
`;
