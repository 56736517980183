import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins/spacing';

export const breadcrumbs = css`
  color: hsl(var(--color-text-1));
  font-size: var(--font-size-small);
  text-transform: capitalize;

  span {
    color: hsl(var(--color-text-1));

    &:first-of-type {
      font-weight: var(--font-weight-bold);
    }
  }

  a {
    color: inherit;
    font-weight: var(--font-weight-bold);

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const arrow = css`
  margin: 0 ${spacing(10)} 0;
`;
