import { useDispatch } from 'react-redux';
import { settingsAction } from 'modules/settings';
import { quickSearchAction } from 'modules/quickSearch';
import { openingsActions } from 'modules/openings';
import { talentOpeningsAction } from 'modules/talentOpenings';
import { pipelinesAction } from 'modules/pipelines';
import { applicationsAction } from 'modules/applications';
import { applicationFlowActions } from 'modules/application';
import { companyDataAction, userAction } from 'modules/profile';
import { authenticationAction } from 'modules/redux-store';
import { registrationActions } from 'modules/register';
import { loginAction } from 'modules/login';
import { impersonateAction } from 'modules/admin';
import {
  companyOnboardingAction,
  talentOnboardingAction,
} from 'modules/onboarding';
import { talentDashboardActions } from 'modules/talentDashboard';
import { useCallback } from 'react';
import { talentMessagesActions } from 'modules/talentMessages';
import { billingActions } from 'modules/billing';
import { companyMessagesActions } from 'modules/companyMessages';
import { evaluationsActions } from 'modules/evaluations';
import { activityActions } from 'modules/activity';

export const useResetReducer = () => {
  const dispatch = useDispatch();

  const resetReducer = useCallback(() => {
    dispatch(authenticationAction.logout());
    dispatch(registrationActions.reset());
    dispatch(loginAction.reset());
    dispatch(talentOnboardingAction.reset());
    dispatch(companyOnboardingAction.reset());
    dispatch(companyDataAction.reset());
    dispatch(settingsAction.reset());
    dispatch(quickSearchAction.reset());
    dispatch(userAction.reset());
    dispatch(openingsActions.reset());
    dispatch(talentOpeningsAction.reset());
    dispatch(pipelinesAction.reset());
    dispatch(applicationsAction.reset());
    dispatch(applicationFlowActions.reset());
    dispatch(talentDashboardActions.reset());
    dispatch(talentMessagesActions.reset());
    dispatch(impersonateAction.reset());
    dispatch(billingActions.reset());
    dispatch(companyMessagesActions.reset());
    dispatch(evaluationsActions.reset());
    dispatch(activityActions.reset());
  }, [dispatch]);

  return resetReducer;
};
