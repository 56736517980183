import React from 'react';
import * as styles from 'modules/admin/styles/AdminOverview.styles';
import * as invoicesTableStyles from 'modules/admin/invoices/styles/InvoicesTable.styles';
import { formatDate } from 'modules/admin/static';

interface Props {
  isLoading: boolean;
  applicants?: Applicant[];
}

export const ApplicantsTable: React.FC<Props> = ({ isLoading, applicants }) => {
  return (
    <table>
      <thead css={styles.tableHead}>
        <tr>
          <td width="300">Applicant name</td>
          <td width="350">Applicant email</td>
          <td width="300">Opening title</td>
          <td width="300">Company name</td>
          <td width="150">Applicated time</td>
        </tr>
      </thead>
      <tbody css={isLoading ? styles.loading : null}>
        {applicants &&
          applicants.map((applicant) => (
            <tr key={applicant.id} css={invoicesTableStyles.root}>
              <td>{`${applicant.firstName} ${applicant.lastName}`}</td>
              <td>{applicant.email}</td>
              <td>{applicant.openingTitle}</td>
              <td>{applicant.companyName}</td>
              <td>{formatDate(applicant.createdAt, 'PP, HH:mm')}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
