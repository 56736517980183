import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { settingsAction } from '../redux';

interface DepartmentRequest {
  name: string;
  parentId: string | null;
}

export const useDepartments = () => {
  const dispatch = useDispatch();

  const [error, setError] = useState<HttpError>();
  const [isLoading, setIsLoading] = useState(false);

  const http = useHttpClient('/api/v1/company');

  async function getDepartments(companyId: string, load = true) {
    load && dispatch(settingsAction.loading());
    try {
      const { data } = await http.get<Department[]>(
        `${companyId}/settings/departments`,
      );
      dispatch(settingsAction.addDepartments(data));
      return data;
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(settingsAction.error(e));
      return e;
    }
  }

  async function getArchivedDepartments(companyId: string, load = true) {
    load && dispatch(settingsAction.loading());
    try {
      const { data } = await http.get<Department[]>(
        `${companyId}/settings/departments/archived`,
      );
      dispatch(settingsAction.addArchivedDepartments(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(settingsAction.error(e));
    }
  }

  async function addDepartment(
    companyId: string,
    info: DepartmentRequest,
    callback: (data: Department) => void,
  ) {
    setIsLoading(true);
    try {
      const { data } = await http.post<Department, DepartmentRequest>(
        `${companyId}/settings/departments`,
        info,
      );
      dispatch(settingsAction.addSingleDepartment(data));
      callback(data);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function updateDepartment(
    companyId: string,
    departmentId: string,
    info: DepartmentRequest,
    callback?: VoidFunction,
  ) {
    setIsLoading(true);
    try {
      const { data } = await http.put<Department, DepartmentRequest>(
        `${companyId}/settings/departments/${departmentId}`,
        info,
      );
      dispatch(settingsAction.updateSingleDepartment(data));
      callback?.();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function archiveDepartment(
    companyId: string,
    departmentId: string,
    callback: VoidFunction,
  ) {
    setIsLoading(true);
    try {
      await http.put(
        `/${companyId}/settings/departments/${departmentId}/archive`,
      );
      getDepartments(companyId, false);
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function deleteDepartment(
    companyId: string,
    departmentId: string,
    callback: VoidFunction,
  ) {
    setIsLoading(true);
    try {
      await http.delete(`${companyId}/settings/departments/${departmentId}`);
      getArchivedDepartments(companyId, false);
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function restoreDepartment(
    companyId: string,
    department: Department,
    callback: VoidFunction,
    parentId?: string,
  ) {
    setIsLoading(true);
    try {
      await http.put(
        `${companyId}/settings/departments/${department.id}/restore`,
        { name: department.name, parent: parentId || department.parentId },
      );
      if (parentId) {
        updateDepartment(companyId, department.id, {
          name: department.name,
          parentId,
        });
      }
      getArchivedDepartments(companyId, false);
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  return {
    error,
    isLoading,
    addDepartment,
    getDepartments,
    updateDepartment,
    deleteDepartment,
    archiveDepartment,
    restoreDepartment,
    getArchivedDepartments,
  };
};
