import React from 'react';
import * as styles from 'modules/app/styles/Loading.styles.css';
import { LoadingImg } from './LoadingImg';
import { AnimatePresence, motion } from 'framer-motion';

interface Props {
  isLoading: boolean;
  component: React.ReactNode;
  altText?: string;
  customStyles?: SerializedStyles;
}

export const Loading: React.FC<Props> = ({
  isLoading,
  component,
  customStyles,
}) => (
  <>
    <>
      {isLoading && (
        <motion.div
          layout="position"
          key="loading"
          css={[styles.root, customStyles ? customStyles : undefined]}
          exit={{ opacity: 0 }}
        >
          <LoadingImg />
        </motion.div>
      )}

      {!isLoading && component}
    </>
  </>
);
