import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';

interface EmploymentTypeValue {
  value: string;
  label: React.ReactElement;
}

export const employmentTypeValues: EmploymentTypeValue[] = [
  {
    value: 'part-time',
    label: (
      <FormattedMessage id="onboarding.form.fields.experience_employment.employment_type_part_time" />
    ),
  },
  {
    value: 'full-time',
    label: (
      <FormattedMessage id="onboarding.form.fields.experience_employment.employment_type_full_time" />
    ),
  },
  {
    value: 'self-employed',
    label: (
      <FormattedMessage id="onboarding.form.fields.experience_employment.employment_type_self" />
    ),
  },
  {
    value: 'freelance',
    label: (
      <FormattedMessage id="onboarding.form.fields.experience_employment.employment_type_freelance" />
    ),
  },
  {
    value: 'contract',
    label: (
      <FormattedMessage id="onboarding.form.fields.experience_employment.employment_type_contract" />
    ),
  },
  {
    value: 'internship',
    label: (
      <FormattedMessage id="onboarding.form.fields.experience_employment.employment_type_internship" />
    ),
  },
  {
    value: 'apprenticeship',
    label: (
      <FormattedMessage id="onboarding.form.fields.experience_employment.employment_type_apprenticeship" />
    ),
  },
  {
    value: 'seasonal',
    label: (
      <FormattedMessage id="onboarding.form.fields.experience_employment.employment_type_seasonal" />
    ),
  },
];
