import React from 'react';

import * as styles from 'modules/app/styles/Heading.styles';
import { SerializedStyles } from '@emotion/react';
import { fontFamily as fontFamilyFn } from 'modules/app/styles/Heading.styles';
import { motion, MotionProps } from 'framer-motion';

interface Props extends MotionProps {
  size: keyof typeof styles;
  css?: SerializedStyles | SerializedStyles[];
  fontFamily?: 'primary' | 'secondary';
  useMotion?: boolean;
}

export const Paragraph: React.FC<Props> = ({
  size,
  fontFamily,
  children,
  css,
  useMotion,
  ...rest
}) => {
  if (useMotion) {
    return (
      <motion.p css={[styles[size], fontFamilyFn(fontFamily), css]} {...rest}>
        {children}
      </motion.p>
    );
  }

  return (
    <p css={[styles[size], fontFamilyFn(fontFamily), css]} {...rest}>
      {children}
    </p>
  );
};
