import { useHttpClient } from 'modules/app';
import { removeWhiteSpace } from 'modules/talents';
import { useState } from 'react';

export function useAssets() {
  const [suggestedTags, setSuggestedTags] = useState<Expertise[]>();

  const assetsService = useHttpClient('/api/v1/assets');

  async function getAllTags(text: string) {
    if (text === '') {
      setSuggestedTags(undefined);
      return;
    }

    try {
      const search = removeWhiteSpace(text)
        .replace('#', '%23')
        .replace('+', '%2B');
      const {
        data: { data },
      } = await assetsService.get<ExpertiseResponse>(`/tags?search=${search}`);

      setSuggestedTags(data);
    } catch (e) {}
  }

  return {
    suggestedTags,
    setSuggestedTags,
    getAllTags,
  };
}
