import React from 'react';
import { Control, Controller } from 'react-hook-form';

import * as styles from 'modules/talentMessages/styles/MessageInput.styles';
import AttachIcon from 'assets/icons/attachment.svg';
import Dropzone, { FileRejection } from 'react-dropzone';

interface Props {
  name: string;
  acceptedFiles: string[];
  maxSize: number;
  control: Control<Record<string, string>>;
  onDrop: (files: File[]) => void;
  setRejectedFiles: (rejected?: FileRejection[]) => void;
  isDisabled: boolean;
}

export const MobileAttachmentInput: React.FC<Props> = ({
  name,
  acceptedFiles,
  maxSize,
  control,
  onDrop,
  setRejectedFiles,
  isDisabled,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Dropzone
          onDrop={onDrop}
          accept={acceptedFiles}
          maxSize={maxSize}
          disabled={isDisabled}
          noKeyboard
          {...field}
        >
          {({ getRootProps, getInputProps, fileRejections }) => {
            if (Boolean(fileRejections.length)) {
              setRejectedFiles(fileRejections);
            } else {
              setRejectedFiles(undefined);
            }

            return (
              <div css={styles.button} {...getRootProps()}>
                <AttachIcon />
                <input {...getInputProps()} type="file" name={name} />
              </div>
            );
          }}
        </Dropzone>
      )}
    />
  );
};
