import React from 'react';
import { motion } from 'framer-motion';
import LoadingImage from 'assets/icons/loading.svg';

import * as styles from 'modules/app/styles/Loading.styles.css';

interface Props {
  customStyles?: SerializedStyles | SerializedStyles[];
}

export const LoadingImg: React.FC<Props> = ({ customStyles }) => {
  return (
    <motion.span
      css={[styles.imageRoot, customStyles]}
      animate={{
        rotateZ: [0, 360],
      }}
      transition={{ duration: 1.2, repeat: Infinity, ease: 'easeInOut' }}
    >
      <LoadingImage />
    </motion.span>
  );
};
