import React from 'react';
import { SectionHead } from 'modules/admin/components';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import {
  Button,
  DropdownMenu,
  SelectComponent,
  SmallLoadingImg,
  TextInput,
} from 'modules/app';
import Search from 'assets/icons/search.svg';
import { motion } from 'framer-motion';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import * as invoicesHeaderStyles from 'modules/admin/invoices/styles/InvoicesHeader.styles';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { FormProvider, useForm } from 'react-hook-form';

interface Props {
  isLoading?: boolean;
  query: ApplicantsQuery;
  setQuery: React.Dispatch<React.SetStateAction<ApplicantsQuery>>;
  handleSearch: (
    e: React.KeyboardEvent<HTMLInputElement> &
      React.ChangeEvent<HTMLInputElement>,
  ) => void;
  handleChangeSort: (option: string) => void;
  handleChangeSortBy: (option: string) => void;
  handleGenerateSearchFilterOptions: (
    options: string[],
    activeFilter: string,
  ) => {
    id: string;
    element: EmotionJSX.Element;
  }[];
}

export const ApplicantsHeader: React.FC<Props> = ({
  isLoading,
  query,
  setQuery,
  handleSearch,
  handleChangeSort,
  handleChangeSortBy,
  handleGenerateSearchFilterOptions,
}) => {
  const form = useForm();
  const { formatMessage } = useIntl();
  const { openings } = useSelector((selector: AppState) => selector.applicants);
  const { applicants } = useSelector(
    (selector: AppState) => selector.applicants,
  );

  const sortOptions = ['DESC', 'ASC'];
  const sortByOptions = ['created_at', 'updated_at', 'first_name', 'last_name'];

  function onResetFilter() {
    setQuery({
      page: 1,
      sort: 'DESC',
      sortBy: 'created_at',
      search: '',
      input: '',
      companyId: '',
      openingIds: '',
    });
  }

  function handleOpeningIdsChange(value: string | string[]) {
    if (typeof value === 'string') return;
    setQuery((prev) => ({
      ...prev,
      openingIds: value.join(','),
    }));
  }

  return (
    <motion.div transition={{ duration: 0.3 }} {...FADE_IN_MOVE_Y_REGULAR}>
      <SectionHead title={`Applicants (${applicants?.total || 0})`}>
        <TextInput
          autoFocus
          value={query.input}
          icon={isLoading ? <SmallLoadingImg /> : <Search />}
          css={invoicesHeaderStyles.search}
          placeholder={formatMessage({
            id: 'admin.applicants.applicantsHeader.input.placeholder',
          })}
          onChange={(e) => setQuery({ ...query, input: e.target.value })}
          onKeyUp={(
            e: React.KeyboardEvent<HTMLInputElement> &
              React.ChangeEvent<HTMLInputElement>,
          ) => handleSearch(e)}
        />

        <FormProvider {...form}>
          <SelectComponent
            isMulti
            name="openings"
            label={formatMessage({
              id: 'admin.applicants.applicantsHeader.multiSelect',
            })}
            onValueChange={handleOpeningIdsChange}
            values={openings.map((opening) => ({
              label: opening.title,
              value: opening.id,
            }))}
            constraints={{ required: true }}
          />
        </FormProvider>

        <div css={invoicesHeaderStyles.searchFilter}>
          <DropdownMenu
            onSelect={(id) => {
              handleChangeSortBy(id);
            }}
            placeholder={
              <p>
                <FormattedMessage id="admin.shared.sortBy" />{' '}
                <strong>{query.sortBy}</strong>
              </p>
            }
            hideCaret={false}
            items={handleGenerateSearchFilterOptions(
              sortByOptions,
              query.sortBy,
            )}
            position="bottomRight"
            customButtonStyles={invoicesHeaderStyles.sortByDropdown}
          />

          <DropdownMenu
            onSelect={(id) => {
              handleChangeSort(id);
            }}
            placeholder={
              <p>
                <FormattedMessage id="admin.shared.sort" />{' '}
                <strong>{query.sort}</strong>
              </p>
            }
            hideCaret={false}
            items={handleGenerateSearchFilterOptions(sortOptions, query.sort)}
            position="bottomRight"
            customButtonStyles={invoicesHeaderStyles.sortByDropdown}
          />
          <Button
            buttonType={'outline'}
            size={'tiny'}
            onClick={onResetFilter}
            disabled={isLoading}
          >
            <FormattedMessage id="admin.shared.resetFilter" />
          </Button>
        </div>
      </SectionHead>
    </motion.div>
  );
};
