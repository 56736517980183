import React, { useEffect } from 'react';

import * as styles from 'modules/app/styles/AppLayout.styles';
import { MainContent, ProfileBar, useCsrfCookie } from 'modules/app';

import LogoGrey from 'assets/icons/logoGrey.svg';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import { navigate } from 'gatsby-plugin-intl';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

interface Props {
  publicGdpr?: PublicGdpr;
  isPublic?: boolean;
}

export const AppLayout: React.FC<Props> = ({
  children,
  publicGdpr,
  isPublic = false,
}) => {
  const { isLoggedIn } = useCsrfCookie();
  const { user } = useSelector((state: AppState) => state.session);

  useEffect(() => {
    if (
      isPublic ||
      !isLoggedIn() ||
      user?.talentOnboardingDone ||
      user?.companyOnboardingDone
    ) {
      return;
    }

    navigate('/app/onboarding');
  }, [isPublic, isLoggedIn, user]);

  return (
    <main css={styles.root}>
      <nav>
        <ProfileBar />
      </nav>

      <section css={styles.content}>{children}</section>
      <footer>
        <MainContent customStyles={styles.footer}>
          <div>
            <LogoGrey />
          </div>

          {publicGdpr && (
            <div css={styles.gdpr}>
              <Link to={`/company-privacy-policy/${publicGdpr.companyHandle}`}>
                <FormattedMessage
                  id="companyPreview.gdpr.companyPolicy"
                  values={{ name: publicGdpr.companyName }}
                />
              </Link>
            </div>
          )}
        </MainContent>
      </footer>
    </main>
  );
};
