import { createReduxStore } from 'modules/redux-store';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

/**
 *
 * @description Provider component that generates the redux store which wraps the whole application
 * @param children root element provided by Gatsby
 */
export const StoreProvider: React.FC = ({ children }) => {
  const { store, persistor } = createReduxStore();

  if (typeof window !== 'undefined') {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {children}
        </PersistGate>
      </Provider>
    );
  }

  return <Provider store={store}>{children}</Provider>;
};
