import { useIntl } from 'gatsby-plugin-intl';

export const useCompanyDescriptionValues = () => {
  const intl = useIntl();

  const sizeValues = [
    {
      value: '0-1',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_size_0',
      }),
    },
    {
      value: '2-10',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_size_1',
      }),
    },
    {
      value: '11-50',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_size_2',
      }),
    },
    {
      value: '51-250',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_size_3',
      }),
    },
    {
      value: '251-500',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_size_4',
      }),
    },
    {
      value: '501-1000',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_size_5',
      }),
    },
    {
      value: '1001-5000',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_size_6',
      }),
    },
    {
      value: '5001-10000',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_size_7',
      }),
    },
    {
      value: '10000+',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_size_8',
      }),
    },
  ];

  const industryValues = [
    {
      value: 'animation',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_0',
      }),
    },
    {
      value: 'automotive',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_1',
      }),
    },
    {
      value: 'banking',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_2',
      }),
    },
    {
      value: 'business-srv',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_3',
      }),
    },
    {
      value: 'creative-srv',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_4',
      }),
    },
    {
      value: 'data-centers',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_5',
      }),
    },
    {
      value: 'ecommerce',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_6',
      }),
    },
    {
      value: 'edu-train-coach',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_7',
      }),
    },
    {
      value: 'energy',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_8',
      }),
    },
    {
      value: 'ent-events',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_9',
      }),
    },
    {
      value: 'fintech',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_10',
      }),
    },
    {
      value: 'gaming-ind',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_11',
      }),
    },
    {
      value: 'gov-org',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_12',
      }),
    },
    {
      value: 'health-care',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_13',
      }),
    },
    {
      value: 'human-resources',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_14',
      }),
    },
    {
      value: 'immersive-env',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_15',
      }),
    },
    {
      value: 'inf-sec',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_16',
      }),
    },
    {
      value: 'it-cons',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_17',
      }),
    },
    {
      value: 'management',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_18',
      }),
    },
    {
      value: 'market-research',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_19',
      }),
    },
    {
      value: 'market-adv',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_20',
      }),
    },
    {
      value: 'media',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_21',
      }),
    },
    {
      value: 'research-dev',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_22',
      }),
    },
    {
      value: 'robotics',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_23',
      }),
    },
    {
      value: 'smart-cities',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_24',
      }),
    },
    {
      value: 'telecommunications',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_25',
      }),
    },
    {
      value: 'product-company',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_26',
      }),
    },
    {
      value: 'it-srv',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_27',
      }),
    },
    {
      value: 'it-support-srv',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_28',
      }),
    },
    {
      value: 'help-desk-it-srv',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_29',
      }),
    },
    {
      value: 'network-sec',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_30',
      }),
    },
    {
      value: 'it-srv-provider',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_31',
      }),
    },
    {
      value: 'cloud-srv',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_32',
      }),
    },
    {
      value: 'data-backup-srv',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_33',
      }),
    },
    {
      value: 'social-media-agcy',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_34',
      }),
    },
    {
      value: 'web-dsgn-srv',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_35',
      }),
    },
    {
      value: 'email-market-srv',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_36',
      }),
    },
    {
      value: 'sw-dev',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_37',
      }),
    },
    {
      value: 'sw-dev-company',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_38',
      }),
    },
    {
      value: 'full-srv-dig-agcy',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_39',
      }),
    },
    {
      value: 'dig-agcy',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_40',
      }),
    },
    {
      value: 'dsgn-company',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_41',
      }),
    },
    {
      value: 'seo',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_42',
      }),
    },
    {
      value: 'web-dsgn-agcy',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_43',
      }),
    },
    {
      value: 'content-agcy',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_industry_44',
      }),
    },
  ];

  const organizationTypeValues = [
    {
      value: 'Self-Employed',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_type_0',
      }),
    },
    {
      value: 'Sole Proprietorship',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_type_1',
      }),
    },
    {
      value: 'Partnership',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_type_2',
      }),
    },
    {
      value: 'Corporation',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_type_3',
      }),
    },
    {
      value: 'Limited Liability Company',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_type_4',
      }),
    },
    {
      value: 'Government Agency',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_type_5',
      }),
    },
    {
      value: 'Privately Held',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_type_6',
      }),
    },
    {
      value: 'Public Company',
      label: intl.formatMessage({
        id: 'onboarding.form.fields.information.organization_type_7',
      }),
    },
  ];

  const teamBuildingFrequencyValues = [
    {
      value: 'weekly',
      label: intl.formatMessage({
        id: 'teamBonding.teamBuildingFrequency.items.weekly',
      }),
    },
    {
      value: 'twice-a-month',
      label: intl.formatMessage({
        id: 'teamBonding.teamBuildingFrequency.items.twiceAMonth',
      }),
    },
    {
      value: 'monthly',
      label: intl.formatMessage({
        id: 'teamBonding.teamBuildingFrequency.items.monthly',
      }),
    },
    {
      value: 'querterly',
      label: intl.formatMessage({
        id: 'teamBonding.teamBuildingFrequency.items.quarterly',
      }),
    },
    {
      value: 'every-6-months',
      label: intl.formatMessage({
        id: 'teamBonding.teamBuildingFrequency.items.every6Months',
      }),
    },
    {
      value: 'once-a-year',
      label: intl.formatMessage({
        id: 'teamBonding.teamBuildingFrequency.items.onceAYear',
      }),
    },
  ];

  const employeeTrainingPrograms = [
    {
      value: 'casual-learning-check-ins',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.employeeTrainingPrograms.items.casualLearningCheckIns',
      }),
    },
    {
      value: 'education-plan',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.employeeTrainingPrograms.items.educationPlan',
      }),
    },
    {
      value: 'certifications',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.employeeTrainingPrograms.items.certifications',
      }),
    },
    { value: 'conferences', label: 'Conferences' },
    {
      value: 'courses',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.employeeTrainingPrograms.items.courses',
      }),
    },
    {
      value: 'devoting-working-to-learning',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.employeeTrainingPrograms.items.devotingWorkingToLearning',
      }),
    },
    {
      value: 'external-training',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.employeeTrainingPrograms.items.externalTraining',
      }),
    },
    {
      value: 'internal-knowledge',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.employeeTrainingPrograms.items.internalKnowledge',
      }),
    },
    {
      value: 'mentoring-and-coaching',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.employeeTrainingPrograms.items.mentoringAndCoaching',
      }),
    },
    {
      value: 'external-lecturers',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.employeeTrainingPrograms.items.externalLecturers',
      }),
    },
    {
      value: 'seminars',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.employeeTrainingPrograms.items.seminars',
      }),
    },
    {
      value: 'workshops',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.employeeTrainingPrograms.items.workshops',
      }),
    },
  ];

  const learningInitiatives = [
    {
      value: 'business-development',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningInitiatives.items.businessDevelopment',
      }),
    },
    {
      value: 'culture-integration',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningInitiatives.items.cultureIntegration',
      }),
    },
    {
      value: 'content-management',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningInitiatives.items.contentManagement',
      }),
    },
    {
      value: 'domain-specific',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningInitiatives.items.domainSpecific',
      }),
    },
    {
      value: 'interpersonal',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningInitiatives.items.interpersonal',
      }),
    },
    {
      value: 'languages',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningInitiatives.items.languages',
      }),
    },
    {
      value: 'leadership',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningInitiatives.items.leadership',
      }),
    },
    {
      value: 'marketing',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningInitiatives.items.marketing',
      }),
    },
    {
      value: 'project-management',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningInitiatives.items.projectManagement',
      }),
    },
    {
      value: 'product-management',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningInitiatives.items.productManagement',
      }),
    },
    {
      value: 'marketing-and-sales',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningInitiatives.items.marketingAndSales',
      }),
    },
  ];

  const learningActivity = [
    {
      value: 'overall-employee-performance',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningActivity.items.overallEmployeePerformance',
      }),
    },
    {
      value: 'performance-indicators',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningActivity.items.performanceIndicators',
      }),
    },
    {
      value: 'one-on-one-feedback',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningActivity.items.oneOnOneFeedback',
      }),
    },
    {
      value: 'formal-tests',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningActivity.items.formalTests',
      }),
    },
    {
      value: 'improved-bussines-results',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningActivity.items.improvedBussinesResults',
      }),
    },
    {
      value: 'productivity-and-quality',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningActivity.items.productivityAndQuality',
      }),
    },
    {
      value: 'informal-feedback',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningActivity.items.informalFeedback',
      }),
    },
    {
      value: 'post-training-evaluations',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningActivity.items.postTrainingEvaluations',
      }),
    },
    {
      value: 'official-certifications',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningActivity.items.officialCertifications',
      }),
    },
    {
      value: 'observation',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningActivity.items.observation',
      }),
    },
    {
      value: 'self-assessment-questionnaires',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningActivity.items.selfAssessmentQuestionnaires',
      }),
    },
    {
      value: 'not-applicable',
      label: intl.formatMessage({
        id: 'trainingAndDevelopment.learningActivity.items.notApplicable',
      }),
    },
  ];

  const careerGrowPath = [
    {
      value: 'developed',
      label: intl.formatMessage({
        id: 'careerPath.careerGrowthPath.items.developed',
      }),
    },
    {
      value: 'not-developed',
      label: intl.formatMessage({
        id: 'careerPath.careerGrowthPath.items.notDeveloped',
      }),
    },
  ];

  const personInCharge = [
    {
      value: 'ceo',
      label: intl.formatMessage({
        id: 'careerPath.personInCharge.items.ceo',
      }),
    },
    {
      value: 'cto',
      label: intl.formatMessage({ id: 'careerPath.personInCharge.items.cto' }),
    },
    {
      value: 'coo',
      label: intl.formatMessage({ id: 'careerPath.personInCharge.items.coo' }),
    },
    {
      value: 'respective-superior',
      label: intl.formatMessage({
        id: 'careerPath.personInCharge.items.respectiveSuperior',
      }),
    },
    {
      value: 'hr-dept',
      label: intl.formatMessage({
        id: 'careerPath.personInCharge.items.hrDept',
      }),
    },
    {
      value: 'employee',
      label: intl.formatMessage({
        id: 'careerPath.personInCharge.items.employee',
      }),
    },
    {
      value: 'N/A',
      label: intl.formatMessage({ id: 'careerPath.personInCharge.items.na' }),
    },
  ];

  const topics = [
    {
      value: 'career-development',
      label: intl.formatMessage({
        id: 'careerPath.topics.items.careerDevelopment',
      }),
    },

    {
      value: 'challenges-since-meeting',
      label: intl.formatMessage({
        id: 'careerPath.topics.items.challengesSinceMeeting',
      }),
    },
    {
      value: 'engagement-motivation-satisfaction',
      label: intl.formatMessage({
        id: 'careerPath.topics.items.engagementMotivationSatisfaction',
      }),
    },
    {
      value: 'culture-alignment-check-in',
      label: intl.formatMessage({
        id: 'careerPath.topics.items.cultureAlignmentCheckIn',
      }),
    },
    {
      value: 'tasks-responsibilities',
      label: intl.formatMessage({
        id: 'careerPath.topics.items.tasksResponsibilities',
      }),
    },
    {
      value: 'kpi-monitoring-review',
      label: intl.formatMessage({
        id: 'careerPath.topics.items.kpiMonitoringReview',
      }),
    },
    {
      value: 'givig-recieving-feedback',
      label: intl.formatMessage({
        id: 'careerPath.topics.items.givigRecievingFeedback',
      }),
    },
    {
      value: 'short-long-term-goals',
      label: intl.formatMessage({
        id: 'careerPath.topics.items.shortLongTermGoals',
      }),
    },
    {
      value: 'ways-to-improve',
      label: intl.formatMessage({
        id: 'careerPath.topics.items.waysToImprove',
      }),
    },
    {
      value: 'improvement-opportunities',
      label: intl.formatMessage({
        id: 'careerPath.topics.items.improvementOpportunities',
      }),
    },
    {
      value: 'work-life-balance',
      label: intl.formatMessage({
        id: 'careerPath.topics.items.workLifeBalance',
      }),
    },
  ];

  const sessionFrequency = [
    {
      value: 'daily',
      label: intl.formatMessage({
        id: 'careerPath.sessionFrequency.items.daily',
      }),
    },
    {
      value: 'weekly',
      label: intl.formatMessage({
        id: 'careerPath.sessionFrequency.items.weekly',
      }),
    },
    {
      value: 'monthly',
      label: intl.formatMessage({
        id: 'careerPath.sessionFrequency.items.monthly',
      }),
    },
    {
      value: 'querterly',
      label: intl.formatMessage({
        id: 'careerPath.sessionFrequency.items.quarterly',
      }),
    },
    {
      value: 'every-6-months',
      label: intl.formatMessage({
        id: 'careerPath.sessionFrequency.items.every6Months',
      }),
    },
    {
      value: 'once-a-year',
      label: intl.formatMessage({
        id: 'careerPath.sessionFrequency.items.onceAYear',
      }),
    },
  ];

  const hrSessionConductor = [
    {
      value: 'external',
      label: intl.formatMessage({
        id: 'careerPath.hrSessionConductor.items.external',
      }),
    },
    {
      value: 'company-hr',
      label: intl.formatMessage({
        id: 'careerPath.hrSessionConductor.items.companyHr',
      }),
    },
  ];

  const leadersSessionConductor = [
    {
      value: 'company-expert',
      label: intl.formatMessage({
        id: 'careerPath.leadersSessionConductor.items.companyExpert',
      }),
    },
    {
      value: 'external-expert',
      label: intl.formatMessage({
        id: 'careerPath.leadersSessionConductor.items.externalExpert',
      }),
    },
    {
      value: 'company-dept-head',
      label: intl.formatMessage({
        id: 'careerPath.leadersSessionConductor.items.companyDeptHead',
      }),
    },
    {
      value: 'company-mgmt',
      label: intl.formatMessage({
        id: 'careerPath.leadersSessionConductor.items.companyMgmt',
      }),
    },
    {
      value: 'superior',
      label: intl.formatMessage({
        id: 'careerPath.leadersSessionConductor.items.superior',
      }),
    },
  ];

  const typeOfWorkplace = [
    {
      value: 'remote',
      label: intl.formatMessage({
        id: 'openings.typeOfWorkplace.items.remote',
      }),
    },
    {
      value: 'fieldwork',
      label: intl.formatMessage({
        id: 'openings.typeOfWorkplace.items.fieldWork',
      }),
    },
    {
      value: 'office-work',
      label: intl.formatMessage({
        id: 'openings.typeOfWorkplace.items.officeWork',
      }),
    },
    {
      value: 'hybrid',
      label: intl.formatMessage({
        id: 'openings.typeOfWorkplace.items.hybrid',
      }),
    },
  ];

  const partTimePeriod = [
    {
      value: 'day',
      label: intl.formatMessage({
        id: 'openings.partTimeWorkingHours.items.day',
      }),
    },
    {
      value: 'Week',
      label: intl.formatMessage({
        id: 'openings.partTimeWorkingHours.items.week',
      }),
    },
    {
      value: 'Month',
      label: intl.formatMessage({
        id: 'openings.partTimeWorkingHours.items.month',
      }),
    },
  ];

  const contractType = [
    {
      value: 'student',
      label: intl.formatMessage({
        id: 'openings.contractType.items.student',
      }),
    },
    {
      value: 'internship',
      label: intl.formatMessage({
        id: 'openings.contractType.items.internship',
      }),
    },
    {
      value: 'temporary',
      label: intl.formatMessage({
        id: 'openings.contractType.items.temporary',
      }),
    },
    {
      value: 'indefinite',
      label: intl.formatMessage({
        id: 'openings.contractType.items.indefinite',
      }),
    },
  ];

  const temporaryProbationPeriod = [
    {
      value: 'with-probation',
      label: intl.formatMessage({
        id: 'openings.temporaryProbationPeriod.items.withProbationPeriod',
      }),
    },
    {
      value: 'without-probation',
      label: intl.formatMessage({
        id: 'openings.temporaryProbationPeriod.items.withoutProbationPeriod',
      }),
    },
  ];

  const careerLevel = [
    {
      value: 'internship',
      label: intl.formatMessage({
        id: 'openings.careerLevel.items.internship',
      }),
    },
    {
      value: 'novice',
      label: intl.formatMessage({
        id: 'openings.careerLevel.items.novice',
      }),
    },
    {
      value: 'junior-level',
      label: intl.formatMessage({
        id: 'openings.careerLevel.items.juniorLevel',
      }),
    },
    {
      value: 'middle-level',
      label: intl.formatMessage({
        id: 'openings.careerLevel.items.middleLevel',
      }),
    },
    {
      value: 'senior-level',
      label: intl.formatMessage({
        id: 'openings.careerLevel.items.seniorLevel',
      }),
    },
    {
      value: 'executive',
      label: intl.formatMessage({
        id: 'openings.careerLevel.items.executive',
      }),
    },
    {
      value: 'N/A',
      label: intl.formatMessage({
        id: 'openings.careerLevel.items.n/a',
      }),
    },
  ];

  const workingHoursData = [
    {
      value: 'full-time',
      label: intl.formatMessage({
        id: 'openings.workingHours.items.fullTime',
      }),
    },
    {
      value: 'part-time',
      label: intl.formatMessage({
        id: 'openings.workingHours.items.partTime',
      }),
    },
  ];

  return {
    sizeValues,
    industryValues,
    organizationTypeValues,
    teamBuildingFrequencyValues,
    employeeTrainingPrograms,
    learningInitiatives,
    learningActivity,
    careerGrowPath,
    personInCharge,
    topics,
    hrSessionConductor,
    sessionFrequency,
    leadersSessionConductor,
    typeOfWorkplace,
    contractType,
    temporaryProbationPeriod,
    careerLevel,
    partTimePeriod,
    workingHoursData,
  };
};
