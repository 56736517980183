import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { FormattedMessage } from 'react-intl';
import { CheckboxField, CheckboxGroup, Label, TextEditor } from 'modules/app';
import React from 'react';
import * as styles from 'modules/onboarding/styles/Basics.styles';
import { useCompanyDescriptionValues } from '../../consts';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';

interface Props {
  companyData: Company;
  showAdditionalQuestions: boolean;
}

export const TrainingAndDevelopmentInputs: React.FC<Props> = ({
  companyData,
  showAdditionalQuestions,
}) => {
  const { employeeTrainingPrograms, learningInitiatives, learningActivity } =
    useCompanyDescriptionValues();
  return (
    <>
      <div css={margin.bottom.xxxlrg}>
        <CheckboxGroup
          groupLabel="trainingAndDevelopment.employeeTrainingPrograms.label"
          groupName="employeeTrainingPrograms"
          data={employeeTrainingPrograms}
          selectedValues={companyData.employeeTrainingPrograms}
        />
      </div>

      <div css={margin.bottom.xxxlrg}>
        <Label
          label={
            <FormattedMessage id="trainingAndDevelopment.employeeLearningPrograms.label" />
          }
          labelStyle
          customStyles={margin.bottom.med}
        />
        <CheckboxField
          name="employeeLearningPrograms"
          checked={companyData.employeeLearningPrograms}
          constraints={{}}
        >
          <FormattedMessage id="trainingAndDevelopment.employeeLearningPrograms.item" />
        </CheckboxField>
      </div>

      {showAdditionalQuestions && (
        <motion.div {...FADE_IN_MOVE_Y_REGULAR}>
          <div css={margin.bottom.xxxlrg}>
            <CheckboxGroup
              groupLabel="trainingAndDevelopment.learningInitiatives.label"
              groupName="learningInitiatives"
              data={learningInitiatives}
              selectedValues={companyData.learningInitiatives}
              constraints={{ required: showAdditionalQuestions, maxLength: 3 }}
            />
          </div>

          <div css={margin.bottom.xxxlrg}>
            <CheckboxGroup
              groupLabel="trainingAndDevelopment.learningActivity.label"
              groupName="learningActivity"
              data={learningActivity}
              selectedValues={companyData.learningActivity}
              constraints={{ required: showAdditionalQuestions, maxLength: 3 }}
              resetValue="not-applicable"
            />
          </div>
        </motion.div>
      )}

      <div css={styles.textEditorWrapper}>
        <TextEditor
          label="trainingAndDevelopment.continuousLearning.label"
          name="continuousLearning"
          defaultValue={companyData.continuousLearning || undefined}
          constraints={{
            required: false,
            maxLength: 1000,
          }}
          maxChar={1000}
          placeholder="trainingAndDevelopment.continuousLearning.placeholder"
        />
      </div>
    </>
  );
};
