import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

export function useFormResetValues<Model>(form: UseFormReturn<Model>) {
  const {
    getValues,
    reset,
    formState: { isSubmitSuccessful },
  } = form;

  useEffect(() => {
    if (!isSubmitSuccessful) return;
    reset(getValues(), {
      keepDirty: false,
      keepIsSubmitted: false,
      keepDefaultValues: false,
      keepValues: false,
    });
  }, [isSubmitSuccessful]);
}
