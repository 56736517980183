import { css } from '@emotion/react';

export const textFaded = css`
  color: hsl(var(--color-borderDark-5));
  font-size: var(--font-size-small);
`;

export const textCapitalize = css`
  text-transform: capitalize;
`;

export const statusLoading = css`
  opacity: 0.6;
  pointer-events: none;
  transition: 0.2s;
`;
