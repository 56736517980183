import { companyOnboardingAction, useCompanyId } from 'modules/onboarding';
import { useHttpClient, useToastify } from 'modules/app';
import { useDispatch } from 'react-redux';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';

export const useOnboardingLocations = () => {
  const dispatch = useDispatch();
  const companyId = useCompanyId();
  const { toastError, toastSuccess } = useToastify();

  const [error, setError] = useState<HttpError>();

  const http = useHttpClient('/api/v1/company');

  async function getLocations(page: number, load = true) {
    if (!companyId) return;
    load && dispatch(companyOnboardingAction.loading());

    try {
      const { data } = await http.get<CompanyLocationResponse>(
        `${companyId}/profile/locations`,
        { params: { page, perPage: 20 } },
      );
      const locationsList: ListingData[] = data.data.map((item) => ({
        title: item.address,
        content: item.additionalInfo,
        id: item.id,
        headquarters: item.isHeadquarters,
      }));

      dispatch(companyOnboardingAction.setListArray(locationsList));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(companyOnboardingAction.error(e));
    }
  }

  async function deleteLocation(locationId: string) {
    if (!companyId) return;

    try {
      await http.delete(`${companyId}/profile/locations/${locationId}`);
      getLocations(1, false);
    } catch (e) {
      toastError();
    }
  }

  async function addLocation(
    info: CompanyLocationRequest,
    callback: VoidFunction,
  ) {
    try {
      await http.post<Location, CompanyLocationRequest>(
        `${companyId}/profile/locations`,
        info,
      );
      callback();
      getLocations(1, false);
      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    } finally {
    }
  }

  return {
    error,
    addLocation,
    getLocations,
    deleteLocation,
  };
};
