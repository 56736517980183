import React, { useState, useEffect } from 'react';
import { useFormContext, Controller, RegisterOptions } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'gatsby-plugin-intl';

import * as styles from 'modules/app/styles/TextArea.styles';
import { Label } from 'modules/app';
import { FormError } from '../../intl';

interface Props {
  name: string;
  placeholder?: string;
  value: string;
  topLabel?: string;
  bottomLabel?: string;
  onValueChange?: (text: string) => void;
  customStyles?: SerializedStyles;
  constraints?: RegisterOptions;
  reset?: number;
  isOptional?: boolean;
  maxChar?: string;
  readOnly?: boolean;
  altLabel?: string;
}

export const TextArea: React.FC<Props> = ({
  name,
  placeholder,
  topLabel,
  bottomLabel,
  value,
  onValueChange,
  customStyles,
  constraints,
  isOptional = false,
  reset,
  maxChar,
  readOnly,
  altLabel,
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const intl = useIntl();
  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [value]);

  useEffect(() => {
    if (Number.isInteger(reset) && reset !== 0) {
      setVal('');
    }
  }, [reset]);

  return (
    <div
      css={[
        styles.root,
        customStyles ? customStyles : undefined,
        errors[name] ? styles.error : undefined,
      ]}
    >
      {topLabel && (
        <Label
          label={<FormattedMessage id={topLabel} />}
          isOptional={isOptional}
          maxChar={maxChar}
          altText={altLabel}
        />
      )}
      <Controller
        control={control}
        name={name}
        rules={constraints}
        render={({ field: { onChange } }) => (
          <textarea
            readOnly={readOnly}
            name={name}
            value={val}
            placeholder={
              placeholder
                ? intl.formatMessage({
                    id: placeholder,
                  })
                : undefined
            }
            onChange={(e) => {
              onChange(e.target.value);
              setVal(e.target.value);

              onValueChange?.(e.target.value);
            }}
          />
        )}
      />
      {bottomLabel && (
        <Label
          label={<FormattedMessage id={bottomLabel} />}
          isOptional={isOptional}
        />
      )}

      <FormError
        errors={errors}
        name={name}
        values={{
          max: constraints?.maxLength?.value,
        }}
      />
    </div>
  );
};
