import { ActionUnion } from 'modules/redux-store';
import { adminBillingActivityActions } from './actions';

interface InvoicesInitState {
  isLoading: boolean;
  error?: ApiErrorData;
  billingActivity?: AdminBillingActivityResponse;
}

const INITIAL_STATE: InvoicesInitState = {
  isLoading: false,
};

export const adminBillingActivityReducer = (
  state: InvoicesInitState = INITIAL_STATE,
  action: ActionUnion<typeof adminBillingActivityActions>,
): InvoicesInitState => {
  switch (action.type) {
    case 'adminBillingActivity/LOADING':
      return {
        ...state,
        isLoading: true,
      };

    case 'adminBillingActivity/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case 'adminBillingActivity/SET_ADMIN_BILLING_ACTIVITY':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        billingActivity: action.payload,
      };

    case 'adminBillingActivity/RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
