import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import * as styles from 'modules/admin/styles/Sidebar.styles';
import { useMatch } from '@reach/router';
import { FADE_IN_MOVE_Y_REGULAR } from '../../../style/animations.config';
import { motion } from 'framer-motion';

interface Props {
  routes: AdminRoutes[];
}

export const AdminSidebar: React.FC<Props> = ({ routes }) => {
  const IsMatch = (route: string) => useMatch(route);

  return (
    <div css={[styles.sidebar, styles.subSidebar]}>
      <div css={styles.stickyNavigation}>
        <div css={styles.sidebar__navigation}>
          <motion.section {...FADE_IN_MOVE_Y_REGULAR}>
            {routes.map((route) => (
              <Link
                key={route.to}
                to={route.to}
                className={IsMatch(`/:lang${route.to}`) ? 'active' : ''}
              >
                {route.content}
              </Link>
            ))}
          </motion.section>
        </div>
      </div>
    </div>
  );
};
