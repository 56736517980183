import React, { HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';

import BlackInfo from 'assets/icons/blackInfo.svg';
import BlackCross from 'assets/icons/blackCross.svg';
import WarningBlack from 'assets/icons/warn_black.svg';
import WarningWhite from 'assets/icons/warn_white.svg';

import * as styles from 'modules/app/styles/SuccessPill.styles';

interface Props extends HTMLAttributes<HTMLDivElement> {
  type: 'success' | 'warning' | 'error';
  text?: string;
  title?: string;
  onClose?: () => void;
}

export const Pill: React.FC<Props> = ({
  text,
  title,
  onClose,
  type,
  ...rest
}) => {
  return (
    <div css={[styles.root, styles[type]]} {...rest}>
      <div css={styles.content}>
        <div css={styles.title}>
          {type === 'success' && <BlackInfo />}
          {type === 'warning' && <WarningBlack />}
          {type === 'error' && <WarningWhite />}

          <span>
            <FormattedMessage
              id={title ? title : 'shared.pills.successDefaultTitle'}
            />
          </span>
        </div>

        <span>
          <FormattedMessage
            id={text ? text : 'shared.pills.successDefaultText'}
          />
        </span>
      </div>

      {onClose && (
        <span onClick={onClose} css={styles.closeBtn}>
          <BlackCross />
        </span>
      )}
    </div>
  );
};
