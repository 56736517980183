import { useHttpClient, useToastify } from 'modules/app';
import { useState } from 'react';

export const useResetAdminPassword = () => {
  const adminUsersService = useHttpClient('/admin/users');

  const [isLoading, setIsLoading] = useState(false);
  const { toastError, toastSuccess } = useToastify();

  async function requestResetPassword(userId: string) {
    setIsLoading(true);

    try {
      await adminUsersService.post(`/${userId}/reset-password`);
      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      toastError();
    }
    setIsLoading(false);
  }

  return {
    isLoading,
    requestResetPassword,
  };
};
