import { css } from '@emotion/react';
import { flex } from '@prototyp/gatsby-plugin-gumball/utils';
import { ellipsis } from 'style/global.styles';

export const root = css`
  ${flex.display.flex};
  ${flex.justify.between};
  ${flex.align.center};
  padding: calc(var(--unit) * 2) 0;
  border-bottom: 1px solid hsl(var(--color-textLight-10));
  cursor: pointer;
  margin: calc(var(--unit) * 8) 0 calc(var(--unit) * 8);

  p {
    ${ellipsis}
  }

  svg {
    filter: brightness(100);
    display: block;
    width: calc(var(--unit) * 5);
    height: calc(var(--unit) * 5);
  }
`;
