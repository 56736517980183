import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as styles from 'modules/app/styles/TextInput.styles';
import { PrimitiveType } from 'intl-messageformat';

interface Props {
  message: string | undefined;
  values?: Record<string, PrimitiveType>;
}

export const GenericError: React.FC<Props> = ({ message, values }) => {
  return (
    <>
      {message && (
        <span css={styles.fieldError}>
          <FormattedMessage id={message} values={values} />
        </span>
      )}
    </>
  );
};
