export const sortByOptions = ['registered_at', 'name', 'handle'];
export const sortOptions: Sort[] = ['DESC', 'ASC'];
const searchByOptions: CompaniesSearchBy[] = ['name', 'handle'];

export function isCompanySortBy(
  value: string | CompaniesSortBy,
): value is CompaniesSortBy {
  return sortByOptions.includes(value as CompaniesSortBy);
}

export function isCompanySort(value: string | Sort): value is Sort {
  return sortOptions.includes(value as Sort);
}

export function isCompanySearchBy(
  value: string | CompaniesSearchBy,
): value is CompaniesSearchBy {
  return searchByOptions.includes(value as CompaniesSearchBy);
}
