export enum StatusCodes {
  OK = 200,
  BAD_REQUEST = 400,
  UNPROCESSABLE_ENTITY = 422,
  TOO_MANY_REQUESTS = 429,
  SERVER_ERROR = 500,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  GONE = 410,
  FORBIDDEN = 403,
}
