import { css } from '@emotion/react';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  margin-left: calc(var(--unit) * 8);

  &:first-of-type {
    margin-left: 0;

    @media ${breakpoints.large} {
      margin-left: calc(var(--unit) * 8);
    }
  }

  a {
    color: hsl(var(--color-white-11));
  }

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: hsl(var(--color-grayscale-1));
    transition: all 0.3s ease-out;
  }

  :hover {
    &:after {
      width: 100%;
    }
  }
`;

export const active = css`
  ${root};

  &:after {
    width: 100%;
  }
`;
