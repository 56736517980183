import { makeBaseImagePath, useHttpClient, useToastify } from 'modules/app';
import { useCallback, useState } from 'react';
import { FileError } from 'react-dropzone';

export const useMetaImageUpload = (
  onImageUpload: (img: string) => void,
  currentImage?: string,
) => {
  const { toastError, toastSuccess } = useToastify();
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const metaImage = currentImage ? makeBaseImagePath(currentImage) : undefined;
  const [image, setImage] = useState(metaImage);
  const [isFileLarge, setIsFileLarge] = useState(false);
  const [isWrongFormat, setIsWrongFormat] = useState(false);
  const isError = isFileLarge || isWrongFormat;

  const http = useHttpClient('/api/v1/company');

  const handleError = useCallback((errors: FileError[][]) => {
    if (!Boolean(errors.length)) {
      setIsFileLarge(false);
      setIsWrongFormat(false);

      return;
    }

    const error = errors[0].map((error) => error.code);

    if (error.includes('file-too-large')) {
      setIsFileLarge(true);
    }

    if (error.includes('file-invalid-type')) {
      setIsWrongFormat(true);
    }
  }, []);

  const onDrop = (files: File[]) => {
    const file = files[0];

    if (!file) {
      return;
    }

    const bufReader = new FileReader();
    bufReader.readAsArrayBuffer(file);

    bufReader.onloadend = () => {
      const arr = new Uint8Array(bufReader.result as ArrayBuffer);
      const str = arr.reduce((data, byte) => {
        return data + String.fromCharCode(byte);
      }, '');

      const base64 = btoa(str);
      const img = `data:${file.type};base64,${base64}`;
      onImageUpload(img);
    };

    setImage(URL.createObjectURL(file));
  };

  const onUpdate = async (
    files: File[],
    companyId: string,
    openingId: string,
  ) => {
    const file = files[0];

    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('image', file);

    setIsUpdateLoading(true);

    try {
      const { data } = await http.put<string, FormData>(
        `${companyId}/openings/${openingId}/meta-image`,
        formData,
      );
      setImage(makeBaseImagePath(data));
      toastSuccess('openings.metaImage.updateSuccess');
    } catch (e) {
      toastError();
    } finally {
      setIsUpdateLoading(false);
    }
  };

  const deleteImage = async (companyId?: string, openingId?: string) => {
    if (!companyId || !openingId) {
      return;
    }

    setIsDeleteLoading(true);

    try {
      await http.delete(`${companyId}/openings/${openingId}/meta-image`);
      setImage(undefined);
      toastSuccess('openings.metaImage.deleteSuccess');
    } catch (e) {
      toastError();
    } finally {
      setIsDeleteLoading(false);
    }
  };

  return {
    image,
    onDrop,
    handleError,
    isFileLarge,
    isWrongFormat,
    isError,
    onUpdate,
    isUpdateLoading,
    deleteImage,
    isDeleteLoading,
  };
};
