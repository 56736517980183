import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { talentOnboardingAction } from 'modules/onboarding';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

export function useTalentCertificates() {
  const dispatch = useDispatch();
  const { toastSuccess } = useToastify();

  const [httpError, setHttpError] = useState<HttpError>();

  const talentProfileService = useHttpClient<Basics>('/api/v1/talent/profile');

  async function getCertificateLocally(currentPage = 1, perPage?: number) {
    try {
      const {
        data: { data, lastPage, page },
      } = await talentProfileService.get<CertificateResponse>('/certificates', {
        params: { currentPage, perPage: perPage || 15 },
      });

      setHttpError(undefined);
      return { data, lastPage, page };
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  async function getCertificateDispatch(currentPage: number, perPage?: number) {
    try {
      const {
        data: { data },
      } = await talentProfileService.get<CertificateResponse>('/certificates', {
        params: { currentPage, perPage: perPage || 20 },
      });

      if (!data) return;

      const certificatesList: ListingData[] = data.map((item) => ({
        title: item.name,
        content: item.issuingOrganization,
        id: item.id,
      }));

      dispatch(talentOnboardingAction.setListArray(certificatesList));
      setHttpError(undefined);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  async function addCertificate(
    requestData: CertificateRequest,
    isOnboarding?: boolean,
  ) {
    try {
      await talentProfileService.post<Certificate, CertificateRequest>(
        '/certificates',
        requestData,
      );

      setHttpError(undefined);
      if (isOnboarding) getCertificateDispatch(1);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  async function updateCertificate(
    requestData: CertificateRequest,
    certificateId: string,
  ) {
    try {
      await talentProfileService.put<Certificate, CertificateRequest>(
        `/certificates/${certificateId}`,
        requestData,
      );
      setHttpError(undefined);
      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  async function deleteCertificate(certificateId: string) {
    try {
      await talentProfileService.delete(`/certificates/${certificateId}`);
      setHttpError(undefined);
      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  return {
    httpError,
    addCertificate,
    updateCertificate,
    deleteCertificate,
    getCertificateLocally,
    getCertificateDispatch,
  };
}
