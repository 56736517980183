import { RouteComponentProps, useLocation } from '@reach/router';
import React from 'react';
import { UsersDetail, UsersOverview } from '.';

export const Users: React.FC<RouteComponentProps> = () => {
  const { pathname } = useLocation();
  const defaultPathname = '/en/admin/users/';

  return pathname.includes('data-copy-requests') ||
    pathname === defaultPathname ? (
    <UsersOverview />
  ) : (
    <UsersDetail />
  );
};
