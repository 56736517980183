import { useCallback } from 'react';
import Cookies from 'universal-cookie';
import { addYears } from 'date-fns';

export const useCookiePolicy = () => {
  const setCookie = useCallback(
    (status: 'accept' | 'decline' | 'necessary') => {
      const currentDate = new Date();
      const cookie = new Cookies();

      cookie.set('acceptCookies', status, {
        path: '/',
        expires: addYears(currentDate, 1),
        secure: true,
      });
    },
    [],
  );

  const getCookie = useCallback(() => {
    const cookie = new Cookies();

    return cookie.get('acceptCookies');
  }, []);

  return {
    setCookie,
    getCookie,
  };
};
