import { RouteComponentProps, useParams } from '@reach/router';
import { HttpError, Loading } from 'modules/app';
import { useCompanyData } from 'modules/profile';
import React from 'react';

import * as styles from 'modules/dashboard/styles/Root.styles';
import { DashboardContent } from './components/DashboardContent';
import Seo from 'modules/app/components/Seo';
import { useIntl } from 'gatsby-plugin-intl';

interface Props extends RouteComponentProps {
  companyHandle?: string;
}

export const CompanyDashboard: React.FC<Props> = ({ companyHandle }) => {
  const { companyData, isLoading, error } = useCompanyData(companyHandle);
  const { formatMessage } = useIntl();
  const params = useParams();

  return (
    <section key={params.companyHandle} css={styles.loadingStyle}>
      {companyData && (
        <Seo
          title={formatMessage(
            { id: 'seoTitle.companyOverview' },
            { company: companyData.name },
          )}
        />
      )}

      <Loading
        isLoading={isLoading}
        customStyles={styles.loadingStyle}
        component={
          companyData && !error ? (
            <DashboardContent companyData={companyData} />
          ) : (
            <HttpError error={error} />
          )
        }
      />
    </section>
  );
};
