import { useHttpClient } from 'modules/app';
import { useState } from 'react';

export const useGetTeamMembers = () => {
  const adminUsersService = useHttpClient('/admin/users');
  const [isLoading, setIsLoading] = useState(false);

  // change number of results per page --> only here
  async function getTeamMembers(
    {
      page = 1,
      perPage = undefined,
      sort = 'DESC',
      sortBy = 'created_at',
      search = '',
      searchBy = '',
      hideDeleted = true,
      hideStaff = false,
      staffRoleId = undefined,
      companyId = undefined,
    }: UsersAttributes,
    handleSetUsers: (data?: Users[]) => void,
  ) {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await adminUsersService.get<AdminResponse<Users>>('', {
        params: {
          page,
          perPage,
          sort,
          sortBy,
          search,
          searchBy,
          hideDeleted,
          hideStaff,
          staffRoleId,
          companyId,
        },
      });
      handleSetUsers(data);
    } catch (e) {}

    setIsLoading(false);
  }

  return {
    isLoading,
    getTeamMembers,
  };
};
