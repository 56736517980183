import React, { useEffect } from 'react';
import Seo from 'modules/app/components/Seo';
import * as sectionStyles from '../styles/Section.styles';
import { useLocation } from '@reach/router';
import { ApplicantsBody, ApplicantsHeader } from './components';
import { useSearchParams } from '../hooks';
import { useApplicants, useApplicantsQueryHandler } from './hooks';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

export const Applicants: React.FC = () => {
  const { search: queryString } = useLocation();
  const queryParams = new URLSearchParams(queryString);

  const urlParams = {
    urlSearch: queryParams.get('search') ?? '',
    urlPage: queryParams.get('page') ?? '1',
    urlSort: queryParams.get('sort') ?? 'DESC',
    urlSortBy: queryParams.get('sortBy') ?? 'created_at',
    urlOpeningIds: queryParams.get('openingIds') ?? '',
    urlCompanyId: queryParams.get('companyId') ?? '',
  };

  const { isLoading } = useSelector(
    (selector: AppState) => selector.applicants,
  );

  const { getApplicants, getOpenings } = useApplicants();
  const { query, setQuery } = useApplicantsQueryHandler(
    getApplicants,
    urlParams,
    queryString,
  );

  const {
    handleSearch,
    handlePageChange,
    handleChangeSortBy,
    handleChangeSort,
    handleGenerateSearchFilterOptions,
  } = useSearchParams<ExtraSearchQueryData>({ query, setQuery });

  useEffect(() => {
    getOpenings();
  }, []);

  return (
    <>
      <Seo title="Applicants" />
      <div css={sectionStyles.section}>
        <ApplicantsHeader
          isLoading={isLoading}
          query={query}
          setQuery={setQuery}
          handleSearch={handleSearch}
          handleChangeSort={handleChangeSort}
          handleChangeSortBy={handleChangeSortBy}
          handleGenerateSearchFilterOptions={handleGenerateSearchFilterOptions}
        />
        <ApplicantsBody query={query} handlePageChange={handlePageChange} />
      </div>
    </>
  );
};
