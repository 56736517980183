import React, { InputHTMLAttributes, useState } from 'react';
import * as styles from 'modules/app/styles/InputButton.styles';
import { margin, padding, text } from '@prototyp/gatsby-plugin-gumball/utils';
import { Label, Value } from 'modules/app';
import { FormattedMessage } from 'gatsby-plugin-intl';

interface Props extends InputHTMLAttributes<Omit<HTMLInputElement, 'type'>> {
  data: Value[];
  name: string;
  selected: string | undefined;
  onValueChange?: (selected: string | undefined) => void;
  enableDeselect?: boolean;
  label: string;
}

export const RadioButtons: React.FC<Props> = ({
  name,
  enableDeselect,
  selected,
  data,
  onValueChange,
  label,
}) => {
  const [selectedValue, setSelectedValue] = useState(selected);

  const onClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const currentValue = event.currentTarget.value;

    if (enableDeselect) {
      setSelectedValue(
        selectedValue === currentValue ? undefined : currentValue,
      );
      onValueChange?.(
        selectedValue === currentValue ? undefined : currentValue,
      );

      return;
    }

    setSelectedValue(currentValue);
    onValueChange?.(currentValue);
  };

  return (
    <>
      <Label
        label={<FormattedMessage id={label} />}
        customStyles={padding.top.xlrg}
        labelStyle
      />
      <div css={[styles.root]}>
        {data.map((item, index) => {
          return (
            <div css={[styles.item, margin.bottom.tny]} key={index}>
              <input
                name={name}
                type="radio"
                css={styles.input}
                defaultChecked={selectedValue === item.value}
                value={item.value}
                id={item.value}
                onClick={onClick}
              />
              <label
                htmlFor={item.value}
                css={[
                  selectedValue === item.value
                    ? styles.inputButtonChecked
                    : styles.inputButton,
                  text.transform.capitalize,
                ]}
              >
                {item.label}
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
};
