import { css } from '@emotion/react';

export const root = css`
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--unit) * 3) 0;
  width: 100%;
`;

export const item = css`
  margin-bottom: calc(var(--unit) * 3);

  &:not(:last-child) {
    margin-right: calc(var(--unit) * 2);
  }
`;

export const input = css`
  display: none;
`;

export const inputButton = css`
  cursor: pointer;
  padding: var(--unit) calc(var(--unit) * 3);
  border: 1px solid hsl(var(--color-borderUltraLight-9));
  border-radius: 3px;

  &:hover {
    border-color: hsl(var(--color-robin-4));
  }
`;

export const inputButtonChecked = css`
  ${inputButton};

  border-color: hsl(var(--color-robin-4));
  background-color: hsl(var(--color-robin-2));
`;

export const labelWrapper = css`
  display: flex;
  justify-content: space-between;
`;
