import React from 'react';
import { Helmet } from 'react-helmet';

import useSiteMetadata from '../../graphql/useSiteMetadata';

interface Props {
  title: string;
  description?: string;
  lang?: string;
  url?: string;
  image?: string;
  canonical?: string;
}

export const Seo: React.FC<Props> = ({
  description,
  lang,
  title,
  url,
  image,
  canonical,
}: Props) => {
  const siteMetadata = useSiteMetadata();
  const metaDescription = description ?? siteMetadata.description;
  const metaImage = image || siteMetadata.image;

  return (
    <>
      <Helmet htmlAttributes={{ lang }}>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />

        <title>{title}</title>
        <meta name="description" content={metaDescription} />

        <meta property="og:title" content={title} />
        <meta property="og:site_name" content="Roango" />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImage} />
      </Helmet>

      {url && (
        <Helmet>
          <meta property="og:url" content={url} />
          <meta name="twitter:url" content={url} />
        </Helmet>
      )}

      {(canonical || url) && (
        <Helmet>
          <link rel="canonical" href={canonical || url} />
        </Helmet>
      )}
    </>
  );
};
