import { useLocation, navigate as reachNavigate } from '@reach/router';
import { navigate } from 'gatsby-plugin-intl';
import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { billingActions } from '../redux';

type ButtonType = 'purchase' | 'upgrade' | 'downgrade';

type HiddenForm = Omit<RedirectType, 'preconditions'>;

export const useHandlePlan = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const [isModal, setIsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiErrorData>();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [hiddenForm, setHiddenForm] = useState<HiddenForm>();

  const http = useHttpClient('/api/v1/company/');

  function onPlanChoose(planId: string, type: ButtonType, handle: string) {
    if (type === 'purchase') {
      navigate(
        `/profile/settings/company/${handle}/billing/billing-data?planId=${planId}`,
      );
      return;
    }

    setIsModal(true);
  }

  function handlePlanResponse(
    companyId: string,
    data: PurchasePlanResponse,
    isCard = false,
  ) {
    // Request was successfull or it faild withoud the need to do more steps
    // Redirecting user to payment check page
    if (!data.redirect) {
      navigate(
        `/app/${isCard ? 'card' : 'payment'}-check/${companyId}?id=${
          data.transaction_id
        }`,
      );

      return;
    }

    setIsRedirecting(true);
    // User needs to approve payment
    if (data.redirect.preconditions) {
      reachNavigate(data.redirect.url, { replace: true });

      return;
    }

    // User needs to appruve payment with additional parameters
    setHiddenForm({
      url: data.redirect.url,
      parameters: data.redirect.parameters,
    });
  }

  function getQueryParams() {
    const query = new URLSearchParams(search);

    const planId = query.get('planId');
    const transactionId = query.get('id');
    const requestType = query.get('type');

    return {
      planId: planId || undefined,
      transactionId: transactionId || undefined,
      requestType: requestType || undefined,
    };
  }

  async function purchasePlan(
    companyId: string,
    info: PurchasePlanRequest,
    type: 'purchase' | 'upgrade' = 'purchase',
  ) {
    setIsLoading(true);
    setError(undefined);

    try {
      const { data } = await http.post<
        PurchasePlanResponse,
        PurchasePlanRequest
      >(`${companyId}/settings/company-subscription-plans/${type}`, info);
      handlePlanResponse(companyId, data);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }

    setIsLoading(false);
  }

  async function checkPayment(
    companyId: string,
    transactionId: string,
    isCard = false,
  ) {
    const url = isCard
      ? `${companyId}/settings/check-card-registration-status/${transactionId}`
      : `${companyId}/settings/check-transaction-status/${transactionId}`;

    dispatch(billingActions.loading());

    try {
      const { data } = await http.get<PaymentCheckResponse>(url);
      dispatch(billingActions.setPaymentCheck(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(billingActions.error(e));
    }
    setIsLoading(false);
  }

  async function checkCard(companyId: string, transactionId: string) {
    checkPayment(companyId, transactionId, true);
  }

  async function changeCard(companyId: string, info: CardRequest) {
    setIsLoading(true);

    try {
      const { data } = await http.post<PurchasePlanResponse, CardRequest>(
        `${companyId}/settings/register-card`,
        info,
      );
      handlePlanResponse(companyId, data, true);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }

    setIsLoading(false);
  }

  return {
    error,
    isModal,
    isLoading,
    hiddenForm,
    isRedirecting,
    checkCard,
    changeCard,
    setIsModal,
    purchasePlan,
    checkPayment,
    onPlanChoose,
    getQueryParams,
  };
};
