import React from 'react';
import { Heading, MainContent, Skeleton } from 'modules/app';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';

import * as styles from 'modules/dashboard/styles/ProfileAnalytics.styles';

interface Props {
  isLoading: boolean;
}

export const ProfileAnalytics: React.FC<Props> = ({ isLoading }) => {
  const { visits } = useSelector((state: AppState) => state.companyDashboard);

  return (
    <section css={styles.root}>
      <MainContent>
        {isLoading ? (
          <Skeleton
            count={2}
            containerStyles={styles.analyticsWrapper}
            customStyles={styles.skeleton}
          />
        ) : (
          <motion.div
            {...FADE_IN_MOVE_Y_REGULAR}
            transition={{ duration: 0.3 }}
          >
            <Heading as="h3" size="fluidXxxLarge">
              <FormattedMessage id="companyDashboard.analytics.title" />
            </Heading>

            <div css={styles.analyticsWrapper}>
              <div>
                <p css={styles.visitsTitle}>
                  <FormattedMessage id="companyDashboard.analytics.pageViewsLabel" />
                </p>
                <p css={styles.visitsNumber}>
                  {visits?.totalOpeningsViewsCount.toString()}
                </p>
              </div>

              <div>
                <p css={styles.visitsTitle}>
                  <FormattedMessage id="companyDashboard.analytics.pageVisitorsLabel" />
                </p>
                <p css={styles.visitsNumber}>
                  {visits?.totalOpeningsVisitsCount.toString()}
                </p>
              </div>
            </div>
          </motion.div>
        )}
      </MainContent>
    </section>
  );
};
