import { useRef, useState } from 'react';
import { useCookiePolicy } from '../components';

export const useCookieAccept = (callback?: (isAccepted: boolean) => void) => {
  const { setCookie, getCookie } = useCookiePolicy();
  const wasCookieAccepted = useRef(Boolean(getCookie()));
  const [isCookieAccepted, setIsCookieAccepted] = useState<boolean>(
    Boolean(getCookie()),
  );

  function onNecessaryAccept() {
    setCookie('necessary');
    callback?.(true);
    setIsCookieAccepted(true);
  }

  function enableGTM() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerText =
      "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
      "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
      "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
      `'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${process.env.GATSBY_GTM_AUTH_ID}&gtm_preview=env-${process.env.GATSBY_GTM_ENV}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);\n` +
      `})(window,document,'script','dataLayer','${process.env.GATSBY_GTM_ID}');`;

    document.head.appendChild(script);

    const noScript = document.createElement('noscript');
    const iFrame = document.createElement('iframe');

    iFrame.src = `https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GTM_ID}&gtm_auth=${process.env.GATSBY_GTM_AUTH_ID}&gtm_preview=env-${process.env.GATSBY_GTM_ENV}&gtm_cookies_win=x`;
    iFrame.width = '0';
    iFrame.height = '0';
    iFrame.setAttribute('style', 'display:none;visibility:hidden');

    noScript.appendChild(iFrame);

    document.body.appendChild(noScript);
  }

  function onCookieAccept() {
    setCookie('accept');
    callback?.(true);

    enableGTM();

    setIsCookieAccepted(true);
  }

  function onCookieDecline() {
    setCookie('decline');
    callback?.(false);
    setIsCookieAccepted(true);
  }

  return {
    isCookieAccepted,
    wasCookieAccepted,
    enableGTM,
    onCookieAccept,
    onCookieDecline,
    onNecessaryAccept,
  };
};
