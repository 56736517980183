import React from 'react';
import * as styles from 'modules/admin/styles/ImpersonateEndButton.styles';
import LogoutIcon from 'assets/icons/logout.svg';
import { Button } from 'modules/app';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { useImpersonateEnd } from 'modules/admin/hooks';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

export const ImpersonateEndButton: React.FC = () => {
  const { user } = useSelector((state: AppState) => state.session);
  const { user: adminUser, isLoading } = useSelector(
    (state: AppState) => state.adminImpersonate,
  );

  const { requestImpersonateEnd } = useImpersonateEnd();

  if (!adminUser?.adminId || isLoading) {
    return null;
  }

  return (
    <div css={styles.buttonContainer}>
      <motion.div transition={{ duration: 0.3 }} {...FADE_IN_MOVE_Y_REGULAR}>
        <div>
          <p>
            <strong>Impersonate Mode ON</strong> <br />
            {adminUser.adminRole} is currently impersonating
            <strong> {`${user?.firstName} ${user?.lastName}`} </strong> <br />
            with ID: <strong>{user?.id}</strong>
          </p>
        </div>
        <Button
          size="small"
          icon={<LogoutIcon css={styles.fill} />}
          css={styles.button}
          onClick={requestImpersonateEnd}
        >
          End impersonation
        </Button>
      </motion.div>
    </div>
  );
};
