import { breakpoints } from 'style/variables';
import { css } from '@emotion/react';

export const root = css`
  display: grid;
  grid-template-columns: auto minmax(auto, 1600px) auto;
  overflow: hidden;
  position: relative;
`;

export const wrapper = css`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: inherit;
  overflow-x: scroll;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const wrapperDraggable = css`
  ${wrapper};
  cursor: grab;
`;

export const contentWrapper = (paddingRight: number) => css`
  grid-column: 2;
  display: flex;
  padding: 0 calc(var(--unit) * 5);

  &::after {
    content: '';
    padding-inline-end: calc(var(--unit) * 5);
  }

  @media ${breakpoints.medium} {
    padding: 0 calc(var(--unit) * 16);

    &::after {
      padding-inline-end: calc(var(--unit) * 16);
    }
  }

  @media ${breakpoints.large} {
    padding: 0 calc(var(--unit) * 36);

    &::after {
      padding-inline-end: calc(var(--unit) * 36);
    }
  }

  @media ${breakpoints.xxLarge} {
    padding: 0 calc(var(--unit) * 36);

    &::after {
      padding-inline-end: ${`calc(${paddingRight} * 1px + 144px)`};
    }
  }
`;
