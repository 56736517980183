import { useIntl } from 'gatsby-plugin-intl';
import { useToastify } from 'modules/app';
import { UseFormReturn } from 'react-hook-form';

export function useCompanyBasicsErrorHandle(
  setHttpError: React.Dispatch<React.SetStateAction<ApiErrorData | undefined>>,
  setIsHandleExisting: React.Dispatch<React.SetStateAction<boolean>>,
  form?: UseFormReturn<CompanyBasicsRequest, unknown>,
) {
  const { formatMessage } = useIntl();
  const { toastError } = useToastify();

  function handleUpdateBasicInfoError(error?: ApiErrorData) {
    if (!error) return;

    if (error.data && error.data.errors && error.data.errors.website) {
      toastError('onboarding.form.errors.url');
      return;
    }

    if (error.cause && error.cause === 'item_already_exists') {
      setIsHandleExisting(true);
      toastError('onboarding.form.errors.company_handle');
      return;
    }

    setHttpError(error);
  }

  function handleCompanyErrors(error?: ApiErrorData) {
    if (!error) return;

    if (error.cause && error.cause === 'item_already_exists') {
      setIsHandleExisting(true);
      return;
    }

    if (error.data?.errors && error.data.errors.phone) {
      form?.setError('representativePhone', {
        type: 'invalidPhone',
        message: formatMessage({
          id: 'onboarding.form.errors.phone',
        }),
      });
      return;
    }

    if (error.data?.errors && error.data.errors.website) {
      toastError('onboarding.form.errors.url');
      return;
    }

    if (error.data?.errors && error.data.errors.representativeEmail) {
      toastError('onboarding.form.errors.representative_email');
      return;
    }

    setHttpError(error);
  }

  return {
    handleUpdateBasicInfoError,
    handleCompanyErrors,
  };
}
