import React from 'react';
import { padding } from '@prototyp/gatsby-plugin-gumball/utils';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Heading } from 'modules/app';

import InfoIcon from 'assets/icons/info.svg';

import * as styles from '../styles/Root.styles';

export const ForbiddenContent: React.FC = () => {
  return (
    <section css={styles.root}>
      <aside>
        <InfoIcon css={styles.icon} />
      </aside>
      <article>
        <Heading css={padding.bottom.huge} as="h1" size="fluidXxLarge">
          <FormattedMessage id="notfound.forbidden.title" />
        </Heading>
        <div css={styles.content}>
          <p css={padding.bottom.xlrg}>
            <FormattedMessage id="notfound.forbidden.subtitle" />
          </p>
        </div>
      </article>
    </section>
  );
};
