import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { Controller, useFormContext, RegisterOptions } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FormError } from 'modules/app/intl';

import { useMaxNumber } from './useMaxNumber';
import { TextInput } from './TextInput';
import { SerializedStyles } from '@emotion/react';

interface Props extends InputHTMLAttributes<Omit<HTMLInputElement, 'type'>> {
  name: string;
  label: string;
  constraints: RegisterOptions;
  value: string;
  onValid?: (val: string) => void;
  css?: SerializedStyles;
  isOptional?: boolean;
  onValueChange?: (data: string) => void;
}

export const NumberField: React.FC<Props> = ({
  name,
  value,
  constraints,
  label,
  onValid,
  css,
  isOptional = false,
  onValueChange,
  ...rest
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const [val, setVal] = useState<string>(value);
  const onChangeHandler = useMaxNumber(constraints);

  const err = errors[name];

  useEffect(() => {
    setVal(value);
  }, [value]);

  return (
    <div>
      <div>
        <Controller
          name={name}
          control={control}
          rules={constraints}
          render={({ field: { onChange } }) => {
            return (
              <TextInput
                {...rest}
                css={css}
                showError={err}
                isOptional={isOptional}
                formErrors={<FormError errors={errors} name={name} />}
                label={<FormattedMessage id={label} />}
                id={label}
                value={val}
                type="text"
                onChange={async (e) => {
                  const inputValue = e.target.value;
                  onValueChange?.(inputValue);
                  const shouldUpdate = onChangeHandler(inputValue, onValid);

                  if (
                    constraints.maxLength &&
                    inputValue.length > constraints.maxLength
                  ) {
                    return;
                  }

                  if (shouldUpdate) {
                    onChange(inputValue);
                    setVal(inputValue);
                  }
                }}
              />
            );
          }}
        />
      </div>
    </div>
  );
};
