import { createAction } from 'modules/redux-store';

export const adminBillingActivityActions = {
  setAdminBillingActivity: (payload?: AdminBillingActivityResponse) =>
    createAction('adminBillingActivity/SET_ADMIN_BILLING_ACTIVITY', payload),
  loading: () => createAction('adminBillingActivity/LOADING'),
  error: (payload?: ApiErrorData) =>
    createAction('adminBillingActivity/ERROR', payload),
  reset: () => createAction('adminBillingActivity/RESET'),
};
