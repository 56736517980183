import { ActionUnion } from 'modules/redux-store';

import { notesActions } from './actions';

interface NotesInitState {
  isLoading: boolean;
  error?: ApiErrorData;
  notes?: Note[];
  members?: NoteMember[];
}

const INITIAL_STATE: NotesInitState = {
  isLoading: false,
};

export const reducer = (
  state: NotesInitState = INITIAL_STATE,
  action: ActionUnion<typeof notesActions>,
): NotesInitState => {
  switch (action.type) {
    case 'notes/LOADING':
      return {
        ...state,
        isLoading: true,
      };

    case 'notes/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case 'notes/SET_NOTES':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        notes: action.payload,
      };

    case 'notes/SET_MEMBERS':
      return {
        ...state,
        members: action.payload,
      };

    case 'notes/PIPELINES_RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
