import { css } from '@emotion/react';

export const root = css`
  background-color: transparent;
  margin: calc(var(--unit) * 2) 0 calc(var(--unit) * 2) 0;

  border: 1px solid hsl(var(--color-error-7));

  p {
    color: hsl(var(--color-error-7));
  }

  svg {
    display: none;
    color: hsl(var(--color-error-7));
  }
`;
