import { RouteComponentProps } from '@reach/router';
import React, { useCallback, useEffect } from 'react';
import {
  BaseModal,
  Heading,
  HttpError,
  MainContent,
  NoNewMessages,
  Skeleton,
} from 'modules/app';
import Seo from 'modules/app/components/Seo';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { useConversations } from './hooks';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

import { AnimatePresence } from 'framer-motion';
import { ConversationList } from './components/ConversationList';
import { Messages } from './components/Messages';
import { MobileMessages } from './components/MobileMessages';

import * as styles from 'modules/talentMessages/styles/Root.styles';
import { talentMessagesActions } from './redux';

export const TalentMessages: React.FC<RouteComponentProps> = () => {
  const isMobile = window.innerWidth < 768;

  const {
    isLoadMore,
    conversationId,
    getConversations,
    loadMoreConversations,
  } = useConversations();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const {
    page,
    error,
    lastPage,
    isLoading,
    conversations,
    unreadConversations,
  } = useSelector((state: AppState) => state.talentMessages);
  const isUnread = Boolean(unreadConversations.length);

  const onLoadMore = useCallback(
    (page: number) => {
      if (!conversations) return;

      loadMoreConversations(page, conversations);
    },
    [conversations],
  );

  useEffect(() => {
    getConversations();

    return () => {
      dispatch(talentMessagesActions.resetConversations());
    };
  }, [unreadConversations]);

  return (
    <>
      <Seo title={formatMessage({ id: 'seoTitle.talentMessages' })} />

      <section css={styles.root}>
        <MainContent>
          <div css={styles.title}>
            <Heading as="h1" size="fluidLarge">
              <FormattedMessage id="talentMessages.title" />
            </Heading>
            <div css={isUnread ? styles.messageUnread : styles.messages}>
              {unreadConversations.length.toString()}
            </div>
          </div>

          <section css={styles.contentWrapper}>
            {isLoading ? (
              <div css={styles.conversationsWrapper}>
                <Skeleton count={3} customStyles={styles.skeletonItem} />
              </div>
            ) : !error && conversations ? (
              Boolean(conversations.length) ? (
                <div css={styles.conversationsWrapper}>
                  <ConversationList
                    conversationList={conversations}
                    onLoadMore={onLoadMore}
                    lastPage={lastPage}
                    page={page}
                    isLoading={isLoadMore}
                  />
                </div>
              ) : (
                <div css={styles.noMessages}>
                  <NoNewMessages type="talent" />
                </div>
              )
            ) : (
              <div css={styles.noMessages}>
                <HttpError error={error} />
              </div>
            )}

            {!isMobile && conversationId && (
              <section css={styles.messagesWrapper}>
                <Messages isConversationsLoading={isLoading} />
              </section>
            )}

            <AnimatePresence>
              {isMobile && conversationId && (
                <BaseModal>
                  <MobileMessages />
                </BaseModal>
              )}
            </AnimatePresence>
          </section>
        </MainContent>
      </section>
    </>
  );
};
