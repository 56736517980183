import { css } from '@emotion/react';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const banner = css`
  padding: var(--spacing-xhuge) 0;
  background-color: hsl(var(--color-robin-2));

  @media ${breakpoints.medium} {
    padding: calc(var(--unit) * 30) 0;
  }
`;

export const title = css`
  @media ${breakpoints.medium} {
    grid-column: 1 / span 5;
  }
`;

export const description = css`
  @media ${breakpoints.medium} {
    grid-column: 8 / span 4;
  }
`;
