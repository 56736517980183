import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { navigate } from 'gatsby-plugin-intl';
import { useEffect, useState } from 'react';

export const useCompanyBreadcrumbAccess = (
  accessor: keyof OnboardingCompanyBreadcrumbs,
) => {
  const breadcrumbAccess = useSelector(
    (state: AppState) => state.companyOnboarding,
  );
  const [isAvailable, setIsAvailable] = useState(false);

  useEffect(() => {
    if (breadcrumbAccess.finished) {
      navigate('/profile/talent/overview/invitations');

      return;
    }

    if (breadcrumbAccess.finishedScreen) {
      navigate('/app/onboarding-company-final');

      return;
    }

    if (accessor === 'finishedScreen' && breadcrumbAccess.finishedScreen) {
      return;
    }

    if (breadcrumbAccess[accessor]) {
      setIsAvailable(true);

      return;
    }

    const keys = Object.keys(breadcrumbAccess);

    if (!breadcrumbAccess[accessor]) {
      let lastSuccess = '';
      for (const key of keys) {
        const access = breadcrumbAccess[key];

        if (access) {
          lastSuccess = key;
        }

        if (!access) {
          navigate(`/app/onboarding/company/${lastSuccess}`);

          return;
        }
      }
    }
  }, [accessor, breadcrumbAccess]);

  return isAvailable;
};
