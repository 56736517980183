import React, { InputHTMLAttributes, useState } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import * as styles from 'modules/app/styles/InputButton.styles';
import { Label } from 'modules/app';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';

interface Props extends InputHTMLAttributes<Omit<HTMLInputElement, 'type'>> {
  groupLabel: string;
  groupName: string;
  selectedValues: string[];
  data: { value: string; label: string }[];
  constraints?: RegisterOptions;
  reset?: number;
  customStyles?: SerializedStyles | SerializedStyles[];
  isMulti?: boolean;
  additionalInfo?: string;
  onValuesChange?: (selected: string[]) => void;
}

export const CheckboxButtonGroup: React.FC<Props> = ({
  groupLabel,
  groupName,
  selectedValues,
  constraints,
  data,
  customStyles,
  isMulti,
  onValuesChange,
  additionalInfo,
  ...rest
}) => {
  const {
    control,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const [selected, setSelected] = useState<string[]>(selectedValues);
  const intl = useIntl();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isMulti) {
      const newArray = [event.target.value];
      setSelected(newArray);
      setValue(groupName, newArray);
      clearErrors(groupName);

      onValuesChange?.(newArray);

      return;
    }

    const filterValues = selected.filter((v) => v !== event.target.value);

    const toggleValue = selected.includes(event.target.value)
      ? filterValues
      : [...selected, event.target.value];

    const newArray =
      constraints?.maxLength && selected.length >= constraints?.maxLength
        ? filterValues
        : toggleValue;

    setSelected(newArray);
    setValue(groupName, newArray);
    clearErrors(groupName);

    onValuesChange?.(newArray);
  };

  return (
    <div css={customStyles}>
      <div css={styles.labelWrapper}>
        <Label
          label={<FormattedMessage id={groupLabel} />}
          altText={
            additionalInfo
              ? intl.formatMessage({ id: `${additionalInfo}` })
              : constraints?.maxLength && `max ${constraints.maxLength}`
          }
          customStyles={margin.bottom.med}
          labelStyle
          isOptional={!Boolean(constraints?.required)}
          error={errors[groupName]}
        />
      </div>

      <div css={styles.root}>
        {data.map((item, index) => {
          const isChecked = selected.includes(item.value);

          return (
            <div key={index} css={styles.item}>
              <Controller
                name={groupName}
                control={control}
                rules={{
                  ...constraints,
                  validate: () => {
                    if (!constraints?.required) {
                      return;
                    }

                    return Boolean(selected.length);
                  },
                }}
                defaultValue={isChecked}
                render={() => {
                  return (
                    <input
                      {...rest}
                      css={styles.input}
                      checked={isChecked}
                      id={`checkbox-${item.value}`}
                      type="checkbox"
                      name={item.value}
                      value={item.value}
                      onChange={onChange}
                    />
                  );
                }}
              />

              <label
                css={isChecked ? styles.inputButtonChecked : styles.inputButton}
                htmlFor={`checkbox-${item.value}`}
              >
                {item.label}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
