import { HttpError } from 'modules/app/http/client/httpError';
import { createAction } from 'modules/redux-store';

export const talentOnboardingAction = {
  updateTalentOnboardingAccess: (payload: OnboardingTalentBreadcrumbs) =>
    createAction('onboarding/TALENT_ACCESS', payload),
  loading: () => createAction('onboarding/TALENT_LOADING'),
  error: (payload: HttpError) =>
    createAction('onboarding/TALENT_ERROR', payload),
  inRequest: (payload: boolean) =>
    createAction('onboarding/TALENT_IN_REQUEST', payload),
  setListArray: (payload: ListingData[]) =>
    createAction('onboarding/TALENT_SET_LIST_ARRAY', payload),
  reset: () => createAction('onboarding/TALENT_RESET'),
};

export const companyOnboardingAction = {
  updateCompanyOnboardingAccess: (payload: OnboardingCompanyBreadcrumbs) =>
    createAction('onboarding/COMPANY_ACCESS', payload),
  loading: () => createAction('onboarding/COMPANY_LOADING'),
  error: (payload: ApiErrorData) =>
    createAction('onboarding/COMPANY_ERROR', payload),
  inRequest: (payload: boolean) =>
    createAction('onboarding/COMPANY_IN_REQUEST', payload),
  setListArray: (payload: ListingData[]) =>
    createAction('onboarding/COMPANY_SET_LIST_ARRAY', payload),
  setCompanyData: (payload?: Company) =>
    createAction('onboarding/COMPANY_SET_COMPANY_DATA', payload),
  setCountries: (payload: { value: string; label: string }[]) =>
    createAction('onboarding/COMPANY_SET_COUNTRIES', payload),
  reset: () => createAction('onboarding/COMPANY_ONBOARDING_RESET'),
};
