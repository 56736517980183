import React from 'react';
const RichTextEditor = React.lazy(
  () => import('modules/app/components/textEditor/components/RichTextEditor'),
);

import * as styles from 'modules/app/styles/CoverLetter.styles';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils';

interface Props {
  letter: string | undefined;
}

export const CoverLetter: React.FC<Props> = ({ letter }) => {
  return (
    <div css={[grid.colSpan.c12, styles.root]}>
      <React.Suspense fallback={null}>
        <RichTextEditor
          isReadOnly={true}
          value={letter ? JSON.parse(letter) : undefined}
        />
      </React.Suspense>
    </div>
  );
};
