import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';

export const proficiencyValues: LanguageChoice<React.ReactNode>[] = [
  {
    value: 'native',
    label: (
      <FormattedMessage id="onboarding.form.fields.languages.proficiency_native" />
    ),
  },
  {
    value: 'a1',
    label: <FormattedMessage id="onboarding.form.fields.languages.a1" />,
  },
  {
    value: 'a2',
    label: <FormattedMessage id="onboarding.form.fields.languages.a2" />,
  },
  {
    value: 'b1',
    label: <FormattedMessage id="onboarding.form.fields.languages.b1" />,
  },
  {
    value: 'b2',
    label: <FormattedMessage id="onboarding.form.fields.languages.b2" />,
  },
  {
    value: 'c1',
    label: <FormattedMessage id="onboarding.form.fields.languages.c1" />,
  },
  {
    value: 'c2',
    label: <FormattedMessage id="onboarding.form.fields.languages.c2" />,
  },
];
