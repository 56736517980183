import { createAction } from 'modules/redux-store';

export const companyDashboardAction = {
  setStats: (payload?: CompanyStats) =>
    createAction('companyDashboard/COMPANY_STATS', payload),
  setVisits: (payload?: CompanyVisits) =>
    createAction('companyDashboard/COMPANY_VISITS', payload),
  error: (payload: ApiErrorData) =>
    createAction('companyDashboard/ERROR', payload),
  reset: () => createAction('companyDashboard/RESET'),
};
