import { navigate } from 'gatsby-plugin-intl';
import { useHttpClient, useToastify } from 'modules/app';
import { useMandatoryRefresh } from 'modules/auth';
import { useState } from 'react';

export const useOnboarding = () => {
  const { toastError } = useToastify();
  const { mandatoryRefresh } = useMandatoryRefresh();

  const [isLoading, setIsLoading] = useState(false);

  const http = useHttpClient('/api/v1/settings/signals');

  async function signalTalentOnboarding(isLinkClicked?: boolean) {
    setIsLoading(true);
    try {
      await http.put('/talent-onboarding');
      await mandatoryRefresh();
      isLinkClicked && navigate('/profile/talent/manage/basic-information');
    } catch (e) {
      toastError();
    } finally {
      setIsLoading(false);
    }
  }

  async function signalCompanyOnboarding() {
    setIsLoading(true);
    try {
      await http.put('/company-onboarding');
      await mandatoryRefresh();
    } catch (e) {
      toastError();
    } finally {
      setIsLoading(false);
    }
  }

  return {
    isLoading,
    signalTalentOnboarding,
    signalCompanyOnboarding,
  };
};
