import React from 'react';

import * as styles from 'modules/app/styles/CtaButton.styles';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';

interface Props {
  textId: string;
  buttonType?: 'primary' | 'secondary';
  theme?: 'light' | 'dark';
  path: string;
  customStyles?: SerializedStyles;
}

export const CtaButton: React.FC<Props> = ({
  textId,
  buttonType = 'primary',
  theme = 'light',
  path,
  customStyles,
}) => {
  const css = () => {
    if (buttonType === 'secondary' && theme === 'dark') {
      return styles.secondaryDark;
    }

    if (buttonType === 'secondary') {
      return styles.secondary;
    }

    if (buttonType === 'primary' && theme === 'dark') {
      return styles.primaryDark;
    }

    return styles.primary;
  };

  return (
    <Link to={path} css={[css(), customStyles]}>
      <span>
        <FormattedMessage id={textId} />
      </span>
    </Link>
  );
};
