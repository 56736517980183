import React, { useEffect, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  HttpError,
  Heading,
  CompanyLegalInputs,
  CompanyBasicInputs,
  normalizeCompanyFormData,
} from 'modules/app';
import * as styles from 'modules/app/styles/GenericForm.styles';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { motion } from 'framer-motion';
import { useCompanyBasics } from 'modules/profile/hooks';

interface Props {
  onInit: (form: HTMLFormElement) => void;
  company?: Company;
  isOnboarding?: boolean;
  countriesValue: { value: string; label: string }[];
}

export const CompanyForm: React.FC<Props> = ({
  onInit,
  company,
  isOnboarding,
  countriesValue,
}) => {
  const methods = useForm<CompanyBasicsRequest>({
    defaultValues: {
      handle: company?.handle || '',
      name: company?.name || '',
      website: company?.website.substring(8) || '',
      country: company?.country || '',
      establishedAt: company?.establishedAt.substring(0, 4) || '',
      representativeFirstName: company?.representativeFirstName || '',
      representativeLastName: company?.representativeLastName || '',
      representativeEmail: company?.representativeEmail || '',
      representativePhone: company?.representativePhone || null,
    },
  });

  const ref = useRef<HTMLFormElement>(null);

  const {
    httpError,
    requestOnboardingCompanyUpdate,
    requestOnboardingCreateCompany,
    isHandleExisting,
    setIsHandleExisting,
  } = useCompanyBasics(methods);

  const onSubmit = async (data: CompanyBasicsRequest) => {
    const model = normalizeCompanyFormData(data);

    if (!company) {
      requestOnboardingCreateCompany(model, isOnboarding);
      return;
    }

    requestOnboardingCompanyUpdate(model, isOnboarding);
  };

  useEffect(() => {
    if (!ref.current) return;

    onInit(ref.current);
  }, [ref, onInit]);

  return (
    <motion.div
      {...FADE_IN_MOVE_Y_REGULAR}
      transition={{ duration: 0.4 }}
      css={styles.root}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} ref={ref}>
          <CompanyBasicInputs
            countriesValue={countriesValue}
            company={company}
            isHandleExisting={isHandleExisting}
            setIsHandleExisting={setIsHandleExisting}
          />

          <div css={styles.subheaderGroup}>
            <Heading as="h1" size="fluidLarge">
              <FormattedMessage id="onboarding.form.fields.information.legal_representative" />
            </Heading>
          </div>
          <CompanyLegalInputs company={company} />
        </form>
      </FormProvider>
      <HttpError error={httpError} />
    </motion.div>
  );
};
