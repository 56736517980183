import React from 'react';
import CloseIcon from 'assets/icons/tagDelete.svg';
import * as styles from 'modules/app/styles/Tag.styles';

interface Props {
  text: string;
  onDelete?: () => void;
}

export const Tag: React.FC<Props> = ({ text, onDelete }) => {
  return (
    <div css={styles.root}>
      <span>{text}</span>
      {onDelete && <CloseIcon css={styles.deleteIcon} onClick={onDelete} />}
    </div>
  );
};
