export function normalizeExperienceRequest(
  data: ExperienceData,
): ExperienceRequest {
  return {
    jobTitle: data.jobTitle,
    companyName: data.companyName,
    employmentStartDate: data.employmentStartDate
      ? `${data.employmentStartDate.getFullYear()}-${
          data.employmentStartDate.getMonth() + 1
        }-01`
      : '',
    employmentEndDate: data.employmentEndDate
      ? `${data.employmentEndDate.getFullYear()}-${
          data.employmentEndDate.getMonth() + 1
        }-${data.employmentEndDate.getDate()}`
      : null,
    employmentType: data.employmentType === '' ? null : data.employmentType,
    workplace: data.workplace,
    jobDescription: data.jobDescription,
  };
}
