import React from 'react';
import * as styles from 'modules/onboarding/styles/Expertise.styles';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { removeWhiteSpace } from 'modules/talents';

interface Props {
  onRemove: (expertiseId: string) => void;
  expertiseList: CompanyExpertise[] | undefined;
}

export const CompanyExpertiseList: React.FC<Props> = ({
  onRemove,
  expertiseList,
}) => {
  return (
    <div css={styles.chosen}>
      {expertiseList && expertiseList.length !== 0 && (
        <p css={styles.label}>
          <FormattedMessage id="onboarding.form.fields.expertise.your_chosen_expertise" />
        </p>
      )}

      <div css={styles.chosenExpertise}>
        <ul css={styles.chosenList}>
          {expertiseList &&
            expertiseList.map((expertise: CompanyExpertise) => (
              <li css={styles.chosenListItem} key={expertise.id}>
                <p css={styles.chosenText}>
                  #{removeWhiteSpace(expertise.name)}
                </p>
                <button
                  onClick={() => onRemove(expertise.companyTagId)}
                  css={styles.chosenRemove}
                >
                  <div css={styles.minus} />
                </button>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
