import { globalHistory, useMatch } from '@reach/router';
import { useGetActivity } from './useGetActivity';
import { format, startOfToday, startOfYear } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { companyActionTypes, userActionTypes } from '../consts';
import { useToastify } from 'modules/app';

interface Props {
  page: number;
  handlePageReset: VoidFunction;
}

export function useActivityLog({ page, handlePageReset }: Props) {
  const { toastError } = useToastify();
  const { getActivity } = useGetActivity();

  const matchCompanies = useMatch('/:lang/admin/activity/companies');
  const matchAdmin = useMatch('/:lang/admin/activity/admin');
  const matchSupport = useMatch('/:lang/admin/activity/support');

  const today = format(startOfToday(), 'yyyy-MM-dd');
  const firstDayOfTheYear = format(startOfYear(new Date()), 'yyyy-MM-dd');

  const title = useMemo(() => {
    if (matchAdmin) return 'Admin activity';
    if (matchCompanies) return 'Company activity';
    if (matchSupport) return 'Support activity';
    return 'Talent activity';
  }, [matchCompanies, matchAdmin, matchSupport]);

  const path = useMemo(
    () => (matchCompanies ? 'company' : 'users'),
    [matchCompanies],
  );

  const actionTypes = useMemo(
    () => (matchCompanies ? companyActionTypes : userActionTypes),
    [matchCompanies],
  );

  const [endDate, setEndDate] = useState(today);
  const [searchTerm, setSearchTerm] = useState('');
  const [actionType, setActionType] = useState('');
  const [startDate, setStartDate] = useState(firstDayOfTheYear);

  const userType = useMemo(() => {
    if (matchAdmin || matchSupport) return 'system';
    if (matchCompanies) return 'user';

    return 'user';
  }, [matchAdmin, matchSupport, matchCompanies]);

  const adminRole = useMemo(() => {
    if (matchAdmin) return 'Admin';
    if (matchSupport) return 'Support';
    return;
  }, [matchAdmin, matchSupport]);

  function onSearch(
    e: React.KeyboardEvent<HTMLInputElement> &
      React.ChangeEvent<HTMLInputElement>,
  ) {
    if (e.key === 'Enter') {
      setSearchTerm(e.target.value);
    }

    if (e.target.value === '') {
      setSearchTerm('');
    }
  }

  function onSelectAction(type: string) {
    setActionType(type);
    handlePageReset();
  }

  function handleStartDateChange(date: Date) {
    if (date.getTime() > new Date(endDate).getTime()) {
      toastError('admin.shared.error.startDate');
      return;
    }

    setStartDate(format(date, 'yyyy-MM-dd'));
    handlePageReset();
  }

  function handleEndDateChange(date: Date) {
    if (date.getTime() < new Date(startDate).getTime()) {
      toastError('admin.shared.error.endDate');
      return;
    }

    setEndDate(format(date, 'yyyy-MM-dd'));
    handlePageReset();
  }

  useEffect(() => {
    return globalHistory.listen((location) => {
      if (location) {
        setActionType('');
        handlePageReset();
      }
    });
  }, []);

  useEffect(() => {
    getActivity(
      {
        page,
        subject: userType,
        verb: actionType,
        search: searchTerm,
        searchBy: 'user_name',
        startDate,
        endDate,
        adminRole,
      },
      path,
    );
  }, [
    path,
    page,
    userType,
    actionType,
    searchTerm,
    startDate,
    endDate,
    adminRole,
  ]);

  return {
    title,
    actionType,
    actionTypes,
    endDate,
    startDate,
    onSearch,
    onSelectAction,
    handleStartDateChange,
    handleEndDateChange,
  };
}
