import { css } from '@emotion/react';
import { fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball/mixins';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';
import { scrollbar } from 'style/global.styles';

export const root = (theme: 'light' | 'dark') => css`
  margin-bottom: 32px;
  label {
    display: block;
    font-size: ${fontSize('small')};
    line-height: ${lineHeight('small')};
    color: hsl(var(--color-grayscale-6));
  }

  .roango-select__control {
    background-color: transparent;
    margin-top: 4px;
    border: none;
    border-bottom: 1px solid hsl(var(--color-borderLight-8));
    border-radius: 0;
    padding-left: 0;
    min-height: 0;

    :hover {
      border-color: hsl(var(--color-borderDark-5));
    }
  }

  .roango-select__control--is-focused,
  .roango-select__control--menu-is-open {
    box-shadow: none;
    border-color: hsl(var(--color-borderDark-5));
  }

  .roango-select__multi-value {
    background-color: hsl(var(--color-note-10));
    border-radius: 3px;
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    padding: var(--unit) calc(var(--unit) * 2) var(--unit) calc(var(--unit) * 3);

    &:hover {
      background-color: hsl(var(--color-note-9));
    }
  }

  .roango-select__multi-value__label {
    padding: 0;
  }

  .roango-select__multi-value__remove {
    svg {
      fill: hsl(var(--color-grayscale-4));
    }

    &:hover {
      background: transparent;
      cursor: pointer;

      svg {
        fill: hsl(var(--color-text-1));
      }
    }
  }

  .roango-select__value-container {
    padding-left: 0;

    & > div {
      margin-right: calc(var(--unit) * 2);
      margin-left: 0;
    }
  }

  .roango-select__indicator,
  .roango-select__indicators {
    padding-right: 0;

    & > svg {
      fill: ${theme === 'dark'
        ? 'hsl(var(--color-textLight-7))'
        : 'hsl(var(--color-text-1))'};
    }
  }

  .roango-select__placeholder {
    margin-left: 0;
    color: hsl(var(--color-textLight-7));
    white-space: nowrap;
  }

  .roango-select__menu {
    background-color: ${theme === 'dark'
      ? 'hsl(var(--color-borderBlack-2))  '
      : 'hsl(var(--color-white-11))'};
    padding: 8px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
  }

  .roango-select__option {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    border-radius: 4px;
    ${font.size.tiny}
    color: ${theme === 'dark'
      ? 'hsl(var(--color-grayscale-1))'
      : 'hsl(var(--color-text-1))'};

    &--is-focused {
      background-color: ${theme === 'dark'
        ? 'hsl(var(--color-grayscale-8))'
        : 'hsl(var(--color-borderLight-8), 30%)'};
    }

    &--is-selected {
      background-color: ${theme === 'dark'
        ? 'hsl(var(--color-grayscale-8))'
        : 'hsl(var(--color-borderLight-8), 30%)'};
    }
  }

  .roango-select__input {
    color: ${theme === 'dark'
      ? 'hsl(var(--color-grayscale-1))'
      : 'hsl(var(--color-text-1))'};
  }

  .roango-select__menu-list {
    padding: 0;
    ${scrollbar};
  }

  .roango-select__menu-notice--no-options {
    ${font.size.tiny};
  }

  .roango-select__single-value {
    span {
      display: none;
    }
  }
`;

export const error = css`
  label {
    color: hsl(var(--color-error-7));
  }

  .roango-select__control {
    border-bottom: 1px solid hsl(var(--color-error-7));
  }
`;

export const bottomText = css`
  margin: 0;
  font-size: ${fontSize('tiny')};
  line-height: ${lineHeight('tiny')};
  color: hsl(var(--color-text-7));
`;
