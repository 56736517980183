import React from 'react';
import { DropdownMenu } from '.';

import * as styles from 'modules/app/styles/Pagination.styles';
import { useIntl } from 'gatsby-plugin-intl';

interface Props {
  perPage: number;
  setPerPage: (id: string) => void;
  defaultPerPage: number;
}

export const PerPage: React.FC<Props> = ({
  perPage,
  setPerPage,
  defaultPerPage,
}) => {
  const intl = useIntl();

  const itemsPerPage = [
    {
      id: defaultPerPage.toString(),
      element: <p>{defaultPerPage.toString()}</p>,
    },
    {
      id: '25',
      element: <p>25</p>,
    },
    {
      id: '50',
      element: <p>50</p>,
    },
  ];

  return (
    <DropdownMenu
      onSelect={setPerPage}
      customButtonStyles={styles.perPage}
      placeholder={
        <p>
          {perPage} {intl.formatMessage({ id: 'pagination.perPage' })}
        </p>
      }
      items={itemsPerPage}
      position="topRight"
    />
  );
};
