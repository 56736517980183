import { ActionUnion } from 'modules/redux-store';
import { invoicesActions } from './actions';

interface InvoicesInitState {
  isLoading: boolean;
  error?: ApiErrorData;
  invoices?: InvoicesDataResponse;
}

const INITIAL_STATE: InvoicesInitState = {
  isLoading: false,
};

export const invoicesReducer = (
  state: InvoicesInitState = INITIAL_STATE,
  action: ActionUnion<typeof invoicesActions>,
): InvoicesInitState => {
  switch (action.type) {
    case 'invoices/LOADING':
      return {
        ...state,
        isLoading: true,
      };

    case 'invoices/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case 'invoices/SET_INVOICES':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        invoices: action.payload,
      };

    case 'invoices/RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
