import React from 'react';
import { Button } from '../shared';
import * as styles from 'modules/app/styles/Tag.styles';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { AnimatePresence, motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import PlusIcon from 'assets/icons/plusBlack.svg';
import { useAddTagButton } from '../hooks/useAddTagButton';

interface Props {
  onAddTag: (tag: string) => void;
  customStyles?: SerializedStyles;
  isApplication?: boolean;
  companyId?: string;
}

export const AddTagButton: React.FC<Props> = ({
  onAddTag,
  customStyles,
  isApplication,
  companyId,
}) => {
  const intl = useIntl();
  const {
    onChange,
    onSubmit,
    setIsModal,
    onTagsClick,
    onCompanyTagsClick,
    ref,
    isModal,
    methods,
    inputRef,
    modalRef,
    companyTags,
    suggestedTags,
  } = useAddTagButton(onAddTag, companyId);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;
  const { ref: inputRegRef, ...rest } = register('value', {
    required: true,
    maxLength: 30,
    shouldUnregister: true,
  });

  return (
    <div ref={ref} css={[styles.addTag, customStyles]}>
      <Button
        type="button"
        buttonType="ghost"
        css={styles.addTagBtn}
        onClick={() => {
          setIsModal((isModal) => !isModal);
        }}
      >
        <FormattedMessage id="profileManagement.companyMission.valuesPlaceholder" />
        <PlusIcon />
      </Button>

      <AnimatePresence>
        {isModal && (
          <motion.div
            ref={modalRef}
            transition={{ duration: 0.2 }}
            {...FADE_IN_MOVE_Y_REGULAR}
            exit={{ opacity: 0, y: 10, transition: { duration: 0.2 } }}
            css={styles.addTagModal}
          >
            <>
              <input
                ref={(e) => {
                  inputRef.current = e;
                  inputRegRef(e);
                }}
                {...rest}
                css={
                  errors.value ? styles.addTagInputError : styles.addTagInput
                }
                autoComplete="off"
                placeholder={intl.formatMessage({
                  id: 'profileManagement.companyMission.addTags',
                })}
                onChange={onChange}
                name="value"
              />

              <Button
                buttonType="ghost"
                icon={<PlusIcon />}
                css={styles.newTagBtn}
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                <FormattedMessage id="profileManagement.companyMission.createTags" />
              </Button>
            </>
            {isApplication && (
              <ul css={styles.addTagSuggested}>
                {companyId ? (
                  <>
                    {companyTags &&
                      Boolean(companyTags.length) &&
                      companyTags.map((tag, i) => (
                        <li key={i} onClick={() => onCompanyTagsClick(tag)}>
                          {tag}
                        </li>
                      ))}
                  </>
                ) : (
                  <>
                    {suggestedTags &&
                      Boolean(suggestedTags.length) &&
                      suggestedTags.map((tag) => (
                        <li key={tag.id} onClick={() => onTagsClick(tag)}>
                          {tag.name}
                        </li>
                      ))}
                  </>
                )}
              </ul>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
