import React from 'react';
import { Link as IntlLink } from 'gatsby-plugin-intl';
import { Link as GatsbyLink } from 'gatsby';
import { GatsbyLinkProps } from 'gatsby';

export const Link: React.FC<GatsbyLinkProps<string>> = ({
  children,
  to,
  ref,
  ...rest
}) => {
  if (typeof window !== undefined && /public-opening/.test(location.pathname)) {
    return (
      <GatsbyLink
        to={`/${window.localStorage.getItem('gatsby-intl-language')}${to}`}
        {...rest}
        ref={ref}
      >
        {children}
      </GatsbyLink>
    );
  }

  if (typeof window !== undefined && /public-opening/.test(to)) {
    return (
      <GatsbyLink to={`${to}`} {...rest} ref={ref}>
        {children}
      </GatsbyLink>
    );
  }

  return (
    <IntlLink to={to} {...rest} ref={ref}>
      {children}
    </IntlLink>
  );
};
