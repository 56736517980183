import { PortfolioIdEnum, useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { talentOnboardingAction } from 'modules/onboarding';
import { useState } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useDispatch } from 'react-redux';

interface GetPortfolioProps {
  page?: number;
  perPage?: number;
  load?: boolean;
}

interface AddPortfolioProps {
  portfolio: PortfolioData;
  portfolioId?: string;
  setError?: UseFormSetError<PortfolioData>;
}

export function useTalentPortfolio() {
  const dispatch = useDispatch();
  const { toastSuccess } = useToastify();
  const talentProfileService = useHttpClient<Basics>('/api/v1/talent/profile');

  const [pageCount, setPageCount] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [httpError, setHttpError] = useState<HttpError>();
  const [portfolios, setPortfolios] = useState<Portfolio[]>();

  async function getPortfolios({ page, perPage }: GetPortfolioProps) {
    setIsLoading(true);
    try {
      const {
        data: { data: portfoliosList, lastPage, page: portfolioPage },
      } = await talentProfileService.get<PortfolioResponse>(
        `/portfolios?perPage=${perPage}&page=${page}`,
      );

      typeof window !== undefined &&
        window.scrollTo({ top: 0, behavior: 'smooth' });
      setIsLoading(false);
      setHttpError(undefined);
      setPageCount(lastPage);
      setCurrentPage(portfolioPage);
      setPortfolios(portfoliosList);

      return { portfolios: portfoliosList, lastPage, page: portfolioPage };
    } catch (e) {
      setIsLoading(false);
      if (!(e instanceof HttpError)) return;
      setHttpError(e);

      return { error: e };
    }
  }

  async function getPortfoliosDispatch({
    page,
    perPage,
    load = true,
  }: GetPortfolioProps) {
    load && dispatch(talentOnboardingAction.loading());
    try {
      const {
        data: { data: portfoliosList },
      } = await talentProfileService.get<PortfolioResponse>(
        `/portfolios?perPage=${perPage}&page=${page}`,
      );

      const portfolioList: ListingData[] = portfoliosList?.map((item) => ({
        title: PortfolioIdEnum[item.portfolioId],
        content: item.description || '',
        id: item.id,
      }));

      dispatch(talentOnboardingAction.setListArray(portfolioList));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(talentOnboardingAction.error(e));
    }
  }

  async function addPortfolio({ portfolio, setError }: AddPortfolioProps) {
    try {
      const { data } = await talentProfileService.post(
        '/portfolios',
        portfolio,
      );

      return { portfolio: data };
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      e.cause === 'item_already_exists'
        ? setError?.('url', {
            type: 'manual',
            message: 'http_errors.item_already_exists',
          })
        : setHttpError(e);

      return { error: e };
    }
  }

  async function deletePortfolio(portfolioId: string) {
    try {
      await talentProfileService.delete(`/portfolios/${portfolioId}`);
      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  async function updatePortfolio({
    portfolio,
    portfolioId,
    setError,
  }: AddPortfolioProps) {
    try {
      const { data } = await talentProfileService.put(
        `/portfolios/${portfolioId}`,
        portfolio,
      );

      return { project: data };
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      e.cause === 'item_already_exists'
        ? setError?.('url', {
            type: 'manual',
            message: 'http_errors.item_already_exists',
          })
        : setHttpError(e);
      return { error: e };
    }
  }

  return {
    isLoading,
    pageCount,
    currentPage,
    httpError,
    portfolios,
    getPortfolios,
    getPortfoliosDispatch,
    setCurrentPage,
    setPageCount,
    setPortfolios,
    addPortfolio,
    deletePortfolio,
    updatePortfolio,
  };
}
