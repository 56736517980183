import { WorkingHoursEnum, WorkplaceEnum } from 'modules/profilePreview';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar, Heading } from 'modules/app';

import * as styles from 'modules/app/styles/OpeningBox.styles';
import { useDispatch } from 'react-redux';
import { openingsActions } from 'modules/openings';
import { Link } from 'gatsby';

interface Props {
  opening: OpeningsSearch | TalentMatch;
  theme?: 'light' | 'dark';
  customStyles?: SerializedStyles | SerializedStyles[];
}

export const OpeningBox: React.FC<Props> = ({
  opening,
  theme = 'light',
  customStyles,
}) => {
  const dispatch = useDispatch();
  const avatarCustomStyles = opening.companyIconPicture
    ? styles.avatar
    : styles.avatarWithBackground;

  return (
    <article css={[styles.rootTheme(theme), customStyles]}>
      <header>
        <Link
          css={theme === 'light' ? styles.nameLight : styles.name}
          to={`/company/${opening.companyHandle}`}
        >
          <Avatar
            customStyles={avatarCustomStyles}
            name={opening.company}
            imagePath={opening.companyIconPicture?.large}
            isShadow={theme === 'dark' ? false : true}
          />
          <p>{opening.company}</p>
        </Link>
      </header>
      <section>
        <header
          css={theme === 'light' ? styles.openingNameLight : styles.openingName}
          onClick={() => {
            dispatch(openingsActions.loading());
          }}
        >
          <Link to={`/public-opening/${opening.companyHandle}/${opening.slug}`}>
            <Heading as="h3" size="fluidLarge">
              {opening.title}
            </Heading>
          </Link>
        </header>
        <section css={styles.openingInfo}>
          <p>{opening.cityCountry},</p>
          {opening.typeOfWorkplace &&
            opening.typeOfWorkplace.map((workplace, i) => (
              <p key={i}>
                <FormattedMessage id={`${WorkplaceEnum[workplace]}`} />,
              </p>
            ))}
          <p>
            <FormattedMessage
              id={`${WorkingHoursEnum[opening.workingHours]}`}
            />
          </p>
        </section>
      </section>
    </article>
  );
};
