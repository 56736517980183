import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import * as styles from 'modules/app/styles/ToggleSwitchInput.styles';

interface Props extends InputHTMLAttributes<Omit<HTMLInputElement, 'type'>> {
  name: string;
  checked: boolean;
  constraints: RegisterOptions;
  reset?: number;
  customStyles?: SerializedStyles | SerializedStyles[];
  onChecked?: (isChecked: boolean) => void;
}

export const ToggleSwitchInput: React.FC<Props> = ({
  children,
  name,
  checked,
  constraints,
  reset,
  customStyles,
  onChecked,
  ...rest
}) => {
  const { control, setValue } = useFormContext();
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  useEffect(() => {
    setValue(name, isChecked, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [isChecked, setValue, name]);

  useEffect(() => {
    setIsChecked(checked);
    setValue(name, checked);
  }, [checked, name, setValue]);

  useEffect(() => {
    if (Number.isInteger(reset) && reset !== 0) {
      setIsChecked(false);
      setValue(name, false);
    }
  }, [reset, setIsChecked, setValue, name]);

  return (
    <div css={[customStyles]}>
      <Controller
        name={name}
        control={control}
        rules={constraints}
        defaultValue={isChecked}
        render={({ field: { onChange } }) => {
          return (
            <input
              {...rest}
              css={styles.input}
              checked={isChecked}
              id={`switch-${name}`}
              type="checkbox"
              name={name}
              onChange={() => {
                onChange(!isChecked);
                onChecked?.(!isChecked);
                setIsChecked(!isChecked);
              }}
            />
          );
        }}
      />

      <label css={styles.label} htmlFor={`switch-${name}`}>
        <span css={isChecked ? styles.controlChecked : styles.control}>
          <div css={isChecked ? styles.toggleChecked : styles.toggle} />
        </span>

        {children && <span css={styles.content}>{children}</span>}
      </label>
    </div>
  );
};
