import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { AppState } from 'modules/redux-store';
import { useDispatch, useSelector } from 'react-redux';
import { openingsActions } from '../redux';
import { usePlanRestrictions } from './usePlanRestrictions';

interface GetOpeningsParams {
  companyId: string;
  page?: number;
  itemsPerPage?: number;
  search?: string;
  departmentList?: string[];
  statusList?: string[];
  locationList?: string[];
}

interface PaginatedResponse<T> {
  page: number;
  per_page: number;
  total: number;
  last_page: number;
  data: T[];
}

interface ScheduleData {
  status: string;
  publishedAt: string;
}

export const useOpenings = () => {
  const { toastSuccess } = useToastify();
  const { getPlan } = usePlanRestrictions();
  const { currentPage, searchTerm, departments, statuses, locations, perPage } =
    useSelector((state: AppState) => state.openings);

  const dispatch = useDispatch();
  const http = useHttpClient('/api/v1/company');

  async function getCompanyOpenings({
    companyId,
    page,
    itemsPerPage,
    search,
    departmentList,
    statusList,
    locationList,
  }: GetOpeningsParams) {
    /** stringify array and add delimiter */
    const departmentsString = (departmentList || departments || []).join();
    const statusesString = (statusList || statuses || []).join();
    const locationsString = (locationList || locations || []).join(';');

    dispatch(openingsActions.loading());

    try {
      const { data } = await http.get<PaginatedResponse<CompanyOpening>>(
        `/${companyId}/openings`,
        {
          params: {
            per_page: itemsPerPage || perPage,
            page: page || currentPage,
            search,
            deparments: departmentsString,
            statuses: statusesString,
            locations: locationsString,
          },
        },
      );
      dispatch(
        openingsActions.setCompanyOpenings({
          companyOpenings: data.data,
          pageCount: data.last_page,
          perPage: data.per_page,
          currentPage: page || currentPage,
          searchTerm: search ? search : searchTerm,
          departments: departmentList || departments,
          statuses: statusList || statuses,
          locations: locationList || locations,
        }),
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(openingsActions.error(e));
    }
  }

  async function getOpeningsFilters(companyId: string, load = true) {
    load && dispatch(openingsActions.filtersLoading());
    try {
      const { data } = await http.get<CompanyOpeningFilters>(
        `${companyId}/openings/config`,
      );
      dispatch(openingsActions.setCompanyOpeningsFilters({ filters: data }));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(openingsActions.error(e));
    }
  }

  async function getSingleOpening(companyId: string, openingId: string) {
    dispatch(openingsActions.loading());
    try {
      const { data } = await http.get<Opening>(
        `${companyId}/openings/${openingId}/show`,
      );
      dispatch(openingsActions.addCurrentOpening(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(openingsActions.error(e));
    }
  }

  function callback(companyId: string) {
    getOpeningsFilters(companyId, false);
    getCompanyOpenings({ companyId, page: 1 });
    getPlan(companyId);
  }

  async function changeOpeningStatus(
    companyId: string,
    openingId: string,
    status: string,
  ) {
    try {
      await http.put<{ status: string }>(
        `${companyId}/openings/${openingId}/status`,
        {
          status,
        },
      );
      callback(companyId);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(openingsActions.error(e));
    }
  }

  async function archiveOpening(companyId: string, openingId: string) {
    try {
      await http.put<{ status: string }>(
        `${companyId}/openings/${openingId}/archive`,
      );
      callback(companyId);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(openingsActions.error(e));
    }
  }

  async function updateScheduleDate(
    companyId: string,
    openingId: string,
    info: ScheduleData,
  ) {
    try {
      await http.put<ScheduleData>(
        `${companyId}/openings/${openingId}/schedule`,
        info,
      );
      callback(companyId);
      toastSuccess('openings.scheduleOpening.success');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(openingsActions.error(e));
    }
  }

  async function updateCloseDate(
    companyId: string,
    openingId: string,
    expiresAt: Pick<Opening, 'expiresAt'>,
  ) {
    try {
      await http.put<Pick<Opening, 'expiresAt'>>(
        `${companyId}/openings/${openingId}/expire`,
        expiresAt,
      );
      callback(companyId);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(openingsActions.error(e));
    }
  }

  async function deleteOpening(companyId: string, openingId: string) {
    try {
      await http.delete(`/${companyId}/openings/${openingId}`);
      callback(companyId);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(openingsActions.error(e));
    }
  }

  function updatePagination(
    companyId: string,
    currentPage?: number,
    itemsPerPage?: number,
  ) {
    getCompanyOpenings({ companyId, page: currentPage, itemsPerPage });
  }

  return {
    deleteOpening,
    archiveOpening,
    updateCloseDate,
    getSingleOpening,
    updatePagination,
    getOpeningsFilters,
    getCompanyOpenings,
    updateScheduleDate,
    changeOpeningStatus,
  };
};
