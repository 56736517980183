import { ToastOptions, Slide } from 'react-toastify';

export const toastGlobal: ToastOptions = {
  bodyClassName: 'toast__body',
  closeButton: true,
  closeOnClick: false,
  draggable: false,
  transition: Slide,
  position: 'bottom-left',
  hideProgressBar: true,
  autoClose: 3000,
};

export const toastError: ToastOptions = {
  ...toastGlobal,
  className: 'toast--error',
};

export const toastSuccess: ToastOptions = {
  ...toastGlobal,
  className: 'toast--success',
};

export const toastInfo: ToastOptions = {
  ...toastGlobal,
  className: 'toast--info',
};
