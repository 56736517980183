import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';
import { removeWhiteSpace } from '../utils';

export function useTalentExpertise() {
  const [httpError, setHttpError] = useState<HttpError>();
  const [totalExpertise, setTotalExpertise] = useState(0);
  const [chosenExpertise, setChosenExpertise] = useState<
    Expertise[] | undefined
  >(undefined);

  const talentService = useHttpClient<Basics>('/api/v1/talent/profile');

  async function getExpertiseWithoutSet() {
    try {
      const {
        data: { data, total },
      } = await talentService.get<ExpertiseResponse>('/expertise');

      setHttpError(undefined);
      return { data, total };
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  async function getExpertise() {
    try {
      const {
        data: { data, total },
      } = await talentService.get<ExpertiseResponse>('/expertise');
      setTotalExpertise(total);
      setChosenExpertise(data);
      setHttpError(undefined);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  async function deleteExpertise(id: string) {
    try {
      await talentService.delete(`/expertise/${id}`);
      await getExpertise();
      setHttpError(undefined);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  async function addExpertise(expertise: string, callback: VoidFunction) {
    try {
      await talentService.post<{ names: string[] }>('/expertise', {
        names: [removeWhiteSpace(expertise)],
      });
      callback();
      setHttpError(undefined);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  return {
    httpError,
    totalExpertise,
    chosenExpertise,
    setTotalExpertise,
    setChosenExpertise,
    getExpertise,
    getExpertiseWithoutSet,
    deleteExpertise,
    addExpertise,
  };
}
