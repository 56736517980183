import { css } from '@emotion/react';

export const content = css`
  margin: calc(var(--unit) * 8) 0 calc(var(--unit) * 4);
  color: hsl(var(--color-grayscale-6));

  span {
    color: hsl(var(--color-text-1));
    font-weight: var(--font-weight-bold);
  }
`;

export const info = css`
  color: hsl(var(--color-warning-6));
  margin-bottom: calc(var(--unit) * 10);
`;

export const buttonGroup = css`
  display: flex;
  align-items: center;

  button:first-of-type {
    margin-right: calc(var(--unit) * 3);
  }
`;
