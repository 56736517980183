import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';

interface DisableData {
  otpToken: string;
}
interface EnableData extends DisableData {
  otpSecret: string;
}

export const useTwoFactor = () => {
  const http = useHttpClient<TwoFA>('/api/v1/settings/two-factor');

  const [isForm, setIsForm] = useState(false);
  const [is2fa, setIs2fa] = useState<boolean>();
  const [secret, setSecret] = useState<string>();
  const [error, setError] = useState<HttpError>();
  const [isLoading, setIsLoading] = useState(false);

  function handleError(e: unknown) {
    if (!(e instanceof HttpError)) return;
    setError(e);
  }

  async function getSecret() {
    setIsLoading(true);

    try {
      const { data } = await http.get<TwoFA>('/enable');
      setSecret(data.secret);
    } catch (e) {
      handleError(e);
    }

    setIsLoading(false);
  }

  async function getUserTwoFactor() {
    try {
      const { data } = await http.get<boolean>('/check');
      setIs2fa(data);
    } catch (e) {
      handleError(e);
    }
  }

  async function enable2fa(
    code: string,
    secret: string,
    callback: VoidFunction,
  ) {
    setError(undefined);
    try {
      await http.post<EnableData>(`/enable`, {
        otpToken: code,
        otpSecret: secret,
      });
      callback();
    } catch (e) {
      handleError(e);
    }
  }

  async function disable2fa(code: string, callback: VoidFunction) {
    setError(undefined);
    try {
      await http.post<DisableData>(`/disable`, {
        otpToken: code,
      });
      callback();
    } catch (e) {
      handleError(e);
    }
  }

  return {
    is2fa,
    getSecret,
    setIsForm,
    getUserTwoFactor,
    error,
    secret,
    isForm,
    setIs2fa,
    isLoading,
    enable2fa,
    disable2fa,
  };
};
