import { FormattedMessage } from 'gatsby-plugin-intl';
import {
  CheckboxField,
  InputField,
  SelectComponent,
  TextArea,
  useTransformSelect,
} from 'modules/app';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import * as styles from 'modules/app/styles/ProjectsForm.styles';

const DatePicker = React.lazy(() => import('../../form/DatePicker'));

function transformExperienceId(
  value: string | undefined | null,
  selectValues: {
    value: string;
    label: string;
  }[],
):
  | {
      value: string;
      label: string;
    }
  | undefined {
  const valueLabel = selectValues.filter((val) => {
    return val.value === value;
  });
  if (value && selectValues && valueLabel.length !== 0) {
    return {
      value: value,
      label: valueLabel[0].label,
    };
  }
  return undefined;
}

const relatedWithValues = [
  {
    value: 'professional-experience',
    label: (
      <FormattedMessage id="onboarding.form.fields.project_list.employment_dropdown_label" />
    ),
  },
  {
    value: 'personal',
    label: (
      <FormattedMessage id="onboarding.form.fields.project_list.personal_project_dropdown_label" />
    ),
  },
  {
    value: 'freelance',
    label: (
      <FormattedMessage id="onboarding.form.fields.project_list.freelance_dropdown_label" />
    ),
  },
];

interface Props {
  currentProject?: Project;
  resetValue: number;
  experienceData: Experience[];
}

export const TalentProjectInputs: React.FC<Props> = ({
  currentProject,
  resetValue,
  experienceData,
}) => {
  const { setValue } = useFormContext();
  const [endDateValue, setEndDateValue] = useState<string | undefined>();
  const [startDate, setStartDate] = useState<Date | undefined>(
    currentProject?.startDate ? new Date(currentProject?.startDate) : undefined,
  );
  const [isFeatured, setIsFeatured] = useState<boolean>(false);
  const [experienceList, setExperienceList] = useState<
    {
      value: string;
      label: string;
    }[]
  >(
    experienceData
      ? experienceData?.map((item) => {
          return { value: item.id, label: item.companyName };
        })
      : [],
  );
  const [isRelatedWith, setIsRelatedWith] = useState(false);

  const [selectedExperienceId, setSelectedExperienceId] = useState<
    | {
        value: string;
        label: string;
      }
    | undefined
  >(transformExperienceId(currentProject?.userExperienceId, experienceList));
  const [selectedProjectType, setSelectedProjectType] = useState<
    string | string[]
  >(currentProject?.relatedWith || '');

  const onSelectedProjectType = () => {
    if (selectedProjectType !== 'professional-experience') {
      setIsRelatedWith(false);
      setSelectedExperienceId(undefined);
      setValue('userExperienceId', null);

      return;
    }

    if (!isRelatedWith && selectedProjectType === 'professional-experience') {
      if (Boolean(experienceList.length)) {
        setExperienceList(experienceList);
        setIsRelatedWith(true);
        setValue('userExperienceId', currentProject?.userExperienceId);

        return;
      }

      setValue('userExperienceId', currentProject?.userExperienceId);
      setExperienceList(
        experienceData?.map((item) => {
          return { value: item.id, label: item.companyName };
        }),
      );
      setIsRelatedWith(true);

      return;
    }
  };

  useEffect(() => {
    setSelectedExperienceId(
      transformExperienceId(currentProject?.userExperienceId, experienceList),
    );
  }, [currentProject, experienceList]);

  useEffect(onSelectedProjectType, [selectedProjectType]);

  return (
    <>
      <div css={styles.subgridForm}>
        <InputField
          type="text"
          name="name"
          value={currentProject?.name || ''}
          constraints={{
            required: true,
            maxLength: {
              value: 150,
              message: 'shared.errors.max',
            },
          }}
          reset={resetValue}
          label="onboarding.form.fields.project_list.project_name_label"
        />

        <InputField
          type="text"
          name="owner"
          value={currentProject?.owner || ''}
          reset={resetValue}
          constraints={{
            required: true,
            maxLength: {
              value: 150,
              message: 'shared.errors.max',
            },
          }}
          label="onboarding.form.fields.project_list.owner_label"
        />

        <React.Suspense fallback={null}>
          <DatePicker
            name="startDate"
            constraints={{
              required: true,
              maxLength: 20,
            }}
            reset={resetValue}
            label="onboarding.form.fields.project_list.start_date_label"
            maxDate={true}
            setStartDate={setStartDate}
            value={currentProject?.startDate || ''}
          />

          <DatePicker
            isOptional={true}
            name="endDate"
            constraints={{}}
            setEndDateValue={setEndDateValue}
            endDateValue={endDateValue}
            reset={resetValue}
            label="onboarding.form.fields.project_list.end_date_label"
            maxDate={true}
            currentButton={true}
            defaultValue="shared.date_picker.still_on_going"
            minDate={startDate}
            isDisabled={!startDate}
            value={currentProject?.endDate || ''}
            isNullable={true}
          />
        </React.Suspense>

        <SelectComponent
          isOptional={true}
          name="relatedWith"
          reset={resetValue}
          label={
            <FormattedMessage id="onboarding.form.fields.project_list.related_with_label" />
          }
          values={relatedWithValues}
          selectedValue={useTransformSelect(
            currentProject?.relatedWith,
            relatedWithValues,
          )}
          constraints={{}}
          setSelectedValue={setSelectedProjectType}
        />

        <SelectComponent
          isOptional={true}
          name="userExperienceId"
          reset={resetValue}
          label={
            <FormattedMessage id="onboarding.form.fields.project_list.related_to_my_work_at_label" />
          }
          values={experienceList}
          constraints={{}}
          selectedValue={selectedExperienceId}
          isDisabled={!isRelatedWith}
        />
      </div>

      <InputField
        isOptional={true}
        type="text"
        name="url"
        value={currentProject?.url || ''}
        reset={resetValue}
        constraints={{
          validate: (url: string) => {
            if (!url) {
              return true;
            }

            return /^(http|https):\/\/[^ "]+$/.test(url)
              ? true
              : 'onboarding.form.errors.url';
          },
        }}
        label="onboarding.form.fields.project_list.url_label"
      />

      <TextArea
        isOptional={true}
        reset={resetValue}
        name="description"
        placeholder="onboarding.form.fields.project_list.description_placeholder"
        topLabel="onboarding.form.fields.project_list.description_label"
        value={currentProject?.description || ''}
        constraints={{
          maxLength: {
            value: 255,
            message: 'shared.errors.max',
          },
        }}
      />

      <div css={styles.makeFeatured}>
        <CheckboxField
          name={currentProject ? `featured${currentProject.id}` : 'featured'}
          checked={currentProject?.featured || isFeatured}
          constraints={{}}
          reset={resetValue}
        />
        <label onClick={() => setIsFeatured(!isFeatured)}>
          <FormattedMessage id="onboarding.form.fields.project_list.make_featured" />{' '}
          (max: 3)
        </label>
      </div>
    </>
  );
};
