import { FormattedMessage } from 'gatsby-plugin-intl';
import { ActionModal, Alert, Button, InputField, TextArea } from 'modules/app';
import React from 'react';
import * as styles from 'modules/settings/styles/Companies.styles';
import { format } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { useInvoicesCancelForm } from '../hooks';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

interface Props {
  isModalOpen: boolean;
  invoice: InvoiceResponse;
  handleCloseCancelModal: VoidFunction;
}

export const InvoiceCancelModal: React.FC<Props> = ({
  invoice,
  isModalOpen,
  handleCloseCancelModal,
}) => {
  const { error } = useSelector((state: AppState) => state.invoices);
  const form = useForm<InvoiceCancelRefundData>({
    defaultValues: {
      reason: '',
      amount: 0,
    },
  });
  const amount = form.watch('amount');

  const {
    isButtonClicked,
    handleCancelClick,
    handleRefundWithFiscalizationClick,
    handleRefundWithoutFiscalizationClick,
    handleCloseButton,
  } = useInvoicesCancelForm(form, invoice, handleCloseCancelModal);

  return (
    <ActionModal isOpen={isModalOpen} setIsOpen={handleCloseButton}>
      <h3 css={styles.modalTitle}>
        <FormattedMessage id="admin.invoices.invoicesCancelModal.title" />
      </h3>
      <div css={styles.modal}>
        <p css={styles.modalContent}>
          <FormattedMessage
            id="admin.invoices.invoicesCancelModal.text"
            values={{
              invoiceNumber: invoice.invoiceNumber,
              invoiceDate: format(new Date(invoice.createdAt), 'PP, HH:mm'),
              invoiceCompany: invoice.companyName,
            }}
          />
        </p>
        <FormProvider {...form}>
          <form>
            <TextArea
              name="reason"
              placeholder={
                'admin.invoices.invoicesCancelModal.textAreaPlaceholder'
              }
              value=""
              topLabel={'admin.invoices.invoicesCancelModal.textAreaTopLabel'}
              isOptional={false}
              maxChar="5000"
              customStyles={margin.bottom.xxxlrg}
            />
            <div css={styles.numberField}>
              <InputField
                name="amount"
                value={String(amount)}
                label={'admin.invoices.invoicesCancelModal.amountLabel'}
                placeholder={'admin.invoices.invoicesCancelModal.amountLabel'}
                constraints={{}}
              />
              <span>{invoice.currency}</span>
            </div>
            <Alert
              message={
                <FormattedMessage id="admin.invoices.invoicesRefund.error" />
              }
              show={error?.cause === 'refund_amount_not_allowed'}
              type="error"
            />
            <div css={styles.actionButtonContainer}>
              <Button
                buttonType="primary"
                size="small"
                css={styles.modalDeactivateBtn}
                type="button"
                onClick={handleCancelClick}
                disabled={isButtonClicked}
              >
                <FormattedMessage id="admin.invoices.invoicesCancelModal.buttonCancelInvoice" />
              </Button>
            </div>

            <div css={styles.actionButtonContainer}>
              <Button
                buttonType="primary"
                size="small"
                css={styles.modalDeactivateBtn}
                type="button"
                onClick={handleRefundWithoutFiscalizationClick}
                disabled={isButtonClicked}
              >
                <FormattedMessage id="admin.invoices.invoicesCancelModal.buttonRefundNoFiscal" />
              </Button>
              <span>
                <FormattedMessage id="admin.invoices.invoicesCancelModal.refundWithoutFiscalText" />
              </span>
            </div>

            <div css={styles.actionButtonContainer}>
              <Button
                buttonType="primary"
                size="small"
                css={styles.modalDeactivateBtn}
                type="button"
                onClick={handleRefundWithFiscalizationClick}
                disabled={isButtonClicked}
              >
                <FormattedMessage id="admin.invoices.invoicesCancelModal.buttonRefundWithFiscal" />
              </Button>
              <span>
                <FormattedMessage id="admin.invoices.invoicesCancelModal.refundWithFiscalText" />
              </span>
            </div>

            <div css={styles.closeButtonContainer}>
              <Button
                buttonType="ghost"
                size="small"
                type="button"
                onClick={handleCloseButton}
              >
                <FormattedMessage id="admin.invoices.invoicesCancelModal.buttonCancel" />
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </ActionModal>
  );
};
