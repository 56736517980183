import React, { useEffect } from 'react';
import { RouteComponentProps, useLocation } from '@reach/router';
import { navigate } from 'gatsby-plugin-intl';
import { useCsrfCookie } from 'modules/app';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { loginAction } from 'modules/login';

export interface Props extends RouteComponentProps {
  Component: React.FC<RouteComponentProps>;
  companyInviteToken?: string;
  companyClaimToken?: string;
}

export const PublicRouter: React.FC<Props> = ({
  Component,
  companyInviteToken,
  companyClaimToken,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { isLoggedIn } = useCsrfCookie();
  const registration = useSelector((state: AppState) => state.registration);
  const { redirect: loginRedirect } = useSelector(
    (state: AppState) => state.login,
  );
  const { user } = useSelector((state: AppState) => state.session);
  const { companyInviteToken: inviteToken, companyClaimToken: claimToken } =
    useSelector((state: AppState) => state.registration);

  const redirect = () => {
    if (!isLoggedIn() || !user) return;

    if (loginRedirect) {
      return loginRedirect;
    }

    if (registration.isSocialRegistrationProcess) {
      return '/app/social-register/social-step-one';
    }

    if (companyInviteToken || inviteToken) {
      return `/app/company/users/join/${companyInviteToken || inviteToken}`;
    }

    if (companyClaimToken || claimToken) {
      return `/app/transfer/${companyInviteToken || inviteToken}${search}`;
    }

    if (!user.talentOnboardingDone && !user.companyOnboardingDone) {
      return '/app/onboarding';
    }

    return '/profile/talent/overview/invitations';
  };

  useEffect(() => {
    return () => {
      dispatch(loginAction.loginRedirect());
    };
  }, []);

  const path = redirect();

  if (path) {
    navigate(path);

    return null;
  }

  return <Component {...rest} />;
};
