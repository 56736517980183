export function checkExpiryDate(date?: string) {
  if (!date) return false;

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const year = Number(`20${date.slice(-2)}`);
  const month = Number(date.substring(0, 2));

  if (year === currentYear && month < currentMonth) {
    return true;
  }

  return false;
}
