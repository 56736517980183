import { css } from '@emotion/react';
import { center } from 'style/global.styles';

export const root = css`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  svg {
    width: 50px;
    height: 50px;

    ${center};
  }
`;

export const imageRoot = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
