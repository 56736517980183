import React from 'react';
import { SectionBody } from 'modules/admin/components';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { InvoicesTable } from './InvoicesTable';
import Pagination from 'modules/app/components/pagination/Pagination';
import * as styles from '../styles/InvoicesBody.styles';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { useInvoiceActions } from '../hooks';
import { InvoiceCancelModal } from './InvoiceCancelModal';

interface Props {
  query: ExtraSearchQueryData;
  handlePageChange: (page: number) => void;
}

export const InvoicesBody: React.FC<Props> = ({ query, handlePageChange }) => {
  const { isLoading, invoices, error } = useSelector(
    (selector: AppState) => selector.invoices,
  );

  const { chosenInvoice, handleOpenCancelModal, handleCloseCancelModal } =
    useInvoiceActions();

  return (
    <>
      <SectionBody>
        <motion.div transition={{ duration: 0.3 }} {...FADE_IN_MOVE_Y_REGULAR}>
          <InvoicesTable
            isLoading={isLoading}
            handleOpenCancelModal={handleOpenCancelModal}
            invoices={invoices?.invoices}
          />
          {!error && invoices?.lastPage !== 1 && (
            <React.Suspense fallback={null}>
              <div css={[styles.pagination, isLoading ? styles.loading : null]}>
                <Pagination
                  forcePage={query.page - 1}
                  initialPage={query.page - 1}
                  pageCount={invoices?.lastPage || 1}
                  onPageChange={(pag) => handlePageChange(pag.selected + 1)}
                />
              </div>
            </React.Suspense>
          )}
        </motion.div>
      </SectionBody>
      {chosenInvoice && (
        <InvoiceCancelModal
          invoice={chosenInvoice}
          isModalOpen={Boolean(chosenInvoice)}
          handleCloseCancelModal={handleCloseCancelModal}
        />
      )}
    </>
  );
};
