import { useIntl } from 'gatsby-plugin-intl';

export const useTalentBasicValues = () => {
  const { formatMessage } = useIntl();

  const genderValues = [
    {
      value: 'male',
      label: formatMessage({
        id: 'onboarding.form.fields.basics.gender_male',
      }),
    },
    {
      value: 'female',
      label: formatMessage({
        id: 'onboarding.form.fields.basics.gender_female',
      }),
    },
    {
      value: '',
      label: formatMessage({
        id: 'onboarding.form.fields.basics.disability_na',
      }),
    },
  ];

  const disabilityValues = [
    {
      value: 'yes',
      label: formatMessage({
        id: 'onboarding.form.fields.basics.disability_yes',
      }),
    },
    {
      value: 'no',
      label: formatMessage({
        id: 'onboarding.form.fields.basics.disability_no',
      }),
    },
    {
      value: '',
      label: formatMessage({
        id: 'onboarding.form.fields.basics.disability_na',
      }),
    },
  ];

  return {
    genderValues,
    disabilityValues,
  };
};
