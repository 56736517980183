import React from 'react';
import * as styles from 'modules/admin/styles/AdminOverview.styles';
import { BillingActivityTableItems } from './BillingActivityTableItems';

interface Props {
  isLoading: boolean;
  billingActivity?: AdminBillingActivity[];
}

export const BillingActivityTable: React.FC<Props> = ({
  isLoading,
  billingActivity,
}) => {
  return (
    <table>
      <thead css={styles.tableHead}>
        <tr>
          <td width="300">Company name</td>
          <td width="250">Date</td>
          <td width="150">Activity type</td>
          <td width="400">Description</td>
          <td width="150">Plan</td>
          <td width="120">Amount</td>
        </tr>
      </thead>
      <tbody css={isLoading ? styles.loading : null}>
        {billingActivity &&
          billingActivity.map((activity) => (
            <BillingActivityTableItems
              key={activity.id}
              billingActivity={activity}
            />
          ))}
      </tbody>
    </table>
  );
};
