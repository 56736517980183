import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useEffect, useState } from 'react';

type CountryValue = {
  value: string;
  label: string;
};

export function useCompanyConfig() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState<ApiErrorData>();
  const [countriesValue, setCountriesValue] = useState<CountryValue[]>([]);

  const http = useHttpClient('/api/v1/assets');

  async function getCompanyConfig() {
    try {
      const {
        data: { countries },
      } = await http.get<CompanyConfig>('/companies');
      setCountriesValue(
        countries.map((country) => ({
          value: country.code,
          label: country.name,
        })),
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setLoadError(e);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getCompanyConfig();
  }, []);

  return {
    isLoading,
    loadError,
    countriesValue,
  };
}
