import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';

interface FreezeUserProps {
  userId: string;
  data: { freezeMessage: string; freezeTermsAccepted: boolean };
  callback: VoidFunction;
}

export const useFreezeUser = () => {
  const { toastError, toastSuccess } = useToastify();
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const adminService = useHttpClient('/admin/users');

  async function freezeUser({ userId, data, callback }: FreezeUserProps) {
    setIsLoading(true);
    try {
      await adminService.post(`/${userId}/freeze-account`, data);
      toastSuccess('shared.pills.successDefaultText');
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      toastError(e.message);
    }
    setIsLoading(false);
  }

  function handleCheckbox(isChecked: boolean) {
    setIsChecked(isChecked);
  }

  return {
    isLoading,
    freezeUser,
    isChecked,
    handleCheckbox,
  };
};
