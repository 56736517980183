import { ActionUnion } from 'modules/redux-store';

import { userAction } from './actions';

interface UserState {
  basics?: BasicsRequest;
  isLoading: boolean;
  error?: ApiErrorData;
}

const INITIAL_STATE: UserState = {
  basics: undefined,
  isLoading: false,
  error: undefined,
};

export const userReducer = (
  state: UserState = INITIAL_STATE,
  action: ActionUnion<typeof userAction>,
): UserState => {
  switch (action.type) {
    case 'user/USER_BASICS':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        basics: action.payload,
      };
    case 'user/LOADING':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case 'user/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case 'user/USER_RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
