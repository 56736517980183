import { createAction } from 'modules/redux-store';

export const registrationActions = {
  addStepOne: (payload: RegistrationData) =>
    createAction('registration/ADD_STEP_ONE', payload),

  addStepTwo: (payload: {
    isTosAccepted: boolean;
    isProcessingPrivacyPolicyAccepted: boolean;
  }) => createAction('registration/ADD_STEP_TWO', payload),

  addStepThree: (payload: RegistrationData) =>
    createAction('registration/ADD_STEP_THREE', payload),

  addStepFour: (payload: { otpSecret: string; otpToken: string }) =>
    createAction('registration/ADD_STEP_FOUR', payload),

  completeRegistration: () =>
    createAction('registration/COMPLETE_REGISTRATION'),

  startSocialRegistrationProcess: () =>
    createAction('registration/START_SOCIAL_REGISTRATION_PROCESS'),

  abortSocialRegistrationProcess: () =>
    createAction('registration/ABORT_SOCIAL_REGISTRATION_PROCESS'),

  setCompanyInviteToken: (payload: {
    companyInviteToken: string | undefined;
  }) => createAction('registration/COMPANY_INVITE_TOKEN', payload),

  setCompanyClaimToken: (payload: { companyClaimToken?: string }) =>
    createAction('registration/COMPANY_CLAIM_TOKEN', payload),

  reset: () => createAction('registration/RESET'),
};
