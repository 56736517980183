import { useHttpClient, useToastify } from 'modules/app';
import { useState } from 'react';
import { useGetCompanyDetails } from './useGetCompanyDetails';

export const useDeleteCompany = () => {
  const adminCompaniesService = useHttpClient('/admin/companies');
  const { getCompanyDetails } = useGetCompanyDetails();

  const [isLoading, setIsLoading] = useState(false);

  const { toastError, toastSuccess } = useToastify();

  async function deleteCompany(companyId: string, callback?: VoidFunction) {
    setIsLoading(true);

    try {
      await adminCompaniesService.delete(`/${companyId}`);
      callback?.();
      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      toastError();
    }
    setIsLoading(false);
  }

  async function deactivateCompany(companyId: string, callback?: VoidFunction) {
    setIsLoading(true);

    try {
      await adminCompaniesService.delete(`/${companyId}/deactivate`);
      const data = await getCompanyDetails(companyId);
      callback?.();
      toastSuccess('shared.pills.successDefaultText');
      return data;
    } catch (e) {
      toastError();
    }

    setIsLoading(false);
  }

  return {
    isLoading,
    deleteCompany,
    deactivateCompany,
  };
};
