import { css } from '@emotion/react';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  width: 100%;

  @media ${breakpoints.medium} {
    max-width: calc(var(--unit) * 160);
  }
`;

export const subgridForm = css`
  @media ${breakpoints.medium} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    & > div:nth-of-type(odd) {
      margin-right: 33px;
    }
  }
`;

export const subheaderGroup = css`
  margin: calc(var(--unit) * 20) 0 calc(var(--unit) * 20) 0;
`;

export const httpErrorFix = css`
  margin: calc(var(--unit) * 5) 0 calc(var(--unit) * 5) 0;
`;

export const makeFeatured = css`
  display: flex;
  justify-content: left;
  margin-bottom: calc(var(--unit) * 13);

  & > label {
    margin-left: calc(var(--unit) * 5);
  }
`;

export const cancelBtn = css`
  color: hsl(var(--color-error-7));
`;
