import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { talentOnboardingAction, useReset } from 'modules/onboarding';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

export function useTalentEducation() {
  const dispatch = useDispatch();
  const { setReset } = useReset();

  const [httpError, setHttpError] = useState<HttpError>();

  const talentProfileService = useHttpClient<Basics>('/api/v1/talent/profile');

  async function getTalentEducationsLocally(
    pageNumber?: number,
    search?: string,
    perPage?: number,
  ) {
    try {
      const {
        data: { data, lastPage, page },
      } = await talentProfileService.get<EducationResponse>('/education', {
        params: {
          page: pageNumber || 1,
          perPage: perPage || 20,
          search: search || '',
        },
      });

      setHttpError(undefined);
      return { data, lastPage, page };
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  async function getTalentEducationsDispatch(
    page: number,
    search?: string,
    perPage?: number,
    load = true,
  ) {
    load && dispatch(talentOnboardingAction.loading());

    try {
      const {
        data: { data },
      } = await talentProfileService.get<EducationResponse>('/education', {
        params: {
          page,
          perPage: perPage || 20,
          search: search || '',
        },
      });

      const educationsList: ListingData[] = data?.map((item) => ({
        title: item.educationalInstitution,
        content: item.fieldOfStudy,
        id: item.id,
      }));

      dispatch(talentOnboardingAction.setListArray(educationsList));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(talentOnboardingAction.error(e));
    }
  }

  async function addTalentEducation(
    educationData: EducationRequest,
    isOnboarding?: boolean,
  ) {
    try {
      const { data } = await talentProfileService.post<
        Education,
        EducationRequest
      >('/education', educationData);
      if (!data) return;

      setHttpError(undefined);
      if (isOnboarding) {
        setReset();
        getTalentEducationsDispatch(1);
        return;
      }

      return { data };
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  async function updateTalentEducation(
    education: EducationRequest,
    id: string,
  ) {
    try {
      await talentProfileService.put(`/education/${id}`, education);
      setHttpError(undefined);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
      return { error: e };
    }
  }

  async function deleteTalentEducation(id: string) {
    try {
      await talentProfileService.delete(`/education/${id}`);
      await getTalentEducationsDispatch(1);
      setHttpError(undefined);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
      return { error: e };
    }
  }

  return {
    httpError,
    getTalentEducationsLocally,
    getTalentEducationsDispatch,
    addTalentEducation,
    updateTalentEducation,
    deleteTalentEducation,
  };
}
