import { useCsrfCookie } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { AppState } from 'modules/redux-store';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'throttle-debounce';
import { talentOpeningsAction } from '..';
import { OpeningsSearchService } from '../http';

export const useSearchOpenings = () => {
  const { getCsrf } = useCsrfCookie();
  const csrf = getCsrf();

  const { params } = useSelector((state: AppState) => state.talentOpenings);

  const openingsSearchService = useMemo(
    () => new OpeningsSearchService(),
    [csrf],
  );

  const dispatch = useDispatch();

  async function searchOpenings(info: OpeningsSearchParams, perPage = 6) {
    try {
      dispatch(talentOpeningsAction.loading());
      const isLocation = Boolean(info.xLocation && info.yLocation);
      const sort = isLocation ? { sort_by: 'distance', sort: 'ASC' } : {};
      const requestHeaders = isLocation
        ? {
            'x-location-latitude': info.xLocation,
            'x-location-longitude': info.yLocation,
          }
        : {};

      const {
        data: { data, last_page, page },
      } = await openingsSearchService.get<OpeningsSearchResponse>('', {
        headers: requestHeaders,
        params: {
          ...sort,
          page: info.page,
          per_page: perPage,
          search: info.search || undefined,
          career_level: info.careerLevel,
          contract_type: info.contractType,
          type_of_workplace: info.typeOfWorkplace,
          working_hours: info.workingHours,
          location: info.location,
        },
      });

      dispatch(
        talentOpeningsAction.searchOpenings({
          openings: data,
          page: page,
          lastPage: last_page,
        }),
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(talentOpeningsAction.error(e));
    }
  }

  const onSearch = debounce(300, (value: string) => {
    dispatch(
      talentOpeningsAction.searchParams({
        params: {
          ...params,
          search: value,
          page: 1,
        },
      }),
    );
  });

  const onPageChange = (data: { selected: number }) => {
    dispatch(
      talentOpeningsAction.searchParams({
        params: {
          ...params,
          page: data.selected + 1,
        },
      }),
    );
  };

  return {
    searchOpenings,
    onSearch,
    onPageChange,
  };
};
