export function isAdmin(member: Users, allAdminIds?: string[]) {
  if (!allAdminIds) return false;
  return Boolean(allAdminIds.find((adminId) => adminId === member.id));
}

export function isOnlyAdmin(
  member: Users,
  adminCount: number,
  allAdminIds?: string[],
) {
  if (adminCount < 1) return false;

  return isAdmin(member, allAdminIds) && adminCount < 2;
}
