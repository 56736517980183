import { ActionUnion } from 'modules/redux-store';

import { companyDashboardAction } from './actions';

interface DashboardInitState {
  error?: ApiErrorData;
  stats?: CompanyStats;
  visits?: CompanyVisits;
}

const INITIAL_STATE: DashboardInitState = {};

export const companyDashboardReducer = (
  state: DashboardInitState = INITIAL_STATE,
  action: ActionUnion<typeof companyDashboardAction>,
): DashboardInitState => {
  switch (action.type) {
    case 'companyDashboard/COMPANY_STATS':
      return {
        ...state,
        error: undefined,
        stats: action.payload,
      };
    case 'companyDashboard/COMPANY_VISITS':
      return {
        ...state,
        error: undefined,
        visits: action.payload,
      };
    case 'companyDashboard/ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'companyDashboard/RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
