import { format } from 'date-fns';
import { FlatLink } from 'modules/admin/components';
import React from 'react';

interface Props<T> {
  query: T;
  setQuery: React.Dispatch<React.SetStateAction<T>>;
}

export function useSearchParams<T>({ query, setQuery }: Props<T>) {
  const handleSearch = (
    e: React.KeyboardEvent<HTMLInputElement> &
      React.ChangeEvent<HTMLInputElement>,
  ) => {
    setQuery({
      ...query,
      input: e.target.value,
    });
    if (e.key === 'Enter') {
      setQuery({
        ...query,
        page: 1,
        search: e.target.value,
      });
    }

    if (e.target.value === '') {
      setQuery({
        ...query,
        page: 1,
        search: '',
      });
    }
  };

  const handlePageChange = (page: number) => {
    setQuery({
      ...query,
      page: page,
    });
  };

  const handleChangeSortBy = (option: string) => {
    setQuery({
      ...query,
      sortBy: option,
    });
  };

  const handleChangeSearchBy = (option: string) => {
    setQuery({
      ...query,
      searchBy: option,
      search: '',
      input: '',
    });
  };

  const handleChangeSort = (option: string) => {
    setQuery({
      ...query,
      sort: option,
    });
  };

  const handleStartDateChange = (date: Date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    setQuery({
      ...query,
      startDate: formattedDate,
    });
  };

  const handleEndDateChange = (date: Date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    setQuery({
      ...query,
      endDate: formattedDate,
    });
  };

  const handleGenerateSearchFilterOptions = (
    options: string[],
    activeFilter: string,
  ) => {
    return options.map((item: string) => ({
      id: item,
      element: <FlatLink text={item} active={Boolean(activeFilter === item)} />,
    }));
  };

  return {
    handleSearch,
    handlePageChange,
    handleChangeSortBy,
    handleChangeSearchBy,
    handleChangeSort,
    handleStartDateChange,
    handleEndDateChange,
    handleGenerateSearchFilterOptions,
  };
}
