import { useSelector } from 'react-redux';
import { AppState } from '../../redux-store';

const allPermissions = {
  addEditInformation: true,
  deleteInformation: true,
  uploadFiles: true,
  removeFiles: true,
  pipelineProcess: true,
  templatesCommunication: true,
  templatesTests: true,
  templatesReasons: true,
  autoResponses: true,
  closeCompany: true,
  createOpening: true,
  createApplicationForm: true,
  editOpening: true,
  editApplication: true,
  publishShareOpening: true,
  accessSettings: true,
  closeAccount: true,
  privacy: true,
  security: true,
  notifications: true,
  profileDataCopy: true,
  dashboard: true,
  applications: true,
  requestTests: true,
  deleteApplication: true,
  deleteCandidate: true,
  changeStage: true,
  disqualify: true,
  tagCandidates: true,
  candidateDetails: true,
  notesOnApplications: true,
  tasksOnApplications: true,
  closeApplicationWithinAssignedDepartment: true,
  closeApplicationOutsideAssignedDepartment: true,
  events: true,
  taskManagement: true,
  activityLog: true,
  strategicReports: true,
  pipelineReports: true,
  conversionReport: true,
  closeCompanyAccount: true,
  companyTeamMembers: true,
  companyDepartments: true,
  calendar: true,
  messaging: true,
  subscriptionsAndBilling: true,
};

const permissionsTable: Record<
  'limited_team_member' | 'team_member' | 'reviewer' | 'billing_manager',
  Partial<Record<keyof typeof allPermissions, boolean>>
> = {
  limited_team_member: {
    accessSettings: true,
    closeAccount: true,
    privacy: true,
    security: true,
    notifications: true,
    profileDataCopy: true,
    dashboard: true,
    applications: true,
    requestTests: true,
    deleteApplication: true,
    deleteCandidate: true,
    changeStage: true,
    disqualify: true,
    tagCandidates: true,
    candidateDetails: true,
    notesOnApplications: true,
    tasksOnApplications: true,
    closeApplicationWithinAssignedDepartment: true,
    events: true,
    taskManagement: true,
    activityLog: true,
    strategicReports: true,
    pipelineReports: true,
    conversionReport: true,
    calendar: true,
    messaging: true,
  },
  team_member: {
    addEditInformation: true,
    deleteInformation: true,
    uploadFiles: true,
    removeFiles: true,
    pipelineProcess: true,
    templatesCommunication: true,
    templatesTests: true,
    templatesReasons: true,
    autoResponses: true,
    createOpening: true,
    createApplicationForm: true,
    editOpening: true,
    editApplication: true,
    publishShareOpening: true,
    accessSettings: true,
    closeAccount: true,
    privacy: true,
    security: true,
    notifications: true,
    profileDataCopy: true,
    dashboard: true,
    applications: true,
    requestTests: true,
    deleteApplication: true,
    deleteCandidate: true,
    changeStage: true,
    disqualify: true,
    tagCandidates: true,
    candidateDetails: true,
    notesOnApplications: true,
    tasksOnApplications: true,
    closeApplicationWithinAssignedDepartment: true,
    closeApplicationOutsideAssignedDepartment: true,
    events: true,
    taskManagement: true,
    activityLog: true,
    strategicReports: true,
    pipelineReports: true,
    conversionReport: true,
    calendar: true,
    messaging: true,
  },
  reviewer: {
    accessSettings: true,
    closeAccount: true,
    privacy: true,
    security: true,
    notifications: true,
    profileDataCopy: true,
    dashboard: true,
    applications: true,
    notesOnApplications: true,
    tasksOnApplications: true,
    taskManagement: true,
    activityLog: true,
  },
  billing_manager: {
    accessSettings: true,
    closeCompany: true,
    privacy: true,
    security: true,
    notifications: true,
    profileDataCopy: true,
    subscriptionsAndBilling: true,
    taskManagement: true,
  },
};

export const useNormalizedPermissions = () => {
  const roles = useSelector(
    (state: AppState) => state.session.userCompanyRoles,
  );

  return (
    permission: keyof typeof allPermissions,
    companyHandleOrId: string | undefined,
  ): boolean => {
    if (!companyHandleOrId) {
      return false;
    }

    if (roles) {
      for (const role of roles) {
        if (
          role.handle === companyHandleOrId ||
          role.companyId === companyHandleOrId
        ) {
          if (role.companyRole.id === 'administrator') {
            return true;
          }

          const tableEntry = permissionsTable[role.companyRole.id];

          return Boolean(tableEntry[permission]);
        }
      }
    }

    return false;
  };
};
