import { useCheckIfImpersonate } from 'modules/admin/static';
import { useGracePeriod } from 'modules/app';
import { AppState } from 'modules/redux-store';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useCreateOpeningCheck = () => {
  const { isGracePeriod } = useGracePeriod();
  const isImpersonate = useCheckIfImpersonate();
  const { activePlan } = useSelector((state: AppState) => state.billing);
  const { user } = useSelector((state: AppState) => state.adminImpersonate);

  const isDisabled = useMemo(() => {
    if (isGracePeriod) return true;

    if (!isImpersonate) return false;

    return user?.adminRole !== 'SuperAdmin' || activePlan?.planPrice !== 0;
  }, [isImpersonate, user?.adminRole, isGracePeriod, activePlan?.planPrice]);

  return isDisabled;
};
