import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { useParams } from '@reach/router';
import { useOpenings } from './useOpenings';

export const useOpeningFlow = () => {
  const { companyData } = useSelector((state: AppState) => state.companyData);
  const { currentOpening, isLoading, error } = useSelector(
    (state: AppState) => state.openings,
  );
  const { getSingleOpening } = useOpenings();

  const params = useParams();
  const openingId = params.openingId;

  useEffect(() => {
    if (!companyData?.id) return;

    getSingleOpening(companyData.id, openingId);
  }, [companyData, openingId]);

  return {
    currentOpening,
    companyData,
    isLoading,
    error,
  };
};
