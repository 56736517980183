import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';

export const search = css`
  min-width: ${spacing(580)};
`;

export const searchFilter = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const sortByDropdown = css`
  font-weight: var(--font-weight-regular);
  padding: 0;
  margin-bottom: ${spacing(16)};
`;

export const datePickers = css`
  display: flex;

  & > div {
    margin-right: ${spacing(32)};
  }
`;
