import { ActionUnion } from 'modules/redux-store';
import { applicantsActions } from './actions';

interface InvoicesInitState {
  isLoading: boolean;
  error?: ApiErrorData;
  applicants?: SearchResponse<Applicant>;
  openings: OpeningsList[];
}

const INITIAL_STATE: InvoicesInitState = {
  isLoading: false,
  applicants: undefined,
  openings: [],
};

export const applicantsReducer = (
  state: InvoicesInitState = INITIAL_STATE,
  action: ActionUnion<typeof applicantsActions>,
): InvoicesInitState => {
  switch (action.type) {
    case 'applicants/LOADING':
      return {
        ...state,
        isLoading: true,
      };
    case 'applicants/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'applicants/SET_APPLICANTS':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        applicants: action.payload,
      };
    case 'applicants/SET_OPENINGS':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        openings: action.payload,
      };
    case 'applicants/RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
