import { useEffect, useRef, useState } from 'react';
import { makeBaseImagePath } from './makeBaseImagePath';

export const useImageLoader = (
  imgSrc: string | undefined | null,
  onLoaded?: () => void,
): boolean => {
  const [isLoading, setIsLoading] = useState(true);
  const loadingCheckRef = useRef<boolean>(true);

  const loadingEffect = () => {
    if (!imgSrc) {
      setIsLoading(false);
    }

    if (imgSrc) {
      if (!isLoading) {
        setIsLoading(true);
      }

      loadingCheckRef.current = true;

      const img = new Image();
      img.src = makeBaseImagePath(imgSrc);

      img.onload = () => {
        if (loadingCheckRef.current) {
          setIsLoading(false);
          loadingCheckRef.current = true;
          onLoaded?.();
        }
      };
    }

    return () => {
      loadingCheckRef.current = false;
    };
  };

  useEffect(loadingEffect, [imgSrc]);

  return isLoading;
};
