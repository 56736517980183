import React from 'react';
import { useLocation } from '@reach/router';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { Avatar } from '../Avatar';

import * as styles from './styles/Dropdown.styles';
import SmallCheck from 'assets/icons/checkGreen.svg';
import Eye from 'assets/icons/showPassword.svg';
import { LoadingImg } from 'modules/app';
import { useGetCompanyInfo } from './hooks';

interface Props {
  company: UserCompanyRoles;
}

export const CompanyLink: React.FC<Props> = ({ company }) => {
  const { pathname } = useLocation();
  const handle = useSelector(
    (state: AppState) => state.companyData.companyData?.handle,
  );
  const { isLoading, getCompany } = useGetCompanyInfo();
  const isActive =
    pathname.includes('profile') &&
    pathname.includes('company') &&
    handle === company.handle;

  const getCompanyData = () => {
    if (isActive) return;

    getCompany(company.companyId, company.handle);
  };

  return (
    <button
      key={company.id}
      css={isActive ? styles.linkActive : styles.link}
      onClick={getCompanyData}
    >
      <div css={styles.avatarWrapper}>
        <Avatar
          customStyles={styles.avatar}
          name={company.name}
          imagePath={company.iconPicture?.small || null}
          isShadow={false}
        />

        <p css={styles.name}>{company.name}</p>
        <SmallCheck className="check" />
      </div>

      {isLoading ? (
        <LoadingImg customStyles={styles.loadingSvg} />
      ) : (
        <Eye className="eye" />
      )}
    </button>
  );
};
