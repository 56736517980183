import {
  flex,
  font,
  margin,
  text,
} from '@prototyp/gatsby-plugin-gumball/utils';
import { TalentList } from 'modules/admin/components';
import SearchIcon from 'assets/icons/search.svg';
import ClearIcon from 'assets/icons/clear.svg';
import * as buttonStyles from 'modules/app/styles/Button.styles';
import { Button, Loading, TextInput } from 'modules/app';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { useGetTalents } from 'modules/admin/hooks';

interface Props {
  role: string;
  companyId?: string;
  handleClick: VoidFunction;
  onAssign: (id: string, role: string) => void;
}

export const AddMembers: React.FC<Props> = ({
  role,
  companyId,
  handleClick,
  onAssign,
}) => {
  const [term, setTerm] = useState('');
  const [search, setSearch] = useState(term);
  const [talents, setTalents] = useState<Users[]>();
  const { getTalents, isLoading } = useGetTalents(setTalents);

  const handleSetTalents = (data?: Users[]) => {
    setTalents(data);
  };

  const getTalentData = useCallback(
    (search: string, count: number) =>
      getTalents(
        {
          search: search,
          perPage: count,
          hideDeleted: true,
          hideStaff: true,
        },
        handleSetTalents,
      ),
    [getTalents],
  );

  const handleAssign = useCallback(
    (id: string) => {
      onAssign(id, role);
    },
    [onAssign, role, search],
  );

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || (Boolean(search) && !Boolean(term))) {
      setSearch(event.currentTarget.value);
      getTalentData(
        event.currentTarget.value,
        event.currentTarget.value ? 15 : 5,
      );
    }
  };

  useEffect(() => {
    getTalentData(search, search ? 15 : 5);
  }, [companyId, handleAssign]);

  return (
    <>
      <div css={[flex.display.flex, flex.justify.between, margin.bottom.xlrg]}>
        <div css={[font.size.large, text.transform.capitalize]}>
          Add {<FormattedMessage id={`settings.membersContent.${role}`} />}
        </div>
        <Button
          buttonType="neutral"
          size="small"
          type="button"
          onClick={handleClick}
        >
          Cancel
        </Button>
      </div>
      <TextInput
        placeholder="Search Users by Name or Email"
        value={term}
        onChange={(e) => setTerm(e.currentTarget.value)}
        onKeyUp={handleEnter}
        icon={
          search ? (
            <ClearIcon
              css={buttonStyles.root}
              onClick={() => {
                setTerm('');
                setSearch('');
                getTalentData('', 5);
              }}
            />
          ) : (
            <SearchIcon />
          )
        }
      />
      <Loading
        isLoading={isLoading}
        component={
          <React.Suspense fallback={null}>
            {Boolean(talents) && (
              <TalentList
                talents={talents}
                search={search}
                role={role}
                companyId={companyId}
                editRole={handleAssign}
              />
            )}
          </React.Suspense>
        }
      />
    </>
  );
};
