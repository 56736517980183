import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils/breakpoints';

import {
  root as btn,
  largeExtended,
  mediumExtended,
} from 'modules/app/styles/Button.styles';

export const root = css`
  ${btn};
  ${largeExtended};
  position: relative;
  background: none;

  > span {
    z-index: 1;
  }

  &::before {
    content: '';
    border-radius: var(--border-radius-regular);
    height: 100%;
    width: 0;
    position: absolute;
    top: 0;
    left: 0;

    transition: width 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  }

  @media ${breakpoints.mediumMax} {
    ${mediumExtended}
  }
`;

export const primary = css`
  ${root}
  background-color: hsl(var(--color-robin-2));
  color: hsl(var(--color-text-1));
  transition: all 0.3s ease-out;

  &::before {
    background-color: hsl(var(--color-text-1));
  }

  &:hover,
  &:active {
    color: hsl(var(--color-white-11));

    &::before {
      width: 100%;
    }
  }
`;

export const secondary = css`
  ${root};
  padding: ${spacing(15)} ${spacing(51)};
  border: 1px solid hsl(var(--color-grayscale-3));
  color: hsl(var(--color-text-1));

  &::before {
    background-color: hsl(var(--color-grayscale-3));
    z-index: 0;
  }

  &:hover,
  &:active {
    &::before {
      width: 100%;
    }
  }
`;

export const primaryDark = css`
  ${primary};

  &::before {
    background-color: hsl(var(--color-grayscale-1));
  }

  &:hover,
  &:active {
    color: hsl(var(--color-text-1));

    &::before {
      width: 100%;
    }
  }
`;

export const secondaryDark = css`
  ${secondary};
  color: hsl(var(--color-grayscale-1));

  &:hover,
  &:active {
    color: hsl(var(--color-text-1));
  }
`;
