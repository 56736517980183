export function isOpeningActive(conversation?: Conversation) {
  if (!conversation) return false;

  const activeStatus: PublishStatus[] = [
    'closed',
    'published-public',
    'published-internal',
    'scheduled-for-publishing-(internal)',
    'scheduled-for-publishing-(public)',
  ];

  return activeStatus.includes(conversation?.openingStatus);
}
