import React from 'react';
import CaretDown from 'assets/icons/down.svg';
import * as styles from 'modules/app/styles/Dropdown.styles';
import * as buttonStyles from 'modules/app/styles/Button.styles';
import { AnimatePresence, motion } from 'framer-motion';
import {
  FADE_IN_MOVE_Y_REGULAR,
  FADE_IN_MOVE_Y_REVERSE,
} from 'style/animations.config';
import { LoadingImg } from 'modules/app';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { SingleMenu } from './SingleMenu';
import { MultiSelectMenu } from './MultiSelectMenu';
import { DropdownSearch } from './DropdownSearch';
import { useDropdownMenu } from './hooks';

export type ItemType = {
  id: string;
  element: JSX.Element | string;
  disabled?: boolean;
};

export type NewItemType = {
  id: string;
  element: string;
  disabled?: boolean;
  items?: { id: string; element: string }[];
};

interface Props {
  placeholder?: JSX.Element;
  value?: { id: string; title: JSX.Element };
  onSelect?: (id: string) => void;
  items?: Array<ItemType>;
  position?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  theme?: 'light' | 'dark';
  hideCaret?: boolean;
  customButtonStyles?: SerializedStyles | SerializedStyles[];
  isLoading?: boolean;
  disabled?: boolean;
  newItems?: Array<NewItemType>;
  onNewSelect?: (firstItemId: string, secondItemId?: string) => void;
  newPosition?: 'left' | 'right';
  textPlaceholder?: string;
  isSearch?: boolean;
}

export const DropdownMenu: React.FC<Props> = ({
  placeholder,
  value,
  items,
  onSelect,
  position = 'bottomRight',
  theme = 'light',
  hideCaret,
  customButtonStyles,
  isLoading,
  disabled,
  newItems,
  onNewSelect,
  newPosition = 'right',
  textPlaceholder,
  isSearch = false,
}) => {
  const {
    ref,
    values,
    expanded,
    wrapperRef,
    currentItem,
    isOtherExpanded,
    onSearch,
    setExpanded,
    setCurrentItem,
    setIsOtherExpanded,
  } = useDropdownMenu(items);

  const TRANSITION =
    position === 'bottomLeft' || position === 'bottomRight'
      ? {
          ...FADE_IN_MOVE_Y_REGULAR,
        }
      : {
          ...FADE_IN_MOVE_Y_REVERSE,
        };

  return (
    <div ref={wrapperRef} css={styles.root}>
      <button
        type="button"
        onClick={() => {
          setExpanded(!expanded);
          setIsOtherExpanded(false);
        }}
        css={[buttonStyles.root, buttonStyles.small, customButtonStyles]}
        disabled={disabled}
      >
        {value?.title || placeholder}
        {!hideCaret && (
          <>
            {isLoading ? (
              <LoadingImg customStyles={styles.loading} />
            ) : (
              <CaretDown css={expanded ? styles.caretRotated : styles.caret} />
            )}
          </>
        )}
      </button>

      <AnimatePresence>
        {expanded && (
          <motion.div
            transition={{ duration: 0.2 }}
            {...TRANSITION}
            exit={{ opacity: 0, y: 10, transition: { duration: 0.2 } }}
            css={[styles[position], styles.theme(theme)]}
            ref={ref}
          >
            {isSearch && <DropdownSearch handleOnChange={onSearch} />}

            <div css={styles.dropdownWrapper}>
              {textPlaceholder && (
                <span css={styles.textPlaceholder}>
                  {<FormattedMessage id={textPlaceholder} />}
                </span>
              )}

              {values && onSelect && (
                <SingleMenu
                  items={values}
                  onSelect={onSelect}
                  setExpanded={setExpanded}
                />
              )}

              {newItems && onNewSelect && (
                <MultiSelectMenu
                  items={newItems}
                  onSelect={onNewSelect}
                  currentItem={currentItem}
                  setCurrentItem={setCurrentItem}
                  setExpanded={setExpanded}
                  isOtherExpanded={isOtherExpanded}
                  position={newPosition}
                  setIsOtherExpanded={setIsOtherExpanded}
                  theme={theme}
                />
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
