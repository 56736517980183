import React from 'react';
import { RouteComponentProps, useLocation } from '@reach/router';
import { navigate } from 'gatsby-plugin-intl';
import { normalizeRedirectUrl, useCsrfCookie } from 'modules/app';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { loginAction } from 'modules/login';

interface Props extends RouteComponentProps {
  Component: React.FC<RouteComponentProps>;
  companyInviteToken?: string;
  companyClaimToken?: string;
}

export const ProtectedRouter: React.FC<Props> = ({
  Component,
  companyInviteToken,
  companyClaimToken,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { pathname, hash, search } = useLocation();
  const { isLoggedIn } = useCsrfCookie();
  const registration = useSelector((state: AppState) => state.registration);
  const { user } = useSelector((state: AppState) => state.session);

  const redirect = () => {
    if (!isLoggedIn() && companyInviteToken) {
      return `/login/invite/${companyInviteToken}`;
    }

    if (!isLoggedIn() && companyClaimToken) {
      return `/login/claim/${companyClaimToken}${search}`;
    }

    if (!isLoggedIn() || !user) {
      dispatch(
        loginAction.loginRedirect(normalizeRedirectUrl(pathname, search, hash)),
      );
      return '/login';
    }

    if (registration.isSocialRegistrationProcess) {
      return '/app/social-register/social-step-one';
    }

    return;
  };

  const path = redirect();

  if (path) {
    navigate(path);

    return null;
  }

  return <Component {...rest} />;
};
