import { RegisterOptions } from 'react-hook-form';

type ClosureDefinition = (
  val: string,
  onValid?: (val: string) => void,
) => boolean;

export const useMaxNumber = (
  constraints: RegisterOptions,
): ClosureDefinition => {
  return function (
    inputValue: string,
    onValid?: (val: string) => void,
  ): boolean {
    // if its an empty string
    if (!inputValue) {
      return true;
    }

    if (
      constraints.maxLength &&
      constraints.maxLength.value === inputValue.length
    ) {
      if (onValid) {
        onValid(inputValue);
      }

      return true;
    } else if (
      constraints.maxLength &&
      inputValue.length > constraints.maxLength.value
    ) {
      return false;
    }

    if (isNaN(parseInt(inputValue))) {
      return false;
    }

    return true;
  };
};
