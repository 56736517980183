import React from 'react';
import { SectionBody } from 'modules/admin/components';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import Pagination from 'modules/app/components/pagination/Pagination';
import * as invoicesBodyStyles from 'modules/admin/invoices/styles/InvoicesBody.styles';
import { ApplicantsTable } from './ApplicantsTable';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { Button } from 'modules/app';
import { useApplicants } from '../hooks';
import { FormattedMessage } from 'gatsby-plugin-intl';

interface Props {
  query: ApplicantsQuery;
  handlePageChange: (page: number) => void;
}

export const ApplicantsBody: React.FC<Props> = ({
  query,
  handlePageChange,
}) => {
  const { exportApplicants } = useApplicants();
  const { isLoading, applicants, error } = useSelector(
    (selector: AppState) => selector.applicants,
  );

  function onExportClick() {
    if (!query.openingIds?.length) return;

    const openingIds = query.openingIds.split(',');
    exportApplicants(openingIds);
  }

  return (
    <SectionBody>
      <motion.div transition={{ duration: 0.3 }} {...FADE_IN_MOVE_Y_REGULAR}>
        <div css={invoicesBodyStyles.exportContainer}>
          <Button
            buttonType={'outline'}
            size={'tiny'}
            onClick={onExportClick}
            disabled={!query.openingIds?.length}
          >
            <FormattedMessage id="admin.shared.exportList" />
          </Button>
          {!query.openingIds?.length && (
            <span>
              <FormattedMessage id="admin.applicatns.applicantsExport.disclaimer" />
            </span>
          )}
        </div>
        <ApplicantsTable isLoading={isLoading} applicants={applicants?.data} />
        {!error && applicants?.lastPage !== 1 && (
          <React.Suspense fallback={null}>
            <div
              css={[
                invoicesBodyStyles.pagination,
                false ? invoicesBodyStyles.loading : null,
              ]}
            >
              <Pagination
                forcePage={query.page - 1}
                initialPage={query.page - 1}
                pageCount={applicants?.lastPage || 1}
                onPageChange={(page) => handlePageChange(page.selected + 1)}
              />
            </div>
          </React.Suspense>
        )}
      </motion.div>
    </SectionBody>
  );
};
