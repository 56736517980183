import React, { ButtonHTMLAttributes } from 'react';
import { SerializedStyles } from '@emotion/react';

import * as styles from 'modules/app/styles/IconButton.styles';
import { LoadingImg } from 'modules/app';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: ButtonType;
  icon?: React.ReactNode;
  css?: SerializedStyles;
  size?: 'small' | 'medium' | 'large' | 'tiny';
  isLoading?: boolean;
}

export const IconButton: React.FC<Partial<Props>> = ({
  buttonType = 'primary',
  icon,
  css,
  size,
  isLoading,
  ...rest
}) => {
  return (
    <>
      {isLoading ? (
        <button
          css={[
            styles.root,
            styles[buttonType],
            size && styles[size],
            isLoading &&
              (buttonType === 'primary'
                ? styles.loadingPrimary
                : styles.loading),
            css,
          ]}
        >
          <LoadingImg />
        </button>
      ) : (
        <button
          css={[styles.root, styles[buttonType], size && styles[size], css]}
          {...rest}
        >
          {icon}
        </button>
      )}
    </>
  );
};
