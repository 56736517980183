import { useState } from 'react';

export const useCounter = () => {
  const [counter, setCounter] = useState<number>(0);

  return {
    counter,
    addCount: () => {
      setCounter((counter) => ++counter);
    },
    reset: () => setCounter(0),
  };
};
