import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';

export const useGdpr = () => {
  const { toastError, toastSuccess } = useToastify();

  const [error, setError] = useState<HttpError>();
  const [isLoading, setIsLoading] = useState(false);
  const [existingGdpr, setExistingGdpr] = useState<Gdpr>();
  const [isChecked, setIsChecked] = useState(Boolean(existingGdpr));

  const http = useHttpClient('/api/v1/company');

  async function getGdpr(companyHandle: string) {
    setIsLoading(true);
    try {
      const { data } = await http.get<Gdpr>(
        `${companyHandle}/settings/gdpr-settings`,
      );
      setExistingGdpr(data);
      setIsChecked(true);
    } catch (e) {
      if (!(e instanceof HttpError) || e.status === 404) return;
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function deleteGdpr(companyId: string, gdprId: string) {
    try {
      await http.delete(`${companyId}/settings/gdpr/${gdprId}`);
      setExistingGdpr(undefined);
    } catch (e) {
      toastError();
    }
  }

  async function addGdpr(companyId: string, info: CreateUpdateGdpr) {
    setIsLoading(true);
    try {
      const { data } = await http.post<Gdpr, CreateUpdateGdpr>(
        `/${companyId}/settings/gdpr`,
        info,
      );

      toastSuccess('settings.gdprPage.successCreatedText');
      setExistingGdpr(data);
    } catch (e) {
      toastError();
    } finally {
      setIsLoading(false);
    }
  }

  async function editGdpr(
    companyId: string,
    gdprId: string,
    data: CreateUpdateGdpr,
    callback: VoidFunction,
  ) {
    setIsLoading(true);
    try {
      await http.put<CreateUpdateGdpr>(
        `${companyId}/settings/gdpr/${gdprId}`,
        data,
      );
      callback();
      toastSuccess('settings.gdprPage.successUpdatedText');
    } catch (e) {
      toastError();
    } finally {
      setIsLoading(false);
    }
  }

  return {
    error,
    isLoading,
    isChecked,
    existingGdpr,
    getGdpr,
    addGdpr,
    editGdpr,
    deleteGdpr,
    setIsChecked,
  };
};
