import { DropdownMenu } from 'modules/app';
import React, { useMemo } from 'react';
import * as styles from '../../styles/Table.styles';
import Delete from 'assets/icons/delete.svg';
import Ban from 'assets/icons/closeOutline.svg';
import MoreOptionsIcon from 'assets/icons/moreOptions.svg';
import { UserCard } from '..';
import { flex, font, text } from '@prototyp/gatsby-plugin-gumball/utils';
import { formatDate } from 'modules/admin/static';
import { Link } from 'gatsby-plugin-intl';
import { FlatLink } from '../FlatLink';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

interface Props {
  company: Companies;
  onDelete: (id: string) => void;
  onDeactivate: (id: string) => void;
}

export const CompanyData: React.FC<Props> = ({
  company,
  onDelete,
  onDeactivate,
}) => {
  const { userStaffRole } = useSelector((state: AppState) => state.session);

  const isSupport = useMemo(
    () => userStaffRole?.name === 'Support',
    [userStaffRole],
  );

  const userActions = [
    {
      id: 'delete',
      disabled: !company.deletedAt || isSupport,
      element: <FlatLink text="Delete" icon={<Delete />} />,
    },
    {
      id: 'ban',
      disabled: !!company.deletedAt || isSupport,
      element: <FlatLink text="Deactivate" icon={<Ban />} />,
    },
  ];

  const planStatusStyles =
    company.planStatus === 'active' ? styles.successPill : styles.errorPill;

  const handleSelect = (event: CompaniesActions) => {
    switch (event) {
      case 'ban':
        onDeactivate(company.id);
        return;
      case 'delete':
        onDelete(company.id);
        return;
      default:
        return;
    }
  };

  return (
    <tr css={styles.row}>
      <td css={styles.data} width="200">
        <Link to={`/admin/companies/${company.id}`}>
          <UserCard
            profilePicture={company.iconPicture?.small}
            name={company.name}
            info={company.handle}
          />
        </Link>
      </td>
      <td>
        <div css={[flex.display.flex, flex.direction.column, flex.align.start]}>
          <p css={font.weight.bold}>{company.representativeFullName}</p>
          <p css={[text.color.textLight, font.size.small]}>
            {company.representativeEmail}
          </p>
        </div>
      </td>
      <td>
        <div css={[flex.display.flex, flex.direction.column, flex.align.start]}>
          <p css={font.weight.bold}>
            {formatDate(company.registeredAt, 'LLL do, HH:mm')}
          </p>
        </div>
      </td>
      <td>
        <div css={[flex.display.flex, flex.direction.column, flex.align.start]}>
          <p css={font.weight.bold}>{company.planName}</p>
          {company.planStatus && (
            <p css={planStatusStyles}>{company.planStatus}</p>
          )}
        </div>
      </td>
      <td css={styles.action}>
        <DropdownMenu
          onSelect={handleSelect}
          hideCaret={true}
          placeholder={<MoreOptionsIcon css={styles.button} />}
          items={userActions}
          position="bottomRight"
          disabled={isSupport}
        />
      </td>
    </tr>
  );
};
