import React from 'react';
import { isFuture } from 'date-fns';
import { FormattedMessage } from 'gatsby-plugin-intl';

interface Props {
  openingStatus: PublishStatus;
  openingPublishDate?: string;
}

export const StartDateLabel: React.FC<Props> = ({
  openingStatus,
  openingPublishDate,
}) => {
  if (!openingPublishDate) {
    return <FormattedMessage id="openings.overview.scheduledForPublish" />;
  }

  const date = new Date(openingPublishDate);

  if (isFuture(date)) {
    return (
      <>
        <FormattedMessage id="openings.overview.scheduledForPublish" />{' '}
        {(openingStatus.includes('internal') && (
          <>
            (<FormattedMessage id="openings.overview.internal" />)
          </>
        )) ||
          (openingStatus.includes('public') && (
            <>
              (<FormattedMessage id="openings.overview.public" />)
            </>
          ))}
      </>
    );
  }

  return (
    <>
      <FormattedMessage
        id={
          openingStatus === 'draft'
            ? `openings.overview.created`
            : `openings.overview.published`
        }
      />
    </>
  );
};
