import React from 'react';
import { Content } from './components';
import * as contentStyles from 'modules/notFound/styles/Root.styles';
import { AppLayout, MainContent, Navbar, Footer } from 'modules/app';
import { RouteComponentProps } from '@reach/router';

interface Props extends RouteComponentProps {
  type?: 'contentOnly' | 'contentOnlyCenter' | 'dashboardFull' | 'page';
  additionalStyles?:
    | (keyof typeof contentStyles)[]
    | keyof typeof contentStyles;
}

export const NotFound: React.FC<Props> = ({ type, additionalStyles }) => {
  const getStyles = (style: SerializedStyles) => {
    const customStyles = additionalStyles
      ? Array.isArray(additionalStyles)
        ? additionalStyles.map((label) => contentStyles[label])
        : [contentStyles[additionalStyles]]
      : undefined;

    return customStyles ? [style, ...customStyles] : style;
  };

  switch (type) {
    case 'contentOnly':
      return (
        <div css={getStyles(contentStyles.contentWrapper)}>
          <Content />
        </div>
      );

    case 'dashboardFull':
      return (
        <AppLayout isPublic>
          <MainContent customStyles={getStyles(contentStyles.wrapper)}>
            <Content showButton />
          </MainContent>
        </AppLayout>
      );

    case 'contentOnlyCenter':
      return (
        <MainContent customStyles={getStyles(contentStyles.wrapper)}>
          <Content showButton />
        </MainContent>
      );

    case 'page':
    default:
      return (
        <section>
          <Navbar />
          <MainContent customStyles={contentStyles.wrapper}>
            <Content showButton />
          </MainContent>
          <Footer />
        </section>
      );
  }
};
