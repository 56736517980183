import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';
import { breakpoints } from 'style/variables';

export const root = css`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;

  > nav {
    max-width: 100vw;
  }
`;

export const content = css`
  max-width: 100vw;

  > div[tabindex='-1'] {
    height: 100%;
  }
`;

export const footer = css`
  padding-top: calc(var(--unit) * 20);
  padding-bottom: calc(var(--unit) * 20);

  @media ${breakpoints.medium} {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
`;

export const gdpr = css`
  margin-top: ${spacing(32)};

  & > a {
    color: hsl(var(--color-grayscale-6));

    &:hover {
      text-decoration: underline;
    }
  }

  @media ${breakpoints.medium} {
    margin-top: 0;
  }
`;

export const talentFooter = css`
  padding: 60px 20px;
  max-width: 1600px;
  margin: 0 auto;

  > p {
    ${font.size.base};
    color: hsl(var(--color-grayscale-6));

    > a {
      font-weight: bold;
      color: hsl(var(--color-text-1));
    }
  }

  @media ${breakpoints.medium} {
    padding: 80px 64px;
  }

  @media ${breakpoints.large} {
    padding: 80px 144px;
  }
`;
