import React from 'react';
import * as styles from 'modules/admin/styles/StatusIndicator.styles';
interface StatusPillProps {
  dataToCheck: string | boolean;
  statusTrueMessage: string;
  statusFalseMessage: string;
}

export const StatusIndicator: React.FC<StatusPillProps> = ({
  dataToCheck,
  statusTrueMessage,
  statusFalseMessage,
}) => {
  return (
    <div
      css={[
        styles.statusPill,
        Boolean(dataToCheck) ? styles.statusVerified : styles.statusUnverified,
      ]}
    >
      {Boolean(dataToCheck) ? statusTrueMessage : statusFalseMessage}
    </div>
  );
};
