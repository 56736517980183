import { css } from '@emotion/react';

export const loadingIndicator = css`
  position: absolute;
  top: calc(var(--unit) * 7);
  left: 0;

  width: 20px;
  height: 20px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
