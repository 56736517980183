import { useHandlePlan } from './useHandlePlan';
import { ErrorOption } from 'react-hook-form';
import { useHttpClient, useToastify } from 'modules/app';
import { useDispatch } from 'react-redux';
import { billingActions } from 'modules/billing';
import { useState } from 'react';
import { navigate } from 'gatsby-plugin-intl';
import { HttpError } from 'modules/app/http/client/httpError';

export type SelectValue = {
  value: string;
  label: string;
};

type SetFormError = (
  name: keyof BillingDetailsRequest,
  error: ErrorOption,
) => void;

export const useBillingDetails = () => {
  const dispatch = useDispatch();
  const { toastSuccess } = useToastify();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiErrorData>();
  const [countriesValue, setCountriesValue] = useState<SelectValue[]>([]);

  const { getQueryParams } = useHandlePlan();

  const http = useHttpClient('/api/v1/company/');
  const assetsHttp = useHttpClient('/api/v1/assets');
  const { planId, requestType } = getQueryParams();

  async function setCountriesSelect() {
    try {
      const {
        data: { countries },
      } = await assetsHttp.get<CompanyConfig>('/companies');
      setCountriesValue(
        countries.map((country) => ({
          value: country.code,
          label: country.name,
        })),
      );
    } catch (e) {}
  }

  async function getBillingDetails(companyId?: string) {
    if (!companyId) return;
    setCountriesSelect();

    dispatch(billingActions.loading());

    try {
      const { data } = await http.get<BillingDetailsResponse>(
        `${companyId}/settings/billing-details`,
      );
      dispatch(billingActions.setBillingInfo(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.status === 404) {
        dispatch(billingActions.setBillingInfo());
        return;
      }
      dispatch(billingActions.billingInfoError(e));
    }
  }

  async function addBillingDetails(
    info: BillingDetailsRequest,
    companyId?: string,
    handle?: string,
    setFormError?: SetFormError,
  ) {
    if (!companyId) return;

    setIsLoading(true);
    setError(undefined);

    try {
      const { data } = await http.post<
        BillingDetailsResponse,
        BillingDetailsRequest
      >(`/${companyId}/settings/billing-details/create`, info);
      dispatch(billingActions.setBillingInfo(data));
      toastSuccess('settings.billingContent.billingInfo.addSuccess');

      if (!planId) return;

      navigate(
        `/profile/settings/company/${handle}/billing/payment-method?planId=${planId}${
          requestType ? `&type=${requestType}` : ''
        }`,
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.data?.errors.vatId) {
        setFormError?.('vatId', {
          type: 'manual',
          message: 'settings.billingContent.billingInfo.vatIdFormat',
        });
        return;
      }
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  return {
    error,
    isLoading,
    countriesValue,
    getBillingDetails,
    addBillingDetails,
  };
};
