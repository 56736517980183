import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';

export const flatLink = css`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const flatLinkIcon = css`
  margin: 0 ${spacing(16)} 0 0;
`;

export const flatLinkText = css`
  margin: 0 ${spacing(32)} 0 0;
`;

export const flatLinkActive = css`
  font-weight: 700 !important;
  pointer-events: none;
`;
