import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as styles from 'modules/app/styles/Slider.styles';

interface Props {
  customStyles?: SerializedStyles;
  isDraggable?: boolean;
}

export const Slider: React.FC<Props> = ({ children, customStyles }) => {
  const [rightPadding, setRightPadding] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);
  const [isDraggable, setIsDraggable] = useState(false);

  useEffect(() => {
    if (ref.current && parentRef.current) {
      const parent = parentRef.current.getBoundingClientRect().right;
      const child = ref.current.getBoundingClientRect().right;
      const lastChild =
        ref.current.lastElementChild?.getBoundingClientRect().right;

      setRightPadding(parent - child);

      if (lastChild && parent < lastChild) {
        setIsDraggable(true);

        return;
      }
    }
  }, [ref, parentRef]);

  const draggableScroll = useCallback(() => {
    if (!dragRef.current || !isDraggable) {
      return;
    }

    const slider = dragRef.current;

    let isDragging = false;
    let startX: number;
    let scrollLeft: number;

    slider.addEventListener('mousedown', (e) => {
      isDragging = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
      slider.style.cursor = 'grabbing';
    });
    slider.addEventListener('mouseleave', () => {
      isDragging = false;
      slider.style.cursor = 'grab';
    });
    slider.addEventListener('mouseup', () => {
      isDragging = false;
      slider.style.cursor = 'grab';
    });
    slider.addEventListener('mousemove', (e) => {
      if (!isDragging) {
        return;
      }

      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const scroll = (x - startX) * 1.5;
      slider.scrollLeft = scrollLeft - scroll;
    });
  }, [isDraggable]);

  useEffect(draggableScroll, [isDraggable]);

  return (
    <section ref={parentRef} css={[styles.root, customStyles]}>
      <div
        ref={dragRef}
        css={isDraggable ? styles.wrapperDraggable : styles.wrapper}
      >
        <div ref={ref} css={styles.contentWrapper(rightPadding)}>
          {children}
        </div>
      </div>
    </section>
  );
};
