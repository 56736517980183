import { createAction } from 'modules/redux-store';

export const quickSearchAction = {
  searchValue: (payload?: string) =>
    createAction('quickSearch/SEARCH_VALUE', payload),

  companies: (payload: {
    companies: ExploreCompanies[];
    lastPage: number;
    page: number;
  }) => createAction('quickSearch/COMPANIES', payload),
  companiesLoading: () => createAction('quickSearch/COMPANIES_LOADING'),
  companiesError: (payload: ApiErrorData) =>
    createAction('quickSearch/COMPANIES_ERROR', payload),

  openings: (payload: {
    openings: OpeningsSearch[];
    lastPage: number;
    page: number;
  }) => createAction('quickSearch/OPENINGS', payload),
  openingsLoading: () => createAction('quickSearch/OPENINGS_LOADING'),
  openingsError: (payload: ApiErrorData) =>
    createAction('quickSearch/OPENINGS_ERROR', payload),

  // companies: (payload: {
  //   companies?: CompanySearch[];
  //   isLoading: boolean;
  //   error?: ApiErrorData;
  //   companiesCurrentPage?: number;
  //   companiesPageCount?: number;
  // }) => createAction('quickSearch/COMPANIES', payload),

  // openings: (payload: {
  //   openings?: OpeningsSearch[];
  //   isLoading: boolean;
  //   error?: ApiErrorData;
  //   openingsCurrentPage?: number;
  //   openingsPageCount?: number;
  // }) => createAction('quickSearch/OPENINGS', payload),

  // loading: () => createAction('quickSearch/LOADING'),
  // error: (payload: ApiErrorData) => createAction('quickSearch/ERROR', payload),

  reset: () => createAction('quickSearch/SEARCH_RESET'),
};
