type ButtonType = {
  type: 'purchase' | 'upgrade' | 'downgrade';
  text: string;
};

export function planButtonType(
  plan: Plan,
  activePlan?: ActivePlanCached,
): ButtonType {
  if (!activePlan || activePlan.planPrice === 0) {
    return {
      type: 'purchase',
      text: 'settings.billingContent.subscriptionContent.purchase',
    };
  }

  if (activePlan.planPrice < plan.price) {
    return {
      type: 'upgrade',
      text: 'settings.billingContent.subscriptionContent.upgrade',
    };
  }

  return {
    type: 'downgrade',
    text: 'settings.billingContent.subscriptionContent.downgrade',
  };
}
