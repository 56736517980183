import { useHttpClient, useToastify } from 'modules/app';
import { useState } from 'react';

export const useRetryRequestDataCopy = () => {
  const adminUsersService = useHttpClient('/admin/users');

  const [isLoading, setIsLoading] = useState(false);
  const { toastError, toastSuccess } = useToastify();

  async function retryRequestData(userId: string, copyRequestId: string) {
    setIsLoading(true);

    try {
      await adminUsersService.post(
        `/${userId}/data-copy-requests/retry/${copyRequestId}`,
      );
      toastSuccess('admin.users.copy-profile-data.retryRequest');
    } catch (e) {
      toastError();
    }

    setIsLoading(false);
  }

  return {
    isLoading,
    retryRequestData,
  };
};
