import { adminAction } from 'modules/admin/redux';
import { useDispatch } from 'react-redux';
import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';

export const useGetAdminUsers = (
  callback?: React.Dispatch<AdminResponse<Users>>,
) => {
  const adminUsersService = useHttpClient('/admin/users');
  const dispatch = useDispatch();

  // change number of results per page --> only here
  async function getAdminUsers({
    page,
    perPage,
    sort = 'DESC',
    sortBy = 'created_at',
    search,
    searchBy,
    hideDeleted,
    hideStaff,
    staffRoleId,
    companyId,
  }: UsersAttributes) {
    dispatch(adminAction.loading());
    try {
      const { data } = await adminUsersService.get<AdminResponse<Users>>('', {
        params: {
          page,
          perPage,
          sort,
          sortBy,
          search,
          searchBy,
          hideDeleted,
          hideStaff,
          staffRoleId,
          companyId,
        },
      });

      if (!data) return;

      if (staffRoleId === 'Admin') {
        dispatch(adminAction.admin(data));
        return;
      }

      if (staffRoleId === 'Support') {
        dispatch(adminAction.support(data));
        return;
      }

      if (staffRoleId === 'SuperAdmin') {
        dispatch(adminAction.superadmin(data));
        return;
      }

      if (hideStaff) {
        dispatch(adminAction.talents(data));
        return;
      }

      dispatch(adminAction.users(data));
      callback?.(data);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(adminAction.error(e));
    }
  }

  return {
    getAdminUsers,
  };
};
