import { formatDay } from './formatDay';

export function isFirstDay(
  timeList: { id: string; createdAt: string }[],
  message: ConversationMessage,
): boolean {
  const times = timeList.filter(
    (time) => time.createdAt === formatDay(message.createdAt),
  );

  const index = times.findIndex((time) => time.id === message.id);

  if (index === 0) {
    return true;
  }

  return false;
}
