import React from 'react';
import { padding } from '@prototyp/gatsby-plugin-gumball/utils';
import { navigate } from 'gatsby-link';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Heading, Button } from 'modules/app';

import InfoIcon from 'assets/icons/info.svg';

import * as styles from '../styles/Root.styles';
import { Link } from 'gatsby';
import { RouteComponentProps } from '@reach/router';

interface Props extends RouteComponentProps {
  showButton?: boolean;
}

export const Content: React.FC<Props> = ({ showButton }) => {
  return (
    <section css={styles.root}>
      <aside>
        <InfoIcon css={styles.icon} />
      </aside>
      <article>
        <Heading css={padding.bottom.huge} as="h1" size="fluidXxLarge">
          <FormattedMessage id="notfound.title" />
        </Heading>
        <div css={styles.content}>
          <p css={padding.bottom.xlrg}>
            <FormattedMessage id="notfound.subtitle" />
          </p>
          <p css={showButton && padding.bottom.huge}>
            <FormattedMessage
              id="notfound.contact"
              values={{
                link: (
                  <Link to="/support">
                    <strong>
                      <FormattedMessage id="notfound.link" />
                    </strong>
                  </Link>
                ),
              }}
            />
          </p>
        </div>
        {showButton && (
          <Button
            type="button"
            buttonType="primary"
            onClick={() => navigate('/')}
          >
            <FormattedMessage id="notfound.button" />
          </Button>
        )}
      </article>
    </section>
  );
};
