import { createAction } from 'modules/redux-store';

export const applicationsAction = {
  currentStage: (payload: string) =>
    createAction('applications/CURRENT_STAGE', payload),

  setOpening: (payload: CompanyOpening | undefined) =>
    createAction('applications/OPENING', payload),
  openingLoading: () => createAction('applications/OPENING_LOADING'),
  openingError: (payload: ApiErrorData) =>
    createAction('applications/OPENING_ERROR', payload),

  setApplications: (payload: {
    applications: Application[] | undefined;
    page: number;
    lastPage: number;
  }) => createAction('applications/SET_APPLICATIONS', payload),
  applicationsLoading: () => createAction('applications/APPLICATIONS_LOADING'),
  applicationsError: (payload: ApiErrorData) =>
    createAction('applications/APPLICATIONS_ERROR', payload),
  setApplicationsTags: (payload: { value: string; label: string }[]) =>
    createAction('applications/SET_APPLICATIONS_TAGS', payload),

  applicationLoading: () => createAction('applications/APPLICATION_LOADING'),
  applicationError: (payload: ApiErrorData) =>
    createAction('applications/APPLICATION_ERROR', payload),
  setApplication: (payload?: Application) =>
    createAction('applications/SET_APPLICATION', payload),
  setHistory: (payload: History | undefined) =>
    createAction('applications/SET_HISTORY', payload),

  setReasonsValues: (payload?: ReasonsList[]) =>
    createAction('applications/SET_REASONS_VALUES', payload),
  setOpeningsValues: (payload?: OpeningsList[]) =>
    createAction('applications/SET_OPENINGS_VALUES', payload),

  reset: () => createAction('applications/APPLICATIONS_RESET'),
};
