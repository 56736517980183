import { AppState } from 'modules/redux-store';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useCompanyId = (): string | undefined => {
  const { companyData: onboardingCompany } = useSelector(
    (state: AppState) => state.companyOnboarding,
  );
  const { companyData } = useSelector((state: AppState) => state.companyData);
  const { userCompanyRoles } = useSelector((state: AppState) => state.session);

  const companyId = useMemo(() => {
    if (onboardingCompany) {
      return onboardingCompany.id;
    }

    if (companyData) {
      return companyData.id;
    }

    if (!userCompanyRoles) {
      return;
    }

    if (!Boolean(userCompanyRoles.length)) {
      return;
    }

    return userCompanyRoles[0].companyId;
  }, [onboardingCompany, companyData, userCompanyRoles]);

  return companyId;
};
