import { ActionUnion } from 'modules/redux-store';

import { settingsAction } from './actions';

interface SettingsInitState {
  companyUsers?: CompanyUser[];
  isLoading: boolean;
  error?: ApiErrorData;
  companyId?: string;
  departments: Department[];
  archivedDepartments: Department[];
  currentCompanyRole?: UserCompanyRoles;
  reasons: {
    page: number;
    lastPage: number;
    reasons?: Reason[];
  };
}

const INITIAL_STATE: SettingsInitState = {
  isLoading: false,
  error: undefined,
  companyUsers: undefined,
  companyId: undefined,
  departments: [],
  archivedDepartments: [],
  currentCompanyRole: undefined,
  reasons: {
    page: 1,
    lastPage: 1,
  },
};

export const settingsReducer = (
  state: SettingsInitState = INITIAL_STATE,
  action: ActionUnion<typeof settingsAction>,
): SettingsInitState => {
  switch (action.type) {
    case 'settings/COMPANY_USERS':
      return {
        ...state,
        companyUsers: action.payload,
        isLoading: false,
        error: undefined,
      };

    case 'settings/COMPANY_ID':
      return {
        ...state,
        companyId: action.payload,
      };

    case 'settings/ADD_DEPARTMENTS':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        departments: action.payload,
      };

    case 'settings/ADD_ARCHIVED_DEPARTMENTS':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        archivedDepartments: action.payload,
      };

    case 'settings/ADD_SINGLE_DEPARTMENT':
      const stateCopy = { ...state };
      const departments = [...stateCopy.departments, action.payload];

      stateCopy.departments = departments;

      return stateCopy;

    case 'settings/UPDATE_SINGLE_DEPARTMENT':
      const idx = state.departments.findIndex(
        (item: Department) => action.payload.id === item.id,
      );
      state.departments[idx] = action.payload;
      state.departments = [...state.departments];

      return { ...state };

    case 'settings/DEACTIVATE_COMPANY':
      return {
        ...state,
        isLoading: false,
        error: undefined,
      };

    case 'settings/SET_REASONS':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        reasons: action.payload,
      };

    case 'settings/LOADING':
      return {
        ...state,
        isLoading: true,
      };

    case 'settings/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case 'settings/CURRENT_COMPANY_ROLE':
      return {
        ...state,
        currentCompanyRole: action.payload,
      };

    case 'settings/SETTINGS_RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
