import React, { useEffect, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  HttpError,
  useToastify,
  useFormGuardEffect,
} from 'modules/app';
import * as styles from 'modules/app/styles/GenericForm.styles';
import { useReset } from 'modules/onboarding';
import { TalentExperienceInputs } from './inputs';
import {
  useTalentExperience,
  normalizeExperienceRequest,
} from 'modules/talents';

interface Props {
  setCurrentExperience?: React.Dispatch<
    React.SetStateAction<Experience[] | undefined>
  >;
  isProfile?: boolean;
  resetFormCounter?: () => void;
  currentPage?: number;
  setPageCount?: React.Dispatch<React.SetStateAction<number>>;
  isOnboarding?: boolean;
}

export const TalentExperienceForm: React.FC<Props> = ({
  setCurrentExperience,
  isProfile,
  resetFormCounter,
  currentPage,
  setPageCount,
  isOnboarding = false,
}) => {
  const methods = useForm<ExperienceData>({
    defaultValues: {
      jobTitle: '',
      companyName: '',
      employmentStartDate: null,
      employmentEndDate: null,
      employmentType: null,
      workplace: [],
      jobDescription: null,
    },
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;
  useFormGuardEffect(methods, isOnboarding);

  const formRef = useRef<HTMLFormElement>(null);

  const { toastSuccess } = useToastify();
  const { resetValue } = useReset();
  const { addExperience, getExperienceLocally } = useTalentExperience();

  const [formError, setFormError] = useState<ApiErrorData>();
  const [isInRequest, setIsInRequest] = useState(false);

  const gettingExperience = async () => {
    setIsInRequest(true);
    const response = await getExperienceLocally(currentPage, 5);
    setIsInRequest(false);

    if (!response) return;
    const { lastPage, data } = response;

    setPageCount?.(lastPage);
    setCurrentExperience?.(data);
  };

  const onSubmit = async (data: ExperienceData) => {
    const model = normalizeExperienceRequest(data);

    setIsInRequest(true);
    const response = await addExperience(model, isOnboarding);
    setIsInRequest(false);

    if (!response) return;

    if (isOnboarding) {
      setFormError(undefined);
      reset();
      formRef?.current?.scrollIntoView({ behavior: 'smooth' });

      return;
    }

    if (isProfile) {
      toastSuccess('shared.pills.successDefaultText');
      gettingExperience();
      resetFormCounter?.();
    }
  };

  useEffect(() => {
    if (Boolean(Object.keys(errors).length)) {
      formRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errors]);

  return (
    <div css={styles.root}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <HttpError styles={styles.httpError} error={formError} />

          <TalentExperienceInputs resetValue={resetValue} />

          <Button disabled={isInRequest} type="submit" css={styles.submitBtn}>
            <FormattedMessage id="onboarding.form.fields.experience_employment.save_experience" />
          </Button>
          {isProfile && resetFormCounter && (
            <Button
              css={styles.cancelBtn}
              buttonType="ghost"
              onClick={() => {
                resetFormCounter();
              }}
              disabled={isInRequest}
            >
              <FormattedMessage id="profileManagement.heading.buttons.cancel" />
            </Button>
          )}
        </form>
      </FormProvider>
    </div>
  );
};
