import React from 'react';
import * as styles from '../../styles/Table.styles';
import Remove from 'assets/icons/closeOutlineBlack.svg';
import { Button, DropdownMenu } from 'modules/app';
import { UserCard } from '..';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';
import { font, padding } from '@prototyp/gatsby-plugin-gumball/utils';
import { isOnlyAdmin, isAdmin } from 'modules/admin/helpers';
import { companyRoleValues } from 'modules/settings/consts';

interface Props {
  member: Users;
  companyId: string;
  assignRole: (id: string, role: string) => void;
  removeRole: (id: string) => void;
  adminCount: number;
  allAdminIds?: string[];
}

export const TeamMemberItem: React.FC<Props> = ({
  member,
  companyId,
  assignRole,
  removeRole,
  adminCount,
  allAdminIds,
}) => {
  const { formatMessage } = useIntl();

  const rolesValues = companyRoleValues.map((values) => ({
    id: values.value,
    element: formatMessage({ id: values.label }),
  }));

  const onAssingRole = (id: string) => {
    assignRole(member.id, id);
  };

  const onRemoveRole = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = event.currentTarget;
    removeRole(value);
  };

  if (!member) {
    return null;
  }

  return (
    <tr css={styles.row}>
      <td css={styles.data}>
        <Link to={`/admin/users/${member.id}`}>
          <UserCard
            name={member.firstName}
            lastName={member.lastName}
            profilePicture={member.profilePicture?.small}
          />
        </Link>
      </td>
      <td css={font.weight.bold}>
        {Boolean(member.companyRoles.length) && (
          <DropdownMenu
            onSelect={onAssingRole}
            position="bottomLeft"
            customButtonStyles={padding.none}
            disabled={isOnlyAdmin(member, adminCount, allAdminIds)}
            placeholder={
              <span css={font.size.base}>
                <FormattedMessage
                  id={`settings.membersContent.${
                    member.companyRoles.find(
                      (item) => item.companyId === companyId,
                    )?.roleId
                  }`}
                />
              </span>
            }
            items={rolesValues}
          />
        )}
      </td>
      <td css={styles.action}>
        {!isAdmin(member, allAdminIds) && (
          <Button
            buttonType="outline"
            size="tiny"
            value={member.id}
            icon={<Remove />}
            onClick={onRemoveRole}
          >
            Remove
          </Button>
        )}
      </td>
    </tr>
  );
};
