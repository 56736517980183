import { UseFormReturn } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from '@reach/router';
import { quickSearchAction } from 'modules/quickSearch';
import { navigate } from 'gatsby-plugin-intl';

export interface QuickSearchForm {
  search: string;
}

export const useQuickSearch = (
  methods: UseFormReturn<QuickSearchForm, unknown>,
) => {
  const { pathname } = useLocation();
  const { setValue, watch } = methods;
  const dispatch = useDispatch();
  const [isSearchActive, setIsSearchActive] = useState(false);
  const value = watch('search');
  const isCloseIcon = useMemo(() => Boolean(value.length), [value]);

  const onSubmit = async (data: { search: string }) => {
    if (!data.search) return;

    dispatch(quickSearchAction.searchValue(data.search));
    navigate('/search/quick-search');
  };

  const resetValue = () => {
    setValue('search', '');

    if (pathname === '/en/search/quick-search') return;

    setIsSearchActive(false);
    dispatch(quickSearchAction.searchValue());
  };

  const onSearchToggle = () => {
    setIsSearchActive(true);
  };

  const onSearchClose = () => {
    if (pathname === '/en/search/quick-search') return;

    setIsSearchActive(false);
  };

  useEffect(() => {
    if (pathname !== '/en/search/quick-search') {
      setValue('search', '');
      dispatch(quickSearchAction.searchValue());
      return;
    }

    setIsSearchActive(true);
  }, [pathname]);

  return {
    onSubmit,
    resetValue,
    onSearchToggle,
    onSearchClose,
    isSearchActive,
    setIsSearchActive,
    isCloseIcon,
  };
};
