import {
  font,
  margin,
  padding,
  text,
} from '@prototyp/gatsby-plugin-gumball/utils';
import format from 'date-fns/format';
import { useIntl } from 'gatsby-plugin-intl';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';

import * as styles from 'modules/app/styles/DatePicker.styles';

interface Props {
  onSelect: (date: Date) => void;
  dateString: string | undefined;
  buttonIcon: JSX.Element;
  label: JSX.Element;
  children?: JSX.Element;
  minDate?: Date;
  maxDate?: Date;
  isDisabled?: boolean;
  theme?: 'light' | 'dark';
  showTime?: boolean;
  isOpening?: boolean;
}

const DatePickerButton: React.FC<Props> = ({
  onSelect,
  dateString,
  buttonIcon,
  label,
  children,
  minDate,
  maxDate,
  isDisabled,
  theme = 'light',
  showTime = true,
  isOpening = false,
}) => {
  const intl = useIntl();
  const [dateValue, setDateValue] = useState<Date | null>(null);

  function handleTimeChange() {
    if (!isOpening || !dateString || !dateValue) {
      return;
    }

    const date = format(new Date(dateString), 'do MMM yyyy');
    const newDate = format(dateValue, 'do MMM yyyy');

    if (
      dateValue.valueOf() === new Date(dateString).valueOf() ||
      date !== newDate
    ) {
      return;
    }

    onSelect(dateValue);
  }

  useEffect(() => {
    if (!dateString) {
      setDateValue(null);
      return;
    }

    setDateValue(new Date(dateString));
  }, [dateString]);

  return (
    <div css={[styles.root, margin.none, styles.datePickerButton]}>
      <p css={styles.companyOpeningsDateTitle}>{label}</p>
      <ReactDatePicker
        selected={dateValue}
        onChange={(date: Date) => setDateValue(date)}
        onSelect={(date: Date) => onSelect(date)}
        minDate={minDate}
        maxDate={maxDate}
        disabled={isDisabled}
        onCalendarClose={handleTimeChange}
        customInput={
          <button css={theme === 'light' ? styles.button : styles.buttonDark}>
            <p
              css={[
                padding.right.xlrg,
                font.size.small,
                theme === 'light' ? text.color.text : text.color.white,
              ]}
            >
              {dateValue
                ? format(
                    dateValue,
                    `do MMMM yyyy ${showTime ? "'at' h:mm aaa" : ''}`,
                  )
                : '-'}
            </p>
            <div>{buttonIcon}</div>
          </button>
        }
        showTimeInput={showTime}
        dateFormat="MMMM d, yyyy HH:mm"
        timeInputLabel={intl.formatMessage({
          id: 'dateTimePicker.setATime',
        })}
        placeholderText={intl.formatMessage({
          id: 'dateTimePicker.placeholder',
        })}
      >
        {children}
      </ReactDatePicker>
    </div>
  );
};

export default DatePickerButton;
