import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { SectionLink } from './SectionLink';
import { useLocation } from '@reach/router';
import { QuickSearch } from './QuickSearch';

import * as styles from './styles/ProfileBar.styles';
import { BarDropdown } from './BarDropdown';

import { MessageNotification } from './MessageNotification';

export const TalentProfileBar: React.FC = () => {
  const user = useSelector((state: AppState) => state.session.user);
  const { pathname } = useLocation();

  return (
    <>
      <div css={styles.barSection}>
        <SectionLink
          path="/profile/talent/overview/invitations"
          text={<FormattedMessage id="profileManagement.profileBar.overview" />}
          pathname={pathname}
          active={'/profile/talent/overview'}
        />

        <SectionLink
          path="/profile/talent/openings"
          text={<FormattedMessage id="profileManagement.profileBar.openings" />}
          pathname={pathname}
          active={'/profile/talent/openings'}
        />

        <SectionLink
          path="/profile/talent/manage/basic-information"
          text={
            <FormattedMessage id="profileManagement.profileBar.manageYourProfile" />
          }
          pathname={pathname}
          active="/profile/talent/manage/"
        />

        <div css={styles.navSection}>
          <QuickSearch />

          {/*
          TODO: Not available in MVP. Will be added later.
          */}
          {/* <Notification /> */}
          <MessageNotification />

          <BarDropdown
            firstName={user?.firstName}
            lastName={user?.lastName}
            profilePicture={user?.profilePicture?.medium}
          />
        </div>
      </div>
    </>
  );
};
