import { useCallback } from 'react';
import { FileRejection } from 'react-dropzone';
import { useFormContext, useWatch } from 'react-hook-form';
import { getAttachmentSize } from '../utils';
import { attachmentErrors } from '../utils/attachmentError';

export const useHandleAttachments = (name: string) => {
  const {
    setValue,
    control,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext();
  const uploadedFiles: File[] = useWatch({ name: name, defaultValue: [] });
  const error = errors[name];
  const filesSize = getAttachmentSize(uploadedFiles);
  const isSizeError = filesSize > 10;

  const handleError = useCallback((rejectedFiles?: FileRejection[]) => {
    const fileErrors = attachmentErrors(rejectedFiles);

    if (!Boolean(fileErrors.length)) {
      clearErrors();

      return;
    }

    fileErrors.map((error) => {
      if (error === 'file-too-large') {
        setError(name, { message: 'talentMessages.sizeError' });
      }

      if (error === 'file-invalid-type') {
        setError(name, { message: 'shared.file_input.invalid_type' });
      }
    });
  }, []);

  const onDelete = (index: number) => {
    const values = uploadedFiles;
    values.splice(index, 1);

    const valuesSize = getAttachmentSize(values);

    if (valuesSize < 10) {
      clearErrors();
    }

    setValue(name, values);
  };

  const onDrop = useCallback(
    (files: File[]) => {
      if (Boolean(uploadedFiles.length)) {
        const newValues = [...uploadedFiles, ...files];
        setValue(name, newValues);

        return;
      }

      setValue(name, files);
    },
    [uploadedFiles],
  );

  return {
    onDelete,
    onDrop,
    handleError,
    error,
    filesSize,
    isSizeError,
    errors,
    control,
    uploadedFiles,
  };
};
