import React from 'react';
import { Initials, makeBaseImagePath } from 'modules/app';

import * as styles from 'modules/app/styles/Avatar.styles';
import StealthIcon from 'assets/icons/stealth.svg';

interface Props {
  name?: string;
  lastName?: string;
  customStyles?: SerializedStyles | SerializedStyles[];
  imagePath: string | null | undefined;
  isStealth?: boolean;
  isBase64?: boolean;
  isShadow?: boolean;
}

export const Avatar: React.FC<Props> = ({
  name,
  lastName,
  customStyles,
  imagePath,
  isStealth,
  isBase64 = false,
  isShadow = true,
}) => {
  const avatarStyles = imagePath
    ? styles.avatarWithImage
    : styles.avatarWithoutImage;

  const rootStyles = isShadow
    ? [avatarStyles, styles.rootShadow]
    : [styles.rootNoShadow, avatarStyles];

  return (
    <div css={[rootStyles, customStyles]}>
      {isStealth ? (
        <StealthIcon />
      ) : (
        <div css={styles.avatar}>
          {!imagePath && (
            <span>
              <Initials firstName={name} lastName={lastName} />
            </span>
          )}
          {imagePath && !isBase64 && <img src={makeBaseImagePath(imagePath)} />}

          {imagePath && isBase64 && <img src={imagePath} />}
        </div>
      )}
    </div>
  );
};
