import { useCsrfCookie } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { OpeningsSearchService } from '../http';
import { talentOpeningsAction } from '../redux';

export function useRecentOpenings() {
  const { getCsrf } = useCsrfCookie();
  const csrf = getCsrf();

  const openingsSearchService = useMemo(
    () => new OpeningsSearchService(),
    [csrf],
  );

  const dispatch = useDispatch();

  async function getRecentOpenings() {
    try {
      dispatch(talentOpeningsAction.loading());
      const {
        data: { data },
      } = await openingsSearchService.get<OpeningsSearchResponse>('', {
        params: {
          sort_by: 'created_at',
          sort: 'DESC',
          per_page: 10,
        },
      });

      dispatch(talentOpeningsAction.recentOpenings(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(talentOpeningsAction.error(e));
    }
  }

  return { getRecentOpenings };
}
