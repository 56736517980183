import React from 'react';
import { useSocialPopup } from './hooks';

interface Props {
  render: (onClick: () => void) => React.ReactElement;
  type: 'twitter' | 'facebook' | 'linkedin';
  text?: string;
}

export const ShareWrapper: React.FC<Props> = ({ render, type, text }) => {
  const onSocial = useSocialPopup();

  return render(() => {
    onSocial(type, type === 'linkedin' ? text : encodeURIComponent(`${text}`));
  });
};
