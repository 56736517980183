export enum CareerPathEnum {
  'developed' = 'developed',
  'not-developed' = 'notDeveloped',
}

export enum PersonInChargeEnum {
  'ceo' = 'ceo',
  'cto' = 'cto',
  'coo' = 'coo',
  'respective-superior' = 'respectiveSuperior',
  'hr-dept' = 'hrDept',
  'employee' = 'employee',
}

export enum TopicsEnum {
  'career-development' = 'careerDevelopment',
  'challenges-since-meeting' = 'challengesSinceMeeting',
  'engagement-motivation-satisfaction' = 'engagementMotivationSatisfaction',
  'culture-alignment-check-in' = 'cultureAlignmentCheckIn',
  'tasks-responsibilities' = 'tasksResponsibilities',
  'kpi-monitoring-review' = 'kpiMonitoringReview',
  'givig-recieving-feedback' = 'givigRecievingFeedback',
  'short-long-term-goals' = 'shortLongTermGoals',
  'ways-to-improve' = 'waysToImprove',
  'improvement-opportunities' = 'improvementOpportunities',
  'work-life-balance' = 'workLifeBalance',
}

export enum FrequencyEnum {
  'daily' = 'daily',
  'weekly' = 'weekly',
  'monthly' = 'monthly',
  'querterly' = 'quarterly',
  'every-6-months' = 'every6Months',
  'once-a-year' = 'onceAYear',
}

export enum HrEnum {
  'external' = 'external',
  'company-hr' = 'companyHr',
}

export enum LeadershipEnum {
  'company-expert' = 'companyExpert',
  'external-expert' = 'externalExpert',
  'company-dept-head' = 'companyDeptHead',
  'company-mgmt' = 'companyMgmt',
  'superior' = 'superior',
}

export enum IndustryEnum {
  'animation' = 'organization_industry_0',
  'automotive' = 'organization_industry_1',
  'banking' = 'organization_industry_2',
  'business-srv' = 'organization_industry_3',
  'creative-srv' = 'organization_industry_4',
  'data-centers' = 'organization_industry_5',
  'ecommerce' = 'organization_industry_6',
  'edu-train-coach' = 'organization_industry_7',
  'energy' = 'organization_industry_8',
  'ent-events' = 'organization_industry_9',
  'fintech' = 'organization_industry_10',
  'gaming-ind' = 'organization_industry_11',
  'gov-org' = 'organization_industry_12',
  'health-care' = 'organization_industry_13',
  'human-resources' = 'organization_industry_14',
  'immersive-env' = 'organization_industry_15',
  'inf-sec' = 'organization_industry_16',
  'it-cons' = 'organization_industry_17',
  'management' = 'organization_industry_18',
  'market-research' = 'organization_industry_19',
  'market-adv' = 'organization_industry_20',
  'media' = 'organization_industry_21',
  'research-dev' = 'organization_industry_22',
  'robotics' = 'organization_industry_23',
  'smart-cities' = 'organization_industry_24',
  'telecommunications' = 'organization_industry_25',
  'product-company' = 'organization_industry_26',
  'it-srv' = 'organization_industry_27',
  'it-support-srv' = 'organization_industry_28',
  'help-desk-it-srv' = 'organization_industry_29',
  'network-sec' = 'organization_industry_30',
  'it-srv-provider' = 'organization_industry_31',
  'cloud-srv' = 'organization_industry_32',
  'data-backup-srv' = 'organization_industry_33',
  'social-media-agcy' = 'organization_industry_34',
  'web-dsgn-srv' = 'organization_industry_35',
  'email-market-srv' = 'organization_industry_36',
  'sw-dev' = 'organization_industry_37',
  'sw-dev-company' = 'organization_industry_38',
  'full-srv-dig-agcy' = 'organization_industry_39',
  'dig-agcy' = 'organization_industry_40',
  'dsgn-company' = 'organization_industry_41',
  'seo' = 'organization_industry_42',
  'web-dsgn-agcy' = 'organization_industry_43',
  'content-agcy' = 'organization_industry_44',
}
