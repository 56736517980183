import React, { useEffect, useRef } from 'react';

import * as styles from 'modules/talentMessages/styles/MessageList.styles';
import { MessageListItem } from './MessageListItem';
import { formatDay, isFirstDay } from '../utils';

interface Props {
  messages: Conversation;
}

export const MessageList: React.FC<Props> = ({ messages }) => {
  const ref = useRef<HTMLDivElement>(null);
  const talentUser = messages.conversationUsers.find(
    (conversationUser) => conversationUser.userId === messages.userId,
  );
  const conversationMessages = messages.conversationMessages.sort(
    (a, b) => new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf(),
  );

  const timeList = conversationMessages.map((message) => ({
    id: message.id,
    createdAt: formatDay(message.createdAt),
  }));

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current.scrollTop = ref.current.scrollHeight;
  }, [messages.conversationMessages]);

  return (
    <div ref={ref} css={styles.root}>
      {conversationMessages.map((message) => {
        const isFirst = isFirstDay(timeList, message);
        const user = messages.conversationUsers.find(
          (user) => user.id === message.conversationUserId,
        );

        if (!user) {
          return;
        }

        if (message.conversationUserId === talentUser?.id) {
          return (
            <MessageListItem
              key={message.id}
              type="talent"
              message={message}
              isFirstDay={isFirst}
              user={user}
            />
          );
        }

        return (
          <MessageListItem
            key={message.id}
            type="company"
            message={message}
            isFirstDay={isFirst}
            user={user}
          />
        );
      })}
    </div>
  );
};
