import {
  Button,
  HeaderLayout,
  Heading,
  HttpError,
  InfoText,
  MainContent,
  Skeleton,
  Slider,
} from 'modules/app';
import React from 'react';

import * as styles from 'modules/dashboard/styles/NoPlan.styles';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { Plan } from './Plan';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import { NoPlansHeading } from './NoPlansHeading';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { motion } from 'framer-motion';
import { useActivePlan } from 'modules/billing/hooks';

interface Props {
  companyId: string;
  companyHandle: string;
}

export const NoPlan: React.FC<Props> = ({ companyId, companyHandle }) => {
  const { isLoading, error, plans } = useSelector(
    (state: AppState) => state.billing,
  );
  const { latestPlan } = useSelector((state: AppState) => state.companyData);
  const { isInRequest, startFreeTrial } = useActivePlan();

  return (
    <section css={styles.root}>
      <MainContent customStyles={styles.mainContent}>
        <NoPlansHeading companyId={companyId} />
      </MainContent>

      {isLoading ? (
        <MainContent customStyles={styles.mainContent}>
          <Skeleton count={3} containerStyles={styles.skeleton} />
        </MainContent>
      ) : !error && plans ? (
        <motion.section
          {...FADE_IN_MOVE_Y_REGULAR}
          transition={{ duration: 0.3 }}
        >
          {Boolean(plans.length) ? (
            <Slider>
              {plans
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((plan) => (
                  <Plan
                    key={plan.id}
                    plan={plan}
                    companyHandle={companyHandle}
                  />
                ))}
            </Slider>
          ) : (
            <MainContent customStyles={styles.mainContent}>
              <InfoText textId="companyDashboard.noPlan.noPlans" />
            </MainContent>
          )}
        </motion.section>
      ) : (
        <MainContent>
          <HttpError />
        </MainContent>
      )}

      {!latestPlan && (
        <HeaderLayout>
          <section css={styles.freeTrial}>
            <Heading as="h4" size="fluidXLarge">
              <FormattedMessage id="settings.billingContent.subscriptionContent.freeTrial" />
            </Heading>

            <p css={styles.freeTrialContent}>
              <FormattedMessage
                id="settings.billingContent.subscriptionContent.freeTrialContent"
                values={{
                  link: (
                    <Link to="/support#pricing">
                      <FormattedMessage id="settings.billingContent.subscriptionContent.freeTrialHere" />
                    </Link>
                  ),
                }}
              />
            </p>

            <Button
              type="button"
              buttonType="secondary"
              size="small"
              isLoading={isInRequest}
              onClick={() => startFreeTrial(companyId)}
            >
              <FormattedMessage id="settings.billingContent.subscriptionContent.freeTrialBtn" />
            </Button>
          </section>
        </HeaderLayout>
      )}

      <HeaderLayout>
        <motion.section
          {...FADE_IN_MOVE_Y_REGULAR}
          transition={{ duration: 0.3 }}
          css={styles.customPlan}
        >
          <Heading as="h3" size="fluidXLarge">
            <FormattedMessage id="companyDashboard.noPlan.customTitle" />
          </Heading>
          <div css={styles.customPlanContent}>
            <p>
              <FormattedMessage id="companyDashboard.noPlan.customContent" />
            </p>
            <Link
              css={styles.customPlanBtn}
              to="/request-demo?interestedIn=custom_plan"
            >
              <FormattedMessage id="companyDashboard.noPlan.customBtn" />
            </Link>
          </div>
        </motion.section>
      </HeaderLayout>
    </section>
  );
};
