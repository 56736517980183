import { createAction } from 'modules/redux-store';

export const notesActions = {
  setNotes: (payload?: Note[]) => createAction('notes/SET_NOTES', payload),
  setMembers: (payload?: NoteMember[]) =>
    createAction('notes/SET_MEMBERS', payload),
  loading: () => createAction('notes/LOADING'),
  error: (payload: ApiErrorData) => createAction('notes/ERROR', payload),

  reset: () => createAction('notes/PIPELINES_RESET'),
};
