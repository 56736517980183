import Seo from 'modules/app/components/Seo';
import React from 'react';
import { useSearchParams } from '../hooks';
import { useLocation } from '@reach/router';
import * as styles from './styles/BillingActivity.styles';
import { format, startOfYear, startOfToday } from 'date-fns';
import { useBillingActivity, useBillingActivityQuery } from './hooks';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { BillingActivityBody, BillingActivityHeader } from './components';

export const BillingActivity: React.FC = () => {
  const { search: queryString } = useLocation();
  const queryParams = new URLSearchParams(queryString);

  const firstDayOfTheYear = format(startOfYear(new Date()), 'yyyy-MM-dd');
  const today = format(startOfToday(), 'yyyy-MM-dd');

  const urlParams = {
    urlSearch: queryParams.get('search') ?? '',
    urlSearchBy: queryParams.get('searchBy') ?? 'company_name',
    urlPage: queryParams.get('page') ?? '1',
    urlSort: queryParams.get('sort') ?? 'DESC',
    urlSortBy: queryParams.get('sortBy') ?? 'created_at',
    urlStartDate: queryParams.get('startDate') ?? firstDayOfTheYear,
    urlEndDate: queryParams.get('endDate') ?? today,
    urlHideDeleted: queryParams.get('hideDeleted') ?? 'true',
    urlPaymentType: queryParams.get('paymentType') ?? undefined,
  };

  const { getAdminBillingActivities } = useBillingActivity();
  const { query, setQuery } = useBillingActivityQuery(
    getAdminBillingActivities,
    urlParams,
    queryString,
  );
  const { isLoading } = useSelector(
    (selector: AppState) => selector.adminBillingActivity,
  );

  const {
    handleSearch,
    handlePageChange,
    handleChangeSortBy,
    handleChangeSort,
    handleGenerateSearchFilterOptions,
    handleChangeSearchBy,
  } = useSearchParams<BillingActivityQuery>({ query, setQuery });

  return (
    <>
      <Seo title="Billing Activity" />
      <div css={styles.root}>
        <BillingActivityHeader
          isLoading={isLoading}
          query={query}
          setQuery={setQuery}
          handleSearch={handleSearch}
          handleChangeSort={handleChangeSort}
          handleChangeSortBy={handleChangeSortBy}
          handleGenerateSearchFilterOptions={handleGenerateSearchFilterOptions}
          handleChangeSearchBy={handleChangeSearchBy}
        />
        <BillingActivityBody
          handlePageChange={handlePageChange}
          query={query}
        />
      </div>
    </>
  );
};
