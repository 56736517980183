import { flex } from '@prototyp/gatsby-plugin-gumball/utils';
import { ActionModal, DropdownMenu } from 'modules/app';
import React, { useState } from 'react';
import { useOpeningValues } from '../consts';
import * as buttonStyles from 'modules/app/styles/Button.styles';
import { AnimatePresence } from 'framer-motion';
import { DeactivateLimitModal } from './DeactivateLimitModal';
import { useCreateOpeningCheck, usePlanRestrictions } from '../hooks';
import { OpeningRestrictionModal } from './OpeningRestrictionModal';

export interface StatusData {
  companyId: string;
  openingId: string;
  status: string;
}

interface Props {
  data: CompanyOpening;
  onChange: (companyId: string, openingId: string, status: string) => void;
  customStyles?: SerializedStyles | SerializedStyles[];
}

export const OpeningItemStatus: React.FC<Props> = ({
  data,
  onChange,
  customStyles,
}) => {
  const [isDeactivateModal, setIsDeactivateModal] = useState(false);
  const [isLimitModal, setIsLimitModal] = useState(false);
  const [status, setStatus] = useState<StatusData>();
  const { isDeactivateLimit, isPlanLimit } = usePlanRestrictions();

  const { statusValues, statusColor, statusSelectValues } = useOpeningValues();
  const selectedItemLabel = statusValues.find(
    (v) => v.value === data.status,
  )?.label;
  const isDisabled = useCreateOpeningCheck();

  function onStatusChange(id: string) {
    const inactiveStatus: PublishStatus[] = [
      'archived',
      'deactivated',
      'draft',
    ];

    if (isPlanLimit && inactiveStatus.includes(data.status)) {
      setIsLimitModal(true);
      return;
    }

    if (isDeactivateLimit && inactiveStatus.includes(data.status)) {
      setStatus({ companyId: data.companyId, openingId: data.id, status: id });
      setIsDeactivateModal(true);
      return;
    }

    onChange(data.companyId, data.id, id);
  }

  return (
    <>
      <AnimatePresence>
        {isDeactivateModal && isDeactivateLimit && (
          <ActionModal
            isOpen={isDeactivateModal}
            setIsOpen={setIsDeactivateModal}
          >
            <DeactivateLimitModal
              setIsOpen={setIsDeactivateModal}
              statusData={status}
              onStatusChange={onChange}
            />
          </ActionModal>
        )}
        {isLimitModal && isPlanLimit && (
          <ActionModal isOpen={isLimitModal} setIsOpen={setIsLimitModal}>
            <OpeningRestrictionModal
              setIsOpen={setIsLimitModal}
              handle={data.companyHandle}
            />
          </ActionModal>
        )}
      </AnimatePresence>
      <div css={[flex.display.flex, flex.align.center, customStyles]}>
        <DropdownMenu
          disabled={isDisabled}
          customButtonStyles={buttonStyles.statusButton(
            statusColor[data.status],
          )}
          onSelect={onStatusChange}
          value={{
            id: data.status,
            title: <p>{selectedItemLabel}</p>,
          }}
          items={statusSelectValues(data)}
        />
      </div>
    </>
  );
};
