import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

export function useFormGuardOnBackClick<Model>(form: UseFormReturn<Model>) {
  const {
    formState: { dirtyFields },
    watch,
  } = form;
  const { formatMessage } = useIntl();
  const isDirty = useMemo(
    () => Boolean(Object.keys(dirtyFields).length),
    [watch()],
  );

  const handleGuardedRouteCheck = () => {
    if (!isDirty) return;

    return window.confirm(formatMessage({ id: 'confirm-navigation' }));
  };

  return { handleGuardedRouteCheck };
}
