import { useCsrfCookie } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { AppState } from 'modules/redux-store';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { transformCustomStages } from '../helpers';
import { PipelinesServices } from '../http';
import { usePipeline } from './usePipeline';

export const useStage = () => {
  const [error, setError] = useState<HttpError>();
  const [isLoading, setIsLoading] = useState(false);
  const { pipeline } = useSelector((state: AppState) => state.pipelines);

  const { getPipeline } = usePipeline();
  const { getCsrf } = useCsrfCookie();
  const csrf = getCsrf();

  const stageHttp = useMemo(() => new PipelinesServices(), [csrf]);

  async function addStage(
    companyId: string,
    pipelineId: string,
    info: AddStageData,
    callback: VoidFunction,
  ) {
    if (!pipeline) return;

    const customStages = transformCustomStages(pipeline.customStages);
    const stages = [...customStages, info];

    setIsLoading(true);
    try {
      await stageHttp.post(`/${companyId}/pipelines/${pipelineId}/stages`, {
        stages,
      });
      getPipeline(companyId, pipelineId, false);
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }
    setIsLoading(false);
  }

  async function editStage(
    companyId: string,
    pipelineId: string,
    stageId: string,
    info: AddStageData,
    callback: VoidFunction,
  ) {
    if (!pipeline) return;

    const stages = transformCustomStages(pipeline.customStages);
    const editingStage = stages.find((stage) => stage.id === stageId);

    if (!editingStage) return;

    const index = stages.indexOf(editingStage);
    stages[index] = info;

    setIsLoading(true);
    try {
      await stageHttp.post(`/${companyId}/pipelines/${pipelineId}/stages`, {
        stages,
      });
      getPipeline(companyId, pipelineId, false);
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }
    setIsLoading(false);
  }

  async function deleteStage(
    companyId: string,
    pipelineId: string,
    stageId: string,
    callback: VoidFunction,
  ) {
    if (!pipeline) return;

    const customStages = transformCustomStages(pipeline.customStages);
    const stages = customStages.filter((stage) => stage.id !== stageId);

    setIsLoading(true);
    try {
      await stageHttp.post(`/${companyId}/pipelines/${pipelineId}/stages`, {
        stages,
      });
      getPipeline(companyId, pipelineId, false);
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }
    setIsLoading(false);
  }

  async function addStageMessage(
    companyId: string,
    pipelineId: string,
    stageId: string,
    info: StageMessageData,
    callback: VoidFunction,
  ) {
    setIsLoading(true);
    try {
      await stageHttp.post(
        `/${companyId}/pipelines/${pipelineId}/stages/${stageId}/messages`,
        info,
      );
      getPipeline(companyId, pipelineId, false);
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }
    setIsLoading(false);
  }

  async function editStageMessage(
    companyId: string,
    pipelineId: string,
    stageId: string,
    messageId: string,
    info: StageMessageData,
    callback: VoidFunction,
  ) {
    setIsLoading(true);
    try {
      await stageHttp.put(
        `/${companyId}/pipelines/${pipelineId}/stages/${stageId}/messages/${messageId}`,
        info,
      );
      getPipeline(companyId, pipelineId, false);
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }
    setIsLoading(false);
  }

  return {
    error,
    isLoading,
    addStage,
    editStage,
    deleteStage,
    addStageMessage,
    editStageMessage,
  };
};
