import { useIntl } from 'gatsby-plugin-intl';
import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { invoicesActions } from '../redux';
import { useInvoices } from './useInvoices';

export function useInvoicesCancelForm(
  form: UseFormReturn<InvoiceCancelRefundData, unknown>,
  invoice: InvoiceResponse,
  handleCloseCancelModal: VoidFunction,
) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { watch, setError, clearErrors } = form;
  const reason = watch('reason');
  const amount = watch('amount');

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const { cancelInvoice, refundWithFiscalization, refundWithoutFiscalization } =
    useInvoices();

  function checkIsReasonError() {
    if (!reason.length || reason.length < 50) {
      setError('reason', {
        message: formatMessage({
          id: 'admin.invoices.invoicesCancelModal.textAreaMin',
        }),
      });
      return true;
    }

    if (reason.length > 5000) {
      setError('reason', {
        message: formatMessage({
          id: 'admin.invoices.invoicesCancelModal.textAreaMax',
        }),
      });
      return true;
    }

    clearErrors('reason');
    return false;
  }

  function checkIsAmountError() {
    if (!String(amount).length) {
      setError('amount', {
        message: formatMessage({
          id: 'admin.invoices.invoicesCancelModal.amountRequired',
        }),
      });
      return true;
    }

    if (amount <= 0) {
      setError('amount', {
        message: formatMessage({
          id: 'admin.invoices.invoicesCancelModal.amountMin',
        }),
      });
      return true;
    }

    if (!/^\d+(\.\d{1,2})?$/g.test(String(amount))) {
      setError('amount', {
        message: formatMessage({
          id: 'admin.invoices.invoicesCancelModal.shouldBeNumber',
        }),
      });
      return true;
    }

    if (amount > Number(invoice.finalPrice)) {
      setError('amount', {
        message: formatMessage(
          { id: 'admin.invoices.invoicesCancelModal.amountMax' },
          { amount: invoice.finalPrice },
        ),
      });
      return true;
    }

    clearErrors('amount');
    return false;
  }

  async function handleCancelClick() {
    if (checkIsAmountError() || checkIsReasonError()) return;
    setIsButtonClicked(true);
    await cancelInvoice(
      {
        invoiceId: invoice.id,
        companyId: invoice.companyId,
        reason,
        amount: Number(amount),
      },
      handleCloseCancelModal,
    );
    setIsButtonClicked(false);
  }

  async function handleRefundWithFiscalizationClick() {
    if (checkIsAmountError() || checkIsReasonError()) return;
    setIsButtonClicked(true);
    await refundWithFiscalization(
      {
        invoiceId: invoice.id,
        companyId: invoice.companyId,
        reason,
        amount: Number(amount),
      },
      handleCloseCancelModal,
    );
    setIsButtonClicked(false);
  }

  async function handleRefundWithoutFiscalizationClick() {
    if (checkIsAmountError() || checkIsReasonError()) return;
    setIsButtonClicked(true);
    await refundWithoutFiscalization(
      {
        invoiceId: invoice.id,
        companyId: invoice.companyId,
        reason,
        amount: Number(amount),
      },
      handleCloseCancelModal,
    );
    setIsButtonClicked(false);
  }

  function handleCloseButton() {
    dispatch(invoicesActions.error());
    handleCloseCancelModal();
  }

  return {
    isButtonClicked,
    handleCloseButton,
    handleCancelClick,
    handleRefundWithFiscalizationClick,
    handleRefundWithoutFiscalizationClick,
  };
}
