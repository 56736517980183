import React from 'react';
import { LoadMore } from './LoadMore';

import { margin } from '@prototyp/gatsby-plugin-gumball/utils';

interface Props<T> {
  listing: T[];
  page: number;
  limit: number;
  itemFn: (item: T, i?: number) => React.ReactNode;
  onMore: () => void;
  listingStyles?: SerializedStyles | SerializedStyles[];
  theme?: 'black' | 'white';
}

export function PaginatedListing<T>({
  listing,
  page,
  limit,
  onMore,
  itemFn,
  listingStyles,
  theme,
}: Props<T>) {
  return (
    <>
      <div css={listingStyles}>
        {listing
          .filter((_, i) => i < page * limit)
          .map((item, i) => itemFn(item, i))}
      </div>

      {page * limit < listing.length && (
        <LoadMore
          customStyles={margin.top.huge}
          theme={theme}
          onClick={onMore}
        />
      )}
    </>
  );
}
