import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints, font } from '@prototyp/gatsby-plugin-gumball/utils';
import { linkUnderline } from 'style/global.styles';
import * as buttonStyles from 'modules/app/styles/Button.styles';

export const root = css`
  padding: ${spacing(40)};
  background: hsl(var(--color-grayscale-9));
  border-radius: ${spacing(3)};
  min-height: ${spacing(346)};
  min-width: ${spacing(416)};
  width: ${spacing(416)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: ${spacing(32)};

  :last-of-type {
    margin-right: 0;
  }
`;

export const title = css`
  ${font.size.large};
  color: hsl(var(--color-white-11));
  ${font.family.secondary};
  font-weight: normal;
  ${linkUnderline};

  @media ${breakpoints.medium} {
    ${font.size.xLarge};
  }
`;

export const priceAndButton = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const price = css`
  ${font.size.medium};
  ${font.weight.bold};

  @media ${breakpoints.medium} {
    ${font.size.large}
  }
`;

export const openings = css`
  ${font.size.small};
  color: hsl(var(--color-textLight-7));
`;

export const button = css`
  ${buttonStyles.root}
  ${buttonStyles.secondary}
  width: fit-content;
`;
