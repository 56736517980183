import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { billingActions } from '../redux';
import { masterRegex } from './../consts/payment';
import { useForm, RegisterOptions, UseFormSetValue } from 'react-hook-form';
import { visaRegex } from '../consts';
import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';

export const usePayment = () => {
  const { setValue } = useForm();
  const dispatch = useDispatch();

  const [cardType, setCardType] = useState('');
  const [expiryDate, setExpiryDate] = useState('');

  const http = useHttpClient('/api/v1/company/');

  const onExpiryDateChange = (value: string) => {
    if (value.length === 2) {
      setValue('expiryDate', `${value} / `);
      setExpiryDate(`${value} / `);

      return;
    }

    if (value.length === 4) {
      setValue('expiryDate', value.substring(0, 1));
      setExpiryDate(value.substring(0, 1));
    }
  };

  const onCardTypeChange = (value: string | string[]) => {
    if (Array.isArray(value)) return;

    setCardType(value);
  };

  async function getCard(companyId: string) {
    try {
      const { data } = await http.get<PaymentCard>(
        `${companyId}/settings/billing-details/registered-card`,
      );
      dispatch(billingActions.setCard(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.status === 404) {
        dispatch(billingActions.setCard());
        return;
      }
      dispatch(billingActions.error(e));
    }
  }

  const onCardNumberChange = (
    value: string,
    setValue: UseFormSetValue<PaymentForm>,
  ) => {
    if (Number(value.substring(0, 1)) === 4) {
      setValue('cardBrand', 'VISA');
      setCardType('VISA');

      return;
    }

    if (
      Number(value.substring(0, 1)) === 5 ||
      Number(value.substring(0, 1)) === 2
    ) {
      setValue('cardBrand', 'MASTER');
      setCardType('MASTER');

      return;
    }
  };

  const cardConstraints = (): RegisterOptions => {
    switch (cardType) {
      case 'VISA':
        return {
          required: true,
          pattern: {
            value: visaRegex,
            message: '',
          },
        };
      case 'MASTER':
        return {
          required: true,
          pattern: {
            value: masterRegex,
            message: '',
          },
        };
      default:
        return {
          required: true,
        };
    }
  };

  return {
    cardType,
    expiryDate,
    getCard,
    cardConstraints,
    onCardTypeChange,
    onCardNumberChange,
    onExpiryDateChange,
  };
};
