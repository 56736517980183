import React, { useEffect } from 'react';
import * as styles from './styles/ProfileBar.styles';
import EmailIcon from 'assets/icons/emailOutline.svg';
import { useConversations } from 'modules/talentMessages/hooks';
import { useSelector } from 'react-redux';
import { useCheckIfImpersonate } from 'modules/admin/static';
import { AppState } from 'modules/redux-store';
import { doNavigate } from 'modules/app/intl/doNavigate';

export const MessageNotification: React.FC = () => {
  const { getNotifications, conversationId } = useConversations();
  const { unreadConversations } = useSelector(
    (state: AppState) => state.talentMessages,
  );

  const isUnread = Boolean(unreadConversations.length);
  const isImpersonate = useCheckIfImpersonate();

  useEffect(() => {
    const interval = setInterval(getNotifications, 180000);

    return () => clearInterval(interval);
  }, [conversationId]);

  useEffect(() => {
    getNotifications();
  }, []);

  if (isImpersonate) {
    return null;
  }

  return (
    <div
      css={isUnread ? styles.messagesUnread : styles.messages}
      onClick={() => doNavigate('/profile/talent/messages')}
    >
      <EmailIcon /> <span>{unreadConversations.length.toString()}</span>
    </div>
  );
};
