import Download from 'assets/icons/download.svg';
import { FormattedMessage } from 'gatsby-plugin-intl';
import * as styles from 'modules/app/styles/ImageInput.styles';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  acceptedFiles?: string | string[];
  maxSize: number;
}

export const ImageInput: React.FC<Props> = ({
  name,
  acceptedFiles,
  maxSize,
}) => {
  const { watch, setValue, control } = useFormContext();
  const imageURI = watch(name);
  const [errorCode, setErrorCode] = useState<string>('');

  return (
    <>
      <label css={styles.topLabel}>
        <FormattedMessage id="shared.file_input.title" />
      </label>
      <Controller
        name={name}
        control={control}
        render={() => (
          <Dropzone
            onDrop={([image]) => {
              setValue(name, image);
            }}
            accept={acceptedFiles}
            maxSize={maxSize}
          >
            {({ getRootProps, getInputProps, fileRejections }) => {
              if (fileRejections[0]) {
                setErrorCode(fileRejections[0].errors[0].code);
              } else {
                setErrorCode('');
              }

              return (
                <>
                  <div {...getRootProps()}>
                    <input
                      name={name}
                      type="file"
                      {...getInputProps()}
                      disabled={imageURI}
                    />
                    {imageURI ? (
                      <>
                        <button
                          onClick={() => setValue(name, null)}
                          css={styles.deleteButton}
                        >
                          <FormattedMessage id="shared.file_input.remove" />
                        </button>
                        <img
                          src={URL.createObjectURL(imageURI)}
                          css={styles.uploadedImg}
                        />
                      </>
                    ) : (
                      <div
                        className="dropzone__uploadmessage"
                        css={styles.uploadOverlay}
                      >
                        <Download />
                      </div>
                    )}
                  </div>
                  {errorCode && errorCode === 'file-too-large' && (
                    <div css={styles.error}>
                      <FormattedMessage id="shared.file_input.large_file_message" />
                    </div>
                  )}
                  {errorCode === 'file-invalid-type' && (
                    <div css={styles.error}>
                      <FormattedMessage id="shared.file_input.invalid_type" />
                    </div>
                  )}
                </>
              );
            }}
          </Dropzone>
        )}
      />
      <label css={styles.bottomLabel}>
        <FormattedMessage id="shared.file_input.limit" />
      </label>
    </>
  );
};
