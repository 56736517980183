import { motion } from 'framer-motion';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import { Heading, MainContent, useGracePeriod } from 'modules/app';
import React from 'react';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';

import * as styles from 'modules/openings/styles/Openings.styles';
import Briefcase from 'assets/icons/briefcase.svg';
import { useCreateOpeningCheck } from '../hooks';

interface Props {
  companyHandle?: string;
}

export const NoOpenings: React.FC<Props> = ({ companyHandle }) => {
  const { isGracePeriod } = useGracePeriod();

  const isDisabled = useCreateOpeningCheck();

  return (
    <MainContent>
      <motion.div
        {...FADE_IN_MOVE_Y_REGULAR}
        transition={{ duration: 0.3 }}
        css={isGracePeriod ? styles.noOpeningsDisabled : styles.noOpenings}
      >
        <div>
          <Briefcase />
        </div>
        <Heading as="h3" size="fluidLarge">
          <FormattedMessage id="openings.noOpeningsTitle" />
        </Heading>

        <p>
          <FormattedMessage id="openings.noOpeningsContent" />
        </p>

        <Link
          css={isDisabled && styles.linkDisabled}
          to={`/profile/company/openings/${companyHandle}/opening/create`}
        >
          <FormattedMessage id="openings.noOpeningsBtn" />
        </Link>
      </motion.div>
    </MainContent>
  );
};
