import React from 'react';
import PersonIcon from 'assets/icons/person.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import DeactivateIcon from 'assets/icons/closeOutline.svg';
import MoreOptionsIcon from 'assets/icons/moreOptions.svg';
import * as styles from 'modules/admin/styles/UserItem.styles';
import { navigate } from 'gatsby-plugin-intl';
import { StatusPill } from './StatusPill';
import { Avatar, DropdownMenu, Tag } from 'modules/app';
import { FlatLink } from 'modules/admin/components';
import { formatDate, useCheckStaffRole } from 'modules/admin/static';

interface Props extends SearchUsersResponseData {
  onDelete: (id: string) => void;
  onDeactivate: (id: string) => void;
  onImpersonate: (id: string) => void;
}

export const UsersItem: React.FC<Props> = ({
  id,
  firstName,
  lastName,
  email,
  profilePicture,
  createdAt,
  emailVerifiedAt,
  companyRoles,
  newsletter,
  roleRoleId,
  deletedAt,
  otpSecret,
  onDelete,
  onDeactivate,
  onImpersonate,
}) => {
  const userActions = [
    {
      id: 'impersonate',
      disabled: !!roleRoleId || !!deletedAt,
      element: <FlatLink text="Impersonate" icon={<PersonIcon />} />,
    },
    {
      id: 'delete',
      disabled:
        Boolean(useCheckStaffRole()?.toUpperCase() === 'SUPPORT') ||
        firstName.includes('DELETED') ||
        typeof deletedAt !== 'string',
      element: <FlatLink text="Delete" icon={<DeleteIcon />} />,
    },
    {
      id: 'deactivate',
      disabled:
        Boolean(useCheckStaffRole()?.toUpperCase() === 'SUPPORT') ||
        typeof deletedAt === 'string' ||
        firstName.includes('DELETED'),
      element: <FlatLink text="Deactivate" icon={<DeactivateIcon />} />,
    },
  ];

  const handleUserAction = (action: string) => {
    switch (action) {
      case 'impersonate':
        onImpersonate(id);
        break;
      case 'delete':
        onDelete(id);
        break;
      case 'deactivate':
        onDeactivate(id);
        break;
    }
  };
  return (
    <tr css={styles.user}>
      <td>
        <span css={styles.userInfo}>
          <span css={styles.userAvatar}>
            <Avatar
              imagePath={profilePicture?.small}
              name={firstName}
              lastName={lastName}
            />
          </span>

          <span>
            <a
              css={styles.userNameClickable}
              onClick={() => navigate(`/admin/users/${id}`)}
            >
              <span>{`${firstName} ${lastName}`}</span>
              {roleRoleId && (
                <span css={styles.tag}>
                  <Tag text={roleRoleId} />
                </span>
              )}

              {deletedAt && (
                <span css={[styles.tag, styles.deletedAtTag]}>
                  <Tag text="Deleted" />
                </span>
              )}
            </a>
            <span css={styles.userEmail}>{email}</span>
          </span>
        </span>
      </td>
      <td>
        <span css={styles.userRegistered}>
          {formatDate(createdAt, 'PP, HH:mm')}
        </span>
      </td>
      <td>
        <span>
          <StatusPill
            pillStatus={!!emailVerifiedAt}
            statusTrueMessage="Verified"
            statusFalseMessage="Unverified"
          />
        </span>
      </td>
      <td>
        <span css={styles.userRelatedTo}>
          {Boolean(!companyRoles.length) && '/'}
          {companyRoles.map((company, key: number) => (
            <span key={key}>
              {company.companyName}
              {companyRoles.length === key + 1 ? '' : ','}
            </span>
          ))}
        </span>
      </td>
      <td>
        <span>
          <StatusPill
            pillStatus={newsletter}
            statusTrueMessage="Signed up"
            statusFalseMessage="Not signed up"
          />
        </span>
      </td>
      <td>
        <span>
          <StatusPill
            pillStatus={!!otpSecret}
            statusTrueMessage="Enabled"
            statusFalseMessage="Disabled"
          />
        </span>
      </td>
      <td>
        <span css={styles.userActions}>
          <DropdownMenu
            onSelect={(id) => {
              handleUserAction(id);
            }}
            customButtonStyles={styles.userActionsDropdown}
            hideCaret={true}
            placeholder={<MoreOptionsIcon />}
            items={userActions}
            position="bottomRight"
          />
        </span>
      </td>
    </tr>
  );
};
