import React from 'react';
import IconCheck from 'assets/icons/feature-check.svg';
import { Paragraph } from 'modules/app';
import { FormattedMessage } from 'gatsby-plugin-intl';

import * as featureListItem from 'modules/app/styles/FeatureListItem.styles';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_CONTROLLED } from 'style/animations.config';

export type FeaturesTheme = 'dark' | 'light';

interface Props {
  featureTitle: string;
  theme?: FeaturesTheme;
}

export const FeatureListItem: React.FC<Props> = ({ featureTitle, theme }) => {
  return (
    <motion.li
      variants={FADE_IN_MOVE_Y_CONTROLLED}
      transition={{ type: 'keyframes', ease: 'easeInOut' }}
      className="feature-list-item"
      css={featureListItem.item(theme)}
    >
      <span
        css={featureListItem.icon}
        aria-label="Checkmark icon inside a circle"
      >
        <IconCheck />
      </span>

      <Paragraph fontFamily="primary" size="medium" css={featureListItem.title}>
        <FormattedMessage id={featureTitle} />
      </Paragraph>
    </motion.li>
  );
};
