import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { font } from '@prototyp/gatsby-plugin-gumball/utils/font';

export const root = css`
  background: hsl(var(--color-text-1));
  padding: calc(var(--unit) * 15) 0 calc(var(--unit) * 20);

  h2 {
    font-family: var(--font-family-primary);
    font-weight: 400;
    padding-bottom: calc(var(--unit) * 10);
  }
`;

export const statsItemWrapper = css`
  column-gap: calc(var(--unit) * 8);
  display: grid;
  grid-gap: ${spacing(48)};

  @media ${breakpoints.medium} {
    grid-gap: ${spacing(32)};
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${breakpoints.large} {
    grid-template-columns: repeat(4, 1fr);
  }

  label {
    font-size: var(--font-size-large);
    margin-bottom: calc(var(--unit) * 3);
    display: block;
  }

  p {
    ${font.size.small};
    ${font.family.primary};
    color: hsl(var(--color-border-7));
  }
`;

export const skeleton = css`
  min-height: ${spacing(80)};
`;
