import { RouteComponentProps } from '@reach/router';
import React from 'react';
import {
  flex,
  font,
  padding,
  text,
} from '@prototyp/gatsby-plugin-gumball/utils';
import * as styles from 'modules/admin/styles/Manage.styles';

interface Props extends RouteComponentProps {
  label: string;
  team?: boolean;
}

export const CompanyInfo: React.FC<Props> = ({ label, children, team }) => {
  return (
    <div
      css={[
        flex.display.flex,
        flex.justify.between,
        !team ? styles.borderBottom : [padding.top.xxlrg, padding.bottom.xxlrg],
      ]}
    >
      <div
        css={[font.size.micro, text.color.textLight, text.transform.uppercase]}
      >
        {label}
      </div>
      <div css={[font.weight.bold, text.align.right]}>{children}</div>
    </div>
  );
};
