import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball';

export const root = css`
  border-bottom: 1px solid hsl(var(--color-borderUltraLight-9));
  transition: 0.2s ease;

  &:hover {
    background: hsl(var(--color-backgroundLight-10));
  }

  td {
    padding: ${spacing(16)} 0;
    padding-right: ${spacing(24)};
  }
`;

export const reason = css`
  word-break: break-word;
`;
