export function normalizeCompanyFormData(
  data: CompanyBasicsRequest,
  company?: Company,
  isOnboarding?: boolean,
): CompanyBasicsRequest {
  if (company && !isOnboarding) {
    return {
      handle: data.handle,
      name: data.name,
      website: `https://${data.website}`,
      country: data.country,
      establishedAt: `${data.establishedAt}-01-01`,
      representativeFirstName: company.representativeFirstName,
      representativeLastName: company.representativeLastName,
      representativeEmail: company.representativeEmail,
      representativePhone: company.representativePhone
        ? company.representativePhone.toLowerCase().replace(/\s+/g, '')
        : null,
    };
  }

  return {
    handle: data.handle,
    name: data.name,
    website: `https://${data.website}`,
    country: data.country,
    establishedAt: `${data.establishedAt}-01-01`,
    representativeFirstName: data.representativeFirstName,
    representativeLastName: data.representativeLastName,
    representativeEmail: data.representativeEmail,
    representativePhone: data.representativePhone
      ? data.representativePhone.toLowerCase().replace(/\s+/g, '')
      : null,
  };
}
