import { css } from '@emotion/react';

export const root = css`
  display: inline-flex;
  align-items: center;
  padding: var(--unit) calc(var(--unit) * 2) var(--unit) calc(var(--unit) * 3);
  background-color: hsl(var(--color-note-10));
  border-radius: 3px;
  width: auto;
  margin: var(--unit) calc(var(--unit) * 2) var(--unit) 0;

  &:hover {
    background-color: hsl(var(--color-note-9));
    cursor: default;
  }

  span {
    margin: 0;
    font-size: var(--font-size-tiny);
    line-height: var(--line-height-tiny);
    margin-right: var(--unit);
  }
`;

export const deleteIcon = css`
  &:hover {
    cursor: pointer;
    path {
      stroke: hsl(var(--color-text-1));
    }
  }
`;

export const addTag = css`
  position: relative;
`;

export const addTagBtn = css`
  padding: calc(var(--unit) * 2) 0;
  font-size: var(--font-size-xsmall);
  line-height: var(--line-height-xsmall);

  svg {
    margin-left: calc(var(--unit) * 2);
  }

  path {
    fill: hsl(var(--color-grayscale-4));
  }

  &:hover {
    path {
      fill: hsl(var(--color-text-1));
    }
  }
`;

export const addTagModal = css`
  padding: var(--unit) calc(var(--unit) * 2) calc(var(--unit) * 2);
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.06);
  background: hsl(var(--color-white-11));
  border-radius: 6px;
  max-width: calc(var(--unit) * 54);
  width: calc(var(--unit) * 54);
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
  z-index: 1;

  label {
    display: none;
  }
`;

export const addTagInput = css`
  padding: calc(var(--unit) * 2) calc(var(--unit) * 3) calc(var(--unit) * 3);
  width: 100%;
  border: none;
  outline: none;
  margin-bottom: calc(var(--unit) * 2);
  border-bottom: 1px solid hsl(var(--color-borderUltraLight-9));
`;

export const addTagInputError = css`
  ${addTagInput};
  border-bottom: 1px solid hsl(var(--color-error-7));
`;

export const addTagSuggested = css`
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: calc(var(--unit) * 40);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: var(--unit);
  }

  &::-webkit-scrollbar-thumb {
    background: hsl(var(--color-grayscale-2));
    border-radius: var(--unit);
  }

  li {
    padding: calc(var(--unit) * 2) calc(var(--unit) * 3);

    &:hover {
      background: hsl(var(--color-grayscale-1));
      cursor: pointer;
    }
  }
`;

export const newTagBtn = css`
  padding: calc(var(--unit) * 2) 0;
  font-size: var(--font-size-xsmall);
  line-height: var(--line-height-xsmall);

  path {
    fill: hsl(var(--color-grayscale-4));
  }

  &:hover {
    path {
      fill: hsl(var(--color-text-1));
    }
  }
`;
