import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils/breakpoints';

export const root = css`
  padding: ${spacing(80)} 0;
`;

export const name = css`
  @media ${breakpoints.medium} {
    display: flex;
    justify-content: space-between;
  }

  div:first-of-type {
    min-height: ${spacing(36)};
    border-radius: ${spacing(3)};

    @media ${breakpoints.medium} {
      flex-basis: 40%;
    }
  }

  div:last-of-type {
    min-height: ${spacing(36)};
    margin-top: ${spacing(16)};

    @media ${breakpoints.medium} {
      flex-basis: 20%;
      margin-top: 0;
    }
  }
`;

export const stages = css`
  min-height: ${spacing(80)};
  margin: ${spacing(40)} 0 ${spacing(32)};
  border-radius: ${spacing(3)};
`;

export const actions = css`
  min-height: ${spacing(60)};
  border-radius: ${spacing(3)};
`;
