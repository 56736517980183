import { ActionUnion } from 'modules/redux-store';
import { getActivityAction } from './actions';

interface GetAnalyticsInitState {
  data?: ActivityData[];
  isLoading: boolean;
  error?: ApiErrorData;
  lastPage?: number;
}

const INITIAL_STATE: GetAnalyticsInitState = {
  data: undefined,
  isLoading: false,
  error: undefined,
  lastPage: undefined,
};

export const activityReducer = (
  state: GetAnalyticsInitState = INITIAL_STATE,
  action: ActionUnion<typeof getActivityAction>,
): GetAnalyticsInitState => {
  switch (action.type) {
    case 'admin/GET_ACTIVITY':
      return <GetAnalyticsInitState>{
        ...state,
        error: undefined,
        isLoading: false,
        data: action.payload.data,
        lastPage: action.payload.lastPage,
      };
    case 'admin/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case 'admin/LOADING':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case 'admin/RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
