import React, { useEffect, useState } from 'react';
import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import Search from 'assets/icons/search.svg';
import {
  LoadingImg,
  Pill,
  SmallLoadingImg,
  TextInput,
  DropdownMenu,
  Button,
} from 'modules/app';
import {
  AdminCheckBox,
  SectionBody,
  SectionHead,
  UsersDeleteModal,
  UsersManageTable,
} from 'modules/admin/components';
import { useSelector } from 'react-redux';
import {
  useSearchUsers,
  useImpersonate,
  useSearchParams,
} from 'modules/admin/hooks';
import { AppState } from 'modules/redux-store';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import * as styles from 'modules/admin/styles/AdminOverview.styles';
import * as sectionStyles from '../styles/Section.styles';

const Pagination = React.lazy(
  () => import('modules/app/components/pagination/Pagination'),
);

export const ManageUsersOverview: React.FC<RouteComponentProps> = () => {
  const { search: queryString } = useLocation();
  const queryParams = new URLSearchParams(queryString);

  const sortByOptions = [
    'created_at',
    'updated_at',
    'deleted_at',
    'user_session_created_at',
  ];

  const sortOptions = ['DESC', 'ASC'];

  const urlParams = {
    urlSearch: queryParams.get('search') ?? '',
    urlPage: queryParams.get('page') ?? '1',
    urlSort: queryParams.get('sort') ?? 'DESC',
    urlSortBy: queryParams.get('sortBy') ?? 'created_at',
    urlHideDeleted: queryParams.get('hideDeleted') ?? 'true',
  };

  const {
    data: users,
    isLoading,
    error,
    total,
    lastPage,
  } = useSelector((state: AppState) => state.adminSearchUsers);

  const numberOfUsers = isLoading ? 0 : total;
  const title = `Manage users (${numberOfUsers})`;

  const { searchUsers } = useSearchUsers();
  const { requestImpersonate } = useImpersonate();
  const [userId, setUserId] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isDeactivateAction, setIsDeactivateAction] = useState(false);
  const [query, setQuery] = useState<ManageUsersDataQuery>({
    page: +urlParams.urlPage,
    sort: urlParams.urlSort,
    sortBy: urlParams.urlSortBy,
    hideDeleted: JSON.parse(urlParams.urlHideDeleted),
    search: urlParams.urlSearch,
    input: '',
  });

  const {
    handleSearch,
    handlePageChange,
    handleChangeSortBy,
    handleChangeSort,
    handleGenerateSearchFilterOptions,
  } = useSearchParams<ManageUsersDataQuery>({ query, setQuery });

  useEffect(() => {
    searchUsers(query);
    navigate(
      `?page=${query.page}&sort=${query.sort}&sortBy=${query.sortBy}&hideDeleted=${query.hideDeleted}&search=${query.search}`,
    );
  }, [query.page, query.sort, query.sortBy, query.hideDeleted, query.search]);

  useEffect(() => {
    if (queryString) return;
    navigate(
      `?page=${query.page}&sort=${query.sort}&sortBy=${query.sortBy}&hideDeleted=${query.hideDeleted}&search=${query.search}`,
    );
  }, [queryString]);

  const onResetFilter = () => {
    setQuery({
      page: 1,
      sort: 'DESC',
      sortBy: 'created_at',
      hideDeleted: true,
      search: '',
      input: '',
    });
  };

  const onHideDeleted = () => {
    setQuery({
      ...query,
      page: 1,
      hideDeleted: !query.hideDeleted,
    });
  };

  const onDelete = (id: string) => {
    setIsDeactivateAction(false);
    setUserId(id);
    setModalOpen(true);
    setQuery({
      ...query,
      page: 1,
    });
  };

  const onDeactivate = (id: string) => {
    setIsDeactivateAction(true);
    setUserId(id);
    setModalOpen(true);
    setQuery({
      ...query,
      page: 1,
    });
  };

  const onImpersonate = (userId: string) => {
    requestImpersonate(userId);
  };

  if (isLoading && !users) {
    return (
      <div css={[sectionStyles.section, styles.loadingSpace]}>
        <LoadingImg />
      </div>
    );
  }

  if (error) {
    return (
      <div css={sectionStyles.section}>
        <Pill type="error" title={error.message} text={' '} />
      </div>
    );
  }

  if (!users) {
    return null;
  }

  return (
    <div css={sectionStyles.users}>
      <motion.div transition={{ duration: 0.3 }} {...FADE_IN_MOVE_Y_REGULAR}>
        <SectionHead title={title}>
          <TextInput
            autoFocus
            value={query.input}
            icon={isLoading ? <SmallLoadingImg /> : <Search />}
            css={styles.search}
            placeholder="Search users by name or email. Press enter to run."
            onChange={(e) => setQuery({ ...query, input: e.target.value })}
            onKeyUp={(
              e: React.KeyboardEvent<HTMLInputElement> &
                React.ChangeEvent<HTMLInputElement>,
            ) => handleSearch(e)}
          />

          <div css={styles.searchFilter}>
            <AdminCheckBox
              onChange={onHideDeleted}
              name="hideDeleted"
              checked={query.hideDeleted}
              disabled={isLoading}
              isSmall={true}
            >
              Hide deleted
            </AdminCheckBox>

            <DropdownMenu
              onSelect={(id) => {
                handleChangeSortBy(id);
              }}
              placeholder={
                <div>
                  Sort by <strong>{query.sortBy}</strong>
                </div>
              }
              hideCaret={false}
              items={handleGenerateSearchFilterOptions(
                sortByOptions,
                query.sortBy,
              )}
              position="bottomRight"
              customButtonStyles={styles.sortByDropdown}
            />

            <DropdownMenu
              onSelect={(id) => {
                handleChangeSort(id);
              }}
              placeholder={
                <div>
                  Sort <strong>{query.sort}</strong>
                </div>
              }
              hideCaret={false}
              items={handleGenerateSearchFilterOptions(sortOptions, query.sort)}
              position="bottomRight"
              customButtonStyles={styles.sortByDropdown}
            />

            <Button
              buttonType={'outline'}
              size={'tiny'}
              onClick={onResetFilter}
              disabled={isLoading}
            >
              Reset filter
            </Button>
          </div>
        </SectionHead>
      </motion.div>

      <SectionBody>
        {Boolean(query.search?.length) &&
          Boolean(users.length) &&
          !isLoading && (
            <div css={styles.results}>
              <strong>{total}</strong> result(s) found for{' '}
              <strong>{query.search}</strong>
            </div>
          )}

        <motion.div transition={{ duration: 0.3 }} {...FADE_IN_MOVE_Y_REGULAR}>
          <UsersManageTable
            isLoading={isLoading}
            users={users}
            query={query}
            onDelete={onDelete}
            onDeactivate={onDeactivate}
            onImpersonate={onImpersonate}
          />

          {!error && lastPage !== 1 && (
            <React.Suspense fallback={null}>
              <div css={[styles.pagination, isLoading ? styles.loading : null]}>
                <Pagination
                  forcePage={query.page - 1}
                  initialPage={query.page - 1}
                  pageCount={lastPage || 1}
                  onPageChange={(pag) => handlePageChange(pag.selected + 1)}
                />
              </div>
            </React.Suspense>
          )}

          <UsersDeleteModal
            isOpen={modalOpen}
            setIsOpen={setModalOpen}
            userId={userId}
            isDeactivateAction={isDeactivateAction}
          />
        </motion.div>
      </SectionBody>
    </div>
  );
};
