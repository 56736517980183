export function handleRequestStatus(
  type: PublishStatus,
  isScheduled: boolean,
  isInternal: boolean,
): PublishStatus {
  if (type === 'draft') {
    return 'draft';
  }

  if (isScheduled) {
    if (isInternal) {
      return 'scheduled-for-publishing-(internal)';
    }

    return 'scheduled-for-publishing-(public)';
  }

  if (isInternal) {
    return 'published-internal';
  }

  return 'published-public';
}
