import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball';
import * as buttonStyles from 'modules/app/styles/Button.styles';
import * as usersItemStyles from 'modules/admin/styles/UserItem.styles';

export const root = css`
  border-bottom: 1px solid hsl(var(--color-borderUltraLight-9));
  transition: 0.2s ease;

  &:hover {
    background: hsl(var(--color-backgroundLight-10));
  }

  td {
    padding: ${spacing(16)} 0;
    padding-right: ${spacing(24)};
  }
`;

export const preview = css`
  ${buttonStyles.outline};
  ${buttonStyles.small};
  transition: border 0.3s ease;
`;

export const invoicesActions = css`
  ${usersItemStyles.userActions};
`;

export const invoicesActionsDropdown = css`
  ${usersItemStyles.userActionsDropdown};
`;
