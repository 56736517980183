import React from 'react';
import { RadioButtonGroup, TextArea } from 'modules/app';
import { useCompanyDescriptionValues } from '../../consts';

interface Props {
  companyData: Company | undefined;
}

export const TeamBondingInputs: React.FC<Props> = ({ companyData }) => {
  const { teamBuildingFrequencyValues } = useCompanyDescriptionValues();

  return (
    <>
      <TextArea
        name="teamBuildingActivities"
        topLabel="teamBonding.teamBuildingActivities.label"
        placeholder="teamBonding.teamBuildingActivities.placeholder"
        value={companyData?.teamBuildingActivities || ''}
        constraints={{
          required: false,
          maxLength: 800,
        }}
        maxChar="800"
        isOptional
      />
      <RadioButtonGroup
        name="teamBuildingFrequency"
        label="teamBonding.teamBuildingFrequency.label"
        data={teamBuildingFrequencyValues}
        selected={companyData?.teamBuildingFrequency}
      />
    </>
  );
};
