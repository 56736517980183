import { useState, useEffect } from 'react';
import { navigate } from '@reach/router';

export function useApplicantsQueryHandler(
  handleRequest: (query: ApplicantsQuery) => void,
  urlParams: ApplicantsUrlParams,
  queryString: string,
) {
  const [query, setQuery] = useState<ApplicantsQuery>({
    search: urlParams.urlSearch,
    page: Number(urlParams.urlPage),
    sort: urlParams.urlSort,
    sortBy: urlParams.urlSortBy,
    openingIds: urlParams.urlOpeningIds,
    companyId: urlParams.urlCompanyId,
    input: '',
  });

  useEffect(() => {
    if (queryString) return;
    navigate(
      `?page=${query.page}&sort=${query.sort}&sortBy=${query.sortBy}&search=${query.search}&companyId=${query.companyId}&openingIds=${query.openingIds}`,
    );
  }, [queryString]);

  useEffect(() => {
    handleRequest(query);
    navigate(
      `?page=${query.page}&sort=${query.sort}&sortBy=${query.sortBy}&search=${query.search}&companyId=${query.companyId}&openingIds=${query.openingIds}`,
    );
  }, [
    query.page,
    query.sort,
    query.sortBy,
    query.search,
    query.companyId,
    query.openingIds,
  ]);

  return {
    query,
    queryString,
    setQuery,
  };
}
