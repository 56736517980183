import { navigate } from 'gatsby-plugin-intl';
import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useMandatoryRefresh } from 'modules/auth';
import { companyOnboardingAction, useCompanyId } from 'modules/onboarding';
import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { companyDataAction } from '../redux';
import { useCompanyBasicsErrorHandle } from './useCompanyBasicsErrorHandle';

export function useCompanyBasics(
  form?: UseFormReturn<CompanyBasicsRequest, unknown>,
) {
  const [httpError, setHttpError] = useState<ApiErrorData>();
  const [isHandleExisting, setIsHandleExisting] = useState(false);

  const dispatch = useDispatch();
  const companyId = useCompanyId();
  const { toastSuccess } = useToastify();
  const { mandatoryRefresh } = useMandatoryRefresh();
  const { handleUpdateBasicInfoError, handleCompanyErrors } =
    useCompanyBasicsErrorHandle(setHttpError, setIsHandleExisting, form);

  const http = useHttpClient('/api/v1/company');

  async function requestCompanyUpdate(
    info: CompanyBasicsRequest,
    companyId: string,
  ) {
    try {
      const { data } = await http.put<Company, CompanyBasicsRequest>(
        `/${companyId}/profile`,
        info,
      );
      await mandatoryRefresh();
      setIsHandleExisting(false);
      setHttpError(undefined);
      dispatch(companyDataAction.companyData(data));
      navigate(`/profile/company/manage/${data.handle}/basic-information`);
      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      handleUpdateBasicInfoError(e);
    }
  }

  // Common handle function for different dispatch if user is on onboarding or not.
  function handleDispatchCompanyAction(
    companyData: Company | undefined,
    isOnboarding?: boolean,
  ) {
    if (!companyData) return;
    setHttpError(undefined);
    dispatch(companyOnboardingAction.setCompanyData(companyData));

    if (!isOnboarding) return;
    dispatch(
      companyOnboardingAction.updateCompanyOnboardingAccess({
        expertise: true,
      }),
    );
    navigate('/app/onboarding/company/expertise');
  }

  async function requestOnboardingCompanyUpdate(
    info: CompanyBasicsRequest,
    isOnboarding?: boolean,
  ) {
    dispatch(companyOnboardingAction.inRequest(true));
    try {
      const { data } = await http.put<Company, CompanyBasicsRequest>(
        `/${companyId}/profile`,
        info,
      );
      await mandatoryRefresh();
      setIsHandleExisting(false);
      handleDispatchCompanyAction(data, isOnboarding);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      handleCompanyErrors(e);
    } finally {
      dispatch(companyOnboardingAction.inRequest(false));
    }
  }

  async function requestOnboardingCreateCompany(
    info: CompanyBasicsRequest,
    isOnboarding?: boolean,
  ) {
    dispatch(companyOnboardingAction.inRequest(true));
    try {
      const { data } = await http.post<Company, CompanyBasicsRequest>(
        `/onboarding/create`,
        info,
      );
      await mandatoryRefresh();
      setIsHandleExisting(false);
      handleDispatchCompanyAction(data, isOnboarding);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      handleCompanyErrors(e);
    } finally {
      dispatch(companyOnboardingAction.inRequest(false));
    }
  }

  async function getHandleCount(handle: string) {
    try {
      const { data } = await http.get<{ count: number }>(
        `onboarding/create?handle=${handle}`,
      );
      return data.count;
    } catch (e) {
      return 0;
    }
  }

  async function requestCreateNewCompany(info: CompanyBasicsRequest) {
    setIsHandleExisting(false);
    const handleCount = await getHandleCount(info.handle);

    if (Boolean(handleCount)) {
      setIsHandleExisting(true);
      return;
    }

    try {
      const { data } = await http.post<Company, CompanyBasicsRequest>(
        `/onboarding/create`,
        info,
      );
      await mandatoryRefresh();
      setIsHandleExisting(false);
      setHttpError(undefined);
      dispatch(companyDataAction.companyData(data));
      navigate(`/profile/company/manage/${data.handle}/basic-information`);
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      handleCompanyErrors(e);
    }
  }

  return {
    httpError,
    isHandleExisting,
    setIsHandleExisting,
    requestCompanyUpdate,
    requestOnboardingCompanyUpdate,
    requestOnboardingCreateCompany,
    requestCreateNewCompany,
  };
}
