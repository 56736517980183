import { PrimitiveType } from 'intl-messageformat';
import React from 'react';
import { FieldErrors } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as styles from 'modules/app/styles/TextInput.styles';

interface Props {
  error?: FieldErrors;
  values?: Record<string, PrimitiveType>;
}

export const FormFieldError: React.FC<Props> = ({ error, values }) => {
  if (!error) {
    return null;
  }

  return (
    <>
      {error.message && (
        <span css={styles.fieldError}>
          <FormattedMessage id={error.message} values={values} />
        </span>
      )}
    </>
  );
};
