import React from 'react';
import * as styles from '../styles/BillingActivityTable.styles';
import { format } from 'date-fns';

interface Props {
  billingActivity: AdminBillingActivity;
}

export const BillingActivityTableItems: React.FC<Props> = ({
  billingActivity,
}) => {
  return (
    <tr css={styles.root}>
      <td>{billingActivity.companyName}</td>
      <td>{format(new Date(billingActivity.createdAt), 'PP, HH:mm')}</td>
      <td>{billingActivity.paymentType}</td>
      <td css={styles.reason}>{billingActivity.reason}</td>
      <td>{billingActivity.subscriptionPlan}</td>
      <td>{`${billingActivity.amount} ${billingActivity.currency}`}</td>
    </tr>
  );
};
