export function normalizeRedirectUrl(
  url: string,
  search?: string,
  hash?: string,
) {
  const splitUrl = url.split('/');
  splitUrl.splice(0, 2);

  const redirectUrl = `/${splitUrl.join('/')}`;

  if (hash) {
    return `${redirectUrl}${hash}`;
  }

  return `${redirectUrl}${search}`;
}
