import { useEffect, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';

type DefaultValue = {
  [key: string]: unknown;
};

interface Props<T> {
  control: Control;
  initial: T;
  equalityFn: (initial: T, defaultValues: DefaultValue) => boolean;
  onChange: (isChanged: boolean) => void;
}

export function ChangeableEffect<T>({
  control,
  initial,
  equalityFn,
  onChange,
}: Props<T>) {
  const allValues = useWatch({ control });
  const [initialValues, setInitialValues] = useState<T>(() => initial);

  useEffect(() => {
    setInitialValues(initial);
  }, [initial]);

  const equalityEffect = () => {
    // We're taking the opposite value of equalityFn because this value is used for
    // enabling disabled button which value is `true` by default inside components.
    const eqProduct = !equalityFn(initialValues, allValues);
    onChange(eqProduct);
  };

  useEffect(equalityEffect, [onChange, allValues, equalityFn]);

  return null;
}
