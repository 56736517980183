export const variants = {
  open: {
    width: 120,
    transition: {
      duration: 0.6,
      ease: [0.19, 1, 0.22, 1],
    },
  },
  closed: {
    width: 0,
    transition: {
      duration: 0.3,
      ease: [1, 0, 0, 1],
    },
  },
};
