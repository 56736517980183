import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { motion } from 'framer-motion';
import { FormattedMessage } from 'gatsby-plugin-intl';
import {
  CheckboxField,
  CheckboxGroup,
  Label,
  SelectComponent,
  TextEditor,
  useCompanyDescriptionValues,
  useTransformSelect,
} from 'modules/app';
import React from 'react';

import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';

interface Props {
  companyData: Company;
  showHRQuestions: boolean;
  showLeadersQuestions: boolean;
}
export const CarrerPathInputs: React.FC<Props> = ({
  companyData,
  showHRQuestions,
  showLeadersQuestions,
}) => {
  const {
    sessionFrequency,
    careerGrowPath,
    hrSessionConductor,
    topics,
    personInCharge,
    leadersSessionConductor,
  } = useCompanyDescriptionValues();

  const selectedHRFrequency = useTransformSelect(
    companyData?.hrSessionFrequency,
    sessionFrequency,
  );
  const selectedHRConductor = useTransformSelect(
    companyData?.hrSessionConductor,
    hrSessionConductor,
  );

  const selectedLeadersFrequency = useTransformSelect(
    companyData?.leaderSessionFrequency,
    sessionFrequency,
  );
  const selectedLeadersConductor = useTransformSelect(
    companyData?.leaderSessionConductor,
    leadersSessionConductor,
  );

  return (
    <>
      <SelectComponent
        name="careerGrowthPath"
        label={<FormattedMessage id="careerPath.careerGrowthPath.label" />}
        constraints={{
          required: false,
        }}
        selectedValue={useTransformSelect(
          companyData?.careerGrowthPath,
          careerGrowPath,
        )}
        values={careerGrowPath}
        isOptional
      />

      <SelectComponent
        name="personInCharge"
        label={<FormattedMessage id="careerPath.personInCharge.label" />}
        constraints={{
          required: false,
        }}
        selectedValue={useTransformSelect(
          companyData?.personInCharge,
          personInCharge,
        )}
        values={personInCharge}
        isOptional
      />

      <div css={margin.bottom.xxxlrg}>
        <Label
          label={<FormattedMessage id="careerPath.hrOneOnOne.label" />}
          labelStyle
          customStyles={margin.bottom.med}
        />
        <CheckboxField
          name="hrOneOnOne"
          checked={companyData?.hrOneOnOne || false}
          constraints={{}}
        >
          <FormattedMessage id="careerPath.hrOneOnOne.answer" />
        </CheckboxField>
      </div>

      {showHRQuestions && (
        <motion.div {...FADE_IN_MOVE_Y_REGULAR}>
          <SelectComponent
            name="hrSessionFrequency"
            label={<FormattedMessage id="careerPath.sessionFrequency.label" />}
            constraints={{
              required: showHRQuestions,
            }}
            selectedValue={selectedHRFrequency}
            values={sessionFrequency}
          />

          <SelectComponent
            name="hrSessionConductor"
            label={
              <FormattedMessage id="careerPath.hrSessionConductor.label" />
            }
            constraints={{
              required: showHRQuestions,
            }}
            selectedValue={selectedHRConductor}
            values={hrSessionConductor}
          />
        </motion.div>
      )}

      <div css={margin.bottom.xxxlrg}>
        <Label
          label={<FormattedMessage id="careerPath.leadersOneOnOne.label" />}
          labelStyle
          customStyles={margin.bottom.med}
        />
        <CheckboxField
          name="leadersOneOnOne"
          checked={companyData?.leadersOneOnOne || false}
          constraints={{}}
        >
          <FormattedMessage id="careerPath.leadersOneOnOne.answer" />
        </CheckboxField>
      </div>

      {showLeadersQuestions && (
        <motion.div {...FADE_IN_MOVE_Y_REGULAR}>
          <SelectComponent
            name="leaderSessionFrequency"
            label={<FormattedMessage id="careerPath.sessionFrequency.label" />}
            constraints={{
              required: showLeadersQuestions,
            }}
            selectedValue={selectedLeadersFrequency}
            values={sessionFrequency}
          />

          <SelectComponent
            name="leaderSessionConductor"
            label={
              <FormattedMessage id="careerPath.leadersSessionConductor.label" />
            }
            constraints={{
              required: showLeadersQuestions,
            }}
            selectedValue={selectedLeadersConductor}
            values={leadersSessionConductor}
          />
        </motion.div>
      )}

      <div css={margin.bottom.xxxlrg}>
        <CheckboxGroup
          groupLabel="careerPath.topics.label"
          groupName="topics"
          data={topics}
          selectedValues={companyData.topics}
        />
      </div>

      <TextEditor
        label="careerPath.employeeDevelopment.label"
        name="employeeDevelopment"
        defaultValue={companyData?.employeeDevelopment || undefined}
        constraints={{
          required: false,
          maxLength: 1000,
        }}
        maxChar={1000}
        placeholder="careerPath.employeeDevelopment.placeholder"
      />
    </>
  );
};
