import { css } from '@emotion/react';

export const root = css`
  min-height: 100vh;
`;

export const wrapper = css`
  background: hsl(var(--color-white-11));
  box-sizing: content-box;
`;

export const imageWrapper = css`
  position: relative;
`;
