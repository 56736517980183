import { ActionModal, DropdownMenu } from 'modules/app';
import React from 'react';
import * as buttonStyles from 'modules/app/styles/Button.styles';
import MoreOptions from 'assets/icons/moreOptions.svg';
import { AnimatePresence } from 'framer-motion';
import { DeleteModal } from './DeleteModal';
import { usePlanRestrictions } from '../hooks/usePlanRestrictions';
import { OpeningRestrictionModal } from './OpeningRestrictionModal';
import { openingActions } from '../consts';
import { useOpeningActions } from '../hooks/useOpeningActions';
import { DeactivateLimitModal } from './DeactivateLimitModal';
import { useCreateOpeningCheck } from '../hooks';

interface Props {
  data: CompanyOpening;
  theme?: 'light' | 'dark';
  onArchive: (companyId: string, openingId: string) => void;
  onDelete: (companyId: string, openingId: string) => void;
}

export const OpeningActions: React.FC<Props> = ({
  data,
  theme = 'light',
  onArchive,
  onDelete,
}) => {
  const {
    onActionSelect,
    isModal,
    setIsModal,
    isLimitModal,
    setIsLimitModal,
    isDeactivateModal,
    setIsDeactivateModal,
  } = useOpeningActions(data);
  const { isPlanLimit, isDeactivateLimit } = usePlanRestrictions();

  const isDisabled = useCreateOpeningCheck();

  return (
    <>
      <AnimatePresence>
        {isModal && (
          <ActionModal isOpen={isModal} setIsOpen={setIsModal}>
            <DeleteModal
              setIsModal={setIsModal}
              onDelete={onDelete}
              data={data}
            />
          </ActionModal>
        )}
        {isPlanLimit && isLimitModal && (
          <ActionModal isOpen={isLimitModal} setIsOpen={setIsLimitModal}>
            <OpeningRestrictionModal
              handle={data.companyHandle}
              setIsOpen={setIsLimitModal}
            />
          </ActionModal>
        )}
        {isDeactivateLimit && isDeactivateModal && (
          <ActionModal
            isOpen={isDeactivateModal}
            setIsOpen={setIsDeactivateModal}
          >
            <DeactivateLimitModal
              setIsOpen={setIsDeactivateModal}
              handle={data.companyHandle}
              companyId={data.companyId}
              openingId={data.id}
            />
          </ActionModal>
        )}
      </AnimatePresence>

      <DropdownMenu
        disabled={isDisabled}
        onSelect={(id: string) => onActionSelect(id, onArchive)}
        placeholder={<MoreOptions />}
        items={openingActions(data)}
        position="topRight"
        hideCaret
        customButtonStyles={
          theme === 'light'
            ? buttonStyles.svgButton
            : buttonStyles.svgButtonDark
        }
      />
    </>
  );
};
