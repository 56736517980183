import { transformPhone } from './transformPhone';

export function normalizeInitialValues(data: BasicsRequest): BasicsFormData {
  return {
    title: data.title || '',
    workplace: data ? data.workplace : [],
    phone: transformPhone(data?.phone),
    about: data?.about || '',
    place: data?.cityCountry || '',
    dob: new Date(data.dob),
    gender: data?.gender || null,
    disability: data?.disability || null,
    firstName: data.firstName,
    lastName: data.lastName,
  };
}
