import { css } from '@emotion/react';
import { color, spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { wrappers } from 'style/variables';
import { ColorInput } from '../components/shared/ImageGridLayout';

export const wrapper = css`
  max-width: ${wrappers.page};
  margin: 0 auto;
  padding: 0 var(--spacing-xlrg);
  box-sizing: content-box;
`;

export const grid = css`
  display: grid;
  row-gap: var(--spacing-huge);

  @media ${breakpoints.medium} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--spacing-xxxlrg);
  }
`;

export const imageGridLayout = (bgColor?: ColorInput) => css`
  padding: ${spacing(20)} 0 ${spacing(40)};

  background-color: ${bgColor ? color(...bgColor) : 'transparent'};

  @media ${breakpoints.medium} {
    padding: ${spacing(100)} 0 ${spacing(120)};
  }
`;

export const infoSection = (fontColor: ColorInput = ['text', 1]) => css`
  grid-column: 1 / -1;

  display: grid;
  row-gap: var(--spacing-xlrg);

  color: ${color(...fontColor)};

  @media ${breakpoints.medium} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--spacing-xxxlrg);
  }
`;

export const heading = css`
  @media ${breakpoints.medium} {
    grid-column: 1 / span 5;
  }
`;

export const description = css`
  @media ${breakpoints.medium} {
    grid-column: 8 / span 4;
  }
`;
