export const useCalculateCulture = () => {
  const calculateCulture = (
    companyCulture: Culture,
    userCulture: Culture,
  ): string => {
    const companyValues = Object.values(companyCulture);
    const userValues = Object.values(userCulture);

    const finals: number[] = [];
    for (let i = 0; i < companyValues.length; i++) {
      const user = Math.abs(Math.ceil(companyValues[i] * 100));
      const company = Math.abs(Math.ceil(userValues[i] * 100));

      const final = 100 - Math.abs(user - company);

      finals.push(final);
    }

    return Math.round((finals.reduce((a, b) => a + b) / 4) * 100) / 100 + '';
  };

  return {
    calculateCulture,
  };
};
