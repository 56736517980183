export enum WorkplaceEnum {
  'remote' = 'openings.typeOfWorkplace.items.remote',
  'fieldwork' = 'openings.typeOfWorkplace.items.fieldWork',
  'office-work' = 'openings.typeOfWorkplace.items.officeWork',
  'on-site' = 'openings.typeOfWorkplace.items.officeWork',
  'hybrid' = 'openings.typeOfWorkplace.items.hybrid',
}

export enum WorkingHoursEnum {
  'full-time' = 'openings.workingHours.items.fullTime',
  'part-time' = 'openings.workingHours.items.partTime',
}
