import { useHttpClient } from 'modules/app';
import { billingActions } from 'modules/billing';
import { AppState } from 'modules/redux-store';
import { useDispatch, useSelector } from 'react-redux';

export const usePlanRestrictions = () => {
  const dispatch = useDispatch();
  const { activePlan } = useSelector((state: AppState) => state.billing);

  const http = useHttpClient('/api/v1/company/');

  const isPlanLimit =
    activePlan?.allowedActiveOpeningCount ===
    activePlan?.currentActiveOpeningCount;

  const isDeactivateLimit =
    activePlan?.currentActiveOpeningCount ===
    activePlan?.futurePlanAllowedOpeningCount;

  async function getPlan(companyId: string) {
    try {
      const { data } = await http.get<ActivePlanCached>(
        `${companyId}/settings/company-subscription-plans/active-cached`,
      );
      dispatch(billingActions.setActivePlan(data));
    } catch (e) {}
  }

  return { isPlanLimit, getPlan, isDeactivateLimit };
};
