import { Skeleton } from 'modules/app';
import React from 'react';

import * as styles from 'modules/talentMessages/styles/MessagesLoading.styles';

interface SkeletonProps {
  type?: 'input' | 'textarea';
  customStyles?: SerializedStyles;
}

const InputSkeleton: React.FC<SkeletonProps> = ({
  type = 'input',
  customStyles,
}) => {
  return (
    <div css={customStyles}>
      <Skeleton count={1} customStyles={styles.skeletonLabel} />
      <Skeleton
        count={1}
        customStyles={type === 'input' ? styles.skeleton : styles.skeletonArea}
      />
    </div>
  );
};

export const MessagesLoading: React.FC = () => {
  return (
    <div css={styles.root}>
      <div>
        <Skeleton count={4} containerStyles={styles.messages} />
      </div>

      <div css={styles.inputs}>
        <InputSkeleton />
        <InputSkeleton type="textarea" customStyles={styles.textarea} />
      </div>
    </div>
  );
};
