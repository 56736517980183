import { IntlProvider } from 'gatsby-plugin-intl';
import React from 'react';

export const Provider: React.FC = ({ children }) => {
  return (
    <IntlProvider defaultLocale="en" locale="en">
      {children}
    </IntlProvider>
  );
};
