import { ActionUnion } from 'modules/redux-store';

import { quickSearchAction } from './actions';

interface SearchInitState {
  searchValue?: string;
  companies: {
    isLoading: boolean;
    error?: ApiErrorData;
    companies?: ExploreCompanies[];
    lastPage: number;
    page: number;
  };
  openings: {
    isLoading: boolean;
    error?: ApiErrorData;
    openings?: OpeningsSearch[];
    lastPage: number;
    page: number;
  };
}

const INITIAL_STATE: SearchInitState = {
  openings: {
    page: 1,
    lastPage: 1,
    isLoading: false,
  },
  companies: {
    page: 1,
    lastPage: 1,
    isLoading: false,
  },
};

export const quickSearchReducer = (
  state: SearchInitState = INITIAL_STATE,
  action: ActionUnion<typeof quickSearchAction>,
): SearchInitState => {
  switch (action.type) {
    case 'quickSearch/SEARCH_VALUE':
      return {
        ...state,
        searchValue: action.payload,
      };

    case 'quickSearch/COMPANIES':
      return {
        ...state,
        companies: {
          ...action.payload,
          isLoading: false,
          error: undefined,
        },
      };
    case 'quickSearch/COMPANIES_LOADING':
      return {
        ...state,
        companies: {
          ...state.companies,
          isLoading: true,
        },
      };
    case 'quickSearch/COMPANIES_ERROR':
      return {
        ...state,
        companies: {
          ...state.companies,
          error: action.payload,
        },
      };

    case 'quickSearch/OPENINGS':
      return {
        ...state,
        openings: {
          ...action.payload,
          isLoading: false,
          error: undefined,
        },
      };
    case 'quickSearch/OPENINGS_LOADING':
      return {
        ...state,
        openings: {
          ...state.openings,
          isLoading: true,
        },
      };
    case 'quickSearch/OPENINGS_ERROR':
      return {
        ...state,
        openings: {
          ...state.openings,
          error: action.payload,
        },
      };

    case 'quickSearch/SEARCH_RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
