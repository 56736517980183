import React from 'react';
import {
  CheckboxButtonGroup,
  CheckboxField,
  RadioButtonGroup,
  SelectComponent,
  useCompanyDescriptionValues,
} from 'modules/app';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { talentOpeningsAction } from '../redux';

import * as styles from 'modules/talentOpenings/styles/SearchFilters.styles';
import { GooglePlacesArray } from './GooglePlacesArray';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils/margin';
import { useNearMe } from '../hooks';

const filterSelectedValue = (
  values: { value: string; label: string }[],
  selectedValue: string,
) => {
  return values.find((value) => value.value === selectedValue);
};

export const SearchFilters: React.FC = () => {
  const intl = useIntl();
  const { typeOfWorkplace, workingHoursData, contractType, careerLevel } =
    useCompanyDescriptionValues();
  const contractTypeValues = [
    ...contractType,
    { value: '', label: intl.formatMessage({ id: 'search.allLabel' }) },
  ];
  const careerLevelValues = [
    ...careerLevel,
    { value: '', label: intl.formatMessage({ id: 'search.allLabel' }) },
  ];
  const dispatch = useDispatch();
  const { params } = useSelector((state: AppState) => state.talentOpenings);
  const isNearMe = Boolean(params.xLocation && params.yLocation);

  const getNewOpenings = (data: Partial<OpeningsSearchParams>) => {
    dispatch(
      talentOpeningsAction.searchParams({
        params: { ...params, ...data, page: 1 },
      }),
    );
  };
  const { onNearMe, locations, setLocations } = useNearMe(getNewOpenings);

  return (
    <div css={styles.filters}>
      <GooglePlacesArray locations={locations} setLocations={setLocations} />

      <CheckboxField
        name="nearMe"
        constraints={{}}
        checked={isNearMe}
        customStyles={margin.bottom.xxxlrg}
        isSmall
        onChecked={(checked) => {
          onNearMe(checked);
        }}
      >
        <FormattedMessage id="search.nearMeLabel" />
      </CheckboxField>
      <CheckboxButtonGroup
        customStyles={styles.workingHours}
        isMulti={true}
        groupLabel="openings.typeOfWorkplace.label"
        groupName="typeOfWorkplace"
        data={typeOfWorkplace}
        selectedValues={[]}
        onValuesChange={(selected) => {
          const workplaces: string = selected.join(',');

          getNewOpenings({ typeOfWorkplace: workplaces || undefined });
        }}
      />
      <RadioButtonGroup
        label="openings.workingHours.label"
        name="workingHours"
        data={workingHoursData}
        selected={undefined}
        constraints={{}}
        onValueChange={(selected) => {
          getNewOpenings({ workingHours: selected });
        }}
      />

      <SelectComponent
        name="contractType"
        label={<FormattedMessage id="openings.contractType.label" />}
        constraints={{}}
        values={contractTypeValues}
        customStyles={styles.contractType}
        onValueChange={(value) => {
          if (typeof value === 'string') {
            getNewOpenings({ contractType: value || undefined });
          }
        }}
        selectedValue={filterSelectedValue(contractTypeValues, '')}
      />

      <SelectComponent
        name="careerLevel"
        label={<FormattedMessage id="openings.careerLevel.label" />}
        constraints={{}}
        values={careerLevelValues}
        onValueChange={(value) => {
          if (typeof value === 'string') {
            getNewOpenings({ careerLevel: value || undefined });
          }
        }}
        selectedValue={filterSelectedValue(careerLevelValues, '')}
      />
    </div>
  );
};
