import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { applicationsAction } from 'modules/applications/redux/actions';
import { AppState } from 'modules/redux-store';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { settingsAction } from '../redux';

export const useReasons = () => {
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToastify();
  const { page, lastPage, reasons } = useSelector(
    (state: AppState) => state.settings.reasons,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const http = useHttpClient('/api/v1/company');

  function handleError(e: unknown) {
    if (!(e instanceof HttpError)) return;
    if (e.cause === 'item_already_exists') {
      toastError('settings.reasonsContent.alreadyExists');
      return;
    }
    toastError();
  }

  async function getReasons(companyId: string, page: number, load = true) {
    load && dispatch(settingsAction.loading());

    try {
      const { data } = await http.get<ReasonsResponse>(
        `${companyId}/settings/reasons`,
        {
          params: {
            page,
            per_page: 15,
          },
        },
      );

      dispatch(
        settingsAction.setReasons({
          reasons: data.data,
          lastPage: data.lastPage,
          page: data.page,
        }),
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(settingsAction.error(e));
    }
  }

  async function addReason(
    companyId: string,
    info: ReasonFormData,
    callback: VoidFunction,
  ) {
    setIsLoading(true);
    try {
      await http.post<ReasonFormData>(`${companyId}/settings/reasons`, info);
      toastSuccess('settings.reasonsContent.createSuccess');
      getReasons(companyId, page, false);
      callback();
    } catch (e) {
      handleError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function editReason(
    companyId: string,
    reasonId: string,
    info: ReasonFormData,
    callback: VoidFunction,
  ) {
    setIsLoading(true);
    try {
      await http.put<ReasonFormData>(
        `${companyId}/settings/reasons/${reasonId}`,
        info,
      );
      toastSuccess('settings.reasonsContent.saveSuccess');
      getReasons(companyId, page, false);
      callback();
    } catch (e) {
      handleError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function deleteReason(
    companyId: string,
    reasonId: string,
    callback: (page: number) => void,
  ) {
    setIsDeleting(true);
    try {
      await http.delete(`${companyId}/settings/reasons/${reasonId}`);
      const isPageChanging =
        page === lastPage && page > 1 && reasons && reasons.length === 1;
      if (isPageChanging) {
        callback(page - 1);
        return;
      }
      getReasons(companyId, page, false);
    } catch (e) {
      toastError();
    } finally {
      setIsDeleting(false);
    }
  }

  async function getReasonsList(companyId: string) {
    try {
      const { data } = await http.get<ReasonsList[]>(
        `/${companyId}/settings/reasons/short-list`,
      );
      dispatch(applicationsAction.setReasonsValues(data));
      return;
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      return e;
    }
  }

  return {
    isLoading,
    isDeleting,
    addReason,
    editReason,
    getReasons,
    deleteReason,
    getReasonsList,
  };
};
