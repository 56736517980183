import { FormError, GenericError } from 'modules/app/intl';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  maxChar?: number;
  minChar?: number;
}

export const TextEditorErrors: React.FC<Props> = ({
  name,
  minChar,
  maxChar,
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  if (!errors[name]) {
    return null;
  }

  return (
    <>
      {errors[name] && errors[name].type === 'minLengthValidation' && (
        <GenericError
          message={errors[name].message}
          values={{ min: minChar }}
        />
      )}

      {errors[name] && errors[name].type === 'maxLengthValidation' && (
        <GenericError
          message={errors[name].message}
          values={{ max: maxChar }}
        />
      )}

      {errors[name] &&
        errors[name].type !== 'minLengthValidation' &&
        errors[name].type !== 'maxLengthValidation' && (
          <FormError errors={errors} name={name} />
        )}
    </>
  );
};
