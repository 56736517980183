interface Country {
  value: string;
  label: string;
}

type TransformSelectedCountry = Country | undefined;

export function transformSelectedCountry(
  countryCode: string | undefined,
  countries: Country[],
): TransformSelectedCountry {
  const countryLabel = countries.filter(
    (country) => country.value === countryCode,
  );

  if (!countryCode || !countries) return undefined;

  return {
    value: countryCode,
    label: countryLabel[0].label,
  };
}
