import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { talentOnboardingAction } from 'modules/onboarding';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

interface GetProjectsProps {
  search?: string;
  page?: number;
  perPage?: number;
  load?: boolean;
}

export function useTalentProjects() {
  const dispatch = useDispatch();
  const { toastSuccess } = useToastify();

  const [pageCount, setPageCount] = useState(1);
  const [httpError, setHttpError] = useState<HttpError>();
  const [validationError, setValidationError] = useState<HttpError>();
  const [isLoading, setIsLoading] = useState(true);
  const [profileProjects, setProfileProjects] = useState<Project[]>();

  const talentProfileService = useHttpClient<Basics>('/api/v1/talent/profile');

  async function getProfileProjects({
    search,
    page,
    perPage,
  }: GetProjectsProps) {
    try {
      const {
        data: { data, lastPage, page: projectPage },
      } = await talentProfileService.get<ProjectsResponse>('/projects', {
        params: {
          search,
          page,
          perPage,
        },
      });
      typeof window !== 'undefined' &&
        window.scrollTo({ top: 0, behavior: 'smooth' });
      setHttpError(undefined);
      setIsLoading(false);
      setProfileProjects(data);
      setPageCount(lastPage);

      return { projects: data, pageCount: lastPage, page: projectPage };
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
      setIsLoading(false);
    }
  }

  async function getProfileProjectsDispatch({
    search,
    page,
    perPage,
    load = true,
  }: GetProjectsProps) {
    load && dispatch(talentOnboardingAction.loading());

    try {
      const {
        data: { data },
      } = await talentProfileService.get<ProjectsResponse>('/projects', {
        params: {
          search,
          page,
          perPage,
        },
      });

      const projectsList: ListingData[] = data?.map((item) => ({
        title: item.name,
        content: item.owner,
        id: item.id,
        featured: item.featured,
      }));

      dispatch(talentOnboardingAction.setListArray(projectsList));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(talentOnboardingAction.error(e));
    }
  }

  async function deleteProject(id: string) {
    try {
      await talentProfileService.delete(`/projects/${id}`);

      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setHttpError(e);
    }
  }

  async function addProject(project: ProjectRequest) {
    try {
      const { data } = await talentProfileService.post<Project, ProjectRequest>(
        '/projects',
        project,
      );

      setHttpError(undefined);
      setValidationError(undefined);

      return { project: data };
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.cause === 'max_number_reached:featured_projects') {
        setValidationError(e);
      }
      setHttpError(e);
      return { error: e };
    }
  }

  async function updateProject(project: ProjectRequest, projectId?: string) {
    try {
      const { data } = await talentProfileService.put<Project, ProjectRequest>(
        `/projects/${projectId}`,
        project,
      );

      setHttpError(undefined);
      setValidationError(undefined);

      return { project: data };
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.cause === 'max_number_reached:featured_projects') {
        setValidationError(e);
      }
      setHttpError(e);
      return { error: e };
    }
  }

  return {
    httpError,
    validationError,
    profileProjects,
    pageCount,
    isLoading,
    setProfileProjects,
    setPageCount,
    setHttpError,
    getProfileProjects,
    getProfileProjectsDispatch,
    deleteProject,
    addProject,
    updateProject,
  };
}
