import { navigate } from 'gatsby-plugin-intl';
import { useDispatch } from 'react-redux';
import { companyOnboardingAction } from 'modules/onboarding';

export const useCompanyReaction = (
  step: CompanyOnboardingStep,
  onSubmit?: () => void,
) => {
  const dispatch = useDispatch();
  const isOnSubmit = step === 'information/basics';

  const getListTitle = (): string => {
    switch (step) {
      case 'information/basics':
        return 'onboarding.dynamic_headings.information_basics.header';
      case 'expertise':
        return 'onboarding.dynamic_headings.company_expertise.heading';
    }
  };

  const onReactionSubmit = () => {
    if (onSubmit && isOnSubmit) {
      onSubmit();
      return;
    }

    if (step !== 'expertise') return;

    dispatch(
      companyOnboardingAction.updateCompanyOnboardingAccess({
        finishedScreen: true,
      }),
    );
    navigate('/app/onboarding-company-final');
  };

  return {
    onReactionSubmit,
    getListTitle,
  };
};
