import { AdminTable, CompanyData, NoData } from 'modules/admin/components';
import { companyListHeaders } from 'modules/admin/consts';
import React from 'react';

interface Props {
  search: string;
  companies: AdminResponse<Companies>;
  handleSort?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleDelete: (id: string) => void;
  handleDeactivate: (id: string) => void;
}

export const CompanyList: React.FC<Props> = ({
  search,
  companies,
  handleDelete,
  handleSort,
  handleDeactivate,
}) => {
  if (!Boolean(companies.data.length)) {
    return (
      <NoData dataType={search ? 'search' : 'companies'} search={search} />
    );
  }
  return (
    <AdminTable headers={companyListHeaders} onSort={handleSort}>
      {companies.data.map((company: Companies) => (
        <CompanyData
          key={company.id}
          company={company}
          onDelete={handleDelete}
          onDeactivate={handleDeactivate}
        />
      ))}
    </AdminTable>
  );
};
