import React, { useState } from 'react';

export const useMemoryUpload = (
  maxSize: number,
  onImageUploaded: (result: FileReader) => void,
) => {
  const [isFileTooBig, setIsFileTooBig] = useState<boolean>();
  const [isFileInvalidFormat, setIsFileInvalidFormat] = useState<boolean>();
  const [isUploading, setIsUploading] = useState(false);

  return {
    isFileTooBig: isFileTooBig,
    isFileInvalidFormat: isFileInvalidFormat,
    isUploading: isUploading,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsUploading(true);
      setIsFileInvalidFormat(undefined);
      setIsFileTooBig(undefined);

      if (e && e.target && e.target.files) {
        const file = e.target?.files[0];

        if (file) {
          if (file.size > maxSize) {
            setIsFileTooBig(true);
            setIsUploading(false);

            return;
          }

          if (
            !['image/jpeg', 'image/jpg', 'image/png', 'image/webp'].includes(
              file.type,
            )
          ) {
            setIsFileInvalidFormat(true);
            setIsUploading(false);

            return;
          }

          const reader = new FileReader();

          reader.onload = function () {
            if (reader.result) {
              onImageUploaded(reader);

              setIsUploading(false);
            }
          };

          reader.readAsDataURL(file);
        }
      }
    },
  };
};
