import Cookie from 'assets/icons/CookieConsent.svg';
import { Link } from 'gatsby';
import React from 'react';
import * as styles from '../../../styles/CookieConsent.styles';
import { Heading } from '../../Heading';
import { Paragraph } from '../../Paragraph';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { motion } from 'framer-motion';
import {
  MOVE_FROM_BOTTOM,
  MOVE_TO_BOTTOM,
  ROTATE_FADE_IN,
  TILT_X,
} from 'style/animations.config';
import { useCookieAccept } from 'modules/app/hooks';

interface Props {
  onAction?: (isAccepted: boolean) => void;
}

export const CookieConsent: React.FC<Props> = ({ onAction }) => {
  const {
    isCookieAccepted,
    wasCookieAccepted,
    onCookieAccept,
    onCookieDecline,
    onNecessaryAccept,
  } = useCookieAccept(onAction);

  if (wasCookieAccepted.current) {
    return null;
  }

  return (
    <motion.div
      {...(isCookieAccepted ? MOVE_TO_BOTTOM : MOVE_FROM_BOTTOM)}
      transition={{
        delay: isCookieAccepted ? 0.15 : 2.1,
        type: 'keyframes',
        ease: 'easeInOut',
        duration: 0.4,
      }}
      id="cookieConsentModal"
      css={styles.root}
    >
      <div css={styles.wrapper}>
        <div>
          <motion.div
            {...ROTATE_FADE_IN}
            transition={{
              delay: 2.5,
              duration: 0.7,
              bounce: 0.4,
              stiffness: 100,
              type: 'spring',
            }}
            css={styles.image}
          >
            <motion.div
              style={{ backfaceVisibility: 'hidden' }}
              {...TILT_X}
              transition={{
                type: 'spring',
                duration: 1,
                repeatDelay: 5,
                bounce: 1,
                delay: 6,
                repeat: Infinity,
                repeatType: 'loop',
              }}
            >
              <Cookie />
            </motion.div>
          </motion.div>
          <div css={styles.contentWrapper}>
            <Heading as="h2" size="fluidXxLarge" css={styles.title}>
              <FormattedMessage id="cookieConsent.title" />
            </Heading>
            <Paragraph size="fluidSmall" css={styles.description}>
              <FormattedMessage id="cookieConsent.description" />{' '}
              <Link css={styles.privacyPolicyLink} to="/en/cookie-policy">
                <FormattedMessage id="cookieConsent.policyLink" />
              </Link>
            </Paragraph>
          </div>
        </div>
        <div css={styles.buttonWrapper}>
          <button onClick={onCookieAccept} css={styles.buttonPrimary}>
            <FormattedMessage id="cookieConsent.acceptAll" />
          </button>

          <button onClick={onNecessaryAccept} css={styles.buttonPrimary}>
            <FormattedMessage id="cookieConsent.onlyNecessary" />
          </button>

          <button onClick={onCookieDecline} css={styles.buttonSecondary}>
            <FormattedMessage id="cookieConsent.decline" />
          </button>
        </div>
      </div>
    </motion.div>
  );
};
