export function normalizeBasicsRequest(data: BasicsFormData): BasicsRequest {
  return {
    title: data.title || null,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone || null,
    dob: `${data.dob.getFullYear()}-${
      data.dob.getMonth() + 1
    }-${data.dob.getDate()}`,
    workplace: data.workplace,
    gender: data.gender || null,
    disability: data.disability || null,
    about: data.about,
    location: null,
    cityCountry: null,
  };
}
