import React from 'react';
import { formatDate } from 'modules/admin/static';
import { Tag } from 'modules/app';
import * as styles from 'modules/admin/styles/AdminOverview.styles';

export const ActivityItem: React.FC<ActivityData> = ({ ...props }) => {
  const styleAction = (action: string) => {
    let color;

    switch (action) {
      case 'create':
      case 'assign':
      case 'enable':
      case 'apply':
        color = styles.tagSuccess;
        break;
      case 'update':
        color = styles.tagWarning;
        break;
      case 'delete':
      case 'soft_delete':
      case 'expire':
      case 'leave':
        color = styles.tagError;
        break;
      default:
        color = styles.tagWarning;
    }

    return (
      <div css={color}>
        <Tag text={action} />
      </div>
    );
  };

  const renderUsers = (
    userName: string,
    adminName: string,
    fullName: string,
    name: string,
  ) => {
    return (
      <>
        <strong>{adminName && <>{adminName}</>}</strong>
        {adminName && ' to '}
        <strong>{userName || fullName}</strong>
        {fullName && ' at '}
        <strong>{name}</strong>
      </>
    );
  };

  return (
    <tr css={styles.tr}>
      <td>
        <div>
          {renderUsers(
            props.userName,
            props.adminName,
            props.fullName,
            props.name,
          )}

          <div css={styles.tdFaded}>
            {props.email ? props.email : props.userEmail}
          </div>
        </div>
      </td>
      <td css={[styles.textSmall, styles.tdFaded]}>
        {props.subject === 'system' ? 'staff' : props.subject}
      </td>
      <td css={[styles.textSmall]}>
        <strong>{formatDate(props.createdAt, 'PP, HH:mm')}</strong>
      </td>
      <td css={styles.textSmall}>{props.objectName}</td>
      <td>{styleAction(props.verb)}</td>
    </tr>
  );
};
