export const companyListHeaders = [
  { id: 'company', title: 'company', width: '300px' },
  {
    id: 'legalRepresentative',
    title: 'legal representative',
    width: '300px',
  },
  { id: 'reg', title: 'registered on', width: '200px' },
  { id: 'plan', title: 'plan', width: '200px' },
  { id: 'actions', title: '', width: '30px' },
];
