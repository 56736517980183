import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { IndicatorProps } from 'react-select';
import * as styles from 'modules/app/styles/Select.styles';
import { Label, Value } from 'modules/app';
import { FormError } from 'modules/app/intl';
import Clear from 'assets/icons/clear.svg';
import CaretDown from 'assets/icons/down.svg';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import AsyncSelect from 'react-select/async';

interface Props {
  name: string;
  defaultValue?: SelectableNameStructure | SelectableNameStructure[];
  options?: SelectableNameStructure[];
  label: React.ReactNode;
  customStyles?: SerializedStyles;
  isOptional?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  bottomText?: React.ReactNode;
  constraints?: RegisterOptions;
  theme?: 'light' | 'dark';
  isMulti?: boolean;
  onSearch: (value: string) => Promise<SelectableNameStructure[] | undefined>;
}

export const AsyncSelectField: React.FC<Props> = ({
  name,
  defaultValue,
  options,
  label,
  customStyles,
  isOptional,
  isDisabled,
  placeholder,
  bottomText,
  constraints,
  theme = 'light',
  isMulti = false,
  onSearch,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const ClearIndicator = (props: IndicatorProps<Value, boolean>) => {
    const {
      innerProps: { ref, ...restInnerProps },
    } = props;

    return (
      <Clear
        {...restInnerProps}
        ref={ref}
        height={16}
        width={16}
        css={margin.right.med}
      />
    );
  };

  const DropdownIndicator = (props: IndicatorProps<Value, boolean>) => {
    const {
      innerProps: { ref, ...restInnerProps },
    } = props;

    return <CaretDown {...restInnerProps} ref={ref} height={7} width={12} />;
  };

  const promiseOptions = async (value: string) => {
    const data = await onSearch(value);

    if (!data) return [];

    return data;
  };

  return (
    <div
      css={[
        styles.root(theme),
        customStyles ? customStyles : undefined,
        errors[name] ? styles.error : undefined,
      ]}
    >
      <Label label={label} isOptional={isOptional} />

      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        rules={constraints}
        render={({ field }) => (
          <AsyncSelect
            {...field}
            placeholder={placeholder}
            isDisabled={isDisabled}
            className="roango-multi-select"
            classNamePrefix="roango-select"
            defaultOptions={options}
            isMulti={isMulti}
            components={{
              ClearIndicator,
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            loadOptions={promiseOptions}
          />
        )}
      />

      {bottomText && <span css={[styles.bottomText]}>{bottomText}</span>}

      <FormError errors={errors} name={name} />
    </div>
  );
};
