import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import { Button, Heading } from 'modules/app';
import { AppState } from 'modules/redux-store';
import React from 'react';
import { useSelector } from 'react-redux';

import * as styles from 'modules/openings/styles/OpeningRestrictionModal.styles';

interface Props {
  setIsOpen: (isOpen: boolean) => void;
  handle?: string;
}

export const OpeningRestrictionModal: React.FC<Props> = ({
  setIsOpen,
  handle,
}) => {
  const { activePlan } = useSelector((state: AppState) => state.billing);

  return (
    <>
      <Heading as="h3" size="fluidXLarge">
        <FormattedMessage id="openings.planLimit.title" />
      </Heading>
      <p css={styles.content}>
        <FormattedMessage
          id="openings.planLimit.content"
          values={{
            plan: activePlan?.planName,
            active: activePlan?.currentActiveOpeningCount,
            allowed: activePlan?.allowedActiveOpeningCount,
          }}
        />
      </p>

      <Link
        to={`/profile/settings/company/${handle}/billing/subscription`}
        css={styles.button}
      >
        <FormattedMessage id="openings.planLimit.btn" />
      </Link>

      <Button
        buttonType="ghost"
        type="button"
        size="medium"
        onClick={() => setIsOpen(false)}
      >
        <FormattedMessage id="shared.cancel" />
      </Button>
    </>
  );
};
