import { css } from '@emotion/react';

export const root = css`
  display: flex;
`;

export const infoIcon = css`
  min-width: 16px;
  margin-top: var(--unit);
  margin-right: calc(var(--unit) * 3);
`;
