import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import { DropdownMenu, useSocialPopup } from '.';
import { useCheckIfImpersonate } from 'modules/admin/static';

import Share from 'assets/icons/share.svg';
import Facebook from 'assets/icons/facebook.svg';
import Twitter from 'assets/icons/twitter.svg';
import LinkedIn from 'assets/icons/linkedin.svg';
import * as buttonStyles from 'modules/app/styles/Button.styles';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils';

interface Props {
  companyHandle: string;
  openingSlug: string;
  theme?: 'light' | 'dark';
  position?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  customButtonStyles?: SerializedStyles;
  isDisabled?: boolean;
}

export const OpeningSocialShare: React.FC<Props> = ({
  companyHandle,
  openingSlug,
  theme = 'light',
  position,
  customButtonStyles,
  isDisabled,
}) => {
  const popupWindow = useSocialPopup();
  const isImpersonate = useCheckIfImpersonate();

  const shareItems = [
    {
      id: 'facebook',
      element: (
        <span>
          <Facebook height={14} width={14} css={margin.right.med} />
          <FormattedMessage id="shared.socialButtons.facebook" />
        </span>
      ),
    },
    {
      id: 'linkedin',
      element: (
        <span>
          <LinkedIn height={14} width={14} css={margin.right.med} />
          <FormattedMessage id="shared.socialButtons.linkedin" />
        </span>
      ),
    },
    {
      id: 'twitter',
      element: (
        <span>
          <Twitter height={14} width={14} css={margin.right.med} />
          <FormattedMessage id="shared.socialButtons.twitter" />
        </span>
      ),
    },
  ];

  const customStyles = (): SerializedStyles | SerializedStyles[] => {
    const btnTheme =
      theme === 'light' ? buttonStyles.svgButton : buttonStyles.svgButtonDark;

    if (customButtonStyles) {
      return [customButtonStyles, btnTheme];
    }

    return btnTheme;
  };

  const onSocialSelect = (id: 'twitter' | 'facebook' | 'linkedin') => {
    const uri = `${location.origin}/public-opening/${companyHandle}/${openingSlug}`;
    popupWindow(id, id === 'linkedin' ? uri : encodeURIComponent(uri));
  };

  return (
    <DropdownMenu
      onSelect={(id) =>
        onSocialSelect(id as 'twitter' | 'facebook' | 'linkedin')
      }
      placeholder={<Share />}
      items={shareItems}
      position={position}
      hideCaret
      disabled={isDisabled || isImpersonate}
      customButtonStyles={customStyles()}
    />
  );
};
