import { createAction } from 'modules/redux-store';

export const adminAction = {
  talents: (talents: AdminResponse<Users>) =>
    createAction('admin/DATA', { talents }),
  users: (users: AdminResponse<Users>) => createAction('admin/DATA', { users }),
  sessions: (sessions: AdminResponse<Sessions>) =>
    createAction('admin/DATA', { sessions }),
  activeSessions: (sessions: Sessions[]) =>
    createAction('admin/ACTIVE_SESSIONS', sessions),
  admin: (admin: AdminResponse<Users>) => createAction('admin/DATA', { admin }),
  support: (support: AdminResponse<Users>) =>
    createAction('admin/DATA', { support }),
  superadmin: (superadmin: AdminResponse<Users>) =>
    createAction('admin/DATA', { superadmin }),
  companies: (companies: AdminResponse<Companies>) =>
    createAction('admin/DATA', { companies }),

  loading: () => createAction('admin/LOADING'),
  error: (payload: ApiErrorData) => createAction('admin/ERROR', payload),
  reset: () => createAction('admin/RESET'),
};

export const searchUsersAction = {
  search: (payload: {
    data?: SearchUsersResponseData[];
    page?: number;
    lastPage?: number;
  }) => createAction('admin/SEARCH_USERS', payload),
  loading: () => createAction('admin/LOADING'),
  error: (payload: ApiErrorData) => createAction('admin/ERROR', payload),
  reset: () => createAction('admin/RESET'),
};

export const searchUsersDataCopyRequestAction = {
  search: (payload: {
    data?: CopyRequestData[];
    page?: number;
    lastPage?: number;
  }) => createAction('admin/SEARCH_DATA_COPY_REQUEST_USERS', payload),
  loading: () => createAction('admin/LOADING'),
  error: (payload: ApiErrorData) => createAction('admin/ERROR', payload),
  reset: () => createAction('admin/RESET'),
};

export const getActivityAction = {
  activity: (payload: AnalyticsActivity) =>
    createAction('admin/GET_ACTIVITY', payload),
  loading: () => createAction('admin/LOADING'),
  error: (payload: ApiErrorData) => createAction('admin/ERROR', payload),
  reset: () => createAction('admin/RESET'),
};

export const impersonateAction = {
  start: (payload: { user?: Session }) =>
    createAction('impersonate/START', payload),
  end: (payload: { user?: undefined }) =>
    createAction('impersonate/END', payload),
  loading: () => createAction('impersonate/LOADING'),
  error: (payload: ApiErrorData) => createAction('impersonate/ERROR', payload),
  reset: () => createAction('impersonate/RESET'),
};
