import { useEffect, useState } from 'react';

function selectExistingScript(src: string): HTMLScriptElement | null {
  return document.querySelector(`script[src="${src}"]`);
}

export const useScript = (
  src: string,
): 'idle' | 'loading' | 'ready' | 'error' => {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? 'loading' : 'idle');

  useEffect(
    () => {
      if (!src) {
        setStatus('idle');

        return;
      }

      let script = selectExistingScript(src);

      if (!script) {
        script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.setAttribute('data-status', 'loading');
        document.body.appendChild(script);
        const setAttributeFromEvent = (event: Event) => {
          if (script) {
            script.setAttribute(
              'data-status',

              event.type === 'load' ? 'ready' : 'error',
            );
          }
        };
        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      } else {
        const attr = script.getAttribute('data-status');

        if (attr) {
          setStatus(attr);
        }
      }

      const setStateFromEvent = (event: Event) => {
        setStatus(event.type === 'load' ? 'ready' : 'error');
      };
      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);

      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent);

          script.removeEventListener('error', setStateFromEvent);
        }
      };
    },

    [src], // Only re-run effect if script src changes
  );

  return status;
};
