import Seo from 'modules/app/components/Seo';
import React from 'react';
import { useSearchParams, useQueryHandler } from '../hooks';
import { useLocation } from '@reach/router';
import * as sectionStyles from '../styles/Section.styles';
import { InvoicesHeader, InvoicesBody } from './components';
import { format, startOfYear, startOfToday } from 'date-fns';
import { useInvoices } from './hooks';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

export const Invoices: React.FC = () => {
  const { search: queryString } = useLocation();
  const queryParams = new URLSearchParams(queryString);

  const firstDayOfTheYear = format(startOfYear(new Date()), 'yyyy-MM-dd');
  const today = format(startOfToday(), 'yyyy-MM-dd');

  const urlParams = {
    urlSearch: queryParams.get('search') ?? '',
    urlSearchBy: queryParams.get('searchBy') ?? 'company_name',
    urlPage: queryParams.get('page') ?? '1',
    urlSort: queryParams.get('sort') ?? 'DESC',
    urlSortBy: queryParams.get('sortBy') ?? 'created_at',
    urlStartDate: queryParams.get('startDate') ?? firstDayOfTheYear,
    urlEndDate: queryParams.get('endDate') ?? today,
    urlHideDeleted: queryParams.get('hideDeleted') ?? 'true',
  };

  const { requestInvoices } = useInvoices();
  const { query, setQuery } = useQueryHandler(
    requestInvoices,
    urlParams,
    queryString,
  );
  const { isLoading } = useSelector((selector: AppState) => selector.invoices);

  const {
    handleSearch,
    handlePageChange,
    handleChangeSortBy,
    handleChangeSort,
    handleStartDateChange,
    handleEndDateChange,
    handleGenerateSearchFilterOptions,
  } = useSearchParams<ExtraSearchQueryData>({ query, setQuery });

  return (
    <>
      <Seo title="Invoices" />
      <div css={sectionStyles.section}>
        <InvoicesHeader
          isLoading={isLoading}
          query={query}
          setQuery={setQuery}
          handleSearch={handleSearch}
          handleChangeSort={handleChangeSort}
          handleChangeSortBy={handleChangeSortBy}
          handleGenerateSearchFilterOptions={handleGenerateSearchFilterOptions}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
        />
        <InvoicesBody query={query} handlePageChange={handlePageChange} />
      </div>
    </>
  );
};
