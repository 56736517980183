import { StatusCodes } from '../const';

export interface HttpError extends ApiErrorData {
  status: StatusCodes;
}

export class HttpError implements ApiErrorData {
  constructor(status: number, data: ApiErrorData) {
    this.status = status;
    this.type = 'error';
    this.code = data.code;
    this.message = data.message;
    this.cause = data.cause;
    this.payload = data.payload;
    this.data = data.data;
    this.errors = data.errors;
  }
}
