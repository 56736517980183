import { ActionUnion } from 'modules/redux-store';

import { talentMessagesActions } from './actions';

interface TalentMessagesInitState {
  isLoading: boolean;
  error?: ApiErrorData;
  conversations?: Conversation[];
  currentConversationId?: string;
  unreadConversations: string[];
  conversationsList: { id: string; applicationId: string }[];
  lastPage: number;
  page: number;
  messages: {
    isLoading: boolean;
    error?: ApiErrorData;
    messages?: Conversation;
  };
}

const INITIAL_STATE: TalentMessagesInitState = {
  isLoading: false,
  lastPage: 1,
  page: 1,
  unreadConversations: [],
  conversationsList: [],
  messages: {
    isLoading: false,
  },
};

export const talentMessagesReducer = (
  state: TalentMessagesInitState = INITIAL_STATE,
  action: ActionUnion<typeof talentMessagesActions>,
): TalentMessagesInitState => {
  switch (action.type) {
    case 'talentMessages/SET_CONVERSATIONS':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        ...action.payload,
      };
    case 'talentMessages/LOADING':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case 'talentMessages/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case 'talentMessages/SET_MESSAGES':
      return {
        ...state,
        messages: {
          isLoading: false,
          error: undefined,
          messages: action.payload,
        },
      };
    case 'talentMessages/MESSAGES_LOADING':
      return {
        ...state,
        messages: {
          isLoading: true,
          error: undefined,
        },
      };
    case 'talentMessages/MESSAGES_ERROR':
      return {
        ...state,
        messages: {
          isLoading: false,
          error: action.payload,
        },
      };

    case 'talentMessages/RESET_CONVERSATIONS':
      return {
        ...state,
        conversations: undefined,
      };

    case 'talentMessages/SET_UNREAD_CONVERSATIONS':
      return {
        ...state,
        unreadConversations: action.payload,
      };
    case 'talentMessages/SET_CONVERSATIONS_LIST':
      return {
        ...state,
        conversationsList: action.payload,
      };

    case 'talentMessages/RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
