import { Link } from 'gatsby-plugin-intl';
import React from 'react';
import LogoWhite from 'assets/icons/logoWhite.svg';
import LogoBlack from 'assets/icons/logoBlack.svg';
import { Heading } from './Heading';
import { SerializedStyles } from '@emotion/react';

interface Props {
  linkTo?: string;
  colorType?: 'black' | 'white';
  css?: SerializedStyles;
}

export const Logo: React.FC<Props> = ({
  linkTo = '/',
  colorType = 'white',
  ...rest
}) => {
  return (
    <Heading as="h1" size="root" {...rest}>
      <Link to={linkTo}>
        {colorType === 'black' ? <LogoBlack /> : <LogoWhite />}
      </Link>
    </Heading>
  );
};
