import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { billingActions } from '..';

interface ResponseData<T> extends SearchPaginatedResponse {
  data: T[];
}

export const useActivities = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const http = useHttpClient('/api/v1/company/');

  async function getActivities(companyId: string, page: number) {
    dispatch(billingActions.loading());

    try {
      const { data } = await http.get<ResponseData<Activity>>(
        `${companyId}/settings/billing-activities`,
        { params: { page } },
      );
      dispatch(
        billingActions.setActivities({
          data: data.data,
          lastPage: data.last_page,
        }),
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(billingActions.error(e));
    }
  }

  async function getInvoices(companyId: string, page: number) {
    dispatch(billingActions.loading());

    try {
      const { data } = await http.get<ResponseData<Invoice>>(
        `${companyId}/settings/invoices`,
        { params: { page } },
      );
      dispatch(
        billingActions.setInvoices({
          data: data.data,
          lastPage: data.last_page,
        }),
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(billingActions.error(e));
    }
  }

  function onPageChange(data: { selected: number }) {
    setPage(data.selected + 1);
  }

  return {
    page,
    getInvoices,
    onPageChange,
    getActivities,
  };
};
