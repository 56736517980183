import { useHttpClient, useToastify } from 'modules/app';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { talentMessagesActions } from '../redux';
import { getAttachmentSize, getSendingMessage } from '../utils';
import { useConversations } from './useConversations';

interface ReplyForm {
  body: string;
  attachments: File[];
}

export const useReply = (messages: Conversation) => {
  const replyHttp = useHttpClient<Conversation>('/api/v1/user/conversations');

  const dispatch = useDispatch();
  const { toastError } = useToastify();
  const { getMessages } = useConversations();

  const methods = useForm<ReplyForm>({
    defaultValues: {
      body: '',
      attachments: [],
    },
  });

  const { reset, setError } = methods;

  function getFormData(data: ReplyForm) {
    const formData = new FormData();
    formData.append('body', data.body);

    if (Boolean(data.attachments.length)) {
      data.attachments.forEach((attachment) =>
        formData.append(`attachments`, attachment, attachment.name),
      );
    }

    return formData;
  }

  async function reply(data: ReplyForm) {
    const message = messages.conversationMessages;
    const sending = getSendingMessage(data, messages);
    const isLoading = Boolean(message?.find((msg) => msg.isLoading));

    if (!sending || isLoading) return;

    const size = getAttachmentSize(data.attachments);

    if (size > 10) {
      setError('attachments', {
        message: 'talentMessages.sizeError',
      });
      return;
    }

    const newMessages: Conversation = {
      ...messages,
      conversationMessages: [...message, sending],
    };

    reset();
    dispatch(talentMessagesActions.setMessages(newMessages));

    try {
      await replyHttp.post<Conversation, FormData>(
        `/${messages.id}/reply`,
        getFormData(data),
      );
      getMessages(messages.id, false);
    } catch (e) {
      toastError();
      dispatch(talentMessagesActions.setMessages(messages));
    }
  }

  return { methods, reply };
};
