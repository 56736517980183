import React from 'react';
import { SectionHead } from 'modules/admin/components';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { Button, DropdownMenu, SmallLoadingImg, TextInput } from 'modules/app';
import Search from 'assets/icons/search.svg';
import CalendarIcon from 'assets/icons/calendar.svg';
import { motion } from 'framer-motion';
import * as styles from '../styles/InvoicesHeader.styles';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { format, startOfYear, startOfToday } from 'date-fns';

const DatePickerButton = React.lazy(
  () => import('modules/app/components/form/DatePickerButton'),
);

interface Props {
  isLoading?: boolean;
  query: ExtraSearchQueryData;
  setQuery: React.Dispatch<React.SetStateAction<ExtraSearchQueryData>>;
  handleSearch: (
    e: React.KeyboardEvent<HTMLInputElement> &
      React.ChangeEvent<HTMLInputElement>,
  ) => void;
  handleChangeSort: (option: string) => void;
  handleChangeSortBy: (option: string) => void;
  handleGenerateSearchFilterOptions: (
    options: string[],
    activeFilter: string,
  ) => {
    id: string;
    element: EmotionJSX.Element;
  }[];
  handleStartDateChange: (date: Date) => void;
  handleEndDateChange: (date: Date) => void;
}

export const InvoicesHeader: React.FC<Props> = ({
  isLoading,
  query,
  setQuery,
  handleSearch,
  handleChangeSort,
  handleChangeSortBy,
  handleGenerateSearchFilterOptions,
  handleStartDateChange,
  handleEndDateChange,
}) => {
  const sortByOptions = ['company_name', 'created_at'];
  const sortOptions = ['DESC', 'ASC'];
  const firstDayOfTheYear = format(startOfYear(new Date()), 'yyyy-MM-dd');
  const today = format(startOfToday(), 'yyyy-MM-dd');
  const { formatMessage } = useIntl();

  const onResetFilter = () => {
    setQuery({
      page: 1,
      sort: 'DESC',
      sortBy: 'created_at',
      search: '',
      searchBy: 'company_name',
      startDate: firstDayOfTheYear,
      endDate: today,
      input: '',
    });
  };

  return (
    <motion.div transition={{ duration: 0.3 }} {...FADE_IN_MOVE_Y_REGULAR}>
      <SectionHead title="Invoices">
        <TextInput
          autoFocus
          value={query.input}
          icon={isLoading ? <SmallLoadingImg /> : <Search />}
          css={styles.search}
          placeholder={formatMessage({
            id: 'admin.invoices.invoicesHeader.input.placeholder',
          })}
          onChange={(e) => setQuery({ ...query, input: e.target.value })}
          onKeyUp={(
            e: React.KeyboardEvent<HTMLInputElement> &
              React.ChangeEvent<HTMLInputElement>,
          ) => handleSearch(e)}
        />

        <div css={styles.searchFilter}>
          <DropdownMenu
            onSelect={(id) => {
              handleChangeSortBy(id);
            }}
            placeholder={
              <div>
                Sort by <strong>{query.sortBy}</strong>
              </div>
            }
            hideCaret={false}
            items={handleGenerateSearchFilterOptions(
              sortByOptions,
              query.sortBy,
            )}
            position="bottomRight"
            customButtonStyles={styles.sortByDropdown}
          />

          <DropdownMenu
            onSelect={(id) => {
              handleChangeSort(id);
            }}
            placeholder={
              <div>
                Sort by <strong>{query.sort}</strong>
              </div>
            }
            hideCaret={false}
            items={handleGenerateSearchFilterOptions(sortOptions, query.sort)}
            position="bottomRight"
            customButtonStyles={styles.sortByDropdown}
          />
          <Button
            buttonType={'outline'}
            size={'tiny'}
            onClick={onResetFilter}
            disabled={isLoading}
          >
            Reset filter
          </Button>
        </div>
        <React.Suspense fallback={null}>
          <div css={styles.datePickers}>
            <DatePickerButton
              label={
                <FormattedMessage id="admin.users.copy-profile-data.startDate" />
              }
              isDisabled={false}
              dateString={query.startDate}
              maxDate={startOfToday()}
              buttonIcon={<CalendarIcon />}
              theme={'light'}
              onSelect={handleStartDateChange}
              showTime={false}
            />
            <DatePickerButton
              label={
                <FormattedMessage id="admin.users.copy-profile-data.endDate" />
              }
              isDisabled={false}
              dateString={query.endDate}
              buttonIcon={<CalendarIcon />}
              theme={'light'}
              onSelect={handleEndDateChange}
              showTime={false}
            />
          </div>
        </React.Suspense>
      </SectionHead>
    </motion.div>
  );
};
