import { css } from '@emotion/react';

export const wrapper = css`
  width: 100%;
  min-height: 100vh;
  background-color: hsla(var(--color-text-1), 60%);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const root = css`
  background-color: hsl(var(--color-textLight-10));
  margin: 0 auto;
  padding: calc(var(--unit) * 8);
`;

export const content = css`
  margin: 0 auto;
  backface-visibility: hidden;
`;
