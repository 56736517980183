import { useCallback, useEffect, useState } from 'react';
import { DeepMap, DeepPartial, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { globalHistory, useLocation, navigate } from '@reach/router';

export function useMoreFormsGuard<Model>() {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const [forms, setForms] = useState<UseFormReturn<Model, unknown>[]>([]);
  const [areFormsSubmitted, setAreFormsSubmitted] = useState<boolean[]>([]);
  const [formDirtyFields, setFormDirtyFields] =
    useState<DeepMap<DeepPartial<Model>, boolean>>();

  const isGuardSkip =
    pathname.includes('onboarding') ||
    !formDirtyFields ||
    !Object.keys(formDirtyFields).length ||
    areFormsSubmitted.filter((value) => value).length === forms.length;

  const handleAddFormDirtyFields = (
    dirtyField: DeepMap<DeepPartial<Model>, boolean>,
    isSubmit: boolean,
  ) => {
    setAreFormsSubmitted((prev) => [...prev, isSubmit]);
    setFormDirtyFields(dirtyField);
  };

  const handleAddForm = (form: UseFormReturn<Model, unknown>) => {
    setForms((prev) => [...prev, form]);
  };

  const handleGuardedRouteCheck = useCallback(() => {
    const formGuardMessage = window.confirm(
      formatMessage({ id: 'confirm-navigation' }),
    );

    if (!formGuardMessage) navigate(pathname);
  }, []);

  useEffect(() => {
    if (isGuardSkip) return;

    return globalHistory.listen(({ location }) => {
      if (location.pathname !== pathname) handleGuardedRouteCheck();
    });
  }, [formDirtyFields, handleGuardedRouteCheck, pathname]);

  return { handleAddFormDirtyFields, handleAddForm };
}
