import React from 'react';
import * as styles from 'modules/admin/styles/AdminOverview.styles';
import { UsersItem } from 'modules/admin/components';
import { Skeleton } from 'modules/app';

interface Props {
  isLoading: boolean;
  users: SearchUsersResponseData[];
  query: ManageUsersDataQuery;
  onDelete: (id: string) => void;
  onDeactivate: (id: string) => void;
  onImpersonate: (userId: string) => void;
}

export const UsersManageTable: React.FC<Props> = ({
  isLoading,
  users,
  query,
  onDelete,
  onImpersonate,
  onDeactivate,
}) => {
  return (
    <table>
      <thead css={styles.tableHead}>
        <tr>
          <td width="400">Users</td>
          <td width="200">Registered</td>
          <td width="150">E-mail Ver.</td>
          <td width="200">Related to</td>
          <td width="150">Newsletter</td>
          <td width="120">2FA</td>
        </tr>
      </thead>

      {isLoading ? (
        <td colSpan={6}>
          <Skeleton count={4} customStyles={styles.skeleton} />
        </td>
      ) : (
        <tbody>
          {Boolean(users.length) ? (
            users.map((user, key: number) => (
              <UsersItem
                onDelete={onDelete}
                onDeactivate={onDeactivate}
                onImpersonate={onImpersonate}
                key={key}
                {...user}
              />
            ))
          ) : (
            <tr>
              <td>
                {Boolean(query.search) ? (
                  <span>
                    No results found for <strong>"{query.search}"</strong>
                  </span>
                ) : (
                  <span>No results found</span>
                )}
              </td>
            </tr>
          )}
        </tbody>
      )}
    </table>
  );
};
