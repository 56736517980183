import React from 'react';

import EmailIcon from 'assets/icons/noNewMessages.svg';
import { Heading } from 'modules/app';
import { FormattedMessage } from 'gatsby-plugin-intl';

import * as styles from 'modules/app/styles/NoNewMessages.styles';

interface Props {
  type?: 'talent' | 'company';
}

export const NoNewMessages: React.FC<Props> = ({ type = 'talent' }) => {
  return (
    <div css={styles.root}>
      <EmailIcon />

      <Heading as="h2" size="fluidXxLarge" css={styles.title}>
        <FormattedMessage id="applications.messaging.noMessages.heading" />
      </Heading>

      <p css={styles.content}>
        <FormattedMessage
          id={
            type === 'talent'
              ? 'applications.messaging.noMessages.paragraph'
              : 'applications.messaging.noMessages.companyParagraph'
          }
        />
      </p>
    </div>
  );
};
