import CheckedIcon from 'assets/icons/check.svg';
import React, { InputHTMLAttributes, useState } from 'react';
import * as styles from 'modules/app/styles/CheckboxField.styles';

interface Props extends InputHTMLAttributes<Omit<HTMLInputElement, 'type'>> {
  name: string;
  checked: boolean;
  customStyles?: SerializedStyles | SerializedStyles[];
  onChecked?: (isChecked: boolean) => void;
  isSmall?: boolean;
}

export const Checkbox: React.FC<Props> = ({
  children,
  name,
  checked,
  customStyles,
  onChecked,
  isSmall,
  ...rest
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  return (
    <div css={[styles.root, customStyles]}>
      <input
        {...rest}
        css={styles.input}
        checked={isChecked}
        id={`checkbox-${name}`}
        type="checkbox"
        name={name}
        onChange={() => {
          onChecked?.(!isChecked);
          setIsChecked(!isChecked);
        }}
      />
      <label css={styles.label} htmlFor={`checkbox-${name}`}>
        {isSmall ? (
          <span
            css={isChecked ? styles.controlCheckedSmall : styles.controlSmall}
          >
            {isChecked && <CheckedIcon />}
          </span>
        ) : (
          <span css={isChecked ? styles.controlChecked : styles.control}>
            {isChecked && <CheckedIcon />}
          </span>
        )}

        {children && (
          <span css={isSmall ? styles.contentSmall : styles.content}>
            {children}
          </span>
        )}
      </label>
    </div>
  );
};
