import React from 'react';
import * as styles from 'modules/app/styles/TextInput.styles';
import * as inputStyles from 'modules/openings/styles/InputTitle.styles';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { LabelTag } from 'modules/app';
import { flex } from '@prototyp/gatsby-plugin-gumball/utils';
import { useIntl } from 'gatsby-plugin-intl';

interface Props {
  name: string;
  placeholder?: string;
  constraints?: RegisterOptions;
}

export const InputTitle: React.FC<Props> = ({
  name,
  placeholder,
  constraints,
}) => {
  const intl = useIntl();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div css={[inputStyles.root, flex.display.flex, flex.justify.between]}>
      <input
        type="text"
        {...register(name, constraints)}
        css={errors[name] ? styles.inputTitleError : styles.inputTitle}
        placeholder={intl.formatMessage({ id: placeholder })}
        maxLength={constraints?.maxLength as number}
      />

      {constraints?.maxLength && (
        <LabelTag size="tiny" fontFamily="primary">
          {constraints.maxLength} characters max
        </LabelTag>
      )}
    </div>
  );
};
