import { getActivityAction } from 'modules/admin/redux';
import { useDispatch } from 'react-redux';
import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { normalizeActivityParams } from '../helpers';

export interface GetActivity {
  page: number;
  perPage?: number;
  verb?: string;
  subject?: 'user' | 'system';
  search?: string;
  searchBy?: string;
  startDate?: string;
  endDate?: string;
  adminRole?: 'Admin' | 'Support';
}

export const useGetActivity = () => {
  const dispatch = useDispatch();

  const adminLogsService = useHttpClient<AnalyticsActivity>('/admin');

  async function getActivity(
    params: GetActivity,
    activityFor: 'users' | 'company',
  ) {
    dispatch(getActivityAction.loading());

    try {
      const { data } = await adminLogsService.get(`/${activityFor}/logs`, {
        params: normalizeActivityParams(params),
      });
      dispatch(getActivityAction.activity(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(getActivityAction.error(e));
    }
  }

  return {
    getActivity,
  };
};
