import { ActionModal, Button, CheckboxField, TextArea } from 'modules/app';
import React from 'react';
import * as styles from 'modules/settings/styles/Companies.styles';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useFreezeUser } from '../hooks';

interface Props {
  userId: string;
  isOpen: boolean;
  handleModalClose: VoidFunction;
}

interface FormData {
  freezeMessage: string;
  freezeTermsAccepted: boolean;
}

export const UsersFreezeModal: React.FC<Props> = ({
  userId,
  isOpen,
  handleModalClose,
}) => {
  const { freezeUser, isLoading, isChecked, handleCheckbox } = useFreezeUser();
  const form = useForm<FormData>({
    defaultValues: {
      freezeMessage: '',
      freezeTermsAccepted: false,
    },
  });

  function handleSubmit(data: FormData) {
    if (!isChecked) return;

    freezeUser({
      userId,
      data,
      callback: handleModalClose,
    });
  }

  return (
    <ActionModal isOpen={isOpen} setIsOpen={handleModalClose}>
      <h3 css={styles.modalTitle}>
        <FormattedMessage id={`admin.users.freezeModal.title`} />
      </h3>
      <div css={styles.modal}>
        <p css={styles.modalContent}>
          <FormattedMessage id="admin.users.freezeModal.text" />
        </p>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <TextArea
              name="freezeMessage"
              placeholder={`admin.users.freezeModal.textAreaPlaceholder`}
              value=""
              topLabel={`admin.users.freezeModal.textAreaTopLabel`}
              isOptional={false}
              maxChar="1000"
              constraints={{
                maxLength: 1000,
              }}
              customStyles={margin.bottom.xxxlrg}
            />

            <CheckboxField
              name="freezeTermsAccepted"
              checked={isChecked}
              constraints={{ required: true }}
              onChecked={handleCheckbox}
            >
              <FormattedMessage id="admin.users.freezeModal.checkbox" />
            </CheckboxField>
            <div css={styles.modalButtons}>
              <Button
                buttonType="primary"
                size="small"
                type="submit"
                css={styles.modalDeactivateBtn}
                disabled={!isChecked || !form.watch('freezeMessage').length}
                isLoading={isLoading}
              >
                <FormattedMessage id="admin.users.freezeModal.buttonConfirm" />
              </Button>

              <Button
                buttonType="ghost"
                size="small"
                type="button"
                onClick={handleModalClose}
              >
                <FormattedMessage id={`admin.users.freezeModal.buttonCancel`} />
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </ActionModal>
  );
};
