import { useIntl } from 'gatsby-plugin-intl';
import { useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { billingActions } from '../redux';

export const usePlans = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const http = useHttpClient('/api/v1/company/');

  const [currentCurrency, setCurrentCurrency] = useState('EUR');

  const currencySelect = [
    {
      value: 'EUR',
      label: formatMessage({
        id: 'settings.billingContent.subscriptionContent.europe',
      }),
    },
    {
      value: 'USD',
      label: formatMessage({
        id: 'settings.billingContent.subscriptionContent.northAmerica',
      }),
    },
    {
      value: 'Other',
      label: formatMessage({
        id: 'settings.billingContent.subscriptionContent.other',
      }),
    },
  ];

  async function getPlans(companyId: string, currency?: string) {
    dispatch(billingActions.loading());

    try {
      const { data } = await http.get<Plan[]>(
        `${companyId}/settings/company-subscription-plans`,
        { params: { currency } },
      );
      dispatch(billingActions.setPlans(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(billingActions.error(e));
    }
  }

  function onCurrencyChange(value: string | string[]) {
    if (Array.isArray(value)) return;

    setCurrentCurrency(value);
  }

  return {
    currencySelect,
    currentCurrency,
    getPlans,
    onCurrencyChange,
  };
};
