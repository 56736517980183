import { motion } from 'framer-motion';
import React from 'react';

import * as styles from '../../styles/Navbar.styles';

interface Props {
  onClick: VoidFunction;
  isToggled: boolean;
  negative?: boolean;
}

export const NavbarIcon: React.FC<Props> = ({
  onClick,
  isToggled,
  negative,
}) => {
  const circleVariant = {
    open: { pathLength: 1 },
    closed: { pathLength: 0 },
  };

  const style = () => {
    if (isToggled && negative) return styles.navbarIconDarkActive;

    if (negative) return styles.navbarIconDark;

    if (isToggled) return styles.navbarIconActive;

    return styles.navbarIcon;
  };

  return (
    <div onClick={onClick} css={styles.navbarBtn}>
      <motion.svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.circle
          cx="16"
          cy="16"
          r="15.5"
          variants={circleVariant}
          initial="closed"
          animate={isToggled ? 'open' : 'closed'}
          transition={{ duration: 0.6, ease: 'easeOut' }}
          stroke={negative ? '#FFFFFF' : '#292E32'}
        />
      </motion.svg>
      <div css={style()} />
    </div>
  );
};
