import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { useIntl } from 'gatsby-plugin-intl';

import * as styles from '../textEditor/styles/Root.styles';
import { Descendant } from 'slate';
import { addLengthConstraints } from 'modules/app/components/textEditor/helpers';
import { TextEditorLabel } from '../textEditor/components/TextEditorLabel';
import { TextEditorErrors } from '../textEditor/components/TextEditorErrors';

const RichTextWithMentions = React.lazy(
  () =>
    import('modules/app/components/textEditor/components/RichTextWithMentions'),
);

interface Props {
  label?: string;
  name: string;
  placeholder: string;
  constraints: RegisterOptions;
  maxChar?: number;
  minChar?: number;
  optional?: boolean;
  defaultValue?: Descendant[];
  teamMembers?: SlateMemberType[];
  onAddMention: (id: string) => void;
}

export const TextEditorWithMentions: React.FC<Props> = ({
  label,
  name,
  placeholder,
  constraints,
  maxChar,
  defaultValue,
  minChar,
  optional = false,
  teamMembers,
  onAddMention,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const intl = useIntl();

  if (constraints.maxLength || constraints.minLength) {
    addLengthConstraints(constraints);
  }

  return (
    <div css={styles.root}>
      {label && (
        <TextEditorLabel
          name={name}
          label={label}
          maxChar={maxChar}
          optional={optional}
        />
      )}

      <div css={errors[name] ? styles.editorError : styles.editor}>
        <Controller
          control={control}
          name={name}
          rules={constraints}
          defaultValue={defaultValue}
          render={({ field: { onChange } }) => {
            return (
              <React.Suspense fallback={null}>
                <RichTextWithMentions
                  placeholder={intl.formatMessage({ id: placeholder })}
                  value={defaultValue}
                  teamMembers={teamMembers}
                  onAddMention={onAddMention}
                  onChange={(e) => {
                    onChange(JSON.stringify(e));

                    if (
                      JSON.stringify(e) ===
                      '[{"type":"paragraph","children":[{"text":""}]}]'
                    ) {
                      setValue(name, null);
                    }
                  }}
                />
              </React.Suspense>
            );
          }}
        />
      </div>

      {errors[name] && (
        <TextEditorErrors name={name} minChar={minChar} maxChar={maxChar} />
      )}
    </div>
  );
};
