import { ActionUnion } from 'modules/redux-store';

import { registrationActions } from './actions';

interface RegistrationReducerState {
  email: string;
  password: string;
  name: string;
  lastName: string;
  dob: string;
  isTosAccepted: boolean;
  isProcessingPrivacyPolicyAccepted: boolean;
  otpSecret: string | null;
  otpToken: string | null;
  isSocialRegistrationProcess: boolean;
  registrationCompleted: boolean;
  companyInviteToken?: string;
  companyClaimToken?: string;
}

const INITIAL_STATE: RegistrationReducerState = {
  email: '',
  password: '',
  name: '',
  lastName: '',
  dob: '',
  isTosAccepted: false,
  isProcessingPrivacyPolicyAccepted: false,
  otpSecret: null,
  otpToken: null,
  isSocialRegistrationProcess: false,
  registrationCompleted: false,
  companyInviteToken: undefined,
};

export const registerReducer = (
  state: RegistrationReducerState = INITIAL_STATE,
  action: ActionUnion<typeof registrationActions>,
): RegistrationReducerState => {
  switch (action.type) {
    case 'registration/ADD_STEP_ONE':
      return {
        ...state,
        email: action.payload.email,
        password: action.payload.password,
      };
    case 'registration/ADD_STEP_TWO':
      return {
        ...state,
        isTosAccepted: action.payload.isTosAccepted,
        isProcessingPrivacyPolicyAccepted:
          action.payload.isProcessingPrivacyPolicyAccepted,
      };
    case 'registration/ADD_STEP_THREE':
      return {
        ...state,
        name: action.payload.name,
        lastName: action.payload.lastName,
        dob: action.payload.dob,
      };
    case 'registration/ADD_STEP_FOUR':
      return {
        ...state,
        ...action.payload,
      };
    case 'registration/COMPLETE_REGISTRATION':
      return {
        ...state,
        name: '',
        lastName: '',
        password: '',
        dob: '',
        otpToken: null,
        otpSecret: null,
        isTosAccepted: false,
        isProcessingPrivacyPolicyAccepted: false,
        isSocialRegistrationProcess: false,
        registrationCompleted: true,
      };
    case 'registration/START_SOCIAL_REGISTRATION_PROCESS':
      return {
        ...state,
        isSocialRegistrationProcess: true,
      };
    case 'registration/ABORT_SOCIAL_REGISTRATION_PROCESS':
      return {
        ...state,
        isSocialRegistrationProcess: false,
      };
    case 'registration/COMPANY_INVITE_TOKEN':
      return {
        ...state,
        companyInviteToken: action.payload.companyInviteToken,
      };
    case 'registration/COMPANY_CLAIM_TOKEN':
      return {
        ...state,
        companyClaimToken: action.payload.companyClaimToken,
      };
    case 'registration/RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
