import { AppState } from 'modules/redux-store';

const getTalentDashboard = (state: AppState) => state.talentDashboard;

const getActiveApplication =
  (opening: PublicOpening | Opening) => (state: AppState) => {
    const { activeApplications } = getTalentDashboard(state);
    return activeApplications?.find(
      (application) => application.companyOpeningSlug === opening.slug,
    );
  };

const getActiveInvitation =
  (opening: PublicOpening | Opening) => (state: AppState) => {
    const { activeInvitations } = getTalentDashboard(state);
    return activeInvitations?.find(
      (invitation) => invitation.companyOpeningSlug === opening.slug,
    );
  };

export const talentDashboardSelector = {
  getTalentDashboard,
  getActiveApplication,
  getActiveInvitation,
};
