import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { useCallback, useState } from 'react';
import { Button } from '.';

interface Props {
  buttonType?: ButtonType;
  size?: ButtonSize;
  customStyles?: SerializedStyles;
  onLoadMore: (page: number) => void;
  isLoading?: boolean;
}

export const LoadMorePagination: React.FC<Props> = ({
  customStyles,
  buttonType = 'outline',
  size = 'mediumExtended',
  onLoadMore,
  isLoading,
}) => {
  const [page, setPage] = useState(2);

  const onClick = useCallback(() => {
    onLoadMore(page);
    setPage(page + 1);
  }, [page, onLoadMore]);

  return (
    <div css={[customStyles]}>
      <Button
        size={size}
        buttonType={buttonType}
        onClick={onClick}
        isLoading={isLoading}
      >
        <FormattedMessage id="shared.page.load_more" />
      </Button>
    </div>
  );
};
