import { RouteComponentProps, useLocation } from '@reach/router';
import { HeaderLayout, Logo } from 'modules/app';
import { AppState } from 'modules/redux-store';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navbar } from '../shared/Navbar';
import { CompanyProfileBar } from './CompanyProfileBar';

import * as styles from './styles/ProfileBar.styles';
import { TalentProfileBar } from './TalentProfileBar';

interface Props extends RouteComponentProps {
  path?: string;
}

export const ProfileBar: React.FC<Props> = () => {
  const { companyData } = useSelector((state: AppState) => state.companyData);
  const { user } = useSelector((state: AppState) => state.session);
  const { pathname } = useLocation();
  const isCompany = pathname.includes('/profile/company') && companyData;

  if (!user) {
    return <Navbar />;
  }

  return (
    <HeaderLayout>
      <div
        css={
          pathname.includes('public-opening')
            ? styles.rootNoBorder
            : styles.root
        }
      >
        <div css={styles.logo}>
          <Logo />
        </div>

        {isCompany ? (
          <CompanyProfileBar companyData={companyData} />
        ) : (
          <TalentProfileBar />
        )}
      </div>
    </HeaderLayout>
  );
};
