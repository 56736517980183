import { css } from '@emotion/react';
import { color } from '@prototyp/gatsby-plugin-gumball/mixins';

export const item = (theme = 'dark') => css`
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-med);

  color: ${theme === 'dark' ? color('text', 1) : color('text', 6)};
`;

export const icon = css`
  width: 3rem;
  height: 3rem;
`;

export const title = css``;
