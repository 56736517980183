import { useToastify, useHttpClient } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useDispatch } from 'react-redux';
import { invoicesActions } from '../redux/actions';

interface CancelRefundRequest extends InvoiceCancelRefundData {
  invoiceId: string;
  companyId: string;
}

export function useInvoices() {
  const invoicesService = useHttpClient<InvoiceResponse>('/admin');

  const dispatch = useDispatch();
  const { toastSuccess } = useToastify();

  async function requestInvoices(query: ExtraSearchQueryData) {
    dispatch(invoicesActions.loading());
    try {
      const {
        data: { data, lastPage },
      } = await invoicesService.get<SearchResponse<InvoiceResponse>>(
        '/invoices',
        {
          params: { ...query, perPage: 10 },
        },
      );
      dispatch(invoicesActions.setInvoices({ invoices: data, lastPage }));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(invoicesActions.error(e));
    }
  }

  async function refundWithFiscalization(
    data: CancelRefundRequest,
    callback: VoidFunction,
  ) {
    const { invoiceId, companyId, ...rest } = data;
    try {
      await invoicesService.post(
        `/company/${companyId}/invoices/${invoiceId}/refund-with-fiscal`,
        rest,
      );
      toastSuccess('shared.pills.successDefaultText');
      dispatch(invoicesActions.error());
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(invoicesActions.error(e));
    }
  }

  async function refundWithoutFiscalization(
    data: CancelRefundRequest,
    callback: VoidFunction,
  ) {
    const { invoiceId, companyId, ...rest } = data;
    try {
      await invoicesService.post(
        `/company/${companyId}/invoices/${invoiceId}/refund-no-fiscal`,
        rest,
      );
      toastSuccess('shared.pills.successDefaultText');
      dispatch(invoicesActions.error());
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(invoicesActions.error(e));
    }
  }

  async function cancelInvoice(
    data: CancelRefundRequest,
    callback: VoidFunction,
  ) {
    const { invoiceId, companyId, ...rest } = data;
    try {
      await invoicesService.post(
        `/company/${companyId}/invoices/${invoiceId}/cancel-invoice`,
        rest,
      );
      toastSuccess('shared.pills.successDefaultText');
      dispatch(invoicesActions.error());
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(invoicesActions.error(e));
    }
  }

  return {
    requestInvoices,
    refundWithFiscalization,
    refundWithoutFiscalization,
    cancelInvoice,
  };
}
