import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';

export const root = css`
  list-style-type: '—';
  margin-bottom: ${spacing(32)};
  padding: 0 0 0 ${spacing(18)};

  & > li {
    padding: 0 0 ${spacing(8)} ${spacing(18)};
  }
`;
