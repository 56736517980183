import React from 'react';
import * as contentStyles from 'modules/notFound/styles/Root.styles';
import { MainContent } from 'modules/app';
import { RouteComponentProps } from '@reach/router';
import { ForbiddenContent } from './components/ForbiddenContent';

interface Props extends RouteComponentProps {
  type?: 'contentOnly' | 'contentOnlyCenter' | 'dashboardFull' | 'page';
  additionalStyles?:
    | (keyof typeof contentStyles)[]
    | keyof typeof contentStyles;
}

export const Forbidden: React.FC<Props> = ({ additionalStyles }) => {
  const getStyles = (style: SerializedStyles) => {
    const customStyles = additionalStyles
      ? Array.isArray(additionalStyles)
        ? additionalStyles.map((label) => contentStyles[label])
        : [contentStyles[additionalStyles]]
      : undefined;

    return customStyles ? [style, ...customStyles] : style;
  };

  return (
    <MainContent customStyles={getStyles(contentStyles.wrapper)}>
      <ForbiddenContent />
    </MainContent>
  );
};
