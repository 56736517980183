import React from 'react';
import { RouteComponentProps } from '@reach/router';

import * as sectionStyles from 'modules/admin/styles/Section.styles';
import * as styles from 'modules/admin/styles/Manage.styles';
import { Sidebar } from './Sidebar';
import { Content } from 'modules/admin/components';
import { motion } from 'framer-motion';
import queryString from 'query-string';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';

export const Overview: React.FC<RouteComponentProps> = () => {
  const url = queryString.parse(location.search);
  const page = url.page ? parseInt(url.page as string) : 1;
  const urlDataType = url.user ? (url.user as string) : 'admin';
  return (
    <motion.section {...FADE_IN_MOVE_Y_REGULAR}>
      <div css={[sectionStyles.section, styles.grid]}>
        <Sidebar />
        {urlDataType && (
          <Content urlDataType={urlDataType} currentPage={page} />
        )}
      </div>
    </motion.section>
  );
};
