import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { HttpError } from 'modules/app';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';

import * as styles from 'modules/talentMessages/styles/Messages.styles';
import { MessageList } from './MessageList';
import { SendReply } from './SendReply';
import { useConversations } from '../hooks';
import { MessagesLoading } from './MessagesLoading';
import { isOpeningActive } from '../utils';
import { FormattedMessage } from 'gatsby-plugin-intl';

interface Props {
  isConversationsLoading: boolean;
}

export const Messages: React.FC<Props> = ({ isConversationsLoading }) => {
  const { messages, isLoading, error } = useSelector(
    (state: AppState) => state.talentMessages.messages,
  );

  const { conversationId, getMessages } = useConversations();

  const isActive = isOpeningActive(messages);

  useEffect(() => {
    if (!conversationId) return;

    getMessages(conversationId);
  }, [conversationId]);

  if (isLoading || isConversationsLoading) {
    return <MessagesLoading />;
  }

  if (!error && messages) {
    return (
      <motion.div
        {...FADE_IN_MOVE_Y_REGULAR}
        transition={{ duration: 0.2 }}
        css={styles.root}
      >
        {!isActive && (
          <p css={styles.info}>
            <FormattedMessage id="talentMessages.openingInactive" />
          </p>
        )}
        <MessageList messages={messages} />
        {isActive && <SendReply messages={messages} />}
      </motion.div>
    );
  }

  return <HttpError error={error} />;
};
