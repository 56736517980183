import { useNormalizedPermissions } from 'modules/app';
import { useState } from 'react';
import { add, format, startOfTomorrow, startOfToday, isToday } from 'date-fns';
import { navigate, useIntl } from 'gatsby-plugin-intl';
import { usePlanRestrictions } from './usePlanRestrictions';

export const useOpeningActions = (data: CompanyOpening) => {
  const hasPermission = useNormalizedPermissions();
  const { formatMessage } = useIntl();
  const [isModal, setIsModal] = useState(false);
  const [isLimitModal, setIsLimitModal] = useState(false);
  const [isDeactivateModal, setIsDeactivateModal] = useState(false);
  const { isPlanLimit, isDeactivateLimit } = usePlanRestrictions();

  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    data.status.includes('scheduled')
      ? data.status
      : 'scheduled-for-publishing-(internal)',
  );

  const today = startOfToday();
  const tomorrow = startOfTomorrow();
  const minClosingDate =
    data.publishedAt && !isToday(new Date(data.publishedAt))
      ? add(new Date(data.publishedAt), { days: 1 })
      : tomorrow;

  const isSocialShare = Boolean(
    (data.status === 'published-public' || data.status === 'closed') &&
      hasPermission('publishShareOpening', data.companyHandle),
  );

  const scheduleStatusItems = [
    {
      value: 'scheduled-for-publishing-(internal)',
      label: formatMessage({ id: 'openings.overview.internal' }),
    },
    {
      value: 'scheduled-for-publishing-(public)',
      label: formatMessage({ id: 'openings.overview.public' }),
    },
  ];

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) {
      return '-';
    }

    return format(new Date(dateString + 'Z'), "do MMMM yyyy 'at' h:mm aaa");
  };

  const formatDateForPublishedDropdown = (dateString: string | undefined) => {
    if (!dateString) return;
    return format(new Date(dateString + 'Z'), "yyyy-MM-dd'T'HH:mm:ss");
  };

  const onActionSelect = (
    id: string,
    onArchive: (compaynId: string, openingId: string) => void,
  ) => {
    switch (id) {
      case 'edit':
        return navigate(
          `/profile/company/openings/${data.companyId}/opening/edit/${data.id}`,
        );
      case 'duplicate':
        if (isPlanLimit) return setIsLimitModal(true);
        if (isDeactivateLimit) return setIsDeactivateModal(true);
        return navigate(
          `/profile/company/openings/${data.companyId}/opening/duplicate/${data.id}`,
        );
      case 'archive':
        return onArchive(data.companyId, data.id);
      case 'delete':
        return setIsModal(true);
      default:
        return;
    }
  };

  return {
    selectedValue,
    setSelectedValue,
    today,
    tomorrow,
    minClosingDate,
    isSocialShare,
    scheduleStatusItems,
    formatDate,
    formatDateForPublishedDropdown,
    onActionSelect,
    isModal,
    setIsModal,
    isLimitModal,
    setIsLimitModal,
    isDeactivateModal,
    setIsDeactivateModal,
  };
};
