import { adminAction } from 'modules/admin/redux';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { checkSessionStatus } from '../static';
import { AppState } from 'modules/redux-store';

type Attributes = {
  page?: number;
  perPage?: number;
  sortBy?: string;
  sort?: string;
  search?: string;
  searchBy?: string;
  authType?: string | null;
  userId?: string;
};

export const useAdminSessions = () => {
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToastify();
  const { activeSessions } = useSelector((state: AppState) => state.adminUsers);

  const adminService = useHttpClient('/admin');
  const [isLoading, setIsLoading] = useState(false);
  const [isSessionKilled, setIsSessionKilled] = useState(false);
  const [isKillSessionsButtonShown, setIsKillSessionsButtonShown] =
    useState(false);

  // change number of results per page --> only here
  async function getAdminSessions({
    page = 1,
    perPage = 10,
    sort = 'DESC',
    sortBy = 'created_at',
    search,
    searchBy,
    authType,
    userId,
  }: Attributes) {
    dispatch(adminAction.loading());

    try {
      const { data } = await adminService.get<AdminResponse<Sessions>>(
        '/sessions',
        {
          params: {
            page,
            perPage,
            sort,
            sortBy,
            search,
            searchBy,
            authType,
            userId,
          },
        },
      );
      dispatch(adminAction.sessions(data));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(adminAction.error(e));
    }
  }

  async function getAllSessionPerUser({ userId }: Attributes) {
    try {
      const {
        data: { data },
      } = await adminService.get<AdminResponse<Sessions>>('/sessions', {
        params: {
          userId,
        },
      });

      const checkedActiveSessions: Sessions[] = [];
      data.forEach((session) => {
        if (checkSessionStatus(session.expiresAt)) {
          checkedActiveSessions.push(session);
        }
      });

      dispatch(adminAction.activeSessions(checkedActiveSessions));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(adminAction.error(e));
    }
  }

  async function killSession(sessionId: string) {
    setIsLoading(true);
    try {
      await adminService.delete(`/sessions/${sessionId}/kill`);
      const newActiveSessions = activeSessions?.filter(
        (session) => session.id !== sessionId,
      );
      setIsSessionKilled(true);
      dispatch(adminAction.activeSessions(newActiveSessions || []));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      toastError();
    }
    setIsLoading(false);
  }

  async function killAllSessions(userId: string) {
    setIsLoading(true);
    try {
      await adminService.delete(`/sessions/${userId}/kill-all`);
      setIsKillSessionsButtonShown(false);
      toastSuccess('shared.pills.successDefaultText');
      dispatch(adminAction.activeSessions([]));
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      toastError();
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (!activeSessions || !activeSessions.length) {
      setIsKillSessionsButtonShown(false);
      return;
    }

    setIsKillSessionsButtonShown(Boolean(activeSessions.length));
  }, [activeSessions]);

  return {
    isLoading,
    isSessionKilled,
    isKillSessionsButtonShown,
    getAdminSessions,
    getAllSessionPerUser,
    killSession,
    killAllSessions,
  };
};
