import { createAction } from 'modules/redux-store';

export const loginAction = {
  addStepOne: (payload: LoginData) =>
    createAction('login/ADD_2FA_DATA', payload),

  loginRedirect: (payload?: string) =>
    createAction('login/LOGIN_REDIRECT', payload),

  reset: () => createAction('login/RESET'),
};
