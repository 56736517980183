import { HttpError } from 'modules/app/http/client/httpError';
import { ActionUnion } from 'modules/redux-store';
import { activityActions } from './actions';

interface ActivityInitState {
  error?: HttpError;
  isLoading: boolean;
  activities?: Activity[];
}

const INITIAL_STATE: ActivityInitState = {
  isLoading: false,
};

export const reducer = (
  state: ActivityInitState = INITIAL_STATE,
  action: ActionUnion<typeof activityActions>,
): ActivityInitState => {
  switch (action.type) {
    case 'activity/SET_ACTIVITIES': {
      return {
        ...state,
        isLoading: false,
        error: undefined,
        activities: action.payload,
      };
    }

    case 'activity/LOADING': {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case 'activity/ERROR': {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case 'activity/RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
