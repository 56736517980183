import {
  ActionModal,
  Button,
  CheckboxField,
  InputField,
  TextArea,
} from 'modules/app';
import React, { useEffect, useState } from 'react';
import * as styles from 'modules/settings/styles/Companies.styles';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils/index';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { useDeleteUser } from '../hooks';
import { useUserSettings } from 'modules/settings/hooks';

interface Props {
  userId: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  isDeactivateAction: boolean;
  isDetails?: boolean;
}

interface FormData {
  deleteData: DeleteUserRequest;
  areTermsExcepted: boolean;
  reason: string;
}

export const UsersDeleteModal: React.FC<Props> = ({
  userId,
  setIsOpen,
  isOpen,
  isDeactivateAction,
  isDetails,
}) => {
  const translationsFor = isDeactivateAction
    ? 'deactivateModal'
    : 'deleteModal';

  const {
    requestDeactivateUser,
    requestDeleteUser,
    isLoading: requestDeleteUserLoading,
  } = useDeleteUser(isDetails);
  const { requestTfaStatus, isUsersTfaEnabled } = useUserSettings();

  const [isChecked, setIsChecked] = useState(false);
  const [reason, setReason] = useState(0);
  const methods = useForm<FormData>({
    defaultValues: {
      deleteData: {
        otpToken: '',
      },
      areTermsExcepted: false,
      reason: '',
    },
  });

  const { handleSubmit, watch, reset } = methods;

  const otpToken: string = watch('deleteData.otpToken');
  const isButtonDisabled =
    !isChecked ||
    requestDeleteUserLoading ||
    !reason ||
    (!isDeactivateAction && otpToken.length <= 5);

  const closeModal = () => {
    setIsOpen(false);
    setIsChecked(false);
    reset();
  };

  const onSubmit = async (data: FormData) => {
    isDeactivateAction
      ? requestDeactivateUser(userId, data.reason, closeModal)
      : requestDeleteUser(
          userId,
          data.deleteData.otpToken,
          data.reason,
          closeModal,
        );
  };

  useEffect(() => {
    requestTfaStatus();
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <ActionModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <h3 css={styles.modalTitle}>
        <FormattedMessage id={`admin.users.${translationsFor}.title`} />
      </h3>
      <div css={styles.modal}>
        <p css={styles.modalContent}>
          <FormattedMessage id={`admin.users.${translationsFor}.text`} />
        </p>

        {!isDeactivateAction && (
          <ol>
            {[...new Array(4)].map((_, index) => (
              <li key={index}>
                <FormattedMessage
                  id={`admin.users.deleteModal.listItem${index + 1}`}
                />
              </li>
            ))}
          </ol>
        )}

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextArea
              onValueChange={(value) => setReason(value.length)}
              name="reason"
              placeholder={`admin.users.${translationsFor}.textAreaPlaceholder`}
              value=""
              topLabel={`admin.users.${translationsFor}.textAreaTopLabel`}
              isOptional={false}
              maxChar="100"
              constraints={{
                maxLength: 100,
              }}
              customStyles={margin.bottom.xxxlrg}
            />
            <CheckboxField
              name="areTermsExcepted"
              constraints={{
                required: true,
              }}
              checked={isChecked}
              onChecked={(checked) => setIsChecked(checked)}
              customStyles={margin.bottom.xxxlrg}
            >
              <FormattedMessage
                id={`admin.users.${translationsFor}.checkboxText`}
              />
            </CheckboxField>

            {!isDeactivateAction && (
              <>
                {isUsersTfaEnabled ? (
                  <InputField
                    label="admin.users.deleteModal.otpToken"
                    name="deleteData.otpToken"
                    value=""
                    constraints={{}}
                  />
                ) : (
                  <p>
                    <FormattedMessage id="admin.users.deleteModal.enableTfa" />
                  </p>
                )}
                <p css={styles.modalError}>
                  <FormattedMessage id="admin.users.deleteModal.textIrreversible" />
                </p>
              </>
            )}

            <div>
              <Button
                buttonType="primary"
                size="small"
                type="submit"
                css={styles.modalDeactivateBtn}
                disabled={isButtonDisabled}
                isLoading={requestDeleteUserLoading}
              >
                <FormattedMessage
                  id={`admin.users.${translationsFor}.buttonConfirm`}
                />
              </Button>

              <Button
                buttonType="ghost"
                size="small"
                type="button"
                onClick={closeModal}
              >
                <FormattedMessage
                  id={`admin.users.${translationsFor}.buttonCancel`}
                />
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </ActionModal>
  );
};
