import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from 'style/variables';

export const root = css`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media ${breakpoints.large} {
    justify-content: right;
  }
`;

export const paragraph = css`
  margin: 0 ${spacing(8)} 0 0;
`;
