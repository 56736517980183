import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Navbar, Footer } from 'modules/app';
import { Header } from './components/Header';
import { StaticImage } from 'gatsby-plugin-image';
import { Banner } from './components/Banner';
import { FeaturesList } from 'modules/app/components/shared/FeaturesList';
import { FeatureListItem } from 'modules/app/components/shared/FeatureListItem';
import { DoubleMediaLayout } from '../app/components/shared/DoubleMediaLayout';
import { ImageGridLayout } from 'modules/app/components/shared/ImageGridLayout';

import * as styles from './styles/Root.styles';
import { ImageOverlay } from 'modules/app';

export const TalentsPage: React.FC<RouteComponentProps> = () => {
  return (
    <article css={styles.root}>
      <Navbar />
      <section css={styles.wrapper}>
        {/* Talents Header Component */}
        <Header />
      </section>

      {/* Build CV Section */}
      <DoubleMediaLayout
        heading="talentsPage.buildCvSection.title"
        description="talentsPage.buildCvSection.description"
        layoutType="mediaHero"
        bgColor={['textLight', 3]}
        headingColor={['textLight', 10]}
        descColor={['textLight', 7]}
      >
        <StaticImage
          formats={['auto', 'webp', 'avif']}
          loading="lazy"
          placeholder="blurred"
          src="../../assets/images/talents/talent-cv.png"
          alt=""
          quality={100}
        />
        <ImageOverlay background="hsl(var(--color-textLight-3))" type="top" />
      </DoubleMediaLayout>

      {/* Discover Opportunities Section */}
      <DoubleMediaLayout
        heading="talentsPage.discoverOpportunitiesSection.title"
        description="talentsPage.discoverOpportunitiesSection.description"
        layoutType="mediaLeft"
        bgColor={['warning', 9]}
      >
        <StaticImage
          formats={['auto', 'webp', 'avif']}
          loading="lazy"
          placeholder="blurred"
          src="../../assets/images/talents/talent-opportunities.png"
          alt=""
          quality={100}
        />
        <ImageOverlay background="hsl(var(--color-error-9))" type="left" />
      </DoubleMediaLayout>

      {/* Go Stealth Section */}
      <DoubleMediaLayout
        heading="talentsPage.goStealthSection.title"
        description="talentsPage.goStealthSection.description"
        layoutType="mediaRight"
        bgColor={['text', 1]}
        headingColor={['borderUltraLight', 10]}
        descColor={['textLight', 7]}
      >
        <StaticImage
          formats={['auto', 'webp', 'avif']}
          loading="lazy"
          placeholder="blurred"
          src="../../assets/images/talents/talent-privacy.png"
          alt=""
          quality={100}
        />
        <ImageOverlay background="hsl(var(--color-text-1))" type="right" />
      </DoubleMediaLayout>

      {/* Discover Comapnies Section */}
      <DoubleMediaLayout
        heading="talentsPage.discoverCompaniesSection.title"
        description="talentsPage.discoverCompaniesSection.description"
        layoutType="mediaLeft"
        isCta
      >
        <StaticImage
          formats={['auto', 'webp', 'avif']}
          loading="lazy"
          placeholder="blurred"
          src="../../assets/images/talents/talent-culture.png"
          alt=""
          quality={100}
        />
        <ImageOverlay background="hsl(var(--color-grayscale-1))" type="left" />
      </DoubleMediaLayout>

      {/* Work From Your Home Section */}
      <ImageGridLayout
        heading="talentsPage.wfhSection.title"
        description="talentsPage.wfhSection.description"
        bgColor={['white', 11]}
      />

      {/* Get Invited Section */}
      <DoubleMediaLayout
        heading="talentsPage.getInvitedSection.title"
        description="talentsPage.getInvitedSection.description"
        layoutType="mediaRight"
        isCta
      >
        <StaticImage
          formats={['auto', 'webp', 'avif']}
          loading="lazy"
          placeholder="blurred"
          src="../../assets/images/talents/talent-openings.png"
          alt=""
          quality={100}
        />
        <ImageOverlay background="hsl(var(--color-grayscale-1))" type="right" />
      </DoubleMediaLayout>

      {/* Feature List Component */}
      <FeaturesList
        heading="talentsPage.featuresSection.title"
        bgColor={['white', 11]}
      >
        <FeatureListItem featureTitle="talentsPage.featuresSection.feature1.title" />
        <FeatureListItem featureTitle="talentsPage.featuresSection.feature2.title" />
        <FeatureListItem featureTitle="talentsPage.featuresSection.feature3.title" />
        <FeatureListItem featureTitle="talentsPage.featuresSection.feature4.title" />
        <FeatureListItem featureTitle="talentsPage.featuresSection.feature5.title" />
        <FeatureListItem featureTitle="talentsPage.featuresSection.feature6.title" />
        <FeatureListItem featureTitle="talentsPage.featuresSection.feature7.title" />
        <FeatureListItem featureTitle="talentsPage.featuresSection.feature8.title" />
        <FeatureListItem featureTitle="talentsPage.featuresSection.feature9.title" />
        <FeatureListItem featureTitle="talentsPage.featuresSection.feature10.title" />
        <FeatureListItem featureTitle="talentsPage.featuresSection.feature11.title" />
        <FeatureListItem featureTitle="talentsPage.featuresSection.feature12.title" />
      </FeaturesList>

      {/* Page Banner Component */}
      <Banner />

      <Footer />
    </article>
  );
};
