import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { adminBillingActivityActions } from '../redux';
import { format } from 'date-fns';

export function useBillingActivity() {
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToastify();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const adminBillingActivityService = useHttpClient(
    '/admin/billing-activities',
  );

  async function getAdminBillingActivities(query: BillingActivityQuery) {
    dispatch(adminBillingActivityActions.loading());
    try {
      const { data } =
        await adminBillingActivityService.get<AdminBillingActivitySearchResponse>(
          '',
          {
            params: { ...query, perPage: 10 },
          },
        );
      dispatch(
        adminBillingActivityActions.setAdminBillingActivity({
          billingActivities: data.data || [],
          lastPage: data.last_page,
        }),
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(adminBillingActivityActions.error(e));
    }
  }

  async function exportBillingActivities(
    downloadRef: HTMLAnchorElement | null,
    query: BillingActivityQuery,
  ) {
    setIsSubmitting(true);
    try {
      const { data } = await adminBillingActivityService.get<BlobPart>(
        '/export',
        {
          responseType: 'blob',
          params: {
            ...query,
          },
        },
      );
      if (!data || !downloadRef) throw new Error('No data');

      const date = format(new Date(), 'yyyy-MM-dd');
      const url = window.URL.createObjectURL(new Blob([data]));

      downloadRef.setAttribute('href', url);
      downloadRef.setAttribute('download', `${date}-billing-activities.xlsx`);
      downloadRef.click();
      downloadRef.removeAttribute('href');
      downloadRef.removeAttribute('download');

      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      toastError();
    }
    setIsSubmitting(false);
  }

  return {
    isSubmitting,
    getAdminBillingActivities,
    exportBillingActivities,
  };
}
