import React from 'react';
import * as styles from 'modules/onboarding/styles/Expertise.styles';
import { FormProvider, useForm } from 'react-hook-form';
import { HttpError, useFormGuard } from 'modules/app';
import { debounce } from 'throttle-debounce';
import { FormattedMessage } from 'react-intl';
import { FormError } from 'modules/app/intl';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { useIntl } from 'gatsby-plugin-intl';
import { useAssets } from 'modules/shared';
import { useCompanyEdit } from 'modules/profile';

interface Props {
  callback: (companyId: string) => void;
  totalExpertise: number;
  companyId?: string;
}

export const CompanyExpertiseForm: React.FC<Props> = ({
  callback,
  totalExpertise,
  companyId,
}) => {
  const { formatMessage } = useIntl();
  const { error, addExpertise } = useCompanyEdit();
  const { companyData } = useSelector((state: AppState) => state.companyData);

  const id = companyId || companyData?.id;

  const methods = useForm<{ expertise: string }>({
    defaultValues: {
      expertise: '',
    },
  });
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setError,
    clearErrors,
  } = methods;
  useFormGuard(methods);

  const { suggestedTags, setSuggestedTags, getAllTags } = useAssets();

  const onInputChange = debounce(300, (text: string) => {
    if (/[^A-Za-z0-9#+]/g.test(text)) {
      setError('expertise', {
        type: 'manual',
        message: 'shared.errors.string',
      });
      return;
    }

    getAllTags(text);
    clearErrors('expertise');
  });

  function handleSuccess(companyId: string) {
    callback(companyId);
    reset({ expertise: '' });
    setSuggestedTags(undefined);
  }

  async function onSubmit(data: { expertise: string }) {
    if (/[^A-Za-z0-9#+]/g.test(data.expertise) || !id) return;
    if (totalExpertise >= 10) {
      setSuggestedTags(undefined);
      reset();
      setError('expertise', {
        type: 'manual',
        message: 'http_errors.max_expertise',
      });
      return;
    }

    addExpertise(id, [data.expertise], handleSuccess);
  }

  return (
    <div css={styles.expertise}>
      <FormProvider {...methods}>
        <form
          css={
            errors.expertise ? styles.expertiseFormError : styles.expertiseForm
          }
          onSubmit={handleSubmit(onSubmit)}
        >
          <div css={styles.expertiseFormHash}>#</div>
          <label
            htmlFor="expertise"
            css={errors.expertise ? styles.labelError : styles.label}
          >
            <FormattedMessage id="onboarding.form.fields.expertise.choose_a_tag" />
          </label>
          <input
            css={styles.expertiseFormInput}
            type="text"
            placeholder="tag"
            {...register('expertise', {
              required: true,
              minLength: 1,
              pattern: {
                value: /(\d|\w)*/g,
                message: formatMessage({ id: 'shared.errors.string' }),
              },
              maxLength: {
                value: 30,
                message: 'shared.errors.max',
              },
              onChange: (e) => onInputChange(e.target.value),
            })}
            autoComplete="off"
          />
          <input css={styles.expertiseFormSubmit} type="submit" value="Add" />
        </form>
        <FormError
          errors={errors}
          name="expertise"
          values={{
            max: 30,
          }}
        />
      </FormProvider>

      {suggestedTags && (
        <div css={styles.suggested}>
          {suggestedTags?.map((tag) => (
            <div css={styles.suggestedItem} key={tag.id}>
              <p
                onClick={() => {
                  onSubmit({ expertise: tag.name });
                }}
                css={styles.suggestedText}
              >
                {tag.name}
              </p>
              <p css={styles.suggestedResults}>
                {tag.totalCount.toString()} results
              </p>
            </div>
          ))}
        </div>
      )}

      {error && <HttpError error={error} />}
    </div>
  );
};
