import { Link } from '@reach/router';
import { MainContent, useGracePeriod } from 'modules/app';
import React from 'react';
import * as styles from 'modules/openings/styles/Openings.styles';
import { CompanyOpeningActions, CompanyPipelineItems } from '.';
import { useDispatch } from 'react-redux';
import { applicationsAction } from 'modules/applications';
import { OpeningItemStatus } from './OpeningItemStatus';
import { useOpenings } from '../hooks';

interface Props {
  data: CompanyOpening;
}

export const CompanyOpeningItem: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();
  const { isGracePeriod } = useGracePeriod();

  const {
    deleteOpening,
    archiveOpening,
    updateCloseDate,
    updateScheduleDate,
    changeOpeningStatus,
  } = useOpenings();

  return (
    <section css={styles.companyOpeningsListSection}>
      <MainContent>
        <div css={styles.headingWrapper}>
          <Link
            to={`/en/profile/company/openings/${data.companyHandle}/applications/${data.id}`}
            css={
              isGracePeriod
                ? styles.companyOpeningTitleDisabled
                : styles.companyOpeningTitle
            }
            onClick={() => {
              dispatch(applicationsAction.currentStage('all'));
            }}
          >
            {data.title}
          </Link>

          <OpeningItemStatus data={data} onChange={changeOpeningStatus} />
        </div>

        <p css={styles.companyOpeningsListSubtitle}>
          {data.department && `${data.department}, `} {data.cityCountry}
        </p>
        <CompanyPipelineItems data={data} />
        <CompanyOpeningActions
          data={data}
          onArchive={archiveOpening}
          onDelete={deleteOpening}
          onUpdateCloseDate={updateCloseDate}
          onUpdateSceduleDate={updateScheduleDate}
        />
      </MainContent>
    </section>
  );
};
