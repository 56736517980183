import { FormattedMessage } from 'gatsby-plugin-intl';
import {
  InputField,
  SelectComponent,
  useValidateCompanyBasicInputs,
  transformSelectedCountry,
} from 'modules/app';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import * as inputStyles from '../../../styles/PortfolioForm.styles';
import * as styles from '../../../styles/GenericForm.styles';

interface Props {
  company?: Company;
  countriesValue: { value: string; label: string }[];
  isHandleExisting?: boolean;
  setIsHandleExisting?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CompanyBasicInputs: React.FC<Props> = ({
  company,
  countriesValue,
  isHandleExisting,
  setIsHandleExisting,
}) => {
  const {
    formState: { errors },
  } = useFormContext();
  const { validateHandle, validateWebsiteUrl, validateYear } =
    useValidateCompanyBasicInputs({ isHandleExisting, setIsHandleExisting });

  return (
    <>
      <div css={errors.handle ? inputStyles.rootError : inputStyles.root}>
        <label
          css={errors.handle ? inputStyles.urlLabelError : inputStyles.urlLabel}
          htmlFor="handle"
        >
          <FormattedMessage id="onboarding.form.fields.information.username" />
        </label>
        <div css={inputStyles.urlInputWrapper}>
          <p css={inputStyles.urlHandle}>Roango.com/@</p>
          <InputField
            name="handle"
            customStyles={inputStyles.urlInput}
            value={company?.handle || ''}
            showError={false}
            constraints={{
              required: true,
              validate: validateHandle,
            }}
          />
          <div css={inputStyles.helpContainer}>
            <>
              {errors && errors?.handle && (
                <span css={inputStyles.fieldError}>
                  {errors.handle.message && (
                    <FormattedMessage id={errors.handle.message} />
                  )}
                </span>
              )}
            </>
          </div>
        </div>
      </div>
      <div css={styles.subSubgridForm}>
        <InputField
          label="onboarding.form.fields.information.company_name"
          name="name"
          value={company?.name || ''}
          constraints={{
            required: true,
            maxLength: {
              value: 150,
              message: 'shared.errors.max',
            },
          }}
        />

        <InputField
          label="onboarding.form.fields.information.year_of_founding"
          name="establishedAt"
          customStyles={errors.establishedAt && styles.establishedAtError}
          value={company?.establishedAt.substring(0, 4) || ''}
          constraints={{
            required: true,
            maxLength: 4,
            minLength: 4,
            validate: validateYear,
          }}
        />
      </div>

      <div css={styles.subgridForm}>
        <div css={errors.website ? inputStyles.rootError : inputStyles.root}>
          <label
            css={
              errors.website ? inputStyles.urlLabelError : inputStyles.urlLabel
            }
            htmlFor="website"
          >
            <FormattedMessage id="onboarding.form.fields.information.website" />
          </label>
          <div css={inputStyles.urlInputWrapper}>
            <p css={inputStyles.urlHandle}>https://</p>
            <InputField
              name="website"
              customStyles={inputStyles.urlInput}
              showError={false}
              value={
                company?.website ? company.website.split('https://')[1] : ''
              }
              constraints={{
                required: true,
                maxLength: 255,
                minLength: 2,
                validate: validateWebsiteUrl,
              }}
            />

            <div css={inputStyles.helpContainer}>
              <>
                {errors && errors?.website && (
                  <span css={inputStyles.fieldError}>
                    {errors.website.message && (
                      <FormattedMessage id={errors.website.message} />
                    )}
                  </span>
                )}
              </>
            </div>
          </div>
        </div>

        <SelectComponent
          name="country"
          label={
            <FormattedMessage id="onboarding.form.fields.information.country_of_establishment" />
          }
          constraints={{ required: true }}
          selectedValue={transformSelectedCountry(
            company?.country,
            countriesValue,
          )}
          values={countriesValue}
        />
      </div>
    </>
  );
};
