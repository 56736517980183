import { ActionUnion } from 'modules/redux-store';

import { pipelinesAction } from './actions';

interface PipelinesInitState {
  isLoading: boolean;
  error?: ApiErrorData;
  pipelines?: PipelineView[];
  pipeline?: Pipeline;
}

const INITIAL_STATE: PipelinesInitState = {
  isLoading: false,
  error: undefined,
  pipelines: undefined,
  pipeline: undefined,
};

export const pipelinesReducer = (
  state: PipelinesInitState = INITIAL_STATE,
  action: ActionUnion<typeof pipelinesAction>,
): PipelinesInitState => {
  switch (action.type) {
    case 'pipelines/LOADING':
      return {
        ...state,
        isLoading: true,
      };

    case 'pipelines/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case 'pipelines/COMPANY_PIPELINES':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        pipelines: action.payload,
      };

    case 'pipelines/GET_PIPELINE':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        pipeline: action.payload,
      };

    case 'pipelines/PIPELINES_RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
