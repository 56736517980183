export function transformSelectedPlace(
  cityCountry: string | undefined | null,
  location?: GoogleLocation | undefined | null,
): SelectedPlace | undefined {
  if (location && cityCountry) {
    return {
      location: { x: location.x, y: location.y },
      cityCountry: cityCountry,
    };
  }

  return undefined;
}
