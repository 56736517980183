import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import {
  InputField,
  SelectComponent,
  TextArea,
  useCompanyDescriptionValues,
  useTransformSelect,
} from 'modules/app';
import React, { useEffect, useState, useMemo } from 'react';
import * as styles from 'modules/app/styles/GenericForm.styles';
import { WorkplaceEnum } from 'modules/profilePreview';
import { format } from 'date-fns';
import { employmentTypeValues } from '../consts/employmentTypeValues';

const DatePicker = React.lazy(() => import('../../form/DatePicker'));

interface Props {
  currentExperience?: Experience | GhostExperience;
  resetValue: number;
}

export const TalentExperienceInputs: React.FC<Props> = ({
  currentExperience,
  resetValue,
}) => {
  const { formatMessage } = useIntl();
  const [endDateValue, setEndDateValue] = useState<string | undefined>();
  const [startDate, setStartDate] = useState<Date | undefined>(
    currentExperience
      ? new Date(currentExperience.employmentStartDate)
      : undefined,
  );
  const { typeOfWorkplace } = useCompanyDescriptionValues();
  const workplace = useMemo(() => {
    if (!currentExperience?.workplace) return;

    if (!Array.isArray(currentExperience.workplace)) {
      return {
        value: currentExperience.workplace,
        label: (
          <FormattedMessage id={WorkplaceEnum[currentExperience.workplace]} />
        ),
      };
    }

    return currentExperience.workplace.map((currentWorkplace) => ({
      value: currentWorkplace,
      label: <FormattedMessage id={WorkplaceEnum[currentWorkplace]} />,
    }));
  }, [currentExperience]);

  useEffect(() => {
    if (!currentExperience) {
      setStartDate(undefined);
      setEndDateValue(
        formatMessage({ id: 'shared.date_picker.currently_working_there' }),
      );
      return;
    }

    setStartDate(new Date(currentExperience.employmentStartDate));
    setEndDateValue(
      currentExperience.employmentEndDate
        ? format(new Date(currentExperience.employmentEndDate), 'MMMM, yyyy')
        : formatMessage({ id: 'shared.date_picker.currently_working_there' }),
    );
  }, [currentExperience]);

  return (
    <>
      <div css={styles.subgridForm}>
        <InputField
          value={currentExperience?.jobTitle || ''}
          name="jobTitle"
          type="text"
          constraints={{
            required: true,
            maxLength: {
              value: 150,
              message: 'shared.errors.max',
            },
          }}
          label="onboarding.form.fields.experience_employment.job_title"
          reset={resetValue}
        />

        <InputField
          type="text"
          name="companyName"
          value={currentExperience?.companyName || ''}
          constraints={{
            required: true,
            maxLength: {
              value: 150,
              message: 'shared.errors.max',
            },
          }}
          label="onboarding.form.fields.experience_employment.company_name"
          reset={resetValue}
        />

        <SelectComponent
          isOptional={true}
          name="employmentType"
          label={
            <FormattedMessage id="onboarding.form.fields.experience_employment.employment_type" />
          }
          constraints={{}}
          reset={resetValue}
          selectedValue={useTransformSelect(
            currentExperience?.employmentType,
            employmentTypeValues,
          )}
          values={employmentTypeValues}
        />

        <SelectComponent
          isOptional={true}
          name="workplace"
          label={
            <FormattedMessage id="onboarding.form.fields.experience_employment.workplace" />
          }
          isMulti={true}
          constraints={{}}
          reset={resetValue}
          selectedValue={workplace}
          values={typeOfWorkplace}
        />
        <React.Suspense fallback={null}>
          <DatePicker
            name="employmentStartDate"
            constraints={{
              required: true,
            }}
            label="onboarding.form.fields.experience_employment.start_date"
            maxDate={true}
            reset={resetValue}
            setStartDate={setStartDate}
            value={currentExperience?.employmentStartDate || ''}
          />

          <DatePicker
            isOptional={true}
            maxDate={true}
            setEndDateValue={setEndDateValue}
            endDateValue={endDateValue}
            label="onboarding.form.fields.experience_employment.end_date"
            name="employmentEndDate"
            constraints={{}}
            currentButton={true}
            reset={resetValue}
            defaultValue="shared.date_picker.currently_working_there"
            minDate={startDate}
            value={currentExperience?.employmentEndDate || ''}
            isNullable={true}
          />
        </React.Suspense>
      </div>
      <TextArea
        isOptional={true}
        value={currentExperience?.jobDescription || ''}
        constraints={{
          maxLength: {
            value: 255,
            message: 'shared.errors.max',
          },
        }}
        name="jobDescription"
        topLabel="onboarding.form.fields.experience_employment.job_description"
        placeholder="onboarding.form.fields.experience_employment.job_description_placeholder"
        reset={resetValue}
      />
    </>
  );
};
