import { ActionUnion } from 'modules/redux-store';

import { billingActions } from './actions';

interface PaginatedList<T> {
  lastPage?: number;
  data?: T[];
}

interface BillingInitState {
  isLoading: boolean;
  error?: ApiErrorData;
  plans?: Plan[];
  billingInfo?: BillingDetailsResponse;
  activePlan?: ActivePlanCached;
  paymentCheck?: PaymentCheckResponse;
  activities?: PaginatedList<Activity>;
  openings?: { value: string; label: string }[];
  card?: PaymentCard;
  invoices?: PaginatedList<Invoice>;
}

const INITIAL_STATE: BillingInitState = {
  isLoading: false,
  error: undefined,
  plans: undefined,
  billingInfo: undefined,
};

export const billingReducer = (
  state: BillingInitState = INITIAL_STATE,
  action: ActionUnion<typeof billingActions>,
): BillingInitState => {
  switch (action.type) {
    case 'billing/SET_PLANS':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        plans: action.payload,
      };
    case 'billing/SET_BILLING_INFO':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        billingInfo: action.payload,
      };
    case 'billing/LOADING':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case 'billing/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'billing/BILLING_INFO_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        billingInfo: undefined,
      };
    case 'billing/SET_ACTIVE_PLAN':
      return {
        ...state,
        activePlan: action.payload,
      };

    case 'billing/SET_ACTIVITIES':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        activities: action.payload,
      };
    case 'billing/SET_PAYMENT_CHECK':
      return {
        ...state,
        paymentCheck: action.payload,
        isLoading: false,
        error: undefined,
      };
    case 'billing/SET_OPENINGS':
      return {
        ...state,
        openings: action.payload,
      };
    case 'billing/SET_CARD':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        card: action.payload,
      };
    case 'billing/SET_INVOICES':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        invoices: action.payload,
      };
    case 'billing/BILLING_RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
