import { AppState } from 'modules/redux-store';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

export const useNearMe = (
  getNewOpenings: (data: Partial<OpeningsSearchParams>) => void,
) => {
  const ref = useRef(false);
  const [locations, setLocations] = useState<string[]>([]);
  const { params } = useSelector((state: AppState) => state.talentOpenings);
  const { basics } = useSelector((state: AppState) => state.user);
  const isNearMe = Boolean(params.xLocation && params.yLocation);

  const changeLocations = useCallback(() => {
    if (!ref.current) {
      ref.current = true;

      return;
    }

    if (!Boolean(locations.length) && !isNearMe) {
      getNewOpenings({
        location: undefined,
        yLocation: undefined,
        xLocation: undefined,
      });

      return;
    }

    if (!Boolean(locations.length)) {
      return;
    }

    if (isNearMe) {
      getNewOpenings({
        xLocation: undefined,
        yLocation: undefined,
        location: locations.join(';'),
      });

      return;
    }

    getNewOpenings({
      location: locations.join(';'),
    });
  }, [locations, getNewOpenings, isNearMe]);

  const onNearMe = (checked: boolean) => {
    if (!checked) {
      getNewOpenings({
        xLocation: undefined,
        yLocation: undefined,
      });

      return;
    }

    if (Boolean(locations.length)) {
      setLocations([]);
    }

    getNewOpenings({
      xLocation: basics?.location?.x,
      yLocation: basics?.location?.y,
      location: undefined,
    });
  };

  useEffect(changeLocations, [locations]);

  return {
    onNearMe,
    locations,
    setLocations,
  };
};
