import { ActionUnion } from 'modules/redux-store';

import { companyMessagesActions } from './actions';

interface MessagesInitState {
  isLoading: boolean;
  error?: ApiErrorData;
  conversations?: Conversation[];
  messages?: Conversation;
}

const INITIAL_STATE: MessagesInitState = {
  isLoading: false,
  error: undefined,
};

export const companyMessagesReducer = (
  state: MessagesInitState = INITIAL_STATE,
  action: ActionUnion<typeof companyMessagesActions>,
): MessagesInitState => {
  switch (action.type) {
    case 'messages/SET_CONVERSATIONS':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        conversations: action.payload,
      };
    case 'messages/SET_MESSAGES':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        messages: action.payload,
      };
    case 'messages/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'messages/LOADING':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case 'messages/MESSAGES_RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
