import React, { ButtonHTMLAttributes } from 'react';
import { SerializedStyles } from '@emotion/react';

import * as styles from 'modules/app/styles/Button.styles';
import * as loadStyles from 'modules/app/styles/Loading.styles';
import { FormattedMessage } from 'react-intl';
import { LoadingImg } from 'modules/app';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  textId: string;
  buttonType: ButtonType;
  theme?: 'black' | 'white';
  placeholders?: Record<string, number>;
  icon?: React.ReactNode;
  css?: SerializedStyles | SerializedStyles[];
  size?: ButtonSize;
  iconRight?: React.ReactNode;
  isLoading?: boolean;
}

export const Button: React.FC<Partial<Props>> = ({
  textId,
  buttonType = 'primary',
  placeholders,
  icon,
  children,
  css,
  size,
  iconRight,
  isLoading,
  ...rest
}) => {
  const content = Boolean(icon) ? (
    <>
      {isLoading ? (
        <LoadingImg
          customStyles={
            buttonType === 'primary'
              ? loadStyles.loadingPrimaryLeft
              : loadStyles.loadingLeft
          }
        />
      ) : (
        <span
          css={
            size === 'tiny' || size === 'small' ? styles.iconSmall : styles.icon
          }
        >
          {icon}
        </span>
      )}

      <span css={styles.text}>{children}</span>
    </>
  ) : (
    <>
      <span css={styles.text}>{children}</span>
      {isLoading ? (
        <LoadingImg
          customStyles={
            buttonType === 'primary'
              ? loadStyles.loadingPrimary
              : loadStyles.loading
          }
        />
      ) : (
        <>
          {Boolean(iconRight) && (
            <span
              css={
                size === 'tiny' || size === 'small'
                  ? styles.iconRightSmall
                  : styles.iconRight
              }
            >
              {iconRight}
            </span>
          )}
        </>
      )}
    </>
  );

  return (
    <button
      css={[styles.root, styles[buttonType], size && styles[size], css]}
      {...rest}
    >
      {content}
      {textId && <FormattedMessage id={textId} values={placeholders} />}
    </button>
  );
};
