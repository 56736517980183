export function normalizeEducationRequest(data: EducationRequest) {
  return {
    educationalInstitution: data.educationalInstitution,
    startYear: data.startYear.substring(0, 4),
    degree: data.degree || null,
    fieldOfStudy: data.fieldOfStudy || null,
    activities: data.activities || null,
    associations: data.associations || null,
    endYear: data.endYear ? data.endYear.substring(0, 4) : null,
  };
}
