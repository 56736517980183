import React from 'react';

import DownloadIcon from 'assets/icons/download.svg';
import DeleteIcon from 'assets/icons/delete.svg';
import * as styles from 'modules/talentMessages/styles/Attachment.styles';
import { makeBaseImagePath } from 'modules/app';

interface Props {
  name: string;
  onDelete?: () => void;
  path?: string;
  theme?: 'light' | 'dark';
  isSending?: boolean;
}

export const Attachment: React.FC<Props> = ({
  name,
  onDelete,
  path,
  theme = 'dark',
  isSending,
}) => {
  if (onDelete && !path) {
    return (
      <div css={theme === 'dark' ? styles.root : styles.rootLight}>
        <span>{name}</span>
        <DeleteIcon
          css={theme === 'dark' ? styles.deleteIcon : styles.deleteIconLight}
          onClick={onDelete}
        />
      </div>
    );
  }

  const url = makeBaseImagePath(path);

  if (isSending) {
    return (
      <div css={theme === 'dark' ? styles.disabled : styles.disabledLight}>
        <DownloadIcon css={styles.downloadIcon} />
        <span>{name}</span>
      </div>
    );
  }

  return (
    <a
      css={theme === 'dark' ? styles.rootDownload : styles.rootDownloadLight}
      href={url}
      rel="noopener noreferrer nofollow"
      target="__blank"
    >
      <DownloadIcon css={styles.downloadIcon} />
      <span>{name}</span>
    </a>
  );
};
