import React from 'react';
import { FormProvider } from 'react-hook-form';

import * as styles from 'modules/talentMessages/styles/SendReply.styles';
import { useReply } from '../hooks';
import { MessageInput } from './MessageInput';
import { useFormGuard } from 'modules/app';
import { AttachmentsInput } from './AttachmentsInput';

interface Props {
  messages: Conversation;
}

export const SendReply: React.FC<Props> = ({ messages }) => {
  const { methods, reply } = useReply(messages);

  const { handleSubmit } = methods;
  useFormGuard(methods);
  const message = messages.conversationMessages;
  const isLoading = Boolean(message?.find((msg) => msg.isLoading));

  return (
    <FormProvider {...methods}>
      <form css={styles.root} onSubmit={handleSubmit(reply)}>
        <MessageInput
          name="body"
          constraints={{
            required: true,
            maxLength: { value: 5000, message: 'shared.errors.max' },
          }}
          label="talentMessages.messageLabel"
          isLoading={isLoading}
          maxChar="5000"
        />
        <div css={styles.attachments}>
          <AttachmentsInput
            name="attachments"
            acceptedFiles={[
              '.docx',
              '.pdf',
              'image/jpeg',
              'image/jpg',
              'image/png',
              'image/webp',
            ]}
            maxSize={10485760}
          />
        </div>
      </form>
    </FormProvider>
  );
};
