import React from 'react';
import { flex, font, grid, text } from '@prototyp/gatsby-plugin-gumball/utils';
import * as styles from 'modules/admin/styles/Table.styles';
import { Avatar } from 'modules/app';

interface Props {
  profilePicture?: string;
  name: string;
  lastName?: string;
  noLink?: boolean;
  info?: string;
}

export const UserCard: React.FC<Props> = ({
  profilePicture,
  name,
  lastName,
  noLink = false,
  info,
}) => {
  return (
    <div css={[flex.display.flex, grid.gap.xlrg, text.color.text]}>
      <Avatar imagePath={profilePicture} name={name} lastName={lastName} />
      <div>
        <div css={noLink ? styles.link : styles.active}>
          {name} {lastName}
        </div>
        <div css={[font.size.small, text.color.textLight]}>{info}</div>
      </div>
    </div>
  );
};
