import { ActionUnion } from 'modules/redux-store';

import { evaluationsActions } from './actions';

interface EvaluationsInitState {
  error?: ApiErrorData;
  isLoading: boolean;
  evaluations?: Evaluation[];
  page: number;
  lastPage: number;
}

const INITIAL_STATE: EvaluationsInitState = {
  isLoading: false,
  page: 1,
  lastPage: 1,
};

export const reducer = (
  state: EvaluationsInitState = INITIAL_STATE,
  action: ActionUnion<typeof evaluationsActions>,
): EvaluationsInitState => {
  switch (action.type) {
    case 'evaluations/SET_EVALUATIONS': {
      return {
        ...state,
        isLoading: false,
        error: undefined,
        ...action.payload,
      };
    }
    case 'evaluations/LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'evaluations/ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'evaluations/RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
