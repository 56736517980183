import { useEffect } from 'react';
import { navigate } from 'gatsby-plugin-intl';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { useCsrfCookie } from 'modules/app';

export const useOnboardingDone = (companyHandle?: string) => {
  const { user } = useSelector((state: AppState) => state.session);
  const { isLoggedIn } = useCsrfCookie();

  useEffect(() => {
    if (!user || !isLoggedIn()) {
      navigate('/login');
      return;
    }

    if (!user.talentOnboardingDone && !user.companyOnboardingDone) return;

    if (companyHandle) {
      navigate(`/profile/company/overview/${companyHandle}`);
      return;
    }

    navigate('/profile/talent/overview/invitations');
  }, [user, isLoggedIn, companyHandle]);

  return Boolean(user?.talentOnboardingDone || user?.companyOnboardingDone);
};
