import React, { useEffect } from 'react';
import { ConversationListItem } from './ConversationListItem';

import * as styles from 'modules/talentMessages/styles/ConversationList.styles';
import { LoadMorePagination } from 'modules/app';
import { useConversations } from '../hooks';
import { navigate } from 'gatsby-plugin-intl';

interface Props {
  conversationList: Conversation[];
  onLoadMore: (page: number) => void;
  lastPage: number;
  page: number;
  isLoading: boolean;
}

export const ConversationList: React.FC<Props> = ({
  conversationList,
  onLoadMore,
  lastPage,
  page,
  isLoading,
}) => {
  const isMobile = window.innerWidth < 768;
  const conversations = conversationList.sort(
    (a, b) => new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf(),
  );
  const { conversationId } = useConversations();

  useEffect(() => {
    if (isMobile || conversationId) return;

    navigate(`/profile/talent/messages?messageId=${conversations[0].id}`, {
      replace: true,
    });
  }, []);

  return (
    <div css={styles.root}>
      {conversations.map((conversation) => (
        <ConversationListItem
          key={conversation.id}
          conversation={conversation}
        />
      ))}

      {lastPage > page && (
        <LoadMorePagination
          onLoadMore={onLoadMore}
          customStyles={styles.loadMoreBtn}
          isLoading={isLoading}
          size="small"
        />
      )}
    </div>
  );
};
