import { useHttpClient } from 'modules/app/http/hooks/useHttpClient';
import { HttpError } from 'modules/app/http/client/httpError';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FileError } from 'react-dropzone';
import { makeBaseImagePath, useToastify } from 'modules/app';
import { companyDataAction } from '../redux';
import { useDispatch } from 'react-redux';

export const useCompanyMetadata = (companyMeta: CompanyMetadata) => {
  const dispatch = useDispatch();
  const { toastSuccess } = useToastify();

  const methods = useForm<CompanySeoRequest>({
    defaultValues: {
      metaTitle: companyMeta.metaTitle || '',
      metaDescription: companyMeta.metaDescription || '',
      metaImage: null,
    },
  });

  const { setValue } = methods;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<HttpError>();
  const [isFileLarge, setIsFileLarge] = useState(false);
  const [isWrongFormat, setIsWrongFormat] = useState(false);
  const [image, setImage] = useState(
    companyMeta.metaImage
      ? makeBaseImagePath(companyMeta.metaImage)
      : undefined,
  );

  const isError = useMemo(
    () => isFileLarge || isWrongFormat,
    [isFileLarge, isWrongFormat],
  );

  const seoHttp = useHttpClient('/api/v1/company');

  const handleError = useCallback((errors: FileError[][]) => {
    if (!Boolean(errors.length)) {
      setIsFileLarge(false);
      setIsWrongFormat(false);

      return;
    }

    const error = errors[0].map((error) => error.code);

    if (error.includes('file-too-large')) {
      setIsFileLarge(true);
    }

    if (error.includes('file-invalid-type')) {
      setIsWrongFormat(true);
    }
  }, []);

  function onImageDrop(files: File[]) {
    const file = files[0];

    if (!file) return;

    const bufReader = new FileReader();
    bufReader.readAsArrayBuffer(file);

    bufReader.onloadend = () => {
      const arr = new Uint8Array(bufReader.result as ArrayBuffer);
      const str = arr.reduce((data, byte) => {
        return data + String.fromCharCode(byte);
      }, '');

      const base64 = btoa(str);
      const img = `data:${file.type};base64,${base64}`;
      setValue('metaImage', img);
    };

    setImage(URL.createObjectURL(file));
  }

  async function setMetadata(companyId: string, info: CompanySeoRequest) {
    setError(undefined);
    setIsLoading(true);
    try {
      const { data } = await seoHttp.put<Company, CompanySeoRequest>(
        `/${companyId}/profile/meta-data`,
        info,
      );
      dispatch(companyDataAction.companyData(data));
      toastSuccess('shared.pills.successDefaultText');
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      setError(e);
    }
    setIsLoading(false);
  }

  return {
    error,
    image,
    isError,
    methods,
    isLoading,
    isFileLarge,
    isWrongFormat,
    onImageDrop,
    setMetadata,
    handleError,
  };
};
