import { css } from '@emotion/react';
import { fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball/mixins';
import { borderRadius } from './variables';

const toast = css`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: calc(var(--unit) * 5);
  border-radius: 3px;
  box-shadow: none;
  font-size: ${fontSize('tiny')};
  line-height: ${lineHeight('tiny')};

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const global = css`
  body {
    font-family: var(--font-family-primary);
    font-size: ${fontSize('base')};
    line-height: ${lineHeight('base')};
    color: hsl(var(--color-text-1));
    background-color: hsl(var(--color-textLight-10));
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    text-decoration: none;
  }

  p {
    margin: 0;
    font-weight: normal;
  }

  input:-webkit-autofill,
  input:focus:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 200px hsl(var(--color-textLight-10)) inset;
  }

  .pac-container {
    border-radius: ${borderRadius.regular};
  }

  .pac-item {
    border: none;
    padding: calc(var(--unit) + 1px);
    border-bottom: 1px solid hsl(var(--color-borderLight-8));
  }

  .pac-item:hover {
    background-color: hsl(var(--color-borderLight-8));
  }

  .overlay-modal {
    position: fixed;
    z-index: 100;
    width: 100%;
    display: flex;
    justify-content: center;
    left: 0;
    top: 0;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
  }

  .Toastify__toast-container {
    width: auto;
    max-width: 1020px;
  }

  .toast--error {
    background: hsl(var(--color-warning-6));
    color: white;
    ${toast};

    path {
      fill: white;
    }
  }

  .toast--success {
    ${toast};
    background: hsl(var(--color-success-9));
    color: hsl(var(--color-text-1));

    path {
      fill: hsl(var(--color-text-1));
    }
  }

  .toast--info {
    ${toast};
    background: hsl(var(--color-note-8));
    color: hsl(var(--color-text-1));

    path {
      fill: hsl(var(--color-text-1));
    }
  }

  .toast__body {
    margin: 0 calc(var(--unit) * 10) 0 0;
    padding: 0;

    div:first-of-type {
      margin-right: calc(var(--unit) * 5);
    }
  }

  .Toastify__close-button {
    align-self: inherit;
    opacity: 0.7;
  }
`;

export const center = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`;

export const scrollbar = css`
  &::-webkit-scrollbar {
    width: var(--unit);
    height: var(--unit);
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-thumb {
    background: hsl(var(--color-borderDark-5));
    border-radius: var(--unit);
  }
`;

export const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ellipsis = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const linkUnderline = css`
  width: max-content;
  max-width: 100%;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: hsl(var(--color-grayscale-1));
    transition: width 0.3s cubic-bezier(0.85, 0, 0.15, 1);
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
`;
