import { ActionUnion } from 'modules/redux-store';
import { impersonateAction } from './actions';

interface ImpersonateInitState {
  user?: Session;
  error?: ApiErrorData;
  isLoading: boolean;
}

const INITIAL_STATE: ImpersonateInitState = {
  user: undefined,
  error: undefined,
  isLoading: false,
};

export const impersonateReducer = (
  state: ImpersonateInitState = INITIAL_STATE,
  action: ActionUnion<typeof impersonateAction>,
): ImpersonateInitState => {
  switch (action.type) {
    case 'impersonate/START':
      return {
        ...state,
        ...action.payload,
        error: undefined,
        isLoading: false,
      };
    case 'impersonate/END':
      return {
        ...state,
        user: undefined,
        error: undefined,
        isLoading: false,
      };
    case 'impersonate/ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case 'impersonate/LOADING':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case 'impersonate/RESET':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
