import { useLocation } from '@reach/router';
import { navigate } from 'gatsby-plugin-intl';
import { useHttpClient, useToastify } from 'modules/app';
import { HttpError } from 'modules/app/http/client/httpError';
import { AppState } from 'modules/redux-store';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { talentMessagesActions } from '../redux';
import { getMessageId, getUnreadList } from '../utils';

export const useConversations = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { toastError } = useToastify();

  const [isLoadMore, setIsLoadMore] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { unreadConversations, conversations, page, lastPage } = useSelector(
    (state: AppState) => state.talentMessages,
  );
  const { user } = useSelector((state: AppState) => state.session);

  const conversationHttp = useHttpClient<PaginatedResponse<Conversation>>(
    '/api/v1/user/conversations',
  );

  const conversationId = useMemo(() => getMessageId(search), [search]);

  async function getConversations() {
    dispatch(talentMessagesActions.loading());

    try {
      const { data } = await conversationHttp.get('', {
        params: { page: 1 },
      });
      dispatch(
        talentMessagesActions.setConversations({
          ...data,
          conversations: data.data,
        }),
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(talentMessagesActions.error(e));
    }
  }

  async function loadMoreConversations(
    page: number,
    conversations: Conversation[],
  ) {
    setIsLoadMore(true);

    try {
      const { data } = await conversationHttp.get('', {
        params: { page },
      });
      dispatch(
        talentMessagesActions.setConversations({
          ...data,
          conversations: [...conversations, ...data.data],
        }),
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(talentMessagesActions.error(e));
    }

    setIsLoadMore(false);
  }

  async function getMessages(conversationId: string, load = true) {
    load && dispatch(talentMessagesActions.messagesLoading());

    try {
      const { data } = await conversationHttp.get<Conversation>(
        `/${conversationId}`,
      );
      dispatch(talentMessagesActions.setMessages(data));

      if (!Boolean(unreadConversations.find((id) => id === conversationId))) {
        return;
      }

      dispatch(
        talentMessagesActions.setUnreadConversations(
          unreadConversations.filter((id) => id !== conversationId),
        ),
      );
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      dispatch(talentMessagesActions.messagesError(e));
    }
  }

  async function getNotifications() {
    if (!user) return;

    try {
      const {
        data: { data },
      } = await conversationHttp.get('', {
        params: {
          page: 1,
        },
      });

      const conversationsList = data.map((c) => ({
        id: c.id,
        applicationId: c.applicationId,
      }));

      dispatch(talentMessagesActions.setConversationsList(conversationsList));

      const unreadList = getUnreadList(data, user);

      if (!Boolean(unreadList.length)) return;

      if (
        conversationId &&
        Boolean(unreadList.find((id) => id === conversationId))
      ) {
        getMessages(conversationId, false);
        return;
      }

      dispatch(talentMessagesActions.setUnreadConversations(unreadList));
    } catch (e) {
      return;
    }
  }

  async function deleteConversation(id: string) {
    setIsDeleting(true);

    try {
      await conversationHttp.delete(`/${id}/delete`);

      const newConversations = conversations?.filter((c) => c.id !== id);

      if (!newConversations) return;

      dispatch(
        talentMessagesActions.setConversations({
          conversations: newConversations,
          lastPage,
          page,
        }),
      );
      dispatch(
        talentMessagesActions.setConversationsList(
          newConversations.map((c) => ({
            id: c.id,
            applicationId: c.applicationId,
          })),
        ),
      );

      if (!newConversations.length) {
        navigate(`/profile/talent/messages`);
        return;
      }

      if (conversationId === id) {
        navigate(
          `/profile/talent/messages?messageId=${newConversations[0].id}`,
        );
      }

      conversationHttp.get('', {
        params: {
          perPage: newConversations.length,
          page: 1,
        },
      });
    } catch (e) {
      toastError();
    }

    setIsDeleting(false);
  }

  return {
    isDeleting,
    isLoadMore,
    conversationId,
    getMessages,
    getConversations,
    getNotifications,
    deleteConversation,
    loadMoreConversations,
  };
};
