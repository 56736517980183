import { useIntl } from 'gatsby-plugin-intl';
import { formatDay } from '../utils';

export const useGetDay = (createdAt: string) => {
  const { formatMessage } = useIntl();
  const date = new Date();
  const today = formatDay(date.toString());
  const yesterday = formatDay(
    new Date(date.setDate(date.getDate() - 1)).toString(),
  );
  const created = formatDay(createdAt);

  if (today === created) {
    return formatMessage({ id: 'talentMessages.today' });
  }

  if (yesterday === created) {
    return formatMessage({ id: 'talentMessages.yesterday' });
  }

  return created;
};
