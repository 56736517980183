import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';

export const root = css`
  width: 100%;
  max-width: ${spacing(416)};
`;

export const title = css`
  margin: ${spacing(50)} 0 ${spacing(20)};
`;

export const content = css`
  color: hsl(var(--color-grayscale-6));
`;
