import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';
import { Button, Heading } from 'modules/app';
import { AppState } from 'modules/redux-store';
import React from 'react';
import { useSelector } from 'react-redux';

import * as styles from 'modules/openings/styles/OpeningRestrictionModal.styles';
import { ScheduleData } from './CompanyOpeningActions';
import { StatusData } from './OpeningItemStatus';
import { getDeactivateContent } from '../helpers';

interface Props {
  setIsOpen: (isOpen: boolean) => void;
  handle?: string;
  openingId?: string;
  companyId?: string;
  scheduleData?: ScheduleData;
  onScheduleOpening?: (
    companyId: string,
    openingId: string,
    data: {
      status: string;
      publishedAt: string;
    },
  ) => void;
  statusData?: StatusData;
  onStatusChange?: (
    companyId: string,
    openingId: string,
    status: string,
  ) => void;
}

export const DeactivateLimitModal: React.FC<Props> = ({
  setIsOpen,
  handle,
  openingId,
  companyId,
  scheduleData,
  onScheduleOpening,
  statusData,
  onStatusChange,
}) => {
  const { formatMessage } = useIntl();
  const { activePlan } = useSelector((state: AppState) => state.billing);
  const redirectPath =
    openingId && companyId
      ? `/profile/company/openings/${companyId}/opening/duplicate/${openingId}`
      : `/profile/company/openings/${handle}/opening/create`;

  const isButton = Boolean(statusData || scheduleData);

  const content = getDeactivateContent(
    openingId,
    companyId,
    statusData,
    scheduleData,
  );

  function onScheduleDate() {
    if (!scheduleData || !onScheduleOpening) return;

    onScheduleOpening(
      scheduleData.companyId,
      scheduleData.openingId,
      scheduleData.data,
    );

    setIsOpen(false);
  }

  function handleStatusChange() {
    if (!statusData || !onStatusChange) return;

    onStatusChange(
      statusData.companyId,
      statusData.openingId,
      statusData.status,
    );

    setIsOpen(false);
  }

  return (
    <>
      <Heading as="h3" size="fluidXLarge">
        <FormattedMessage id="openings.deactivateLimit.title" />
      </Heading>
      <p css={styles.info}>
        <FormattedMessage id={content.info} />
      </p>
      <p css={styles.contentDeactivate}>
        <FormattedMessage
          id="openings.deactivateLimit.content"
          values={{
            number: activePlan?.futurePlanAllowedOpeningCount,
            value: formatMessage({ id: content.value }),
          }}
        />
      </p>

      {isButton ? (
        <Button
          buttonType="hazard"
          size="small"
          onClick={statusData ? handleStatusChange : onScheduleDate}
        >
          <FormattedMessage
            id={
              statusData
                ? 'openings.deactivateLimit.btnStatus'
                : 'openings.section.scheduleOpening'
            }
          />
        </Button>
      ) : (
        <Link to={redirectPath} css={styles.buttonDeactivate}>
          <FormattedMessage
            id={
              openingId && companyId
                ? 'openings.deactivateLimit.btnDuplicate'
                : 'openings.deactivateLimit.btn'
            }
          />
        </Link>
      )}

      <Button
        buttonType="ghost"
        type="button"
        size="medium"
        onClick={() => setIsOpen(false)}
      >
        <FormattedMessage id="shared.cancel" />
      </Button>
    </>
  );
};
