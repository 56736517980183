import { GumGridConfig } from '@prototyp/gatsby-plugin-gumball/models';
import { pageTwoCols, pageTwoColsReverse } from './grids.config';

/**
 * Unitless unit value (in px)
 */
export const unit = 4;

/**
 * Color HEX values (to be converted to HSL)
 */
export const colors = {
  text: '#131618',
  textLight: '#A5AEB6',
  success: '#BFEAB8',
  error: '#e37468',
  note: '#F0BD7F',
  white: '#FFFFFF',
  backgroundLight: '#F3F3F7',
  backgroundAlt: '#D2D2E1',
  backgroundGray: '#cccccc',
  border: '#A5AEB6',
  borderLight: '#C1C7CD',
  borderDark: '#6E7C87',
  borderUltraLight: '#DDE1E3',
  borderBlack: '#292E32',
  rangeBlue: '#8AEADD',
  warning: '#DB4C3D',
  textSuccess: '#77D369',
};

export const customPalette = {
  robin: [
    '#E1FAF6',
    '#B7F2EA',
    '#8AEADD',
    '#5FE2D0',
    '#34DAC4',
    '#22BAA5',
    '#1A8F7F',
    '#126358',
    '#0A3832',
    '#020D0C',
  ],

  grayscale: [
    '#F9FAFA',
    '#DDE1E3',
    '#C1C7CD',
    '#A5AEB6',
    '#89959F',
    '#6E7C87',
    '#57626B',
    '#40484F',
    '#292E32',
    '#131618',
  ],
};

/**
 * Unitless px value pairs [font-size, line-height]
 */
export const typography = {
  micro: [10, 16],
  tiny: [12, 16],
  xsmall: [13, 20],
  small: [14, 20],
  base: [16, 24],
  medium: [20, 28],
  large: [24, 32],
  xLarge: [32, 40],
  xxLarge: [40, 48],
  xxxLarge: [48, 56],
  huge: [60, 64],
  xHuge: [96, 100],
  smallBoy: [180, 180],
};

/**
 * Minimum and maximum container widths, userd for fluid typography.
 * Max value is the total width of the page design (outer width of the page).
 */
export const minContainerWidth = 360;
export const maxContainerWidth = 1600;

export const fontWeight = {
  regular: 400,
  bold: 700,
  black: 900,
};

export const borderRadius = {
  regular: '3px',
  medium: 'calc(--unit * 2)',
  half: '50px',
  full: '50%',
};

export const fontFamily = {
  primary:
    '"Aeonik", -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif',
  secondary:
    '"Neue Machina", -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;',
};

/**
 * Unitless z-index values, ordered from low to high
 */
export const zIndices = [-1];

/**
 * Breakpoint values without @media tags
 */

export const breakpoints = {
  tnyMax: 'screen and (max-width: 400px)',
  small: 'screen and (min-width: 480px)',
  smallMax: 'screen and (max-width: 767px)',
  medium: 'screen and (min-width: 768px)',
  mediumMax: 'screen and (max-width: 1279px)',
  large: 'screen and (min-width: 1280px)',
  largeMax: '(max-width: 1479px)',
  xxLarge: 'screen and (min-width: 1600px)',
};

/**
 * Wrappers
 */

export const wrappers = {
  tny: '360px',
  sml: '528px',
  page: '1312px',
};

/**
 * Spacing - margins, paddings, grid gaps
 */
export const spacing = {
  tny: 'calc(var(--unit) * 1)',
  sml: 'calc(var(--unit) * 2)',
  med: 'calc(var(--unit) * 3)',
  lrg: 'calc(var(--unit) * 4)',
  xlrg: 'calc(var(--unit) * 5)',
  xxlrg: 'calc(var(--unit) * 6)',
  xxxlrg: 'calc(var(--unit) * 8)',
  huge: 'calc(var(--unit) * 10)',
  xhuge: 'calc(var(--unit) * 15)',
};

/**
 * Main grid - page layout
 */

export const pageGrid: GumGridConfig[] = [
  {
    mediaMinWidth: 'large',
    cols: 12,
    gap: { column: 32 },
  },
];

/**
 * Additional grids - component
 */
export const componentGrids = {
  pageTwoCols,
  pageTwoColsReverse,
};
