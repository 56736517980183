import { navigate, RouteComponentProps, useParams } from '@reach/router';
import {
  useAssingMemberRole,
  useGetCompanyDetails,
  useGetTeamMembers,
} from 'modules/admin/hooks';
import React, { useEffect, useMemo, useState } from 'react';
import * as styles from 'modules/admin/styles/Manage.styles';
import * as detailStyles from 'modules/admin/styles/Details.styles';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import {
  AddMembers,
  AdminTable,
  CompanyInfo,
  SectionBody,
  SectionHead,
  TeamMemberItem,
} from '..';
import { ActionModal, Avatar, Button, Loading } from 'modules/app';
import {
  flex,
  font,
  padding,
  text,
} from '@prototyp/gatsby-plugin-gumball/utils';
import { formatDate } from 'modules/admin/static';
import { useDeleteCompany } from 'modules/admin/hooks/';
import Delete from 'assets/icons/deleteBlack.svg';
import Deactivate from 'assets/icons/closeOutline.svg';
import { companyDetailsHeaders } from 'modules/admin/consts';
import { DeactivateCompanyPlanModal } from './DeactivateCompanyPlanModal';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { ChangeOwnershipModal } from './ChangeOwnershipModal';

export const CompanyDetails: React.FC<RouteComponentProps> = () => {
  const { companyId }: { companyId: string } = useParams();

  const { userStaffRole } = useSelector((state: AppState) => state.session);

  const isSupport = useMemo(
    () => userStaffRole?.name === 'Support',
    [userStaffRole],
  );

  const [modal, setModal] = useState(false);
  const [team, setTeam] = useState<Users[]>([]);
  const [company, setCompany] = useState<CompanyResponse>();
  const [isDeactivatePlanModalOpen, setIsDeactivatePlanModalOpen] =
    useState(false);
  const [isChangeModal, setIsChangeModal] = useState(false);

  const { getCompanyDetails } = useGetCompanyDetails();
  const { getTeamMembers, isLoading } = useGetTeamMembers();

  const { deleteCompany, deactivateCompany } = useDeleteCompany();
  const admins = team.filter(
    (member) =>
      member.companyRoles.find((item) => item.companyId === companyId)
        ?.roleId === 'administrator',
  );
  const allAdminIds = admins?.map((admin) => admin.id);

  const handleSetTeam = (data?: Users[]) => {
    setTeam(data || []);
  };

  const handleRemoveMember = (memberId: string) => {
    const newTeam = team.filter((member) => member.id !== memberId);
    setTeam(newTeam);
  };

  const handleSetCompany = (data: CompanyResponse) => {
    setCompany(data);
  };

  const { assignMemberRole, removeMemberRole } = useAssingMemberRole();
  const assignRole = (id: string, role: string) => {
    const data = {
      companyId: companyId,
      companyRoleId: role,
    };
    assignMemberRole(id, data, handleSetTeam);
  };

  const removeRole = (id: string) => {
    removeMemberRole(id, companyId, handleRemoveMember);
  };

  const handleDeactivate = async () => {
    if (isSupport) return;

    const data = await deactivateCompany(companyId);

    if (!data) return;
    handleSetCompany(data);
  };

  const handleDelete = () => {
    if (isSupport) return;

    deleteCompany(companyId);
    navigate(-1);
  };

  const handleDeactivatePlanModalOpen = () => {
    setIsDeactivatePlanModalOpen(!isDeactivatePlanModalOpen);
  };

  const handleGetCompanyDetails = async () => {
    const data = await getCompanyDetails(companyId);
    if (!data) return;
    handleSetCompany(data);
  };

  useEffect(() => {
    handleGetCompanyDetails();
    getTeamMembers({ companyId: companyId }, handleSetTeam);
  }, [companyId]);

  const isAdministrator = useMemo(
    () =>
      Boolean(
        company?.companyTeamMembers.find(
          (member) =>
            member.id === userStaffRole?.userId &&
            member.role === 'administrator',
        ),
      ),
    [userStaffRole, company],
  );

  if (!company || !companyId) {
    return null;
  }

  return (
    <>
      {isChangeModal && (
        <ActionModal isOpen={isChangeModal} setIsOpen={setIsChangeModal}>
          <ChangeOwnershipModal
            companyId={company.id}
            onClose={() => setIsChangeModal(false)}
          />
        </ActionModal>
      )}

      <div css={[styles.content, styles.details]}>
        <React.Suspense fallback={null}>
          <motion.div {...FADE_IN_MOVE_Y_REGULAR}>
            <SectionHead
              title={company.name}
              customStyles={[flex.align.end, padding.bottom.xxxlrg]}
            >
              <Avatar
                imagePath={company.iconPicture?.small}
                name={company.name}
              />
            </SectionHead>
            <SectionBody>
              <div css={detailStyles.actions}>
                {Boolean(company.deletedAt) ? (
                  <Button
                    icon={<Delete />}
                    buttonType="ghost"
                    css={[padding.none, detailStyles.actionsButton]}
                    onClick={handleDelete}
                    disabled={isSupport}
                  >
                    Delete
                  </Button>
                ) : (
                  <Button
                    icon={<Deactivate />}
                    buttonType="ghost"
                    css={[padding.none, detailStyles.actionsButton]}
                    onClick={handleDeactivate}
                    disabled={isSupport}
                  >
                    Deactivate
                  </Button>
                )}
                {company.currentPlan && (
                  <Button
                    icon={<Deactivate />}
                    buttonType="ghost"
                    css={[padding.none, detailStyles.actionsButton]}
                    onClick={handleDeactivatePlanModalOpen}
                  >
                    Deactivate Plan
                  </Button>
                )}

                {isAdministrator && (
                  <Button
                    buttonType="ghost"
                    css={[detailStyles.actionsButton, padding.none]}
                    onClick={() => setIsChangeModal(true)}
                  >
                    Transfer ownership
                  </Button>
                )}
              </div>
              <CompanyInfo label="handle">{company.handle}</CompanyInfo>
              <CompanyInfo label="year of founding">
                {formatDate(company.establishedAt, 'yyyy')}
              </CompanyInfo>
              <CompanyInfo label="representative">
                {company.representativeFullName}
                <div
                  css={[
                    text.color.textLight,
                    font.size.xsmall,
                    font.weight.regular,
                  ]}
                >
                  {company.representativeEmail}
                </div>
                {Boolean(company.representativePhone) && (
                  <div
                    css={[
                      text.color.textLight,
                      font.size.xsmall,
                      font.weight.regular,
                    ]}
                  >
                    {company.representativePhone}
                  </div>
                )}
              </CompanyInfo>
              <CompanyInfo label="registered on">
                {formatDate(company.registeredAt, 'MMM do yyyy, HH:mm')}
              </CompanyInfo>
              {company.currentPlan?.planName && (
                <CompanyInfo label="current plan">
                  {company.currentPlan.planName}
                </CompanyInfo>
              )}
              {Boolean(company.deletedAt) && (
                <CompanyInfo
                  label={
                    company.name.includes('DELETED')
                      ? 'deleted at'
                      : 'deactivated at'
                  }
                >
                  {formatDate(company.deletedAt, 'MMM do yyyy')}
                </CompanyInfo>
              )}
              {Boolean(company.companyTeamMembers.length) && (
                <>
                  <SectionHead
                    title="Team members"
                    customStyles={[
                      flex.align.end,
                      padding.none,
                      padding.top.xhuge,
                      padding.bottom.huge,
                    ]}
                  >
                    <Button size="small" onClick={() => setModal(true)}>
                      Add team member
                    </Button>
                  </SectionHead>
                  {modal && (
                    <ActionModal isOpen={modal} setIsOpen={setModal}>
                      <AddMembers
                        role="team_member"
                        handleClick={() => setModal(!modal)}
                        companyId={companyId}
                        onAssign={assignRole}
                      />
                    </ActionModal>
                  )}
                  <Loading
                    isLoading={isLoading}
                    component={
                      <AdminTable headers={companyDetailsHeaders}>
                        {team?.map((member) => (
                          <TeamMemberItem
                            key={member.id}
                            companyId={companyId}
                            member={member}
                            assignRole={assignRole}
                            removeRole={removeRole}
                            allAdminIds={allAdminIds}
                            adminCount={admins?.length || 0}
                          />
                        ))}
                      </AdminTable>
                    }
                  />
                </>
              )}
            </SectionBody>
          </motion.div>
        </React.Suspense>
      </div>
      {isDeactivatePlanModalOpen && (
        <DeactivateCompanyPlanModal
          companyId={companyId}
          plan={company.currentPlan}
          handleModalClose={handleDeactivatePlanModalOpen}
          isOpen={isDeactivatePlanModalOpen}
          handleSetCompany={handleSetCompany}
        />
      )}
    </>
  );
};
